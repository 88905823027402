import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  return (
    <SvgContainer title="Check Icon" {...props}>
      <path d="M25.327 8.122a.5.5 0 00-.705.05l-12.584 14.52-4.63-6.483a.5.5 0 00-.815.581l5 7c.01.014.026.02.037.032a.476.476 0 00.043.056.463.463 0 00.07.041.48.48 0 00.055.033.444.444 0 00.458-.034c.011-.007.024-.004.035-.011a.465.465 0 00.044-.048c.013-.012.03-.017.043-.031l13-15a.5.5 0 00-.05-.706z" />
    </SvgContainer>
  );
}
