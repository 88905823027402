import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredGreenArrow(props) {
  return (
    <SvgContainer title="GreenArrow Icon" {...props}>
      <path d="M12 7H2v5.5h10v5L23 9 12 2v5z" fill="#347630" stroke="#347630" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 7H2v5.5h10v5L23 9 12 2v5z" fill="#347630" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    </SvgContainer>
  );
}
