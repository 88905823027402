import Factory from 'factories/_Factory';


export default class Accreditor extends Factory {
  static jsonType = 'accreditor';

  static get defaults() {
    return {
      name: '',
      credit_name: '',
      credit_type: ''
    };
  }
}
