import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueNote(props) {
  return (
    <SvgContainer title="Note Icon" {...props}>
      <path className="pv-note-cls-1" d="M38 53H14V11h32v15h-4V15H18v34h20v4z" />
      <path className="pv-note-cls-1" d="M19 18h21v3H19zM19 28h7v3h-7zM19 35h10v3H19zM19 42h10v3H19zM51.41 54.74a1.51 1.51 0 0 1-1.06-.44L30.52 34.47a1.5 1.5 0 0 1 0-2.12L36 26.87a1.55 1.55 0 0 1 2.12 0L58 46.7a1.49 1.49 0 0 1 0 2.12l-5.53 5.48a1.51 1.51 0 0 1-1.06.44ZM33.7 33.41l17.71 17.7 3.36-3.35-17.71-17.71Z" />
      <path d="M31.58 34.91a1.32 1.32 0 0 1-.34 0 1.48 1.48 0 0 1-1.08-1l-2.88-8.6a1.51 1.51 0 0 1 .39-1.56 1.49 1.49 0 0 1 1.56-.32l8.36 3.12a1.5 1.5 0 0 1 .53 2.45l-5.48 5.48a1.5 1.5 0 0 1-1.06.43Zm-.45-7.6 1.11 3.32 2.11-2.11ZM47.41 50.74a1.51 1.51 0 0 1-1.06-.44 1.49 1.49 0 0 1 0-2.12l5.48-5.48A1.5 1.5 0 0 1 54 44.82l-5.53 5.48a1.51 1.51 0 0 1-1.06.44Z" className="pv-note-cls-1" />
    </SvgContainer>
  );
}
