import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonIconX(props) {
  return (
    <SvgContainer title="X Icon" {...props}>
      <path d="M46.07 49.25a3 3 0 0 1-2.18-.94l-27-28.49a3 3 0 0 1 4.36-4.13l27 28.49a3 3 0 0 1-2.18 5.07Z" />
      <path d="M18.93 49.25a3 3 0 0 1-2.18-5.07l27-28.49a3 3 0 0 1 4.36 4.13l-27 28.49a3 3 0 0 1-2.18.94Z" />
    </SvgContainer>
  );
}
