import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInView from "helpers/inView";
import Button from 'components/inputs/Button';

import loadable from '@loadable/component';
import useWindowDimensions from "helpers/windowDimensions";
// TODO Verify shopper-approved schema is being indexed and then remove this
// import ShopperApproved from './PdpPage/ShopperApproved';
import ProductCards from './PdpPage/ProductCards';
import useScreenSize from './PdpPage/hooks/useScreenSize';


export default function PdpPageClass ({ context }) {
  const {
    question_bank: questionBankName,
    active_question_count: activeQuestionCount,
    sections,
    feature_groups: featureGroups,
    expandable_dropdown_groups: expandableDropdownGroups,
    all_plans_sections: allPlansSections,
    static_image_sections: staticImageSections,
    carousel_image_sections: carouselImageSections,
    blog_feed_sections: blogFeedSections,
    plan_features: pricingPlanFeatures,
    pricing_display: pricingDisplay,
    pricing,
    preload_question: preloadedData,
    // TODO Verify shopper-approved schema is being indexed and then remove this
    // shopper_approved: shopperProduct,
    sample_question: sampleQuestion,
    sample_answer: sampleAnswer,
    header_image: headerImage,
    header_info: headerInfo,
    testimonials,
    subject_list: subjects,
    accreditors: dataAccreditors,
    credit_redemption: dataCreditRedemption,
    breadcrumb: breadcrumbData,
    cross_sells: crossSells,
    pdp_product_cards_ld_flag: pdpProductCardsEnabled
  } = context;

  const PageHeader = loadable(() => import('./PdpPage/PageHeader'));
  const PricingCards = loadable(() => import('./PdpPage/PricingCards'));
  const SampleQuestionModal = loadable(() => import('./PdpPage/components/SampleQuestionModal'));

  // at some point we need to check if we are on a CME product page, then hasCrossSells will need to have logic aimed for cme pages (i.e., return true if there are no products, but there are cme_products and false if there aren't cme_products)
  const hasCrossSells = (crossSells?.products?.length || crossSells?.cme_products?.length) || false;
  const { isTablet, isMobile } = useScreenSize();
  const { width } = useWindowDimensions();
  const smallWeb = width < 993 && width > 820;


  const { data: questionData } = preloadedData;

  const [pricesInView, setPricesInView] = useState(false);
  const pricingCardsRef = useInView(setPricesInView);
  const [headerInView, setHeaderInView] = useState(false);
  const headerRef = useInView(setHeaderInView);

  // Pricing card addons should persist states while parent component (pdp) reamins mounted
  const [expandHidden, setExpandHidden] = useState(true);
  const [collapseHidden, setCollapseHidden] = useState(false);
  const [addonsHidden, setAddonsHidden] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const mobileAddonsLogicObject = { expandHidden, collapseHidden, addonsHidden };

  const managemobileAddons = (e) => {
    if (e.target.id === "expand-upgrade" || e.target.parentNode.id === "expand-upgrade" || e.target.parentNode.parentNode.id === "expand-upgrade") {
      setExpandHidden(true);
      setCollapseHidden(false);
      setAddonsHidden(false);
    } else {
      setExpandHidden(false);
      setCollapseHidden(true);
      setAddonsHidden(true);
    }
  };
  // End of Pricing card addons functionality

  const onClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (sampleQuestion && sampleAnswer) {
      setShowModal(true);
    }
  }, [sampleQuestion, sampleAnswer]);

  const sectionSwitch = ({
    section_type: type,
    id: key,
    product_feature_group_id: featureGroupId,
    all_plans_section_id: allPlansId,
    carousel_image_section_id: carouselImageId,
    blog_id: blogId,
    content,
    title_text: titleText
  }) => {
    switch (type) {
      case 'feature_group': {
        const KeyFeatures = loadable(() => import('./PdpPage/KeyFeatures'));

        return (
          <KeyFeatures
            key={key}
            questionBankName={questionBankName}
            featureGroup={featureGroups[`${featureGroupId}`]}
            activeQuestionCount={activeQuestionCount}
          />
        );
      }
      case 'expandable_dropdown_group': {
        const ExpandableDropdown = loadable(() => import('./PdpPage/ExpandableDropdown'));

        return (
          <ExpandableDropdown
            key={key}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
            expandableDropdownGroup={expandableDropdownGroups[`${key}`]}
          />
        );
      }
      case 'all_plans': {
        const AllPlans = loadable(() => import('./PdpPage/AllPlans'));

        return (
          <AllPlans
            key={key}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
            allPlansSection={allPlansSections[`${allPlansId}`]}
          />
        );
      }
      case 'static_image': {
        const StaticImage = loadable(() => import('./PdpPage/StaticImage'));

        return (
          <StaticImage
            key={key}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
            staticImageSection={staticImageSections[`${key}`]}
          />
        );
      }
      case 'carousel_image': {
        const CarouselImage = loadable(() => import('./PdpPage/CarouselImage'));

        return (
          <CarouselImage
            key={key}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
            carouselImageSection={carouselImageSections[`${carouselImageId}`]}
          />
        );
      }
      case 'sample_question': {
        const SampleQuestion = loadable(() => import('./PdpPage/SampleQuestion'));

        return (
          <SampleQuestion
            key={key}
            questionData={questionData}
            questionBankName={questionBankName}
          />
        );
      }
      case 'testimonial': {
        const Testimonials = loadable(() => import('./PdpPage/Testimonials'));

        return (
          <Testimonials
            key={key}
            questionBankName={questionBankName}
            testimonials={testimonials}
          />
        );
      }
      case 'subject_list': {
        const SubjectList = loadable(() => import('./PdpPage/SubjectList'));

        return (
          <SubjectList
            key={key}
            questionBankName={questionBankName}
            subjects={subjects}
          />
        );
      }
      case 'accreditation statements': {
        const Accreditors = loadable(() => import('./PdpPage/Accreditors'));

        return (
          <Accreditors
            key={key}
            dataAccreditors={dataAccreditors}
            questionBankName={questionBankName}
          />
        );
      }
      case 'blog_feed': {
        const BlogFeed = loadable(() => import('./PdpPage/BlogFeed'));

        return (
          <BlogFeed
            key={key}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
            blogFeedSection={blogFeedSections[`${blogId}`]}
          />
        );
      }
      case 'credit_redemption_instructions': {
        const CreditRedemption = loadable(() => import('./PdpPage/CreditRedemption'));

        return (
          <CreditRedemption
            key={key}
            dataCreditRedemption={dataCreditRedemption}
          />
        );
      }
      case 'markdown': {
        const Markdown = loadable(() => import('./PdpPage/Markdown'));

        return (
          <Markdown
            key={key}
            content={content}
            titleText={titleText}
          />
        );
      }
      default:
        return null;
    }
  };

  const scrollBackToTop = () => {
    if (pricesInView || headerInView) return;
    const targetElement = document.getElementById('pricing-cards');
    window.scrollTo({
      top: targetElement.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <div className="ProductDetailPage">
      <SampleQuestionModal
        onClose={onClose}
        showModal={showModal}
        sampleAnswer={sampleAnswer}
        sampleQuestion={sampleQuestion}
      />
      <div className="details-wrapper">
        <PageHeader
          headerImage={headerImage}
          headerInfo={headerInfo}
          headerRef={headerRef}
          breadcrumbData={breadcrumbData}
        />
      </div>
      <div className="details-wrapper justify-content-center">
        <PricingCards
          pricingPlanFeatures={pricingPlanFeatures}
          pricingDisplay={pricingDisplay}
          activeQuestionCount={activeQuestionCount}
          pricing={pricing}
          pricingCardsRef={pricingCardsRef}
          mobileAddonsLogicObject={mobileAddonsLogicObject}
          managemobileAddons={managemobileAddons}
        />
      </div>
      <div className="sections-container">
        {sections.map(section => sectionSwitch(section))}
        {(pdpProductCardsEnabled && hasCrossSells && !isMobile) ? <ProductCards products={crossSells} displayCount={(isTablet || smallWeb) ? 1 : 3} /> : null}
        {/* TODO Verify shopper-approved schema is being indexed and then remove this */}
        {/* <ShopperApproved shopperProduct={shopperProduct} /> */}
      </div>
      <div className={`UpArrowIcon ${(pricesInView || headerInView) ? 'fade-out' : ''}`}>
        <Button
          icon="UpArrow"
          onClick={scrollBackToTop}
          styleVariant="none"
          iconProps={{
            height: 35, width: 35, viewBoxWidth: 35, viewBoxHeight: 35
          }}
        />
      </div>
    </div>
  );
}

PdpPageClass.propTypes = { context: PropTypes.object.isRequired };
