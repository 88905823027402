import { detect } from 'detect-browser';
import localForage from 'localforage';

// This should be the only .js.erb file
// Any Rails variables that need to be exposed to the JS app should be added here
function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
// Each variable should get exported so they can be individually imported
export const browser = detect();
export const env = 'staging';

// Should the root urls be defined here as they are not dependent on any rails varaibles?
export const loginRoot = '/users/sign_in';
export const apiRoot = inIframe() ? '/api/ati/v1/' : '/api/web/v1/';

// Change the boolean values to enable/disable console logging but do not change the other conditions
export const apiConsoleLogging = true;
export const contextReducerLogging = browser.name !== 'ie' && false;
export const qaSessionTimeoutOn = false;
export const practiceExamsActivated = true;
export const practiceExamAccessTrackersActivated = true;
export const LaunchDarklyClientId = "61b167a780f9560da03fac28";
export const BugFenderAppKey = "mC6qDMGPD8T3MBQGurZyOzPfIv8H5LNu";

export const hubspot = {
  shouldSync: true,
  platformId: "3019334",
  signUpFormId: "",
  newsletterFormId: "84f8c55c-b0d4-430c-8cc1-53d112d83220",
  cmeCoachFormId: "7c082303-1c25-49f3-846c-3849d30e22d9",
  institutionalLandingFormId: "c4ca30fc-8350-498f-81d5-73d96e7171ea"
};

export const algolia = {
  appId: "ANU67R4V3M",
  apiKey: "466a8b962a22ec12e6bf4ec058b83711",
};

export function onAPIUnauthorized(response) {
  if (response.data.errors.some(e => e.code === 'SESSION_REQUIRED')) {
    location.href = loginRoot;
  }
}

export const googleAnalyticsId = 'UA-28511853-1';
export const googleAnalyticsMeasurementId = 'G-6ZELFQDRMM';
export const googleAnalyticsTagManagerId = 'GTM-5T4S7D';
export const apiTimeout = 10000;

export const clearLocalStorage = () => {
  localForage.clear();
};

export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};

export const uaWidgetURLs = {
  app: "https://auth.boardvitals.com",
  cas: "https://auth.boardvitals.com",
  api: "https://auth.boardvitals.com",
  service: "https://www.boardvitals.com/users/sign_in",
  customerService: "https://www.boardvitals.com/users/sign_in",
  script: "https://auth.boardvitals.com/ua-ng-login.js",
  style: ""
};

// The default export is an object with all the config variables
export default {
  browser,
  env,
  loginRoot,
  apiRoot,
  apiConsoleLogging,
  contextReducerLogging,
  qaSessionTimeoutOn,
  hubspot,
  algolia,
  onAPIUnauthorized,
  googleAnalyticsId,
  googleAnalyticsMeasurementId,
  googleAnalyticsTagManagerId,
  apiTimeout,
  clearLocalStorage,
  clearSessionStorage,
  LaunchDarklyClientId,
  BugFenderAppKey
};

