import { stateHelper } from 'reducers/quizzes/quizzes';
import Highlight from 'factories/Highlight';

import {
  HIGHLIGHT_SYNCED,
  HIGHLIGHT_SYNC_OFFLINE
} from 'actions/highlights';
import {
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_QUESTIONS
} from 'actions/quizzes';


const reducers = {};


function setHighlight(state, json) {
  const quizId = parseInt(json.relationships.quiz.data.id);
  const questionId = parseInt(json.relationships.question.data.id);
  let highlight = state.getIn([quizId, 'highlights', questionId]);
  highlight = highlight ? Highlight.update(highlight, json) : new Highlight(json);
  return state.setIn([quizId, 'highlights', questionId], highlight);
}

function setHighlights(state, payload) {
  return payload.included.filter(record => record.type === 'highlight').reduce(setHighlight, state);
}


reducers[HIGHLIGHT_SYNCED] = function highlightSynced(state, payload) {
  return setHighlight(state, payload.data);
};


reducers[HIGHLIGHT_SYNC_OFFLINE] = function highlightSyncOffline(state, payload) {
  const {
    quizId,
    questionId,
    ranges
  } = payload;

  const json = {
    type: 'highlight',
    attributes: {
      ranges: ranges
    },
    relationships: {
      quiz: {
        data: {
          id: quizId,
          type: 'quiz'
        }
      },
      question: {
        data: {
          id: questionId,
          type: 'question'
        }
      }
    }
  };
  const options = { fetched: false };

  let highlight = state.getIn([quizId, 'highlights', questionId]);
  highlight = highlight ? Highlight.update(highlight, json, options) : new Highlight(json, options);

  return state
    .setIn([quizId, 'highlights', questionId], highlight)
    .setIn([quizId, 'synced'], false);
};


// Non-Highlight Action Reducers

reducers[QUIZ_FETCHED_TAKE] = setHighlights;
reducers[QUIZ_FETCHED_QUESTIONS] = setHighlights;
// reducers[OFFLINE_QUIZ_SYNCED] = setHighlights;


export const reducer = stateHelper.createReducer(reducers);

export default reducers;
