import React, { useState } from 'react';
import PropTypes from "prop-types";
import SVGIcon from 'components/SVGIcon';

const CardsCarousel = ({
  cards, mobileOnly, cardClassName, paginationDotsClass, dotWidth, dotHeight, caretWidth, caretHeight, className, displayCount = 1, recommendationCards = false
}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [bounds, setBounds] = useState({ left: 0, right: displayCount });
  const [page, setPage] = useState(0);


  const showsMultipleCards = displayCount > 1;
  const pageCount = cards.length % displayCount === 0 ? cards.length / displayCount : Math.floor(cards.length / displayCount) + 1;

  const previousSlide = () => {
    if (!showsMultipleCards) {
      const lastIndex = cards.length - 1;
      const shouldResetIndex = currentCardIndex === 0;
      const index = shouldResetIndex ? lastIndex : currentCardIndex - 1;
      setCurrentCardIndex(index);
    } else {
      setBounds((prev) => {
        const l = prev.left - displayCount < 0 ? 0 : prev.left - displayCount;
        const r = l + displayCount;
        return { left: l, right: r };
      });
      if (page > 0) setPage(prev => prev - 1);
    }
  };

  const nextSlide = () => {
    if (!showsMultipleCards) {
      const lastIndex = cards.length - 1;
      const shouldResetIndex = currentCardIndex === lastIndex;
      const index = shouldResetIndex ? 0 : currentCardIndex + 1;
      setCurrentCardIndex(index);
    } else {
      if (bounds.right >= cards.length) return;
      setBounds((prev) => {
        const r = prev.right + displayCount > cards.length ? cards.length : prev.right + displayCount;
        return { left: prev.right, right: r };
      });
      if (page < pageCount) setPage(prev => prev + 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 37) {
      previousSlide();
    } else if (e.keyCode === 39) {
      nextSlide();
    }
  };

  const displayMultipleConcurrentCards = () => {
    const cardsToDisplay = cards.slice(bounds.left, bounds.right);
    return <div className="row mx-4 d-flex justify-content-center">{cardsToDisplay.map((card, index) => <div key={index} className={cardClassName}>{card}</div>)}</div>;
  };


  const generatePaginationDots = () => Array.from({ length: pageCount });

  const paginationDotsCallback = ref => (_, i) => <SVGIcon key={i} name="Elipse" width={dotWidth || 15} height={dotHeight || 15} viewBoxWidth={15} viewBoxHeight={15} className={i === ref ? "filled" : ""} />;


  return (
    <div className={`d-flex flex-column ${className}`}>
      <div className={mobileOnly ? "mobile-view" : `row d-flex align-items-center justify-content-center ${recommendationCards ? 'w-100' : ''}`}>
        {(cards.length > displayCount) ? (
          <div className="prev-arrow">
            <span
              role="button"
              tabIndex="0"
              onClick={previousSlide}
              onKeyPress={e => handleKeyPress(e)}
            >
              <SVGIcon name="LeftCaret4" width={caretWidth} height={caretHeight} viewBoxWidth={21} viewBoxHeight={38} className="arrow" />
            </span>
          </div>
        ) : null}
        {showsMultipleCards ? <div className="col">{displayMultipleConcurrentCards()}</div> : <div key={currentCardIndex} className={cardClassName}>{cards[currentCardIndex]}</div>}
        { cards.length > displayCount ? (
          <div className="next-arrow">
            <span
              className="ml-auto"
              role="button"
              tabIndex="0"
              onClick={nextSlide}
              onKeyPress={e => handleKeyPress(e)}
            >
              <SVGIcon name="RightCaret4" width={caretWidth} height={caretHeight} viewBoxWidth={21} viewBoxHeight={38} className="arrow" />
            </span>
          </div>
        ) : null }
      </div>
      { cards.length > displayCount
        ? (
          <div className={paginationDotsClass || "pagination-dots"}>
            { showsMultipleCards ? generatePaginationDots().map(paginationDotsCallback(page)) : cards.map(paginationDotsCallback(currentCardIndex))}
          </div>
        ) : null}
    </div>
  );
};

CardsCarousel.propTypes = {
  cards: PropTypes.array.isRequired,
  mobileOnly: PropTypes.bool,
  cardClassName: PropTypes.string,
  paginationDotsClass: PropTypes.string,
  dotHeight: PropTypes.number,
  dotWidth: PropTypes.number,
  caretWidth: PropTypes.number,
  caretHeight: PropTypes.number,
  className: PropTypes.string,
  displayCount: PropTypes.number,
  recommendationCards: PropTypes.bool
};

export default CardsCarousel;
