import React from "react";
import PropTypes from 'prop-types';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import TabbedSections from '../../../components/menus/TabbedSections';
import Section from '../../../components/Section';
import Table, { TableColumn } from '../../../components/lists/Table';

export default function HomeQuestionBanks({ questionBankPages }) {
  const { width } = useWindowDimensions();
  const isMdOrSmaller = width <= breakpoints.lg;

  const tableSection = (pages, index) => (
    <Table
      key={index}
      rowData={pages}
      className="question-banks-table p-0 pt-3 m-0"
      maxRecords={10}
      noHeader
    >
      <TableColumn
        className="col-6 p-0"
        cellValue={rowItem => (
          <a href={rowItem.path} className="page-link">{rowItem.name}</a>
        )}
      />
    </Table>
  );

  const tablesBySection = () => (
    questionBankPages.map((element, index) => (
      <Section key={index} name={element.name} buttonClassName="mr-2 mr-lg-2 mr-xl-6 px-4" className="d-inline-flex pl-6 py-6">
        {
          element.pages.map((pages, pageIndex) => (
            tableSection(pages, pageIndex)
          ))
        }
      </Section>
    ))
  );

  const iconProps = {
    height: 12,
    width: 20,
    className: 'mx-2',
  };

  const dropdownsBySection = () => (
    questionBankPages.map((element, index) => (
      <Section key={index} name={element.name} icon="DownCaret5" iconProps={iconProps} buttonClassName="mb-3" wrapperClassName="h-auto">
        <div className="mobile-content m-4 py-4 bg-white">
          {
            element.pages.map(pages => (
              pages.map((page, pageIndex) => (
                <div key={pageIndex} className="px-4 text-wrap">
                  <a href={page.path} className="page-link">{page.name}</a>
                </div>
              ))
            ))
          }
        </div>
      </Section>
    ))
  );

  const renderDropdowns = () => (
    <div className="position-relative w-100 d-lg-none d-xl-none d-sm-block d-md-block">
      <TabbedSections activeTab="none" styleVariant="storefrontMobile">
        { dropdownsBySection() }
      </TabbedSections>
    </div>
  );

  return (
    <div className="HomeQuestionBanks home-section-wrapper">
      <div className="container">
        <div className="col col-12 text-left text-lg-center text-xl-center m-0 mt-lg-6 mb-lg-5 pt-6 pb-5">
          <h2 className="storefront header-style section-header mt-6 mb-3">Try BoardVitals Free Today</h2>
          <h3 className="storefront section-subtitle my-5">No credit card required. Find your specialty below</h3>
        </div>
        <div className="qb-wrapper mb-0 mb-lg-2 mx-2 pb-6 row d-flex flex-wrap justify-content-md-center">
          <div className="position-relative mb-6 w-100 d-none d-lg-block d-xl-block">
            <TabbedSections styleVariant="storefront">
              { tablesBySection() }
            </TabbedSections>
          </div>

          {isMdOrSmaller && renderDropdowns()}
        </div>
      </div>
    </div>
  );
}

HomeQuestionBanks.propTypes = {
  questionBankPages: PropTypes.array
};
