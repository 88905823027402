import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgList(props) {
  return (
    <SvgContainer title="List Icon" {...props}>
      <path d="M26 29.5H6A1.502 1.502 0 014.5 28V4A1.502 1.502 0 016 2.5h20A1.502 1.502 0 0127.5 4v24a1.502 1.502 0 01-1.5 1.5zM6 3.5a.5.5 0 00-.5.5v24a.5.5 0 00.5.5h20a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5z" />
      <circle cx={9} cy={13.5} r={1} />
      <circle cx={9} cy={8.5} r={1} />
      <circle cx={9} cy={18.5} r={1} />
      <circle cx={9} cy={23.5} r={1} />
      <path d="M23.5 9h-11a.5.5 0 010-1h11a.5.5 0 010 1zM23.5 14h-11a.5.5 0 010-1h11a.5.5 0 010 1zM23.5 19h-11a.5.5 0 010-1h11a.5.5 0 010 1zM23.5 24h-11a.5.5 0 010-1h11a.5.5 0 010 1z" />
    </SvgContainer>
  );
}
