import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgSyringe(props) {
  return (
    <SvgContainer title="Syringe Icon" {...props}>
      <path d="M9.99 16.354l9.192 9.192 6.364-6.364-9.192-9.193zm9.192 7.778l-1.414-1.414 1.767-1.768a.5.5 0 00-.707-.707l-1.768 1.768-1.414-1.415 1.768-1.768a.5.5 0 00-.707-.707l-1.768 1.769-1.414-1.415 1.768-1.768a.5.5 0 10-.707-.707l-1.768 1.768-1.415-1.415 4.95-4.95 7.779 7.779z" />
      <path d="M26.96 22.01l1.768-1.768a1.5 1.5 0 000-2.121L16.353 5.747l-3.535 3.535-1.414-1.413L13.172 6.1a1.501 1.501 0 000-2.122l-.707-.707a1.5 1.5 0 00-2.122 0l-7.07 7.07a1.502 1.502 0 000 2.123l.707.707a1.502 1.502 0 002.12 0l1.769-1.768 1.414 1.414-3.536 3.536 12.374 12.374a1.49 1.49 0 001.06.44h.002a1.485 1.485 0 001.06-.44l1.767-1.768 2.122 2.122 2.12-2.122 1.769 1.768a.5.5 0 00.707-.708l-1.768-1.767 2.121-2.121zM5.394 12.465a.5.5 0 01-.708 0l-.707-.707a.501.501 0 010-.708l7.07-7.07a.5.5 0 01.709 0l.707.707a.5.5 0 010 .707l-1.768 1.768-3.536 3.534zm3.182-1.768l2.12-2.121 1.415 1.413-2.122 2.122zm10.96 17.323a.495.495 0 01-.353.147.496.496 0 01-.354-.147L7.16 16.354l2.828-2.829 3.536-3.536 2.828-2.828L28.02 18.828a.5.5 0 010 .707zm3.182-1.767l3.535-3.535 1.414 1.414-3.535 3.535z" />
    </SvgContainer>
  );
}
