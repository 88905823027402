import api, { currentUserUrl } from 'helpers/api';
import { hasBeenADaySince } from 'helpers/dateTime';

import { cmeActivitiesFetch } from 'actions/cme';


export const CME_TRACKER_LOADING = 'CME_TRACKER_LOADING';
export const CME_TRACKER_FETCHED = 'CME_TRACKER_FETCHED';
export const CME_TRACKER_ERROR = 'CME_TRACKER_ERROR';
const cmeTrackersFetchKey = (questionBankId = 'all') => `cme-trackers/${questionBankId}`;
export const cmeTrackersFetch = questionBankId => (dispatch, getState) => {
  let { loading, loadedAt } = getState();
  const key = cmeTrackersFetchKey(questionBankId);
  if (loading.has(key)) return loading.get(key);


  // Get load keys for pre-requesite actions
  const activitiesKey = cmeActivitiesFetch.getKey(questionBankId);

  // Check that pre-requisite actions are loaded and call them if they haven't been
  if (!loadedAt.has(activitiesKey) || hasBeenADaySince(loadedAt.get(activitiesKey))) dispatch(cmeActivitiesFetch(questionBankId));


  // Check if pre-requisite actions are loading so that we can wait for them to finish
  ({ loading } = getState());

  const questionBankParam = questionBankId ? `question_banks/${questionBankId}/` : '';
  const promises = [api.get(`${currentUserUrl(getState())}/${questionBankParam}cme_trackers`)];
  if (loading.has(activitiesKey)) promises.push(loading.get(activitiesKey));

  if (promises.length < 1) return Promise.resolve({});

  const promise = Promise.all(promises)
    .then((responses) => {
      dispatch({
        type: CME_TRACKER_FETCHED,
        payload: { key, ...responses[0] }
      });
      return responses[0];
    })
    .catch((error) => {
      dispatch({
        type: CME_TRACKER_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: CME_TRACKER_LOADING,
    payload: { key, promise }
  });

  return promise;
};
cmeTrackersFetch.getKey = cmeTrackersFetchKey;
