import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRocket(props) {
  return (
    <SvgContainer title="Rocket Icon" {...props}>
      <path d="M26.228 6.048v-.001l-.002-.006a.45.45 0 00-.034-.05.485.485 0 00-.075-.109.491.491 0 00-.08-.054.482.482 0 00-.078-.054c-3.523-1.49-9.517-.824-13.683 3.343-.047.048-.09.1-.138.148a10.715 10.715 0 00-6.933 2.68.5.5 0 000 .707l2.67 2.672a11.489 11.489 0 00-.66 1.696c-1.857 2.004-2.508 6.383-1.275 8.82.006.012.019.017.026.028a.488.488 0 00.135.145.477.477 0 00.042.034c.007.004.01.01.018.014a6.952 6.952 0 003.084.632 8.9 8.9 0 005.728-1.906 11.457 11.457 0 001.704-.662l2.671 2.67a.5.5 0 00.707 0 10.691 10.691 0 002.68-6.934c.049-.047.1-.09.148-.138 4.164-4.163 4.83-10.151 3.345-13.674zM14.897 23.76L8.24 17.103a20.782 20.782 0 014.742-7.279 13.084 13.084 0 017.04-3.582.465.465 0 00.032.047l5.656 5.656a.487.487 0 00.046.031 13.085 13.085 0 01-3.581 7.04 20.791 20.791 0 01-7.28 4.743zM8.74 23.26c-.457-.458-.071-1.848 1.078-3.165l2.087 2.087C10.588 23.33 9.2 23.715 8.74 23.26zM25.375 6.625a9.678 9.678 0 01.533 4.103L21.27 6.091a9.672 9.672 0 014.105.534zm-19.08 5.703a10.478 10.478 0 014.896-2.031 23.977 23.977 0 00-2.84 4.088zm.47 12.906a8.388 8.388 0 01.942-7.251l1.405 1.406c-1.538 1.726-2.018 3.638-1.079 4.578a1.768 1.768 0 001.29.484 5.362 5.362 0 003.294-1.557l1.4 1.4a8.387 8.387 0 01-7.251.94zm12.907.472l-2.057-2.057a23.995 23.995 0 004.089-2.84 10.466 10.466 0 01-2.032 4.897z" />
      <path d="M21.469 10.531a3.498 3.498 0 100 4.95 3.508 3.508 0 000-4.95zm-.707 4.243a2.499 2.499 0 110-3.536 2.505 2.505 0 010 3.536z" />
    </SvgContainer>
  );
}
