import React, { useState } from 'react';
import parseMarkdown from 'helpers/parseMarkdown';
import WYSIWYG from 'components/WYSIWYG';
import Button from 'components/inputs/Button';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import PropTypes from 'prop-types';
import PageSection from './PageSection';

const ExpandableDropdownItem = ({
  header, body, questionBankName, activeQuestionCount
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= breakpoints.sm;
  const mobileIconName = isOpen ? 'UpCaret2' : 'DownCaret2';
  const iconName = isOpen ? 'Minus' : 'Plus';
  const parsedHeader = parseMarkdown(header, { questionBankName, activeQuestionCount });
  const parsedBody = parseMarkdown(body, { questionBankName, activeQuestionCount });


  return (
    <div className="expandable-dropdown-item">
      <div className="row w-100 pr-6 d-flex align-content-center">
        <div className="item-header col-sm-10 col-10 col-md-11 col-xl-11 col-lg-11 py-5">
          <Button
            onClick={() => setIsOpen(prev => !prev)}
            styleVariant="none"
            className="text-navy"
          >
            <b className="item-header">{parsedHeader}</b>
          </Button>
        </div>
        <div className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-xs-start justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end">
          <Button
            icon={isMobile ? mobileIconName : iconName}
            styleVariant="none"
            iconProps={{
              width: 19.7, height: 19.7
            }}
            onClick={() => setIsOpen(prev => !prev)}
          />
        </div>
      </div>
      <WYSIWYG className={`dropdown-body ${isOpen ? 'mb-3 ' : 'd-none '}item-body col pt-3`}>{parsedBody}</WYSIWYG>
    </div>
  );
};

ExpandableDropdownItem.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  questionBankName: PropTypes.string,
  activeQuestionCount: PropTypes.number
};


const ExpandableDropdown = ({
  expandableDropdownGroup, questionBankName, activeQuestionCount
}) => {
  const { title, items } = expandableDropdownGroup;

  return (
    <PageSection section="expandable-dropdown">
      <h2 className="d-flex storefront-secondary-title align-self-lg-center mb-2 mb-sm-2 mb-md-2 mb-lg-3 mb-xl-3 pb-4">{parseMarkdown(title, { questionBankName, activeQuestionCount })}</h2>
      <div className="expandable-dropdown-container d-flex align-self-center flex-column w-100 py-5 mt-3 mt-sm-3 mt-md-3 mt-lg-6 mt-xl-6">
        {items.map(item => (
          <ExpandableDropdownItem
            key={item.id}
            header={item.header}
            body={item.body}
            questionBankName={questionBankName}
            activeQuestionCount={activeQuestionCount}
          />
        ))}
      </div>

    </PageSection>
  );
};

ExpandableDropdown.propTypes = {
  expandableDropdownGroup: PropTypes.shape({ title: PropTypes.string, items: PropTypes.array }),
  questionBankName: PropTypes.string,
  activeQuestionCount: PropTypes.number
};

export default ExpandableDropdown;
