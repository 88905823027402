import { Map, List, fromJS } from 'immutable';

import createReducer from 'helpers/createReducer';

import { PERFORMANCE_RESPONSES_FETCHED } from 'actions/performance';
import { QUIZ_SUBMITTED, QUIZ_UNLOAD_PERFORMANCE } from 'actions/quizzes';
import { RESPONSE_SYNCED, RESPONSE_EVALUATED } from 'actions/responses';
import { SESSION_RESET } from 'actions/session';

// Unlike our other reducers, this Map uses Strings for keys as a performance optimization
export const initialState = new Map();
const reducers = {};

const initialResponseState = new List([0, 0, 1, null, false, false]);
const ngnResultMapping = {
  "INCORRECT": 1,
  "CORRECT": 2,
  "PARTIALLYCORRECT": 3,
  "PARTIALLY CORRECT": 3
};

reducers[QUIZ_UNLOAD_PERFORMANCE] = () => initialState;

reducers[PERFORMANCE_RESPONSES_FETCHED] = function questionsFetched(state, payload) {
  // Cast all results into an Immutable Map of Immutable Maps of Immutable Lists
  const { apiResponses } = payload;
  const responsesObj = state.toJS();
  apiResponses.forEach((apiResponseResponses) => {
    apiResponseResponses.forEach((response) => {
      if (!responsesObj[response[0]]) responsesObj[response[0]] = {};
      responsesObj[response[0]][response[1]] = [
        response[2], // ms_spent
        response[3], // extra_ms_spent
        response[4] * 1000, // answered_at
        response[5] === -1 ? null : response[5] === 1, // correct
        response[6] === 1, // answered
        response[7] === 1, // user_evaluated
        response[8] === 1, // is_aact
        response[9], // potential_score
        response[10], // actual score
        response[11], // score result
        response[12] === 1 // can submit
      ];
    });
  });
  return fromJS(responsesObj);
};


reducers[RESPONSE_SYNCED] = function responseSynced(state, payload) {
  const { quizId, questionId, data: responseJson } = payload;
  const newResponseState = state.getIn([quizId.toString(), questionId.toString()], initialResponseState);

  return state.setIn([quizId.toString(), questionId.toString()], newResponseState.set(0, responseJson.attributes.ms_spent)
    .set(2, Date.parse(responseJson.attributes.answered_at))
    .set(3, responseJson.attributes.correct === null ? null : !!responseJson.attributes.correct)
    .set(4, !!responseJson.attributes.user_input || responseJson.attributes.answer !== '{}')
    .set(10, responseJson.attributes.can_submit));
};

// TODO: Reduce RESPONSE_EVALUATED_LOCAL

reducers[RESPONSE_EVALUATED] = function responseEvaluated(state, payload) {
  const { quizId, questionId, data: responseJson } = payload;
  const newResponseState = state.getIn([quizId.toString(), questionId.toString()], initialResponseState);

  return state.setIn([quizId.toString(), questionId.toString()], newResponseState.set(0, responseJson.attributes.ms_spent)
    .set(2, Date.parse(responseJson.attributes.answered_at))
    .set(3, responseJson.attributes.correct === null ? null : !!responseJson.attributes.correct)
    .set(4, !!responseJson.attributes.user_input || responseJson.attributes.answer !== '{}')
    .set(5, true)
    .set(10, responseJson.attributes.can_submit));
};

// TODO: Reduce RESPONSE_SYNC_OFFLINE

reducers[QUIZ_SUBMITTED] = function quizSubmitted(state, payload) {
  const { quizId, included: includedJson } = payload;
  const responsesJSON = includedJson.filter(json => json.type === 'response');
  const quizState = state.get(quizId.toString(), new Map()).withMutations((mutableQuizState) => {
    responsesJSON.forEach((responseJson) => {
      const questionId = responseJson.relationships.question.data.id;
      const newResponseState = mutableQuizState.get(questionId, initialResponseState)
        .set(2, Date.parse(responseJson.attributes.answered_at))
        .set(3, !!responseJson.attributes.correct)
        .set(6, responseJson.attributes.is_aact)
        .set(7, parseInt(responseJson.attributes.derived_potential_score))
        .set(8, parseInt(responseJson.attributes.derived_actual_score))
        .set(9, ngnResultMapping[responseJson.attributes.result])
        .set(10, responseJson.attributes.can_submit);
      mutableQuizState.set(questionId, newResponseState);
    });
  });

  return state.set(quizId.toString(), quizState);
};

reducers[SESSION_RESET] = () => initialState;


export default createReducer(reducers, initialState);
