import Factory from 'factories/_Factory';

export default class Report extends Factory {
  static jsonType = 'class_report';

  static get defaults() {
    return {
      report_id: null,
      class_id: null,
      status: null,
      national_median_all: null,
      national_median_test: null,
      org_median_all: null,
      org_median_test: null,
      peer_results_all: [],
      peer_results_test: [],
      report_data: null,
    };
  }

  static get belongsTo() {
    return ['organization'];
  }
}
