import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredReverseColor(props) {
  return (
    <SvgContainer title="ReverseColor Icon" {...props}>
      <path d="M18 30c0 .1 0 .1 0 0-.1.1-.1.1 0 0h-.1c0 .1.1.1.1 0z" /><path d="M18.2 30.3c7.9-1.2 13.3-8.5 12.1-16.4C29.1 6 21.7.6 13.9 1.8S.6 10.3 1.8 18.2s8.5 13.3 16.4 12.1z" /><path d="M27.6 7.4C26 5.2 23.8 3.5 21 2.5c-7.4-2.8-15.8 1-18.6 8.4-1.7 4.7-.9 9.6 1.8 13.4L27.6 7.4z" fill="#fff" />
    </SvgContainer>
  );
}
