import Factory from 'factories/_Factory';


export default class OrganizationalSubscription extends Factory {
  static jsonType = 'organizational_subscription';

  static afterUpdate(record, json) {
    let newRecord = record;
    if (json.attributes.expiry_date || newRecord.get('expiry_date') === undefined) {
      newRecord = newRecord.set('expiry_date', new Date(json.attributes.expiry_date).getTime());
    }
    return newRecord;
  }

  static get hasMany() {
    return ['question_bank', 'organization'];
  }

  static get defaults() {
    return {
      has_premium_features: false
    };
  }
}
