import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeStatTrustedInstitutions(props) {
  return (
    <SvgContainer title="Home Stat Trusted Institutions Icon" {...props}>
      <path d="M11.448 40.184c-.25 0-.5-.124-.667-.355-2.916-4.063-5.066-9.562-6.05-15.488-.982-5.943-.732-11.887.7-16.766.134-.46.6-.727 1.05-.585.434.142.684.639.55 1.118-1.35 4.612-1.583 10.254-.65 15.914.934 5.66 2.967 10.893 5.734 14.725.283.39.216.94-.15 1.242a.787.787 0 0 1-.5.177l-.017.018Z" fill="#FDA100" />
      <path d="m11.966 39.989-1.017-1.402c.45-.373.834-.994 1.15-1.88.7-1.97.784-4.081.233-6.05-1.233 1.17-2.483 2.501-2.833 4.027l-1.616-.426c.516-2.289 2.383-4.027 3.883-5.429l1.25-1.17.466 1.117c1.117 2.714 1.184 5.748.2 8.569-.433 1.206-.983 2.076-1.683 2.661l-.033-.017ZM7.316 28.35l-.733-1.597c.517-.266 1-.78 1.483-1.597C9.15 23.33 9.65 21.29 9.533 19.284 8.1 20.17 6.617 21.182 6 22.602l-1.5-.763c.95-2.13 3.1-3.424 4.833-4.471l1.433-.87.234 1.19c.566 2.838.016 5.836-1.534 8.426-.666 1.1-1.35 1.81-2.15 2.236ZM4.82 14.99c-1.284-.585-2.35-1.738-3.017-3.246-.533-1.206-.8-2.643-.833-4.276-.033-2.341.7-4.364 1.383-6.085l.55-1.366 1.183 1.58c1.217 1.614 2.733 3.6 2.967 6.049l-1.65.177C5.253 6.227 4.32 4.772 3.37 3.477c-.484 1.366-.75 2.608-.734 3.974.017 1.384.25 2.59.667 3.548.483 1.1 1.267 1.97 2.167 2.377l-.667 1.633.017-.018Z" fill="#FDA100" />
      <path d="m5.546 15.17-.366-1.722c1.666-.408 3.2-1.472 4.216-2.963.85-1.26 1.383-2.75 1.5-4.133-1.55.532-3.166 1.17-4.1 2.36L5.513 7.575c1.367-1.739 3.633-2.502 5.466-3.123l1.467-.496.1 1.1c.183 2.11-.483 4.541-1.817 6.475-1.233 1.828-3.133 3.158-5.2 3.655l.017-.018ZM6.067 28.759c-1 0-2.033-.408-2.9-1.136-.766-.656-1.416-1.543-1.983-2.732-1.017-2.164-1.4-4.808-1.067-7.487l.184-1.384 1.5 1.047c1.566 1.065 3.5 2.395 4.383 4.524l-1.517.728c-.567-1.349-1.783-2.36-3-3.211-.033 1.81.3 3.566 1 5.02.45.958.95 1.65 1.533 2.147.65.55 1.417.816 2.1.745l.167 1.757c-.133 0-.267.017-.4.017v-.035ZM9.935 40.36c-.866 0-1.75-.177-2.566-.514-2.667-1.1-4.167-3.796-5.416-6.563l-.617-1.384 1.9.142c1.85.142 4.166.32 5.9 1.72l-1.017 1.402c-1.083-.887-2.567-1.153-4.017-1.277.967 1.898 2.134 3.583 3.85 4.293 1.033.425 2.2.496 3.267.213l.4 1.72a7.026 7.026 0 0 1-1.717.231l.033.018ZM33.552 40.184a.756.756 0 0 1-.5-.177.918.918 0 0 1-.15-1.242c2.75-3.832 4.783-9.066 5.733-14.725.95-5.66.7-11.32-.65-15.914-.133-.462.1-.958.55-1.118.433-.142.9.106 1.05.585 1.433 4.88 1.683 10.823.7 16.766-.983 5.943-3.133 11.443-6.05 15.488a.828.828 0 0 1-.666.355l-.017-.018Z" fill="#FDA100" />
      <path d="M33.035 39.99c-.7-.586-1.25-1.456-1.683-2.662-1-2.803-.934-5.837.2-8.57l.466-1.117 1.25 1.171c1.5 1.402 3.35 3.14 3.883 5.429l-1.616.426c-.35-1.526-1.6-2.839-2.834-4.028-.533 1.97-.466 4.08.234 6.05.316.887.683 1.508 1.15 1.88l-1.017 1.402-.033.018ZM37.683 28.35c-.817-.426-1.5-1.135-2.15-2.235-1.55-2.59-2.083-5.589-1.533-8.427l.233-1.189 1.433.87c1.733 1.046 3.883 2.341 4.833 4.47l-1.5.763c-.633-1.42-2.1-2.43-3.533-3.318-.133 2.005.383 4.046 1.467 5.873.483.816.95 1.33 1.483 1.596l-.733 1.597ZM40.187 14.992l-.666-1.633c.9-.408 1.683-1.277 2.166-2.377.434-.976.65-2.165.667-3.548.017-1.366-.25-2.59-.733-3.974-.967 1.312-1.884 2.75-2.034 4.346l-1.65-.177c.234-2.449 1.75-4.436 2.967-6.05L42.087 0l.55 1.366c.684 1.721 1.417 3.744 1.383 6.085-.016 1.633-.3 3.087-.833 4.276-.666 1.508-1.733 2.661-3.016 3.247l.016.018Z" fill="#FDA100" />
      <path d="M39.452 15.17c-2.067-.498-3.95-1.828-5.2-3.655-1.316-1.934-2-4.365-1.816-6.476l.1-1.1 1.466.497c1.833.62 4.1 1.384 5.466 3.122l-1.283 1.136c-.933-1.189-2.566-1.828-4.1-2.36.117 1.402.65 2.874 1.5 4.134 1 1.472 2.534 2.555 4.217 2.963l-.367 1.72.017.018ZM38.931 28.759c-.133 0-.266 0-.4-.018l.167-1.756c.683.088 1.45-.196 2.1-.746.583-.496 1.083-1.188 1.533-2.146.683-1.455 1.033-3.194 1-5.021-1.217.87-2.433 1.863-3 3.211l-1.516-.727c.883-2.13 2.833-3.46 4.383-4.524l1.5-1.047.183 1.384c.333 2.679-.034 5.34-1.05 7.486-.567 1.19-1.217 2.076-1.983 2.733-.867.727-1.9 1.135-2.9 1.135l-.017.036ZM35.068 40.361c-.583 0-1.15-.07-1.716-.23l.4-1.721a5.155 5.155 0 0 0 3.266-.213c1.716-.71 2.883-2.395 3.85-4.293-1.434.142-2.917.408-4.017 1.277l-1.016-1.401c1.733-1.42 4.05-1.58 5.9-1.721l1.9-.142-.617 1.384c-1.234 2.767-2.75 5.463-5.417 6.563a6.712 6.712 0 0 1-2.566.515l.033-.018ZM19.713 13.696c-.1 0-.183 0-.283-.053-.433-.16-.65-.674-.5-1.135l2.783-8.197a.822.822 0 0 1 1.067-.532c.433.16.65.674.5 1.135l-2.783 8.197a.847.847 0 0 1-.784.585Z" fill="#FDA100" />
      <path d="M25.278 13.696a.847.847 0 0 1-.783-.585L21.71 4.914c-.15-.46.067-.975.5-1.135a.822.822 0 0 1 1.067.532l2.783 8.197c.15.461-.067.976-.5 1.135a.8.8 0 0 1-.283.053ZM19.711 13.696h-5.55c-.466 0-.833-.39-.833-.887 0-.496.367-.887.833-.887h5.55c.467 0 .833.39.833.887s-.366.887-.833.887Z" fill="#FDA100" />
      <path d="M18.33 18.61a.806.806 0 0 1-.617-.301l-4.167-4.914a.92.92 0 0 1 .067-1.26.807.807 0 0 1 1.183.071l4.166 4.914a.92.92 0 0 1-.066 1.26.797.797 0 0 1-.55.23h-.017Z" fill="#FDA100" />
      <path d="M16.948 26.807h-.15c-.45-.088-.75-.55-.667-1.029l1.383-8.196c.084-.48.517-.799.967-.71.45.089.75.55.667 1.03l-1.384 8.196a.84.84 0 0 1-.816.727v-.018Z" fill="#FDA100" />
      <path d="M16.947 26.807a.834.834 0 0 1-.65-.32.9.9 0 0 1 .117-1.24l5.55-4.915a.777.777 0 0 1 1.166.124.9.9 0 0 1-.117 1.242l-5.55 4.914a.789.789 0 0 1-.533.213l.017-.018Z" fill="#FDA100" />
      <path d="M28.047 26.806a.789.789 0 0 1-.533-.213l-5.55-4.914a.935.935 0 0 1-.117-1.242c.3-.372.817-.425 1.167-.124l5.55 4.914c.35.32.4.87.116 1.242a.81.81 0 0 1-.65.32l.017.017Z" fill="#FDA100" />
      <path d="M28.05 26.807a.84.84 0 0 1-.817-.727l-1.383-8.197c-.084-.479.216-.94.666-1.029.467-.089.884.23.967.71l1.383 8.196c.084.48-.216.94-.666 1.03h-.15v.017ZM30.828 13.696h-5.55c-.466 0-.833-.39-.833-.887 0-.496.367-.887.834-.887h5.55c.466 0 .832.39.832.887s-.366.887-.833.887Z" fill="#FDA100" />
      <path d="M26.664 18.61c-.2 0-.4-.07-.55-.23a.937.937 0 0 1-.067-1.26l4.167-4.913a.806.806 0 0 1 1.183-.071c.35.337.367.887.067 1.26l-4.167 4.913a.806.806 0 0 1-.616.302h-.017ZM25.828 41H19.16c-.466 0-.833-.39-.833-.887s.367-.887.833-.887h6.667c.466 0 .833.39.833.887s-.367.887-.833.887Z" fill="#FDA100" />
      <path d="M22.497 41c-.466 0-.833-.39-.833-.888v-7.096c0-.497.367-.887.833-.887.467 0 .834.39.834.887v7.096c0 .497-.367.888-.834.888Z" fill="#FDA100" />
      <path d="M26.663 33.903h-8.332c-.917 0-1.667-.798-1.667-1.774v-1.774c0-.976.75-1.774 1.667-1.774h8.332c.917 0 1.667.798 1.667 1.774v1.774c0 .976-.75 1.774-1.667 1.774Zm-8.332-3.548v1.774h8.332v-1.774h-8.332Z" fill="#FDA100" />
    </SvgContainer>
  );
}
