import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgEndBlock(props) {
  return (
    <SvgContainer title="End Block Icon" {...props}>
      <defs>
        <style type="text/css">
          {".end-st1{fill:#ed1e27}"}
        </style>
      </defs>
      <path fill="#fff" d="M10 2.3h12.1l8.3 8.3v12.2L22 31.2H10l-8.4-8.5v-12z" /><path className="end-st1" d="M22.1 2.3H10l-8.4 8.4v12l8.4 8.4h12l8.4-8.4V10.6l-8.3-8.3zm7 19.9l-7.6 7.6H10.6L3 22.2V11.3l7.6-7.6h11l7.5 7.5v11z" /><path className="end-st1" d="M27.7 11.7v9.9l-6.8 6.8h-9.7l-6.9-6.8v-9.8L11.1 5H21z" />
    </SvgContainer>
  );
}
