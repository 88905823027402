import React from "react";
import SvgContainer from './SvgContainer';

const PsiCheckboxUnselected = props => (
  <SvgContainer title="Checkbox Unselected Icon" {...props}>
    <path d="M25.6 27.6H6.2c-1 0-1.9-.8-1.9-1.9V6.3c0-1 .8-1.9 1.9-1.9h19.5c1 0 1.9.8 1.9 1.9v19.4c-.1 1-1 1.9-2 1.9z" fill="#fff" />
    <path d="M25.6 29.1H6.2c-1.9 0-3.4-1.5-3.4-3.4V6.3c0-1.9 1.5-3.4 3.4-3.4h19.4c1.9 0 3.4 1.5 3.4 3.4v19.4c0 1.9-1.6 3.4-3.4 3.4zM6.2 4.4c-1 0-1.9.9-1.9 1.9v19.4c0 1 .9 1.9 1.9 1.9h19.4c1 0 1.9-.9 1.9-1.9V6.3c0-1-.9-1.9-1.9-1.9H6.2z" fill="#bab9ba" />
  </SvgContainer>
);

export default PsiCheckboxUnselected;
