import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FollowIcons = () => (
  <>
    <h2>FOLLOW</h2>
    <ul className="social-icon">
      <li className="d-inline-flex justify-content-center align-items-center rounded-circle my-4 mr-4 mr-lg-3">
        <a href="https://www.facebook.com/boardvitals" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" /></a>
      </li>
      <li className="d-inline-flex justify-content-center align-items-center rounded-circle my-4 mr-4 mr-lg-3">
        <a href="https://twitter.com/BoardVitals" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" /></a>
      </li>
      <li className="d-inline-flex justify-content-center align-items-center rounded-circle my-4 mr-4 mr-lg-3">
        <a href="https://www.instagram.com/boardvitals/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" /></a>
      </li>
      <li className="d-inline-flex justify-content-center align-items-center rounded-circle my-4 mr-4 mr-lg-3">
        <a href="https://www.linkedin.com/company/board-vitals-llc" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a>
      </li>
      <li className="d-inline-flex justify-content-center align-items-center rounded-circle my-4">
        <a href="https://vimeo.com/boardvitals" target="_blank" rel="noopener noreferrer"><i className="fa fa-vimeo" /></a>
      </li>
    </ul>
  </>
);

export default function NavigationFooterClass ({ signedIn, updatedHours, ensightenEnabled }) {
  const [currentYear] = useState(new Date().getFullYear());

  const openEnsightenModal = () => {
    /* eslint-disable-next-line no-undef */
    Bootstrapper.gateway.openModal();
  };
  return (
    <footer className="NavigationFooter bg-navy" id="footer">
      <div className="container bg-navy text-white py-6">
        <div className="row d-flex justify-content-md-center">
          <div className="col-12">
            <div className="row d-flex flex-wrap pt-6">
              <div className="col-lg-3 col-md-4 col-sm-4 col-6 mb-5">
                <h2>SUPPORT</h2>
                <ul className="my-4">
                  <li><a href="https://info.boardvitals.com/knowledge" target="_blank" rel="noopener noreferrer">Customer Support</a></li>
                  <li><a href="https://info.boardvitals.com/knowledge/frequently-asked-questions" target="_blank" rel="noopener noreferrer">Help FAQs</a></li>
                  <li><a href="mailto:support@boardvitals.com">support@boardvitals.com</a></li>
                  <li>
                    <a href="tel:+18772211529">(877) 221-1529</a>
                    <ul className="sub-menu-footer">
                      <li>{`Weekdays 9AM - ${updatedHours ? '7' : '6'}PM`}</li>
                      <li>All times in EST</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-6 mb-5">
                <h2>COMPANY</h2>
                <ul className="my-4">
                  <li><a href="/about">About BoardVitals</a></li>
                  <li><a href="/testimonials">Testimonials</a></li>
                  <li><a href="/institutional">Institutions</a></li>
                  <li><a href="https://info.boardvitals.com/boardvitals-affiliate-program" target="_blank" rel="noopener noreferrer">Become an Affiliate</a></li>
                  <li><a href="/givevax">#GiveVax Initiative</a></li>
                  <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                  <li><a href="https://auth.boardvitals.com/policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                  <li><a href="https://auth.boardvitals.com/policy.html#privacy_information_ca" target="_blank" rel="noopener noreferrer">CA Privacy Notice</a></li>
                  {ensightenEnabled && <li><a href="#" id="openmodale" onClick={openEnsightenModal}>Your Privacy Choices</a></li>}
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-6 mb-5">
                <h2>RESOURCES</h2>
                <ul className="my-4">
                  <li><a href="/blog/">BoardVitals Blog</a></li>
                  <li><a href="/what-is-cme">What is CME?</a></li>
                  <li><a href="/cme-coach">CME Coach</a></li>
                  <li><a href="/understanding-the-nclex">Understanding the NCLEX<sup>&reg;</sup></a></li>
                  {signedIn && (<li><a href="app/referrals">Refer a Friend</a></li>)}
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-6 mb-5">
                <h2>MOBILE APP</h2>
                <a className="mobile-app-badge d-block" href="https://apps.apple.com/us/app/boardvitals-medical-exam-prep/id1484690932" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid d-block apple-badge" loading="lazy" alt="Apple iOS BoardVitals App" src="/images/apple-ios-boardvitals-app.svg" width="140px" height="50px" />
                </a>
                <a className="mobile-app-badge d-block" href="https://play.google.com/store/apps/details?id=com.boardvitals&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid d-block android-badge" loading="lazy" alt="Android BoardVitals App" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" width="140px" height="50px" />
                </a>
                <div className="d-none d-lg-block mt-5">
                  <FollowIcons />
                </div>
              </div>
              <div className="col-md-8 col d-lg-none">
                <FollowIcons />
              </div>
            </div>
            <div className="row pt-2 pb-6">
              <div className="col text-center">
                <p className=" m-0">Copyright &copy; BoardVitals {currentYear}. All rights reserved.</p>
                <p className=" m-0">BoardVitals.com has a Shopper Approved rating of 4.6/5 based on 24594 ratings and reviews.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

NavigationFooterClass.propTypes = {
  signedIn: PropTypes.bool,
  updatedHours: PropTypes.bool,
  ensightenEnabled: PropTypes.bool
};
