import Factory from 'factories/_Factory';


export default class UserType extends Factory {
  static jsonType = 'user_type';

  static jsonTypePlural = 'user_types';

  static get defaults() {
    return {
      name: ''
    };
  }
}
