import {
  useRef, useCallback, useState, useEffect
} from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';

import { useCurrentUser } from 'hooks/users';

export function useFirstViewableListItemIndex(onScroll, values = []) {
  const refContainer = useRef(null);
  const visibleListItem = listItem => listItem.offsetTop >= Math.floor(refContainer.current.scrollTop);

  const onTableScroll = () => {
    if (!refContainer.current) return;
    const firstListItem = ([...refContainer.current.children].findIndex(visibleListItem));
    onScroll(firstListItem);
  };

  const debouncedScroll = useCallback(debounce(onTableScroll, 100), [refContainer.current, ...values]);

  return { refContainer, debouncedScroll };
}

// https://usehooks.com/useLocalStorage/
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Added to ensure state is updated amongst components
  useEffect(() => {
    function checkLocalData() {
      const item = window.localStorage.getItem(key);
      if (item) {
        setStoredValue(JSON.parse(item));
      }
    }

    window.addEventListener('storage', checkLocalData);

    return () => {
      window.removeEventListener('storage', checkLocalData);
    };
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));

      // Added as this value won't actually update between multiple components
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useServerTime() {
  const staticData = useSelector(state => state.staticData);
  const timeDifference = staticData.get('time_difference');
  return () => DateTime.now().plus({ milliseconds: timeDifference });
}

export const useHasSeenAlertPopup = () => {
  const user = useCurrentUser();
  const key = `${user.get('id')}-case-modal-alert`;
  const [hasSeenAlert, setHasSeenAlert] = useLocalStorage(key);
  return [hasSeenAlert || false, () => setHasSeenAlert(true)];
};
