import React from "react";
import SvgContainer from './SvgContainer';

function CreditCard (props) {
  return (
    <SvgContainer name="CreditCard" height={20} width={20} viewBoxHeight={25} viewBoxWidth={25} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M22 3c.53 0 1.039.211 1.414.586s.586.884.586 1.414v14c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586h-20c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-14c0-.53.211-1.039.586-1.414s.884-.586 1.414-.586h20zm1 8h-22v8c0 .552.448 1 1 1h20c.552 0 1-.448 1-1v-8zm-15 5v1h-5v-1h5zm13-2v1h-3v-1h3zm-10 0v1h-8v-1h8zm-10-6v2h22v-2h-22zm22-1v-2c0-.552-.448-1-1-1h-20c-.552 0-1 .448-1 1v2h22z" />
    </SvgContainer>
  );
}

export default CreditCard;
