import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeStatCME(props) {
  return (
    <SvgContainer title="Home Stat CME Icon" {...props}>
      <g>
        <path d="M11.493 41a1.11 1.11 0 0 1-.514-.115c-.676-.317-1.027-1.154-.73-1.874l6.491-16.695-7.654-3.373-6.491 16.694c-.27.75-1.082 1.096-1.758.779-.676-.317-1.028-1.154-.73-1.874l7.519-19.376 12.657 5.593-7.519 19.376c-.216.548-.73.894-1.244.894L11.493 41Z" fill="#FDA100" />
        <path d="M11.49 41c-.595 0-1.163-.432-1.299-1.096L7.92 30.447c-.189-.778.244-1.557.974-1.758.73-.202 1.46.259 1.65 1.038l2.272 9.457c.19.778-.243 1.556-.974 1.758a1.279 1.279 0 0 1-.324.058h-.027Z" fill="#FDA100" />
        <path d="M1.35 36.531c-.46 0-.92-.26-1.164-.72a1.518 1.518 0 0 1 .487-1.99l7.87-4.96c.65-.403 1.461-.172 1.867.52a1.518 1.518 0 0 1-.487 1.99l-7.87 4.958c-.217.145-.46.202-.677.202H1.35ZM25.505 41c-.54 0-1.027-.346-1.244-.894l-7.519-19.375L29.4 15.137l7.52 19.376c.297.75-.055 1.586-.731 1.874-.676.288-1.488-.058-1.758-.779L27.94 18.914l-7.655 3.374 6.491 16.694c.298.75-.053 1.586-.73 1.874a1.11 1.11 0 0 1-.514.115l-.027.03Z" fill="#FDA100" />
        <path d="M35.65 36.531c-.244 0-.46-.057-.676-.202l-7.871-4.959c-.65-.404-.866-1.297-.487-1.99.379-.691 1.217-.922 1.866-.518l7.87 4.959c.65.404.866 1.297.488 1.99-.244.46-.704.72-1.163.72h-.027Z" fill="#FDA100" />
        <path d="M25.507 41s-.217 0-.325-.058c-.73-.202-1.163-.98-.973-1.758l2.271-9.457c.19-.779.92-1.24 1.65-1.038.73.201 1.163.98.974 1.758l-2.272 9.457c-.162.663-.703 1.096-1.298 1.096h-.027Z" fill="#FDA100" />
        <path d="M18.497 24.854c6.064 0 10.98-5.241 10.98-11.706 0-6.466-4.916-11.707-10.98-11.707-6.065 0-10.981 5.241-10.981 11.707 0 6.465 4.916 11.706 10.98 11.706Z" fill="#fff" />
        <path d="M18.497 26.295c-6.815 0-12.333-5.91-12.333-13.147S11.71 0 18.497 0c6.79 0 12.334 5.91 12.334 13.148 0 7.237-5.545 13.147-12.334 13.147Zm0-23.412c-5.3 0-9.628 4.613-9.628 10.265 0 5.65 4.327 10.264 9.628 10.264 5.302 0 9.629-4.613 9.629-10.264 0-5.652-4.327-10.265-9.629-10.265Z" fill="#FDA100" />
      </g>
    </SvgContainer>
  );
}
