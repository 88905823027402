import React from 'react';
import PropTypes from "prop-types";
import SvgContainer from './SvgContainer';

function Institution (props) {
  const { small, medium } = props;

  if (small) {
    return (
      <SvgContainer name="institution" height={94} width={94} viewBoxHeight={94} viewBoxWidth={94} {...props}>
        <circle cx="46.9" cy="46.9" r="46.9" fill="#FFF2DB" />
        <g clipPath="url(#clip0_525_3029)">
          <path d="M32.9025 71.0376C32.5774 71.0376 32.2522 70.8853 32.0354 70.6025C28.2417 65.6205 25.4452 58.8763 24.1662 51.61C22.8872 44.3219 23.2124 37.0339 25.0767 31.0511C25.2501 30.4855 25.8571 30.1592 26.4424 30.3332C27.0061 30.5072 27.3312 31.1164 27.1578 31.7038C25.4019 37.3602 25.0984 44.2784 26.3123 51.2184C27.5263 58.1584 30.1711 64.5762 33.7697 69.2754C34.1382 69.754 34.0515 70.4285 33.5746 70.7983C33.3794 70.9506 33.141 71.0159 32.9242 71.0159L32.9025 71.0376Z" fill="#1B3950" />
          <path d="M33.5745 70.7983L32.2522 69.0796C32.8375 68.6228 33.3361 67.8613 33.748 66.7736C34.6584 64.3587 34.7668 61.7698 34.0515 59.355C32.4473 60.7908 30.8214 62.4225 30.3662 64.2934L28.2634 63.7713C28.9354 60.9649 31.3634 58.8328 33.3144 57.1141L34.9403 55.6783L35.5473 57.0489C36.9997 60.3775 37.0864 64.0976 35.8074 67.5567C35.2438 69.0361 34.5284 70.1021 33.6179 70.8201L33.5745 70.7983Z" fill="#1B3950" />
          <path d="M27.5264 56.5268L26.5725 54.5688C27.2445 54.2424 27.8732 53.6115 28.5019 52.6108C29.911 50.37 30.5613 47.8681 30.4096 45.4097C28.5452 46.4975 26.6159 47.7376 25.8138 49.478L23.8627 48.5425C25.0984 45.9319 27.8949 44.3437 30.1494 43.0602L32.0137 41.9941L32.3172 43.4518C33.0543 46.9326 32.3389 50.6093 30.3228 53.7856C29.4557 55.1344 28.5669 56.0046 27.5264 56.5268V56.5268Z" fill="#1B3950" />
          <path d="M24.2746 40.1449C22.6054 39.427 21.218 38.0129 20.3508 36.1637C19.6571 34.6843 19.3103 32.9221 19.2669 30.9206C19.2236 28.0489 20.1774 25.5688 21.0662 23.4585L21.7816 21.7833L23.3208 23.7196C24.9033 25.6993 26.876 28.1359 27.1795 31.1382L25.0333 31.3557C24.8382 29.3977 23.6243 27.6138 22.3886 26.0256C21.7599 27.7008 21.4131 29.2237 21.4348 30.8988C21.4564 32.5958 21.7599 34.0751 22.3019 35.2499C22.9305 36.5988 23.9494 37.6648 25.12 38.1652L24.2529 40.1667L24.2746 40.1449Z" fill="#1B3950" />
          <path d="M25.2284 40.3625L24.7515 38.2522C26.9193 37.7518 28.9137 36.4465 30.2361 34.619C31.3417 33.0744 32.0354 31.2469 32.1872 29.55C30.1711 30.2027 28.0683 30.9859 26.8543 32.4435L25.1851 31.0511C26.9627 28.9191 29.9109 27.9836 32.2955 27.2222L34.2032 26.613L34.3333 27.9619C34.5718 30.5508 33.7046 33.5313 31.9704 35.9026C30.3662 38.1434 27.8949 39.7751 25.2068 40.3842L25.2284 40.3625Z" fill="#1B3950" />
          <path d="M25.9004 57.0271C24.5997 57.0271 23.2557 56.5268 22.1284 55.6348C21.1312 54.8298 20.2858 53.7421 19.5487 52.2845C18.2263 49.6303 17.7277 46.3887 18.1613 43.1037L18.3998 41.4067L20.3508 42.6903C22.3886 43.9956 24.9032 45.6273 26.0522 48.2379L24.0795 49.1299C23.3424 47.4765 21.7599 46.2364 20.1774 45.1922C20.134 47.4112 20.5676 49.565 21.4781 51.349C22.0634 52.5238 22.7137 53.3722 23.4725 53.9814C24.3179 54.6558 25.3151 54.9821 26.2039 54.8951L26.4207 57.0489C26.2473 57.0489 26.0739 57.0706 25.9004 57.0706V57.0271Z" fill="#1B3950" />
          <path d="M30.9298 71.2552C29.8025 71.2552 28.6536 71.0376 27.5914 70.6242C24.1228 69.2754 22.1718 65.9686 20.5459 62.5747L19.7438 60.8778L22.2152 61.0519C24.6214 61.2259 27.6347 61.4435 29.8892 63.1621L28.5669 64.8808C27.1578 63.793 25.2284 63.4667 23.3424 63.3144C24.5998 65.6422 26.1172 67.709 28.3501 68.5792C29.6941 69.1014 31.2116 69.1884 32.599 68.8403L33.1193 70.9506C32.3822 71.1246 31.6452 71.2334 30.8864 71.2334L30.9298 71.2552Z" fill="#1B3950" />
          <path d="M61.6479 71.0376C61.4094 71.0376 61.1926 70.9724 60.9975 70.8201C60.5206 70.4502 60.4339 69.7758 60.8024 69.2972C64.3793 64.598 67.0241 58.1802 68.2597 51.2402C69.4954 44.3002 69.1702 37.3602 67.4143 31.7256C67.2409 31.1599 67.5443 30.5508 68.1297 30.355C68.6933 30.1809 69.3003 30.4855 69.4954 31.0729C71.3597 37.0557 71.6849 44.3437 70.4059 51.6318C69.1269 58.9199 66.3304 65.6641 62.5367 70.6243C62.3199 70.9071 61.9947 71.0594 61.6695 71.0594L61.6479 71.0376Z" fill="#1B3950" />
          <path d="M60.9758 70.7983C60.0653 70.0804 59.3499 69.0144 58.7863 67.535C57.4856 64.0977 57.5723 60.3775 59.0465 57.0271L59.6534 55.6566L61.2793 57.0924C63.2303 58.8111 65.6366 60.9431 66.3303 63.7496L64.2275 64.2717C63.7723 62.4007 62.1464 60.7908 60.5423 59.3332C59.8486 61.7481 59.9353 64.337 60.8457 66.7518C61.2576 67.8396 61.7346 68.601 62.3415 69.0579L61.0192 70.7766L60.9758 70.7983Z" fill="#1B3950" />
          <path d="M67.0241 56.5268C65.9618 56.0046 65.073 55.1344 64.2276 53.7856C62.2115 50.6093 61.5178 46.9326 62.2332 43.4518L62.5367 41.9941L64.401 43.0602C66.6555 44.3437 69.452 45.9319 70.6877 48.5425L68.7366 49.478C67.9129 47.7376 66.0052 46.4975 64.1409 45.4097C63.9674 47.8681 64.6395 50.37 66.0485 52.6108C66.6772 53.6115 67.2842 54.2424 67.9779 54.5688L67.0241 56.5268V56.5268Z" fill="#1B3950" />
          <path d="M70.2758 40.1449L69.4087 38.1434C70.5793 37.6431 71.5982 36.577 72.2268 35.2282C72.7905 34.0317 73.0723 32.574 73.094 30.8771C73.1156 29.202 72.7688 27.7008 72.1401 26.0039C70.8828 27.6138 69.6905 29.376 69.4954 31.334L67.3492 31.1164C67.6527 28.1142 69.6255 25.6776 71.208 23.6978L72.7471 21.7616L73.4625 23.4368C74.3513 25.547 75.3051 28.0272 75.2618 30.8989C75.2401 32.9004 74.8716 34.6843 74.1779 36.1419C73.3108 37.9911 71.9233 39.4052 70.2541 40.1232L70.2758 40.1449Z" fill="#1B3950" />
          <path d="M69.322 40.3625C66.6339 39.7533 64.1842 38.1217 62.5584 35.8809C60.8458 33.5095 59.957 30.529 60.1954 27.9401L60.3255 26.5913L62.2332 27.2005C64.6178 27.9619 67.566 28.8974 69.3436 31.0294L67.6744 32.4218C66.4604 30.9642 64.336 30.181 62.3416 29.5283C62.4933 31.247 63.187 33.0527 64.2926 34.5973C65.5933 36.403 67.5877 37.7301 69.7772 38.2305L69.3003 40.3407L69.322 40.3625Z" fill="#1B3950" />
          <path d="M68.6499 57.0271C68.4765 57.0271 68.3031 57.0271 68.1296 57.0054L68.3464 54.8516C69.2352 54.9604 70.2324 54.6123 71.0779 53.9379C71.8366 53.3287 72.487 52.4802 73.0723 51.3055C73.9611 49.5215 74.4163 47.3895 74.373 45.1487C72.7905 46.2147 71.2079 47.433 70.4709 49.0864L68.4982 48.1944C69.6471 45.5838 72.1835 43.9521 74.1995 42.6468L76.1506 41.3632L76.389 43.0601C76.8226 46.3452 76.3457 49.6085 75.0233 52.2409C74.2863 53.6985 73.4408 54.7863 72.4436 55.5913C71.3163 56.4832 69.9723 56.9836 68.6716 56.9836L68.6499 57.0271Z" fill="#1B3950" />
          <path d="M63.6206 71.2552C62.8618 71.2552 62.1248 71.1682 61.3877 70.9724L61.908 68.8621C63.3171 69.2102 64.8129 69.1232 66.1569 68.601C68.3898 67.7308 69.9072 65.664 71.1646 63.3362C69.3002 63.5103 67.3709 63.8366 65.9401 64.9026L64.6178 63.1839C66.8723 61.4435 69.8856 61.2477 72.2918 61.0736L74.7632 60.8996L73.9611 62.5965C72.3569 65.9904 70.3842 69.2972 66.9156 70.646C65.8534 71.0594 64.7261 71.2769 63.5772 71.2769L63.6206 71.2552Z" fill="#1B3950" />
          <path d="M43.6549 38.5568C43.5249 38.5568 43.4165 38.5568 43.2864 38.4915C42.7228 38.2957 42.4409 37.6648 42.636 37.0992L46.2563 27.0481C46.4514 26.4825 47.0801 26.1997 47.6437 26.3955C48.2074 26.5913 48.4892 27.2222 48.2941 27.7878L44.6738 37.8388C44.5221 38.2739 44.0885 38.5568 43.6549 38.5568V38.5568Z" fill="#1B3950" />
          <path d="M50.8954 38.5568C50.4619 38.5568 50.0283 38.2739 49.8766 37.8388L46.2563 27.7878C46.0612 27.2222 46.343 26.5913 46.9066 26.3955C47.4703 26.1997 48.0989 26.4825 48.294 27.0481L51.9143 37.0992C52.1094 37.6648 51.8276 38.2957 51.264 38.4915C51.1339 38.535 51.0255 38.5568 50.8954 38.5568V38.5568Z" fill="#1B3950" />
          <path d="M43.655 38.5568H36.4361C35.8292 38.5568 35.3522 38.0781 35.3522 37.469C35.3522 36.8598 35.8292 36.3812 36.4361 36.3812H43.655C44.262 36.3812 44.7389 36.8598 44.7389 37.469C44.7389 38.0781 44.262 38.5568 43.655 38.5568Z" fill="#1B3950" />
          <path d="M41.8556 44.583C41.5521 44.583 41.2703 44.4525 41.0535 44.2132L35.634 38.1869C35.2221 37.7301 35.2655 37.0557 35.7207 36.6423C36.1543 36.2507 36.848 36.2725 37.2599 36.7293L42.6794 42.7556C43.0913 43.2124 43.0479 43.8869 42.5927 44.3002C42.3759 44.496 42.1158 44.583 41.8773 44.583H41.8556Z" fill="#1B3950" />
          <path d="M40.0563 54.634C40.0563 54.634 39.9263 54.634 39.8612 54.634C39.2759 54.5253 38.8857 53.9596 38.9941 53.3722L40.7934 43.3212C40.9018 42.7338 41.4654 42.3422 42.0507 42.451C42.636 42.5598 43.0262 43.1254 42.9179 43.7128L41.1186 53.7638C41.0318 54.286 40.5766 54.6558 40.0563 54.6558V54.634Z" fill="#1B3950" />
          <path d="M40.0564 54.6341C39.7529 54.6341 39.4277 54.5035 39.2109 54.2425C38.8207 53.7856 38.8857 53.0894 39.3627 52.7196L46.5815 46.6933C47.0368 46.3017 47.7305 46.367 48.099 46.8456C48.4892 47.3025 48.4242 47.9987 47.9472 48.3685L40.7284 54.3947C40.5333 54.5688 40.2731 54.6558 40.0347 54.6558L40.0564 54.6341Z" fill="#1B3950" />
          <path d="M54.494 54.634C54.2556 54.634 53.9954 54.547 53.8003 54.373L46.5815 48.3467C46.1262 47.9551 46.0612 47.2807 46.4297 46.8238C46.8199 46.367 47.492 46.3017 47.9472 46.6715L55.166 52.6978C55.6213 53.0894 55.6863 53.7638 55.3178 54.2207C55.101 54.4817 54.7975 54.6123 54.4723 54.6123L54.494 54.634Z" fill="#1B3950" />
          <path d="M54.494 54.634C53.9738 54.634 53.5185 54.2642 53.4318 53.742L51.6325 43.691C51.5241 43.1036 51.9143 42.538 52.4997 42.4292C53.1066 42.3204 53.6486 42.712 53.757 43.2994L55.5563 53.3504C55.6647 53.9378 55.2745 54.5035 54.6892 54.6123C54.6241 54.6123 54.5591 54.6123 54.494 54.6123V54.634Z" fill="#1B3950" />
          <path d="M58.1143 38.5568H50.8954C50.2884 38.5568 49.8115 38.0781 49.8115 37.469C49.8115 36.8598 50.2884 36.3812 50.8954 36.3812H58.1143C58.7213 36.3812 59.1982 36.8598 59.1982 37.469C59.1982 38.0781 58.7213 38.5568 58.1143 38.5568Z" fill="#1B3950" />
          <path d="M52.6948 44.583C52.4346 44.583 52.1745 44.496 51.9794 44.3002C51.5241 43.8869 51.5025 43.2124 51.8927 42.7556L57.3122 36.7293C57.7241 36.2725 58.3961 36.2507 58.8514 36.6423C59.3066 37.0557 59.3283 37.7301 58.9381 38.1869L53.5185 44.2132C53.3017 44.4525 53.0199 44.583 52.7164 44.583H52.6948Z" fill="#1B3950" />
          <path d="M51.6109 72.0384H42.9396C42.3326 72.0384 41.8557 71.5598 41.8557 70.9506C41.8557 70.3415 42.3326 69.8629 42.9396 69.8629H51.6109C52.2178 69.8629 52.6948 70.3415 52.6948 70.9506C52.6948 71.5598 52.2178 72.0384 51.6109 72.0384Z" fill="#1B3950" />
          <path d="M47.2752 72.0384C46.6682 72.0384 46.1913 71.5597 46.1913 70.9506V62.2484C46.1913 61.6393 46.6682 61.1606 47.2752 61.1606C47.8822 61.1606 48.3591 61.6393 48.3591 62.2484V70.9506C48.3591 71.5597 47.8822 72.0384 47.2752 72.0384Z" fill="#1B3950" />
          <path d="M52.6947 63.3362H41.8556C40.6633 63.3362 39.6878 62.3572 39.6878 61.1607V58.9851C39.6878 57.7886 40.6633 56.8096 41.8556 56.8096H52.6947C53.887 56.8096 54.8626 57.7886 54.8626 58.9851V61.1607C54.8626 62.3572 53.887 63.3362 52.6947 63.3362ZM41.8556 58.9851V61.1607H52.6947V58.9851H41.8556Z" fill="#1B3950" />
        </g>
        <defs>
          <clipPath id="clip0_525_3029">
            <rect width="58.5312" height="50.2768" fill="white" transform="translate(18.0096 21.7616)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }
  if (medium) {
    return (
      <SvgContainer name="institution" height={96} width={96} viewBoxHeight={96} viewBoxWidth={96} {...props}>
        <circle cx="47.665" cy="47.665" r="47.665" fill="#FFF2DB" />
        <g clipPath="url(#clip0_525_3029)">
          <path d="M33.4392 72.1964C33.1087 72.1964 32.7783 72.0416 32.558 71.7542C28.7024 66.6909 25.8603 59.8367 24.5604 52.4519C23.2605 45.045 23.591 37.638 25.4857 31.5577C25.662 30.9828 26.2789 30.6512 26.8737 30.828C27.4466 31.0049 27.777 31.624 27.6008 32.221C25.8162 37.9697 25.5078 45.0007 26.7416 52.0539C27.9753 59.1071 30.6632 65.6296 34.3205 70.4055C34.695 70.8919 34.6069 71.5773 34.1222 71.9532C33.9239 72.108 33.6816 72.1743 33.4613 72.1743L33.4392 72.1964Z" fill="#1B3950" />
          <path d="M34.1222 71.9531L32.7783 70.2064C33.3731 69.7421 33.8799 68.9683 34.2985 67.8627C35.2238 65.4085 35.334 62.7774 34.6069 60.3231C32.9766 61.7824 31.3242 63.4407 30.8615 65.3422L28.7244 64.8115C29.4074 61.9593 31.875 59.7925 33.8578 58.0458L35.5102 56.5865L36.1271 57.9794C37.6033 61.3623 37.6914 65.1432 36.3915 68.6587C35.8187 70.1622 35.0916 71.2456 34.1663 71.9752L34.1222 71.9531Z" fill="#1B3950" />
          <path d="M27.9753 57.4488L27.0059 55.4589C27.6889 55.1272 28.3278 54.486 28.9668 53.469C30.3988 51.1916 31.0598 48.6489 30.9056 46.1505C29.0108 47.256 27.05 48.5163 26.2348 50.2851L24.252 49.3343C25.5078 46.6811 28.3499 45.067 30.6412 43.7625L32.5359 42.6791L32.8444 44.1605C33.5934 47.6982 32.8664 51.4348 30.8174 54.6629C29.9362 56.0338 29.0329 56.9182 27.9753 57.4488V57.4488Z" fill="#1B3950" />
          <path d="M24.6706 40.7998C22.9741 40.0701 21.5641 38.6329 20.6828 36.7536C19.9778 35.2501 19.6253 33.4591 19.5812 31.425C19.5372 28.5064 20.5066 25.9859 21.4099 23.8412L22.1369 22.1387L23.7012 24.1065C25.3095 26.1185 27.3144 28.5949 27.6228 31.6461L25.4417 31.8672C25.2434 29.8773 24.0096 28.0642 22.7538 26.4502C22.1149 28.1527 21.7624 29.7004 21.7844 31.4029C21.8064 33.1275 22.1149 34.631 22.6657 35.8249C23.3046 37.1958 24.3401 38.2792 25.5298 38.7877L24.6485 40.8219L24.6706 40.7998Z" fill="#1B3950" />
          <path d="M25.64 41.0209L25.1553 38.8762C27.3585 38.3676 29.3854 37.041 30.7293 35.1838C31.8529 33.6139 32.558 31.7567 32.7122 30.0321C30.6632 30.6954 28.5261 31.4913 27.2924 32.9727L25.5959 31.5577C27.4025 29.3909 30.3988 28.4401 32.8223 27.6663L34.7611 27.0472L34.8933 28.418C35.1357 31.0491 34.2544 34.0783 32.4919 36.4883C30.8615 38.7656 28.3499 40.4239 25.6179 41.043L25.64 41.0209Z" fill="#1B3950" />
          <path d="M26.3229 57.9573C25.001 57.9573 23.635 57.4488 22.4894 56.5423C21.4759 55.7242 20.6167 54.6187 19.8676 53.1373C18.5236 50.4398 18.0169 47.1454 18.4576 43.8068L18.6999 42.0822L20.6828 43.3867C22.7538 44.7133 25.3094 46.3715 26.4771 49.0248L24.4722 49.9313C23.7232 48.2509 22.1148 46.9906 20.5065 45.9293C20.4624 48.1846 20.9031 50.3735 21.8284 52.1866C22.4233 53.3805 23.0842 54.2428 23.8553 54.8619C24.7146 55.5473 25.7281 55.879 26.6314 55.7905L26.8517 57.9794C26.6754 57.9794 26.4992 58.0016 26.3229 58.0016V57.9573Z" fill="#1B3950" />
          <path d="M31.4343 72.4175C30.2887 72.4175 29.121 72.1964 28.0414 71.7763C24.5163 70.4054 22.5335 67.0447 20.8811 63.5955L20.0659 61.8708L22.5775 62.0477C25.0231 62.2246 28.0855 62.4457 30.3768 64.1924L29.0329 65.9391C27.6008 64.8336 25.64 64.502 23.7232 64.3472C25.001 66.713 26.5433 68.8135 28.8126 69.6979C30.1785 70.2285 31.7208 70.317 33.1308 69.9632L33.6596 72.1079C32.9105 72.2848 32.1614 72.3953 31.3903 72.3953L31.4343 72.4175Z" fill="#1B3950" />
          <path d="M62.6534 72.1964C62.4111 72.1964 62.1908 72.1301 61.9925 71.9753C61.5078 71.5994 61.4196 70.914 61.7942 70.4276C65.4294 65.6518 68.1173 59.1292 69.3731 52.076C70.6289 45.0229 70.2985 37.9697 68.5139 32.2431C68.3376 31.6682 68.6461 31.0492 69.2409 30.8502C69.8138 30.6733 70.4307 30.9828 70.6289 31.5798C72.5237 37.6601 72.8542 45.0671 71.5543 52.474C70.2544 59.881 67.4123 66.7352 63.5567 71.7763C63.3364 72.0637 63.0059 72.2185 62.6755 72.2185L62.6534 72.1964Z" fill="#1B3950" />
          <path d="M61.9704 71.9532C61.0451 71.2235 60.318 70.1401 59.7452 68.6366C58.4233 65.1432 58.5114 61.3623 60.0096 57.9573L60.6265 56.5644L62.2789 58.0237C64.2617 59.7704 66.7073 61.9372 67.4123 64.7894L65.2752 65.3201C64.8125 63.4186 63.1601 61.7824 61.5298 60.301C60.8248 62.7553 60.9129 65.3864 61.8382 67.8406C62.2568 68.9462 62.7415 69.72 63.3584 70.1843L62.0145 71.931L61.9704 71.9532Z" fill="#1B3950" />
          <path d="M68.1173 57.4488C67.0377 56.9182 66.1344 56.0338 65.2752 54.6629C63.2262 51.4348 62.5212 47.6982 63.2483 44.1605L63.5567 42.6791L65.4514 43.7625C67.7428 45.067 70.5849 46.6811 71.8407 49.3343L69.8578 50.2851C69.0206 48.5163 67.0818 47.256 65.1871 46.1505C65.0108 48.6489 65.6938 51.1916 67.1259 53.469C67.7648 54.486 68.3817 55.1272 69.0867 55.4589L68.1173 57.4488V57.4488Z" fill="#1B3950" />
          <path d="M71.4221 40.7998L70.5408 38.7656C71.7305 38.2571 72.766 37.1737 73.4049 35.8028C73.9777 34.5868 74.2642 33.1054 74.2862 31.3808C74.3082 29.6783 73.9557 28.1527 73.3168 26.4281C72.0389 28.0642 70.8272 29.8552 70.6289 31.8451L68.4478 31.624C68.7562 28.5728 70.7611 26.0964 72.3694 24.0844L73.9337 22.1166L74.6607 23.8191C75.564 25.9638 76.5334 28.4843 76.4894 31.4029C76.4673 33.437 76.0928 35.2501 75.3878 36.7315C74.5065 38.6109 73.0965 40.048 71.4 40.7777L71.4221 40.7998Z" fill="#1B3950" />
          <path d="M70.4526 41.0209C67.7207 40.4018 65.2311 38.7435 63.5787 36.4662C61.8382 34.0562 60.9349 31.027 61.1773 28.3959L61.3094 27.0251L63.2482 27.6442C65.6717 28.418 68.6681 29.3688 70.4747 31.5356L68.7782 32.9506C67.5444 31.4693 65.3853 30.6733 63.3584 30.01C63.5126 31.7567 64.2176 33.5918 65.3413 35.1617C66.6632 36.9968 68.6901 38.3456 70.9153 38.8541L70.4306 40.9988L70.4526 41.0209Z" fill="#1B3950" />
          <path d="M69.7697 57.9574C69.5934 57.9574 69.4172 57.9574 69.2409 57.9353L69.4612 55.7463C70.3645 55.8569 71.378 55.5031 72.2372 54.8177C73.0083 54.1986 73.6693 53.3363 74.2642 52.1424C75.1675 50.3293 75.6301 48.1625 75.5861 45.8852C73.9777 46.9686 72.3694 48.2067 71.6203 49.8871L69.6154 48.9806C70.7831 46.3274 73.3609 44.6691 75.4098 43.3425L77.3927 42.038L77.635 43.7626C78.0757 47.1012 77.591 50.4178 76.247 53.0931C75.4979 54.5745 74.6387 55.68 73.6252 56.4981C72.4796 57.4046 71.1136 57.9132 69.7917 57.9132L69.7697 57.9574Z" fill="#1B3950" />
          <path d="M64.6583 72.4175C63.8872 72.4175 63.1381 72.3291 62.389 72.1301L62.9178 69.9854C64.3499 70.3391 65.8701 70.2507 67.236 69.7201C69.5053 68.8356 71.0475 66.7352 72.3254 64.3694C70.4307 64.5462 68.4698 64.8779 67.0157 65.9613L65.6718 64.2146C67.9631 62.4458 71.0255 62.2468 73.471 62.0699L75.9827 61.893L75.1675 63.6176C73.5371 67.0668 71.5322 70.4276 68.0072 71.7984C66.9276 72.2185 65.7819 72.4396 64.6143 72.4396L64.6583 72.4175Z" fill="#1B3950" />
          <path d="M44.367 39.1857C44.2348 39.1857 44.1247 39.1857 43.9925 39.1194C43.4196 38.9204 43.1332 38.2792 43.3315 37.7043L47.0108 27.4894C47.2091 26.9145 47.848 26.6271 48.4209 26.8261C48.9937 27.0251 49.2801 27.6662 49.0818 28.2411L45.4025 38.4561C45.2483 38.8983 44.8076 39.1857 44.367 39.1857V39.1857Z" fill="#1B3950" />
          <path d="M51.7256 39.1857C51.285 39.1857 50.8444 38.8983 50.6901 38.4561L47.0108 28.2411C46.8125 27.6662 47.099 27.0251 47.6718 26.8261C48.2446 26.6271 48.8835 26.9145 49.0818 27.4894L52.7611 37.7043C52.9594 38.2792 52.673 38.9204 52.1002 39.1194C51.968 39.1636 51.8578 39.1857 51.7256 39.1857V39.1857Z" fill="#1B3950" />
          <path d="M44.367 39.1857H37.0304C36.4135 39.1857 35.9288 38.6993 35.9288 38.0802C35.9288 37.4611 36.4135 36.9747 37.0304 36.9747H44.367C44.9839 36.9747 45.4686 37.4611 45.4686 38.0802C45.4686 38.6993 44.9839 39.1857 44.367 39.1857Z" fill="#1B3950" />
          <path d="M42.5383 45.3103C42.2299 45.3103 41.9435 45.1776 41.7232 44.9344L36.2152 38.8098C35.7966 38.3455 35.8407 37.6601 36.3033 37.24C36.744 36.842 37.449 36.8641 37.8676 37.3284L43.3756 43.453C43.7942 43.9173 43.7501 44.6027 43.2874 45.0228C43.0671 45.2218 42.8027 45.3103 42.5604 45.3103H42.5383Z" fill="#1B3950" />
          <path d="M40.7097 55.5252C40.7097 55.5252 40.5775 55.5252 40.5114 55.5252C39.9165 55.4147 39.52 54.8398 39.6301 54.2428L41.4588 44.0279C41.5689 43.4309 42.1418 43.0329 42.7366 43.1434C43.3315 43.254 43.7281 43.8289 43.6179 44.4258L41.7893 54.6408C41.7011 55.1714 41.2385 55.5473 40.7097 55.5473V55.5252Z" fill="#1B3950" />
          <path d="M40.7097 55.5252C40.4012 55.5252 40.0708 55.3925 39.8504 55.1272C39.4539 54.6629 39.52 53.9554 40.0047 53.5795L47.3413 47.4549C47.8039 47.057 48.5089 47.1233 48.8835 47.6097C49.2801 48.074 49.214 48.7816 48.7293 49.1574L41.3927 55.282C41.1944 55.4589 40.93 55.5473 40.6876 55.5473L40.7097 55.5252Z" fill="#1B3950" />
          <path d="M55.3829 55.5252C55.1405 55.5252 54.8761 55.4367 54.6778 55.2599L47.3413 49.1353C46.8786 48.7373 46.8125 48.0519 47.187 47.5876C47.5836 47.1233 48.2666 47.0569 48.7293 47.4328L56.0659 53.5574C56.5285 53.9554 56.5946 54.6408 56.2201 55.1051C55.9998 55.3704 55.6913 55.5031 55.3608 55.5031L55.3829 55.5252Z" fill="#1B3950" />
          <path d="M55.3829 55.5252C54.8541 55.5252 54.3914 55.1493 54.3033 54.6187L52.4747 44.4037C52.3645 43.8068 52.7611 43.2319 53.3559 43.1213C53.9728 43.0108 54.5236 43.4088 54.6338 44.0058L56.4624 54.2207C56.5726 54.8177 56.176 55.3926 55.5812 55.5031C55.5151 55.5031 55.449 55.5031 55.3829 55.5031V55.5252Z" fill="#1B3950" />
          <path d="M59.0622 39.1857H51.7256C51.1087 39.1857 50.624 38.6993 50.624 38.0802C50.624 37.4611 51.1087 36.9747 51.7256 36.9747H59.0622C59.6791 36.9747 60.1638 37.4611 60.1638 38.0802C60.1638 38.6993 59.6791 39.1857 59.0622 39.1857Z" fill="#1B3950" />
          <path d="M53.5542 45.3103C53.2899 45.3103 53.0255 45.2218 52.8272 45.0228C52.3645 44.6027 52.3425 43.9173 52.7391 43.453L58.247 37.3284C58.6656 36.8641 59.3486 36.842 59.8113 37.24C60.274 37.6601 60.296 38.3455 59.8994 38.8098L54.3915 44.9344C54.1711 45.1776 53.8847 45.3103 53.5763 45.3103H53.5542Z" fill="#1B3950" />
          <path d="M52.4527 73.2135H43.6399C43.023 73.2135 42.5383 72.727 42.5383 72.108C42.5383 71.4889 43.023 71.0024 43.6399 71.0024H52.4527C53.0695 71.0024 53.5542 71.4889 53.5542 72.108C53.5542 72.727 53.0695 73.2135 52.4527 73.2135Z" fill="#1B3950" />
          <path d="M48.0463 73.2135C47.4294 73.2135 46.9447 72.727 46.9447 72.108V63.2638C46.9447 62.6448 47.4294 62.1583 48.0463 62.1583C48.6632 62.1583 49.1479 62.6448 49.1479 63.2638V72.108C49.1479 72.727 48.6632 73.2135 48.0463 73.2135Z" fill="#1B3950" />
          <path d="M53.5542 64.3694H42.5383C41.3266 64.3694 40.3351 63.3744 40.3351 62.1583V59.9473C40.3351 58.7312 41.3266 57.7363 42.5383 57.7363H53.5542C54.766 57.7363 55.7574 58.7312 55.7574 59.9473V62.1583C55.7574 63.3744 54.766 64.3694 53.5542 64.3694ZM42.5383 59.9473V62.1583H53.5542V59.9473H42.5383Z" fill="#1B3950" />
        </g>
        <defs>
          <clipPath id="clip0_525_3029">
            <rect width="59.4859" height="51.0969" fill="white" transform="translate(18.3033 22.1166)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }
  return (
    <SvgContainer name="institution" height={125} width={125} viewBoxHeight={125} viewBoxWidth={125} {...props}>
      <circle cx="62.5" cy="62.5" r="62.5" fill="#FFF2DB" />
      <g clipPath="url(#clip0_525_3029)">
        <path d="M43.8467 94.6664C43.4133 94.6664 42.98 94.4634 42.6911 94.0865C37.6356 87.4474 33.9089 78.46 32.2045 68.7767C30.5 59.0645 30.9333 49.3522 33.4178 41.3795C33.6489 40.6257 34.4578 40.1908 35.2378 40.4228C35.9889 40.6547 36.4222 41.4665 36.1911 42.2492C33.8511 49.7871 33.4467 59.0065 35.0645 68.2549C36.6822 77.5032 40.2067 86.0558 45.0022 92.318C45.4933 92.9559 45.3778 93.8546 44.7422 94.3475C44.4822 94.5504 44.1645 94.6374 43.8756 94.6374L43.8467 94.6664Z" fill="#1B3950" />
        <path d="M44.7422 94.3475L42.98 92.0571C43.76 91.4483 44.4244 90.4336 44.9733 88.984C46.1867 85.7659 46.3311 82.3159 45.3778 79.0978C43.24 81.0113 41.0733 83.1856 40.4667 85.6789L37.6644 84.9831C38.56 81.2432 41.7955 78.402 44.3955 76.1116L46.5622 74.1982L47.3711 76.0247C49.3067 80.4604 49.4222 85.418 47.7178 90.0277C46.9667 91.9991 46.0133 93.4197 44.8 94.3765L44.7422 94.3475Z" fill="#1B3950" />
        <path d="M36.6822 75.3288L35.4111 72.7196C36.3067 72.2847 37.1444 71.444 37.9822 70.1103C39.86 67.1242 40.7267 63.7901 40.5244 60.514C38.04 61.9636 35.4689 63.6162 34.4 65.9355L31.8 64.6889C33.4467 61.2099 37.1733 59.0935 40.1778 57.3829L42.6622 55.9623L43.0667 57.9048C44.0489 62.5435 43.0955 67.4431 40.4089 71.6759C39.2533 73.4734 38.0689 74.633 36.6822 75.3288V75.3288Z" fill="#1B3950" />
        <path d="M32.3489 53.498C30.1244 52.5413 28.2755 50.6569 27.12 48.1926C26.1955 46.2211 25.7333 43.8728 25.6755 41.2055C25.6178 37.3786 26.8889 34.0736 28.0733 31.2614L29.0267 29.029L31.0778 31.6093C33.1867 34.2475 35.8155 37.4946 36.22 41.4955L33.36 41.7854C33.1 39.1761 31.4822 36.7988 29.8355 34.6824C28.9978 36.9148 28.5355 38.9442 28.5644 41.1765C28.5933 43.4379 28.9978 45.4093 29.72 46.9749C30.5578 48.7724 31.9155 50.193 33.4755 50.8598L32.32 53.527L32.3489 53.498Z" fill="#1B3950" />
        <path d="M33.62 53.788L32.9844 50.9757C35.8733 50.3089 38.5311 48.5694 40.2933 46.1341C41.7667 44.0757 42.6911 41.6404 42.8933 39.379C40.2067 40.2488 37.4044 41.2925 35.7867 43.2349L33.5622 41.3795C35.9311 38.5383 39.86 37.2916 43.0378 36.2769L45.58 35.4651L45.7533 37.2626C46.0711 40.7127 44.9155 44.6845 42.6044 47.8446C40.4667 50.8308 37.1733 53.0052 33.5911 53.8169L33.62 53.788Z" fill="#1B3950" />
        <path d="M34.5155 75.9957C32.7822 75.9957 30.9911 75.3289 29.4889 74.1402C28.16 73.0675 27.0333 71.6179 26.0511 69.6755C24.2889 66.1385 23.6244 61.8187 24.2022 57.4409L24.52 55.1796L27.12 56.8901C29.8355 58.6296 33.1866 60.804 34.7177 64.283L32.0889 65.4716C31.1066 63.2683 28.9977 61.6157 26.8889 60.2241C26.8311 63.1813 27.4089 66.0515 28.6222 68.4288C29.4022 69.9944 30.2689 71.125 31.28 71.9368C32.4066 72.8356 33.7355 73.2704 34.92 73.1545L35.2089 76.0247C34.9777 76.0247 34.7466 76.0536 34.5155 76.0536V75.9957Z" fill="#1B3950" />
        <path d="M41.2178 94.9563C39.7155 94.9563 38.1844 94.6664 36.7689 94.1155C32.1467 92.318 29.5467 87.9113 27.38 83.3886L26.3111 81.1272L29.6044 81.3591C32.8111 81.5911 36.8267 81.881 39.8311 84.1713L38.0689 86.4617C36.1911 85.0121 33.62 84.5772 31.1067 84.3743C32.7822 87.4764 34.8044 90.2306 37.78 91.3903C39.5711 92.0861 41.5933 92.2021 43.4422 91.7382L44.1355 94.5504C43.1533 94.7823 42.1711 94.9273 41.16 94.9273L41.2178 94.9563Z" fill="#1B3950" />
        <path d="M82.1534 94.6664C81.8356 94.6664 81.5467 94.5794 81.2867 94.3765C80.6512 93.8836 80.5356 92.9849 81.0267 92.347C85.7934 86.0848 89.3178 77.5322 90.9645 68.2839C92.6112 59.0355 92.1778 49.7871 89.8378 42.2782C89.6067 41.5245 90.0112 40.7127 90.7912 40.4518C91.5423 40.2198 92.3512 40.6257 92.6112 41.4085C95.0956 49.3812 95.5289 59.0935 93.8245 68.8057C92.1201 78.518 88.3934 87.5054 83.3378 94.1155C83.0489 94.4924 82.6156 94.6954 82.1823 94.6954L82.1534 94.6664Z" fill="#1B3950" />
        <path d="M81.2578 94.3475C80.0445 93.3907 79.0911 91.9701 78.34 89.9987C76.6067 85.418 76.7223 80.4604 78.6867 75.9957L79.4956 74.1692L81.6622 76.0826C84.2622 78.373 87.4689 81.2142 88.3934 84.9541L85.5911 85.6499C84.9845 83.1566 82.8178 81.0113 80.68 79.0688C79.7556 82.2869 79.8711 85.7369 81.0845 88.955C81.6334 90.4046 82.2689 91.4193 83.0778 92.0281L81.3156 94.3185L81.2578 94.3475Z" fill="#1B3950" />
        <path d="M89.3178 75.3288C87.9022 74.633 86.7178 73.4734 85.5911 71.6759C82.9044 67.4431 81.98 62.5435 82.9333 57.9048L83.3378 55.9623L85.8222 57.3829C88.8267 59.0935 92.5533 61.2099 94.2 64.6889L91.6 65.9355C90.5022 63.6162 87.96 61.9636 85.4756 60.514C85.2444 63.7901 86.14 67.1242 88.0178 70.1103C88.8556 71.444 89.6644 72.2847 90.5889 72.7196L89.3178 75.3288V75.3288Z" fill="#1B3950" />
        <path d="M93.6512 53.498L92.4956 50.8308C94.0556 50.164 95.4134 48.7434 96.2512 46.9459C97.0023 45.3514 97.3778 43.4089 97.4067 41.1476C97.4356 38.9152 96.9734 36.9148 96.1356 34.6534C94.46 36.7988 92.8712 39.1471 92.6112 41.7564L89.7512 41.4665C90.1556 37.4656 92.7845 34.2185 94.8934 31.5803L96.9445 29L97.8978 31.2324C99.0823 34.0446 100.353 37.3496 100.296 41.1765C100.267 43.8438 99.7756 46.2211 98.8512 48.1636C97.6956 50.6279 95.8467 52.5123 93.6223 53.4691L93.6512 53.498Z" fill="#1B3950" />
        <path d="M92.38 53.788C88.7978 52.9762 85.5333 50.8018 83.3667 47.8156C81.0844 44.6555 79.9 40.6837 80.2178 37.2336L80.3911 35.4362L82.9333 36.2479C86.1111 37.2626 90.04 38.5093 92.4089 41.3505L90.1844 43.206C88.5667 41.2635 85.7356 40.2198 83.0778 39.35C83.28 41.6404 84.2044 44.0467 85.6778 46.1051C87.4111 48.5114 90.0689 50.2799 92.9867 50.9468L92.3511 53.759L92.38 53.788Z" fill="#1B3950" />
        <path d="M91.4845 75.9957C91.2534 75.9957 91.0222 75.9957 90.7911 75.9667L91.08 73.0965C92.2645 73.2414 93.5934 72.7776 94.72 71.8788C95.7311 71.0671 96.5978 69.9364 97.3778 68.3708C98.5622 65.9935 99.1689 63.1523 99.1111 60.1662C97.0022 61.5867 94.8934 63.2103 93.9111 65.4137L91.2822 64.225C92.8134 60.746 96.1934 58.5716 98.88 56.8321L101.48 55.1216L101.798 57.3829C102.376 61.7607 101.74 66.1095 99.9778 69.6175C98.9956 71.5599 97.8689 73.0095 96.54 74.0822C95.0378 75.2709 93.2467 75.9377 91.5134 75.9377L91.4845 75.9957Z" fill="#1B3950" />
        <path d="M84.7823 94.9563C83.7711 94.9563 82.7889 94.8403 81.8067 94.5794L82.5 91.7672C84.3778 92.231 86.3711 92.1151 88.1623 91.4193C91.1378 90.2596 93.16 87.5054 94.8356 84.4033C92.3511 84.6352 89.78 85.0701 87.8734 86.4907L86.1111 84.2003C89.1156 81.881 93.1311 81.6201 96.3378 81.3881L99.6311 81.1562L98.5623 83.4175C96.4245 87.9403 93.7956 92.347 89.1734 94.1445C87.7578 94.6953 86.2556 94.9853 84.7245 94.9853L84.7823 94.9563Z" fill="#1B3950" />
        <path d="M58.1756 51.3817C58.0023 51.3817 57.8578 51.3817 57.6845 51.2947C56.9334 51.0338 56.5578 50.193 56.8178 49.4392L61.6423 36.045C61.9023 35.2912 62.74 34.9144 63.4911 35.1753C64.2423 35.4362 64.6178 36.277 64.3578 37.0308L59.5334 50.425C59.3311 51.0048 58.7534 51.3817 58.1756 51.3817V51.3817Z" fill="#1B3950" />
        <path d="M67.8245 51.3817C67.2467 51.3817 66.6689 51.0048 66.4667 50.425L61.6423 37.0308C61.3823 36.277 61.7578 35.4362 62.5089 35.1753C63.26 34.9144 64.0978 35.2912 64.3578 36.045L69.1823 49.4392C69.4423 50.193 69.0667 51.0338 68.3156 51.2947C68.1423 51.3527 67.9978 51.3817 67.8245 51.3817V51.3817Z" fill="#1B3950" />
        <path d="M58.1757 51.3817H48.5557C47.7468 51.3817 47.1112 50.7438 47.1112 49.9321C47.1112 49.1203 47.7468 48.4825 48.5557 48.4825H58.1757C58.9845 48.4825 59.6201 49.1203 59.6201 49.9321C59.6201 50.7438 58.9845 51.3817 58.1757 51.3817Z" fill="#1B3950" />
        <path d="M55.7778 59.4124C55.3734 59.4124 54.9978 59.2385 54.7089 58.9195L47.4867 50.8888C46.9378 50.28 46.9956 49.3813 47.6022 48.8304C48.18 48.3086 49.1045 48.3376 49.6534 48.9464L56.8756 56.9771C57.4245 57.5859 57.3667 58.4847 56.76 59.0355C56.4711 59.2964 56.1245 59.4124 55.8067 59.4124H55.7778Z" fill="#1B3950" />
        <path d="M53.38 72.8066C53.38 72.8066 53.2067 72.8066 53.12 72.8066C52.34 72.6616 51.82 71.9078 51.9645 71.125L54.3622 57.7309C54.5067 56.9481 55.2578 56.4262 56.0378 56.5712C56.8178 56.7161 57.3378 57.4699 57.1934 58.2527L54.7956 71.6469C54.68 72.3427 54.0734 72.8356 53.38 72.8356V72.8066Z" fill="#1B3950" />
        <path d="M53.38 72.8066C52.9756 72.8066 52.5422 72.6326 52.2533 72.2847C51.7333 71.6759 51.82 70.7482 52.4556 70.2553L62.0756 62.2246C62.6822 61.7027 63.6067 61.7897 64.0978 62.4275C64.6178 63.0364 64.5311 63.9641 63.8956 64.457L54.2756 72.4877C54.0156 72.7196 53.6689 72.8356 53.3511 72.8356L53.38 72.8066Z" fill="#1B3950" />
        <path d="M72.62 72.8066C72.3022 72.8066 71.9556 72.6907 71.6956 72.4587L62.0756 64.428C61.4689 63.9061 61.3822 63.0074 61.8733 62.3986C62.3933 61.7897 63.2889 61.7028 63.8956 62.1956L73.5156 70.2264C74.1222 70.7482 74.2089 71.6469 73.7178 72.2558C73.4289 72.6037 73.0244 72.7776 72.5911 72.7776L72.62 72.8066Z" fill="#1B3950" />
        <path d="M72.62 72.8066C71.9267 72.8066 71.32 72.3137 71.2045 71.6179L68.8067 58.2237C68.6622 57.4409 69.1822 56.6871 69.9622 56.5422C70.7711 56.3972 71.4934 56.9191 71.6378 57.7019L74.0356 71.0961C74.18 71.8788 73.66 72.6326 72.88 72.7776C72.7934 72.7776 72.7067 72.7776 72.62 72.7776V72.8066Z" fill="#1B3950" />
        <path d="M77.4444 51.3817H67.8244C67.0156 51.3817 66.38 50.7438 66.38 49.9321C66.38 49.1203 67.0156 48.4825 67.8244 48.4825H77.4444C78.2533 48.4825 78.8889 49.1203 78.8889 49.9321C78.8889 50.7438 78.2533 51.3817 77.4444 51.3817Z" fill="#1B3950" />
        <path d="M70.2223 59.4124C69.8756 59.4124 69.5289 59.2964 69.2689 59.0355C68.6623 58.4847 68.6334 57.5859 69.1534 56.9771L76.3756 48.9464C76.9245 48.3376 77.8201 48.3086 78.4267 48.8304C79.0334 49.3813 79.0623 50.28 78.5423 50.8888L71.32 58.9195C71.0312 59.2385 70.6556 59.4124 70.2512 59.4124H70.2223Z" fill="#1B3950" />
        <path d="M68.7778 96.0001H57.2223C56.4134 96.0001 55.7778 95.3622 55.7778 94.5505C55.7778 93.7387 56.4134 93.1009 57.2223 93.1009H68.7778C69.5867 93.1009 70.2223 93.7387 70.2223 94.5505C70.2223 95.3622 69.5867 96.0001 68.7778 96.0001Z" fill="#1B3950" />
        <path d="M63 96C62.1912 96 61.5556 95.3622 61.5556 94.5505V82.9537C61.5556 82.142 62.1912 81.5042 63 81.5042C63.8089 81.5042 64.4445 82.142 64.4445 82.9537V94.5505C64.4445 95.3622 63.8089 96 63 96Z" fill="#1B3950" />
        <path d="M70.2223 84.4033H55.7778C54.1889 84.4033 52.8889 83.0987 52.8889 81.5042V78.605C52.8889 77.0104 54.1889 75.7058 55.7778 75.7058H70.2223C71.8111 75.7058 73.1111 77.0104 73.1111 78.605V81.5042C73.1111 83.0987 71.8111 84.4033 70.2223 84.4033ZM55.7778 78.605V81.5042H70.2223V78.605H55.7778Z" fill="#1B3950" />
      </g>
      <defs>
        <clipPath id="clip0_525_3029">
          <rect width="78" height="67" fill="white" transform="translate(24 29)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
}

Institution.propTypes = { small: PropTypes.bool, medium: PropTypes.bool };

export default Institution;
