import PatientTabGroup from 'factories/PatientTabGroup';
import StateHelper from 'helpers/StateHelper';

import {
  QUIZ_CREATING,
  QUIZ_UNLOAD_TAKE,
  QUIZ_FETCHED_QUESTIONS,
  QUIZ_FETCHED_ALL_QUESTIONS
} from 'actions/quizzes';

export const stateHelper = new StateHelper(PatientTabGroup);
export const { initialState } = stateHelper;
const reducers = {};

reducers[QUIZ_FETCHED_QUESTIONS] = stateHelper.set;
reducers[QUIZ_FETCHED_ALL_QUESTIONS] = stateHelper.set;

reducers[QUIZ_UNLOAD_TAKE] = stateHelper.reset;
reducers[QUIZ_CREATING] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
