import StateHelper from 'helpers/StateHelper';
import CmeActivity from 'factories/CmeActivity';

import { SESSION_RESET } from 'actions/session';
import { CME_ACTIVITIES_FETCHED } from 'actions/cme';

const stateHelper = new StateHelper(CmeActivity);

const reducers = {};


reducers[CME_ACTIVITIES_FETCHED] = stateHelper.set;

reducers[SESSION_RESET] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
