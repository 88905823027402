export default function capitalize(string) {
  if (!string) return "";
  return string.slice(0, 1).toUpperCase() + string.slice(1);
}

export const snakeToPascal = string => string.split('_').map(str => capitalize(
  str.split('/')
    .map(capitalize)
    .join('/')
)).join('');

export function decodeEntities(encodedString) {
  const translateRegex = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: " ",
    amp: "&",
    quot: "\"",
    lt: "<",
    gt: ">"
  };
  return encodedString.replace(translateRegex, function(match, entity) {
    return translate[entity];
  }).replace(/&#(\d+);/gi, function(match, numStr) {
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
}

export function pluralize(string) {
  if (!string) return "";
  if (string.slice(-1) === "s") return string;
  if (string.slice(-1) === "y") return string.slice(0, -1) + "ies";
  return string + "s";
}

export const reduceString = {
  needsReducing: (fullString, maxLength) => fullString?.length > maxLength,
  keepBeginning: (fullString, maxLength) => (reduceString.needsReducing(fullString, maxLength) ? `${fullString.slice(0, maxLength)}...` : fullString),
  keepEnding: (fullString, maxLength) => (reduceString.needsReducing(fullString, maxLength) ? `...${fullString.slice(-maxLength)}` : fullString),
  splitInHalf: (fullString, maxLength) => {
    if (!reduceString.needsReducing(fullString, maxLength)) { return fullString; }
    const middle = Math.floor(fullString.length / 2);
    const halves = [fullString.substr(0, middle), fullString.substr(middle + 1)];
    halves[0] = halves[0].slice(0, maxLength / 2);
    halves[1] = halves[1].slice(-maxLength / 2);
    return halves.join('...');
  },
  // FOR REDUCING EMAILS "@" IS A GOOD splitChar
  splitByChar: (fullString, maxLength, splitChar) => {
    if (!reduceString.needsReducing(fullString, maxLength)) { return fullString; }
    if (!fullString.includes(splitChar)) { return reduceString.splitInHalf(fullString, maxLength); }
    const halves = fullString.split(splitChar);
    // Prevent ending up with just the domain, if the domain is too long
    if (maxLength < halves[1].length + 5) { return reduceString.splitInHalf(fullString, maxLength); }
    halves[0] = reduceString.keepBeginning(halves[0], maxLength - halves[1].length);
    return halves.join(splitChar);
  },
};
