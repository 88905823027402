import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeCMECheck(props) {
  return (
    <SvgContainer title="Home CME Check Icon" {...props}>
      <path d="m5.406 12.803 2.53 3.115M7.938 15.918l7.589-8.1" stroke="#3E8A3D" strokeWidth="2" strokeLinecap="round" />
      <path d="M20.214 11.245c0 5.112-4.21 9.28-9.435 9.28-5.225 0-9.435-4.168-9.435-9.28 0-5.112 4.21-9.28 9.435-9.28 5.225 0 9.435 4.168 9.435 9.28Z" stroke="#3E8A3D" strokeWidth="2" />
    </SvgContainer>
  );
}
