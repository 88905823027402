import StateHelper from 'helpers/StateHelper';
import AddonAccessTracker from 'factories/AddonAccessTracker';

import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';


const stateHelper = new StateHelper(AddonAccessTracker);
export const { initialState } = stateHelper;
const reducers = {};

reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
