import Factory from 'factories/_Factory';

export default class CmeCreditTracker extends Factory {
  static jsonType = 'cme_credit_tracker';

  static get defaults() {
    return {
      already_paid_for: null,
      cached_charge_amount: null,
      charge_id: null,
      coupon: null,
      deactivated: null
    };
  }

  static get belongsTo() {
    return ['question_bank', 'cme_activity'];
  }
}
