import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgNext(props) {
  return (
    <SvgContainer title="Next Icon" {...props}>
      <path fill="#3589c3" d="M2.1 7.5v17L28 16z" /><path d="M1.2 25.6V6.4L30.8 16 1.2 25.6zM2.9 8.7v14.5L25.2 16 2.9 8.7z" fill="#fff" />
    </SvgContainer>
  );
}
