import React from "react";

export default function HomeMobileApp() {
  const imageAltText = "Board Prep & CME App";

  return (
    <div className="HomeMobileApp home-section-wrapper">
      <div className="container">
        <div className="row d-flex flex-wrap px-3 px-md-0">
          <div className="col-12 col-lg-6 d-flex align-items-center order-2">
            <div className="col-12 col-xl-10 p-0">
              <h2 className="storefront section-title mt-5 mt-md-6 mt-lg-0 pt-3 pt-md-3 pt-lg-0">BOARDVITALS MOBILE APP</h2>
              <h2 className="storefront header-style mt-6 mt-md-6 mb-5 mb-md-4 mb-lg-5 pt-md-2 pt-lg-1 pb-lg-2">Study Anytime, Anywhere</h2>
              <p className="storefront pt-3 pt-md-4 pb-4 pb-md-2">
                Commuting to class? In between clinicals? Download the BoardVitals mobile app to:
              </p>
              <ul className="pl-3 pt-lg-4 ml-3 mb-1 mb-md-2 mb-lg-4">
                <li className="mb-4">Answer practice questions on-the-go</li>
                <li className="mb-4">Track progress and performance</li>
                <li>Access content offline</li>
              </ul>
              <div className="pb-6 pb-lg-0 pt-5 pt-md-6 mb-6 mb-lg-0">
                <div className="mobile-app-badge d-inline-block">
                  <a className="d-block mb-2 mb-md-3 pb-1 pb-md-2" href="https://apps.apple.com/us/app/boardvitals-medical-exam-prep/id1484690932" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid d-block apple-badge" loading="lazy" alt="Apple iOS BoardVitals App" src="/images/apple-ios-boardvitals-app.svg" />
                  </a>
                </div>
                <div className="mobile-app-badge d-inline-block">
                  <a className="d-block mb-2 mb-md-3 pb-1 pb-md-2" href="https://play.google.com/store/apps/details?id=com.boardvitals&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid d-block android-badge" loading="lazy" alt="Android BoardVitals App" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-end order-1 order-lg-2 mt-4 mt-md-5 mt-lg-0">
            <img
              className="img-fluid d-lg-none mt-4 pt-md-4"
              loading="lazy"
              src="/images/homepage/board-review-questions-mobile-app-iPhone-iPad.png"
              alt={imageAltText}
            />
            <img
              className="img-fluid d-none d-lg-block mt-6 mb-4"
              loading="lazy"
              src="/images/homepage/board-review-questions-mobile-app-Desktop.png"
              alt={imageAltText}
            />
          </div>
        </div>
      </div>

    </div>
  );
}
