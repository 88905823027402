import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgDashboard(props) {
  return (
    <SvgContainer title="Dashboard Icon" {...props}>
      <path d="M28.121 20.495a12.326 12.326 0 00-1.837-10.084.487.487 0 00-.078-.117c-.006-.006-.014-.008-.02-.013a12.465 12.465 0 00-20.373 0c-.006.005-.014.007-.02.013a.487.487 0 00-.078.117A12.326 12.326 0 003.88 20.495l-.001.005a.487.487 0 00.066.235 12.496 12.496 0 003.763 6.116l.005.004.02.02a204.018 204.018 0 01.155.09.486.486 0 00.107.022.468.468 0 00.069.013h15.874a.498.498 0 00.185-.037.48.48 0 00.065-.04.489.489 0 00.082-.048l.02-.02.004-.004a12.496 12.496 0 003.763-6.117.487.487 0 00.066-.235zM23.734 26H8.266a11.503 11.503 0 01-3.214-5h3.33a.5.5 0 000-1H4.779a11.38 11.38 0 011.461-8.553l2.379 2.38a.5.5 0 00.707-.708l-2.51-2.51A11.474 11.474 0 0115.5 6.024V9.5a.5.5 0 001 0V6.025a11.474 11.474 0 018.684 4.583l-2.512 2.513a.5.5 0 10.707.707l2.381-2.38A11.38 11.38 0 0127.221 20h-3.603a.5.5 0 000 1h3.33a11.503 11.503 0 01-3.214 5z" />
      <path d="M21.425 15.426a.498.498 0 00-.01-.061l-.004-.005a.497.497 0 00-.086-.131c-.008-.01-.012-.023-.021-.032a.538.538 0 00-.058-.039.503.503 0 00-.105-.069l-.005-.003a.47.47 0 00-.067-.012.484.484 0 00-.12-.022.493.493 0 00-.106.02.48.48 0 00-.078.014l-4.816 1.926a3.413 3.413 0 00-2.343.934 3.5 3.5 0 002.28 6.052c.04.002.079.002.117.002a3.5 3.5 0 003.495-3.387c0-.026-.006-.05-.005-.076l1.916-4.789v-.002l.005-.01a.436.436 0 00.011-.31zm-3.716 6.898a2.564 2.564 0 01-1.79.675A2.5 2.5 0 0115.998 18l.083.001a2.5 2.5 0 011.629 4.323zm-.08-4.907l2.424-.97-.971 2.428a3.502 3.502 0 00-1.454-1.458z" />
    </SvgContainer>
  );
}
