import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgNext(props) {
  return (
    <SvgContainer title="Next Icon" {...props}>
      <path d="M50.5 32.1v.19a2.94 2.94 0 0 1-1 2.14l-17.19 15.2a3 3 0 0 1-2 .76 3 3 0 0 1-2-5.25L39.79 35H17a3 3 0 0 1 0-6h23L28.46 18a3 3 0 0 1 4.14-4.34l17 16.19a3 3 0 0 1 .9 2.25Z" />
    </SvgContainer>
  );
}
