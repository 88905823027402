import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredNotes(props) {
  return (
    <SvgContainer title="Notes Icon" {...props}>
      <defs>
        <style type="text/css">
          {".notes-st0{fill:#fff}"}
        </style>
      </defs>
      <path className="notes-st0" d="M1 5h24v15H1z" /><path d="M26 21H1V5h25v16zM2 20h23V6H2v14z" fill="#3e4c56" /><path transform="rotate(144.845 20.878 21.45)" fill="#f68833" d="M13.4 17.5h15v7.9h-15z" /><path d="M23.7 14.6l2-1.4c.8-.5 1.8-.3 2.3.4l2.6 3.7c.5.8.3 1.8-.4 2.3l-2 1.4-4.5-6.4z" fill="#f15b60" /><path className="notes-st0" d="M17 29l-7.3.3 2.8-6.8z" /><path fill="#0d0e0e" d="M12.5 29.2l-1.7-2.5-1.1 2.6z" /><g><path d="M9.4 29.5l2.9-7.2h.1L25.7 13c.4-.3.9-.4 1.4-.3.5.1.9.4 1.2.8l2.7 3.8c.3.4.4.9.3 1.4-.1.5-.4.9-.8 1.2l-2.1 1.5-11.3 7.9-7.7.2zm3.3-6.9L10.1 29l6.9-.3 13.2-9.3c.3-.2.5-.5.6-.9.1-.4 0-.7-.2-1l-2.7-3.8c-.2-.3-.5-.5-.9-.6-.4-.1-.7 0-1 .2l-2.1 1.5-11.2 7.8z" /></g><path d="M16.9 28.9l-4.6-6.4.5-.3 4.5 6.4z" /><path transform="rotate(-35.333 25.987 17.801)" d="M25.7 14h.5v7.6h-.5z" /><path fill="none" d="M1.7 9.5h23.5v9.3H1.7z" /><text transform="translate(5.122 15.216)" fontSize="8" fontFamily="HelveticaNeue-Medium">ABC</text>
    </SvgContainer>
  );
}
