import combineReducers from 'helpers/combineReducers';

import rootReducers, { stateHelper } from './quizzes';
import notesReducers from './notes';
import highlightsReducers from './highlights';
import strikeThroughReducers from './strikeThroughs';
import quizBlockReducers from './quizBlocks';


export default stateHelper.createReducer(combineReducers([
  rootReducers,
  notesReducers,
  highlightsReducers,
  strikeThroughReducers,
  quizBlockReducers
]));
