import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgPhone(props) {
  return (
    <SvgContainer title="Phone Icon" {...props}>
      <path d="M22.282 18.202a1.503 1.503 0 00-2.12 0l-2.122 2.122c-.433.43-2.3-.18-4.243-2.122a9.343 9.343 0 01-1.98-2.758c-.296-.691-.351-1.273-.14-1.484l2.12-2.121a1.5 1.5 0 000-2.121L9.556 5.475a1.503 1.503 0 00-2.121 0l-2.122 2.12c-2.974 2.974-1.11 9.498 4.243 14.85a20.292 20.292 0 008.16 5.178 10.163 10.163 0 002.902.466 5.18 5.18 0 003.787-1.401l2.121-2.121a1.501 1.501 0 000-2.121zM8.141 6.182a.5.5 0 01.707 0l4.243 4.243a.5.5 0 010 .707l-1.768 1.767-4.95-4.95zm9.861 20.483a19.275 19.275 0 01-7.74-4.927C5.618 17.094 3.68 11.41 5.7 8.691l4.991 4.99a2.793 2.793 0 00.207 2.157 10.353 10.353 0 002.192 3.071c1.41 1.41 3.232 2.54 4.542 2.54a1.723 1.723 0 00.69-.137l4.988 4.989c-1.216.898-3.072 1.036-5.308.364zm7.816-2.806l-1.768 1.768-4.95-4.95 1.768-1.768a.503.503 0 01.707 0l4.243 4.243a.5.5 0 010 .707zM21.222 11.278a.498.498 0 00.354-.146l2.828-2.83a.5.5 0 10-.707-.706l-2.828 2.829a.5.5 0 00.353.853zM19.1 9.156a.5.5 0 00.5-.5V4.414a.5.5 0 00-1 0v4.242a.5.5 0 00.5.5zM27.586 12.4h-4.243a.5.5 0 000 1h4.243a.5.5 0 000-1z" />
    </SvgContainer>
  );
}
