import Factory from 'factories/_Factory';


export default class DentalTemplate extends Factory {
  static jsonType = 'dental_template';

  static get defaults() {
    return {
      patient: '',
      chief_complaint: '',
      history: '',
      current_findings: ''
    };
  }
}
