import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgOpenBookDark(props) {
  return (
    <SvgContainer title="Open Book Icon" {...props}>
      <path fill="none" d="M0 0h32v32H0z" />
      <path d="M25.013 11.06h-.684V8.353a.668.668 0 00-.402-.615A8.366 8.366 0 0020.563 7h-.09A8.378 8.378 0 0016.1 8.221 8.73 8.73 0 0011.637 7a8.373 8.373 0 00-3.364.738.682.682 0 00-.402.615v2.707h-.684a.692.692 0 00-.485.197.673.673 0 00-.202.478v12.18a.666.666 0 00.364.596.698.698 0 00.707-.028 7.281 7.281 0 014.071-1.242c1.453 0 2.872.433 4.072 1.242a.696.696 0 00.772 0 7.281 7.281 0 014.072-1.242c1.453 0 2.872.433 4.071 1.242a.692.692 0 00.972-.21.67.67 0 00.099-.348v-12.19a.673.673 0 00-.202-.478.692.692 0 00-.485-.197zM20.55 8.353c.825.007 1.643.16 2.414.45v12.103a8.508 8.508 0 00-6.18.481V9.418a7.514 7.514 0 013.766-1.065zm-11.307.45a6.989 6.989 0 016.172.613V21.4a8.387 8.387 0 00-6.172-.494V8.804z" fill="#101010" />
    </SvgContainer>
  );
}
