import { List } from 'immutable';

import Factory from 'factories/_Factory';
import PatientTabContent from 'factories/PatientTabContent';


export default class PatientTab extends Factory {
  static jsonType = 'patient_tab';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;
    if (attributes.patient_tab_contents || newRecord.get('patient_tab_contents') === undefined) {
      const patientTabContents = (attributes.patient_tab_contents || []).map(patientTabContent => new PatientTabContent(patientTabContent));
      newRecord = newRecord.set('patient_tab_contents', new List(patientTabContents));
    }

    return newRecord;
  }

  static get belongsTo() {
    return ['patient_tab_group'];
  }

  static get defaults() {
    return {
      header: '',
      order: 0,
    };
  }
}
