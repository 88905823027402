import React from 'react';
import { connect } from 'react-redux';

import TabbedSections from 'components/menus/TabbedSections';
import Section from 'components/Section';
import Table, { TableColumn } from 'components/lists/Table';
import WYSIWYG from 'components/WYSIWYG';


// StyleGuide = containers/TakeQuizPage/lab-values-modal

class LabValuesModalClass extends React.Component {
  render() {
    const { staticData } = this.props;
    const labValues = staticData.get('lab_values').toList().toArray();
    return (
      <div className="LabValues">
        <TabbedSections styleVariant="QuestionModalTabs">
          {labValues.map(labValue => (
            <Section type="none" name={labValue.get('tab_name')} key={labValue.get('id')} className="p-2">
              {labValue.get('sections').toList().toArray().map(section => (
                <Table
                  key={section.get('id')}
                  rowData={section.get('items').toList().toArray()}
                  className="mb-2"
                  rowBorder
                  headerBorder
                >
                  <TableColumn
                    className="lab-value-header"
                    cellValue={rowItem => (
                      <WYSIWYG>{rowItem.get('name')}</WYSIWYG>
                    )}
                  >
                    <b>{section.get('heading')}</b>
                  </TableColumn>
                  <TableColumn
                    className="lab-value-header"
                    cellValue={rowItem => (
                      <WYSIWYG className="lab-value-cell">{rowItem.get('ref_range')}</WYSIWYG>
                    )}
                  >
                    <b>Reference Range</b>
                  </TableColumn>
                  <TableColumn
                    className="lab-value-header"
                    cellValue={rowItem => (
                      <WYSIWYG className="lab-value-cell">{rowItem.get('si_ref_intervals')}</WYSIWYG>
                    )}
                  >
                    <b>SI Reference Intervals</b>
                  </TableColumn>
                </Table>
              ))}
            </Section>
          ))}
        </TabbedSections>
      </div>
    );
  }
}

function mapStateToProps({
  staticData
}) {
  return {
    staticData
  };
}

export default connect(mapStateToProps, null)(LabValuesModalClass);
