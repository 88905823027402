import React from "react";
import SvgContainer from './SvgContainer';

const PsiGoTo = props => (
  <SvgContainer title="GoTo Icon" {...props}>
    <linearGradient id="PsiGoTo_svg__SVGID_1_" gradientUnits="userSpaceOnUse" x1="15.426" y1="4.274" x2="25.592" y2="21.883"><stop offset=".433" stopColor="#e4826b" /><stop offset=".688" stopColor="#f5d4c7" /><stop offset=".797" stopColor="#fff" /></linearGradient><path fill="url(#PsiGoTo_svg__SVGID_1_)" d="M18.5 2.5V26l12.1-11.8z" /><path d="M16.5 6.5V30l12.1-11.8z" /><linearGradient id="PsiGoTo_svg__SVGID_2_" gradientUnits="userSpaceOnUse" x1="15.426" y1="4.274" x2="25.592" y2="21.883"><stop offset=".433" stopColor="#e4826b" /><stop offset=".688" stopColor="#f5d4c7" /><stop offset=".797" stopColor="#fff" /></linearGradient><path fill="url(#PsiGoTo_svg__SVGID_2_)" d="M18.5 2.5V26l12.1-11.8z" /><g><path d="M2.3 6.8v23.5l12-11.8z" /><linearGradient id="PsiGoTo_svg__SVGID_3_" gradientUnits="userSpaceOnUse" x1="1.181" y1="4.586" x2="11.347" y2="22.195"><stop offset=".433" stopColor="#e4826b" /><stop offset=".688" stopColor="#f5d4c7" /><stop offset=".797" stopColor="#fff" /></linearGradient><path fill="url(#PsiGoTo_svg__SVGID_3_)" d="M4.3 2.8v23.5l12-11.8z" /></g><g><path d="M18.7 26.2h-1V2.8l.9-.4 12 11.8-.7.7L18.7 4z" /></g><g><path d="M4.8 26.2h-1V2.8l.9-.4 12 11.8-.7.7L4.8 4z" /></g>
  </SvgContainer>
);

export default PsiGoTo;
