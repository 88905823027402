import Factory from 'factories/_Factory';


export default class CmeCertificate extends Factory {
  static jsonType = 'cme_certificate';

  static get defaults() {
    return {
      hours_used: 0,
      questions_logged_for_credit: new Set(),
      document_url: '',
      date_range: ''
    };
  }

  static get belongsTo() {
    return ['cme_activity', 'cme_credit_tracker'];
  }
}
