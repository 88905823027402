import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import useWindowDimensions from "helpers/windowDimensions";
import PageSection from './PageSection';
import CardsCarousel from './components/CardsCarousel';


const ProductCard = ({
  name, duration, questionCount, price, cmeCredits, path
}) => {
  const { width } = useWindowDimensions();
  const mediumWeb = width > 820 && width < 1360;
  const isCME = cmeCredits !== undefined;
  const calculatedDuration = () => {
    if (duration === 31) return "1 month";
    if (duration === 93) return "3 months";
    if (duration === 185) return "6 months";
    if (duration === 365) return "12 months";
    if (duration === 730) return "24 months";
  };
  const calculatedPrice = () => `$${price / 100}`;
  const largerMargin = (isCME && cmeCredits.length === 1) || !isCME;
  const characterLimit = mediumWeb ? 30 : 35;
  const multipleCredits = cmeCredits && cmeCredits.length > 1;
  const longName = name.length > characterLimit;


  const deriveMargin = () => {
    if (longName && mediumWeb) return 2;
    if (longName && largerMargin) return 3;
    if (longName) return 4;
    if (largerMargin) return 5;
    if (multipleCredits) return 0;
    return 2;
  };

  const cmeCreditText = () => (
    <>
      {cmeCredits.map((credit, i) => {
        if (credit.type.match(/^AMA PRA Category 1/g)) return <div key={i}><span>{`${credit.cme_hours}`} <i>{`${credit.type}`}</i></span><br /></div>;
        return <div key={i}><span key={i}>{`${credit.cme_hours} ${credit.type}`}</span><br /></div>;
      })}
    </>
  );

  return (
    <div className="w-100">
      <div><b>{name}</b></div>
      <div>{isCME ? cmeCreditText() : `${questionCount}+ questions`}</div>
      <div className={`flex-column justify-content-end pt-${deriveMargin()}`}>
        <div className="mb-4 mt-6 d-flex align-items-end"><b>{`${calculatedDuration()}: ${calculatedPrice()}`}</b></div>
        <div className="mb-3 d-flex align-items-end"><a href={`/${path}`}><Button>View Details</Button></a></div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  questionCount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  cmeCredits: PropTypes.array,
  path: PropTypes.string.isRequired
};

const ProductCards = ({ products, isCME = false, displayCount }) => {
  const { width } = useWindowDimensions();
  const smallWeb = width < 990 && width > 820;
  const { products: nonCmeProducts, cme_products: cmeProducts } = products;
  const neededProducts = isCME ? cmeProducts : [...nonCmeProducts, ...cmeProducts];
  const productCards = neededProducts.map(prod => (
    <ProductCard
      key={prod.id}
      name={prod.name}
      duration={prod.duration}
      questionCount={prod.question_count}
      price={prod.price}
      cmeCredits={prod.cme_credits}
      path={prod.path}
    />
  ));
  return (
    <PageSection section="product-cards">
      <h2 className="storefront-secondary-title text-center mb-5">Explore More Products</h2>
      <CardsCarousel cards={productCards} displayCount={displayCount} dotWidth={10} dotHeight={10} paginationDotsClass="mt-5 pagination-dots d-flex justify-content-center " cardClassName={`product-card ${!smallWeb ? 'col' : ''} pt-4 px-6 pb-2 h-100 mx-4`} className="align-items-center align-self-center justify-content-center w-100" recommendationCards />
    </PageSection>
  );
};


ProductCards.propTypes = {
  products: PropTypes.shape({
    products: PropTypes.array,
    cme_products: PropTypes.array
  }).isRequired,
  isCME: PropTypes.bool,
  displayCount: PropTypes.number.isRequired
};

export default ProductCards;
