import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueTimer(props) {
  return (
    <SvgContainer title="Timer Icon" {...props}>
      <path d="M34 9a23 23 0 0 0-22.8 20H8.72A1.72 1.72 0 0 0 7 30.72v3.56A1.72 1.72 0 0 0 8.72 36h2.64A23 23 0 1 0 34 9Zm0 43a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" className="cls-1" />
      <path className="cls-1" d="M39.7 41.44 32 34.6V19h3v14.25l6.69 5.94-1.99 2.25z" />
    </SvgContainer>
  );
}
