import StateHelper from 'helpers/StateHelper';
import ClinicalPearl from 'factories/ClinicalPearl';

import { CLINICAL_PEARLS_FETCHED } from 'actions/clinicalPearls';
import { QUESTION_BANK_SELECTED } from 'actions/questionBanks';

const stateHelper = new StateHelper(ClinicalPearl);
export const { initialState } = stateHelper;
const reducers = {};

reducers[CLINICAL_PEARLS_FETCHED] = stateHelper.set;
reducers[QUESTION_BANK_SELECTED] = stateHelper.resetAndSet;

export default stateHelper.createReducer(reducers);
