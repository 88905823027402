import api, { selectedQuestionBankUrl } from 'helpers/api';


export const HIGHLIGHT_SYNCING = 'HIGHLIGHT_SYNCING';
export const HIGHLIGHT_SYNCED = 'HIGHLIGHT_SYNCED';
export const HIGHLIGHT_SYNC_ERROR = 'HIGHLIGHT_SYNC_ERROR';

const getHighlightSyncKey = (quizId, questionId) => `${quizId}/highlights/${questionId}`;
export const highlightSync = (quizId, questionId, ranges) => (dispatch, getState) => {
  const key = getHighlightSyncKey(quizId, questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/highlights`, {
    highlight: { ranges }
  })
    .then((response) => {
      dispatch({
        type: HIGHLIGHT_SYNCED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: HIGHLIGHT_SYNC_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: HIGHLIGHT_SYNCING,
    payload: { key, promise }
  });
  return promise;
};
highlightSync.getKey = getHighlightSyncKey;

export const HIGHLIGHT_SYNC_OFFLINE = 'HIGHLIGHT_SYNC_OFFLINE';
export const highlightSyncOffline = (quizId, questionId, ranges) => (dispatch) => {
  dispatch({
    type: HIGHLIGHT_SYNC_OFFLINE,
    payload: {
      quizId,
      questionId,
      ranges
    }
  });
};
