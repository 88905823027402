import React from "react";
import { formatString } from "./homePageHelpers";

export default function HomeAdvantage({ advantageTextContent }) {
  const { infoSectionTitle, infoHeaderText, infoDescriptionText } = advantageTextContent;

  return (
    <div className="HomeAdvantage home-section-wrapper pt-5 pt-md-6 pb-6">
      <div className="container py-lg-6">
        <div className="advantage-wrapper row d-flex flex-wrap justify-content-md-center mt-5 mt-lg-6 mb-6 py-lg-5 px-3 px-md-0">
          <div className="col col-12 col-lg-7 d-flex align-items-center p-0 pb-6 pb-md-6 pb-lg-0">
            <img className="img-fluid mb-4 mb-md-3 mb-lg-0" loading="lazy" alt="Achieve Excellence from Board Exams to CME Online" src="/images/homepage/board-review-sample-question-features.png" />
          </div>
          <div className="col col-12 col-lg-5 d-flex align-items-center">
            <div>
              <h2
                className="storefront section-title mt-3 mt-md-0"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(infoSectionTitle) || "THE BOARDVITALS ADVANTAGE" }}
              />
              <h2
                className="storefront section-header header-style py-2 py-lg-3 my-6"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(infoHeaderText) || "Achieve Excellence from Board Exams to CME" }}
              />
              {infoDescriptionText ? (
                /* eslint-disable-next-line react/no-danger */
                <p className="storefront m-1" dangerouslySetInnerHTML={{ __html: formatString(infoDescriptionText) }} />
              ) : (
                <p className="storefront m-1">BoardVitals empowers practitioners to achieve career excellence with comprehensive online healthcare and medical education content and learning tools for <a className="storefront" href="/medical-board-reviews">Board Exam Prep</a> & <a className="storefront" href="/cme">Continuing Medical Education</a>.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
