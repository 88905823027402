import StateHelper from 'helpers/StateHelper';
import RiskAssessment from 'factories/RiskAssessment';

import { RISK_ASSESSMENT_SYNCED, RISK_UNLOAD } from 'actions/riskAssessment';

const stateHelper = new StateHelper(RiskAssessment);
export const { initialState } = stateHelper;
const reducers = {};

reducers[RISK_ASSESSMENT_SYNCED] = stateHelper.resetAndSet;

reducers[RISK_UNLOAD] = stateHelper.reset;

export default stateHelper.createReducer(reducers);
