import React from "react";
import SvgContainer from "./SvgContainer";

export default function Help (props) {
  return (
    <SvgContainer height={99} width={99} viewBoxHeight={99} viewBoxWidth={99} name="help" {...props}>
      <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="49.133" cy="49.133" r="49.133" fill="#FFF2DB" />
        <path d="M29.3645 50.9346C26.7724 50.9346 24.6533 53.044 24.6533 55.6243V57.9538C24.6533 60.5341 26.7724 62.6435 29.3645 62.6435H30.5192C33.1113 62.6435 35.2304 60.5341 35.2304 57.9538V55.6243C35.2304 53.044 33.1113 50.9346 30.5192 50.9346H29.3645ZM32.7858 55.6243V57.9538C32.7858 59.195 31.7661 60.21 30.5192 60.21H29.3645C28.1176 60.21 27.098 59.2011 27.098 57.9538V55.6243C27.098 54.3831 28.1114 53.3681 29.3645 53.3681H30.5192C31.7661 53.3681 32.7858 54.3769 32.7858 55.6243Z" fill="#1B3950" />
        <path d="M23.6702 71.0934C23.6702 69.0757 25.3164 67.4309 27.3495 67.4309H32.5397C34.3947 67.4309 35.9303 68.8127 36.176 70.592H29.8924V73.0255H38.5285L41.3294 65.3153H55.6779L51.6546 73.8143H21.4406V76.2478H75.0507V73.8143H54.3511L59.523 62.8818H39.6096L37.7669 67.9506C36.692 66.1836 34.751 64.9974 32.5336 64.9974H27.3433C23.9773 64.9974 21.2256 67.7366 21.2256 71.0873V72.1022H23.6702V71.0873V71.0934Z" fill="#1B3950" />
        <path d="M69.9343 21.2251H46.5997C43.5469 21.2251 41.0654 23.6953 41.0654 26.7341V42.2645C41.0654 45.3033 43.5469 47.7735 46.5997 47.7735H48.6451L53.9397 53.631L54.8427 52.561C54.8427 52.561 57.0048 50.0052 59.0931 47.7674H69.9343C72.9871 47.7674 75.4686 45.2972 75.4686 42.2584V26.7341C75.4686 23.6953 72.9871 21.2251 69.9343 21.2251ZM73.0239 42.2645C73.0239 43.9582 71.6358 45.34 69.9343 45.34H58.0367L57.6743 45.7191C56.2738 47.1988 54.7997 48.8741 53.8845 49.9319L49.7322 45.3339H46.5997C44.8982 45.3339 43.5101 43.9521 43.5101 42.2584V26.7341C43.5101 25.0404 44.8982 23.6586 46.5997 23.6586H69.9343C71.6358 23.6586 73.0239 25.0404 73.0239 26.7341V42.2645Z" fill="#1B3950" />
        <path d="M60.862 26.8505C60.4382 26.5387 59.9345 26.3063 59.3633 26.1596C58.7921 26.0128 58.1717 25.9395 57.5083 25.9395C56.8449 25.9395 56.1386 26.0189 55.4875 26.184C54.8364 26.3491 54.2344 26.5937 53.6816 26.9239V30.14C54.216 29.6692 54.7873 29.3207 55.3892 29.0883C55.9912 28.856 56.5685 28.7398 57.1275 28.7398C57.3855 28.7398 57.6189 28.7765 57.8277 28.856C58.0365 28.9355 58.2208 29.0394 58.3621 29.1739C58.5034 29.3084 58.6201 29.4735 58.6999 29.6631C58.7798 29.8526 58.8166 30.0544 58.8166 30.2745C58.8166 30.5313 58.7798 30.7637 58.7061 30.9777C58.6324 31.1917 58.5279 31.3934 58.3867 31.5891C58.2454 31.7848 58.0673 31.9804 57.8646 32.1761C57.6619 32.3717 57.4223 32.5857 57.1521 32.812C56.8941 33.026 56.6668 33.24 56.4764 33.4601C56.286 33.6741 56.1201 33.9064 55.9912 34.1449C55.8622 34.3833 55.7639 34.6401 55.7025 34.9092C55.641 35.1782 55.6042 35.4778 55.6042 35.808C55.6042 36.0159 55.6226 36.236 55.6656 36.4683C55.7086 36.7007 55.7577 36.9086 55.8315 37.092H58.6569C58.5771 36.9697 58.5156 36.823 58.4665 36.6456C58.4174 36.4683 58.3989 36.291 58.3989 36.1198C58.3989 35.8691 58.4297 35.649 58.4972 35.4534C58.5648 35.2577 58.6569 35.0743 58.7798 34.8969C58.9026 34.7196 59.0623 34.5484 59.2527 34.3711C59.4431 34.1938 59.6643 33.9981 59.91 33.7964C60.2846 33.4907 60.6102 33.191 60.8989 32.9037C61.1876 32.6163 61.4271 32.3167 61.6175 31.9988C61.8141 31.6869 61.9553 31.3506 62.0598 30.996C62.1642 30.6414 62.2133 30.2501 62.2133 29.8159C62.2133 29.1311 62.0966 28.5442 61.8509 28.055C61.6114 27.5659 61.2797 27.1684 60.8559 26.8505H60.862Z" fill="#1B3950" />
        <path d="M57.4038 38.2476C56.8325 38.2476 56.3534 38.4126 55.9726 38.7489C55.5918 39.0852 55.4014 39.501 55.4014 40.0024C55.4014 40.5037 55.5918 40.9073 55.9726 41.2619C56.3534 41.6043 56.8264 41.7755 57.4038 41.7755C57.9811 41.7755 58.448 41.6104 58.8226 41.2742C59.185 40.9379 59.3693 40.516 59.3693 40.0024C59.3693 39.4888 59.185 39.0791 58.8104 38.755C58.4357 38.4188 57.9689 38.2537 57.4038 38.2537V38.2476Z" fill="#1B3950" />
      </svg>
    </SvgContainer>
  );
}
