import { useRef, useCallback } from 'react';

// Using a callback makes it easier to pass down a ref as a prop

export default function useInView(setState) {
  const ref = useRef(null);
  const refCallback = useCallback((node) => {
    if (ref.current) ref.current.disconnect();
    ref.current = new IntersectionObserver((entries) => {
      setState(entries[0].isIntersecting);
    });
    if (node) ref.current.observe(node);
  }, []);

  return refCallback;
}
