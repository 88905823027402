import * as React from "react";
import SvgContainer from './SvgContainer';

export default function VitalPrepLogo(props) {
  return (
    <SvgContainer x="0" y="0" viewBoxWidth="625" viewBoxHeight="106" {...props}>
      <path fill="#fff" d="M113.4 12.8h14.5v81.9h-14.5zM196.2 12.8h-61.7V25h23.6v69.7h14.5V25h23.6zM219.1 12.8l-26.7 81.9h14.8l6.4-22.2h27.5l6.3 22.2h15.4l-26.7-81.9h-17zM217 61.1l2.9-10c2.6-8.7 5-17.9 7.3-27h.5c2.4 9 4.8 18.3 7.4 27l2.9 10h-21zM284.9 12.8h-14.5v81.9h48.5V82.5h-34zM385.8 12.8H360v81.9h14.5V64.6h11.8c17.6 0 31.1-8.3 31.1-26.5.1-19-13.4-25.3-31.6-25.3zm-.7 40.2h-10.6V24.4h10.1c12.2 0 18.5 3.5 18.5 13.6s-5.8 15-18 15zM485.2 37.1c0-18.3-13.1-24.3-30.1-24.3h-27.6v81.9H442v-32h12.5l17.6 32h16.3l-19.6-34.1c9.9-3.5 16.4-11.1 16.4-23.5zm-31.6 14H442V24.4h11.6c11.2 0 17.3 3.3 17.3 12.7 0 9.3-6.1 14-17.3 14zM510.8 58.2h29.4V46h-29.4V25h34.7V12.8h-49.3v81.9h50.5V82.5h-35.9zM582.6 12.8h-25.8v81.9h14.5V64.6h11.8c17.6 0 31.1-8.3 31.1-26.5 0-19-13.5-25.3-31.6-25.3zm-.7 40.2h-10.6V24.4h10.1c12.2 0 18.5 3.5 18.5 13.6s-5.8 15-18 15zM65.6 52.8l-8.2 14.3-23.1-40.6 15.9-.1 7.4 12.7 15.3-26.5H10.8l47.4 82.1 47.3-82.1H88.8z" />
    </SvgContainer>
  );
}
