import Response from 'factories/Response';

import api, { selectedQuestionBankUrl } from 'helpers/api';
import { getServerTimeNow } from 'helpers/dateTime';
import { responseEvaluation, responseAnswerIds } from 'helpers/response';


const responseSyncKey = (quizId, questionId) => `quizzes/${quizId}/responses/${questionId}`;
const responseEvaluateKey = (quizId, questionId) => `quizzes/${quizId}/responses/${questionId}/evaluate`;

export const RESPONSE_SET = 'RESPONSE_SET';
export const responseSet = (quizId, questionId, selectedAnswer, hotspotIsCorrect) => (dispatch, getState) => {
  const {
    questions,
    loading,
    responses,
    quizzes
  } = getState();
  const question = questions.get(questionId);
  if (loading[responseSyncKey(quizId, questionId)]) return;
  const quiz = quizzes.get(quizId);
  let response = responses.getIn([quizId, questionId]);

  if (!response) {
    response = new Response({
      relationships: {
        quiz: { data: { id: quizId } },
        question: { data: { id: questionId } }
      }
    });
  }

  const tutorMode = quiz?.get('type') === 'tutor';
  const ungraded = tutorMode && (response.get('correct') !== null && response.get('correct') !== undefined);
  const inputAttribute = ungraded ? 'ungraded_user_input' : 'user_input';
  const evaluationAttribute = ungraded ? 'ungraded_correct' : 'correct';

  // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
  const answeredBefore = response.get(inputAttribute) !== null || (tutorMode && response.get('evaluated_at') !== null);
  const correctBefore = response.get(evaluationAttribute);
  let totalAnswered = quiz.get('answeredCount');
  let totalAnsweredCorrectly = quiz.get('correctCount');
  let totalAnsweredIncorrectly = quiz.get('incorrectCount');

  if (question.get('aact_type')) {
    response = response.set(inputAttribute, JSON.stringify(selectedAnswer));
  } else {
    switch (question.get('type')) {
      case 'HotspotQuestion':
        response = response.set(inputAttribute, `${selectedAnswer},${!!hotspotIsCorrect}`);
        break;
      case 'OpenEndedQuestion':
      case 'FillInBlankQuestion': {
        response = response.set(inputAttribute, selectedAnswer);
        break;
      }
      case 'SortableQuestion':
      case 'DragDropCloze':
      case 'MatrixDropdown':
      case 'DropdownTable':
        response = response.set(inputAttribute, JSON.stringify(selectedAnswer));
        break;
      case 'MultipleChoiceQuestion':
      case 'GraphicChoiceQuestion':
      default: {
        // Extract Answer IDs
        let answerIds = responseAnswerIds(response, ungraded);
        const answerSelected = answerIds.indexOf(selectedAnswer) !== -1;
        if (question.get('answers').filter(answer => answer.get('is_correct')).size > 1) {
          // Multiple-select update
          if (answerSelected) {
            answerIds.splice(answerIds.indexOf(selectedAnswer), 1);
          } else {
            answerIds.push(selectedAnswer);
          }
        } else if (answerSelected) {
          answerIds = '';
        } else {
          answerIds = [selectedAnswer];
        }
        response = response.set(inputAttribute, answerIds ? JSON.stringify(answerIds) : answerIds);
        break;
      }
    }
  }

  response = response.set(evaluationAttribute, null)
    .set('synced', false)
    .set('updated_at', getServerTimeNow());

  if (!ungraded) {
    // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
    const answeredAfter = response.get(inputAttribute) !== null || (tutorMode && response.get('evaluated_at') !== null);
    if (answeredBefore !== answeredAfter) totalAnswered += answeredAfter ? 1 : -1;
    if (correctBefore === true) totalAnsweredCorrectly -= 1;
    if (correctBefore === false) totalAnsweredIncorrectly -= 1;
  }

  dispatch({
    type: RESPONSE_SET,
    payload: {
      quizId,
      questionId,
      response,
      totalAnswered,
      totalAnsweredCorrectly,
      totalAnsweredIncorrectly
    }
  });
};

export const RESPONSE_EVALUATE_LOCAL = 'RESPONSE_EVALUATE_LOCAL';
export const responseEvaluateLocal = (quizId, questionId) => (dispatch, getState) => {
  const { questions, responses, quizzes } = getState();
  const question = questions.get(questionId);
  const quiz = quizzes.get(quizId);
  const response = responses.getIn([quizId, questionId]);

  if (!response) {
    // TODO: I think this should create a new Response record evaluated to incorrect
    console.warn('Could not find a Response to grade');
    return;
  }

  const tutorMode = quiz?.get('type') === 'tutor';
  const ungraded = tutorMode && response.get('correct') !== null;

  // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
  const answeredBefore = response.get('user_input') !== null || (tutorMode && response.get('evaluated_at') !== null);
  const correctBefore = response.get('correct');
  let totalAnswered = quiz.get('answeredCount');
  let totalAnsweredCorrectly = quiz.get('correctCount');
  let totalAnsweredIncorrectly = quiz.get('incorrectCount');

  // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
  const answeredAfter = response.get('user_input') !== null || tutorMode;
  const correctAfter = responseEvaluation(response, question, ungraded);

  if (answeredBefore !== answeredAfter) totalAnswered += answeredAfter ? 1 : -1;
  if (!ungraded && correctBefore !== correctAfter) {
    if (correctBefore === true) totalAnsweredCorrectly -= 1;
    if (correctBefore === false) totalAnsweredIncorrectly -= 1;
    if (correctAfter === true) totalAnsweredCorrectly += 1;
    if (correctAfter === false) totalAnsweredIncorrectly += 1;
  }


  const payload = {
    quizId,
    questionId,
    totalAnswered,
    totalAnsweredCorrectly,
    totalAnsweredIncorrectly
  };
  payload[ungraded ? 'ungraded_correct' : 'correct'] = correctAfter;

  dispatch({
    type: RESPONSE_EVALUATE_LOCAL,
    payload
  });
};

export const RESPONSE_EVALUATING = "RESPONSE_EVALUATING";
export const RESPONSE_EVALUATED = "RESPONSE_EVALUATED";
export const RESPONSE_EVALUATION_ERROR = "RESPONSE_EVALUATION_ERROR";

export const responseEvaluate = (quizId, questionId) => (dispatch, getState) => {
  const {
    quizzes, loading, responses, questions
  } = getState();
  const quiz = quizzes.get(quizId);
  const response = responses.getIn([quizId, questionId]);
  const question = questions.get(questionId);
  const key = responseEvaluateKey(quizId, questionId);

  if (!quiz) return Promise.reject(new Error('Quiz not found'));
  if (loading.has(key)) return loading.get(key);


  const tutorMode = quiz.get('type') === 'tutor';
  const ungraded = tutorMode && (response?.get('correct') || null) !== null;

  // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
  const answeredBefore = !!response && (response.get('user_input') !== null || (tutorMode && response.get('evaluated_at') !== null));
  const correctBefore = response ? response.get('correct') : null;
  let totalAnswered = quiz.get('answeredCount');
  let totalAnsweredCorrectly = quiz.get('correctCount');
  let totalAnsweredIncorrectly = quiz.get('incorrectCount');

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/evaluate`)
    .then((apiResponse) => {
      // Evaluating a Tutor Mode Response makes it "answered" in the scope of the Quiz
      const answeredAfter = apiResponse.data.attributes.user_input !== null || tutorMode;
      const correctAfter = apiResponse.data.attributes.correct;

      if (answeredBefore !== answeredAfter) totalAnswered += answeredAfter ? 1 : -1;
      if (!ungraded && correctBefore !== correctAfter) {
        if (correctBefore === true) totalAnsweredCorrectly -= 1;
        if (correctBefore === false) totalAnsweredIncorrectly -= 1;
        if (correctAfter === true) totalAnsweredCorrectly += 1;
        if (correctAfter === false) totalAnsweredIncorrectly += 1;
      }


      dispatch({
        type: RESPONSE_EVALUATED,
        payload: {
          key,
          quizId,
          questionId,
          totalAnswered,
          totalAnsweredCorrectly,
          totalAnsweredIncorrectly,
          ...apiResponse
        }
      });
      return apiResponse;
    })
    .catch((error) => {
      dispatch({
        type: RESPONSE_EVALUATION_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: RESPONSE_EVALUATING,
    payload: { key, promise }
  });

  return promise;
};
responseEvaluate.getKey = responseEvaluateKey;

export const RESPONSE_SYNCING = 'RESPONSE_SYNCING';
export const RESPONSE_SYNCED = 'RESPONSE_SYNCED';
export const RESPONSE_SYNC_ERROR = 'RESPONSE_SYNC_ERROR';
export const responseSync = (quizId, questionId, event, time, ignoreEvaluate = false) => (dispatch, getState) => {
  const key = responseSyncKey(quizId, questionId);

  const { quizzes, responses, questions } = getState();

  const quiz = quizzes.get(quizId);
  if (!quiz) return Promise.reject(new Error('Quiz not found'));

  const response = responses.getIn([quizId, questionId]);
  const question = questions.get(questionId);

  if ((!response || response.get('synced')) && (!event || !time)) {
    return Promise.resolve('Nothing to sync');
  }

  const { loading } = getState();
  // If question type is aact then allow multiple response set
  const isAact = response?.get('is_aact');
  if (loading.has(key) && !isAact) return loading.get(key);

  const requestBody = {};

  if (response && !response.get('synced')) {
    requestBody.response = {
      user_input: response.get('user_input'),
      ungraded_user_input: response.get('ungraded_user_input')
    };
  }

  if (event && time) {
    requestBody.time_slice = { event, time };
  }

  if (ignoreEvaluate) {
    requestBody.ignore_evaluation = true;
  }

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/response`, requestBody)
    .then((apiResponse) => {
      dispatch({
        type: RESPONSE_SYNCED,
        payload: {
          key,
          quizId,
          questionId,
          ...apiResponse
        }
      });
      return apiResponse;
    })
    .catch((error) => {
      dispatch({
        type: RESPONSE_SYNC_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: RESPONSE_SYNCING,
    payload: { key, promise }
  });

  return promise;
};
responseSync.getKey = responseSyncKey;

const responseTimeSpentKey = (quizId, questionId) => `quizzes/${quizId}/responses/${questionId}/time_spent`;
export const RESPONSE_TIME_UPDATING = 'RESPONSE_TIME_UPDATING';
export const RESPONSE_TIME_UPDATED = 'RESPONSE_TIME_UPDATED';
export const RESPONSE_TIME_ERROR = 'RESPONSE_SYNC_ERROR';
export const responseTimeSpent = (quizId, questionId, event, time) => (dispatch, getState) => {
  const { quizzes, questions } = getState();
  const question = questions.get(questionId);
  const key = responseTimeSpentKey(quizId, questionId);

  const quiz = quizzes.get(quizId);
  if (!quiz) return Promise.reject(new Error('Quiz not found'));

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const requestBody = {};

  if (event && time) {
    requestBody.time_slice = { event, time };
  }

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/response/time_spent`, requestBody)
    .then((apiResponse) => {
      dispatch({
        type: RESPONSE_TIME_UPDATED,
        payload: {
          key,
          quizId,
          questionId,
          ...apiResponse
        }
      });
      return apiResponse;
    })
    .catch((error) => {
      dispatch({
        type: RESPONSE_TIME_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: RESPONSE_TIME_UPDATING,
    payload: { key, promise }
  });

  return promise;
};
responseTimeSpent.getKey = responseTimeSpentKey;

export const RESPONSE_SYNC_OFFLINE = 'RESPONSE_SYNC_OFFLINE';
export const responseSyncOffline = (quizId, questionId, event, time) => (dispatch) => {
  if (!event || !time) {
    console.warn('No need to issue responseSyncOffline to update state');
    return;
  }
  dispatch({
    type: RESPONSE_SYNC_OFFLINE,
    payload: {
      createdAt: Date.now(),
      quizId,
      questionId,
      event,
      time
    }
  });
};
