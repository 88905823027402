import React from "react";
import PropTypes from "prop-types";

import loadable from '@loadable/component';

const PricingCards = ({
  pricingPlanFeatures,
  pricingDisplay,
  activeQuestionCount,
  pricing,
  pricingCardsRef,
  mobileAddonsLogicObject,
  managemobileAddons
}) => {
  const PageSection = loadable(() => import('./PageSection'));

  const featuresForTooltip = (i) => {
    if (i === 2 || i === 3 || i === 4) {
      return true;
    }
  };

  const tooltipDescription = (feature) => {
    switch (feature) {
      case "100% Pass Guarantee":
        return "If you don't pass, we'll provide free access to your purchase as many times as you need to pass your exam";
      case "Buy Now, Start Later":
        return "Not ready to start studying? You can buy now and delay your plan start date for up to 6 months";
      case "Ask a Physician™":
        return "Connect with our medical experts to answer your content questions";
      case "Ask a Clinician™":
        return "Connect with our medical experts to answer your content questions";
      default:
        break;
    }
  };

  const defaultPricing = () => {
    const DefaultPricing = loadable(() => import("./DefaultPricing"));

    return (
      <DefaultPricing
        pricingPlanFeatures={pricingPlanFeatures}
        activeQuestionCount={activeQuestionCount}
        pricing={pricing}
        featuresForTooltip={featuresForTooltip}
        tooltipDescription={tooltipDescription}
        mobileAddonsLogicObject={mobileAddonsLogicObject}
        managemobileAddons={managemobileAddons}
      />
    );
  };

  const yearlyPricing = () => {
    const YearlyPricing = loadable(() => import("./YearlyPricing"));

    return (
      <YearlyPricing
        pricingPlanFeatures={pricingPlanFeatures}
        activeQuestionCount={activeQuestionCount}
        pricing={pricing}
        featuresForTooltip={featuresForTooltip}
        tooltipDescription={tooltipDescription}
      />
    );
  };

  const cmePricing = () => {
    const CmePricing = loadable(() => import("./CmePricing"));

    return <CmePricing pricing={pricing} />;
  };

  return (
    <PageSection id="pricing-cards" section="pricing-cards" sectionRef={pricingCardsRef}>
      {pricingDisplay === "default_pricing" && defaultPricing()}
      {pricingDisplay === "one_year_pricing" && yearlyPricing()}
      {pricingDisplay === "cme_pricing" && cmePricing()}
    </PageSection>
  );
};

PricingCards.propTypes = {
  pricingPlanFeatures: PropTypes.array.isRequired,
  pricingDisplay: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  pricing: PropTypes.array.isRequired,
  pricingCardsRef: PropTypes.func,
  mobileAddonsLogicObject: PropTypes.object,
  managemobileAddons: PropTypes.func
};

export default PricingCards;
