import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgEnvelope(props) {
  return (
    <SvgContainer title="Envelope Icon" {...props}>
      <path d="M26 6.5H6A2.503 2.503 0 003.5 9v14A2.503 2.503 0 006 25.5h20a2.503 2.503 0 002.5-2.5V9A2.503 2.503 0 0026 6.5zm-5.253 9.46L27.5 9.207v13.38zM6 7.5h20a1.494 1.494 0 011.193.6l-9.428 9.428a2.499 2.499 0 01-3.53 0L4.807 8.1A1.494 1.494 0 016 7.5zm5.253 8.46L4.5 22.587V9.207zM26 24.5H6a1.496 1.496 0 01-1.28-.73l7.24-7.104 1.568 1.57a3.499 3.499 0 004.944 0l1.569-1.57 7.239 7.105A1.496 1.496 0 0126 24.5z" />
    </SvgContainer>
  );
}
