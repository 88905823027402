import React from "react";
import SvgContainer from './SvgContainer';

const PsiCalculator = props => (
  <SvgContainer title="Calculator Icon" {...props}>
    <path d="M26.9 30.4H5c-1.3 0-2.4-1.1-2.4-2.4V3.9c0-1.3 1.1-2.4 2.4-2.4h21.9c1.3 0 2.4 1.1 2.4 2.4V28c0 1.4-1.1 2.4-2.4 2.4z" fill="#e9e8e8" />
    <path d="M26.9 30.8H5c-1.5 0-2.8-1.2-2.8-2.8V3.9c0-1.5 1.2-2.8 2.8-2.8h21.9c1.5 0 2.8 1.2 2.8 2.8V28c0 1.6-1.3 2.8-2.8 2.8zM5 1.9c-1.1 0-2 .9-2 2V28c0 1.1.9 2 2 2h21.9c1.1 0 2-.9 2-2V3.9c0-1.1-.9-2-2-2H5z" fill="#8c8c8c" />
    <rect x="4.9" y="4.4" width="22.1" height="6.8" fill="#f5841f" />
    <rect x="4.9" y="13.3" width="6.1" height="6.1" fill="#585858" />
    <rect x="12.9" y="13.3" width="6.1" height="6.1" fill="#585858" />
    <rect x="4.8" y="21.4" width="6.1" height="6.1" fill="#585858" />
    <rect x="12.9" y="21.5" width="6.1" height="6.1" fill="#585858" />
    <rect x="20.9" y="13.3" width="6.1" height="6.1" fill="#47a0d8" />
    <rect x="20.9" y="21.5" width="6.1" height="6.1" fill="#47a0d8" />
  </SvgContainer>
);

export default PsiCalculator;
