import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgMessageWhite(props) {
  return (
    <SvgContainer title="Message White Icon" {...props}>
      <defs>
        <path id="a" d="M0 .083h21v19.89H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .786)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path fill="#0044DB" d="M10.5.95C5.193.95.875 4.457.875 8.767c0 2.423 1.362 4.673 3.737 6.17l.2.127.055 4.01c.067-.035.18-.111.346-.272l2.52-2.555.237.053c.847.189 1.699.283 2.53.283 5.307 0 9.625-3.506 9.625-7.816 0-4.31-4.318-7.815-9.625-7.815M4.794 19.973a.75.75 0 0 1-.256-.044c-.357-.13-.516-.498-.545-.775l-.05-3.613C1.435 13.881 0 11.424 0 8.766 0 3.978 4.71.082 10.5.082S21 3.978 21 8.766c0 4.789-4.71 8.685-10.5 8.685-.817 0-1.65-.085-2.48-.253l-2.188 2.217c-.16.154-.577.558-1.038.558" mask="url(#b)" />
        </g>
        <path fill="#0044DB" d="M7.44 9.987c0 .719-.588 1.302-1.312 1.302a1.308 1.308 0 0 1-1.313-1.302c0-.72.588-1.303 1.313-1.303.724 0 1.312.584 1.312 1.303m4.375 0c0 .719-.588 1.302-1.312 1.302A1.308 1.308 0 0 1 9.19 9.987c0-.72.588-1.303 1.313-1.303.724 0 1.312.584 1.312 1.303m3.063 1.303a1.309 1.309 0 0 1-1.313-1.303c0-.72.588-1.303 1.313-1.303.724 0 1.312.584 1.312 1.303s-.588 1.302-1.312 1.302z" />
      </g>
    </SvgContainer>
  );
}
