import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHighlighter(props) {
  return (
    <SvgContainer title="Highlighter Icon" {...props}>
      <path d="M29.402 7.01a1.49 1.49 0 00-.44-1.06l-1.47-1.471a1.503 1.503 0 00-2.12 0l-1.768 1.768-1.061-1.06a1.501 1.501 0 00-2.121 0l-9.9 9.899a.478.478 0 00-.051.078.486.486 0 00-.05.068l-3.498 7.696-4.178 4.178a.5.5 0 00.353.854h25a.5.5 0 000-1H9.962l.496-.496 7.696-3.499a.478.478 0 00.072-.052.484.484 0 00.075-.05l9.899-9.899a1.501 1.501 0 000-2.12l-1.005-1.006 1.768-1.768a1.49 1.49 0 00.44-1.06zm-18.37 9.293l6.051 6.05-1.945.885-4.99-4.99zM9.707 19.22l4.46 4.46-3.89 1.768-1.168-1.17-1.169-1.17zm-.245 6.826l-.914.914H4.305l3.036-3.036 1.06 1.061zm18.031-13.789l-9.546 9.546-6.364-6.363 9.546-9.546a.5.5 0 01.707 0l1.06 1.06.494.493 4.103 4.103a.5.5 0 010 .707zm.763-4.894l-1.768 1.768-1.756-1.756-.421-.421 1.768-1.767a.5.5 0 01.707 0l1.47 1.47a.5.5 0 010 .706z" />
      <path d="M19.715 10.136l-3.535 3.535a2.5 2.5 0 103.535 3.536l3.535-3.536a2.5 2.5 0 000-3.535 2.56 2.56 0 00-3.535 0zm2.828 2.828L19.008 16.5a1.535 1.535 0 01-2.121 0 1.502 1.502 0 010-2.122l3.535-3.535a1.5 1.5 0 112.121 2.121z" />
    </SvgContainer>
  );
}
