import api, {
  currentUserUrl
} from 'helpers/api';

export const SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_ERROR';
export const SUBSCRIPTIONS_LOADING = 'SUBSCRIPTIONS_LOADING';
export const SUBSCRIPTIONS_TRIAL_CREATED = 'SUBSCRIPTIONS_TRIAL_CREATED';
const getSubscriptionCreateTrialKey = questionBankId => `subscriptions/createTrial/${questionBankId}`;
export const subscriptionCreateTrial = questionBankId => async (dispatch, getState) => {
  const { session, loading } = getState();
  const key = getSubscriptionCreateTrialKey(questionBankId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${currentUserUrl({ session })}/create_trial/${questionBankId}`)
    .then((response) => {
      dispatch({
        type: SUBSCRIPTIONS_TRIAL_CREATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: SUBSCRIPTIONS_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: SUBSCRIPTIONS_LOADING,
    payload: { key, promise }
  });
  return promise;
};
subscriptionCreateTrial.getKey = getSubscriptionCreateTrialKey;
