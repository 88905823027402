import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgComputer(props) {
  return (
    <SvgContainer title="Computer Icon" {...props}>
      <path d="M27.528 18.5a.972.972 0 00.972-.972V2.472a.972.972 0 00-.972-.972H4.471a.972.972 0 00-.971.972v15.056a.972.972 0 00.971.972H12.5v2H7a.465.465 0 00-.061.012.425.425 0 00-.342.208.468.468 0 00-.044.056l-3 6-.004.015a.491.491 0 00-.026.095.496.496 0 00-.02.099L3.5 27v2.5a1 1 0 001 1h23a1 1 0 001-1V27l-.003-.016a.508.508 0 00-.02-.096.502.502 0 00-.026-.097l-.004-.015-3-6a153.35 153.35 0 00-.11-.14.472.472 0 00-.077-.053.49.49 0 00-.084-.048.496.496 0 00-.103-.02A.47.47 0 0025 20.5h-5.5v-2zM4.5 17.528L4.471 2.5 27.5 2.472V17.5l-8 .01v-.01h-7v.018zm0 11.972v-2h23v2zm20.19-8l2.5 5H4.81l2.5-5h17.38zm-6.19-1h-5v-2h5z" />
      <path d="M25.5 14.544V5.456a.957.957 0 00-.956-.956H7.456a.957.957 0 00-.956.956v9.088a.957.957 0 00.957.956h17.087a.957.957 0 00.956-.956zm-1-.044l-17 .044L7.457 5.5 24.5 5.456zM8.5 24a.5.5 0 00.5.5h14a.5.5 0 000-1H9a.5.5 0 00-.5.5z" />
      <path d="M10 12.5a.498.498 0 00.353-.146L14 8.707l3.647 3.647a.5.5 0 00.707 0l4-4a.5.5 0 00-.707-.707L18 11.293l-3.646-3.646a.5.5 0 00-.707 0l-4 4A.5.5 0 0010 12.5z" />
    </SvgContainer>
  );
}
