import Factory from 'factories/_Factory';

export default class CmeQuestionBank extends Factory {
  static jsonType = 'cme_question_bank';

  static get defaults() {
    return {
      max_questions_required: 0,
      questions_per_hour: 0
    };
  }

  static get belongsTo() {
    return ['cme_activity', 'question_bank'];
  }
}
