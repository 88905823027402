import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgStrikeout(props) {
  return (
    <SvgContainer title="Strikeout Icon" {...props}>
      <path d="M26 15.5H6a.5.5 0 000 1h20a.5.5 0 000-1zM20.037 18a4.006 4.006 0 011.463 3c0 2.481-2.557 4.5-5.7 4.5a6.386 6.386 0 01-4.3-1.563V22a.5.5 0 00-1 0v4a.5.5 0 001 0v-.79a7.602 7.602 0 004.3 1.29c3.695 0 6.7-2.468 6.7-5.5a4.793 4.793 0 00-1.092-3zM11.963 14a4.006 4.006 0 01-1.463-3c0-2.481 2.557-4.5 5.7-4.5a6.388 6.388 0 014.3 1.562V10a.5.5 0 001 0V6a.5.5 0 00-1 0v.79a7.603 7.603 0 00-4.3-1.29c-3.695 0-6.7 2.467-6.7 5.5a4.793 4.793 0 001.091 3z" />
    </SvgContainer>
  );
}
