import { Map, List } from 'immutable';

import createReducer from 'helpers/createReducer';

import { QUESTION_BANK_SELECTED } from 'actions/questionBanks';
import { PERFORMANCE_QUESTIONS_FETCHED } from 'actions/performance';
import { QUESTION_MARKED, QUESTION_UNMARKED, QUESTION_RATED } from 'actions/questions';
import { QUIZ_SEEN_QUESTION_SYNCED, QUIZ_SEEN_QUESTION_OFFLINE, QUIZ_UNLOAD_PERFORMANCE } from 'actions/quizzes';
import { SESSION_RESET } from 'actions/session';


export const initialState = new Map();
const reducers = {};

reducers[QUIZ_UNLOAD_PERFORMANCE] = () => initialState;

reducers[PERFORMANCE_QUESTIONS_FETCHED] = function questionsFetched(state, payload) {
  const { response: questions } = payload;

  return state.withMutations((newState) => {
    questions.forEach((question) => {
      const questionId = question[0];
      const questionAtts = {
        subjectIds: new List(question[1]),
        trial: question[2] === 1,
        practice: question[3] === 1,
        seen: question[4] === 1,
        ratingStars: question[5],
        difficulty: question[6],
        ngn_type: question[7] === 1,
        group_id: question[8],
        potentialScore: question[9],
        order_priority: question[10],
      };

      newState.set(questionId, new Map(questionAtts));
    });
  });
};

reducers[QUESTION_MARKED] = function questionMarked(state, payload) {
  const { questionId } = payload;
  return state.setIn([questionId, 'marked'], true);
};

reducers[QUESTION_UNMARKED] = function questionUnmarked(state, payload) {
  const { questionId } = payload;
  return state.setIn([questionId, 'marked'], false);
};

reducers[QUESTION_RATED] = function questionRated(state, payload) {
  const json = payload.data.attributes;
  const questionId = parseInt(payload.data.relationships.question.data.id);
  return state.setIn([questionId, 'ratingStars'], json.rating_stars);
};

reducers[QUIZ_SEEN_QUESTION_SYNCED] = function quizSeenQuestionSynced(state, payload) {
  const { questionId } = payload;
  return state.setIn([questionId, 'seen'], true);
};

reducers[QUIZ_SEEN_QUESTION_OFFLINE] = function quizSeenQuestionOffline(state, payload) {
  const { questionId } = payload;
  return state.setIn([questionId, 'seen'], true);
};


reducers[QUESTION_BANK_SELECTED] = () => initialState;
reducers[SESSION_RESET] = () => initialState;

export default createReducer(reducers, initialState);
