import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgElipse(props) {
  const { className } = props;
  return (
    <SvgContainer title="Elipse Icon" {...props}>
      {className.includes("filled") ? (
        <ellipse cx="7" cy="7.5" rx="7" ry="7.5" fill="#1B3950" />
      ) : (
        <circle cx="7.5" cy="7.5" r="7.5" fill="#A4A4A4" />
      )}
    </SvgContainer>
  );
}
