import { MEMBER_GROUPS_PAGINATED, MEMBER_GROUP_USERS_PAGINATED } from 'actions/b2b/memberGroups';
import { ORGANIZATION_MEMBERS_PAGINATED } from 'actions/b2b/organizations';

function updatePaginationEndClause(dispatch, paginationEndConstant, paginationPayload, response) {
  switch (paginationEndConstant) {
    case MEMBER_GROUPS_PAGINATED:
      paginationPayload.hasMoreMemberGroupsPagination = response?.data.length !== 0;
      break;
    case ORGANIZATION_MEMBERS_PAGINATED:
      paginationPayload.hasMoreMembersPagination = response?.data.length !== 0;
      break;
    case MEMBER_GROUP_USERS_PAGINATED:
      paginationPayload.hasMoreMembersPagination = response?.data.length !== 0;
      break;
    default:
      break;
  }
  dispatch({
    type: paginationEndConstant,
    payload: paginationPayload
  });
}

function createApiRequestAction({
  getKey,
  request,
  loadingConstant,
  loadedConstant,
  errorConstant,
  paginationEndConstant,
}) {
  const action = (...args) => (dispatch, getState) => {
    const { loading, session } = getState();
    const organizationId = session.get('selected_organization_id');
    const key = getKey(...args);

    if (loading.has(key)) return loading.get(key);

    const promise = request(getState, ...args)
      .then((response) => {
        dispatch({
          type: loadedConstant,
          payload: { key, ...response }
        });
        if (paginationEndConstant) updatePaginationEndClause(dispatch, paginationEndConstant, { key, organizationId }, response);
        return response;
      })
      .catch((error) => {
        dispatch({
          type: errorConstant,
          payload: { error, key }
        });
        throw error;
      });

    dispatch({
      type: loadingConstant,
      payload: { key, promise }
    });

    return promise;
  };
  action.getKey = getKey;

  return action;
}

export { createApiRequestAction };
