import React, {
  useState, useRef, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { useOnClickOutside, useKeyPress } from 'hooks/keypress';
import Button from './Button';
import Input from './Input';

// StyleGuide = elements/inputs/select-dropdown

export default function Select({
  name,
  placeholder,
  className,
  options,
  onClick,
  onChange,
  value,
  disabled,
  required,
  styleVariant,
  small,
  large,
  id,
  scrollable,
  active,
  buttonClass,
  skipSort,
  truncateText,
  iconClass,
  searchEnabled
}) {
  const [showSelections, setShowSelections] = useState(false);
  const [searchText, setSearchText] = useState("");

  const downArrowPressed = useKeyPress('ArrowDown');
  const upArrowPressed = useKeyPress('ArrowUp');

  function preventDefaultKeyActions(e) {
    // up & down arrow keys
    if ([38, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  }

  const sortedOptions = skipSort ? options : options.sort((a, b) => (
    ('' + a.name).localeCompare(b.name)
  ));
  const [finalOptions, setFinalOptions] = useState(sortedOptions);

  useEffect(() => {
    if (showSelections) {
      window.addEventListener("keydown", preventDefaultKeyActions);
    } else {
      window.removeEventListener("keydown", preventDefaultKeyActions);
      setSearchText("");
    }
    return () => {
      window.removeEventListener("keydown", preventDefaultKeyActions);
    };
  }, [showSelections]);

  useEffect(() => {
    setFinalOptions(sortedOptions);
  }, [sortedOptions]);

  useEffect(() => {
    const filteredOptions = searchText ? sortedOptions.filter(option => option.name.toLowerCase().startsWith(searchText.toLowerCase())) : sortedOptions;
    setFinalOptions(filteredOptions);
  }, [searchText]);

  const handleSearch = (val) => {
    setSearchText(val);
  };

  const selectRef = useRef();
  const handlePageClick = useCallback(() => (showSelections && setShowSelections(false)));
  useOnClickOutside(selectRef, handlePageClick);

  const currentSelection = finalOptions.find(option => option.value === value);
  const optionsLength = finalOptions.length;
  const selectedName = (() => {
    if (currentSelection) return currentSelection.name;
    if (placeholder) return placeholder;
    return 'Select';
  })();

  const derivedButtonClass = classNames(`w-100 ${buttonClass}`, { showSelections }, {
    'form-control-sm': small && !large,
    'form-control-lg': large && !small
  });
  const listboxClass = classNames('options-list', { hidden: !showSelections, scrollable });

  const selectFirst = () => onChange(finalOptions[0].value);
  const selectLast = () => onChange(finalOptions[optionsLength - 1].value);

  const selectUpOrDown = (direction) => {
    const currentIndex = finalOptions.indexOf(currentSelection);
    if (currentIndex === 0 && (direction === -1)) return selectLast();
    if (currentIndex === optionsLength - 1 && (direction === 1)) return selectFirst();
    onChange(finalOptions[currentIndex + direction].value);
  };

  const selectAbove = () => {
    if (!finalOptions.length) return;
    if (!value) return selectFirst();
    selectUpOrDown(-1);
  };

  const selectBelow = () => {
    if (!finalOptions.length) return;
    if (!value) selectFirst();
    selectUpOrDown(1);
  };

  const handleSelection = (optionValue, key = null) => {
    if (key != null) {
      if (key.type === "keydown" && key.key === "Enter") {
        setShowSelections(false);
        onChange(optionValue);
      }
      // else comes with tab key
    } else {
      setShowSelections(false);
      onChange(optionValue);
    }
  };

  const debounceSelectAbove = useCallback(debounce(selectAbove, 250));
  const debounceSelectBelow = useCallback(debounce(selectBelow, 250));

  if (showSelections && upArrowPressed) debounceSelectAbove();
  if (showSelections && downArrowPressed) debounceSelectBelow();

  const onClickButton = () => {
    if (onClick) onClick();
    setShowSelections(!showSelections);
  };

  return (
    <div className={`Select name-${name} ${styleVariant} ${className}`} ref={selectRef}>
      <Button
        ariaHaspopup="listbox"
        ariaControls={name + "-listbox"}
        ariaExpanded={showSelections}
        ariaActivedescendant={`${value || name}-box`}
        ariaLabel={name}
        ariaDisabled={disabled}
        disabled={disabled}
        select
        className={derivedButtonClass}
        styleVariant={styleVariant}
        id={id}
        onClick={onClickButton}
        active={active}
        role="combobox"
        selectIconClass={iconClass}
        selectMenuOpen={showSelections}
        truncateText={truncateText}
      >
        {truncateText ? <div className="overflow-hidden text-nowrap text-truncate"> {selectedName} </div> : selectedName}
      </Button>
      <ul
        className={listboxClass}
        role="listbox"
        id={name + "-listbox"}
        name={name}
        aria-disabled={disabled}
        aria-required={required}
        aria-expanded={showSelections}
      >
        {searchEnabled && (
        <li>
          <Input type="text" id={name + '-box'} value={searchText} onChange={handleSearch} name="search" placeholder="Search" />
        </li>
        )}
        {finalOptions.map((option, key) => (
          <li
            tabIndex={0}
            name={option.name}
            value={option.value}
            className="select-option"
            key={key}
            role="option"
            id={option.value + "-box"}
            aria-selected={option.value === value}
            onClick={() => handleSelection(option.value)}
            onKeyDown={e => handleSelection(option.value, e)}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
}


Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  styleVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'light',
    'white',
    'dark',
    'outline-primary',
    'outline-secondary',
    'outline-light',
    'outline-dark'
  ]),
  small: PropTypes.bool,
  large: PropTypes.bool,
  id: PropTypes.string,
  scrollable: PropTypes.bool,
  active: PropTypes.bool,
  buttonClass: PropTypes.string,
  skipSort: PropTypes.bool,
  truncateText: PropTypes.bool,
  iconClass: PropTypes.string,
  searchEnabled: PropTypes.bool
};

Select.defaultProps = {
  options: [],
  className: '',
  onChange: () => {},
  disabled: false,
  required: false,
  styleVariant: 'light',
  value: '',
  small: false,
  large: false,
  active: false,
  buttonClass: '',
  skipSort: false,
  truncateText: false,
  searchEnabled: false
};
