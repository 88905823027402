import React from "react";
import PropTypes from "prop-types";


// StyleGuide = organisms/TakeQuizPage/answer-status

function AnswerStatus(props) {
  const {
    question,
    correct,
    first,
    isSampleQuestion
  } = props;

  let questionType = "";
  let multipleChoice = false;

  questionType = isSampleQuestion ? question.type : question.get('type');
  multipleChoice = isSampleQuestion ? question.answers.filter(answer => answer.attributes.is_correct).length > 1
    : question.get('answers').filter(answer => answer.get('is_correct')).size > 1;

  let alertProps = {};

  if (questionType !== 'MultipleChoiceQuestion' || multipleChoice) {
    alertProps = {
      role: 'alert',
      'aria-live': 'assertive'
    };
  }

  if (!correct) {
    return (
      <div className="AnswerStatus d-flex align-items-center ml-4">
        <div {...alertProps} className="AnswerStatus-incorrect text-red">Incorrect, try again!</div>
      </div>
    );
  }

  return (
    <div className="AnswerStatus d-flex flex-row align-items-center ml-4">
      <div {...alertProps} className={`AnswerStatus-correct pl-3 text-green ${first ? 'perfect' : ''}`}>
        {first ? 'Perfect!' : 'Correct'}
      </div>
      <div className="AnswerStatus-correct-icon">
        {first && (
          <div className="AnswerStatus-border">
            { Array(16).fill().map((_, i) => (
              <div key={i} className="AnswerStatus-border-child" />
            ))}
          </div>
        )}
        <div className="AnswerStatus-check-child AnswerStatus-check-a" />
        <div className="AnswerStatus-check-child AnswerStatus-check-b" />
      </div>
    </div>
  );
}

AnswerStatus.propTypes = {
  question: PropTypes.object.isRequired,
  first: PropTypes.bool,
  correct: PropTypes.bool,
  isSampleQuestion: PropTypes.bool
};

AnswerStatus.defaultProps = {
  correct: false,
  first: false
};

export default AnswerStatus;
