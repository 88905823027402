import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRightArrow(props) {
  return (
    <SvgContainer title="Right Arrow Icon" {...props}>
      <path d="M26.464 16.177a.478.478 0 00.019-.085.501.501 0 00.014-.078L26.5 16l-.003-.014a.501.501 0 00-.014-.078.478.478 0 00-.019-.085l-.01-.028a.505.505 0 00-.044-.065.45.45 0 00-.036-.062l-8-9a.5.5 0 00-.748.664l7.26 8.168H6a.5.5 0 000 1h18.886l-7.26 8.168a.5.5 0 00.748.664l8-9a.45.45 0 00.036-.062.505.505 0 00.044-.065l.01-.028z" />
    </SvgContainer>
  );
}
