import React from "react";
import PropTypes from "prop-types";
import SvgContainer from "./SvgContainer";

function Book(props) {
  const { xs, small, medium } = props;

  if (xs) {
    return (
      <SvgContainer
        name="book"
        height={77}
        width={77}
        viewBoxHeight={77}
        viewBoxWidth={77}
        {...props}
      >
        <svg
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="38.8947" cy="38.6842" r="38.6842" fill="#FFF2DB" />
          <g clipPath="url(#clip0_529_16618)">
            <path
              d="M40.2505 52.8094H22.982C21.9267 52.8094 21.0632 51.9469 21.0632 50.8929V43.2271H33.5349C37.2381 43.2271 40.2505 46.2359 40.2505 49.9347V52.8094ZM22.982 45.1435V50.8929H38.3318V49.9347C38.3318 47.2899 36.1828 45.1435 33.5349 45.1435H22.982Z"
              fill="#1B3950"
            />
            <path
              d="M39.2911 25.9788C38.7539 25.9788 38.3318 25.5572 38.3318 25.0206C38.3318 22.3759 36.1828 20.2294 33.5349 20.2294H22.982V25.0206C22.982 25.5572 22.5598 25.9788 22.0226 25.9788C21.4854 25.9788 21.0632 25.5572 21.0632 25.0206V20.2294C21.0632 19.1754 21.9267 18.313 22.982 18.313H33.5349C37.2381 18.313 40.2505 21.3218 40.2505 25.0206C40.2505 25.5572 39.8284 25.9788 39.2911 25.9788Z"
              fill="#1B3950"
            />
            <path
              d="M55.6003 52.8094H38.3318V49.9347C38.3318 46.2359 41.3442 43.2271 45.0473 43.2271H57.519V50.8929C57.519 51.9469 56.6556 52.8094 55.6003 52.8094ZM40.2505 50.8929H55.6003V45.1435H45.0473C42.3995 45.1435 40.2505 47.2899 40.2505 49.9347V50.8929Z"
              fill="#1B3950"
            />
            <path
              d="M56.5597 25.9788C56.0224 25.9788 55.6003 25.5572 55.6003 25.0206V20.2294H45.0473C42.3995 20.2294 40.2505 22.3759 40.2505 25.0206C40.2505 25.5572 39.8284 25.9788 39.2911 25.9788C38.7539 25.9788 38.3318 25.5572 38.3318 25.0206C38.3318 21.3218 41.3442 18.313 45.0473 18.313H55.6003C56.6556 18.313 57.519 19.1754 57.519 20.2294V25.0206C57.519 25.5572 57.0969 25.9788 56.5597 25.9788Z"
              fill="#1B3950"
            />
            <path
              d="M39.2911 42.2687C38.7539 42.2687 38.3318 41.8471 38.3318 41.3105V29.8117C38.3318 29.2751 38.7539 28.8535 39.2911 28.8535C39.8284 28.8535 40.2505 29.2751 40.2505 29.8117V41.3105C40.2505 41.8471 39.8284 42.2687 39.2911 42.2687Z"
              fill="#1B3950"
            />
            <path
              d="M22.0226 45.1436C21.4854 45.1436 21.0632 44.722 21.0632 44.1853V25.0207C21.0632 24.4841 21.4854 24.0625 22.0226 24.0625C22.5598 24.0625 22.982 24.4841 22.982 25.0207V44.1853C22.982 44.722 22.5598 45.1436 22.0226 45.1436Z"
              fill="#1B3950"
            />
            <path
              d="M56.5597 45.1436C56.0225 45.1436 55.6003 44.722 55.6003 44.1853V25.0207C55.6003 24.4841 56.0225 24.0625 56.5597 24.0625C57.0969 24.0625 57.5191 24.4841 57.5191 25.0207V44.1853C57.5191 44.722 57.0969 45.1436 56.5597 45.1436Z"
              fill="#1B3950"
            />
            <path
              d="M31.6162 48.9765H22.0226C21.4854 48.9765 21.0632 48.5549 21.0632 48.0183C21.0632 47.4817 21.4854 47.0601 22.0226 47.0601H31.6162C32.1535 47.0601 32.5756 47.4817 32.5756 48.0183C32.5756 48.5549 32.1535 48.9765 31.6162 48.9765Z"
              fill="#1B3950"
            />
            <path
              d="M56.5598 48.9765H46.9662C46.429 48.9765 46.0068 48.5549 46.0068 48.0183C46.0068 47.4817 46.429 47.0601 46.9662 47.0601H56.5598C57.0971 47.0601 57.5192 47.4817 57.5192 48.0183C57.5192 48.5549 57.0971 48.9765 56.5598 48.9765Z"
              fill="#1B3950"
            />
            <path
              d="M33.535 58.4438H17.2259C16.1706 58.4438 15.3071 57.5622 15.3071 56.4699V26.0365C15.3071 24.9441 16.1706 24.0625 17.2259 24.0625H22.0227C22.5599 24.0625 22.982 24.4841 22.982 25.0207C22.982 25.5573 22.5599 25.979 22.0227 25.979H17.2259V56.4699L33.535 56.5274C34.0723 56.5274 34.4944 56.949 34.4944 57.4856C34.4944 58.0222 34.0723 58.4438 33.535 58.4438Z"
              fill="#1B3950"
            />
            <path
              d="M61.3564 58.4438H45.0473C44.51 58.4438 44.0879 58.0222 44.0879 57.4856C44.0879 56.949 44.51 56.5274 45.0473 56.5274H61.3564V26.0365L56.5596 25.979C56.0224 25.979 55.6002 25.5573 55.6002 25.0207C55.6002 24.4841 56.0224 24.0625 56.5596 24.0625H61.3564C62.4117 24.0625 63.2751 24.9441 63.2751 26.0365V56.4699C63.2751 57.5622 62.4117 58.4438 61.3564 58.4438Z"
              fill="#1B3950"
            />
            <path
              d="M43.1287 60.4753H35.4538C33.8612 60.4753 32.5757 59.1337 32.5757 57.4856C32.5757 56.949 32.9978 56.5273 33.535 56.5273C34.0723 56.5273 34.4944 56.949 34.4944 57.4856C34.4944 58.0797 34.9165 58.5588 35.4538 58.5588H43.1287C43.6659 58.5588 44.088 58.0797 44.088 57.4856C44.088 56.949 44.5102 56.5273 45.0474 56.5273C45.5846 56.5273 46.0068 56.949 46.0068 57.4856C46.0068 59.1337 44.7212 60.4753 43.1287 60.4753Z"
              fill="#1B3950"
            />
            <path
              d="M33.5351 26.937H27.7789C27.2417 26.937 26.8196 26.5153 26.8196 25.9787C26.8196 25.4421 27.2417 25.0205 27.7789 25.0205H33.5351C34.0724 25.0205 34.4945 25.4421 34.4945 25.9787C34.4945 26.5153 34.0724 26.937 33.5351 26.937Z"
              fill="#1B3950"
            />
            <path
              d="M33.5351 32.6865H27.7789C27.2417 32.6865 26.8196 32.2649 26.8196 31.7283C26.8196 31.1916 27.2417 30.77 27.7789 30.77H33.5351C34.0724 30.77 34.4945 31.1916 34.4945 31.7283C34.4945 32.2649 34.0724 32.6865 33.5351 32.6865Z"
              fill="#1B3950"
            />
            <path
              d="M33.5351 38.4355H27.7789C27.2417 38.4355 26.8196 38.0139 26.8196 37.4773C26.8196 36.9407 27.2417 36.519 27.7789 36.519H33.5351C34.0724 36.519 34.4945 36.9407 34.4945 37.4773C34.4945 38.0139 34.0724 38.4355 33.5351 38.4355Z"
              fill="#1B3950"
            />
            <path
              d="M50.8034 26.937H45.0473C44.51 26.937 44.0879 26.5153 44.0879 25.9787C44.0879 25.4421 44.51 25.0205 45.0473 25.0205H50.8034C51.3407 25.0205 51.7628 25.4421 51.7628 25.9787C51.7628 26.5153 51.3407 26.937 50.8034 26.937Z"
              fill="#1B3950"
            />
            <path
              d="M50.8034 32.6865H45.0473C44.51 32.6865 44.0879 32.2649 44.0879 31.7283C44.0879 31.1916 44.51 30.77 45.0473 30.77H50.8034C51.3407 30.77 51.7628 31.1916 51.7628 31.7283C51.7628 32.2649 51.3407 32.6865 50.8034 32.6865Z"
              fill="#1B3950"
            />
            <path
              d="M50.8034 38.4355H45.0473C44.51 38.4355 44.0879 38.0139 44.0879 37.4773C44.0879 36.9407 44.51 36.519 45.0473 36.519H50.8034C51.3407 36.519 51.7628 36.9407 51.7628 37.4773C51.7628 38.0139 51.3407 38.4355 50.8034 38.4355Z"
              fill="#1B3950"
            />
          </g>
          <defs>
            <clipPath id="clip0_529_16618">
              <rect
                width="47.9681"
                height="42.1622"
                fill="white"
                transform="translate(15.3071 18.313)"
              />
            </clipPath>
          </defs>
        </svg>
      </SvgContainer>
    );
  }

  if (small) {
    return (
      <SvgContainer
        name="book"
        height={94}
        width={94}
        viewBoxHeight={94}
        viewBoxWidth={94}
        {...props}
      >
        <svg
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="46.9" cy="46.9" r="46.9" fill="#FFF2DB" />
          <g clipPath="url(#clip0_428_1237)">
            <path
              d="M48.5439 64.0249H27.6079C26.3285 64.0249 25.2817 62.9793 25.2817 61.7014V52.4075H40.4021C44.8918 52.4075 48.5439 56.0553 48.5439 60.5397V64.0249ZM27.6079 54.731V61.7014H46.2177V60.5397C46.2177 57.3333 43.6123 54.731 40.4021 54.731H27.6079Z"
              fill="#1B3950"
            />
            <path
              d="M47.3808 31.4961C46.7295 31.4961 46.2177 30.985 46.2177 30.3344C46.2177 27.128 43.6123 24.5257 40.4021 24.5257H27.6079V30.3344C27.6079 30.985 27.0961 31.4961 26.4448 31.4961C25.7934 31.4961 25.2817 30.985 25.2817 30.3344V24.5257C25.2817 23.2478 26.3285 22.2022 27.6079 22.2022H40.4021C44.8918 22.2022 48.5439 25.8501 48.5439 30.3344C48.5439 30.985 48.0322 31.4961 47.3808 31.4961Z"
              fill="#1B3950"
            />
            <path
              d="M67.1537 64.0249H46.2177V60.5397C46.2177 56.0553 49.8699 52.4075 54.3595 52.4075H69.48V61.7014C69.48 62.9793 68.4332 64.0249 67.1537 64.0249ZM48.5439 61.7014H67.1537V54.731H54.3595C51.1493 54.731 48.5439 57.3333 48.5439 60.5397V61.7014Z"
              fill="#1B3950"
            />
            <path
              d="M68.3169 31.4961C67.6655 31.4961 67.1537 30.985 67.1537 30.3344V24.5257H54.3595C51.1493 24.5257 48.5439 27.128 48.5439 30.3344C48.5439 30.985 48.0322 31.4961 47.3808 31.4961C46.7295 31.4961 46.2177 30.985 46.2177 30.3344C46.2177 25.8501 49.8699 22.2022 54.3595 22.2022H67.1537C68.4332 22.2022 69.48 23.2478 69.48 24.5257V30.3344C69.48 30.985 68.9682 31.4961 68.3169 31.4961Z"
              fill="#1B3950"
            />
            <path
              d="M47.3808 51.2457C46.7295 51.2457 46.2177 50.7345 46.2177 50.0839V36.1431C46.2177 35.4925 46.7295 34.9813 47.3808 34.9813C48.0322 34.9813 48.5439 35.4925 48.5439 36.1431V50.0839C48.5439 50.7345 48.0322 51.2457 47.3808 51.2457Z"
              fill="#1B3950"
            />
            <path
              d="M26.4448 54.731C25.7934 54.731 25.2817 54.2198 25.2817 53.5692V30.3344C25.2817 29.6838 25.7934 29.1727 26.4448 29.1727C27.0961 29.1727 27.6079 29.6838 27.6079 30.3344V53.5692C27.6079 54.2198 27.0961 54.731 26.4448 54.731Z"
              fill="#1B3950"
            />
            <path
              d="M68.3168 54.731C67.6655 54.731 67.1537 54.2198 67.1537 53.5692V30.3344C67.1537 29.6838 67.6655 29.1727 68.3168 29.1727C68.9681 29.1727 69.4799 29.6838 69.4799 30.3344V53.5692C69.4799 54.2198 68.9681 54.731 68.3168 54.731Z"
              fill="#1B3950"
            />
            <path
              d="M38.0759 59.3779H26.4448C25.7934 59.3779 25.2817 58.8668 25.2817 58.2162C25.2817 57.5656 25.7934 57.0544 26.4448 57.0544H38.0759C38.7273 57.0544 39.239 57.5656 39.239 58.2162C39.239 58.8668 38.7273 59.3779 38.0759 59.3779Z"
              fill="#1B3950"
            />
            <path
              d="M68.3168 59.3779H56.6857C56.0344 59.3779 55.5226 58.8668 55.5226 58.2162C55.5226 57.5656 56.0344 57.0544 56.6857 57.0544H68.3168C68.9682 57.0544 69.4799 57.5656 69.4799 58.2162C69.4799 58.8668 68.9682 59.3779 68.3168 59.3779Z"
              fill="#1B3950"
            />
            <path
              d="M40.4021 70.8559H20.6292C19.3498 70.8559 18.303 69.7871 18.303 68.4627V31.5659C18.303 30.2415 19.3498 29.1727 20.6292 29.1727H26.4448C27.0961 29.1727 27.6079 29.6838 27.6079 30.3344C27.6079 30.985 27.0961 31.4961 26.4448 31.4961H20.6292V68.4627L40.4021 68.5324C41.0535 68.5324 41.5652 69.0436 41.5652 69.6942C41.5652 70.3447 41.0535 70.8559 40.4021 70.8559Z"
              fill="#1B3950"
            />
            <path
              d="M74.1324 70.8559H54.3595C53.7081 70.8559 53.1964 70.3447 53.1964 69.6942C53.1964 69.0436 53.7081 68.5324 54.3595 68.5324H74.1324V31.5659L68.3168 31.4961C67.6655 31.4961 67.1537 30.985 67.1537 30.3344C67.1537 29.6838 67.6655 29.1727 68.3168 29.1727H74.1324C75.4118 29.1727 76.4586 30.2415 76.4586 31.5659V68.4627C76.4586 69.7871 75.4118 70.8559 74.1324 70.8559Z"
              fill="#1B3950"
            />
            <path
              d="M52.0333 73.3188H42.7284C40.7976 73.3188 39.239 71.6923 39.239 69.6942C39.239 69.0436 39.7508 68.5324 40.4021 68.5324C41.0535 68.5324 41.5652 69.0436 41.5652 69.6942C41.5652 70.4144 42.077 70.9953 42.7284 70.9953H52.0333C52.6846 70.9953 53.1964 70.4144 53.1964 69.6942C53.1964 69.0436 53.7081 68.5324 54.3595 68.5324C55.0108 68.5324 55.5226 69.0436 55.5226 69.6942C55.5226 71.6923 53.964 73.3188 52.0333 73.3188Z"
              fill="#1B3950"
            />
            <path
              d="M40.4021 32.6579H33.4234C32.7721 32.6579 32.2603 32.1467 32.2603 31.4962C32.2603 30.8456 32.7721 30.3344 33.4234 30.3344H40.4021C41.0534 30.3344 41.5652 30.8456 41.5652 31.4962C41.5652 32.1467 41.0534 32.6579 40.4021 32.6579Z"
              fill="#1B3950"
            />
            <path
              d="M40.4021 39.6284H33.4234C32.7721 39.6284 32.2603 39.1172 32.2603 38.4666C32.2603 37.816 32.7721 37.3049 33.4234 37.3049H40.4021C41.0534 37.3049 41.5652 37.816 41.5652 38.4666C41.5652 39.1172 41.0534 39.6284 40.4021 39.6284Z"
              fill="#1B3950"
            />
            <path
              d="M40.4021 46.5987H33.4234C32.7721 46.5987 32.2603 46.0876 32.2603 45.437C32.2603 44.7864 32.7721 44.2753 33.4234 44.2753H40.4021C41.0534 44.2753 41.5652 44.7864 41.5652 45.437C41.5652 46.0876 41.0534 46.5987 40.4021 46.5987Z"
              fill="#1B3950"
            />
            <path
              d="M61.3381 32.6579H54.3595C53.7081 32.6579 53.1964 32.1467 53.1964 31.4962C53.1964 30.8456 53.7081 30.3344 54.3595 30.3344H61.3381C61.9895 30.3344 62.5013 30.8456 62.5013 31.4962C62.5013 32.1467 61.9895 32.6579 61.3381 32.6579Z"
              fill="#1B3950"
            />
            <path
              d="M61.3381 39.6284H54.3595C53.7081 39.6284 53.1964 39.1172 53.1964 38.4666C53.1964 37.816 53.7081 37.3049 54.3595 37.3049H61.3381C61.9895 37.3049 62.5013 37.816 62.5013 38.4666C62.5013 39.1172 61.9895 39.6284 61.3381 39.6284Z"
              fill="#1B3950"
            />
            <path
              d="M61.3381 46.5987H54.3595C53.7081 46.5987 53.1964 46.0876 53.1964 45.437C53.1964 44.7864 53.7081 44.2753 54.3595 44.2753H61.3381C61.9895 44.2753 62.5013 44.7864 62.5013 45.437C62.5013 46.0876 61.9895 46.5987 61.3381 46.5987Z"
              fill="#1B3950"
            />
          </g>
          <defs>
            <clipPath id="clip0_428_1237">
              <rect
                width="58.1556"
                height="51.1166"
                fill="white"
                transform="translate(18.303 22.2022)"
              />
            </clipPath>
          </defs>
        </svg>
      </SvgContainer>
    );
  }

  if (medium) {
    return (
      <SvgContainer
        name="book"
        height={96}
        width={96}
        viewBoxHeight={96}
        viewBoxWidth={96}
        {...props}
      >
        <circle cx="47.665" cy="47.665" r="47.665" fill="#FFF2DB" />
        <g clipPath="url(#clip0_428_1237)">
          <path
            d="M49.3358 65.0692H28.0583C26.758 65.0692 25.6941 64.0065 25.6941 62.7078V53.2623H41.0612C45.624 53.2623 49.3358 56.9696 49.3358 61.5271V65.0692ZM28.0583 55.6236V62.7078H46.9716V61.5271C46.9716 58.2684 44.3237 55.6236 41.0612 55.6236H28.0583Z"
            fill="#1B3950"
          />
          <path
            d="M48.1537 32.0099C47.4917 32.0099 46.9716 31.4903 46.9716 30.8292C46.9716 27.5705 44.3237 24.9257 41.0612 24.9257H28.0583V30.8292C28.0583 31.4903 27.5381 32.0099 26.8762 32.0099C26.2142 32.0099 25.6941 31.4903 25.6941 30.8292V24.9257C25.6941 23.627 26.758 22.5643 28.0583 22.5643H41.0612C45.624 22.5643 49.3358 26.2717 49.3358 30.8292C49.3358 31.4903 48.8157 32.0099 48.1537 32.0099Z"
            fill="#1B3950"
          />
          <path
            d="M68.2491 65.0692H46.9716V61.5271C46.9716 56.9696 50.6834 53.2623 55.2462 53.2623H70.6133V62.7078C70.6133 64.0065 69.5494 65.0692 68.2491 65.0692ZM49.3358 62.7078H68.2491V55.6236H55.2462C51.9837 55.6236 49.3358 58.2684 49.3358 61.5271V62.7078Z"
            fill="#1B3950"
          />
          <path
            d="M69.4312 32.0099C68.7693 32.0099 68.2491 31.4903 68.2491 30.8292V24.9257H55.2462C51.9837 24.9257 49.3358 27.5705 49.3358 30.8292C49.3358 31.4903 48.8157 32.0099 48.1537 32.0099C47.4917 32.0099 46.9716 31.4903 46.9716 30.8292C46.9716 26.2717 50.6834 22.5643 55.2462 22.5643H68.2491C69.5494 22.5643 70.6133 23.627 70.6133 24.9257V30.8292C70.6133 31.4903 70.0932 32.0099 69.4312 32.0099Z"
            fill="#1B3950"
          />
          <path
            d="M48.1537 52.0816C47.4917 52.0816 46.9716 51.5621 46.9716 50.9009V36.7326C46.9716 36.0714 47.4917 35.5519 48.1537 35.5519C48.8157 35.5519 49.3358 36.0714 49.3358 36.7326V50.9009C49.3358 51.5621 48.8157 52.0816 48.1537 52.0816Z"
            fill="#1B3950"
          />
          <path
            d="M26.8762 55.6237C26.2142 55.6237 25.6941 55.1042 25.6941 54.443V30.8292C25.6941 30.168 26.2142 29.6485 26.8762 29.6485C27.5381 29.6485 28.0583 30.168 28.0583 30.8292V54.443C28.0583 55.1042 27.5381 55.6237 26.8762 55.6237Z"
            fill="#1B3950"
          />
          <path
            d="M69.4312 55.6237C68.7693 55.6237 68.2491 55.1042 68.2491 54.443V30.8292C68.2491 30.168 68.7693 29.6485 69.4312 29.6485C70.0932 29.6485 70.6133 30.168 70.6133 30.8292V54.443C70.6133 55.1042 70.0932 55.6237 69.4312 55.6237Z"
            fill="#1B3950"
          />
          <path
            d="M38.697 60.3464H26.8762C26.2142 60.3464 25.6941 59.8269 25.6941 59.1657C25.6941 58.5045 26.2142 57.985 26.8762 57.985H38.697C39.359 57.985 39.8791 58.5045 39.8791 59.1657C39.8791 59.8269 39.359 60.3464 38.697 60.3464Z"
            fill="#1B3950"
          />
          <path
            d="M69.4312 60.3464H57.6104C56.9484 60.3464 56.4283 59.8269 56.4283 59.1657C56.4283 58.5045 56.9484 57.985 57.6104 57.985H69.4312C70.0932 57.985 70.6133 58.5045 70.6133 59.1657C70.6133 59.8269 70.0932 60.3464 69.4312 60.3464Z"
            fill="#1B3950"
          />
          <path
            d="M41.0612 72.0117H20.9657C19.6654 72.0117 18.6016 70.9254 18.6016 69.5794V32.0807C18.6016 30.7347 19.6654 29.6485 20.9657 29.6485H26.8762C27.5381 29.6485 28.0582 30.168 28.0582 30.8292C28.0582 31.4904 27.5381 32.0099 26.8762 32.0099H20.9657V69.5794L41.0612 69.6503C41.7231 69.6503 42.2433 70.1698 42.2433 70.831C42.2433 71.4921 41.7231 72.0117 41.0612 72.0117Z"
            fill="#1B3950"
          />
          <path
            d="M75.3416 72.0117H55.2462C54.5842 72.0117 54.0641 71.4921 54.0641 70.831C54.0641 70.1698 54.5842 69.6503 55.2462 69.6503H75.3416V32.0807L69.4312 32.0099C68.7692 32.0099 68.2491 31.4904 68.2491 30.8292C68.2491 30.168 68.7692 29.6485 69.4312 29.6485H75.3416C76.6419 29.6485 77.7058 30.7347 77.7058 32.0807V69.5794C77.7058 70.9254 76.6419 72.0117 75.3416 72.0117Z"
            fill="#1B3950"
          />
          <path
            d="M52.882 74.5147H43.4253C41.4631 74.5147 39.8791 72.8617 39.8791 70.831C39.8791 70.1698 40.3992 69.6503 41.0612 69.6503C41.7231 69.6503 42.2433 70.1698 42.2433 70.831C42.2433 71.563 42.7634 72.1533 43.4253 72.1533H52.882C53.544 72.1533 54.0641 71.563 54.0641 70.831C54.0641 70.1698 54.5842 69.6503 55.2462 69.6503C55.9082 69.6503 56.4283 70.1698 56.4283 70.831C56.4283 72.8617 54.8443 74.5147 52.882 74.5147Z"
            fill="#1B3950"
          />
          <path
            d="M41.0612 33.1905H33.9686C33.3067 33.1905 32.7866 32.671 32.7866 32.0099C32.7866 31.3487 33.3067 30.8292 33.9686 30.8292H41.0612C41.7231 30.8292 42.2432 31.3487 42.2432 32.0099C42.2432 32.671 41.7231 33.1905 41.0612 33.1905Z"
            fill="#1B3950"
          />
          <path
            d="M41.0612 40.2747H33.9686C33.3067 40.2747 32.7866 39.7552 32.7866 39.094C32.7866 38.4328 33.3067 37.9133 33.9686 37.9133H41.0612C41.7231 37.9133 42.2432 38.4328 42.2432 39.094C42.2432 39.7552 41.7231 40.2747 41.0612 40.2747Z"
            fill="#1B3950"
          />
          <path
            d="M41.0612 47.3588H33.9686C33.3067 47.3588 32.7866 46.8393 32.7866 46.1781C32.7866 45.5169 33.3067 44.9974 33.9686 44.9974H41.0612C41.7231 44.9974 42.2432 45.5169 42.2432 46.1781C42.2432 46.8393 41.7231 47.3588 41.0612 47.3588Z"
            fill="#1B3950"
          />
          <path
            d="M62.3387 33.1905H55.2462C54.5842 33.1905 54.0641 32.671 54.0641 32.0099C54.0641 31.3487 54.5842 30.8292 55.2462 30.8292H62.3387C63.0006 30.8292 63.5208 31.3487 63.5208 32.0099C63.5208 32.671 63.0006 33.1905 62.3387 33.1905Z"
            fill="#1B3950"
          />
          <path
            d="M62.3387 40.2747H55.2462C54.5842 40.2747 54.0641 39.7552 54.0641 39.094C54.0641 38.4328 54.5842 37.9133 55.2462 37.9133H62.3387C63.0006 37.9133 63.5208 38.4328 63.5208 39.094C63.5208 39.7552 63.0006 40.2747 62.3387 40.2747Z"
            fill="#1B3950"
          />
          <path
            d="M62.3387 47.3588H55.2462C54.5842 47.3588 54.0641 46.8393 54.0641 46.1781C54.0641 45.5169 54.5842 44.9974 55.2462 44.9974H62.3387C63.0006 44.9974 63.5208 45.5169 63.5208 46.1781C63.5208 46.8393 63.0006 47.3588 62.3387 47.3588Z"
            fill="#1B3950"
          />
        </g>
        <defs>
          <clipPath id="clip0_428_1237">
            <rect
              width="59.1042"
              height="51.9504"
              fill="white"
              transform="translate(18.6016 22.5643)"
            />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }
  return (
    <SvgContainer
      name="book"
      height={127}
      width={127}
      viewBoxHeight={127}
      viewBoxWidth={127}
      {...props}
    >
      <svg
        width="127"
        height="127"
        viewBox="0 0 127 127"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="63.5" cy="63.5" r="63.5" fill="#FFF2DB" />
        <g clipPath="url(#clip0_428_1237)">
          <path
            d="M65.7258 86.6861H37.3796C35.6473 86.6861 34.23 85.2705 34.23 83.5402V70.9568H54.7022C60.7809 70.9568 65.7258 75.8958 65.7258 81.9673V86.6861ZM37.3796 74.1027V83.5402H62.5762V81.9673C62.5762 77.626 59.0487 74.1027 54.7022 74.1027H37.3796Z"
            fill="#1B3950"
          />
          <path
            d="M64.151 42.644C63.2691 42.644 62.5762 41.9519 62.5762 41.0711C62.5762 36.7298 59.0487 33.2064 54.7022 33.2064H37.3796V41.0711C37.3796 41.9519 36.6867 42.644 35.8048 42.644C34.9229 42.644 34.23 41.9519 34.23 41.0711V33.2064C34.23 31.4762 35.6473 30.0605 37.3796 30.0605H54.7022C60.7809 30.0605 65.7258 34.9996 65.7258 41.0711C65.7258 41.9519 65.0329 42.644 64.151 42.644Z"
            fill="#1B3950"
          />
          <path
            d="M90.9224 86.6861H62.5762V81.9673C62.5762 75.8958 67.5211 70.9568 73.5998 70.9568H94.072V83.5402C94.072 85.2705 92.6547 86.6861 90.9224 86.6861ZM65.7258 83.5402H90.9224V74.1027H73.5998C69.2533 74.1027 65.7258 77.626 65.7258 81.9673V83.5402Z"
            fill="#1B3950"
          />
          <path
            d="M92.4972 42.644C91.6154 42.644 90.9224 41.9519 90.9224 41.0711V33.2064H73.5998C69.2533 33.2064 65.7258 36.7298 65.7258 41.0711C65.7258 41.9519 65.0329 42.644 64.151 42.644C63.2691 42.644 62.5762 41.9519 62.5762 41.0711C62.5762 34.9996 67.5211 30.0605 73.5998 30.0605H90.9224C92.6547 30.0605 94.072 31.4762 94.072 33.2064V41.0711C94.072 41.9519 93.3791 42.644 92.4972 42.644Z"
            fill="#1B3950"
          />
          <path
            d="M64.151 69.3838C63.2691 69.3838 62.5762 68.6918 62.5762 67.8109V48.9357C62.5762 48.0549 63.2691 47.3628 64.151 47.3628C65.0329 47.3628 65.7258 48.0549 65.7258 48.9357V67.8109C65.7258 68.6918 65.0329 69.3838 64.151 69.3838Z"
            fill="#1B3950"
          />
          <path
            d="M35.8048 74.1027C34.9229 74.1027 34.23 73.4106 34.23 72.5297V41.0711C34.23 40.1903 34.9229 39.4982 35.8048 39.4982C36.6867 39.4982 37.3796 40.1903 37.3796 41.0711V72.5297C37.3796 73.4106 36.6867 74.1027 35.8048 74.1027Z"
            fill="#1B3950"
          />
          <path
            d="M92.4972 74.1027C91.6153 74.1027 90.9224 73.4106 90.9224 72.5297V41.0711C90.9224 40.1903 91.6153 39.4982 92.4972 39.4982C93.3791 39.4982 94.072 40.1903 94.072 41.0711V72.5297C94.072 73.4106 93.3791 74.1027 92.4972 74.1027Z"
            fill="#1B3950"
          />
          <path
            d="M51.5527 80.3944H35.8048C34.9229 80.3944 34.23 79.7023 34.23 78.8215C34.23 77.9406 34.9229 77.2485 35.8048 77.2485H51.5527C52.4346 77.2485 53.1275 77.9406 53.1275 78.8215C53.1275 79.7023 52.4346 80.3944 51.5527 80.3944Z"
            fill="#1B3950"
          />
          <path
            d="M92.4972 80.3944H76.7493C75.8674 80.3944 75.1745 79.7023 75.1745 78.8215C75.1745 77.9406 75.8674 77.2485 76.7493 77.2485H92.4972C93.3791 77.2485 94.072 77.9406 94.072 78.8215C94.072 79.7023 93.3791 80.3944 92.4972 80.3944Z"
            fill="#1B3950"
          />
          <path
            d="M54.7023 95.935H27.9308C26.1986 95.935 24.7812 94.4879 24.7812 92.6947V42.7384C24.7812 40.9453 26.1986 39.4982 27.9308 39.4982H35.8048C36.6867 39.4982 37.3796 40.1903 37.3796 41.0711C37.3796 41.9519 36.6867 42.644 35.8048 42.644H27.9308V92.6947L54.7023 92.7891C55.5841 92.7891 56.277 93.4812 56.277 94.362C56.277 95.2429 55.5841 95.935 54.7023 95.935Z"
            fill="#1B3950"
          />
          <path
            d="M100.371 95.935H73.5998C72.7179 95.935 72.025 95.2429 72.025 94.362C72.025 93.4812 72.7179 92.7891 73.5998 92.7891H100.371V42.7384L92.4972 42.644C91.6153 42.644 90.9224 41.9519 90.9224 41.0711C90.9224 40.1903 91.6153 39.4982 92.4972 39.4982H100.371C102.103 39.4982 103.521 40.9453 103.521 42.7384V92.6947C103.521 94.4879 102.103 95.935 100.371 95.935Z"
            fill="#1B3950"
          />
          <path
            d="M70.4501 99.2695H57.8518C55.2377 99.2695 53.1274 97.0674 53.1274 94.362C53.1274 93.4812 53.8203 92.7891 54.7022 92.7891C55.5841 92.7891 56.277 93.4812 56.277 94.362C56.277 95.3372 56.9699 96.1237 57.8518 96.1237H70.4501C71.332 96.1237 72.0249 95.3372 72.0249 94.362C72.0249 93.4812 72.7178 92.7891 73.5997 92.7891C74.4816 92.7891 75.1745 93.4812 75.1745 94.362C75.1745 97.0674 73.0643 99.2695 70.4501 99.2695Z"
            fill="#1B3950"
          />
          <path
            d="M54.7022 44.2169H45.2535C44.3716 44.2169 43.6787 43.5248 43.6787 42.644C43.6787 41.7631 44.3716 41.071 45.2535 41.071H54.7022C55.5841 41.071 56.277 41.7631 56.277 42.644C56.277 43.5248 55.5841 44.2169 54.7022 44.2169Z"
            fill="#1B3950"
          />
          <path
            d="M54.7022 53.6545H45.2535C44.3716 53.6545 43.6787 52.9624 43.6787 52.0816C43.6787 51.2008 44.3716 50.5087 45.2535 50.5087H54.7022C55.5841 50.5087 56.277 51.2008 56.277 52.0816C56.277 52.9624 55.5841 53.6545 54.7022 53.6545Z"
            fill="#1B3950"
          />
          <path
            d="M54.7022 63.0921H45.2535C44.3716 63.0921 43.6787 62.4 43.6787 61.5192C43.6787 60.6383 44.3716 59.9462 45.2535 59.9462H54.7022C55.5841 59.9462 56.277 60.6383 56.277 61.5192C56.277 62.4 55.5841 63.0921 54.7022 63.0921Z"
            fill="#1B3950"
          />
          <path
            d="M83.0485 44.2169H73.5998C72.7179 44.2169 72.025 43.5248 72.025 42.644C72.025 41.7631 72.7179 41.071 73.5998 41.071H83.0485C83.9304 41.071 84.6233 41.7631 84.6233 42.644C84.6233 43.5248 83.9304 44.2169 83.0485 44.2169Z"
            fill="#1B3950"
          />
          <path
            d="M83.0485 53.6545H73.5998C72.7179 53.6545 72.025 52.9624 72.025 52.0816C72.025 51.2008 72.7179 50.5087 73.5998 50.5087H83.0485C83.9304 50.5087 84.6233 51.2008 84.6233 52.0816C84.6233 52.9624 83.9304 53.6545 83.0485 53.6545Z"
            fill="#1B3950"
          />
          <path
            d="M83.0485 63.0921H73.5998C72.7179 63.0921 72.025 62.4 72.025 61.5192C72.025 60.6383 72.7179 59.9462 73.5998 59.9462H83.0485C83.9304 59.9462 84.6233 60.6383 84.6233 61.5192C84.6233 62.4 83.9304 63.0921 83.0485 63.0921Z"
            fill="#1B3950"
          />
        </g>
        <defs>
          <clipPath id="clip0_428_1237">
            <rect
              width="78.7395"
              height="69.209"
              fill="white"
              transform="translate(24.7812 30.0605)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgContainer>
  );
}

Book.propTypes = {
  xs: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
};

export default Book;
