import { Map } from 'immutable';

import { SESSION_RESET } from 'actions/session';
import { RESPONSE_SYNCED } from 'actions/responses';
import { STATIC_DATA_FETCHED, staticDataFetch } from 'actions/staticData';
import { QUESTION_BANK_SELECTED, QUESTION_BANK_SUBJECTS_FETCHING } from 'actions/questionBanks';
import {
  QUIZ_SUBMITTED,
  QUIZ_ARCHIVED,
  QUIZ_UNARCHIVED,
  QUIZZES_ARCHIVED,
  QUIZZES_UNARCHIVED,
  QUIZ_UNLOAD_PERFORMANCE,
  QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES
} from 'actions/quizzes';
import {
  ORGANIZATION_SELECTED,
  ORGANIZATION_UNLOAD_MEMBERS
} from 'actions/b2b/organizations';
import { ORGANIZATION_UNLOAD_MEMBER_GROUPS } from 'actions/b2b/memberGroups';
import { RISK_ASSESSMENT_SYNCED, RISK_UNLOAD } from 'actions/riskAssessment';
import { UNLOAD_ASSIGNMENT } from 'actions/b2b/assignments';


/* loadedAt Slice of State
 *
 * This is a special reducer which checks every dispatched Action.
 * It adds and removes keys automatically when a `key` is present in the
 * action payload using the inverse logic of the loading Slice of State.
 * It also reduces specific action constants to cover instances when
 * one action resets data which was loaded by a different action.
 */


// TODO: Find a clean way to limit which actions/keys get stored here
// TODO: Find a cleaner way to unset keys when reducing different actions

function resetCmeAndPerformanceCalculations(state) {
  // Unset keys for data that is calculated from other data
  return state.filter((value, key) => {
    if (key.match(/^cme-trackers\/(\d+|all)$/)) return false;
    if (key.match(/^cme|performance\/calculate\/(\d+|all)$/)) return false;
    return true;
  });
}

function resetRiskData(state) { // expire data in the next 5 min to verify a sync was done
  const expireTime = new Date().getTime() - (3600 - 300) * 1000;
  return state.set('risk_assessment', expireTime);
}

function resetCmePerformanceCalculationsAndRisk(state) {
  return resetRiskData(resetCmeAndPerformanceCalculations(state));
}

const reducers = {};

reducers[SESSION_RESET] = function sessionReset() {
  return new Map();
};

reducers[RESPONSE_SYNCED] = resetCmeAndPerformanceCalculations;
reducers[QUIZ_SUBMITTED] = resetCmePerformanceCalculationsAndRisk;
reducers[QUIZ_ARCHIVED] = resetCmePerformanceCalculationsAndRisk;
reducers[QUIZ_UNARCHIVED] = resetCmePerformanceCalculationsAndRisk;
reducers[QUIZZES_ARCHIVED] = resetCmePerformanceCalculationsAndRisk;
reducers[QUIZZES_UNARCHIVED] = resetCmePerformanceCalculationsAndRisk;
reducers[QUIZ_UNLOAD_PERFORMANCE] = function quizUnloadPerformance(state) {
  return state.filter((value, key) => !key.match(/^performance\//));
};

reducers[ORGANIZATION_UNLOAD_MEMBERS] = function organizationUnloadMembers(state) {
  return state.filter((value, key) => !key.match(/(members)/));
};

reducers[ORGANIZATION_UNLOAD_MEMBER_GROUPS] = function organizationUnloadMemberGroups(state) {
  return state.filter((value, key) => !key.match(/(member-groups)/));
};

reducers[QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES] = (state, payload) => {
  const { quizId, isAdaptive } = payload;
  const regexQuestions = new RegExp(`^quizzes/${quizId}/questions`);
  const regexResponses = new RegExp(`^quizzes/${quizId}/fetch_quiz_responses`);
  return state.filter((value, key) => {
    if (key.match(regexResponses)) {
      return false;
    }
    if (isAdaptive && key.match(regexQuestions)) {
      return false;
    }
    return true;
  });
};

reducers[UNLOAD_ASSIGNMENT] = (state, payload) => {
  const { assignmentId } = payload;
  const regex = new RegExp(`^organizations/assignments/${assignmentId}`);
  return state.filter((value, key) => !key.match(regex));
};

reducers[QUESTION_BANK_SUBJECTS_FETCHING] = function questionBanksSubjectsFetching(state) {
  return state.filter((value, key) => !key.match(/^question-bank-subjects-fetch\//));
};

reducers[QUESTION_BANK_SELECTED] = function questionBankSelected(state) {
  return state.filter((value, key) => !key.match(/^question-bank-subjects-fetch\/|(^(questionBanks\/[0-9]+\/questions\/excerpts))|(^(quizzes-fetch-all|performance\/\d+\/(calculate|peers|questions)))|^risk_assessment|^clinical_pearls$/));
};

reducers[ORGANIZATION_SELECTED] = function questionBankSelected(state) {
  return state.filter((value, key) => !key.match(/^organizations\//));
};

reducers[RISK_UNLOAD] = function quizUnloadRisk(state) {
  return state.filter((value, key) => !key.match('risk_assessment'));
};

// StaticData may be pre-loaded, so it may not include a Promise.
// Therefore we need to manually reduce STATIC_DATA_FETCHED
reducers[STATIC_DATA_FETCHED] = function staticDataFetched(state) {
  return state.set(staticDataFetch.getKey(), new Date().getTime());
};


export default function reducer(state = new Map(), action) {
  let newState = state;
  if (action?.payload?.key) {
    if (Object.prototype.hasOwnProperty.call(action.payload, 'promise')) {
      // Actions that unset loadedAt have a key and a promise in the payload
      // This means that the action is now loading
      newState = newState.set(action.payload.key, null);
    } else {
      // Actions that set loadedAt have a key but no promise in the payload
      // This means that the action is now loaded
      newState = newState.set(action.payload.key, new Date().getTime());
    }
  }

  // This duplicates our basic reducer functionality so that we can reduce specific actions if needed
  if (Object.prototype.hasOwnProperty.call(reducers, action.type)) {
    newState = reducers[action.type](newState, action.payload);
  }

  return newState;
}

reducers[RISK_ASSESSMENT_SYNCED] = function sessionReset(state, payload) {
  const risk = payload.data.attributes;
  if (risk && risk.synced_at > risk.updated_at) {
    return resetRiskData(state);
  }
  return state;
};
