import React from "react";
import { withSvgContainer } from './SvgContainer';


function SuperCorrectRing() {
  return (
    <React.Fragment>
      <defs>
        <path id="a" d="M.007.661h5.836v1.857H.007z" />
        <path id="c" d="M0 .348h5.836v1.856H0z" />
      </defs>
      <g fillRule="evenodd">
        <path d="M20.452 5.928A.928.928 0 0119.524 5V1.014a.927.927 0 111.856 0V5a.928.928 0 01-.928.928" />
        <path d="M26.619 7.585a.927.927 0 01-.85-1.301l1.6-3.65a.928.928 0 111.7.745l-1.6 3.65a.928.928 0 01-.85.556M20.452 41.76a.927.927 0 01-.928-.928v-3.985a.928.928 0 111.856 0v3.985a.927.927 0 01-.928.928m8.258-2.05a.931.931 0 01-.852-.557l-1.6-3.649a.93.93 0 011.701-.745l1.6 3.65a.927.927 0 01-.849 1.3m9.542-9.759a.917.917 0 01-.464-.125l-3.446-1.992a.927.927 0 11.93-1.607l3.446 1.994a.926.926 0 01.338 1.267.924.924 0 01-.804.463" />
        <g transform="translate(35 19.76)">
          <mask id="b">
            <use xlinkHref="#a" />
          </mask>
          <path d="M4.915 2.518H.935a.927.927 0 110-1.857h3.98a.929.929 0 11-.001 1.857" mask="url(#b)" />
        </g>
        <path d="M34.708 15.65a.928.928 0 01-.374-1.778l3.645-1.602a.928.928 0 11.748 1.699l-3.647 1.602a.912.912 0 01-.372.079m-3.156-4.514a.926.926 0 01-.656-1.584l2.784-2.784a.927.927 0 111.311 1.312l-2.783 2.784a.92.92 0 01-.656.272m2.784 24.128a.925.925 0 01-.655-.271l-2.784-2.785a.928.928 0 011.312-1.312l2.783 2.784a.926.926 0 01-.656 1.583M20.39 5.798a.928.928 0 01-.927-.928V.928a.927.927 0 111.856-.001V4.87a.929.929 0 01-.928.929m-6.167 1.637a.928.928 0 01-.849-.552l-1.599-3.61a.926.926 0 01.472-1.223.924.924 0 011.224.472l1.6 3.609a.927.927 0 01-.848 1.304M12.64 39.285a.929.929 0 01-.848-1.305l1.6-3.608a.928.928 0 111.696.752l-1.599 3.61a.929.929 0 01-.849.551M2.59 29.55a.928.928 0 01-.46-1.732l3.446-1.971a.927.927 0 11.921 1.611l-3.447 1.97a.913.913 0 01-.46.123" />
        <g transform="translate(0 19.76)">
          <mask id="d">
            <use xlinkHref="#c" />
          </mask>
          <path d="M4.908 2.204H.928a.928.928 0 010-1.856h3.98a.928.928 0 010 1.856" mask="url(#d)" />
        </g>
        <path d="M6.135 15.41a.913.913 0 01-.37-.077L2.123 13.75a.928.928 0 01.738-1.702l3.645 1.584a.927.927 0 01-.37 1.779m3.144-4.275a.922.922 0 01-.656-.272L5.84 8.08a.928.928 0 011.312-1.312l2.784 2.784a.926.926 0 01-.656 1.584M6.496 35.264a.928.928 0 01-.656-1.584l2.783-2.784a.928.928 0 011.313 1.312l-2.784 2.785a.927.927 0 01-.656.27" />
      </g>
    </React.Fragment>
  );
}

export default withSvgContainer(SuperCorrectRing, 'Super Correct Ring', 42, 42);
