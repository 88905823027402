import React from 'react';
import parseMarkdown from 'helpers/parseMarkdown';
import PropTypes from 'prop-types';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import PageSection from './PageSection';
import CardsCarousel from './components/CardsCarousel';

const CarouselImage = ({ questionBankName, activeQuestionCount, carouselImageSection }) => {
  const {
    section_title: sectionTitle, description, title_text: titleText, is_flipped: isFlipped, images
  } = carouselImageSection;

  const { width: screenWidth } = useWindowDimensions();

  const isSm = screenWidth < breakpoints.lg;

  const imageDimensions = {};
  let caretWidth;
  let caretHeight;
  let dotHeight;
  let dotWidth;

  if (screenWidth < 390) {
    imageDimensions.width = 250;
    imageDimensions.height = 63;
    caretWidth = 9.41;
    caretHeight = 18.82;
    dotHeight = 7.75;
    dotWidth = 8.3;
  } else if (screenWidth <= 420) {
    imageDimensions.width = 290;
    imageDimensions.height = 73;
    caretWidth = 9.41;
    caretHeight = 18.82;
    dotHeight = 7.75;
    dotWidth = 8.3;
  } else if (screenWidth <= breakpoints.sm && screenWidth > 420) {
    imageDimensions.width = 339;
    imageDimensions.height = 85;
    caretWidth = 9.41;
    caretHeight = 18.82;
    dotHeight = 7.75;
    dotWidth = 8.3;
  } else if (screenWidth >= 992 && screenWidth <= 1005) {
    imageDimensions.width = 450;
    imageDimensions.height = 113;
  } else if (screenWidth >= 1006 && screenWidth <= 1080) {
    imageDimensions.width = 500;
    imageDimensions.height = 125;
  } else if (screenWidth >= 1081 && screenWidth <= 1360) {
    imageDimensions.width = 550;
    imageDimensions.height = 138;
  } else {
    imageDimensions.width = 617;
    imageDimensions.height = 155;
    caretWidth = 17;
    caretHeight = 34;
  }

  const imageCards = images.map(image => <img loading="lazy" width={imageDimensions.width} height={imageDimensions.height} src={image.original_url} alt={image.alt_text} />);

  return (
    <PageSection section="carousel-image">
      <div className={`d-flex ${isSm ? 'flex-column-reverse' : 'row'} align-items-center ${isFlipped ? 'flex-lg-row-reverse flex-xl-row-reverse' : ''}  justify-content-between`}>
        <div className={`col-lg-5 col-xl-4 col-12 m${isFlipped ? 'l' : 'r'}-xl-6 justify-content-${isFlipped ? 'end' : 'start'}`}>
          <div className="carousel-image-text">
            <h3 className="storefront-underlined-title section-title mb-6">{parseMarkdown(sectionTitle, { questionBankName, activeQuestionCount }).toUpperCase()}</h3>
            <h2 className="mb-6 storefront-secondary-title">{parseMarkdown(titleText, { questionBankName, activeQuestionCount })}</h2>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="" dangerouslySetInnerHTML={{ __html: description }} />
          </div>

        </div>
        <div className={`carousel-image-cards d-flex col-lg flex-direction-row mr-sm-0 mr-xs-0 mr-md-0 mr-lg-${isFlipped ? '0' : '0'}  mr-xl-${isFlipped ? '6' : '0'} justify-content-sm-center justify-content-xs-center justify-content-md-center justify-content-lg-${isFlipped ? 'start' : 'end'} justify-content-xl-${isFlipped ? 'start' : 'end'}`}>
          <CardsCarousel className={isSm ? 'mb-6' : ''} cards={imageCards} caretWidth={caretWidth} caretHeight={caretHeight} cardClassName={isSm ? '' : "mx-3"} dotHeight={dotHeight} dotWidth={dotWidth} paginationDotsClass="mt-5 pagination-dots d-flex justify-content-center" />
        </div>

      </div>

    </PageSection>
  );
};

CarouselImage.propTypes = {
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  carouselImageSection: PropTypes.shape().isRequired
};

export default CarouselImage;
