import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgResidency(props) {
  return (
    <SvgContainer title="Residency Icon" {...props}>
      <path d="M18 7.5h-1.5V6a.5.5 0 00-1 0v1.5H14a.5.5 0 000 1h1.5V10a.5.5 0 001 0V8.5H18a.5.5 0 000-1zM15 14h2v2h-2zM18 14h2v2h-2zM12 14h2v2h-2zM15 17h2v2h-2zM18 17h2v2h-2zM12 17h2v2h-2z" />
      <path d="M28 27.5h-.5v-14a1.001 1.001 0 00-1-1h-3v-4a1.001 1.001 0 00-1-1h-2v-4h-9v4h-2a1.001 1.001 0 00-1 1v4h-3a1.001 1.001 0 00-1 1v14H4a.5.5 0 000 1h24a.5.5 0 000-1zm-15.5-23h7v7h-7zm-7 23v-14h3v14zm7 0v-6h3v6zm4 0v-6h3v6zm4 0v-7h-9v7h-2v-19h2v4h9v-4h2v19zm3 0v-14h3v14z" />
    </SvgContainer>
  );
}
