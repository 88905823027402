import React from "react";
import PropTypes from "prop-types";

import SVGIcon from 'components/SVGIcon';
import Button from "components/inputs/Button";
import Tooltip from "components/Tooltip";

const SimplifiedPricingCard = ({
  id,
  pricingPlanFeatures,
  months,
  discount,
  price,
  featuresForTooltip,
  tooltipDescription,
  isCme,
  isBestValue
}) => (
  <>
    <div className="simplified-pricing-card">
      {isBestValue && (
        <div className="best-value">
          <p>Best Value</p>
        </div>
      )}
      <div className="header">
        <h2>
          {months + " Months"}
        </h2>
        {isCme && (
          <p className={`monthly-savings ${discount === 0 && "hidden"}`}>
            {`${discount}%`} Monthly Savings
          </p>
        )}
      </div>
      {!isCme && (
        <div className="details">
          <ul className="text-left">
            {pricingPlanFeatures?.map((feature, i) => {
              if (months === 1) {
                if (featuresForTooltip(i)) {
                  return;
                }
              }

              return (
                <span key={i} className="detail-wrapper d-flex mb-3 align-items-start">
                  <SVGIcon name="CircleCheck" width={16} height={16} className="circle-check" />
                  <li>
                    <span>{feature}</span>{featuresForTooltip(i) && <Tooltip hoverable className="ml-2">{tooltipDescription(feature)}</Tooltip>}
                  </li>
                </span>
              );
            })}
          </ul>
        </div>
      )}
      <form id={`product-${id}-form`} className="add-to-cart-form" method="POST" action="/cart_items" data-remote="true">
        <div className="add-to-cart">
          <input type="hidden" name="cart_item[product_id]" value={id} />
          <h3>${price}</h3>
          <Button type="submit" form={`product-${id}-form`} className="storefront-button-text">
            Add to Cart
          </Button>
        </div>
      </form>
    </div>
  </>
);

SimplifiedPricingCard.propTypes = {
  id: PropTypes.number.isRequired,
  pricingPlanFeatures: PropTypes.array,
  months: PropTypes.number.isRequired,
  discount: PropTypes.number,
  price: PropTypes.number.isRequired,
  featuresForTooltip: PropTypes.func,
  tooltipDescription: PropTypes.func,
  isCme: PropTypes.bool,
  isBestValue: PropTypes.bool
};

export default SimplifiedPricingCard;
