import Factory from 'factories/_Factory';


export default class ClinicalPearl extends Factory {
  static jsonType = 'clinical_pearls';

  static get defaults() {
    return {
      safe_name: '',
      trial: false,
    };
  }

  static get hasMany() {
    return ['category'];
  }
}
