import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRightCaret1(props) {
  return (
    <SvgContainer title="Right Caret Icon" {...props}>
      <path d="M21.484 16.094a.467.467 0 000-.188.455.455 0 00-.074-.178.471.471 0 00-.026-.048l-10-12c-.005-.006-.012-.009-.018-.015a.491.491 0 00-.079-.063.509.509 0 00-.077-.052.5.5 0 00-.083-.024.483.483 0 00-.105-.021c-.008-.001-.014-.005-.022-.005a.478.478 0 00-.066.013.354.354 0 00-.194.07.479.479 0 00-.06.033c-.006.005-.009.012-.014.018a.496.496 0 00-.065.08.49.49 0 00-.051.076.489.489 0 00-.024.084.492.492 0 00-.022.105c0 .007-.004.014-.004.022V28c0 .008.004.014.004.022a.593.593 0 00.162.345c.005.005.008.012.014.017a.49.49 0 00.078.045.469.469 0 00.06.035.498.498 0 00.182.036.497.497 0 00.202-.044.509.509 0 00.042-.029.492.492 0 00.123-.094c.005-.005.012-.007.017-.013l10-12a.471.471 0 00.026-.048.455.455 0 00.074-.178zM20.349 16L11.5 26.619V5.38z" />
    </SvgContainer>
  );
}
