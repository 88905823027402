import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRightCaret4(props) {
  return (
    <SvgContainer title="Right Caret Icon" {...props}>
      <path d="M2 36L19 19L2 2" />
    </SvgContainer>
  );
}
