import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgDownCaret3(props) {
  return (
    <SvgContainer title="Down Caret Icon" viewBoxHeight={12} viewBoxWidth={12} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1137.000000, -23.000000)">
          <polygon transform="translate(1143.000000, 26.000000) rotate(-315.000000) translate(-1143.000000, -26.000000) " points="1147 22 1147 30 1139 30" />
        </g>
      </g>
    </SvgContainer>
  );
}
