import * as React from "react";
import PropTypes from 'prop-types';
import SvgContainer from './SvgContainer';

export default function SvgHamburger({ fill, ...props }) {
  const { styleVariant, title } = props;
  return (
    <SvgContainer title={title} width={20} height={20} viewBoxHeight={styleVariant !== 'none' ? 100 : 80} viewBoxWidth={100} {...props}>
      <rect width="100" height="10" rx="8" fill={fill} />
      <rect y="35" width="100" height="10" rx="8" fill={fill} />
      <rect y="70" width="100" height="10" rx="8" fill={fill} />
      { styleVariant === 'down' && (
        <polygon points="33,98 66,98 49.5,118" />
      )}
      { styleVariant === 'up' && (
        <polygon points="49.5,98 66,118 33,118" />
      )}
    </SvgContainer>
  );
}

SvgHamburger.propTypes = {
  fill: PropTypes.string,
  styleVariant: PropTypes.oneOf([
    'up',
    'down',
    'none'
  ]),
  title: PropTypes.string
};

SvgHamburger.defaultProps = {
  fill: 'black',
  styleVariant: 'none',
  title: 'Hamburger'
};
