import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLeftCaret1(props) {
  return (
    <SvgContainer title="Left Caret Icon" {...props}>
      <path d="M21.5 4c0-.008-.004-.014-.004-.022a.492.492 0 00-.021-.104.489.489 0 00-.025-.084.49.49 0 00-.05-.075.496.496 0 00-.065-.081c-.006-.005-.009-.013-.015-.018a85.058 85.058 0 01-.152-.082.48.48 0 00-.102-.02A.473.473 0 0021 3.5c-.008 0-.014.004-.022.004a.49.49 0 00-.105.022.497.497 0 00-.083.024 1.173 1.173 0 00-.156.115c-.005.006-.013.009-.018.015l-10 12a.471.471 0 00-.026.048.455.455 0 00-.073.178.467.467 0 000 .188.455.455 0 00.073.178.471.471 0 00.026.048l10 12c.005.006.012.008.017.013a.492.492 0 00.123.094.509.509 0 00.043.03.497.497 0 00.201.043.498.498 0 00.18-.036.469.469 0 00.062-.035.49.49 0 00.078-.045c.006-.005.008-.012.014-.018a.496.496 0 00.065-.08.59.59 0 00.096-.264c0-.008.004-.014.004-.022zm-1 1.381v21.237L11.651 16z" />
    </SvgContainer>
  );
}
