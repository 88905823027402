import Factory from 'factories/_Factory';


export default class OrganizationalMembership extends Factory {
  static jsonType = 'organizational_membership';

  static get defaults() {
    return {
      role: '',
      verified: false,
      deactivated: false
    };
  }

  static get belongsTo() {
    return ['organization', 'user'];
  }
}
