import api from 'helpers/api';
import { DateTime } from 'luxon';
import { setTimeDifference } from 'helpers/dateTime';

export const STATIC_DATA_LOADING = 'STATIC_DATA_LOADING';
export const STATIC_DATA_FETCHED = 'STATIC_DATA_FETCHED';
export const STATIC_DATA_ERROR = 'STATIC_DATA_ERROR';


// staticDataFetched needs to be a separate export for setting preloaded data in CmeCoach
export const staticDataFetched = (response, timeDifference, key) => ({
  type: STATIC_DATA_FETCHED,
  payload: {
    ...response,
    timeDifference,
    key
  }
});

const getStaticDataKey = () => 'static-data';
export const staticDataFetch = () => (dispatch, getState) => {
  const key = getStaticDataKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get('static_data')
    .then((response) => {
      const timeDifference = DateTime.fromISO(response.meta.server_time).diffNow().milliseconds;
      setTimeDifference(timeDifference);
      dispatch(staticDataFetched(response, timeDifference, key));
      return response;
    })
    .catch((error) => {
      dispatch({
        type: STATIC_DATA_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({ type: STATIC_DATA_LOADING, payload: { key, promise } });
  return promise;
};
staticDataFetch.getKey = getStaticDataKey;
