import React from "react";
import PropTypes from 'prop-types';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";

export default function HomeFromBlog({ blogs }) {
  const { width: screenWidth } = useWindowDimensions();
  const isMobile = screenWidth <= breakpoints.sm;
  const characterLimit = isMobile ? 64 : 318;
  return (
    <div className="HomeFromBlog home-section-wrapper pb-6">
      <div className="container">
        <div className="text-center py-0 py-lg-6">
          <h2 className="storefront section-title py-6">FROM THE BLOG</h2>
        </div>
        <div className="wrapper-blog bg-white p-5 mx-lg-3">
          { blogs.map((blog, index) => (
            <div key={index} className="blog-card text-left border p-4 mb-4 d-flex">
              <img src={blog.thumbnail} alt="blog-img" loading="lazy" width="100" height="100" />
              <div className="ml-4 contentBlog">
                <a href={blog.url} className="storefront blog-title font-weight-bold m-0 truncate truncate__title">{blog.title}</a>
                <p className="storefront mb-2">{`${blog.content.replace(/<[^>]*>/g, '').substring(0, characterLimit)}...`}</p>
                <a href={blog.url} className="storefront blue more-link font-weight-bold">
                  Read more &gt;
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

HomeFromBlog.propTypes = {
  blogs: PropTypes.array
};
