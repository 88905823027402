import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgOrangeCheck(props) {
  return (
    <SvgContainer title="Orange Check Icon" {...props}>
      <path fillRule="evenodd" d="M7.5 15a7.5 7.5 0 0 1 0-15C11.64 0 15 3.36 15 7.5c0 4.142-3.36 7.5-7.5 7.5zm-1.575-3.352.022.019 6.553-6.33-1.21-1.17-5.365 5.185L3.71 7.21 2.5 8.378l3.404 3.289.02-.019z" fill="#FDA100" />
    </SvgContainer>
  );
}
