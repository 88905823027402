import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgDownCaret2(props) {
  return (
    <SvgContainer title="Down Caret Icon" {...props}>
      <path d="M28.384 10.68a.5.5 0 00-.704-.064L16 20.349 4.32 10.616a.5.5 0 00-.64.768l12 10a161.539 161.539 0 00.13.075.498.498 0 00.19.041.498.498 0 00.189-.041 161.539 161.539 0 00.13-.075l12-10a.5.5 0 00.065-.704z" />
    </SvgContainer>
  );
}
