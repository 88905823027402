import { stateHelper } from 'reducers/quizzes/quizzes';
import StrikeThrough from 'factories/StrikeThrough';

import {
  STRIKE_THROUGH_CREATED,
  STRIKE_THROUGH_DESTROYED,
  STRIKE_THROUGH_CREATE_OFFLINE,
  STRIKE_THROUGH_DESTROY_OFFLINE
} from 'actions/strikeThroughs';
import {
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_QUESTIONS
} from 'actions/quizzes';


const reducers = {};


function setStrikeThrough(state, json) {
  const quizId = parseInt(json.relationships.quiz.data.id);
  const answerId = parseInt(json.relationships.answer.data.id);
  let strikeThrough = state.getIn([quizId, 'strike_throughs', answerId]);
  strikeThrough = strikeThrough ? StrikeThrough.update(strikeThrough, json) : new StrikeThrough(json);
  return state.setIn([quizId, 'strike_throughs', answerId], strikeThrough);
}

function setStrikeThroughs(state, payload) {
  return payload.included.filter(record => record.type === 'strike_through').reduce(setStrikeThrough, state);
}


reducers[STRIKE_THROUGH_CREATED] = function strikeThroughCreated(state, payload) {
  return setStrikeThrough(state, payload.data);
};
reducers[STRIKE_THROUGH_DESTROYED] = function strikeThroughDestroyed(state, payload) {
  return setStrikeThrough(state, payload.data);
};


reducers[STRIKE_THROUGH_CREATE_OFFLINE] = function strikeThroughCreateOffline(state, payload) {
  const {
    quizId,
    answerId
  } = payload;

  const json = {
    type: 'strike_through',
    attributes: {
      deleted: false
    },
    relationships: {
      quiz: {
        data: {
          id: quizId,
          type: 'quiz'
        }
      },
      answer: {
        data: {
          id: answerId,
          type: 'answer'
        }
      }
    }
  };
  const options = { fetched: false };

  let strikeThrough = state.getIn([quizId, 'strike_throughs', answerId]);
  strikeThrough = strikeThrough ? StrikeThrough.update(strikeThrough, json, options) : new StrikeThrough(json, options);

  return state
    .setIn([quizId, 'strike_throughs', answerId], strikeThrough)
    .setIn([quizId, 'synced'], false)
    .setIn([quizId, 'updated_at'], new Date().getTime());
};


reducers[STRIKE_THROUGH_DESTROY_OFFLINE] = function strikeThroughDestroyOffline(state, payload) {
  const {
    quizId,
    answerId
  } = payload;

  const json = {
    type: 'strike_through',
    attributes: {
      deleted: true
    },
    relationships: {
      quiz: {
        data: {
          id: quizId,
          type: 'quiz'
        }
      },
      answer: {
        data: {
          id: answerId,
          type: 'answer'
        }
      }
    }
  };
  const options = { fetched: false };

  let strikeThrough = state.getIn([quizId, 'strike_throughs', answerId]);
  strikeThrough = strikeThrough ? StrikeThrough.update(strikeThrough, json, options) : new StrikeThrough(json, options);

  return state
    .setIn([quizId, 'strike_throughs', answerId], strikeThrough)
    .setIn([quizId, 'synced'], false);
};


// Non-StrikeThroughs Action Reducers

reducers[QUIZ_FETCHED_TAKE] = setStrikeThroughs;
reducers[QUIZ_FETCHED_QUESTIONS] = setStrikeThroughs;

// reducers[OFFLINE_QUIZ_SYNCED] = setStrikeThroughs;


export const reducer = stateHelper.createReducer(reducers);

export default reducers;
