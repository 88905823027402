import React from "react";
import PropTypes from 'prop-types';

const PageSection = ({
  children, section, id, sectionRef
}) => (
  <div id={id} className={`${section} sections-wrapper d-flex flex-wrap`} ref={sectionRef}>
    <div className="container d-flex flex-column py-6 px-6 h-100 justify-content-center  inner-wrapper">
      {children}
    </div>
  </div>
);

PageSection.propTypes = {
  section: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object
  ]),
  sectionRef: PropTypes.func,
  id: PropTypes.string
};

export default PageSection;
