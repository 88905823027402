import React from "react";
import SvgContainer from './SvgContainer';

const Plus = props => (
  <SvgContainer title="Calculator Icon" {...props}>
    <path d="M2 2.2417L21.6953 2.2417" stroke="#1B3950" strokeWidth="4" strokeLinecap="round" />
  </SvgContainer>
);

export default Plus;
