import React from "react";

const italicize = (parentString) => {
  let italicizedString = '';
  if (parentString) {
    const substringsToItalicize = ["AMA PRA Category 1™", "Credit(s)", "AMA PRA Category 1"];

    italicizedString = substringsToItalicize.reduce((result, substring) => {
      const regex = new RegExp(`(${substring.replace('(', '\\(').replace(')', '\\)')})`, 'g');
      return result.replace(regex, match => `<i>${match}</i>`);
    }, parentString);
  }
  /* eslint-disable-next-line react/no-danger */
  return <span dangerouslySetInnerHTML={{ __html: italicizedString }} />;
};

export default italicize;
