import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { cmeCoachSearch } from "actions/cmeCoach";
import CmeCoachDetails from "./CmeCoachDetails";
import CmeCoachInputWithDropdown from "../misc-components/CmeCoachInputWithDropdown";

export default function CmeCoachFilter({
  specialties,
  usStates,
  displaySelectObject
}) {
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedSpecialtyName, setSelectedSpecialtyName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [displayCoachContainer, setDisplayCoachContainer] = useState(false);

  const dispatch = useDispatch();

  const {
    displaySelectSpecialty,
    displaySelectState,
    setDisplaySelectSpecialty,
    setDisplaySelectState,
    closeDropdowns
  } = displaySelectObject;

  const filteredSpecialties = specialties.filter(specialty => specialty.get("cme_organization_id"));
  const specialtyOptions = filteredSpecialties.toList().map(specialty => ({
    value: specialty.get("id"),
    name: specialty.get("name")
  })).toArray();

  const stateOptions = usStates.toList().map(state => ({
    value: state.get("id"),
    name: state.get("name")
  })).toArray();

  const handleSelected = (e, inputType) => {
    const value = e.target.value || e.target.getAttribute("data-value");

    if (inputType === "specialty") setSelectedSpecialtyId(value);
    if (inputType === "state") setSelectedStateId(value);
  };

  const handleSearch = () => {
    if (selectedSpecialtyId && selectedStateId) {
      dispatch(cmeCoachSearch(selectedSpecialtyId, selectedStateId));
    } else {
      return;
    }

    setDisplayCoachContainer(true);
  };

  useEffect(() => {
    let specialtyName;
    let stateName;

    specialties.filter((specialty) => {
      if (specialty.get("id") === selectedSpecialtyId) specialtyName = specialty.get("name");
      return specialtyName;
    });

    usStates.filter((state) => {
      if (state.get("id") === selectedStateId) stateName = state.get("name");
      return stateName;
    });

    setSelectedSpecialtyName(specialtyName);
    setSelectedStateName(stateName);
    closeDropdowns();
  }, [selectedSpecialtyId, selectedStateId]);

  return (
    <>
      <div className="finder-container d-flex align-items-center">
        <CmeCoachInputWithDropdown
          options={specialtyOptions}
          displaySelectOptions={displaySelectSpecialty}
          setDisplaySelectOptions={setDisplaySelectSpecialty}
          inputType="specialty"
          handleSelected={handleSelected}
          selectedId={selectedSpecialtyId}
          selectedName={selectedSpecialtyName}
        />
        <div className="vertical-line" />
        <CmeCoachInputWithDropdown
          options={stateOptions}
          displaySelectOptions={displaySelectState}
          setDisplaySelectOptions={setDisplaySelectState}
          inputType="state"
          handleSelected={handleSelected}
          selectedId={selectedStateId}
          selectedName={selectedStateName}
        />
        <span>
          <button
            type="submit"
            className="go-button text-center ml-5"
            onClick={() => handleSearch()}
          >
            Go
          </button>
        </span>
      </div>
      <CmeCoachDetails
        selectedSpecialtyName={selectedSpecialtyName}
        selectedStateName={selectedStateName}
        displayCoachContainer={displayCoachContainer}
      />
    </>
  );
}

CmeCoachFilter.propTypes = {
  specialties: PropTypes.object,
  usStates: PropTypes.object,
  displaySelectObject: PropTypes.object
};
