import React from 'react';
import PropTypes from 'prop-types';


// StyleGuide = elements/global/loading-icon

class LoadingIcon extends React.Component {
  render() {
    const {
      children,
      size,
      hideIcon,
      hideLabel,
      overlay,
      backgroundOpacity
    } = this.props;

    let { className } = this.props;
    const style = {};

    if (overlay) {
      className += ' loading-icon-overlay';
      style.backgroundColor = `rgba(255, 255, 255, ${backgroundOpacity}`;
    }

    if (size === 'large') {
      return (
        <div className={`LoadingIcon loading-icon-large ${className}`} style={style}>
          <img className="loading-icon-image" alt="Loading..." src="/loader.gif" />
        </div>
      );
    }

    return (
      <div className={`LoadingIcon loading-icon-${size} ${className}`} style={style}>
        { !hideIcon && (
          <div className="loading-icon-icon" />
        )}
        { !hideLabel && (
          <div role="alert" aria-live="assertive" className="loading-icon-label">{ children }</div>
        )}
      </div>
    );
  }
}

LoadingIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  hideIcon: PropTypes.bool,
  hideLabel: PropTypes.bool,
  overlay: PropTypes.bool,
  className: PropTypes.string,
  backgroundOpacity: PropTypes.number
};

LoadingIcon.defaultProps = {
  children: 'Loading...',
  size: 'small',
  hideIcon: false,
  hideLabel: false,
  overlay: false,
  className: '',
  backgroundOpacity: 0.5
};

export default LoadingIcon;
