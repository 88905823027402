import React from 'react';
import PropTypes from 'prop-types';


// StyleGuide = elements/inputs/select-dropdown

class LegacySelect extends React.Component {
  render() {
    const {
      name,
      label,
      placeholder,
      className,
      options,
      onChange,
      value,
      disabled,
      required,
      styleVariant
    } = this.props;

    const sortedOptions = options.sort((a, b) => (
      ('' + a.name).localeCompare(b.name)
    ));

    return (
      <div className={`Select name-${name} ${styleVariant} ${className}`}>
        { label && label !== '' && (
          <span className="visible-xs">{label}</span>
        )}
        <select
          name={name}
          className="form-control"
          onChange={e => onChange(e.currentTarget.value)}
          disabled={disabled}
          required={required}
          value={value}
          aria-label={name}
        >
          { placeholder && placeholder !== '' && (
            <option className="hidden-xs" value="">{placeholder}</option>
          )}

          {sortedOptions.map((option, key) => (
            <option
              name={option.name}
              value={option.value}
              key={key}
            >
              {option.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

LegacySelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  styleVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'light',
    'dark',
    'outline-primary',
    'outline-secondary',
    'outline-light',
    'outline-dark'
  ])
};

LegacySelect.defaultProps = {
  options: [],
  className: '',
  onChange: () => {},
  disabled: false,
  required: false
};

export default LegacySelect;
