import StateHelper from 'helpers/StateHelper';
import QuestionBank from 'factories/QuestionBank';
import {
  QUESTION_BANK_FETCHED,
  QUESTION_BANKS_FETCHED,
  QUESTION_BANK_SELECTED,
  QUESTION_BANK_NAMES_FETCHED,
} from 'actions/questionBanks';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  USER_SUBSCRIPTIONS_FETCHED
} from 'actions/users';
import {
  PRODUCTS_FETCHED,
  PRODUCT_IAP_SYNCED
} from 'actions/products';
import {
  CME_ACTIVITIES_FETCHED
} from 'actions/cme';


const stateHelper = new StateHelper(QuestionBank);
export const { initialState } = stateHelper;
const reducers = {};


reducers[QUESTION_BANK_FETCHED] = stateHelper.set;
reducers[QUESTION_BANKS_FETCHED] = stateHelper.set;
reducers[QUESTION_BANK_SELECTED] = stateHelper.set;


// Non-QuestionBanks Action Reducers
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;
reducers[USER_SUBSCRIPTIONS_FETCHED] = stateHelper.set;
reducers[PRODUCTS_FETCHED] = stateHelper.set;
reducers[PRODUCT_IAP_SYNCED] = stateHelper.set;
reducers[CME_ACTIVITIES_FETCHED] = stateHelper.set;

// B2B fetch
reducers[QUESTION_BANK_NAMES_FETCHED] = function addNames(state, payload) {
  return state.withMutations((newState) => {
    Object.entries(payload).forEach((questionBank) => {
      newState.set(questionBank[1][0], new QuestionBank({
        id: questionBank[1][0],
        attributes: {
          name: questionBank[1][1]
        }
      }));
    });
  });
};


export default stateHelper.createReducer(reducers);
