import { useSelector } from 'react-redux';

import { getLoadingKey } from 'helpers/loading';
import {
  responseSync,
  responseEvaluate,
} from 'actions/responses';


export const useIsLoading = (...args) => {
  const loading = useSelector(state => state.loading);
  const key = getLoadingKey(...args);
  if (!key) {
    console.error('Received useIsLoading() with invalid arguments.', { key, args });
    return false;
  }

  if (key instanceof RegExp) {
    const loadingKeys = loading.keySeq();
    for (const k of loadingKeys) {
      if (k.match(key)) return true;
    }
    return false;
  }

  return loading.has(key);
};


export const useLoadedAt = (...args) => {
  const key = getLoadingKey(...args);
  if (!key) {
    console.error('Received useLoadedAt() with invalid arguments.', { key, args });
    return undefined;
  }
  return useSelector(state => state.loadedAt.get(key));
};


export const useIsLoaded = (...args) => {
  const key = getLoadingKey(...args);
  const loadedAt = useSelector(state => state.loadedAt);
  if (!key) {
    console.error('Received useIsLoaded() with invalid arguments.', { key, args });
    return false;
  }
  return loadedAt.get(key) && loadedAt.has(key);
};

export const useIsResponseSyncing = (quizId, questionId) => {
  const responseSyncing = useIsLoading(responseSync, quizId, questionId);
  return useIsLoading(responseEvaluate, quizId, questionId) || responseSyncing;
};
