import * as React from "react";
import PropTypes from 'prop-types';
import SvgContainer from './SvgContainer';

export default function SvgCheck({ dynamicNumber, ...props }) {
  const { styleVariant } = props;
  const fillColor = styleVariant === 'light' ? '#00AEEF' : '#0044DB';

  return (
    <SvgContainer title="Checkbox Selected" width={21} height={21} viewBoxHeight={23} viewBoxWidth={23} {...props}>
      <g fillRule="nonzero" fill="none">
        <rect fill={fillColor} width="21" height="21" rx="5" />
        <text x="45%" y="55%" fill="#FFF" dominantBaseline="middle" textAnchor="middle" fontWeight="bold">{dynamicNumber}</text>
      </g>
    </SvgContainer>
  );
}

SvgCheck.propTypes = {
  dynamicNumber: PropTypes.number,
  styleVariant: PropTypes.oneOf([
    'light',
    'none'
  ]),
};

SvgCheck.defaultProps = {
  styleVariant: 'none',
  dynamicNumber: 1
};
