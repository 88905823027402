import { createSelector } from 'reselect';


let multiSelectorIndex = 0;
const multiSelectors = {};

export function createMultiSelector(inputSelectors, resultFunc) {
  const selectors = {};
  const multiSelector = (state, id, ...args) => {
    const selectorId = String(id);
    if (!Object.hasOwnProperty.call(selectors, selectorId)) {
      selectors[selectorId] = createSelector(inputSelectors, resultFunc);
    }
    return selectors[selectorId](state, id, ...args);
  };

  multiSelectors[multiSelectorIndex] = multiSelector;
  multiSelectorIndex += 1;
  return multiSelector;
}

window.multiSelectors = multiSelectors;
