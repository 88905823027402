import React from "react";
import SvgContainer from './SvgContainer';

const PsiExit = props => (
  <SvgContainer title="Exit Icon" {...props}>
    <path d="M29.4 28.3H2.6c-.3 0-.5-.2-.5-.5V4.1c0-.3.2-.5.5-.5h26.8c.3 0 .5.2.5.5v23.7c0 .3-.2.5-.5.5z" fill="#445f2c" /><radialGradient id="PsiExit_svg__a" cx="15.279" cy="15.217" r="12.366" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#e0ebb4" /><stop offset=".226" stopColor="#d9e7aa" /><stop offset=".633" stopColor="#c7dd92" /><stop offset="1" stopColor="#b4d27a" /></radialGradient><path d="M28 26.8H2.6c-.3 0-.5-.2-.5-.5V4.1c0-.3.2-.5.5-.5H28c.3 0 .5.2.5.5v22.1c-.1.3-.3.6-.5.6z" fill="url(#PsiExit_svg__a)" /><path fillRule="evenodd" clipRule="evenodd" fill="#010101" d="M22.3 9.7L21 8.4l-5.7 5.7-5.7-5.7-1.4 1.3 5.7 5.7-5.7 5.7 1.4 1.4 5.7-5.7 5.7 5.7 1.3-1.4-5.7-5.7z" />
  </SvgContainer>
);

export default PsiExit;
