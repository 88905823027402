import Factory from 'factories/_Factory';

export default class CreditCard extends Factory {
  static jsonType = 'stripe_customer';

  static get defaults() {
    return {
      card_last4: '',
      card_zip_code: '',
      card_exp_month: '',
      card_exp_year: ''
    };
  }
}
