import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgInfo(props) {
  return (
    <SvgContainer title="Info Icon" {...props}>
      <path d="M16 4a12 12 0 1012 12A12.013 12.013 0 0016 4zm0 23a11 11 0 1111-11 11.012 11.012 0 01-11 11z" />
      <path d="M16 12.5a2.5 2.5 0 10-2.5-2.5 2.503 2.503 0 002.5 2.5zm0-4a1.5 1.5 0 11-1.5 1.5A1.502 1.502 0 0116 8.5zM18.5 20.5v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v2a1 1 0 001 1v3a1 1 0 00-1 1v2a1 1 0 001 1h5a1 1 0 001-1v-2a1 1 0 00-1-1zm0 3h-5v-2h1v-5h-1v-2h4v7h1z" />
    </SvgContainer>
  );
}
