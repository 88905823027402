import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgGreenCheck(props) {
  return (
    <SvgContainer title="Green Check Icon" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#3E8A3D" fillRule="nonzero">
          <path d="M10.818.198c-.257-.264-.684-.264-.927 0L3.872 6.36 1.12 3.55c-.257-.264-.685-.264-.927 0-.257.263-.257.703 0 .951l3.209 3.294C3.53 7.927 3.73 8 3.9 8c.171 0 .342-.073.47-.205l6.447-6.631c.243-.278.243-.703 0-.966z" transform="translate(-146 -275) translate(146 275)" />
        </g>
      </g>
    </SvgContainer>
  );
}
