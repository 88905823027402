import React from 'react';
import PropTypes from "prop-types";

import Keypad from './keypad/Keypad';
import Display from './display/Display';


const CalculatorContainer = ({
  displayValue, mode, trigUnit, ...keypadProps
}) => (
  <div className={`calculator-container ${mode}`}>
    <div className={`calculator ${mode}`}>
      <Display trigUnit={trigUnit} value={displayValue} mode={mode} />
      <Keypad trigUnit={trigUnit} mode={mode} {...keypadProps} />
    </div>
  </div>
);


CalculatorContainer.propTypes = {
  displayValue: PropTypes.string.isRequired,
  changeMode: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['basic', 'scientific', 'fred']),
  trigUnit: PropTypes.oneOf(['deg', 'rad'])
};

export default CalculatorContainer;
