import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLinked(props) {
  return (
    <SvgContainer title="Linked Icon" {...props}>
      <path d="M24 9.5H8A4.505 4.505 0 003.5 14v4A4.505 4.505 0 008 22.5h16a4.505 4.505 0 004.5-4.5v-4A4.505 4.505 0 0024 9.5zm-16 12A3.504 3.504 0 014.5 18v-4A3.504 3.504 0 018 10.5h5.207a4.49 4.49 0 00-1.43 2H9A2.503 2.503 0 006.5 15v2A2.503 2.503 0 009 19.5h6a2.503 2.503 0 002.5-2.5v-.91a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V18a3.504 3.504 0 01-3.5 3.5zm3.5-5.5a1.502 1.502 0 001.5 1.5h1a1.501 1.501 0 001.5-1.496V15a1.502 1.502 0 011.5-1.5h6a1.502 1.502 0 011.5 1.5v2a1.502 1.502 0 01-1.5 1.5h-2.55a4.412 4.412 0 00.05-.5v-2h-.01A1.498 1.498 0 0019 14.59h-1A1.498 1.498 0 0016.51 16h-.01v1a1.502 1.502 0 01-1.5 1.5H9A1.502 1.502 0 017.5 17v-2A1.502 1.502 0 019 13.5h2.55a4.412 4.412 0 00-.05.5v2zm16 2a3.504 3.504 0 01-3.5 3.5h-5.206a4.49 4.49 0 001.43-2H23a2.503 2.503 0 002.5-2.5v-2a2.503 2.503 0 00-2.5-2.5h-6a2.503 2.503 0 00-2.5 2.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2a3.504 3.504 0 013.5-3.5h8a3.504 3.504 0 013.5 3.5zM16 23.5a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5zM18.646 23.94a.5.5 0 00-.707.707l1.414 1.414a.5.5 0 00.707-.707zM13.354 23.94l-1.414 1.414a.5.5 0 10.707.707l1.414-1.414a.5.5 0 10-.707-.707zM16 8.5a.5.5 0 00.5-.5V6a.5.5 0 00-1 0v2a.5.5 0 00.5.5zM18.293 8.207a.498.498 0 00.353-.146l1.414-1.414a.5.5 0 10-.706-.707L17.94 7.353a.5.5 0 00.353.854zM13.354 8.06a.5.5 0 10.707-.707L12.646 5.94a.5.5 0 10-.707.707z" />
    </SvgContainer>
  );
}
