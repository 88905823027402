import { List } from 'immutable';

import Factory from 'factories/_Factory';
import CmeLicense from 'factories/CmeLicense';


export default class CmeOrganization extends Factory {
  static jsonType = 'cme_organization';

  static afterUpdate(cmeOrganization, json) {
    const licenses = List((json.attributes.licenses || []).map(licenseJson => new CmeLicense(licenseJson)));
    return cmeOrganization.set('licenses', licenses);
  }

  static get defaults() {
    return {
      name: '',
      phone_number: '',
      website: ''
    };
  }
}
