import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import LegacyQuestion from 'components/LegacyQuestion';
import Button from 'components/inputs/Button';
import { questionPreloaded } from 'actions/questions';


// We need to export the non-Redux-Connected Component Class for testing and inheritance
export class SampleQuestionClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponseCorrectness: false,
      selectedAnswer: null
    };
  }

  componentDidMount() {
    const { actions, preloadedData, questionId } = this.props;
    if (preloadedData && preloadedData.data && preloadedData.data.id && parseInt(preloadedData.data.id) === questionId) {
      actions.questionPreloaded(preloadedData);
    } else {
      console.warn('no preloaded data for Sample Question');
    }
  }

  onChangeAnswer(newAnswer) {
    let { selectedAnswer } = this.state;
    const { questions, questionId } = this.props;
    const question = questions.get(questionId);
    if (Number.isInteger(newAnswer)) {
      if (!Array.isArray(selectedAnswer)) selectedAnswer = [];
      if (question.get('correct_answer_ids').length > 1) {
        if (selectedAnswer.indexOf(newAnswer) !== -1) {
          selectedAnswer.splice(selectedAnswer.indexOf(newAnswer), 1);
        } else {
          selectedAnswer.push(newAnswer);
        }
      } else {
        selectedAnswer = [newAnswer];
      }
    } else {
      selectedAnswer = newAnswer;
    }
    this.setState({ selectedAnswer });
  }

  render() {
    const { showResponseCorrectness, selectedAnswer } = this.state;
    const { questions, questionId } = this.props;
    const question = questions.get(questionId);

    if (!question) return null;

    return (
      <div className="SampleQuestion">
        <LegacyQuestion
          question={question}
          onChangeAnswer={answer => this.onChangeAnswer(answer)}
          onClickShowAnswer={() => this.setState({ showResponseCorrectness: !showResponseCorrectness })}
          showResponseCorrectness={showResponseCorrectness}
          canShowAnswer
          selectedAnswer={selectedAnswer}
        />
      </div>
    );
  }
}

SampleQuestionClass.propTypes = {
  questionId: PropTypes.number.isRequired,
  preloadedData: PropTypes.object
};

function mapStateToProps({ questions }) {
  return { questions };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      questionPreloaded
    }, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SampleQuestionClass);
