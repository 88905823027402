import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVuePencil(props) {
  return (
    <SvgContainer title="Pencil Icon" {...props}>
      <path d="M27.62 47.77a1.45 1.45 0 0 1-1.06-.44l-7.24-7.23a1.5 1.5 0 0 1 0-2.12L45.5 11.8a1.51 1.51 0 0 1 2.12 0l7.24 7.2a1.5 1.5 0 0 1 .44 1.06 1.52 1.52 0 0 1-.44 1.06L28.68 47.33a1.47 1.47 0 0 1-1.06.44ZM22.51 39l5.11 5.11 24.06-24L46.56 15Z" className="pv-pencil-cls-1" /><path d="M16.59 51.9a1.5 1.5 0 0 1-1.42-2L19 38.57a1.5 1.5 0 0 1 2.45-.57l7.23 7.23a1.51 1.51 0 0 1-.54 2.47l-11 4.13a1.64 1.64 0 0 1-.55.07ZM21 41.82l-2 6.08 5.9-2.21ZM48.52 26.88a1.49 1.49 0 0 1-1.06-.44l-7.24-7.24a1.5 1.5 0 0 1 2.12-2.12l7.24 7.24a1.51 1.51 0 0 1 0 2.12 1.53 1.53 0 0 1-1.06.44Z" className="pv-pencil-cls-1" />
    </SvgContainer>
  );
}
