import StateHelper from 'helpers/StateHelper';

import {
  SESSION_RESET,
  SESSION_FETCHED
} from 'actions/session';

import {
  ORGANIZATIONS_FETCHED
} from 'actions/b2b/organizations';
import StaffOrganization from '../factories/StaffOrganization';


const stateHelper = new StateHelper(StaffOrganization);

const reducers = {};

reducers[ORGANIZATIONS_FETCHED] = stateHelper.set;


reducers[SESSION_RESET] = stateHelper.reset;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;

export default stateHelper.createReducer(reducers);
