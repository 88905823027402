import Factory from 'factories/_Factory';

export default class Product extends Factory {
  static jsonType = 'product'

  static afterUpdate(record, json) {
    let newRecord = record;
    const { attributes } = json;

    if (attributes.type === "StudentPurchaseOption") {
      newRecord = newRecord.set('student_purchase_option', true);
    }
    if (attributes.category === "practice_exam") {
      newRecord = newRecord.set('practice_exam_purchase_modal_description', attributes.practice_exam_purchase_modal_description);
    }

    return newRecord;
  }

  static get defaults () {
    return {
      name: '',
      display_name: '',
      price: -1,
      duration: 0,
      apple_price: -1,
      category: '',
      apple_price_tier: -1,
      apple_product_id: '',
      google_product_id: '',
      cme_discount_amount: 0,
      description: '',
      ce_credit_type: '',
      is_vital_prep: false,
      iap_price_type: '',
      promo_banner: '',
      active: false,
      student_purchase_option: false
    };
  }

  static get hasMany () {
    return ['question_bank'];
  }
}
