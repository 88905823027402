export default function createReducer(handlers, initialState) {
  return function reducer(state = initialState, action) {
    let newState = state;

    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      newState = handlers[action.type](state, action.payload);
    }

    return newState;
  };
}
