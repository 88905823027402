import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueMultipleFlags(props) {
  return (
    <SvgContainer title="Multiple Flags Icon" {...props}>
      <defs>
        <clipPath id="clip-path">
          <path d="M42.66 7.69V23a1.41 1.41 0 0 1 1 1 6.24 6.24 0 0 1-1 2.42c-1.06 1.35-2.92 1.61-4.52 1.95a10.83 10.83 0 0 0-5 2 10.26 10.26 0 0 0-2.64 4c-1.05 2.44-1.63 5.92-4.28 7.21a30.68 30.68 0 0 1-3 .85 6.4 6.4 0 0 0-2.7 1.9l-.44.1-11.19 2.81L4.15 25.8l16-15.52Z" className="cls-1" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path d="M36.62-1.38v15.31c.93.33.94.66 1 1a5.08 5.08 0 0 1-.9 2.42c-1.06 1.36-2.92 1.61-4.51 2a10.7 10.7 0 0 0-5 2 10.22 10.22 0 0 0-2.64 4c-1 2.44-1.62 5.92-4.28 7.21a28 28 0 0 1-3 .86 6.29 6.29 0 0 0-2.7 1.89l-.44.11L3 38.17l-4.77-21.45L14.17 1.2Z" className="cls-1" />
        </clipPath>
        <style>
          {".cls-1{fill:none}"}
        </style>
      </defs>
      <path d="M51.73 30.51c-.22.27-.37.27-.43.11ZM49.89 57.63h-.18a1.58 1.58 0 0 1-1.57-1.41l-1.59-14.17c-1.35-.39-6.56-1.66-8 1.69 0 0-3.43 8.65-10.78 4.53 0 0-4.94-1.6-5.91 2.19 0 0 .6-6.35 4.57-7 3.36-.57 5.65-3.42 6.38-6.64.8-3.56 1.46-5.74 5.42-6.85a25.34 25.34 0 0 0 4.72-1.33 12.16 12.16 0 0 0 1.93-1.88l-.38-3.44a1.58 1.58 0 1 1 3.1-.32l3.69 32.92a1.59 1.59 0 0 1-1.4 1.71Z" />
      <path d="M43.59 49h-.18a1.6 1.6 0 0 1-1.58-1.41l-1.58-14.13c-1.36-.39-6.57-1.66-8.06 1.69 0 0-3.42 8.65-10.78 4.53 0 0-4.93-1.6-5.91 2.19 0 0 .61-6.35 4.57-7 3.36-.57 5.65-3.42 6.38-6.64.81-3.56 1.46-5.74 5.43-6.85A25.45 25.45 0 0 0 36.59 20a11.73 11.73 0 0 0 1.93-1.88l-.38-3.44a1.59 1.59 0 0 1 1.4-1.68 1.57 1.57 0 0 1 1.75 1.4L45 47.29A1.58 1.58 0 0 1 43.59 49Z" style={{ clipPath: "url(#clip-path)" }} />
      <path d="M37.67 40h-.18a1.59 1.59 0 0 1-1.57-1.42l-1.59-14.19c-1.35-.39-6.56-1.66-8 1.69 0 0-3.43 8.64-10.78 4.52 0 0-4.94-1.59-5.91 2.19 0 0 .6-6.34 4.56-7 3.37-.57 5.66-3.41 6.39-6.64.8-3.56 1.46-5.74 5.42-6.85a25.34 25.34 0 0 0 4.67-1.3 11.69 11.69 0 0 0 1.93-1.88l-.38-3.43a1.58 1.58 0 1 1 3.15-.35l3.69 32.91a1.6 1.6 0 0 1-1.4 1.75Z" style={{ clipPath: "url(#clip-path-2)" }} />
    </SvgContainer>
  );
}
