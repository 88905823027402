import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgResetPassword(props) {
  return (
    <SvgContainer title="Reset Password" {...props} viewBoxHeight={19} viewBoxWidth={14}>
      <g fill="#4A4A4A">
        <path d="M7 0C4.385 0 2.257 2.084 2.257 4.644v2.71h-.652C.72 7.354 0 8.058 0 8.924v7.505C0 17.295.72 18 1.605 18h10.79C13.28 18 14 17.295 14 16.429V8.924c0-.867-.72-1.57-1.605-1.57h-.65v-2.71C11.744 2.084 9.614 0 7 0zM2.898 4.644C2.898 2.43 4.738.628 7 .628c2.263 0 4.103 1.801 4.103 4.016v2.71H2.898v-2.71zm9.498 3.338c.53 0 .963.423.963.942v7.506c0 .52-.432.942-.963.942H1.605a.954.954 0 01-.963-.942V8.924c0-.52.432-.942.963-.942h10.791z" />
        <path d="M7 9c-1.103 0-2 .843-2 1.879 0 .651.355 1.242.937 1.585v1.538c0 .55.477.998 1.063.998s1.063-.447 1.063-.998v-1.538c.582-.343.937-.934.937-1.585C9 9.843 8.103 9 7 9zm.595 3.013a.304.304 0 00-.18.273v1.717c0 .215-.186.39-.415.39-.229 0-.415-.175-.415-.39v-1.717a.304.304 0 00-.18-.273c-.468-.216-.758-.651-.758-1.133 0-.7.607-1.27 1.353-1.27s1.353.57 1.353 1.27c0 .482-.29.917-.758 1.133z" />
      </g>
    </SvgContainer>
  );
}
