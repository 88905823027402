import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

import rootReducer from 'reducers';
import immutablePersistenceTransform from 'helpers/immutablePersistenceTransform';
import { loadingMiddleware } from 'helpers/loading';
import { bugfenderReduxLogger, bugfenderReduxExceptionReporter } from 'helpers/bugfenderLogger';


export default function makeStore(persist = false) {
  const middleware = [thunk, loadingMiddleware, bugfenderReduxLogger, bugfenderReduxExceptionReporter];
  if (window?.enableReduxConsoleLogging) {
    middleware.push(
      createLogger({
        diff: true,
        collapsed: true, // collapsed can also be a function (getState, action) if we want certain actions to be open
        predicate: (getState, action) => action.type !== 'QUIZ_TICK',
        diffPredicate: (getState, action) => !['STATIC_DATA_FETCHED', 'PERFORMANCE_RESPONSES_FETCHED', 'PERFORMANCE_QUESTIONS_FETCHED', 'QUIZZES_FETCHED'].includes(action.type),
        stateTransformer(state) {
          const stateLog = {};
          // We want to make sure to output any ImmutableJS objects here as plain JS objects
          Object.keys(state).forEach((key) => {
            if (['modal', 'performanceResponses', 'performanceQuestions', 'performancePeers', 'performanceAllStudents', 'performanceSchoolPeers'].includes(key)) return;

            const value = state[key];
            if (typeof value.toJS === 'function') {
              stateLog[key] = value.toJS();
            } else {
              stateLog[key] = value;
            }
          });
          return stateLog;
        }
      })
    );
  }

  const persistConfig = {
    transforms: [immutablePersistenceTransform],
    key: 'root',
    storage: localForage,
    blacklist: ['loading', 'modal']
  };

  const reducer = persist ? persistReducer(persistConfig, rootReducer) : rootReducer;

  const middlewareEnhancer = applyMiddleware(...middleware);
  const store = createStore(reducer, undefined, compose(middlewareEnhancer));
  return store;
}
