import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgUpCaret2(props) {
  return (
    <SvgContainer title="Up Caret Icon" {...props}>
      <path d="M28.32 20.616l-12-10a.473.473 0 00-.05-.027.448.448 0 00-.172-.072.46.46 0 00-.188-.001 1.126 1.126 0 00-.23.1l-12 10a.5.5 0 00.64.768L16 11.651l11.68 9.733a.5.5 0 00.64-.768z" />
    </SvgContainer>
  );
}
