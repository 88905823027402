import { Map } from 'immutable';

import Factory from 'factories/_Factory';


export default class LabValue extends Factory {
  static jsonType = 'lab_value';

  static afterUpdate(record, json) {
    // const sections = List((json.attributes.sections || []).map(section => new LabValueSection(section)));
    // return labValue.set('sections', sections);

    let newRecord = record;

    if (json.attributes.sections || newRecord.get('sections') === undefined) {
      let sections = new Map();

      (json.attributes.sections || []).forEach((sectionJson) => {
        const sectionId = parseInt(sectionJson.id) || 0;
        let section = new Map({
          id: sectionId,
          heading: sectionJson.heading || '',
          items: new Map()
        });

        sectionJson.items.forEach((itemJson) => {
          const itemId = parseInt(itemJson.id) || 0;
          section = section.setIn(['items', itemId], new Map({
            id: itemId,
            name: itemJson.name || '',
            ref_range: itemJson.ref_range || '',
            si_ref_intervals: itemJson.si_ref_intervals || ''
          }));
        });

        sections = sections.set(sectionId, section);
      });

      newRecord = newRecord.set('sections', sections);
    }

    return newRecord;
  }

  static get defaults() {
    return {
      tab_name: ''
    };
  }
}
