import * as React from "react";
import SvgContainer from './SvgContainer';

export default function Remove(props) {
  return (
    <SvgContainer title="Remove" viewBoxHeight={14} viewBoxWidth={14} {...props}>
      <g
        fill="#767676"
        fillRule="evenodd"
      >
        <path d="M7 0a7 7 0 100 14A7 7 0 007 0zm0 12.727A5.724 5.724 0 011.273 7 5.724 5.724 0 017 1.273 5.724 5.724 0 0112.727 7 5.724 5.724 0 017 12.727z" fillRule="nonzero" /><path d="M10.158 6H3.842C3.38 6 3 6.45 3 7s.379 1 .842 1h6.316C10.62 8 11 7.55 11 7s-.379-1-.842-1z" />
      </g>
    </SvgContainer>
  );
}
