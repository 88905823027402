import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCalculator(props) {
  return (
    <SvgContainer title="Calculator Icon" {...props}>
      <path d="M25 2.5H7A2.503 2.503 0 004.5 5v22A2.503 2.503 0 007 29.5h18a2.503 2.503 0 002.5-2.5V5A2.503 2.503 0 0025 2.5zM26.5 27a1.502 1.502 0 01-1.5 1.5H7A1.502 1.502 0 015.5 27V5A1.502 1.502 0 017 3.5h18A1.502 1.502 0 0126.5 5z" />
      <path d="M7.5 10.5h17v-5h-17zm1-4h15v3h-15zM14 16h-2v-2a.5.5 0 00-1 0v2H9a.5.5 0 000 1h2v2a.5.5 0 001 0v-2h2a.5.5 0 000-1zM13.621 21.379a.5.5 0 00-.707 0L11.5 22.793l-1.414-1.414a.5.5 0 10-.707.707l1.414 1.414-1.414 1.414a.5.5 0 10.707.707l1.414-1.414 1.414 1.414a.5.5 0 10.707-.707L12.207 23.5l1.414-1.414a.5.5 0 000-.707zM23 16h-5a.5.5 0 000 1h5a.5.5 0 000-1zM23 23h-5a.5.5 0 000 1h5a.5.5 0 000-1z" />
      <circle cx={20.5} cy={21.5} r={0.5} />
      <circle cx={20.5} cy={25.5} r={0.5} />
    </SvgContainer>
  );
}
