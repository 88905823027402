import React from 'react';
import PropTypes from 'prop-types';

import indexToLetter from 'helpers/indexToLetter';

import WYSIWYG from 'components/WYSIWYG';

class LegacyAnswers extends React.Component {
  renderAnswer(answer, index) {
    const {
      selectedAnswerIds,
      correctAnswerIds,
      showResponseCorrectness,
      onSelectAnswer,
      onClickAnswer,
      multipleChoice,
      strikeThroughs,
      disableAnswerSelection
    } = this.props;
    const isChecked = selectedAnswerIds.indexOf(answer.get('id')) !== -1;
    const isCorrect = correctAnswerIds.indexOf(answer.get('id')) !== -1;
    const hasStrikeThrough = strikeThroughs.size > 0 && strikeThroughs.has(answer.get('id'))
                             && !strikeThroughs.get(answer.get('id')).get('deleted');
    let status = null;
    if (showResponseCorrectness) {
      if (isCorrect) {
        status = true;
      } else if (isChecked) {
        status = false;
      }
    }

    let className = 'answer col col-12 col-xs-12';
    if (isCorrect && showResponseCorrectness) {
      className += ' correct-answer';
    }

    return (
      <li className={className} key={answer.get('choice')}>
        <label
          className={`answer-label ${hasStrikeThrough && 'strikeThrough'}`}
          htmlFor={`answer-${answer.get('choice')}`}
        >
          { status === true && (
            <div className="answer-status success">&#10004;&#xFE0E;</div>
          )}
          { status === false && (
            <div className="answer-status error">&#215;</div>
          )}
          <input
            readOnly={hasStrikeThrough}
            disabled={hasStrikeThrough || disableAnswerSelection}
            id={`answer-${answer.get('choice')}`}
            className="response-input"
            type={multipleChoice ? 'checkbox' : 'radio'}
            value={answer.get('id')}
            checked={isChecked}
            onChange={() => onSelectAnswer(answer.get('id'))}
          />
          <span
            className="answer-letter-and-text"
            onClick={ev => onClickAnswer(ev, answer.get('id'), isChecked)}
            onKeyDown={ev => onClickAnswer(ev, answer.get('id'), isChecked)}
            role="button"
            tabIndex={index}
          >
            <span className="answer-index">{ indexToLetter(index) }. </span>
            <WYSIWYG className="answer-name">{ answer.get('safe_name') }</WYSIWYG>
          </span>
        </label>
      </li>
    );
  }

  render() {
    const { answers } = this.props;
    return (
      <ul className="LegacyAnswers row">
        {answers.map((answer, index) => this.renderAnswer(answer, index))}
      </ul>
    );
  }
}

LegacyAnswers.propTypes = {
  answers: PropTypes.object.isRequired,
  correctAnswerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAnswerIds: PropTypes.arrayOf(PropTypes.number),
  onSelectAnswer: PropTypes.func,
  onClickAnswer: PropTypes.func,
  showResponseCorrectness: PropTypes.bool,
  multipleChoice: PropTypes.bool,
  strikeThroughs: PropTypes.object,
  disableAnswerSelection: PropTypes.bool
};

LegacyAnswers.defaultProps = {
  selectedAnswerIds: [],
  onSelectAnswer: () => {},
  strikeThroughs: new Map(),
  showResponseCorrectness: false,
  multipleChoice: false
};

export default LegacyAnswers;
