import * as React from "react";
import SvgContainer from './SvgContainer';

export default function FredRadioUnselected(props) {
  return (
    <SvgContainer title="Radio Unselected Icon" {...props}>
      <path d="M16 31C7.7 31 1 24.3 1 16S7.7 1 16 1s15 6.7 15 15-6.7 15-15 15zm0-29C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2z" fill="#c1c4cb" />
    </SvgContainer>
  );
}
