import { useDispatch } from 'react-redux';

import { staticDataFetch } from 'actions/staticData';
import { useIsLoading, useLoadedAt } from 'hooks/loading';


export const useFetchStaticDataIfNeeded = () => {
  const dispatch = useDispatch();
  const isLoading = useIsLoading(staticDataFetch);
  const loadedAt = useLoadedAt(staticDataFetch);

  // Reload static data every 24 hours
  if ((!loadedAt || new Date().getTime() - loadedAt > 1000 * 60 * 60 * 24) && !isLoading) {
    dispatch(staticDataFetch());
  }

  return isLoading;
};
