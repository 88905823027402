import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeReasonChallengingQuestions(props) {
  return (
    <SvgContainer title="Challenging Questions" {...props}>
      <circle cx="63.5" cy="63.5" r="63.5" fill="#FFF2DB" />
      <g fill="#1B3950">
        <path d="M77.697 96.532H30V39.936h11.962v44.681h27.422l.27 1.132c.777 3.157 2.511 5.958 5.023 8.162l2.99 2.621h.03ZM32.99 93.553h37.261a19.09 19.09 0 0 1-3.17-5.957h-28.11V42.915h-5.98v50.638Z" />
        <path d="M70.159 87.596h-31.19V31h50.837v34.821l-1.794-.357c-.688-.15-1.495-.209-2.452-.209-3.29 0-6.43.954-9.121 2.74-3.08 2.026-5.383 5.065-6.55 8.58v.148a15.89 15.89 0 0 0-.777 4.915c0 1.37.18 2.74.539 4.081l.508 1.877Zm-28.2-2.979h24.402c-.15-.983-.24-1.996-.24-2.979 0-1.817.27-3.693.808-5.54l.06-.268a19.571 19.571 0 0 1 7.775-10.307c3.17-2.114 6.908-3.246 10.796-3.246.448 0 .867 0 1.256.03V33.978H41.959v50.638Z" />
        <path d="M68.43 77.766a1.51 1.51 0 0 1-1.346-.834L57.066 56.974c-.359-.744-.06-1.638.658-1.995.748-.358 1.645-.06 2.004.655L69.746 75.59c.358.745.06 1.639-.658 1.996-.21.12-.449.15-.658.15v.029Z" />
        <path d="M46.447 81.638c-.239 0-.448-.06-.658-.149a1.451 1.451 0 0 1-.657-1.995l17.942-35.745a1.462 1.462 0 0 1 2.004-.655c.747.387 1.046 1.25.658 1.995L47.793 80.834c-.27.506-.777.834-1.346.834v-.03Z" />
        <path d="M75.634 68.264a1.51 1.51 0 0 1-1.346-.834L63.074 45.06c-.359-.745-.06-1.639.658-1.996.718-.358 1.645-.06 2.003.655L76.95 66.09c.358.745.06 1.639-.658 1.996-.21.12-.449.149-.658.149v.03ZM50.934 48.872c-3.29 0-5.98-2.68-5.98-5.957 0-3.277 2.69-5.957 5.98-5.957 3.29 0 5.98 2.68 5.98 5.957 0 3.277-2.69 5.957-5.98 5.957Zm0-8.936a2.993 2.993 0 0 0-2.99 2.979 2.993 2.993 0 0 0 2.99 2.979c1.645 0 2.99-1.34 2.99-2.979a2.993 2.993 0 0 0-2.99-2.979Z" />
        <path d="M85.564 101c-4.784 0-9.33-1.728-12.829-4.855-2.96-2.592-5.024-5.958-5.95-9.681a18.58 18.58 0 0 1-.629-4.826c0-1.817.27-3.693.808-5.54l.06-.268a19.571 19.571 0 0 1 7.775-10.307c3.17-2.114 6.907-3.246 10.795-3.246 1.166 0 2.153.089 3.05.268 9.3 1.4 16.388 9.591 16.388 19.093C105.032 92.302 96.3 101 85.594 101h-.03Zm-15.67-24.425v.148a15.89 15.89 0 0 0-.777 4.915c0 1.37.18 2.74.538 4.081.778 3.187 2.542 6.017 5.054 8.192 2.96 2.65 6.818 4.11 10.855 4.11 9.061 0 16.448-7.357 16.448-16.383 0-8.042-5.981-14.983-13.906-16.174-.777-.15-1.585-.209-2.542-.209-3.29 0-6.43.954-9.12 2.74-3.08 2.026-5.383 5.065-6.55 8.58Z" />
        <path d="M79.46 72.791c.36-.297.778-.595 1.197-.863a8.85 8.85 0 0 1 3.02-1.192 9.103 9.103 0 0 1 1.854-.179c.927 0 1.795.12 2.542.358.748.238 1.406.596 1.914 1.072a4.84 4.84 0 0 1 1.226 1.668c.3.656.419 1.37.419 2.175 0 .804-.12 1.4-.3 1.966a4.156 4.156 0 0 1-.806 1.43 7.135 7.135 0 0 1-1.047 1.072c-.389.298-.748.566-1.077.834a5.925 5.925 0 0 0-.897.744c-.239.239-.418.537-.478.835l-.419 2.085h-3.17l-.329-2.383c-.09-.477-.03-.924.15-1.281.18-.358.418-.685.747-.983a9.865 9.865 0 0 1 1.047-.834 7.584 7.584 0 0 0 1.077-.864c.329-.328.628-.625.867-1.013.24-.357.359-.804.359-1.34 0-.596-.21-1.073-.598-1.43-.39-.357-.927-.536-1.615-.536-.538 0-.957.06-1.316.149-.359.119-.658.238-.927.357-.24.12-.479.268-.658.358a1.06 1.06 0 0 1-.568.178c-.419 0-.748-.178-.957-.566l-1.226-1.876-.03.06Zm2.752 17.366c0-.357.06-.685.21-.983.149-.298.328-.566.568-.804.239-.238.508-.417.837-.536.329-.12.658-.209 1.047-.209.388 0 .717.06 1.046.209.33.12.598.328.838.536.239.238.418.506.568.804.15.298.21.626.21.983 0 .358-.06.686-.21 1.013-.12.298-.33.596-.568.804-.24.239-.509.417-.838.537-.329.119-.658.208-1.046.208-.39 0-.718-.06-1.047-.208a2.245 2.245 0 0 1-.837-.537 3.023 3.023 0 0 1-.569-.804c-.12-.328-.209-.655-.209-1.013Z" />
      </g>
    </SvgContainer>
  );
}
