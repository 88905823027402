import StateHelper from 'helpers/StateHelper';
import Category from 'factories/Category';

import { CATEGORIES_FETCHED } from 'actions/categories';

const stateHelper = new StateHelper(Category);
export const { initialState } = stateHelper;
const reducers = {};

reducers[CATEGORIES_FETCHED] = stateHelper.resetAndSet;


export default stateHelper.createReducer(reducers);
