import React, { useState } from "react";
import PropTypes from "prop-types";

import SVGIcon from 'components/SVGIcon';
import Button from "components/inputs/Button";
import Tooltip from "components/Tooltip";
import useWindowDimensions from "helpers/windowDimensions";

import italicize from 'helpers/italicize';

const PricingCard = ({
  id,
  pricingPlanFeatures,
  activeQuestionCount,
  months,
  discount,
  price,
  practiceExamAddon,
  cmeAddon,
  featuresForTooltip,
  tooltipDescription,
  mobileAddonsLogicObject,
  managemobileAddons
}) => {
  const [totalPrice, setTotalPrice] = useState(price);

  const { width } = useWindowDimensions();
  const {
    expandHidden,
    collapseHidden,
    addonsHidden
  } = mobileAddonsLogicObject;

  const handleKeyPress = (e) => {
    if (e.keyCode === 36 || e.keyCode === 38) {
      managemobileAddons(e);
    }
  };

  const handleAddonClick = (e) => {
    const targetNode = e.target;

    if (targetNode.id === `${months + "-practice-exam-addon"}`) {
      if (targetNode.checked === true) {
        setTotalPrice(totalPrice + (practiceExamAddon.addon_price - practiceExamAddon.coupon_amount));
      } else {
        setTotalPrice(totalPrice - (practiceExamAddon.addon_price - practiceExamAddon.coupon_amount));
      }
    } else if (targetNode.id === `${months + "-cme-certificate-addon"}`) {
      if (targetNode.checked === true) {
        setTotalPrice(totalPrice + (cmeAddon.addon_price - cmeAddon.coupon_amount));
      } else {
        setTotalPrice(totalPrice - (cmeAddon.addon_price - cmeAddon.coupon_amount));
      }
    }
  };

  const orderAddonDetails = (arr, search) => {
    let foundIndex;
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].includes(search)) {
        foundIndex = i;
        break;
      }
      if (i === arr.length - 1) foundIndex = -1;
    }
    if (foundIndex !== -1) {
      const detailWithSearchTerm = arr[foundIndex];
      arr.splice(foundIndex, 1);
      arr.unshift(detailWithSearchTerm);
    }
    return arr;
  };

  const tooltipClassNames = (element = "", addon = "") => {
    if (element === "parent" && months === 6 && width >= 992) return "right";
    if (element === "parent" && months === 1 && width >= 992) return "left";
    if (element === "parent" && width < 500 && addon === "") return "left";
    if (element === "parent" && width < 464 && addon === "practice_exam") return "right";
    if (element === "content" && width < 768) return "mobile-tooltip";
    if (element === "blurb" && width < 464) return "mobile-tooltip";
  };

  return (
    <>
      <div className="pricing-card">
        {months === 6 && (
          <div className="best-value">
            <p>Best Value</p>
          </div>
        )}
        <div className="header">
          <h2>
            {months + ` ${months === 1 ? "Month" : "Months"}`}
          </h2>
          <p className="questions-count">
            Access {activeQuestionCount}+ Questions
          </p>
          <p className={`monthly-savings ${discount === 0 && "hidden"}`}>
            {`${discount}%`} Monthly Savings
          </p>
        </div>
        <div className="details">
          <ul className="text-left">
            {pricingPlanFeatures.map((feature, i) => {
              if (months === 1) {
                if (featuresForTooltip(i)) {
                  return;
                }
              }

              return (
                <span key={i} className="detail-wrapper d-flex mb-3 align-items-start">
                  <SVGIcon name="CircleCheck" width={16} height={16} className="circle-check" />
                  <li>
                    <span>{feature}</span>
                    {featuresForTooltip(i) && <Tooltip hoverable className="ml-2" contentClass={tooltipClassNames("blurb")}>{tooltipDescription(feature)}</Tooltip>}
                  </li>
                </span>
              );
            })}
          </ul>
        </div>
        <form id={`product-${id}-form`} method="POST" action="/cart_items">
          {practiceExamAddon || cmeAddon ? (
            <>
              <div className="upgrade">
                <p>Upgrade Your Prep:</p>
              </div>
              <div
                id="expand-upgrade"
                role="button"
                tabIndex="0"
                className={`expand-upgrade ${expandHidden ? "d-none" : ""}`}
                onClick={e => managemobileAddons(e)}
                onKeyPress={e => handleKeyPress(e)}
              >
                <p>
                  Upgrade Your Prep
                  <SVGIcon
                    name="DownCaret4"
                    className="ml-3"
                    width={18}
                    height={18}
                  />
                </p>
              </div>
              <div
                id="collapse-upgrade"
                role="button"
                tabIndex="0"
                className={`collapse-upgrade ${collapseHidden ? "d-none" : ""}`}
                onClick={e => managemobileAddons(e)}
                onKeyPress={e => handleKeyPress(e)}
              >
                <p>
                  Upgrade Your Prep
                  <SVGIcon
                    name="UpCaret2"
                    className="ml-3"
                    width={18}
                    height={18}
                  />
                </p>
              </div>
              <div id="add-ons" className={`add-ons ${addonsHidden ? "d-none" : ""}`}>
                {practiceExamAddon && (
                  <div className="practice-exam d-flex-inline">
                    <div className="d-flex">
                      <input id={months + "-practice-exam-addon"} className="regular-checkbox mt-2" type="checkbox" onClick={e => handleAddonClick(e)} name="cart_item[add_practice_exam]" value={practiceExamAddon.id} />
                      <label id={months + "-practice-exam-addon-label"} htmlFor={months + "-practice-exam-addon"} className="ml-3">
                        Full-Length Practice Exam {practiceExamAddon.coupon ? (
                          <>
                            <span className="strike">${practiceExamAddon.addon_price} </span>
                            <span className="price-red ml-2">{`($${practiceExamAddon.addon_price - practiceExamAddon.coupon_amount})`}</span>
                          </>
                      ) : (
                        <span>${practiceExamAddon.addon_price}</span>
                      )}
                        <Tooltip hoverable className={`ml-2 ${tooltipClassNames("parent", "practice_exam")}`} contentClass={`ml-2 ${tooltipClassNames("content")}`}>
                          Mimics the exact blueprint, length, and exam interface you'll see on test day
                        </Tooltip>
                      </label>
                    </div>
                    <div>
                      <ul>
                        {orderAddonDetails(practiceExamAddon?.details, "AMA PRA").map((detail, i) => <li key={i}>{italicize(detail)}</li>)}
                      </ul>
                    </div>
                  </div>
                )}
                {cmeAddon && (
                  <div className="cme">
                    <input id={months + "-cme-certificate-addon"} className="regular-checkbox" type="checkbox" onClick={e => handleAddonClick(e)} name="cart_item[add_cme]" value={cmeAddon.id} />
                    <label id={months + "-cme-certificate-addon-label"} htmlFor={months + "-cme-certificate-addon"} className="ml-3">
                      CME Certificate {cmeAddon.coupon ? (
                        <>
                          <span className="strike">${cmeAddon.addon_price} </span>
                          <span className="price-red ml-2">{`($${cmeAddon.addon_price - cmeAddon.coupon_amount})`}</span>
                        </>
                    ) : (
                      <span>${cmeAddon.addon_price}</span>
                    )}
                    </label>
                    <Tooltip hoverable className={`ml-2 ${tooltipClassNames("parent")}`} contentClass={`ml-2 ${tooltipClassNames("content")}`}>
                      Redeem the CME you earn while you study
                    </Tooltip>
                    <div>
                      <ul>
                        {orderAddonDetails(cmeAddon?.details, "AMA PRA").map((detail, i) => <li key={i}>{italicize(detail)}</li>)}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : <></> }
          <div className="add-to-cart">
            <input type="hidden" name="cart_item[product_id]" value={id} />
            <h3>${totalPrice}</h3>
            <Button type="submit" form={`product-${id}-form`} className="storefront-button-text">
              Add to Cart
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

PricingCard.propTypes = {
  id: PropTypes.number.isRequired,
  pricingPlanFeatures: PropTypes.array.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  months: PropTypes.number.isRequired,
  discount: PropTypes.number,
  price: PropTypes.number.isRequired,
  practiceExamAddon: PropTypes.object,
  cmeAddon: PropTypes.object,
  featuresForTooltip: PropTypes.func.isRequired,
  tooltipDescription: PropTypes.func.isRequired,
  mobileAddonsLogicObject: PropTypes.object,
  managemobileAddons: PropTypes.func
};

export default PricingCard;
