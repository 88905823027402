import { Bugfender } from '@bugfender/sdk';
import axiosRoot from 'axios';
import Config from 'config';

const axios = axiosRoot.create({
  baseURL: Config.apiRoot,
  timeout: Config.apiTimeout
});

// axios interceptors

function logDebug(message) {
  if (Config.env !== 'development') {
    Bugfender.log(`Request: ${message}`);
  }
}

function logError(error) {
  const errorString = JSON.stringify(error);
  const includesItemEditor = errorString.toLowerCase().includes("item-editor");

  if (Config.env !== 'development' && !includesItemEditor) {
    Bugfender.error(`Request: ${errorString}`);
  }
}

if (axios.interceptors?.request) {
  axios.interceptors.request.use((request) => {
    logDebug(`Url: ${request.baseURL}${request.url} >>> Method: ${request.method} >>> Timeout: ${request.timeout}`);

    return request;
  }, (error) => {
    logError(error);

    return Promise.reject(error);
  });
}

if (axios.interceptors?.response) {
  axios.interceptors.response.use(response => response, (error) => {
    if (error.config && error.config.timeout === 4000 && !error.response) {
      error.config.timeout = 8000;
      return axios(error.config);
    }

    logError(error);

    return Promise.reject(error);
  });
}

// axios send request

let token = false;

async function sendRequest(request, expectedStatuses = [200]) {
  try {
    request.headers = {
      Accept: 'application/json'
    };
    if (Config.useSessionToken && token && !request.url.endsWith('sign_in') && !request.url.endsWith('register')) {
      // If there's an orphaned token and we use it in the sign_in endpoint, it will sign us in with the token and not the credentials used
      request.headers.Authorization = `Bearer ${token}`;
    }
    if (Config.env === 'development' && Config.apiConsoleLogging) console.log('request: ', request);
    const response = await axios(request);
    if (expectedStatuses.indexOf(response.status) > -1) {
      // Additional response handling can be done here
      if (Config.env === 'development' && Config.apiConsoleLogging) console.log('response: ', response);
      if (Config.useSessionToken && response.headers.authorization) {
        token = response.headers.authorization.slice(7, response.headers.authorization.length);
      }
      return response.data;
    }
    const error = new Error();
    error.response = response;
    throw error;
  } catch (error) {
    // Additional error handling can be done here
    if (Config.onAPIUnauthorized && typeof Config.onAPIUnauthorized === 'function' && error && error.response && error.response.status === 401) {
      Config.onAPIUnauthorized(error.response);
    }
    error.isNetworkError = true;
    error.messages = [];
    error.codes = [];
    error.code = null;

    if (error.response) {
      if (Config.env === 'development' && Config.apiConsoleLogging) console.warn('response: ', error.response);

      error.isNetworkError = false;
      if (error.response.data && error.response.data.errors) {
        error.messages = error.response.data.errors.map(error => error.details);
        error.message = error.messages.join(', ');
        error.codes = error.response.data.errors.map(error => error.code);
        [error.code] = error.codes;
      } else {
        error.message = error.response.statusText;
      }
    } else if (Config.env === 'development' && Config.apiConsoleLogging) {
      console.warn('network error');
    }

    throw error;
  }
}

export default {
  setToken: function(newToken) {
    token = newToken;
  },
  resetToken: function() {
    token = false;
  },
  getToken: function() {
    return token;
  },
  get: (url, options = {}) => sendRequest({
    ...options,
    url,
    method: 'GET'
  }),
  post: (url, data = {}, options = {}) => sendRequest({
    ...options,
    url,
    method: 'POST',
    data
  }),
  put: (url, data = {}, options = {}) => sendRequest({
    ...options,
    url,
    method: 'PUT',
    data
  }),
  patch: (url, data = {}, options = {}) => sendRequest({
    ...options,
    url,
    method: 'PATCH',
    data
  }),
  delete: (url, options = {}) => sendRequest({
    ...options,
    url,
    method: 'DELETE'
  }, [200, 204])
};

export function currentUserUrl(state) {
  const { session } = state;
  const userId = session.get('current_user_id');
  return `users/${userId}`;
}

export function selectedQuestionBankUrl(state, userIdArg) {
  const { session } = state;
  const userId = userIdArg || session.get('current_user_id');
  const questionBankId = session.get('selected_question_bank_id');
  return `users/${userId}/question_banks/${questionBankId}`;
}

export function selectedOrganizationUrl(state) {
  const { session } = state;
  const userId = session.get('current_user_id');
  const organizationId = session.get('selected_organization_id');
  return `users/${userId}/organizations/${organizationId}`;
}
