import {
  List
} from 'immutable';

import Factory from 'factories/_Factory';


export default class ProductPage extends Factory {
  static jsonType = 'product_page';

  static afterUpdate(productPage, json) {
    return productPage.set('cme_details', List(json.attributes.cme_details));
  }

  static get defaults() {
    return {
      name: '',
      path: '',
      blurb: '',
      heading: '',
      board_info: '',
      cme_reason_to_buy_blurb: '',
      price_1_month: -1,
      price_3_month: -1,
      price_6_month: -1,
      price_12_month: -1,
      cme_addon_price_1_month: -1,
      cme_addon_price_3_month: -1,
      cme_addon_price_6_month: -1,
      cme_addon_price_12_month: -1,
      has_free_trial: false,
    };
  }

  static get hasMany() {
    return ['product_index'];
  }
}
