import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from 'components/LoadingIcon';
import LegacySelect from 'components/LegacyQuestion/LegacySelect';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';


// StyleGuide = organisms/forms/

class CmeCoach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpecialtyId: 0,
      selectedStateId: 0,
      inputEmail: ''
    };
  }

  componentDidUpdate(prevProps) {
    // Scroll back to top when search changes
    const { cmeCoach } = this.props;
    const prevCmeCoach = prevProps.cmeCoach;

    if ((prevCmeCoach.get('searchedSpecialtyId') !== cmeCoach.get('searchedSpecialtyId'))
      || (prevCmeCoach.get('searchedStateId') !== cmeCoach.get('searchedStateId'))) {
      window.requestAnimationFrame(() => {
        if (this.el) this.el.scrollTop = 0;
      });
    }
  }

  onSearch() {
    const { onSearch } = this.props;
    const { selectedSpecialtyId, selectedStateId } = this.state;
    if (selectedStateId || selectedSpecialtyId) {
      onSearch(selectedSpecialtyId, selectedStateId);
    }
  }

  onSubmitEmailForm(ev) {
    ev.preventDefault();
    const { inputEmail } = this.state;
    const { onSubmitEmail } = this.props;
    onSubmitEmail(inputEmail)
      .then(() => this.setState({ inputEmail: '' }))
      .catch(() => {});
  }

  renderProduct(product, index) {
    return (
      <li
        className="CmeProduct"
        key={index}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/${product.get('path')}`}
        >
          <h2>{product.get('name')}</h2>
          <ul className="cme-product-details">
            {product.get('cme_details').map((item, index2) => (
              <li key={index2}>{item}</li>
            ))}
          </ul>

          {product.get('cme_reason_to_buy_blurb') && (
            <p className="cme-product-reason">{product.get('cme_reason_to_buy_blurb')}</p>
          )}

          <span className="link-blue">Learn more</span>
        </a>
      </li>
    );
  }

  renderRequirements(mocYear, requirements, organization, index) {
    return (
      <div key={index} className={`license-req year-${mocYear}`}>
        { mocYear !== '' && (
          <h3 className="underline">
            By the end of year {mocYear}
          </h3>
        )}
        <div className={`req-${mocYear}`}>
          { requirements.map((requirement, index2) => (
            <div key={index2}>
              <i className="fa fa-check" />
              {requirement.get('description')}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderLicense(license, organization, index) {
    const noYearRequirements = license.get('requirements_by_moc_year').get('');
    const yearRequirements = license.get('requirements_by_moc_year').delete('');
    const hasRequirements = license.get('requirements_by_moc_year').size > 0;

    return (
      <div
        key={index}
        className="CmeCoachLicense"
      >
        <h2>
          {organization.get('name')}
          <a
            href={organization.get('website')}
            rel="noopener noreferrer"
            target="_blank"
            className="cme-organization-website"
          >
            &#8689;
          </a>
        </h2>

        <div className="license-glance">
          <h3>At a Glance</h3>

          { license.get('renewal_timeframe') && (
            <p>{license.get('cme_hours')} total hours every {license.get('renewal_timeframe')} years</p>
          )}

          { !hasRequirements && (
            <p>No requirements for {organization.get('name')}</p>
          )}

          { noYearRequirements && this.renderRequirements('', noYearRequirements, organization, -1) }

          { yearRequirements.entrySeq().map((e, index2) => this.renderRequirements(e[0], e[1], organization, index2)) }
        </div>
        <div className="license-prop-description">
          {license.get('description')}
        </div>
        <a href={license.get('source')} rel="noopener noreferrer" target="_blank">Source</a>
      </div>
    );
  }

  render() {
    const {
      cmeCoach,
      specialties,
      usStates,
      productPages
    } = this.props;
    const {
      selectedSpecialtyId,
      selectedStateId,
      inputEmail
    } = this.state;


    const organizations = cmeCoach.get('organizations');
    const isSearching = cmeCoach.get('isSearching');


    const filteredSpecialties = specialties.filter(specialty => specialty.get('cme_organization_id'));
    const specialtyOptions = filteredSpecialties.toList().map(specialty => ({
      value: specialty.get('id'),
      name: specialty.get('name')
    })).toArray();

    const stateOptions = usStates.toList().map(state => ({
      value: state.get('id'),
      name: state.get('name')
    })).toArray();

    const searchedSpecialty = specialties.get(cmeCoach.get('searchedSpecialtyId'));
    const searchedState = usStates.get(cmeCoach.get('searchedStateId'));


    const hasSearched = searchedSpecialty || searchedState;

    const titles = [];
    if (searchedSpecialty) titles.push(searchedSpecialty.get('name'));
    if (searchedState) titles.push(searchedState.get('name'));
    const title = `${titles.join(' & ')} Requirements`;

    const disclaimer = 'Each state and medical specialty board has their own '
      + 'set of CME requirements to maintain your license and board certification.';

    return (
      <div className="CmeCoach">
        <h1 className="cme-coach-heading">CME Coach&trade; <span>by BoardVitals</span></h1>
        <h2 className="cme-coach-subheading">Score a free report outlining your specific Specialty and State Requirements</h2>
        <div className="cme-coach-wrapper">
          <div className="cme-coach-sections">
            { hasSearched && (
              <div className="cme-coach-section cme-coach-section-left">
                <div className="CmeCoachProducts">
                  <h1>Recommended CME</h1>
                  <ul>
                    { productPages.toList().map((product, index) => this.renderProduct(product, index)) }
                  </ul>
                </div>
              </div>
            )}
            <div className="cme-coach-section cme-coach-section-right">
              <div className="CmeCoachSearch d-md-flex">
                <div className="form-group">
                  <LegacySelect
                    name="specialty"
                    label="Specialty"
                    placeholder="Select a Specialty"
                    onChange={id => this.setState({ selectedSpecialtyId: parseInt(id) })}
                    options={specialtyOptions}
                    value={selectedSpecialtyId}
                    disabled={isSearching}
                  />
                </div>
                <div className="form-group">
                  <LegacySelect
                    name="state"
                    label="State"
                    placeholder="Select a State"
                    onChange={id => this.setState({ selectedStateId: id })}
                    options={stateOptions}
                    value={selectedStateId}
                    disabled={isSearching}
                  />
                </div>
                <div className="form-group">
                  <Button
                    type="submit"
                    disabled={isSearching}
                    className="find-cme-ajax"
                    onClick={() => this.onSearch()}
                  >
                    Go
                  </Button>
                </div>
              </div>

              { isSearching && (
                <LoadingIcon className="cme-coach-loading" />
              )}

              { !isSearching && hasSearched && (
                <div
                  className="CmeCoachRequirements"
                  ref={(ref) => { this.el = ref; }}
                >
                  <h1>{ title }</h1>

                  <div className="list">
                    { organizations.map((organization, index) => (
                      organization.get('licenses').map((license, index2) => (
                        this.renderLicense(license, organization, index * index2)
                      ))
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          { hasSearched && (
            <div className="disclaimer">{disclaimer}</div>
          )}
        </div>
        { hasSearched && (
          <form onSubmit={ev => this.onSubmitEmailForm(ev)}>
            <h3 className="cme-coach-email-label">Get a copy of your CME requirements for easy reference</h3>
            <div className="cme-coach-email-form-wrapper d-md-flex">
              <div className="form-group">
                <Input
                  name="cme-coach-email"
                  type="email"
                  placeholder="Email"
                  required
                  value={inputEmail}
                  onChange={value => this.setState({ inputEmail: value })}
                  disabled={cmeCoach.get('isSendingEmail')}
                />
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  disabled={cmeCoach.get('isSendingEmail')}
                >
                  Send
                </Button>
              </div>
              { cmeCoach.get('isSendingEmail') && (
                <div className="form-group">
                  <LoadingIcon hideLabel />
                </div>
              )}

              {(!cmeCoach.get('isSendingEmail') && cmeCoach.get('sendEmailStatus') !== null) && (
                <div className="form-group">
                  {cmeCoach.get('sendEmailStatus') ? 'Email sent!' : 'There was an error sending your email. Please try again later or with a different email address.'}
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    );
  }
}

CmeCoach.propTypes = {
  cmeCoach: PropTypes.object.isRequired,
  specialties: PropTypes.object.isRequired,
  usStates: PropTypes.object.isRequired,
  productPages: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSubmitEmail: PropTypes.func.isRequired,
};

export default CmeCoach;
