import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgPointGraph(props) {
  return (
    <SvgContainer title="Point Graph Icon" {...props}>
      <path d="M26 7.5a2.492 2.492 0 00-1.684 4.335l-3.49 5.816a2.426 2.426 0 00-2.203.265l-4.54-4.54a2.502 2.502 0 10-3.768.46l-3.49 5.815A2.47 2.47 0 006 19.5a2.522 2.522 0 101.684.665l3.49-5.816a2.426 2.426 0 002.203-.265l4.54 4.54a2.502 2.502 0 103.768-.46l3.49-5.815A2.47 2.47 0 0026 12.5a2.5 2.5 0 000-5zm-20 16A1.5 1.5 0 117.5 22 1.502 1.502 0 016 23.5zM10.5 12a1.5 1.5 0 111.5 1.5 1.502 1.502 0 01-1.5-1.5zm9.5 9.5a1.5 1.5 0 111.5-1.5 1.502 1.502 0 01-1.5 1.5zm6-10a1.5 1.5 0 111.5-1.5 1.502 1.502 0 01-1.5 1.5z" />
    </SvgContainer>
  );
}
