import React from "react";
import PropTypes from 'prop-types';

const ProductDetails = ({ children, section }) => (
  <div className={`${section} home-section-wrapper`}>
    {children}
  </div>
);

ProductDetails.propTypes = {
  section: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.object]).isRequired,
};

export default ProductDetails;
