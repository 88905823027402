import Factory from 'factories/_Factory';


export default class Notification extends Factory {
  static jsonType = 'notification';

  static afterUpdate(record, json) {
    let newRecord = record;
    if (json.attributes.assignment_end_time || newRecord.get('assignment_end_time') === undefined) {
      newRecord = newRecord.set('assignment_end_time', new Date(json.attributes.assignment_end_time).getTime());
    }
    if (json.attributes.seen_at || newRecord.get('seen_at') === undefined) {
      newRecord = newRecord.set('seen_at', new Date(json.attributes.seen_at).getTime());
    }
    return newRecord;
  }

  static get defaults() {
    return {
      title: '',
      message_type: '',
      body: '',
      mobile_body: '',
      web_body: '',
      external_url: '',
      assignment_id: 0,
      question_bank_id: 0
    };
  }

  static get belongsTo() {
    return ['user'];
  }
}
