import React from "react";
import PropTypes from "prop-types";

import scaleDisplayFontSize from "../utils/scaleDisplayFontSize";

const Display = ({ value = "0", trigUnit, mode }) => {
  // scales fontSize based on length of display value
  const fontSize = scaleDisplayFontSize(value, mode);
  return (
    <section className="calculator-display">
      {mode === "scientific" && <div className="display-unit">{trigUnit}</div>}
      <div id="display" className="display-value" style={{ fontSize }}>
        {value}
      </div>
    </section>
  );
};

Display.propTypes = {
  value: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['basic', 'scientific', 'fred']),
  trigUnit: PropTypes.oneOf(['deg', 'rad'])
};

export default Display;
