import api from 'helpers/api';


export const PRACTICE_EXAM_TEMPLATE_LOADING = 'PRACTICE_EXAM_TEMPLATE_LOADING';
export const PRACTICE_EXAM_TEMPLATE_LOADING_ERROR = 'PRACTICE_EXAM_TEMPLATE_LOADING_ERROR';
export const PRACTICE_EXAM_TEMPLATE_FETCHED = 'PRACTICE_EXAM_TEMPLATE_FETCHED';

function practiceExamLoading(key, promise, dispatch) {
  dispatch({
    type: PRACTICE_EXAM_TEMPLATE_LOADING,
    payload: { key, promise }
  });
}

function practiceExamError(error, dispatch, key) {
  dispatch({
    type: PRACTICE_EXAM_TEMPLATE_LOADING_ERROR,
    payload: { error, key }
  });
  return error;
}

const createPracticeExamAction = (actionType, keyMethod, restAction = 'post', urlGenerator) => (userId, questionBankId, practiceExamTemplateId, blockId, questionId) => (dispatch, getState) => {
  const key = keyMethod(practiceExamTemplateId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);
  const url = urlGenerator
    ? urlGenerator(userId, questionBankId, practiceExamTemplateId, blockId, questionId)
    : `users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}`;

  const promise = api[restAction](url)
    .then((response) => {
      dispatch({
        type: actionType,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};

const getPracticeExamTemplateKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/fetch`;
export const practiceExamTemplateFetch = createPracticeExamAction(PRACTICE_EXAM_TEMPLATE_FETCHED, getPracticeExamTemplateKey, 'get');
practiceExamTemplateFetch.getKey = practiceExamTemplateFetch;

const getDeleteTemplateBlockKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/delete-template-block`;
const getDeleteTemplateURL = (userId, questionBankId, practiceExamTemplateId, blockId) => `users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/remove_block/${blockId}`;
export const deleteTemplateBlock = createPracticeExamAction(PRACTICE_EXAM_TEMPLATE_FETCHED, getDeleteTemplateBlockKey, 'patch', getDeleteTemplateURL);
deleteTemplateBlock.getKey = getDeleteTemplateBlockKey;

const getAddQuestionKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/add-question`;
const getAddQuestionURL = (userId, questionBankId, practiceExamTemplateId, blockId, questionId) => `users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/add_question/${blockId}/${questionId}`;
export const addQuestion = createPracticeExamAction(PRACTICE_EXAM_TEMPLATE_FETCHED, getAddQuestionKey, 'patch', getAddQuestionURL);
addQuestion.getKey = getAddQuestionKey;

const getRemoveQuestionKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/remove-question`;
const getRemoveQuestionURL = (userId, questionBankId, practiceExamTemplateId, blockId, questionId) => `users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/remove_question/${blockId}/${questionId}`;
export const removeQuestion = createPracticeExamAction(PRACTICE_EXAM_TEMPLATE_FETCHED, getRemoveQuestionKey, 'patch', getRemoveQuestionURL);
removeQuestion.getKey = getRemoveQuestionKey;

const getMoveTemplateBlockKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/move-template-block`;
export const moveTemplateBlock = (userId, questionBankId, practiceExamTemplateId, blockId, newPosition) => (dispatch, getState) => {
  const key = getMoveTemplateBlockKey(practiceExamTemplateId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/move_block/${blockId}/${newPosition}`)
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
moveTemplateBlock.getKey = getMoveTemplateBlockKey;

const getUpdateTemplateBlockKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/update-template-block`;
export const updateTemplateBlock = (userId, questionBankId, practiceExamTemplateId, blockId, blockParams) => (dispatch, getState) => {
  const key = getUpdateTemplateBlockKey(practiceExamTemplateId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/template_blocks/${blockId}/`, { template_block: blockParams })
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
updateTemplateBlock.getKey = getUpdateTemplateBlockKey;

const getUpdateTemplateKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/update-template`;
export const updateTemplate = (userId, questionBankId, practiceExamTemplateId, practiceExamTemplateParams) => (dispatch, getState) => {
  const key = getUpdateTemplateKey(practiceExamTemplateId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}`, { practice_exam_template: practiceExamTemplateParams })
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
updateTemplate.getKey = getUpdateTemplateKey;

const getCreateTemplateKey = () => 'practice-exams/create';
export const createTemplate = (userId, questionBankId, practiceExamTemplateParams) => (dispatch, getState) => {
  const key = getCreateTemplateKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`users/${userId}/question_banks/${questionBankId}/practice_exam_templates/`, { practice_exam_template: practiceExamTemplateParams })
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
createTemplate.getKey = getCreateTemplateKey;

const getFetchPracticeExamTemplatesKey = questionId => `practice-exams/by-question/${questionId}`;
export const fetchPracticeExamsForQuestion = (userId, questionId) => (dispatch, getState) => {
  const key = getFetchPracticeExamTemplatesKey(questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/practice_exam_templates/${questionId}`)
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
fetchPracticeExamsForQuestion.getKey = getFetchPracticeExamTemplatesKey;

const getAddTemplateBlockKey = practiceExamTemplateId => `practice-exams/${practiceExamTemplateId}/add-block`;
export const addTemplateBlock = (userId, questionBankId, practiceExamTemplateId, templateBlockParams) => (dispatch, getState) => {
  const key = getAddTemplateBlockKey(practiceExamTemplateId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`users/${userId}/question_banks/${questionBankId}/practice_exam_templates/${practiceExamTemplateId}/add_block`, { template_block: templateBlockParams })
    .then((response) => {
      dispatch({
        type: PRACTICE_EXAM_TEMPLATE_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => practiceExamError(error, dispatch, key));

  practiceExamLoading(key, promise, dispatch);
  return promise;
};
addTemplateBlock.getKey = getAddTemplateBlockKey;
