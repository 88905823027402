import React from "react";
import SvgContainer from "./SvgContainer";

export default function Note (props) {
  return (
    <SvgContainer height={99} width={99} viewBoxHeight={99} viewBoxWidth={99} name="note" {...props}>
      <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="49.133" cy="49.133" r="49.133" fill="#FFF2DB" />
        <path d="M80.6831 46.6289L73.6744 39.6161C73.2881 39.2295 72.664 39.2295 72.2777 39.6161L56.8636 55.0392C56.7447 55.1582 56.6605 55.3069 56.611 55.4655L53.8125 65.2784C53.7085 65.6303 53.8026 66.0119 54.0651 66.2696C54.2484 66.4579 54.501 66.562 54.7635 66.562C54.8576 66.562 54.9468 66.5471 55.0359 66.5224L64.8431 63.7222C65.0065 63.6776 65.1502 63.5884 65.269 63.4694L80.6831 48.0463C81.0694 47.6597 81.0694 47.0353 80.6831 46.6487V46.6289V46.6289ZM56.2098 64.1038L58.0623 57.6263L62.6835 62.2503L56.2098 64.1038ZM64.5756 61.3433L58.9637 55.738L72.9761 41.7224L78.578 47.3277L64.5756 61.3433Z" fill="#1B3950" />
        <path d="M57.4928 43.3131L47.4925 53.3193L41.0535 46.8765C40.6473 46.5098 40.0232 46.5395 39.6567 46.9459C39.3149 47.3225 39.3149 47.9024 39.6567 48.2791L46.7991 55.4207C47.1854 55.8073 47.8095 55.8073 48.1959 55.4207L58.8946 44.7157C59.2611 44.3093 59.2314 43.6848 58.8302 43.3131C58.4537 42.9711 57.8792 42.9711 57.4978 43.3131H57.4928V43.3131Z" fill="#1B3950" />
        <path d="M66.1161 65.5906C65.5712 65.5906 65.1254 66.0366 65.1254 66.5818V69.5554H33.4256V27.9248H55.7987L65.1254 37.257V41.8017C65.1254 42.3468 65.5712 42.7929 66.1161 42.7929C66.6609 42.7929 67.1067 42.3468 67.1067 41.8017V36.8456C67.1067 36.583 67.0027 36.3302 66.8144 36.1468L56.9082 26.2348C56.725 26.0465 56.4724 25.9424 56.2098 25.9424H32.435C31.8901 25.9424 31.4443 26.3884 31.4443 26.9336V70.5466C31.4443 71.0918 31.8901 71.5378 32.435 71.5378H66.1161C66.6609 71.5378 67.1067 71.0918 67.1067 70.5466V66.5818C67.1067 66.0366 66.6609 65.5906 66.1161 65.5906Z" fill="#1B3950" />
      </svg>
    </SvgContainer>
  );
}
