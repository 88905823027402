import React from 'react';
import PropTypes from 'prop-types';


// StyleGuide = elements/global/bv-logo

function BVLogo({
  height, width, small, light
}) {
  const defaultHeight = 36;
  const defaultWidth = small ? 32 : 122;

  const finalHeight = height || defaultHeight;
  const finalWidth = width || finalHeight / defaultHeight * defaultWidth;

  if (light) {
    return (
      <svg aria-labelledby="bv-logo-alt-desc-id" role="img" width="157" height="45" viewBox="0 0 157 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id="bv-logo-alt-desc-id">Board Vitals - Medical Board Review and Question Bank</title>
        <g clipPath="url(#clip0_975_2766)">
          <path d="M0 11.6328H2.7015C3.78869 11.6328 4.6222 11.7618 5.20862 12.0197C5.79175 12.2776 6.25299 12.6777 6.59232 13.2101C6.93166 13.7458 7.09968 14.3443 7.09968 14.9957C7.09968 15.6041 6.95142 16.1629 6.65492 16.6655C6.35841 17.1681 5.92353 17.5715 5.34699 17.8857C6.05861 18.1304 6.6055 18.4147 6.99096 18.7421C7.37641 19.0694 7.67292 19.4629 7.88706 19.9292C8.10121 20.3921 8.20663 20.898 8.20663 21.4403C8.20663 22.5447 7.8047 23.4772 7.00084 24.2377C6.19698 25.0015 5.11638 25.3818 3.76233 25.3818H0V11.6328ZM1.34087 12.9786V17.3831H2.12496C3.07708 17.3831 3.77551 17.2938 4.22357 17.1185C4.67162 16.94 5.02743 16.6589 5.28769 16.2753C5.54796 15.8918 5.67974 15.4652 5.67974 14.999C5.67974 14.3674 5.45901 13.8747 5.02084 13.5176C4.58267 13.1605 3.88423 12.9819 2.92553 12.9819H1.34087V12.9786ZM1.34087 18.7652V24.036H3.04084C4.04237 24.036 4.77704 23.9368 5.24157 23.7417C5.70939 23.5466 6.08167 23.2391 6.365 22.8192C6.64833 22.4025 6.78999 21.9495 6.78999 21.4634C6.78999 20.8517 6.59232 20.3193 6.19368 19.8663C5.79505 19.41 5.24816 19.0992 4.55302 18.9306C4.0852 18.8181 3.27475 18.7652 2.11837 18.7652H1.34416H1.34087Z" fill="#20407E" />
          <path d="M17.4373 11.2855C19.5128 11.2855 21.2491 11.9799 22.6492 13.3687C24.0461 14.7575 24.7445 16.4703 24.7445 18.5006C24.7445 20.5309 24.0461 22.2206 22.6525 23.6226C21.2556 25.0246 19.5557 25.7256 17.5493 25.7256C15.543 25.7256 13.8035 25.0279 12.4033 23.6325C11.0064 22.2371 10.3047 20.5474 10.3047 18.5634C10.3047 17.2441 10.6243 16.0173 11.2601 14.8898C11.8959 13.7622 12.7657 12.8793 13.8661 12.2412C14.9664 11.603 16.1557 11.2822 17.434 11.2822L17.4373 11.2855ZM17.4966 12.6214C16.4819 12.6214 15.5232 12.8859 14.6139 13.4183C13.7079 13.9474 12.9963 14.6616 12.4889 15.561C11.9783 16.4604 11.7246 17.4623 11.7246 18.5667C11.7246 20.2002 12.2913 21.5824 13.418 22.7067C14.548 23.8309 15.9087 24.3963 17.4999 24.3963C18.564 24.3963 19.5491 24.1384 20.4518 23.6193C21.3578 23.1034 22.0628 22.3958 22.5702 21.4964C23.0775 20.6003 23.3312 19.6017 23.3312 18.5039C23.3312 17.4061 23.0775 16.424 22.5702 15.5412C22.0628 14.6583 21.3512 13.954 20.4287 13.4249C19.5096 12.8926 18.5311 12.628 17.4999 12.628L17.4966 12.6214Z" fill="#20407E" />
          <path d="M33.091 11.6328L39.4791 25.3851H37.9998L35.8452 20.8616H29.9447L27.8099 25.3851H26.2812L32.755 11.6328H33.091ZM32.9164 14.5559L30.5674 19.5324H35.2522L32.9164 14.5559Z" fill="#20407E" />
          <path d="M41.6797 11.6328H44.4108C45.9329 11.6328 46.9608 11.6956 47.5044 11.818C48.3181 12.0065 48.9803 12.4066 49.4877 13.0216C49.995 13.6366 50.252 14.3939 50.252 15.29C50.252 16.0373 50.0774 16.6986 49.7282 17.264C49.3757 17.8328 48.8749 18.2593 48.2226 18.5503C47.5703 18.8413 46.6709 18.9901 45.5211 18.9934L50.4464 25.3785H48.753L43.8244 18.9934H43.0502V25.3785H41.6797V11.6328ZM43.0502 12.9786V17.6509L45.4124 17.6707C46.325 17.6707 47.0036 17.5848 47.4418 17.4095C47.88 17.2343 48.2226 16.9565 48.4697 16.5729C48.7135 16.1894 48.8387 15.7595 48.8387 15.2866C48.8387 14.8138 48.7135 14.4071 48.4664 14.0301C48.2193 13.6532 47.8931 13.3853 47.4879 13.2233C47.0827 13.0613 46.4106 12.9786 45.4717 12.9786H43.0568H43.0502Z" fill="#20407E" />
          <path d="M53.183 25.3851V11.6328H56.0229C58.072 11.6328 59.5579 11.7981 60.4836 12.1288C61.8113 12.5983 62.8524 13.4085 63.6002 14.5592C64.3481 15.7132 64.7204 17.0855 64.7204 18.6826C64.7204 20.0614 64.4239 21.2717 63.8308 22.3199C63.2378 23.3681 62.4669 24.1385 61.5214 24.6378C60.5726 25.1371 59.2482 25.3851 57.5383 25.3851H53.1797H53.183ZM54.4975 24.0856H56.0822C57.9765 24.0856 59.2877 23.9666 60.0224 23.7318C61.0503 23.3945 61.864 22.7894 62.4537 21.9164C63.0435 21.0402 63.34 19.9721 63.34 18.7057C63.34 17.3797 63.0204 16.2456 62.3813 15.3065C61.7421 14.3674 60.8493 13.7226 59.7094 13.3721C58.8528 13.1109 57.4395 12.9786 55.4694 12.9786H54.5008V24.0856H54.4975Z" fill="#20407E" />
          <path d="M92.7377 11.6328H94.2894L99.0104 22.257L103.811 11.6328H105.359L99.1587 25.3851H98.849L92.7344 11.6328H92.7377Z" fill="#0982C1" />
          <path d="M107.547 11.6328H108.97V25.3851H107.547V11.6328Z" fill="#0982C1" />
          <path d="M110.82 12.9786V11.6328H118.628V12.9786H115.452V25.3851H114V12.9786H110.82Z" fill="#0982C1" />
          <path d="M126.51 11.6328L133.155 25.3851H131.616L129.376 20.8616H123.238L121.018 25.3851H119.43L126.164 11.6328H126.513H126.51ZM126.328 14.5559L123.884 19.5324H128.756L126.325 14.5559H126.328Z" fill="#0982C1" />
          <path d="M135.367 11.6328H136.794V24.0591H142.276V25.3851H135.367V11.6328Z" fill="#0982C1" />
          <path d="M143.058 22.8224L144.27 22.1214C145.124 23.6358 146.105 24.393 147.222 24.393C147.703 24.393 148.151 24.2839 148.57 24.0723C148.991 23.8574 149.311 23.5697 149.528 23.206C149.746 22.8455 149.858 22.462 149.858 22.0552C149.858 21.5923 149.696 21.1426 149.374 20.6995C148.926 20.0878 148.115 19.3537 146.932 18.494C145.743 17.6277 145.005 16.9994 144.712 16.6125C144.208 15.9644 143.957 15.2634 143.957 14.5095C143.957 13.911 144.106 13.3654 144.402 12.8727C144.699 12.38 145.117 11.9932 145.657 11.7088C146.198 11.4244 146.781 11.2822 147.417 11.2822C148.089 11.2822 148.715 11.4409 149.298 11.765C149.884 12.0857 150.504 12.6776 151.156 13.5374L149.993 14.3872C149.456 13.7027 149.001 13.2497 148.622 13.0314C148.244 12.8132 147.832 12.7041 147.387 12.7041C146.814 12.7041 146.343 12.8727 145.977 13.2067C145.611 13.544 145.43 13.9573 145.43 14.4533C145.43 14.7509 145.493 15.0419 145.624 15.3229C145.753 15.604 145.987 15.9082 146.329 16.2389C146.517 16.4141 147.133 16.8737 148.171 17.6144C149.403 18.494 150.25 19.2744 150.708 19.9621C151.166 20.6466 151.396 21.3377 151.396 22.0288C151.396 23.0241 151.004 23.8937 150.217 24.6278C149.433 25.3652 148.477 25.7322 147.354 25.7322C146.491 25.7322 145.703 25.5074 145.002 25.0643C144.297 24.6179 143.648 23.8739 143.055 22.8257L143.058 22.8224Z" fill="#0982C1" />
          <path d="M85.8516 29.793V32.2829C104.232 32.6036 125.804 34.9414 150.081 40.9231C150.081 40.9231 121.706 30.8809 85.8516 29.793Z" fill="#20407E" />
          <path d="M68.6829 29.5713V32.0612C50.3029 32.3819 28.7304 34.7197 4.45312 40.7014C4.45312 40.7014 32.8288 30.6625 68.6829 29.5713Z" fill="#20407E" />
          <path d="M153.462 13.8218V11.8014H152.711V11.5303H154.52V11.8014H153.765V13.8218H153.462Z" fill="#010101" />
          <path d="M154.82 13.8218V11.5303H155.275L155.815 13.1538C155.865 13.3026 155.901 13.4184 155.924 13.4911C155.95 13.4084 155.99 13.2861 156.046 13.1241L156.593 11.5303H156.998V13.8218H156.708V11.9039L156.046 13.8218H155.772L155.114 11.8709V13.8218H154.824H154.82Z" fill="#010101" />
          <path d="M93.1968 5.24741C93.1704 4.99941 92.6927 4.77787 92.4159 4.67206C92.4423 4.52987 92.3599 4.38438 92.1985 4.32155L91.7208 4.13308C91.5264 4.06033 91.2991 4.13308 91.2102 4.29841C91.1245 4.46374 91.2102 4.65883 91.4012 4.73488L91.8789 4.92005C92.0041 4.96965 92.1425 4.95643 92.2512 4.8936C92.5378 5.00272 92.7849 5.19781 92.7849 5.19781C92.9595 5.80954 91.3881 8.2796 90.7555 9.23521L90.4755 9.11948C90.4755 9.11948 88.479 12.9089 85.8961 12.3633C85.8961 12.3633 82.9179 11.6358 85.4052 7.1355L85.1878 7.02969C85.626 6.03108 86.8581 3.3461 87.5236 3.06503C87.5236 3.06503 87.8399 3.10141 88.1133 3.21052C88.1232 3.32626 88.2056 3.43207 88.3373 3.48167L88.815 3.67015C89.0094 3.7462 89.2367 3.67015 89.3224 3.50812C89.4114 3.34279 89.3257 3.1477 89.1313 3.07165L88.6503 2.88317C88.4955 2.82365 88.3209 2.85671 88.2089 2.95922C87.9288 2.84349 87.4412 2.68146 87.2073 2.82365C87.2073 2.82365 85.9917 3.98097 84.855 6.90404L84.6145 6.785C84.6145 6.785 81.8406 10.6835 84.7562 12.6741C81.208 14.0827 64.9825 21.3342 72.7839 27.9375C78.7931 33.0265 80.2526 38.0691 80.6348 40.7342C79.9627 41.1277 79.5048 41.8486 79.5048 42.6884C79.5048 43.9417 80.5162 44.9568 81.7648 44.9568C83.0134 44.9568 84.0248 43.9417 84.0248 42.6884C84.0248 41.4352 83.0134 40.4201 81.7648 40.4201C81.7516 40.4201 81.7384 40.4234 81.7253 40.4234C81.4024 37.5797 79.933 32.5404 73.512 27.0613C73.512 27.0613 65.737 21.5524 85.7314 13.1899C86.4759 13.2759 89.1511 13.3189 91.3057 9.4799L91.0718 9.36748C92.9365 6.77177 93.1935 5.24741 93.1935 5.24741H93.1968ZM82.9574 42.7909C82.9574 43.4027 82.4632 43.8954 81.857 43.8954C81.2508 43.8954 80.7567 43.3994 80.7567 42.7909C80.7567 42.1825 81.2508 41.6865 81.857 41.6865C82.4632 41.6865 82.9574 42.1825 82.9574 42.7909Z" fill="#F79421" />
          <path d="M81.6411 0.856215C81.4797 0.585071 80.8274 0.528858 80.4617 0.518939C80.4156 0.3503 80.2409 0.221341 80.0301 0.211421L79.3943 0.191582C79.1406 0.181662 78.9231 0.353607 78.9133 0.571845C78.9034 0.790083 79.1043 0.975254 79.358 0.985174L79.9939 1.00501C80.1586 1.00832 80.3068 0.938881 80.3925 0.833069C80.7714 0.839682 81.1535 0.962028 81.1535 0.962028C81.6741 1.57376 81.2425 4.94652 81.0481 6.26256L80.544 6.14022C80.544 6.14022 80.6692 10.8588 77.21 11.411C77.21 11.411 73.7738 12.0591 74.1329 6.06747L73.6585 5.98481C73.6124 4.69853 73.5498 1.21995 74.1428 0.644591C74.1428 0.644591 74.5118 0.558618 74.8808 0.575151C74.9533 0.697497 75.0982 0.786776 75.2728 0.790083L75.9087 0.809922C76.1623 0.816536 76.3798 0.647897 76.3897 0.426353C76.3995 0.208115 76.1986 0.0229432 75.9416 0.0163299L75.3091 -0.000203294C75.1048 -0.00681656 74.9269 0.102302 74.8577 0.261021C74.4887 0.241181 73.8562 0.251101 73.6717 0.499099C73.6717 0.499099 72.9337 2.26815 73.2269 5.97158L73.0227 5.96497C73.0227 5.96497 72.031 11.6127 76.5247 12.5849L76.2809 40.5491C75.3914 40.8533 74.749 41.6899 74.749 42.6885C74.749 43.9417 75.7604 44.9568 77.009 44.9568C78.2576 44.9568 79.2691 43.9417 79.2691 42.6885C79.2691 41.7163 78.6563 40.893 77.7997 40.5689L77.7173 12.3666C78.5278 12.198 81.3512 11.5069 81.707 6.28902L81.4797 6.2791C82.1715 2.65503 81.6411 0.852909 81.6411 0.852909V0.856215ZM78.1951 42.7943C78.1951 43.406 77.7042 43.8987 77.0947 43.8987C76.4852 43.8987 75.9943 43.4027 75.9943 42.7943C75.9943 42.2288 76.4193 41.7659 76.9662 41.6998H77.2232C77.7701 41.7659 78.1951 42.2255 78.1951 42.7943Z" fill="#8DC641" />
          <path d="M69.3817 12.3205C70.0604 11.7253 71.2102 10.2274 69.5003 6.65627L69.2269 6.74886C68.222 3.82579 67.0723 2.64532 67.0723 2.64532C66.8416 2.49322 66.3277 2.65194 66.051 2.75775C65.9455 2.64532 65.761 2.59903 65.5996 2.66186L65.1153 2.84041C64.9209 2.91316 64.832 3.10494 64.9144 3.27358C65 3.43892 65.2273 3.51497 65.4184 3.44553L65.9027 3.26366C66.0279 3.21737 66.107 3.12148 66.1267 3.01236C66.4134 2.90324 66.7494 2.87679 66.7494 2.87679C67.3951 3.17438 68.4856 5.83292 68.8941 6.87451L68.6273 6.96048C68.6273 6.96048 70.7852 10.7234 68.4296 12.1949C68.4296 12.1949 65.3393 13.2993 63.4384 8.8651L63.2506 8.93124C62.6774 7.98554 61.1751 5.40306 61.376 4.78802C61.376 4.78802 61.6165 4.61277 61.8966 4.51027C62.002 4.5797 62.1503 4.60285 62.282 4.54994L62.7663 4.37139C62.9607 4.29864 63.0497 4.10686 62.964 3.93822C62.8816 3.77289 62.6543 3.69353 62.4599 3.76627L61.9756 3.94483C61.8208 4.00435 61.7319 4.13992 61.7483 4.27549C61.4617 4.37469 60.9939 4.5764 60.9609 4.82109C60.9609 4.82109 61.152 6.36859 62.9212 9.05028L62.6971 9.17923C62.6971 9.17923 64.6178 13.6663 68.4757 12.8893C89.3498 21.7114 80.2964 27.0747 80.2964 27.0747C74.106 32.4513 72.528 37.6593 72.159 40.4633C70.9499 40.5096 69.9846 41.4983 69.9846 42.7251C69.9846 43.9518 70.996 44.9934 72.2446 44.9934C73.4933 44.9934 74.5047 43.9783 74.5047 42.7251C74.5047 41.8025 73.9545 41.0089 73.1638 40.6551C73.5954 38.0032 75.2262 32.7853 81.3441 27.6038C89.0994 21.0368 73.0518 13.7788 69.3784 12.3139L69.3817 12.3205ZM72.3336 43.9419C71.7241 43.9419 71.2332 43.4459 71.2332 42.8342C71.2332 42.2224 71.7274 41.7298 72.3336 41.7298C72.9398 41.7298 73.434 42.2257 73.434 42.8342C73.434 43.4426 72.9398 43.9419 72.3336 43.9419Z" fill="#043C38" />
        </g>
        <defs>
          <clipPath id="clip0_975_2766">
            <rect width="157" height="45" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={finalWidth} height={finalHeight} viewBox={`${small ? 45 : 0} 0 ${defaultWidth} ${defaultHeight}`}>
      <defs>
        <path id="prefix__a" d="M0 0.151L0 10.97 6.872 10.97 6.872 0.151 0 0.151z" />
        <path id="prefix__c" d="M0 36L122 36 122 0 0 0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        { !small && (
          <>
            <g transform="translate(0 9)">
              <mask id="prefix__b" fill="#fff">
                <use xlinkHref="#prefix__a" />
              </mask>
              <path fill="#FFF" d="M1.123 5.764V9.91h1.422c.839 0 1.453-.077 1.844-.231.39-.155.705-.396.941-.725.237-.328.356-.684.356-1.066 0-.48-.166-.9-.5-1.258-.333-.358-.791-.603-1.374-.736-.39-.088-1.071-.131-2.04-.131h-.65zm0-4.553v3.464h.657c.797 0 1.383-.07 1.759-.21.375-.14.671-.36.89-.662.22-.301.329-.636.329-1.004 0-.495-.184-.883-.552-1.166-.366-.28-.951-.422-1.755-.422H1.123zM0 .15h2.262c.91 0 1.61.103 2.098.305.489.204.874.518 1.159.938.283.422.425.89.425 1.405 0 .48-.125.918-.373 1.314-.249.394-.614.713-1.094.959.596.191 1.054.415 1.376.673.322.257.571.568.75.933.18.366.269.762.269 1.189 0 .868-.337 1.602-1.01 2.202-.674.601-1.578.9-2.711.9H0V.152z" mask="url(#prefix__b)" />
            </g>
            <path fill="#FFF" d="M13.976 10.018c-.842 0-1.64.202-2.395.606-.754.404-1.344.948-1.768 1.63-.424.685-.636 1.448-.636 2.29 0 1.244.47 2.295 1.408 3.152.938.857 2.069 1.285 3.391 1.285.885 0 1.703-.197 2.455-.591.752-.394 1.339-.933 1.76-1.618.422-.683.632-1.444.632-2.28 0-.83-.21-1.582-.631-2.255-.422-.671-1.015-1.21-1.78-1.613-.765-.404-1.577-.606-2.436-.606M13.926 9c1.726 0 3.169.53 4.331 1.588C19.42 11.646 20 12.949 20 14.496c0 1.533-.58 2.834-1.739 3.902C17.101 19.466 15.688 20 14.02 20c-1.69 0-3.114-.531-4.276-1.595C8.582 17.342 8 16.055 8 14.545c0-1.005.265-1.938.795-2.797.529-.859 1.25-1.531 2.165-2.018.913-.487 1.902-.73 2.967-.73M26.53 11.337l-1.956 3.982h3.904l-1.948-3.982zM26.675 9L32 20h-1.233l-1.796-3.62h-4.919L22.273 20H21l5.395-11h.28zM35.093 10.077v3.739l1.886.015c.73 0 1.27-.07 1.62-.209.351-.139.624-.362.82-.67.196-.306.294-.65.294-1.028 0-.369-.099-.703-.298-1.006-.198-.3-.46-.516-.782-.646-.323-.13-.86-.195-1.61-.195h-1.93zM34 9h2.18c1.215 0 2.038.05 2.47.15.649.15 1.178.47 1.584.962.407.492.61 1.097.61 1.816 0 .599-.14 1.126-.42 1.58-.28.454-.68.797-1.202 1.03-.52.231-1.24.35-2.157.355L41 20h-1.353l-3.936-5.107h-.618V20H34V9zM44.024 18.96h1.235c1.477 0 2.5-.094 3.073-.283.803-.269 1.435-.753 1.896-1.453.46-.7.69-1.555.69-2.567 0-1.06-.25-1.966-.748-2.72-.499-.751-1.195-1.267-2.085-1.546-.669-.209-1.77-.314-3.306-.314h-.755v8.884zM43 20V9h2.215c1.599 0 2.758.132 3.48.396 1.037.375 1.847 1.023 2.43 1.945.584.923.875 2.022.875 3.298 0 1.102-.232 2.071-.694 2.909-.462.838-1.063 1.455-1.8 1.855-.74.398-1.774.597-3.107.597H43zM74 9L75.228 9 78.97 17.5 82.773 9 84 9 79.088 20 78.843 20z" />
            <mask id="prefix__d" fill="#fff">
              <use xlinkHref="#prefix__c" />
            </mask>
            <path fill="#FFF" d="M87 20L88 20 88 9 87 9zM89 10.077L89 9 95 9 95 10.077 92.559 10.077 92.559 20 91.442 20 91.442 10.077zM101.531 11.337l-1.958 3.982h3.906l-1.948-3.982zM101.675 9L107 20h-1.233l-1.796-3.62h-4.919L97.273 20H96l5.395-11h.28zM109 9L110.032 9 110.032 18.939 114 18.939 114 20 109 20zM115 17.786l1.016-.534c.715 1.153 1.542 1.73 2.479 1.73.402 0 .778-.082 1.13-.245.353-.164.62-.384.805-.66.184-.274.277-.567.277-.875 0-.352-.135-.695-.406-1.033-.375-.465-1.057-1.025-2.049-1.68-.998-.66-1.618-1.137-1.862-1.432-.423-.492-.635-1.026-.635-1.602 0-.454.125-.87.374-1.245.25-.375.6-.67 1.053-.886.453-.217.944-.324 1.477-.324.562 0 1.09.121 1.58.366s1.01.695 1.558 1.35l-.976.648c-.45-.523-.834-.867-1.15-1.033-.318-.166-.663-.249-1.038-.249-.481 0-.876.128-1.182.384-.307.257-.459.572-.459.948 0 .227.053.448.162.662.109.213.306.446.593.697.158.133.674.482 1.545 1.047 1.035.67 1.745 1.264 2.13 1.787.385.522.578 1.047.578 1.574 0 .759-.329 1.419-.988 1.979s-1.46.84-2.402.84c-.726 0-1.386-.17-1.976-.509-.591-.34-1.136-.907-1.634-1.705M69 24v2.014c14.593.26 31.723 2.151 51 6.986 0 0-22.531-8.119-51-9M55 24v2.014c-14.593.26-31.723 2.151-51 6.986 0 0 22.531-8.119 51-9" mask="url(#prefix__d)" />
          </>
        )}
        <path fill="#FFF" d="M66.81 34.257c0 .493-.394.891-.881.891-.486 0-.88-.398-.88-.891s.394-.891.88-.891c.487 0 .88.398.88.89M75 4c-.02-.2-.402-.378-.625-.464.022-.115-.045-.232-.175-.282l-.383-.152c-.155-.06-.337-.001-.409.132-.069.132-.001.29.153.35l.384.15c.1.04.21.03.296-.02.228.089.426.246.426.246.14.493-1.118 2.485-1.624 3.255l-.223-.094s-1.596 3.053-3.661 2.613c0 0-2.381-.587-.394-4.214l-.173-.086c.35-.804 1.335-2.969 1.869-3.196 0 0 .252.03.472.118.008.094.072.18.178.22l.383.151c.155.061.337.002.407-.13.07-.134.003-.292-.152-.352l-.383-.15c-.124-.05-.265-.02-.352.06-.224-.092-.614-.224-.8-.11 0 0-.973.932-1.883 3.288l-.192-.095s-2.218 3.143.113 4.746c-2.836 1.135-15.814 6.979-9.576 12.301 4.805 4.102 5.975 8.166 6.28 10.313-.537.317-.903.9-.903 1.575 0 1.01.81 1.828 1.808 1.828.999 0 1.808-.819 1.808-1.828 0-1.01-.809-1.829-1.808-1.829-.011 0-.021.003-.033.003-.257-2.291-1.434-6.354-6.57-10.768 0 0-6.217-4.439 9.774-11.18.594.07 2.734.103 4.459-2.988l-.187-.09C74.796 5.227 75 4 75 4" mask="url(#prefix__d)" />
        <path fill="#FFF" d="M62.14 34.268c0 .49-.387.886-.866.886-.477 0-.865-.396-.865-.886 0-.453.335-.824.764-.875h.202c.43.051.765.422.765.875M64.847.686c-.126-.216-.639-.261-.927-.27-.036-.135-.174-.238-.34-.244l-.5-.016c-.2-.008-.37.13-.377.304-.007.175.15.324.35.33l.5.017c.129.004.244-.053.312-.139.298.007.597.105.597.105.408.49.07 3.192-.082 4.245l-.398-.098s.098 3.78-2.62 4.22c0 0-2.7.52-2.417-4.279l-.371-.068c-.035-1.03-.085-3.815.381-4.275 0 0 .29-.068.579-.055.057.098.172.169.308.174l.5.016c.2.006.37-.13.377-.306.007-.174-.149-.322-.35-.329L59.87 0c-.162-.004-.3.084-.355.208-.29-.015-.788-.009-.932.192 0 0-.58 1.416-.35 4.383l-.16-.006s-.78 4.522 2.752 5.301l-.19 22.393c-.7.245-1.204.915-1.204 1.712 0 1.004.795 1.817 1.776 1.817s1.776-.813 1.776-1.817c0-.78-.481-1.438-1.154-1.696l-.064-22.584c.637-.135 2.854-.689 3.134-4.867l-.179-.006c.543-2.902.126-4.344.126-4.344" mask="url(#prefix__d)" />
        <path fill="#FFF" d="M58.105 35.153c-.487 0-.882-.397-.882-.887s.395-.886.882-.886c.488 0 .882.396.882.886s-.394.887-.882.887M55.743 9.806c.543-.478 1.465-1.678.096-4.54l-.22.075c-.805-2.343-1.726-3.29-1.726-3.29-.185-.123-.595.004-.818.09-.085-.091-.232-.127-.362-.078l-.388.144c-.155.058-.227.213-.16.346.068.134.25.195.405.137l.388-.144c.1-.037.165-.115.18-.202.23-.088.498-.108.498-.108.519.238 1.391 2.368 1.717 3.204l-.215.069s1.73 3.015-.157 4.197c0 0-2.475.885-3.997-2.67l-.15.055c-.46-.76-1.663-2.828-1.5-3.32 0 0 .191-.141.416-.222.086.056.203.073.309.033l.387-.144c.156-.058.228-.213.16-.347-.067-.133-.248-.195-.405-.138l-.387.145c-.125.046-.194.155-.183.265-.23.08-.604.241-.631.437 0 0 .152 1.24 1.57 3.389l-.177.103s1.539 3.595 4.626 2.973c16.716 7.072 9.466 11.372 9.466 11.372-4.958 4.31-6.222 8.484-6.516 10.733-.968.036-1.742.83-1.742 1.811 0 1.005.81 1.819 1.81 1.819s1.81-.814 1.81-1.819c0-.74-.441-1.374-1.072-1.658.344-2.125 1.652-6.308 6.55-10.461 6.211-5.264-6.64-11.082-9.582-12.256" mask="url(#prefix__d)" />
      </g>
    </svg>
  );
}

BVLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  small: PropTypes.bool,
  light: PropTypes.bool
};

export default BVLogo;
