import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgStethoscope(props) {
  return (
    <SvgContainer title="Stethoscope Icon" {...props}>
      <path d="M25.61 19.522A4.48 4.48 0 0016.895 21v3.246a2.815 2.815 0 11-5.63 0v-1.77a1.866 1.866 0 001.63-1.845v-1.456l1.997-.827a1.97 1.97 0 001.218-2.034 6.723 6.723 0 003.502-7.007 4.811 4.811 0 00-3.739-4.033 1.983 1.983 0 10-.128.982 3.827 3.827 0 012.879 3.207 5.752 5.752 0 01-2.864 5.889 2 2 0 00-2.398-.7l-2.467 1.022-2.466-1.022a2 2 0 00-2.398.7 5.752 5.752 0 01-2.864-5.89 3.826 3.826 0 012.878-3.21 2.013 2.013 0 10-.126-.984 4.814 4.814 0 00-3.74 4.039 6.723 6.723 0 003.502 7.007 1.972 1.972 0 001.217 2.034l1.997.827v1.456a1.867 1.867 0 001.37 1.793v1.822a3.815 3.815 0 107.63 0V21a3.483 3.483 0 016.672-1.416 4.54 4.54 0 101.043-.062zM13.896 6.5a1 1 0 111-1 1 1 0 01-1 1zm-6-2a1 1 0 11-1 1 1 1 0 011-1zm2 16.13v-2.123L7.28 17.424a1 1 0 01.766-1.848l2.849 1.18 2.848-1.18a1 1 0 011.307.542 1 1 0 01-.541 1.306l-2.615 1.083v2.124a.87.87 0 01-.869.869h-.26a.87.87 0 01-.87-.87zm15.5 6.87a3.5 3.5 0 113.5-3.5 3.504 3.504 0 01-3.5 3.5z" />
      <path d="M25.395 21.5a2.5 2.5 0 102.5 2.5 2.503 2.503 0 00-2.5-2.5zm0 4a1.5 1.5 0 111.5-1.5 1.502 1.502 0 01-1.5 1.5z" />
    </SvgContainer>
  );
}
