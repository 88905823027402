import React from 'react';
import PropTypes from 'prop-types';

import indexToLetter from 'helpers/indexToLetter';

export default function PeerComparisonGraph({ peerComparisonPercents }) {
  if (!peerComparisonPercents) return null;

  const keys = Object.keys(peerComparisonPercents);
  const els = keys.map((key) => {
    const percent = Math.round(peerComparisonPercents[key] * 100);
    const label = Number.isInteger(parseInt(key)) ? indexToLetter(parseInt(key)) : key;
    return (
      <div key={key}>{label}: {percent}%</div>
    );
  });

  return (
    <div className="PeerComparisonGraph">
      <h6>Peer Comparison:</h6>
      {els}
    </div>
  );
}

PeerComparisonGraph.propTypes = {
  peerComparisonPercents: PropTypes.object
};
