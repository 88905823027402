import { Map } from 'immutable';

import StateHelper from 'helpers/StateHelper';
import Question from 'factories/Question';

import {
  QUESTION_PRELOADED,
  QUESTION_FETCHED,
  QUESTION_RESULTS_FETCHED,
  SEARCH_QUESTIONS_FETCHED,
  QUESTION_EXCERPTS_FETCHED,
} from 'actions/questions';
import {
  QUIZ_CREATED,
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_QUESTIONS,
  QUIZ_QUESTION_FETCHED_NEXT,
  QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES,
} from 'actions/quizzes';
import {
  SESSION_RESET
} from 'actions/session';
import {
  QUESTION_BANK_SELECTED
} from 'actions/questionBanks';
import {
  OFFLINE_QUIZ_LOADED
} from 'actions/offline';


const stateHelper = new StateHelper(Question);
export const { initialState } = stateHelper;
const reducers = {};


reducers[QUESTION_PRELOADED] = stateHelper.set;
reducers[QUESTION_FETCHED] = stateHelper.set;
reducers[SEARCH_QUESTIONS_FETCHED] = stateHelper.set;
reducers[QUESTION_RESULTS_FETCHED] = stateHelper.set;
reducers[QUIZ_QUESTION_FETCHED_NEXT] = stateHelper.set;

reducers[QUESTION_EXCERPTS_FETCHED] = (state, payload) =>
  payload.questionArrays.reduce((prevState, questionArray) => prevState.setIn([questionArray[0], 'plain_name'], questionArray[1]), state);

// Non-Questions Action Reducers
reducers[QUIZ_CREATED] = stateHelper.resetAndSet;
reducers[QUIZ_FETCHED_TAKE] = stateHelper.resetAndSet;
reducers[QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES] = (state, payload) => {
  const { isAdaptive, questionIds } = payload;
  if (isAdaptive) {
    questionIds.forEach((questionId) => {
      // eslint-disable-next-line no-param-reassign
      state = state.set(questionId, new Map({ plain_name: state.getIn([questionId, 'plain_name']) }));
    });
  }
  return state;
};
reducers[QUIZ_FETCHED_QUESTIONS] = function quizFetchedQuestions(state, payload) {
  let newState = state;
  newState = stateHelper.set(newState, payload);
  const { difficultyLevels } = payload;
  if (difficultyLevels) {
    // Replace difficulty level returned from the endpoint with correct value from performanceQuestions slice of state
    const questionIds = newState.toList().toArray().map(question => question.get('id'));
    questionIds.forEach((questionId) => {
      const difficultyRecord = difficultyLevels.find(record => record.id === parseInt(questionId));
      if (difficultyRecord) {
        const { difficulty } = difficultyRecord;
        newState = newState.setIn([parseInt(questionId), 'difficulty_level'], difficulty);
      }
    });
  }
  return newState;
};

reducers[SESSION_RESET] = stateHelper.reset;
reducers[QUESTION_BANK_SELECTED] = stateHelper.reset;

reducers[OFFLINE_QUIZ_LOADED] = function offlineQuizLoaded(state, payload) {
  let newState = Map();
  payload.questions.forEach((question) => {
    newState = newState.set(question.get('id'), question);
  });
  return newState;
};


export default stateHelper.createReducer(reducers);
