import StateHelper from 'helpers/StateHelper';
import OrganizationalMembership from 'factories/OrganizationalMembership';

import {
  USER_SUBSCRIPTIONS_FETCHED
} from 'actions/users';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  ORGANIZATION_MEMBERS_FETCHED,
  ORGANIZATION_SELECTED,
  RELOAD_CURRENT_USER_MEMBERSHIPS,
  ORGANIZATION_UNLOAD_MEMBERS
} from 'actions/b2b/organizations';

import {
  MEMBER_GROUP_FETCHED,
  MEMBER_GROUPS_FETCHED
} from 'actions/b2b/memberGroups';

const stateHelper = new StateHelper(OrganizationalMembership);
export const { initialState } = stateHelper;
const reducers = {};


reducers[USER_SUBSCRIPTIONS_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[ORGANIZATION_MEMBERS_FETCHED] = stateHelper.set;
reducers[ORGANIZATION_UNLOAD_MEMBERS] = function organizationUnloadMembers(state, payload) {
  return initialState;
};
reducers[RELOAD_CURRENT_USER_MEMBERSHIPS] = function reloadCurrentUserMemberships(state, payload) {
  return state.withMutations((newState) => {
    payload.toArray().forEach((om) => {
      const [id, membership] = om;
      newState.set(id, membership);
    });
  });
};
reducers[SESSION_RESET] = stateHelper.reset;
reducers[ORGANIZATION_SELECTED] = (state, payload) => state.filter(membership => membership.get('user_id') === payload.currentUserId);

reducers[MEMBER_GROUPS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_FETCHED] = stateHelper.set;

export default stateHelper.createReducer(reducers);
