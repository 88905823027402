import React from "react";
import PropTypes from "prop-types";
import SVGIcon from 'components/SVGIcon';
import PageSection from "./PageSection";

const CreditRedemption = ({ dataCreditRedemption }) => {
  const {
    question_bank: questionBank,
    credit_name: creditName,
    cme_coach_path: cmeCoachPath,
    specialty_name: specialtyName,
    minimum_percent: minimumPercent
  } = dataCreditRedemption;

  return (
    <PageSection section="credit-redemption-instructions">
      <div className="wrapper">
        <h2 className="storefront-secondary-title text-lg-center">How Do I Earn {questionBank} {creditName} Credits?</h2>
        <div className="row text-md mt-3 pt-5 justify-content-evenly align-items-evenly">
          <div className=" d-flex step p-5 mb-5">
            <div className="mr-5 svg"><SVGIcon name="Location" /></div>
            <div className="content">
              <p className="mb-3 font-weight-bold">Step 1</p>
              <p className="mb-0 storefront-body-text">Check your <a href={cmeCoachPath} target="_blank" rel="noreferrer" className="storefront-link">{specialtyName} state requirements</a> for CME where you practice.</p>
            </div>
          </div>
          <div className=" d-flex step p-5 mb-5">
            <div className="mr-5 svg"><SVGIcon name="Checkout" /></div>
            <div className="content">
              <p className="mb-3 font-weight-bold">Step 2</p>
              <p className="mb-0 storefront-body-text">Purchase the {questionBank} {creditName} Self-Assessment Activity.</p>
            </div>
          </div>
          <div className=" d-flex step p-5 mb-5">
            <div className="mr-5 svg"><SVGIcon name="Correct" /></div>
            <div className="content">
              <p className="mb-3 font-weight-bold">Step 3</p>
              <p className="mb-0 storefront-body-text">Answer questions with at least {minimumPercent}% accuracy (you may correct answers as you go).</p>
            </div>
          </div>
          <div className=" d-flex step p-5 mb-5">
            <div className="mr-5 svg"><SVGIcon name="Note" /></div>
            <div className="content">
              <p className="mb-3 font-weight-bold">Step 4</p>
              <p className="mb-0 storefront-body-text">Redeem and submit the form and evaluation.</p>
            </div>
          </div>
          <div className=" d-flex step p-5 mb-5">
            <div className="mr-5 svg"><SVGIcon name="Print" /></div>
            <div className="content">
              <p className="mb-3 font-weight-bold">Step 5</p>
              <p className="mb-0 storefront-body-text">Print your PDF certificate of completion.</p>
            </div>
          </div>
          <div className=" d-flex step p-5 mb-5 align-items-center justify-content-center">
            <div className="mr-5 svg"><SVGIcon name="Help" /></div>
            <div className="content">
              <p className="mb-3 storefront-body-text">For full steps, view our</p>
              <a href="https://info.boardvitals.com/knowledge/redeem-cme" target="_blank" rel="noreferrer" className="storefront-link">
                <p className="mb-0">Support Page on Redeeming CME Hours.</p>
              </a>
            </div>
          </div>
        </div>
        <div className="instructions-note pt-3 pb-3 mt-3 mb-3 ">
          <p className="storefront-disclaimer-text">*Please Note: Non-MOC hours (CME only) are self-reported. Please follow the instructions given by your board to report your CME hours. Members of the ABIM, ABPath, ABP, and ABHONS MOC credits will be submitted to the respective board website by BoardVitals.</p>
        </div>
      </div>
    </PageSection>
  );
};

CreditRedemption.propTypes = {
  dataCreditRedemption: PropTypes.object.isRequired
};

export default CreditRedemption;
