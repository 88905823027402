// Only used for App 1.0 Sample Question Component

import React from 'react';
import PropTypes from 'prop-types';

import LegacySingleChoiceQuestion from './LegacySingleChoiceQuestion';

class LegacyQuestion extends React.Component {
  getPeerComparisonPercents(question) {
    if (question.get('peer_response_count') < 10) return null;
    const percents = {};
    question.get('answers').forEach((answer) => {
      percents[answer.get('choice')] = (answer.get('peer_response_count') || 0) / question.get('peer_response_count');
    });
  }

  render() {
    const { question } = this.props;
    const peerComparisonPercents = this.getPeerComparisonPercents(question);
    return <LegacySingleChoiceQuestion {...this.props} peerComparisonPercents={peerComparisonPercents} />;
  }
}

LegacyQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  showResponseCorrectness: PropTypes.bool,
  canShowAnswer: PropTypes.bool,
  selectedAnswer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  onChangeAnswer: PropTypes.func,
  onClickShowAnswer: PropTypes.func,
  onClickAnswer: PropTypes.func,
  disableAnswerSelection: PropTypes.bool
};

LegacyQuestion.defaultProps = {
  showResponseCorrectness: false,
  canShowAnswer: false
};

export default LegacyQuestion;
