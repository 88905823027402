import React from "react";
import PropTypes from "prop-types";

import Button from "components/inputs/Button";

import ProductDetails from "./ProductDetails";
import PricingCard from "./components/PricingCard";
import CardsCarousel from "./components/CardsCarousel";
import useScreenSize from "./hooks/useScreenSize";

const DefaultPricing = ({
  pricingPlanFeatures,
  activeQuestionCount,
  pricing,
  featuresForTooltip,
  tooltipDescription,
  mobileAddonsLogicObject,
  managemobileAddons
}) => {
  const cards = [];
  let freeTrialId = 0;
  const { isMobile, isTablet } = useScreenSize();

  return (
    <>
      <ProductDetails section="pricing-cards">
        {pricing.map((pricingItem) => {
          const {
            id,
            months,
            discount,
            price,
            practice_exam_addon: practiceExamAddon,
            cme_addon: cmeAddon
          } = pricingItem;
          let card = <div key={id} />;

          if (months !== 0) {
            card = (
              <PricingCard
                id={id}
                key={id}
                pricingPlanFeatures={pricingPlanFeatures}
                activeQuestionCount={activeQuestionCount}
                months={months}
                discount={discount || 0}
                price={price}
                practiceExamAddon={practiceExamAddon}
                cmeAddon={cmeAddon}
                featuresForTooltip={featuresForTooltip}
                tooltipDescription={tooltipDescription}
                mobileAddonsLogicObject={mobileAddonsLogicObject}
                managemobileAddons={managemobileAddons}
              />
            );

            cards.push(card);
          } else {
            freeTrialId = id;
            return null;
          }

          return card;
        })}
      </ProductDetails>
      <ProductDetails section="mobile">
        <CardsCarousel cards={cards} mobileOnly cardClassName="mobile-card" caretWidth={21} caretHeight={38} />
      </ProductDetails>
      <ProductDetails section="other-pricing-cards">
        <div className="d-flex justify-content-between">
          <div className={`${(isMobile || isTablet) ? 'w-100' : 'w-50 mr-5'} d-flex justify-content-start align-items-center other-card p-6`}>
            <form id={`product-${freeTrialId}-form`} method="POST" action="/cart_items">
              <h3 className="mb-4">Try BoardVitals Free for 10 Days</h3>
              <p>No credit card required!</p>
              <input type="hidden" name="cart_item[product_id]" value={freeTrialId} />
              <Button large type="submit" form={`product-${freeTrialId}-form`} className="storefront-button-text">Access Free Questions</Button>
            </form>
          </div>
          <div className={`${(isMobile || isTablet) ? 'd-none' : 'd-flex w-50'} justify-content-center align-items-center other-card ml-5 p-6`}>
            <div className="column">
              <h3 className="mb-4">Looking for Institutional Pricing?</h3>
              <p className="institutional-description">
                Whether you are looking to fill a need at a medical or nursing school, residency program,
                health sciences library, or hospital, BoardVitals is ready to customize a package to best suit your needs.
              </p>
              <a href="https://www.boardvitals.com/institutional" target="_blank" rel="noreferrer" className="storefront-link">Bring BoardVitals to Your Institution{">"}</a>
            </div>

          </div>
        </div>
      </ProductDetails>
    </>
  );
};

DefaultPricing.propTypes = {
  pricingPlanFeatures: PropTypes.array.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  pricing: PropTypes.array.isRequired,
  featuresForTooltip: PropTypes.func.isRequired,
  tooltipDescription: PropTypes.func.isRequired,
  mobileAddonsLogicObject: PropTypes.object,
  managemobileAddons: PropTypes.func
};

export default DefaultPricing;
