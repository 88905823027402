import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCircleCheck(props) {
  return (
    <SvgContainer title="Circle Check Icon" {...props}>
      <path d="M21.628 11.665l-8.593 9.548L10.4 17.7a.5.5 0 10-.8.6l3 4c.008.011.022.015.03.026a.472.472 0 00.035.046.485.485 0 00.081.05.424.424 0 00.254.078h.001a.496.496 0 00.263-.088c.011-.007.025-.003.036-.011a.476.476 0 00.034-.039c.012-.01.027-.015.038-.027l9-10a.5.5 0 00-.745-.67z" />
      <path d="M16 4a12 12 0 1012 12A12.013 12.013 0 0016 4zm0 23a11 11 0 1111-11 11.012 11.012 0 01-11 11z" />
    </SvgContainer>
  );
}
