import { useState, useEffect, useMemo } from 'react';

// javascript/style-guide/config.js
export const breakpoints = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1360
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useResizeListener = (onResize) => {
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useResizeListener(() => setWindowDimensions(getWindowDimensions()));

  return windowDimensions;
}

export function useResponsiveChecks() {
  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => width < breakpoints.sm, [width]);
  const isTabletVert = useMemo(() => width >= breakpoints.sm && width < breakpoints.md, [width]);
  const isTabletHoriz = useMemo(() => width >= breakpoints.md && width < breakpoints.lg, [width]);
  const isLaptopSmall = useMemo(() => width >= breakpoints.lg && width < breakpoints.xl, [width]);

  return {
    isMobile, isTabletVert, isTabletHoriz, isLaptopSmall
  };
}

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const updateDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(updateDimensions());
    };

    if (myRef.current) {
      setDimensions(updateDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
