import { List } from 'immutable';

import Factory from 'factories/_Factory';


export default class CmeActivityTracker extends Factory {
  static jsonType = 'cme_activity_tracker';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;

    if (attributes.questions_logged_for_credit || newRecord.get('questions_logged_for_credit') === undefined) {
      const questionsLoggedForCredit = attributes.questions_logged_for_credit || [];
      newRecord = newRecord
        .set('questions_logged_for_credit', new List(questionsLoggedForCredit));
    }

    return newRecord;
  }

  static get defaults() {
    return {
      // board_info: '',
      completed: null,
      // credits_claimed: 0,
      credits_earned: 0,
      earliest_available_cme: '',
      eligible_to_redeem_hours: null,
      incorrect_total: 0,
      latest_available_cme: '',
      meets_minimum_percent_correct: null,
      // pdp_path: '',
      percent_correct: '',
      // product_id: null,
      // product_price: '',
      // product_credits: new List(),
      // purchased: null,
      // questions_per_hour: 0,
      questions_total_to_next_credit: 0,
      // redeemed_single_use: null,
      // status: '',
    };
  }

  static get belongsTo() {
    return ['cme_activity', 'cme_credit_tracker', 'question_bank', 'user'];
  }
}
