import React from 'react';
import { List } from 'immutable';

export const defaultOptions = {
  title: null,
  closable: true,
  closeLabel: 'Close',
  closeX: true,
  closeXAbsolute: false,
  closeButton: true,
  closeUnderlay: true,
  onClose: null,
  size: null,
  draggable: true,
  containsForm: false,
  actionButtons: null,
  titleSize: null,
  padding: null,
  paddingBottom: null,
  rounded: true,
  border: null,
  headerClasses: null,
  minWidth: null,
  ariaLevel: null,
  headerStyles: {},
  coverScreen: false,
};

export const MODAL_OPEN = 'MODAL_OPEN';
export const modalOpen = (content, options) => (dispatch) => {
  const validContentTypes = ['string'];
  if (!validContentTypes.includes(typeof content) && !React.isValidElement(content)) {
    console.warn('Invalid content provided to modalOpen: ', typeof content, content);
    return;
  }

  const payload = { content, ...defaultOptions };

  switch (typeof options) {
    case 'string':
      // Allow a string to be passed for the title
      payload.title = options;
      break;
    case 'object':
      // Only allow options that are included in defaultOptions
      Object.keys(defaultOptions).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(options, key) && options[key] !== null) {
          if (Array.isArray(options[key])) {
            payload[key] = List(options[key]);
          } else {
            payload[key] = options[key];
          }
        }
      });
      break;
    case 'undefined':
      break;
    default:
      console.warn('Invalid options provided to modalOpen: ', typeof options, options);
      return;
  }

  dispatch({
    type: MODAL_OPEN,
    payload
  });
};


export const MODAL_CLOSE = 'MODAL_CLOSE';
export const modalClose = () => ({
  type: MODAL_CLOSE
});
