import Factory from 'factories/_Factory';


export default class StrikeThrough extends Factory {
  static jsonType = 'strike_through';

  // Validates Answer ID instead of Question ID
  static validateJSON(json) {
    const quizId = parseInt(json?.relationships?.quiz?.data?.id);
    const answerId = parseInt(json?.relationships?.answer?.data?.id);
    if (!quizId || quizId < 0 || !answerId || answerId < 0) throw new Error(`Quiz ID and Answer ID are required to create a new ${this.constructor.name}`);
  }

  static get defaults() {
    return {
      deleted: false
    };
  }

  static get belongsTo() {
    return ['quiz', 'answer'];
  }
}
