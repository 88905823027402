import api, { currentUserUrl } from 'helpers/api';
import { createApiRequestAction } from 'helpers/createAction';

import { performanceQuestionsFetch } from 'actions/performance';


export const QUESTION_BANK_LOADING = 'QUESTION_BANK_LOADING';
export const QUESTION_BANK_ERROR = 'QUESTION_BANK_ERROR';

export const QUESTION_BANK_FETCHED = 'QUESTION_BANK_FETCHED';
const getQuestionBankFetchKey = questionBankId => `question-banks/${questionBankId}`;
export const questionBankFetch = questionBankId => (dispatch, getState) => {
  const { loading } = getState();
  const key = getQuestionBankFetchKey(questionBankId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/question_banks/${questionBankId}`)
    .then((response) => {
      dispatch({
        type: QUESTION_BANK_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_BANK_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: QUESTION_BANK_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionBankFetch.getKey = getQuestionBankFetchKey;


export const QUESTION_BANKS_FETCHED = 'QUESTION_BANKS_FETCHED';
const getQuestionBanksFetchKey = () => `question-banks/all`;
export const questionBanksFetch = () => (dispatch, getState) => {
  const { loading } = getState();
  const key = getQuestionBanksFetchKey();

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/question_banks`)
    .then((response) => {
      dispatch({
        type: QUESTION_BANKS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_BANK_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: QUESTION_BANK_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionBanksFetch.getKey = getQuestionBanksFetchKey;

export const QUESTION_BANK_SUBJECTS_FETCHING = 'QUESTION_BANK_SUBJECTS_FETCHING';
export const QUESTION_BANK_SUBJECTS_FETCHED = 'QUESTION_BANK_SUBJECTS_FETCHED';
const getQuestionBankFetchSubjectsKey = questionBankId => `question-bank-subjects-fetch/${questionBankId}`;
export const questionBankFetchSubjects = (questionBankId, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = getQuestionBankFetchSubjectsKey(questionBankId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/subjects`)
    .then((response) => {
      dispatch({
        type: QUESTION_BANK_SUBJECTS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_BANK_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: QUESTION_BANK_SUBJECTS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
questionBankFetchSubjects.getKey = getQuestionBankFetchSubjectsKey;

export const QUESTION_BANK_SELECTING = 'QUESTION_BANK_SELECTING';
export const QUESTION_BANK_SELECTED = 'QUESTION_BANK_SELECTED';
const getQuestionBankSelectKey = questionBankId => `question-bank-select/${questionBankId}`;
export const questionBankSelect = (questionBankId, userIdArg, fetchRelationships = true) => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = getQuestionBankSelectKey(questionBankId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`users/${userId}/question_banks/${questionBankId}/select`)
    .then((response) => {
      dispatch({
        type: QUESTION_BANK_SELECTED,
        payload: { key, ...response }
      });
      // always fetch subjects with bank since being decoupled
      dispatch(questionBankFetchSubjects(questionBankId, userId));
      if (fetchRelationships) {
        dispatch(performanceQuestionsFetch(questionBankId, userId));
      }
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_BANK_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: QUESTION_BANK_SELECTING,
    payload: { key, promise }
  });

  return promise;
};
questionBankSelect.getKey = getQuestionBankSelectKey;


export const QUESTION_BANK_NAMES_FETCHING = 'QUESTION_BANK_NAMES_FETCHING';
export const QUESTION_BANK_NAMES_FETCHED = 'QUESTION_BANK_NAMES_FETCHED';
export const QUESTION_BANK_NAMES_ERROR = 'QUESTION_BANK_NAMES_ERROR';
const getQBNamesKey = () => 'question_banks/names';
export const getQuestionBankNames = createApiRequestAction({
  getKey: getQBNamesKey,
  request: getState => api.get(`${currentUserUrl(getState())}/question_banks/names`),
  loadingConstant: QUESTION_BANK_NAMES_FETCHING,
  loadedConstant: QUESTION_BANK_NAMES_FETCHED,
  errorConstant: QUESTION_BANK_NAMES_ERROR,
});
