import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';

import Button from 'components/inputs/Button';

import { modalClose } from 'actions/modal';
import Drag from 'helpers/Draggable';


// StyleGuide = molecules/global/modal

export class ModalClass extends React.Component {
  constructor(props) {
    super(props);
    // When shown = null it means an animation is in progress
    this.state = { shown: false };
  }

  componentDidMount() {
    const { modal } = this.props;
    if (modal.get('shouldShow')) {
      this.fade();
    }
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props;
    if (modal.get('shouldShow') !== prevProps.modal.get('shouldShow')) {
      this.fade();
    }
  }

  // eslint-disable-next-line no-unused-vars
  onClickClose(ev) {
    const { actions, modal } = this.props;
    // Don't try to close if we are already closing
    if (!modal.get('shouldShow')) return;

    // The Modal must ALWAYS be closed via the modalClose() Action
    if (modal.get('closable')) actions.modalClose();
  }

  onKeyUp(ev) {
    const { modal } = this.props;

    // Close when escape key is pressed
    if (ev.keyCode === 27) {
      this.onClickClose();
    } else if (!modal.get('containsForm')) {
      // Close when space or enter keys are pressed
      // TODO: Evaluate where we need this. Maybe instead  of an event it is another Modal option which auto-focuses user input to
      // the 'close' button instead of the Modal itself. Then pushing space or enter would close the modal.
      if ([32, 13].includes(ev.keyCode)) this.onClickClose();
    }
  }

  // eslint-disable-next-line no-unused-vars
  onFadeEnd(ev) {
    const { modal } = this.props;
    const { shown } = this.state;
    const fadingIn = modal.get('shouldShow');

    if (fadingIn && shown !== true) {
      // Finish showing Modal
      this.setState({ shown: true }, () => {
        if (this.modalCloseRef) { this.modalCloseRef.focus(); }
        if (modal.get('draggable')) this.draggable = new Drag(this.modalEl);
      });
    } else if (!fadingIn && shown !== false) {
      // Finish hiding Modal
      this.setState({ shown: false });
      if (this.previousElement) {
        this.previousElement.focus();
      }
    }
  }

  fade() {
    const { modal } = this.props;
    const fadingIn = modal.get('shouldShow');

    if (fadingIn) {
      this.previousElement = document.activeElement;
      document.body.classList.add('modal-open');
    } else {
      if (this.draggable) {
        this.draggable.reset();
        this.draggable = null;
      }

      // Call onClose callback (if provided)
      if (modal.get('onClose')) modal.get('onClose')();

      document.body.classList.remove('modal-open');
    }

    // force redraw to make animation work
    this.el.getClientRects();
    // Wait until it's safe to start the animation
    requestAnimationFrame(() => {
      // Start the animation
      this.setState({ shown: null });
    });
  }

  render() {
    const { modal } = this.props;
    const { shown } = this.state;

    const hasBorders = !!modal.get('title');
    const closable = modal.get('closable');
    const closeButton = closable && modal.get('closeButton') && modal.get('closeLabel');
    const closeX = closable && modal.get('closeX');
    const closeUnderlay = closable && modal.get('closeUnderlay');
    const headerClasses = classNames('modal-header', modal.get('headerClasses'), { 'border-bottom-0': !hasBorders });
    const actionButtons = modal.get('actionButtons');

    let modalSize = '';

    if (modal.get('size') === 'xx large') {
      modalSize = 'modal-xxl';
    } else if (modal.get('size') === 'extra large') {
      modalSize = 'modal-xl';
    } else if (modal.get('size') === 'large') {
      modalSize = 'modal-lg';
    } else if (modal.get('size') === 'small') {
      modalSize = 'modal-sm';
    } else if (modal.get('size') === 'medium') {
      modalSize = 'modal-md';
    }
    const shouldDisplay = shown !== false || modal.get('shouldShow');
    const shouldShow = shown !== false && modal.get('shouldShow');

    let modalBodyClassName = 'modal-body';
    if (modal.get('padding') !== null) modalBodyClassName += ` p-${modal.get('padding')}`;
    if (modal.get('paddingBottom') !== null) modalBodyClassName += ` pb-${modal.get('paddingBottom')}`;

    const roundedClassName = !modal.get('rounded') ? 'rounded-0' : null;
    const borderClassName = modal.get('border') !== null ? `border-${modal.get('border')}` : null;
    const draggableStyles = modal.get('draggable') ? { maxHeight: 'calc(100vh - 3.5rem)' } : {};
    const coverScreenStyles = modal.get('coverScreen') ? { height: 'calc(100vh - 3.5rem)' } : {};

    return (
      <FocusTrap active={shouldDisplay}>
        <div className="Modal" ref={(el) => { this.el = el; }}>
          { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */ }
          <div
            className={`modal fade ${shouldDisplay ? 'd-block' : ''} ${shouldShow ? 'show' : ''}`}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
            role="dialog"
            onKeyUp={ev => this.onKeyUp(ev)}
            onClick={ev => this.onClickClose(ev)}
            ref={(el) => { this.modalEl = el; }}
            onTransitionEnd={shown === null ? ev => this.onFadeEnd(ev) : () => {}}
          >
            { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */ }
            <div
              className={`modal-dialog modal-dialog-centered ${modalSize}`}
              role="document"
              onClick={ev => ev.stopPropagation()}
            >
              <div className={`modal-content ${roundedClassName} ${borderClassName}`} style={{ ...draggableStyles, ...coverScreenStyles }}>
                { (modal.get('title') || (closeX && !modal.get('closeXAbsolute'))) && (
                <div className={headerClasses} style={modal.get('headerStyles')}>
                  { modal.get('title') && (
                    modal.get('titleSize') === 'large' ? <h1 className="modal-title">{ modal.get('title') }</h1> : <h3 aria-level={modal.get('ariaLevel') || 3} className="modal-title">{ modal.get('title') }</h3>
                  )}
                  { (closeX && !modal.get('closeXAbsolute')) && (
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => this.onClickClose()}
                    ref={(el) => { this.modalCloseRef = el; }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  )}
                </div>
                )}
                { (closeX && modal.get('closeXAbsolute')) && (
                <button
                  type="button"
                  className="close close-x-absolute"
                  aria-label="Close"
                  onClick={() => this.onClickClose()}
                  ref={(el) => { this.modalCloseRef = el; }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                )}
                <div className={modalBodyClassName} style={modal.get('draggable') ? { overflowY: 'auto' } : {}}>
                  { shouldDisplay && modal.get('content') }
                </div>
                { (!!actionButtons || !!closeButton) && (
                <div className={`modal-footer ${hasBorders ? '' : 'border-top-0'}`}>
                  { actionButtons }
                  { !!closeButton && (
                  <Button
                    key="close"
                    styleVariant="none"
                    onClick={() => this.onClickClose()}
                  >
                    { modal.get('closeLabel') }
                  </Button>
                  )}
                </div>
                )}
              </div>
            </div>
          </div>
          { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */ }
          <div
            className={`modal-backdrop fade ${shouldDisplay ? '' : 'd-none'} ${shouldShow ? 'show' : ''}`}
            onKeyUp={closeUnderlay ? ev => this.onKeyUp(ev) : null}
            onClick={closeUnderlay ? ev => this.onClickClose(ev) : null}
          />
        </div>
      </FocusTrap>
    );
  }
}

function mapStateToProps({ modal }) {
  return { modal };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      modalClose
    }, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalClass);
