import Factory from 'factories/_Factory';


export default class Specialty extends Factory {
  static jsonType = 'specialty';

  static jsonTypePlural = 'specialties';

  static get defaults() {
    return {
      name: ''
    };
  }

  static get belongsTo() {
    return ['cme_organization'];
  }
}
