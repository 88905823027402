import {
  List
} from 'immutable';

import Factory from 'factories/_Factory';


export default class QuizBlock extends Factory {
  static jsonType = 'quiz_block';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;

    if (attributes.question_ids || newRecord.get('question_ids') === undefined) {
      const questionIds = attributes.question_ids || [];
      newRecord = newRecord.set('question_ids', new List(questionIds));
    }
    return newRecord;
  }

  static get belongsTo() {
    return ['quiz'];
  }

  static get defaults() {
    return {
      total_seconds: 0,
      seconds_remaining: 0,
      position: 0,
      practice_exam_template_id: 0,
    };
  }
}
