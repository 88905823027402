import React from 'react';
import OrangeStar from 'components/icons/OrangeStar';
import Button from "components/inputs/Button";
import PropTypes from 'prop-types';
import PageSection from './PageSection';

function Testimonials({ testimonials }) {
  const starsRow = numberOfStars => (
    <div className="stars-row">
      { [...Array(numberOfStars)].map((_el, i) => (<OrangeStar key={i} />)) }
    </div>
  );

  return (
    <PageSection section="testimonials-section">
      <h3 className="storefront-underlined-title section-title">{"What the medical community is saying".toUpperCase()}</h3>
      {testimonials.map((obj) => {
        const { testimonial, images } = obj;
        return (
          <div className="testimonial" key={testimonial.id}>
            {!!images.length && (
              <div className="picture-container">
                <img loading="lazy" src={images[0].url} alt={images[0].alt_text} className="picture" />
              </div>
            )}
            <div className="text-container">
              <h5><b>{testimonial.testifier_name}</b></h5>
              <h5>{testimonial.testifier_title}</h5>
              {starsRow(5)}
              <p><i>{testimonial.message}</i></p>
              <a className="link" href="/testimonials">Read More Testimonials &gt;</a>
            </div>
          </div>
        );
      })}
      <div className="d-flex justify-content-center">
        <a href="/testimonials" target="_blank">
          <Button>
            Read More Testimonials
          </Button>
        </a>
      </div>
    </PageSection>
  );
}

Testimonials.propTypes = {
  testimonials: PropTypes.array.isRequired
};

export default Testimonials;
