import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredTextZoom(props) {
  return (
    <SvgContainer title="TextZoom Icon" {...props}>
      <defs>
        <style type="text/css">
          {".zoom-st0{fill:#084369}.zoom-st2{fill:#fff}"}
        </style>
      </defs>
      <path className="zoom-st0" d="M22 4h19v25H22z" /><path d="M41 4H6C3.3 4 1 6.2 1 8.9v15.2C1 26.7 3.2 29 6 29h35V4z" fill="#d0d8e9" /><path className="zoom-st0" d="M22 4h19v25H22zM42 29h17.5c1.4 0 2.5-2.3 2.5-4.9V8.9C62 6.2 60.9 4 59.5 4H42v25z" /><path className="zoom-st2" d="M57.7 30H6.3C3.4 30 1 27.6 1 24.7V8.3C1 5.4 3.4 3 6.3 3h51.4C60.6 3 63 5.4 63 8.3v16.3c0 3-2.4 5.4-5.3 5.4zM2 8.4v16.2C2 27 4 29 6.4 29h51.2c2.4 0 4.4-2 4.4-4.4V8.4C62 6 60 4 57.6 4H6.4C4 4 2 6 2 8.4z" /><path className="zoom-st2" d="M22 3h1v26h-1zM41 3h1v26h-1z" /><path className="zoom-st0" d="M11.3 12h1.5l3.1 8h-1.6l-.7-2h-3l-.7 2H8.2l3.1-8zm-.5 5h2.4L12 13.5 10.8 17z" /><path className="zoom-st2" d="M30.9 9h2.2l4.6 12h-2.5l-1-3h-4.5l-1 3h-2.5l4.7-12zm-.7 7h3.6L32 10.8 30.2 16zM50.9 7h2.8l5.8 15h-2.8l-1.4-4h-6.1l-1.4 4H45l5.9-15zm-1.2 9h5l-2.5-7h-.1l-2.4 7z" />
    </SvgContainer>
  );
}
