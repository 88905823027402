import * as React from "react";
import PropTypes from 'prop-types';
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  const { styleVariant } = props;
  const fillColor = styleVariant === 'light' ? '#009AEF' : '#0044DB';
  return (
    <SvgContainer title="Checkbox Selected" width={21} height={21} viewBoxHeight={23} viewBoxWidth={23} {...props}>
      <g fillRule="nonzero" fill="none">
        <rect fill={fillColor} width="21" height="21" rx="5" />
        <path d="M15.818 7.198a.642.642 0 00-.927 0L8.872 13.36 6.12 10.55a.642.642 0 00-.927 0c-.257.263-.257.703 0 .951l3.209 3.294A.728.728 0 008.9 15a.663.663 0 00.47-.205l6.447-6.631a.732.732 0 000-.966z" fill="#FFF" />
      </g>
    </SvgContainer>
  );
}

SvgCheck.propTypes = {
  styleVariant: PropTypes.oneOf([
    'light',
    'none'
  ]),
};

SvgCheck.defaultProps = {
  styleVariant: 'none'
};
