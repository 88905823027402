import StateHelper from 'helpers/StateHelper';
import PracticeExamTemplate from 'factories/PracticeExamTemplate';
import {
  PRACTICE_EXAM_TEMPLATE_FETCHED
} from 'actions/practiceExams';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  QUESTION_BANK_FETCHED,
  QUESTION_BANKS_FETCHED,
  QUESTION_BANK_SELECTED
} from 'actions/questionBanks';

const stateHelper = new StateHelper(PracticeExamTemplate);
export const { initialState } = stateHelper;
const reducers = {};

reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;

reducers[QUESTION_BANK_FETCHED] = stateHelper.set;
reducers[QUESTION_BANKS_FETCHED] = stateHelper.set;
reducers[QUESTION_BANK_SELECTED] = stateHelper.set;

reducers[PRACTICE_EXAM_TEMPLATE_FETCHED] = stateHelper.set;


export default stateHelper.createReducer(reducers);
