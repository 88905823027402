import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCme(props) {
  return (
    <SvgContainer title="CME Icon" {...props}>
      <path d="M6 20.5H3a.5.5 0 000 1h3a.5.5 0 000-1zM4.354 16.646a.5.5 0 10-.707.707l2 2a.5.5 0 00.707-.707zM5.646 22.646l-2 2a.5.5 0 10.707.707l2-2a.5.5 0 00-.707-.707zM29 20.5h-3a.5.5 0 000 1h3a.5.5 0 000-1zM26 19.5a.498.498 0 00.353-.146l2-2a.5.5 0 00-.707-.707l-2 2A.5.5 0 0026 19.5zM26.354 22.646a.5.5 0 00-.707.707l2 2a.5.5 0 00.707-.707zM13.137 22.244a.116.116 0 00-.06-.017.129.129 0 00-.073.024.325.325 0 00-.065.063 1.127 1.127 0 01-.147.167.65.65 0 01-.156.104.708.708 0 01-.176.057 1.268 1.268 0 01-.21.016.666.666 0 01-.375-.116 1.005 1.005 0 01-.307-.34 1.936 1.936 0 01-.208-.549 3.302 3.302 0 01-.076-.746 3.59 3.59 0 01.07-.741 1.924 1.924 0 01.193-.547.972.972 0 01.301-.34.675.675 0 01.384-.116.776.776 0 01.23.03.74.74 0 01.173.073.686.686 0 01.128.094l.098.094a.897.897 0 00.085.073.14.14 0 00.085.029.128.128 0 00.105-.043.471.471 0 00.065-.096l.285-.573a1.837 1.837 0 00-.235-.293 1.465 1.465 0 00-.629-.38 1.34 1.34 0 00-.386-.054 1.375 1.375 0 00-.737.206 1.834 1.834 0 00-.575.577 3.013 3.013 0 00-.373.884 4.49 4.49 0 00-.132 1.123 4.752 4.752 0 00.122 1.111 3.03 3.03 0 00.352.885 1.782 1.782 0 00.557.585 1.338 1.338 0 00.743.212 1.385 1.385 0 00.785-.22 1.664 1.664 0 00.55-.65l-.335-.528a.17.17 0 00-.056-.058zM15.858 21.44q-.047-.154-.096-.296l-.938-2.771a.472.472 0 00-.05-.11.165.165 0 00-.133-.081.915.915 0 00-.097-.004h-.645v5.464h.745v-3.144c0-.072-.001-.151-.004-.238s-.008-.174-.013-.264l.968 2.816a.45.45 0 00.12.199.254.254 0 00.175.067h.114a.254.254 0 00.174-.067.46.46 0 00.122-.2l.967-2.808a6.157 6.157 0 00-.018.495v3.143h.745v-5.464h-.644a.917.917 0 00-.097.004.178.178 0 00-.073.022.168.168 0 00-.058.059.469.469 0 00-.052.11l-.935 2.76c-.035.095-.068.195-.1.3s-.06.213-.088.322a10.671 10.671 0 00-.089-.314zM21.044 22.666h-1.495v-1.302h1.145v-.937h-1.145v-1.275h1.495v-.975h-2.345v5.464h2.345v-.975z" />
      <path d="M23.061 3H8.938A1.44 1.44 0 007.5 4.439V12.5a.468.468 0 00.015.073.498.498 0 00.02.102.485.485 0 00.048.086.48.48 0 00.051.076.495.495 0 00.086.067.49.49 0 00.056.043l3.388 1.695a8 8 0 109.67 0l3.389-1.695a.49.49 0 00.055-.043.495.495 0 00.087-.067.48.48 0 00.05-.076.485.485 0 00.05-.086.498.498 0 00.02-.102.468.468 0 00.015-.073V4.439A1.44 1.44 0 0023.061 3zM17.5 4v9.146a7.779 7.779 0 00-3 0V4zm-9 8.19V4.44A.439.439 0 018.938 4H13.5v9.406a7.964 7.964 0 00-1.372.599zM23 21a7 7 0 11-7-7 7.008 7.008 0 017 7zm-4.5-7.594V4h4.561a.439.439 0 01.439.439v7.752l-3.628 1.814a7.964 7.964 0 00-1.372-.6z" />
    </SvgContainer>
  );
}
