import axios from 'axios';
import Cookies from 'js-cookie';

import Config from 'config';

const { hubspot } = Config;

function hubspotSubmitForm(formId, fields = {}) {
  if (!hubspot.shouldSync) return;

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspot.platformId}/${formId}`;

  const data = {
    fields: Object.keys(fields).map(key => ({
      name: key,
      value: fields[key] || ''
    })),
    context: {
      hutk: Cookies.get('hubspotutk')
    }
  };

  return axios.post(url, data);
}


export const hubspotSubmitLeadCapture = email => () => hubspotSubmitForm(hubspot.signUpFormId, { email });

export const hubspotSubmitNewsletterSignup = email => () => hubspotSubmitForm(hubspot.newsletterFormId, { email });

export const hubspotSubmitCmeCoach = email => (dispatch, getState) => {
  const { cmeCoach, staticData } = getState();

  const fields = {
    email,
    cme_coach_specialty_1: staticData.getIn(['specialties', cmeCoach.get('searchedSpecialtyId'), 'name']) || '',
    cme_coach_state_1: staticData.getIn(['us_states', cmeCoach.get('searchedStateId'), 'name']) || ''
  };

  return hubspotSubmitForm(hubspot.cmeCoachFormId, fields);
};

export const hubspotSubmitInstitutionalLanding = data => hubspotSubmitForm(hubspot.institutionalLandingFormId, data);
