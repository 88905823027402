import React from "react";
import SvgContainer from './SvgContainer';

const PsiComments = props => (
  <SvgContainer title="Comments Icon" {...props}>
    <path d="M15 25.1c-1.2 0-2.2 1-2.2 2.3v.4c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-.4c0-1.3-1-2.3-2.2-2.3zm-.1-21.2c-1.8 0-3.5 1.8-3.3 3.6l1.1 13.6c.1 1.1.9 2 2 2.1 1.2.1 2.4-.9 2.4-2.1l1.1-13.6c.3-1.8-1.3-3.6-3.3-3.6z" opacity=".4" /><path d="M16 24.1c-1.2 0-2.2 1-2.2 2.3v.4c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-.4c0-1.3-1-2.3-2.2-2.3zm-.1-21.2c-1.8 0-3.5 1.8-3.3 3.6l1.1 13.6c.1 1.1.9 2 2 2.1 1.2.1 2.4-.9 2.4-2.1l1.1-13.6c.3-1.8-1.3-3.6-3.3-3.6z" fill="#ed2324" />
  </SvgContainer>
);

export default PsiComments;
