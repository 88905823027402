import React from "react";
import PropTypes from "prop-types";
import SvgContainer from "./SvgContainer";

function PDPInfo (props) {
  const { small, medium } = props;
  if (small) {
    return (
      <SvgContainer name="info" height={94} width={94} viewBoxHeight={94} viewBoxWidth={94} {...props}>
        <circle cx="46.9" cy="46.9" r="46.9" fill="#FFF2DB" />
        <g clipPath="url(#clip0_525_3028)">
          <path d="M46.5248 73.5392C32.0468 73.5392 20.2608 61.7601 20.2608 47.2752C20.2608 32.7904 32.0468 21.0112 46.5248 21.0112C61.0028 21.0112 72.7888 32.7972 72.7888 47.2752C72.7888 61.7532 61.0028 73.5392 46.5248 73.5392ZM46.5248 23.7442C33.5499 23.7442 22.9938 34.3004 22.9938 47.2752C22.9938 60.2501 33.5499 70.8062 46.5248 70.8062C59.4997 70.8062 70.0558 60.2501 70.0558 47.2752C70.0558 34.3004 59.4997 23.7442 46.5248 23.7442Z" fill="#1B3950" />
          <path fillRule="evenodd" clipRule="evenodd" d="M48.4516 60.7762V43.4422C48.4516 42.3763 47.5907 41.5154 46.5248 41.5154C45.4589 41.5154 44.5981 42.3763 44.5981 43.4422V60.7762C44.5981 61.842 45.4589 62.7029 46.5248 62.7029C47.5907 62.7029 48.4516 61.842 48.4516 60.7762Z" fill="#1B3950" />
          <path fillRule="evenodd" clipRule="evenodd" d="M46.5248 31.7587C48.1373 31.7587 49.4491 33.0706 49.4491 34.683C49.4491 36.2955 48.1373 37.6073 46.5248 37.6073C44.9123 37.6073 43.6005 36.2955 43.6005 34.683C43.6005 33.0706 44.9123 31.7587 46.5248 31.7587Z" fill="#1B3950" />
        </g>
        <defs>
          <clipPath id="clip0_525_3028">
            <rect width="52.528" height="52.528" fill="white" transform="translate(20.2608 21.0112)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }
  if (medium) {
    return (
      <SvgContainer name="info" height={96} width={96} viewBoxHeight={96} viewBoxWidth={96} {...props}>
        <circle cx="47.665" cy="47.665" r="47.665" fill="#FFF2DB" />
        <g clipPath="url(#clip0_525_3028)">
          <path d="M47.2837 74.7387C32.5695 74.7387 20.5913 62.7674 20.5913 48.0463C20.5913 33.3252 32.5695 21.3539 47.2837 21.3539C61.9978 21.3539 73.9761 33.3322 73.9761 48.0463C73.9761 62.7605 61.9978 74.7387 47.2837 74.7387ZM47.2837 24.1315C34.0972 24.1315 23.3688 34.8599 23.3688 48.0463C23.3688 61.2328 34.0972 71.9612 47.2837 71.9612C60.4702 71.9612 71.1985 61.2328 71.1985 48.0463C71.1985 34.8599 60.4702 24.1315 47.2837 24.1315Z" fill="#1B3950" />
          <path fillRule="evenodd" clipRule="evenodd" d="M49.2419 61.7675V44.1508C49.2419 43.0676 48.3669 42.1926 47.2837 42.1926C46.2004 42.1926 45.3255 43.0676 45.3255 44.1508V61.7675C45.3255 62.8508 46.2004 63.7257 47.2837 63.7257C48.3669 63.7257 49.2419 62.8508 49.2419 61.7675Z" fill="#1B3950" />
          <path fillRule="evenodd" clipRule="evenodd" d="M47.2837 32.2767C48.9224 32.2767 50.2557 33.61 50.2557 35.2487C50.2557 36.8875 48.9224 38.2207 47.2837 38.2207C45.6449 38.2207 44.3117 36.8875 44.3117 35.2487C44.3117 33.61 45.6449 32.2767 47.2837 32.2767Z" fill="#1B3950" />
        </g>
        <defs>
          <clipPath id="clip0_525_3028">
            <rect width="53.3848" height="53.3848" fill="white" transform="translate(20.5913 21.3539)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }
  return (
    <SvgContainer name="info" height={125} width={125} viewBoxHeight={125} viewBoxWidth={125} {...props}>
      <circle cx="62.5" cy="62.5" r="62.5" fill="#FFF2DB" />
      <g clipPath="url(#clip0_525_3028)">
        <path d="M62 98C42.7063 98 27 82.3028 27 63C27 43.6972 42.7063 28 62 28C81.2937 28 97 43.7063 97 63C97 82.2937 81.2937 98 62 98ZM62 31.642C44.7094 31.642 30.642 45.7094 30.642 63C30.642 80.2906 44.7094 94.358 62 94.358C79.2906 94.358 93.358 80.2906 93.358 63C93.358 45.7094 79.2906 31.642 62 31.642Z" fill="#1B3950" />
        <path fillRule="evenodd" clipRule="evenodd" d="M64.5676 80.9917V57.892C64.5676 56.4716 63.4204 55.3244 62 55.3244C60.5796 55.3244 59.4324 56.4716 59.4324 57.892V80.9917C59.4324 82.4121 60.5796 83.5593 62 83.5593C63.4204 83.5593 64.5676 82.4121 64.5676 80.9917Z" fill="#1B3950" />
        <path fillRule="evenodd" clipRule="evenodd" d="M62 42.3223C64.1488 42.3223 65.897 44.0705 65.897 46.2193C65.897 48.3681 64.1488 50.1163 62 50.1163C59.8512 50.1163 58.103 48.3681 58.103 46.2193C58.103 44.0705 59.8512 42.3223 62 42.3223Z" fill="#1B3950" />
      </g>
      <defs>
        <clipPath id="clip0_525_3028">
          <rect width="70" height="70" fill="white" transform="translate(27 28)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
}

PDPInfo.propTypes = { small: PropTypes.bool, medium: PropTypes.bool };

export default PDPInfo;
