import React from "react";
import SvgContainer from './SvgContainer';

function AllPlansCheckMark (props) {
  return (
    <SvgContainer name="all plans checkmark" {...props}>
      <path d="M4.78809 11.3711L7.18208 14.3636" stroke="#3E8A3D" strokeWidth="2" strokeLinecap="round" />
      <path d="M7.18262 14.3635L14.3646 6.58301" stroke="#3E8A3D" strokeWidth="2" strokeLinecap="round" />
      <circle cx="9.87523" cy="9.87523" r="8.87523" stroke="#3E8A3D" strokeWidth="2" />
    </SvgContainer>
  );
}

export default AllPlansCheckMark;
