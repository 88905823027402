import { List, Set, Map } from 'immutable';

import Factory from 'factories/_Factory';
import Image from 'factories/Image';
import Answer from 'factories/Answer';
import Resource from 'factories/Resource';
import DentalTemplate from 'factories/DentalTemplate';
import Video from 'factories/Video';


export default class Question extends Factory {
  static jsonType = 'question';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;

    if (attributes.answers || newRecord.get('answers') === undefined) {
      const answers = (json.attributes.answers || []).map(answer => new Answer(answer));
      newRecord = newRecord.set('answers', new List(answers));
    }

    if (attributes.answers || newRecord.get('correct_answer_ids') === undefined) {
      const correctAnswerIds = (json.attributes.answers || [])
        .filter(answer => answer.attributes.is_correct)
        .map(answer => parseInt(answer.id));
      newRecord = newRecord.set('correct_answer_ids', new Set(correctAnswerIds));
    }

    if (attributes.dental_template) {
      newRecord = newRecord.set('dental_template', new DentalTemplate(attributes.dental_template));
    }

    if (attributes.relationships) {
      newRecord = newRecord.set('aact_relationships', new List(attributes.relationships));
    }

    if (attributes.images || newRecord.get('images') === undefined) {
      const images = (attributes.images || []).map(image => new Image(image));
      newRecord = newRecord.set('images', new List(images));
    }

    if (attributes.videos || newRecord.get('videos') === undefined) {
      const videos = (attributes.videos || []).map(video => new Video(video));
      newRecord = newRecord.set('videos', new List(videos));
    }

    if (attributes.resources || newRecord.get('resources') === undefined) {
      const resources = (attributes.resources || []).map(resource => new Resource(resource));
      newRecord = newRecord.set('resources', new List(resources));
    }

    if (attributes.explanation_images || newRecord.get('explanation_images') === undefined) {
      const images = (attributes.explanation_images || []).map(image => new Image(image));
      newRecord = newRecord.set('explanation_images', new List(images));
    }

    if (attributes.explanation_videos || newRecord.get('explanation_videos') === undefined) {
      const videos = (attributes.explanation_videos || []).map(video => new Video(video));
      newRecord = newRecord.set('explanation_videos', new List(videos));
    }

    if (attributes.group_question_ids || newRecord.get('group_question_ids') === undefined) {
      const groupQuestionIds = attributes.group_question_ids || [];
      newRecord = newRecord
        .set('group_question_ids', new List(groupQuestionIds))
        .set('group_size', groupQuestionIds.length)
        .set('group_index', groupQuestionIds.indexOf(parseInt(json.id)));
    }

    if (attributes.matrix) {
      newRecord = newRecord.set('matrix', new Map(JSON.parse(attributes.matrix)));
    }

    if (attributes.type === "HighlightTextQuestion") {
      let toStrip = attributes.plain_name;
      attributes.answers.forEach((a) => {
        toStrip = toStrip.replace(`${a.id}`, a.attributes.plain_name);
      });
      newRecord = newRecord.set('computed_name', toStrip.replace(/[{}]/g, "").replace(/\[.*?\]/g, ""));
    }

    if (attributes.aact_type) {
      newRecord = newRecord.set('aact_type', attributes.aact_type);
      newRecord = newRecord.set('aact_item_choices', attributes.aact_item_choices);
      newRecord = newRecord.set('aact_rational_data', attributes.aact_rational_data);
      newRecord = newRecord.set('aact_sanitize_data', attributes.aact_sanitize_data);
    }
    return newRecord;
  }

  static get defaults() {
    return {
      type: '',
      safe_name: '',
      plain_name: '',
      safe_reference: '',
      plain_reference: '',
      video_html: '',
      video_url: '',
      video_height: 0,
      video_width: 0,
      video_download_url: '',
      video_streaming_url: '',
      group_id: 0,
      p_value: 0,
      peer_response_count: 0,
      difficulty_level: null,
      safe_explanation: '',
      plain_explanation: '',
      matrix: {},
      computed_name: '',
      lead_in: '',
      ngnType: false,
    };
  }

  static get hasMany() {
    return ['subject'];
  }
}
