import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from 'components/SVGIcon';
import Button from 'components/inputs/Button';
import Select from 'components/inputs/Select';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import { formatString } from "./homePageHelpers";

export default function HomeHeroSection({ occupationsAndSpecialties, heroTextContent }) {
  const [cmeFilter, setCmeFilter] = useState(false);
  const [occupationFilter, setOccupationFilter] = useState(null);
  const [specialtyFilter, setSpecialtyFilter] = useState(null);
  const [submit, setSubmit] = useState(false);
  const { width } = useWindowDimensions();

  const isSm = width < breakpoints.md;
  const isMd = width <= breakpoints.md && width > breakpoints.sm;
  const isLg = width < 1200 && width > breakpoints.md;
  const isLgrOrXl = width >= 1200;

  const { heroTitle, heroDescription } = heroTextContent;

  useEffect(() => {
    setOccupationFilter(null);
    setSpecialtyFilter(null);
    setSubmit(false);
  }, [cmeFilter]);

  useEffect(() => {
    setSpecialtyFilter(null);
    setSubmit(false);
  }, [occupationFilter]);


  const filteredOccupations = () => occupationsAndSpecialties.occupations;

  const filteredSpecialties = () => {
    if (cmeFilter) {
      return occupationsAndSpecialties.cme;
    }
    if (!occupationFilter) {
      return occupationsAndSpecialties.specialties;
    }
    const selectedOccupation = occupationsAndSpecialties.occupations.find(oc => oc.value === occupationFilter);
    return selectedOccupation?.pages;
  };


  const routeChange = (ev) => {
    ev.preventDefault();
    setSubmit(true);
    if (!specialtyFilter) {
      return;
    }
    let path = "/".concat(specialtyFilter);
    if (occupationFilter) {
      path = path.concat(`?occupation-select=${occupationFilter}`);
    } else {
      path = path.concat("?occupation-select=Select+Occupation");
    }
    window.location.href = path;
  };

  const selectionBox = classes => (
    <div className={`selection-box shadow p-6 bg-white rounded border border-1 border-gray ${classes}`}>
      <div className="row">
        <h3 className="storefront col text-left">Get started today and gain exam-ready confidence</h3>
        <a className="d-none d-lgr-block storefront col text-right" href="/institutional">Institutional Packages</a>
      </div>
      <div className="row">
        <p className={`storefront mt-2 col${cmeFilter ? '-3' : ''} d-none d-lgr-block`}>Select Product</p>
        {!specialtyFilter && submit && <p className="d-none d-lgr-block storefront col mt-2 d-none d-lgr-block text-left text-red">Please make a selection</p>}
      </div>
      { isLgrOrXl && (
      <div className="d-none d-lgr-block">
        <div className="row">
          <div className="d-none d-lgr-inline-flex col btn-group btn-block">
            <Button styleVariant="light" onClick={() => setCmeFilter(!cmeFilter)} active={!cmeFilter} className={`large-button border-1 shadow-none ${cmeFilter ? 'bg-white' : 'font-weight-bold'}`}>Exam Prep</Button>
            <Button styleVariant="light" onClick={() => setCmeFilter(!cmeFilter)} active={cmeFilter} className={`large-button border-1 shadow-none ${!cmeFilter ? 'bg-white' : 'font-weight-bold'}`}>CME</Button>
          </div>
          {!cmeFilter && <div className="d-none d-lgr-block separator ml-auto mr-auto bg-gray" />}
          {!cmeFilter && <Select className="d-none d-lgr-block col-3" buttonClass="large-button border-1 shadow-none" truncateText styleVariant="light" name="Select Occupation" placeholder="Select Occupation" required scrollable options={filteredOccupations()} value={occupationFilter} onChange={setOccupationFilter} />}
          <div className="d-none d-lgr-block separator ml-auto mr-auto bg-gray" />
          <Select className={`d-none d-lgr-block col-${!cmeFilter ? '3' : '6'}`} buttonClass={`large-button border-1 shadow-none ${!specialtyFilter && submit ? 'border-red' : ''}`} truncateText styleVariant="light" name="Select Specialty/Exam" placeholder="Select Specialty/Exam" required scrollable options={filteredSpecialties()} value={specialtyFilter} onChange={setSpecialtyFilter} />
          <Button className="large-button d-none d-lgr-block col-3 ml-3 font-weight-bold" onClick={routeChange}>Find My Question Bank</Button>
        </div>
      </div>
      )}
      <div className="d-block d-lgr-none d-xl-none row">
        <div className="col btn-group mt-3 btn-block">
          <Button styleVariant="light" onClick={() => setCmeFilter(!cmeFilter)} active={!cmeFilter} className={`border-1 shadow-none ${cmeFilter ? 'bg-white' : 'font-weight-bold'}`}>Exam Prep</Button>
          <Button styleVariant="light" onClick={() => setCmeFilter(!cmeFilter)} active={cmeFilter} className={`border-1 shadow-none ${!cmeFilter ? 'bg-white' : 'font-weight-bold'}`}>CME</Button>
        </div>
        {!cmeFilter && <Select className="col mt-4" buttonClass="border-1 shadow-none" truncateText styleVariant="light" name="Select Occupation" placeholder="Select Occupation" required scrollable options={filteredOccupations()} value={occupationFilter} onChange={setOccupationFilter} />}
        <Select className="col mt-4" buttonClass={`border-1 shadow-none ${!specialtyFilter && submit ? 'border-red' : ''}`} truncateText styleVariant="light" name="Select Specialty/Exam" placeholder="Select Specialty/Exam" required scrollable options={filteredSpecialties()} value={specialtyFilter} onChange={setSpecialtyFilter} />
        <div className="col mt-5 px-4">
          <Button className="btn-block font-weight-bold" onClick={routeChange}>Find My Question Bank</Button>
        </div>
      </div>
    </div>
  );

  const renderSmallScreen = () => (
    <>
      <div className="mx-0 px-0 d-sm-block d-md-none d-lg-none d-xl-none hero-image-bg" role="img" aria-label="The Standard in Board Exam Prep & CME">
        <SVGIcon className="ml-5 mt-3" name="PrepToPractice" height={127.957} width={123.215} viewBoxHeight={135.957} viewBoxWidth={135.215} />
      </div>
      <div className="d-sm-block d-md-none d-lg-none d-xl-none row align-items-start mx-0 px-0">
        <div className="col px-0 mx-0">
          <h1
            className="storefront title text-navy"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: formatString(heroTitle) || "The Standard in Healthcare Board Exam Prep & CME" }}
          />
          {heroDescription ? (
            /* eslint-disable-next-line react/no-danger */
            <p className="storefront subtitle" dangerouslySetInnerHTML={{ __html: formatString(heroDescription) }} />
          ) : (
            <p className="storefront subtitle"><span className="bg-light-orange">Join the 1.5M+ practitioners   </span>who've trusted BoardVitals online practice questions to prepare for their board exams and earn CME credits.</p>
          )}
          {selectionBox('mb-6')}
        </div>
      </div>
    </>
  );

  return (
    <div className="home-section-wrapper">
      <div className="container-xl px-0">
        <div className="HeroSection pb-sm-6 pb-md-0 mx-0 px-0">
          {isSm && renderSmallScreen()}
          <div className="mx-0 px-0 d-none d-md-block d-lg-block d-xl-block hero-image-bg" role="img" aria-label="The Standard in Board Exam Prep & CME">
            <div className="row align-items-center mx-0 px-0">
              <div className="col-md-6 col-lg-7 col-lgr-5 col-xl-5 px-0 mx-0 justify-content-center">
                <h1
                  className="storefront title text-navy"
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: formatString(heroTitle) || "The Standard in Healthcare Board Exam Prep & CME" }}
                />
                {heroDescription ? (
                  /* eslint-disable-next-line react/no-danger */
                  <p className="storefront subtitle" dangerouslySetInnerHTML={{ __html: formatString(heroDescription) }} />
                ) : (
                  <p className="storefront subtitle"><span className="bg-light-orange">Join the 1.5M+ practitioners   </span>who've trusted BoardVitals online practice questions to prepare for their board exams and earn CME credits.</p>
                )}
                {(isMd || isLg) && selectionBox('d-none d-sm-none d-md-block d-lg-block d-lgr-none d-xl-none')}
              </div>
              <div className="align-self-start col-lgr-1 d-none d-lgr-block logo px-0">
                <SVGIcon name="PrepToPractice" height={150} width={140} viewBoxHeight={135.957} viewBoxWidth={135.215} />
              </div>
              <div
                className="col-4 d-none d-md-block hero-image"
              />
            </div>
            {selectionBox('d-none d-sm-none d-md-none d-lg-none d-lgr-block d-xl-block')}
          </div>
        </div>
      </div>
    </div>

  );
}

HomeHeroSection.propTypes = {
  occupationsAndSpecialties: PropTypes.object
};
