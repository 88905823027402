import Factory from 'factories/_Factory';


export default class PatientTabContent extends Factory {
  static jsonType = 'patient_tab_content';

  static get defaults() {
    return {
      content: '',
      question_id: '',
      patient_tab_id: '',
    };
  }
}
