import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeReasonClearerUnderstanding(props) {
  return (
    <SvgContainer title="Clearer Understanding" {...props}>
      <circle cx="63.5" cy="63.5" r="63.5" fill="#FFF2DB" />
      <g fill="#1B3950">
        <path d="M65.722 86.686H37.376a3.157 3.157 0 0 1-3.15-3.146V70.957H54.7c6.079 0 11.023 4.939 11.023 11.01v4.72ZM37.376 74.103v9.437h25.197v-1.573c0-4.341-3.528-7.864-7.874-7.864H37.376ZM64.148 42.644a1.559 1.559 0 0 1-1.575-1.573c0-4.341-3.528-7.865-7.874-7.865H37.376v7.865c0 .88-.693 1.573-1.575 1.573a1.559 1.559 0 0 1-1.574-1.573v-7.865a3.157 3.157 0 0 1 3.15-3.146h17.322c6.079 0 11.023 4.94 11.023 11.011 0 .88-.693 1.573-1.574 1.573Z" />
        <path d="M90.924 86.686H62.578v-4.719c0-6.071 4.945-11.01 11.024-11.01h20.472V83.54a3.157 3.157 0 0 1-3.15 3.146ZM65.728 83.54h25.196v-9.437H73.602c-4.347 0-7.874 3.523-7.874 7.864v1.573ZM92.5 42.644a1.559 1.559 0 0 1-1.576-1.573v-7.865H73.602c-4.347 0-7.874 3.524-7.874 7.865 0 .88-.693 1.573-1.575 1.573a1.559 1.559 0 0 1-1.575-1.573c0-6.071 4.945-11.01 11.024-11.01h17.322a3.157 3.157 0 0 1 3.15 3.145v7.865c0 .88-.693 1.573-1.575 1.573ZM64.153 69.384a1.559 1.559 0 0 1-1.575-1.573V48.936c0-.881.693-1.573 1.575-1.573s1.575.692 1.575 1.573V67.81c0 .88-.693 1.573-1.575 1.573ZM35.801 74.103a1.559 1.559 0 0 1-1.574-1.573V41.07c0-.88.693-1.573 1.574-1.573.882 0 1.575.692 1.575 1.573V72.53c0 .88-.693 1.573-1.575 1.573Z" />
        <path d="M92.497 74.103a1.559 1.559 0 0 1-1.575-1.573V41.07c0-.88.693-1.573 1.575-1.573s1.575.692 1.575 1.573V72.53c0 .88-.694 1.573-1.575 1.573ZM51.55 80.394H35.8a1.559 1.559 0 0 1-1.574-1.573c0-.88.693-1.572 1.574-1.572H51.55c.882 0 1.575.692 1.575 1.573 0 .88-.693 1.572-1.575 1.572ZM92.495 80.394H76.747a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.572 1.575-1.572h15.748c.881 0 1.574.692 1.574 1.573 0 .88-.693 1.572-1.574 1.572Z" />
        <path d="M54.702 95.935H27.931c-1.732 0-3.15-1.447-3.15-3.24V42.738c0-1.793 1.418-3.24 3.15-3.24h7.874c.882 0 1.575.692 1.575 1.573 0 .88-.693 1.573-1.575 1.573H27.93v50.05l26.771.095c.882 0 1.575.692 1.575 1.573 0 .88-.693 1.573-1.575 1.573ZM100.37 95.935H73.598a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.573 1.575-1.573h26.772v-50.05l-7.874-.095a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.573 1.575-1.573h7.874c1.732 0 3.149 1.447 3.149 3.24v49.957c0 1.793-1.417 3.24-3.149 3.24Z" />
        <path d="M70.448 99.27H57.849c-2.614 0-4.724-2.203-4.724-4.908 0-.88.693-1.573 1.575-1.573s1.575.692 1.575 1.573c0 .975.693 1.762 1.574 1.762h12.599c.882 0 1.575-.787 1.575-1.762 0-.88.692-1.573 1.574-1.573.882 0 1.575.692 1.575 1.573 0 2.705-2.11 4.907-4.724 4.907ZM54.703 44.217h-9.449a1.559 1.559 0 0 1-1.574-1.573c0-.88.693-1.573 1.575-1.573h9.448c.882 0 1.575.692 1.575 1.573 0 .88-.693 1.573-1.575 1.573ZM54.703 53.654h-9.449a1.559 1.559 0 0 1-1.574-1.573c0-.88.693-1.573 1.575-1.573h9.448c.882 0 1.575.693 1.575 1.573 0 .881-.693 1.573-1.575 1.573ZM54.703 63.092h-9.449a1.559 1.559 0 0 1-1.574-1.573c0-.88.693-1.573 1.575-1.573h9.448c.882 0 1.575.692 1.575 1.573s-.693 1.573-1.575 1.573ZM83.047 44.217h-9.449a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.573 1.575-1.573h9.449c.882 0 1.575.692 1.575 1.573 0 .88-.693 1.573-1.575 1.573ZM83.047 53.654h-9.449a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.573 1.575-1.573h9.449c.882 0 1.575.693 1.575 1.573 0 .881-.693 1.573-1.575 1.573ZM83.047 63.092h-9.449a1.559 1.559 0 0 1-1.575-1.573c0-.88.693-1.573 1.575-1.573h9.449c.882 0 1.575.692 1.575 1.573s-.693 1.573-1.575 1.573Z" />
      </g>
    </SvgContainer>
  );
}
