import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueFlagActive(props) {
  return (
    <SvgContainer title="Flag Active Icon" {...props}>
      <path d="M51.07 54.8a4.71 4.71 0 0 1-2.83 1.76 4.76 4.76 0 0 1-4.62-1.86 9.44 9.44 0 0 1-1.28-4.51l-1.51-14.1c-1.74-.32-3.8-.56-5 .7a6.25 6.25 0 0 0-1 1.67 13.9 13.9 0 0 1-6.67 6.69 10.79 10.79 0 0 1-8.13.09c-1.47-.59-3-1.53-4.53-1a3.42 3.42 0 0 0-2 2.75 10.13 10.13 0 0 0 .3 3.54 6.3 6.3 0 0 1-4.56-5.13 11.37 11.37 0 0 1 1.33-7 9.84 9.84 0 0 1 3.89-4.44c1.68-.9 3.71-1 5.29-2.1 2-1.32 2.85-3.76 3.45-6.06.69-2.6 1.35-5.63 3.58-7.37A16.7 16.7 0 0 1 35 15a4.17 4.17 0 0 0 2.44-.75c1.23-1.13.47-3.12.48-4.79a4.68 4.68 0 0 1 8.77-2.2 9.36 9.36 0 0 1 .87 3.52l4.37 39.08a9.31 9.31 0 0 1 0 3.33 4.66 4.66 0 0 1-.86 1.61Z" fill="#fff" />
      <path d="M47.43 57.13A5.23 5.23 0 0 1 43.22 55a9.86 9.86 0 0 1-1.38-4.75l-1.47-13.73c-1.65-.28-3.26-.36-4.23.62a5.2 5.2 0 0 0-.84 1.33l-.1.21a14.46 14.46 0 0 1-6.93 6.93 11.22 11.22 0 0 1-8.5.09c-.27-.1-.53-.22-.8-.34-1.2-.52-2.34-1-3.38-.64a2.91 2.91 0 0 0-1.69 2.36 9.73 9.73 0 0 0 .3 3.36l.16.74-.75-.18a6.83 6.83 0 0 1-5-5.53A11.78 11.78 0 0 1 10 38.15a10.15 10.15 0 0 1 4.1-4.66 13.9 13.9 0 0 1 2.51-1 10.2 10.2 0 0 0 2.75-1.13c1.84-1.23 2.67-3.6 3.24-5.76l.13-.5c.66-2.54 1.4-5.41 3.62-7.15a17 17 0 0 1 8.54-3.52h.2a3.36 3.36 0 0 0 2-.6c.7-.63.57-1.74.44-2.9a13 13 0 0 1-.12-1.52A5.17 5.17 0 0 1 47.11 7a10 10 0 0 1 .89 3.7l4.37 39.07a9.66 9.66 0 0 1 0 3.51 4.85 4.85 0 0 1-.9 1.82 5.17 5.17 0 0 1-3.13 1.95 4.56 4.56 0 0 1-.91.08Zm-8.91-21.8a13.37 13.37 0 0 1 2.4.27l.37.07 1.55 14.46A9 9 0 0 0 44 54.41a4.27 4.27 0 0 0 4.12 1.66 4.21 4.21 0 0 0 2.52-1.58 4 4 0 0 0 .75-1.49 8.77 8.77 0 0 0 0-3.15L47 10.81a8.91 8.91 0 0 0-.81-3.34 4.17 4.17 0 0 0-7.83 2 11.6 11.6 0 0 0 .11 1.39c.15 1.3.32 2.78-.75 3.76a4.28 4.28 0 0 1-2.52.86H35a16.09 16.09 0 0 0-8 3.32c-1.95 1.53-2.66 4.23-3.28 6.61l-.13.5c-.61 2.34-1.53 4.93-3.65 6.34a10.82 10.82 0 0 1-3 1.26 12.2 12.2 0 0 0-2.33.88 9.3 9.3 0 0 0-3.68 4.21 10.77 10.77 0 0 0-1.28 6.73 6 6 0 0 0 3.41 4.5 8.5 8.5 0 0 1-.13-2.9 3.87 3.87 0 0 1 2.33-3.14c1.42-.51 2.85.12 4.12.67l.77.32a10.22 10.22 0 0 0 7.74-.08 13.43 13.43 0 0 0 6.43-6.46l.1-.21a5.84 5.84 0 0 1 1-1.58 4.12 4.12 0 0 1 3.1-1.12Z" />
      <path d="M47.58 53.88a.9.9 0 0 1-.23 0 2 2 0 0 1-2-1.81l-2-18.22c-1.82-.5-8.49-2.1-10.35 2.15 0 0-4.42 11.12-13.85 5.81 0 0-6.36-2.05-7.61 2.81 0 0 .77-8.15 5.87-9 4.32-.73 7.26-4.39 8.2-8.52 1-4.59 1.87-7.38 7-8.81 2-.54 4.25-.8 6.05-1.71a14.32 14.32 0 0 0 2.49-2.42l-.49-4.41a2 2 0 1 1 4-.45l4.74 42.29a2 2 0 0 1-1.82 2.29Z" />
    </SvgContainer>
  );
}
