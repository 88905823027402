import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

import Button from "components/inputs/Button";
import SVGIcon from "components/SVGIcon";

import { svgProps } from 'components/icons/SvgContainer';


// I considered just adding a `dropdownOptions` prop to the <Button> component
// but the dropdown requires the parent <div> added to its markup.

const ButtonDropdown = (props) => {
  const {
    options,
    children,
    styleVariant,
    className,
    large,
    small,
    icon,
    iconProps,
    xClose,
    caretToRight,
    disabled
  } = props;

  const [showDrop, toggleDropdown] = useState(false);
  const dropDownClass = classNames("dropdown-menu", { show: showDrop });

  const handleFocusIn = () => {
    if (!disabled) {
      toggleDropdown(true);
    }
  };

  return (
    <div
      style={{ pointerEvents: disabled ? 'none' : 'all' }}
      className={`ButtonDropdown dropdown ${className}`}
      onMouseOver={handleFocusIn}
      onFocus={handleFocusIn}
      onMouseOut={() => toggleDropdown(false)}
      onBlur={() => toggleDropdown(false)}
    >
      <Button
        large={large}
        small={small}
        icon={icon}
        iconProps={iconProps}
        styleVariant={styleVariant}
        caretToRight={caretToRight}
        disabled={disabled}
      >
        {children}
        <div className={`btn-caret${caretToRight ? ' pr-2' : ''}`} />
        <SVGIcon name="DownCaret1" width={12} height={12} />
      </Button>
      <div className={dropDownClass}>
        {Object.keys(options).map(key => (
          <button key={key} type="button" className="dropdown-item" href="#" onClick={options[key]} onTouchEnd={options[key]}>
            {!xClose ? key
              : (
                <div className="d-flex justify-content-between" aria-label="Remove">
                  <span className="pr-4">{key}</span><span>X</span>
                </div>
              )
          }
          </button>
        ))}
      </div>
    </div>
  );
};

ButtonDropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.func),
  styleVariant: PropTypes.oneOf([
    'primary',
    'light',
    'outline-primary',
    'outline-light',
    'success',
    'danger'
  ]),
  large: PropTypes.bool,
  small: PropTypes.bool,
  icon: PropTypes.string,
  iconProps: PropTypes.shape({ ...svgProps }),
  xClose: PropTypes.bool,
  caretToRight: PropTypes.bool,
  disabled: PropTypes.bool
};

ButtonDropdown.defaultProps = {
  className: '',
  large: false,
  small: false,
  iconProps: { height: 16, width: 16 },
  caretToRight: false,
  disabled: false
};

export default ButtonDropdown;
