import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPrevious(props) {
  return (
    <SvgContainer title="Previous Icon" {...props}>
      <path fill="#3589c3" d="M29.8 7.9v16.9L3.9 16.3z" /><path d="M1.1 16.3l29.6-9.6V26L1.1 16.3zm5.5 0L29 23.6V9L6.6 16.3z" fill="#fff" />
    </SvgContainer>
  );
}
