import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// StyleGuide = elements/global/badge

const Badge = ({
  className, children, styleVariant, pill
}) => {
  const getBadgeClasses = () => (
    classNames(`badge badge-${styleVariant} ${className}`, {
      'badge-pill': pill,
      'border border-grey': styleVariant === 'light'
    })
  );
  return (
    <span className={getBadgeClasses(styleVariant)}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  styleVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'light',
    'dark',
    'danger',
    'warning',
    'success',
    'none',
    'quiz-list-complete',
    'quiz-list-incomplete',
    'quiz-list-new',
    'quiz-list-disabled',
    'assignment-template',
    'quiz-list-hidden-assignment-submitted'
  ]),
  pill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

Badge.defaultProps = {
  styleVariant: 'complete'
};

export default Badge;
