import StateHelper from 'helpers/StateHelper';
import BoardExamDate from 'factories/BoardExamDate';

import { BOARD_EXAM_DATES_FETCHED } from 'actions/boardExamDates';

const stateHelper = new StateHelper(BoardExamDate);
export const { initialState } = stateHelper;
const reducers = {};

reducers[BOARD_EXAM_DATES_FETCHED] = function (state, payload) {
  return state.withMutations((newState) => {
    Object.entries(payload).forEach((exam) => {
      const bed = exam[1];

      if (typeof bed === 'object') {
        newState.set(`${bed.id}`, new BoardExamDate({
          id: `${bed.id}`,
          attributes: {
            user_id: bed.user_id,
            exam_date: bed.exam_date,
            follow_up: bed.follow_up,
            postponed: bed.postponed,
            first_quiz_completed: bed.first_quiz_completed,
            question_bank_id: bed.question_bank_id,
            study_frequency: bed.study_frequency,
            updated_at: bed.updated_at
          },
          relationships: {
            question_bank: {
              data: {
                id: bed.question_bank_id
              }
            },
            user: {
              data: {
                id: bed.user_id
              }
            },
          }
        }));
      }
    });
  });
};

export default stateHelper.createReducer(reducers);
