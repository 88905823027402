import React from "react";
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/SVGIcon';

export default function HomeTestimonials({ testimonials }) {
  const starsSection = () => {
    const iconProps = { height: 22, width: 22, className: "mr-2" };
    return (
      <div className="stars mt-3 mb-4 pb-2 mb-lg-5 pb-lg-0">
        { [...Array(5)].map((_el, index) => (
          <SVGIcon key={index} name="OrangeStar" {...iconProps} />
        ))}
      </div>
    );
  };

  return (
    <div className="HomeTestimonials home-section-wrapper m-0 pt-0 pb-0 pb-lg-6">
      <div className="container pt-0 pb-6 pt-lg-6">
        <div className="col col-12 text-left text-lg-center py-0 py-lg-6 px-3 px-md-4 px-lg-0">
          <h2 className="storefront small section-title my-0 my-lg-6 py-6 ">WHAT THE MEDICAL COMMUNITY IS SAYING</h2>
        </div>
        <div className="row d-flex flex-wrap justify-content-center px-5 px-mb-6 px-lg-6">
          { testimonials?.map((testimonial, index) => (
            <div key={index} className="col col-12 col-lg-4 px-0 px-lg-5 pb-lg-6 mb-lg-6">
              <div
                className={`bg-white text-left px-6 py-5 py-lg-6 testimonial-card ${!testimonial.homepage_mobile && 'd-none d-lg-block d-xl-block'}`}
                key={index}
              >
                <h5 className="font-weight-bold truncate truncate__title">{testimonial.testifier_name}</h5>
                <h5 className="truncate truncate__title">{testimonial.testifier_title}</h5>
                { starsSection() }
                <div className="truncate truncate__message">
                  {testimonial.message}
                </div>
                <div className="py-4 d-lg-none d-xl-none d-sm-block d-md-block">
                  <a href="/testimonials" className="more-link font-weight-bold">
                    Read More Testimonials &gt;
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="py-6 d-none d-lg-block d-xl-block text-center">
          <a href="/testimonials" className="more-btn-section p-4 font-weight-bold btn btn-primary text-center">
            Read More Testimonials
          </a>
        </div>
      </div>
    </div>
  );
}

HomeTestimonials.propTypes = {
  testimonials: PropTypes.array
};
