import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeReasonPassRates(props) {
  return (
    <SvgContainer title="Pass Rates" {...props}>
      <circle cx="63.5" cy="63.5" r="63.5" fill="#FFF2DB" />
      <g fill="#1B3950">
        <path d="M80.242 102.43c-9.574 0-17.383-7.823-17.383-17.414 0-9.591 7.81-17.414 17.383-17.414 9.574 0 17.382 7.823 17.382 17.414 0 9.591-7.808 17.414-17.382 17.414Zm0-32.149c-8.103 0-14.708 6.617-14.708 14.735 0 8.117 6.605 14.735 14.708 14.735S94.95 93.133 94.95 85.016c0-8.118-6.605-14.735-14.708-14.735Z" />
        <path d="M76.232 93.053c-.481 0-.962-.268-1.203-.75l-2.674-5.358c-.321-.67-.054-1.474.588-1.795.669-.322 1.47-.054 1.792.59l2.674 5.357c.321.67.053 1.474-.588 1.795a1.18 1.18 0 0 1-.589.134v.027Z" />
        <path d="M76.233 93.053c-.294 0-.588-.107-.829-.295a1.33 1.33 0 0 1-.214-1.875l10.697-13.395c.455-.59 1.31-.67 1.872-.215a1.33 1.33 0 0 1 .214 1.876L77.276 92.544c-.267.322-.642.51-1.043.51ZM58.845 64.923c-.348 0-.695-.134-.936-.402a1.328 1.328 0 0 1 0-1.902L84.65 35.855a1.322 1.322 0 0 1 1.9 0 1.328 1.328 0 0 1 0 1.902L59.78 64.521a1.342 1.342 0 0 1-.936.402ZM32.103 78.318c-.348 0-.696-.133-.936-.401a1.328 1.328 0 0 1 0-1.903L55.235 51.93a1.322 1.322 0 0 1 1.899 0 1.328 1.328 0 0 1 0 1.902L33.039 77.917a1.342 1.342 0 0 1-.936.401Z" />
        <path d="M58.847 64.923c-.588 0-1.15-.402-1.31-1.018l-2.674-10.716c-.188-.724.267-1.447.962-1.634.696-.188 1.444.267 1.632.964l2.674 10.716c.187.724-.268 1.447-.963 1.635a1.339 1.339 0 0 1-.32.053ZM85.587 48.849c-.749 0-1.337-.59-1.337-1.34V36.793c0-.75.588-1.34 1.337-1.34s1.337.59 1.337 1.34v10.716c0 .75-.588 1.34-1.337 1.34Z" />
        <path d="M85.589 38.132H74.892c-.749 0-1.337-.589-1.337-1.34 0-.75.588-1.339 1.337-1.339h10.697c.749 0 1.337.59 1.337 1.34 0 .75-.588 1.34-1.337 1.34ZM32.103 86.356c-.749 0-1.337-.59-1.337-1.34V36.793c0-.75.588-1.34 1.337-1.34.748 0 1.337.59 1.337 1.34v48.223c0 .75-.588 1.34-1.337 1.34Z" />
        <path d="M58.845 86.356H32.103c-.749 0-1.337-.59-1.337-1.34 0-.75.588-1.34 1.337-1.34h26.742c.749 0 1.337.59 1.337 1.34 0 .75-.588 1.34-1.337 1.34Z" />
      </g>
    </SvgContainer>
  );
}
