import React from "react";
import PropTypes from "prop-types";
import SvgContainer from "./SvgContainer";

export default function Correct(props) {
  const { xs, small, medium } = props;

  if (xs) {
    return (
      <SvgContainer
        height={77}
        width={77}
        viewBoxHeight={77}
        viewBoxWidth={77}
        name="correct"
        {...props}
      >
        <svg
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39.1837" cy="38.6842" r="38.6842" fill="#FFF2DB" />
          <path
            d="M42.5726 23.3356C42.2563 22.9741 41.7141 22.9741 41.3526 23.3356L38.3705 26.2725L36.9699 24.8718C36.6084 24.5555 36.0662 24.5555 35.7499 24.8718C35.4336 25.1881 35.4336 25.7754 35.7499 26.0917L37.7831 28.0798C37.9639 28.2605 38.1898 28.3057 38.3705 28.3057C38.5964 28.3057 38.8224 28.2153 38.9579 28.0798L42.5274 24.5555C42.934 24.194 42.934 23.6518 42.5726 23.3356Z"
            fill="#1B3950"
          />
          <path
            d="M36.9247 49.6771H37.5573C37.7832 49.6771 38.0091 49.4964 38.0543 49.2705C38.1447 48.6379 38.5513 48.3216 39.2291 48.3216C39.8616 48.3216 40.1327 48.6379 40.2231 48.8638C40.3587 49.1349 40.3587 49.4964 40.1779 49.7223C39.9972 49.9934 39.8165 50.0838 39.5905 50.2645C39.0483 50.626 38.5965 51.0326 38.5061 52.1622C38.5061 52.2978 38.5513 52.4333 38.6417 52.5237C38.7321 52.614 38.8676 52.7044 39.0032 52.7044H39.6357C39.9068 52.7044 40.0876 52.5237 40.1327 52.2526C40.1779 51.8459 40.2683 51.7556 40.5394 51.5748C40.8105 51.3941 41.1268 51.1682 41.4882 50.6712C42.0304 49.9482 42.1208 48.9542 41.669 48.1409C41.2171 47.2373 40.3135 46.7402 39.2291 46.7402C37.4669 46.7402 36.6536 47.9602 36.4729 49.1349C36.4729 49.2705 36.4729 49.406 36.6084 49.5416C36.6536 49.632 36.7892 49.6771 36.9247 49.6771Z"
            fill="#1B3950"
          />
          <path
            d="M39.3195 55.2346C39.8435 55.2346 40.2683 54.8098 40.2683 54.2858C40.2683 53.7617 39.8435 53.3369 39.3195 53.3369C38.7954 53.3369 38.3706 53.7617 38.3706 54.2858C38.3706 54.8098 38.7954 55.2346 39.3195 55.2346Z"
            fill="#1B3950"
          />
          <path
            d="M21.6979 49.6771H22.3305C22.5564 49.6771 22.7823 49.4964 22.8275 49.2705C22.9179 48.6379 23.3245 48.3216 24.0023 48.3216C24.6348 48.3216 24.9059 48.6379 24.9963 48.8638C25.1318 49.1349 25.1318 49.4964 24.9511 49.7223C24.7704 49.9934 24.5896 50.0838 24.3637 50.2645C23.8215 50.626 23.3697 51.0326 23.2793 52.1622C23.2793 52.2978 23.3245 52.4333 23.4149 52.5237C23.5053 52.614 23.6408 52.7044 23.7764 52.7044H24.4089C24.68 52.7044 24.8607 52.5237 24.9059 52.2526C24.9511 51.8459 25.0415 51.7556 25.3126 51.5748C25.5837 51.3941 25.9 51.1682 26.2614 50.6712C26.8036 49.9482 26.894 48.9542 26.4422 48.1409C25.9903 47.2373 25.0867 46.7402 24.0023 46.7402C22.2401 46.7402 21.4268 47.9602 21.2461 49.1349C21.2461 49.2705 21.2461 49.406 21.3816 49.5416C21.4268 49.632 21.5624 49.6771 21.6979 49.6771Z"
            fill="#1B3950"
          />
          <path
            d="M24.0475 55.2346C24.5715 55.2346 24.9963 54.8098 24.9963 54.2858C24.9963 53.7617 24.5715 53.3369 24.0475 53.3369C23.5234 53.3369 23.0986 53.7617 23.0986 54.2858C23.0986 54.8098 23.5234 55.2346 24.0475 55.2346Z"
            fill="#1B3950"
          />
          <path
            d="M59.7875 43.4419H55.3143V38.3362C55.3143 37.8392 54.9077 37.4778 54.4558 37.4778H40.0424V33.4565C44.0185 33.0046 47.1362 29.6611 47.1362 25.5494C47.1362 21.1666 43.5667 17.5972 39.1839 17.5972C34.8012 17.5972 31.2317 21.1666 31.2317 25.5494C31.2317 29.6611 34.3493 33.0046 38.3254 33.4565V37.4778H23.9572C23.4602 37.4778 23.0987 37.8844 23.0987 38.3362V43.4419H18.5804C18.0834 43.4419 17.7219 43.8486 17.7219 44.3004V57.6746C17.7219 58.1717 18.1286 58.5331 18.5804 58.5331H29.2888C29.7858 58.5331 30.1473 58.1265 30.1473 57.6746V44.3004C30.1473 43.8034 29.7406 43.4419 29.2888 43.4419H24.8157V39.1947H38.3254V43.4419H33.8523C33.3553 43.4419 32.9938 43.8486 32.9938 44.3004V57.6746C32.9938 58.1717 33.4005 58.5331 33.8523 58.5331H44.5607C45.0577 58.5331 45.4192 58.1265 45.4192 57.6746V44.3004C45.4192 43.8034 45.0126 43.4419 44.5607 43.4419H40.0876V39.1947H53.5974V43.4419H49.1242C48.6272 43.4419 48.2657 43.8486 48.2657 44.3004V57.6746C48.2657 58.1717 48.6724 58.5331 49.1242 58.5331H59.8326C60.3297 58.5331 60.6911 58.1265 60.6911 57.6746V44.3004C60.6459 43.8486 60.2393 43.4419 59.7875 43.4419ZM32.9487 25.5494C32.9487 22.1155 35.75 19.3141 39.1839 19.3141C42.6179 19.3141 45.4192 22.1155 45.4192 25.5494C45.4192 28.9833 42.6179 31.7847 39.1839 31.7847C35.75 31.7847 32.9487 28.9833 32.9487 25.5494ZM28.4303 56.8162H19.4389V45.1589H28.4303V56.8162ZM43.6571 56.8162H34.7108V45.1589H43.7022V56.8162H43.6571ZM58.929 56.8162H49.9375V45.1589H58.929V56.8162Z"
            fill="#1B3950"
          />
          <path
            d="M52.1967 49.6771H52.8293C53.0552 49.6771 53.2811 49.4964 53.3263 49.2705C53.4167 48.6379 53.8233 48.3216 54.501 48.3216C55.1336 48.3216 55.4047 48.6379 55.4951 48.8638C55.6306 49.1349 55.6306 49.4964 55.4499 49.7223C55.2692 49.9934 55.0884 50.0838 54.8625 50.2645C54.3203 50.626 53.8685 51.0326 53.7781 52.1622C53.7781 52.2978 53.8233 52.4333 53.9137 52.5237C54.004 52.614 54.1396 52.7044 54.2751 52.7044H54.9077C55.1788 52.7044 55.3595 52.5237 55.4047 52.2526C55.4499 51.8459 55.5403 51.7556 55.8114 51.5748C56.0825 51.3941 56.3987 51.1682 56.7602 50.6712C57.3024 49.9482 57.3928 48.9542 56.9409 48.1409C56.4891 47.2373 55.5854 46.7402 54.501 46.7402C52.7389 46.7402 51.9256 47.9602 51.7449 49.1349C51.7449 49.2705 51.7449 49.406 51.8804 49.5416C51.9256 49.632 52.0612 49.6771 52.1967 49.6771Z"
            fill="#1B3950"
          />
          <path
            d="M54.5463 55.2346C55.0703 55.2346 55.4951 54.8098 55.4951 54.2858C55.4951 53.7617 55.0703 53.3369 54.5463 53.3369C54.0222 53.3369 53.5974 53.7617 53.5974 54.2858C53.5974 54.8098 54.0222 55.2346 54.5463 55.2346Z"
            fill="#1B3950"
          />
        </svg>
      </SvgContainer>
    );
  }

  if (small) {
    return (
      <SvgContainer
        height={94}
        width={94}
        viewBoxHeight={94}
        viewBoxWidth={94}
        name="correct"
        {...props}
      >
        <svg
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="49.133" cy="49.133" r="49.133" fill="#FFF2DB" />
          <path
            d="M53.4365 29.6388C53.0348 29.1797 52.3461 29.1797 51.887 29.6388L48.0995 33.3689L46.3205 31.5899C45.8614 31.1882 45.1727 31.1882 44.771 31.5899C44.3693 31.9916 44.3693 32.7377 44.771 33.1394L47.3534 35.6644C47.583 35.894 47.8699 35.9514 48.0995 35.9514C48.3864 35.9514 48.6733 35.8366 48.8455 35.6644L53.3791 31.1882C53.8956 30.7291 53.8956 30.0405 53.4365 29.6388Z"
            fill="#1B3950"
          />
          <path
            d="M46.2624 63.0954H47.0658C47.3527 63.0954 47.6396 62.8659 47.697 62.5789C47.8118 61.7755 48.3283 61.3738 49.1891 61.3738C49.9925 61.3738 50.3369 61.7755 50.4516 62.0624C50.6238 62.4068 50.6238 62.8659 50.3942 63.1528C50.1647 63.4971 49.9351 63.6119 49.6482 63.8414C48.9596 64.3005 48.3857 64.817 48.2709 66.2517C48.2709 66.4239 48.3283 66.596 48.4431 66.7108C48.5578 66.8256 48.73 66.9404 48.9022 66.9404H49.7056C50.0499 66.9404 50.2795 66.7108 50.3369 66.3665C50.3942 65.85 50.509 65.7352 50.8533 65.5057C51.1977 65.2761 51.5994 64.9892 52.0585 64.3579C52.7471 63.4397 52.8619 62.1772 52.288 61.1442C51.7141 59.9965 50.5664 59.3652 49.1891 59.3652C46.951 59.3652 45.918 60.9147 45.6885 62.4068C45.6885 62.5789 45.6885 62.7511 45.8606 62.9233C45.918 63.038 46.0902 63.0954 46.2624 63.0954Z"
            fill="#1B3950"
          />
          <path
            d="M49.3038 70.1544C49.9693 70.1544 50.5089 69.6149 50.5089 68.9493C50.5089 68.2837 49.9693 67.7441 49.3038 67.7441C48.6382 67.7441 48.0986 68.2837 48.0986 68.9493C48.0986 69.6149 48.6382 70.1544 49.3038 70.1544Z"
            fill="#1B3950"
          />
          <path
            d="M26.9235 63.0954H27.7269C28.0138 63.0954 28.3008 62.8659 28.3582 62.5789C28.4729 61.7755 28.9894 61.3738 29.8502 61.3738C30.6537 61.3738 30.998 61.7755 31.1128 62.0624C31.2849 62.4068 31.2849 62.8659 31.0554 63.1528C30.8258 63.4971 30.5963 63.6119 30.3093 63.8414C29.6207 64.3005 29.0468 64.817 28.932 66.2517C28.932 66.4239 28.9894 66.596 29.1042 66.7108C29.219 66.8256 29.3911 66.9404 29.5633 66.9404H30.3667C30.711 66.9404 30.9406 66.7108 30.998 66.3665C31.0554 65.85 31.1701 65.7352 31.5145 65.5057C31.8588 65.2761 32.2605 64.9892 32.7196 64.3579C33.4083 63.4397 33.523 62.1772 32.9492 61.1442C32.3753 59.9965 31.2275 59.3652 29.8502 59.3652C27.6121 59.3652 26.5792 60.9147 26.3496 62.4068C26.3496 62.5789 26.3496 62.7511 26.5218 62.9233C26.5792 63.038 26.7513 63.0954 26.9235 63.0954Z"
            fill="#1B3950"
          />
          <path
            d="M29.9073 70.1544C30.5729 70.1544 31.1124 69.6149 31.1124 68.9493C31.1124 68.2837 30.5729 67.7441 29.9073 67.7441C29.2417 67.7441 28.7021 68.2837 28.7021 68.9493C28.7021 69.6149 29.2417 70.1544 29.9073 70.1544Z"
            fill="#1B3950"
          />
          <path
            d="M75.3007 55.1762H69.6193V48.6914C69.6193 48.0601 69.1028 47.601 68.529 47.601H50.2224V42.4935C55.2725 41.9197 59.2322 37.673 59.2322 32.4508C59.2322 26.8842 54.6986 22.3506 49.132 22.3506C43.5655 22.3506 39.0319 26.8842 39.0319 32.4508C39.0319 37.673 42.9916 41.9197 48.0417 42.4935V47.601H29.7925C29.1612 47.601 28.7021 48.1175 28.7021 48.6914V55.1762H22.9634C22.3321 55.1762 21.873 55.6926 21.873 56.2665V73.2532C21.873 73.8844 22.3895 74.3435 22.9634 74.3435H36.5642C37.1955 74.3435 37.6546 73.827 37.6546 73.2532V56.2665C37.6546 55.6352 37.1381 55.1762 36.5642 55.1762H30.8829V49.7817H48.0417V55.1762H42.3603C41.7291 55.1762 41.27 55.6926 41.27 56.2665V73.2532C41.27 73.8844 41.7865 74.3435 42.3603 74.3435H55.9611C56.5924 74.3435 57.0515 73.827 57.0515 73.2532V56.2665C57.0515 55.6352 56.535 55.1762 55.9611 55.1762H50.2798V49.7817H67.4386V55.1762H61.7573C61.126 55.1762 60.6669 55.6926 60.6669 56.2665V73.2532C60.6669 73.8844 61.1834 74.3435 61.7573 74.3435H75.3581C75.9893 74.3435 76.4484 73.827 76.4484 73.2532V56.2665C76.391 55.6926 75.8745 55.1762 75.3007 55.1762ZM41.2126 32.4508C41.2126 28.0893 44.7706 24.5313 49.132 24.5313C53.4935 24.5313 57.0515 28.0893 57.0515 32.4508C57.0515 36.8122 53.4935 40.3702 49.132 40.3702C44.7706 40.3702 41.2126 36.8122 41.2126 32.4508ZM35.4738 72.1628H24.0538V57.3569H35.4738V72.1628ZM54.8134 72.1628H43.4507V57.3569H54.8708V72.1628H54.8134ZM74.2103 72.1628H62.7902V57.3569H74.2103V72.1628V72.1628Z"
            fill="#1B3950"
          />
          <path
            d="M65.6598 63.0954H66.4632C66.7502 63.0954 67.0371 62.8659 67.0945 62.5789C67.2093 61.7755 67.7258 61.3738 68.5866 61.3738C69.39 61.3738 69.7343 61.7755 69.8491 62.0624C70.0212 62.4068 70.0212 62.8659 69.7917 63.1528C69.5621 63.4971 69.3326 63.6119 69.0457 63.8414C68.357 64.3005 67.7831 64.817 67.6684 66.2517C67.6684 66.4239 67.7258 66.596 67.8405 66.7108C67.9553 66.8256 68.1275 66.9404 68.2996 66.9404H69.1031C69.4474 66.9404 69.6769 66.7108 69.7343 66.3665C69.7917 65.85 69.9065 65.7352 70.2508 65.5057C70.5951 65.2761 70.9968 64.9892 71.4559 64.3579C72.1446 63.4397 72.2594 62.1772 71.6855 61.1442C71.1116 59.9965 69.9639 59.3652 68.5866 59.3652C66.3485 59.3652 65.3155 60.9147 65.0859 62.4068C65.0859 62.5789 65.0859 62.7511 65.2581 62.9233C65.3155 63.038 65.4876 63.0954 65.6598 63.0954Z"
            fill="#1B3950"
          />
          <path
            d="M68.6436 70.1544C69.3092 70.1544 69.8487 69.6149 69.8487 68.9493C69.8487 68.2837 69.3092 67.7441 68.6436 67.7441C67.978 67.7441 67.4385 68.2837 67.4385 68.9493C67.4385 69.6149 67.978 70.1544 68.6436 70.1544Z"
            fill="#1B3950"
          />
        </svg>
      </SvgContainer>
    );
  }

  if (medium) {
    return (
      <SvgContainer
        height={99}
        width={99}
        viewBoxHeight={99}
        viewBoxWidth={99}
        name="correct"
        {...props}
      >
        <svg
          width="99"
          height="99"
          viewBox="0 0 99 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="49.5" cy="49.5" r="49.5" fill="#FFF2DB" />
          <path
            d="M53.8362 29.8596C53.4315 29.3971 52.7377 29.3971 52.2751 29.8596L48.4593 33.6176L46.667 31.8253C46.2045 31.4206 45.5107 31.4206 45.106 31.8253C44.7013 32.23 44.7013 32.9817 45.106 33.3864L47.7077 35.9303C47.9389 36.1615 48.228 36.2194 48.4593 36.2194C48.7484 36.2194 49.0375 36.1037 49.2109 35.9303L53.7784 31.4206C54.2987 30.9581 54.2987 30.2643 53.8362 29.8596Z"
            fill="#1B3950"
          />
          <path
            d="M46.6092 63.5666H47.4186C47.7077 63.5666 47.9967 63.3354 48.0546 63.0463C48.1702 62.2369 48.6905 61.8322 49.5578 61.8322C50.3672 61.8322 50.7141 62.2369 50.8297 62.5259C51.0032 62.8728 51.0032 63.3354 50.7719 63.6245C50.5407 63.9713 50.3094 64.087 50.0203 64.3182C49.3265 64.7808 48.7484 65.3011 48.6327 66.7465C48.6327 66.92 48.6905 67.0934 48.8062 67.209C48.9218 67.3247 49.0953 67.4403 49.2687 67.4403H50.0781C50.425 67.4403 50.6563 67.209 50.7141 66.8621C50.7719 66.3418 50.8875 66.2262 51.2344 65.9949C51.5813 65.7636 51.9861 65.4746 52.4486 64.8386C53.1424 63.9135 53.258 62.6416 52.6798 61.6009C52.1017 60.4446 50.9454 59.8086 49.5578 59.8086C47.303 59.8086 46.2623 61.3696 46.031 62.8728C46.031 63.0463 46.031 63.2197 46.2045 63.3932C46.2623 63.5088 46.4357 63.5666 46.6092 63.5666Z"
            fill="#1B3950"
          />
          <path
            d="M49.6734 70.6778C50.344 70.6778 50.8876 70.1342 50.8876 69.4636C50.8876 68.7931 50.344 68.2495 49.6734 68.2495C49.0029 68.2495 48.4593 68.7931 48.4593 69.4636C48.4593 70.1342 49.0029 70.6778 49.6734 70.6778Z"
            fill="#1B3950"
          />
          <path
            d="M27.1252 63.5666H27.9346C28.2237 63.5666 28.5127 63.3354 28.5706 63.0463C28.6862 62.2369 29.2065 61.8322 30.0738 61.8322C30.8832 61.8322 31.2301 62.2369 31.3457 62.5259C31.5192 62.8728 31.5192 63.3354 31.2879 63.6245C31.0566 63.9713 30.8254 64.087 30.5363 64.3182C29.8425 64.7808 29.2643 65.3011 29.1487 66.7465C29.1487 66.92 29.2065 67.0934 29.3222 67.209C29.4378 67.3247 29.6112 67.4403 29.7847 67.4403H30.5941C30.941 67.4403 31.1723 67.209 31.2301 66.8621C31.2879 66.3418 31.4035 66.2262 31.7504 65.9949C32.0973 65.7636 32.502 65.4746 32.9646 64.8386C33.6584 63.9135 33.774 62.6416 33.1958 61.6009C32.6177 60.4446 31.4614 59.8086 30.0738 59.8086C27.8189 59.8086 26.7783 61.3696 26.547 62.8728C26.547 63.0463 26.547 63.2197 26.7204 63.3932C26.7783 63.5088 26.9517 63.5666 27.1252 63.5666Z"
            fill="#1B3950"
          />
          <path
            d="M30.1316 70.6778C30.8022 70.6778 31.3458 70.1342 31.3458 69.4636C31.3458 68.7931 30.8022 68.2495 30.1316 68.2495C29.4611 68.2495 28.9175 68.7931 28.9175 69.4636C28.9175 70.1342 29.4611 70.6778 30.1316 70.6778Z"
            fill="#1B3950"
          />
          <path
            d="M75.864 55.5878H70.1403V49.0546C70.1403 48.4187 69.6199 47.9561 69.0418 47.9561H50.5985V42.8105C55.6863 42.2323 59.6756 37.954 59.6756 32.6927C59.6756 27.0846 55.1081 22.5171 49.5 22.5171C43.8918 22.5171 39.3243 27.0846 39.3243 32.6927C39.3243 37.954 43.3136 42.2323 48.4014 42.8105V47.9561H30.016C29.38 47.9561 28.9175 48.4765 28.9175 49.0546V55.5878H23.1359C22.4999 55.5878 22.0374 56.1082 22.0374 56.6863V73.7999C22.0374 74.4359 22.5577 74.8984 23.1359 74.8984H36.8382C37.4742 74.8984 37.9368 74.378 37.9368 73.7999V56.6863C37.9368 56.0504 37.4164 55.5878 36.8382 55.5878H31.1145V50.1531H48.4014V55.5878H42.6777C42.0417 55.5878 41.5792 56.1082 41.5792 56.6863V73.7999C41.5792 74.4359 42.0995 74.8984 42.6777 74.8984H56.3801C57.016 74.8984 57.4786 74.378 57.4786 73.7999V56.6863C57.4786 56.0504 56.9582 55.5878 56.3801 55.5878H50.6563V50.1531H67.9433V55.5878H62.2195C61.5835 55.5878 61.121 56.1082 61.121 56.6863V73.7999C61.121 74.4359 61.6413 74.8984 62.2195 74.8984H75.9219C76.5578 74.8984 77.0204 74.378 77.0204 73.7999V56.6863C76.9626 56.1082 76.4422 55.5878 75.864 55.5878ZM41.5213 32.6927C41.5213 28.2987 45.1059 24.7141 49.5 24.7141C53.894 24.7141 57.4786 28.2987 57.4786 32.6927C57.4786 37.0867 53.894 40.6713 49.5 40.6713C45.1059 40.6713 41.5213 37.0867 41.5213 32.6927ZM35.7397 72.7014H24.2344V57.7849H35.7397V72.7014ZM55.2237 72.7014H43.7762V57.7849H55.2816V72.7014H55.2237ZM74.7655 72.7014H63.2602V57.7849H74.7655V72.7014Z"
            fill="#1B3950"
          />
          <path
            d="M66.1509 63.5666H66.9603C67.2494 63.5666 67.5385 63.3354 67.5963 63.0463C67.7119 62.2369 68.2323 61.8322 69.0995 61.8322C69.909 61.8322 70.2558 62.2369 70.3715 62.5259C70.5449 62.8728 70.5449 63.3354 70.3137 63.6245C70.0824 63.9713 69.8511 64.087 69.5621 64.3182C68.8683 64.7808 68.2901 65.3011 68.1745 66.7465C68.1745 66.92 68.2323 67.0934 68.3479 67.209C68.4636 67.3247 68.637 67.4403 68.8104 67.4403H69.6199C69.9668 67.4403 70.198 67.209 70.2558 66.8621C70.3137 66.3418 70.4293 66.2262 70.7762 65.9949C71.1231 65.7636 71.5278 65.4746 71.9903 64.8386C72.6841 63.9135 72.7998 62.6416 72.2216 61.6009C71.6434 60.4446 70.4871 59.8086 69.0995 59.8086C66.8447 59.8086 65.804 61.3696 65.5728 62.8728C65.5728 63.0463 65.5728 63.2197 65.7462 63.3932C65.804 63.5088 65.9775 63.5666 66.1509 63.5666Z"
            fill="#1B3950"
          />
          <path
            d="M69.1574 70.6778C69.8279 70.6778 70.3715 70.1342 70.3715 69.4636C70.3715 68.7931 69.8279 68.2495 69.1574 68.2495C68.4868 68.2495 67.9432 68.7931 67.9432 69.4636C67.9432 70.1342 68.4868 70.6778 69.1574 70.6778Z"
            fill="#1B3950"
          />
        </svg>
      </SvgContainer>
    );
  }

  return (
    <SvgContainer
      height={99}
      width={99}
      viewBoxHeight={99}
      viewBoxWidth={99}
      name="correct"
      {...props}
    >
      <svg
        width="99"
        height="99"
        viewBox="0 0 99 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="49.133" cy="49.133" r="49.133" fill="#FFF2DB" />
        <path
          d="M53.4365 29.6388C53.0348 29.1797 52.3461 29.1797 51.887 29.6388L48.0995 33.3689L46.3205 31.5899C45.8614 31.1882 45.1727 31.1882 44.771 31.5899C44.3693 31.9916 44.3693 32.7377 44.771 33.1394L47.3534 35.6644C47.583 35.894 47.8699 35.9514 48.0995 35.9514C48.3864 35.9514 48.6733 35.8366 48.8455 35.6644L53.3791 31.1882C53.8956 30.7291 53.8956 30.0405 53.4365 29.6388Z"
          fill="#1B3950"
        />
        <path
          d="M46.2624 63.0954H47.0658C47.3527 63.0954 47.6396 62.8659 47.697 62.5789C47.8118 61.7755 48.3283 61.3738 49.1891 61.3738C49.9925 61.3738 50.3369 61.7755 50.4516 62.0624C50.6238 62.4068 50.6238 62.8659 50.3942 63.1528C50.1647 63.4971 49.9351 63.6119 49.6482 63.8414C48.9596 64.3005 48.3857 64.817 48.2709 66.2517C48.2709 66.4239 48.3283 66.596 48.4431 66.7108C48.5578 66.8256 48.73 66.9404 48.9022 66.9404H49.7056C50.0499 66.9404 50.2795 66.7108 50.3369 66.3665C50.3942 65.85 50.509 65.7352 50.8533 65.5057C51.1977 65.2761 51.5994 64.9892 52.0585 64.3579C52.7471 63.4397 52.8619 62.1772 52.288 61.1442C51.7141 59.9965 50.5664 59.3652 49.1891 59.3652C46.951 59.3652 45.918 60.9147 45.6885 62.4068C45.6885 62.5789 45.6885 62.7511 45.8606 62.9233C45.918 63.038 46.0902 63.0954 46.2624 63.0954Z"
          fill="#1B3950"
        />
        <path
          d="M49.3038 70.1544C49.9693 70.1544 50.5089 69.6149 50.5089 68.9493C50.5089 68.2837 49.9693 67.7441 49.3038 67.7441C48.6382 67.7441 48.0986 68.2837 48.0986 68.9493C48.0986 69.6149 48.6382 70.1544 49.3038 70.1544Z"
          fill="#1B3950"
        />
        <path
          d="M26.9235 63.0954H27.7269C28.0138 63.0954 28.3008 62.8659 28.3582 62.5789C28.4729 61.7755 28.9894 61.3738 29.8502 61.3738C30.6537 61.3738 30.998 61.7755 31.1128 62.0624C31.2849 62.4068 31.2849 62.8659 31.0554 63.1528C30.8258 63.4971 30.5963 63.6119 30.3093 63.8414C29.6207 64.3005 29.0468 64.817 28.932 66.2517C28.932 66.4239 28.9894 66.596 29.1042 66.7108C29.219 66.8256 29.3911 66.9404 29.5633 66.9404H30.3667C30.711 66.9404 30.9406 66.7108 30.998 66.3665C31.0554 65.85 31.1701 65.7352 31.5145 65.5057C31.8588 65.2761 32.2605 64.9892 32.7196 64.3579C33.4083 63.4397 33.523 62.1772 32.9492 61.1442C32.3753 59.9965 31.2275 59.3652 29.8502 59.3652C27.6121 59.3652 26.5792 60.9147 26.3496 62.4068C26.3496 62.5789 26.3496 62.7511 26.5218 62.9233C26.5792 63.038 26.7513 63.0954 26.9235 63.0954Z"
          fill="#1B3950"
        />
        <path
          d="M29.9073 70.1544C30.5729 70.1544 31.1124 69.6149 31.1124 68.9493C31.1124 68.2837 30.5729 67.7441 29.9073 67.7441C29.2417 67.7441 28.7021 68.2837 28.7021 68.9493C28.7021 69.6149 29.2417 70.1544 29.9073 70.1544Z"
          fill="#1B3950"
        />
        <path
          d="M75.3007 55.1762H69.6193V48.6914C69.6193 48.0601 69.1028 47.601 68.529 47.601H50.2224V42.4935C55.2725 41.9197 59.2322 37.673 59.2322 32.4508C59.2322 26.8842 54.6986 22.3506 49.132 22.3506C43.5655 22.3506 39.0319 26.8842 39.0319 32.4508C39.0319 37.673 42.9916 41.9197 48.0417 42.4935V47.601H29.7925C29.1612 47.601 28.7021 48.1175 28.7021 48.6914V55.1762H22.9634C22.3321 55.1762 21.873 55.6926 21.873 56.2665V73.2532C21.873 73.8844 22.3895 74.3435 22.9634 74.3435H36.5642C37.1955 74.3435 37.6546 73.827 37.6546 73.2532V56.2665C37.6546 55.6352 37.1381 55.1762 36.5642 55.1762H30.8829V49.7817H48.0417V55.1762H42.3603C41.7291 55.1762 41.27 55.6926 41.27 56.2665V73.2532C41.27 73.8844 41.7865 74.3435 42.3603 74.3435H55.9611C56.5924 74.3435 57.0515 73.827 57.0515 73.2532V56.2665C57.0515 55.6352 56.535 55.1762 55.9611 55.1762H50.2798V49.7817H67.4386V55.1762H61.7573C61.126 55.1762 60.6669 55.6926 60.6669 56.2665V73.2532C60.6669 73.8844 61.1834 74.3435 61.7573 74.3435H75.3581C75.9893 74.3435 76.4484 73.827 76.4484 73.2532V56.2665C76.391 55.6926 75.8745 55.1762 75.3007 55.1762ZM41.2126 32.4508C41.2126 28.0893 44.7706 24.5313 49.132 24.5313C53.4935 24.5313 57.0515 28.0893 57.0515 32.4508C57.0515 36.8122 53.4935 40.3702 49.132 40.3702C44.7706 40.3702 41.2126 36.8122 41.2126 32.4508ZM35.4738 72.1628H24.0538V57.3569H35.4738V72.1628ZM54.8134 72.1628H43.4507V57.3569H54.8708V72.1628H54.8134ZM74.2103 72.1628H62.7902V57.3569H74.2103V72.1628V72.1628Z"
          fill="#1B3950"
        />
        <path
          d="M65.6598 63.0954H66.4632C66.7502 63.0954 67.0371 62.8659 67.0945 62.5789C67.2093 61.7755 67.7258 61.3738 68.5866 61.3738C69.39 61.3738 69.7343 61.7755 69.8491 62.0624C70.0212 62.4068 70.0212 62.8659 69.7917 63.1528C69.5621 63.4971 69.3326 63.6119 69.0457 63.8414C68.357 64.3005 67.7831 64.817 67.6684 66.2517C67.6684 66.4239 67.7258 66.596 67.8405 66.7108C67.9553 66.8256 68.1275 66.9404 68.2996 66.9404H69.1031C69.4474 66.9404 69.6769 66.7108 69.7343 66.3665C69.7917 65.85 69.9065 65.7352 70.2508 65.5057C70.5951 65.2761 70.9968 64.9892 71.4559 64.3579C72.1446 63.4397 72.2594 62.1772 71.6855 61.1442C71.1116 59.9965 69.9639 59.3652 68.5866 59.3652C66.3485 59.3652 65.3155 60.9147 65.0859 62.4068C65.0859 62.5789 65.0859 62.7511 65.2581 62.9233C65.3155 63.038 65.4876 63.0954 65.6598 63.0954Z"
          fill="#1B3950"
        />
        <path
          d="M68.6436 70.1544C69.3092 70.1544 69.8487 69.6149 69.8487 68.9493C69.8487 68.2837 69.3092 67.7441 68.6436 67.7441C67.978 67.7441 67.4385 68.2837 67.4385 68.9493C67.4385 69.6149 67.978 70.1544 68.6436 70.1544Z"
          fill="#1B3950"
        />
      </svg>
    </SvgContainer>
  );
}

Correct.propTypes = {
  xs: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
};
