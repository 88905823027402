import StateHelper from 'helpers/StateHelper';
import ExamTemplate from 'factories/ExamTemplate';

import {
  SESSION_RESET
} from 'actions/session';
import {
  EXAM_TEMPLATES_FETCHED,
  EXAM_TEMPLATE_FETCHED,
  EXAM_TEMPLATE_CREATED,
  EXAM_TEMPLATE_UPDATED,
  EXAM_TEMPLATES_LIST_FETCHED,
} from 'actions/b2b/examTemplates';
import {
  ORGANIZATION_SELECTED
} from 'actions/b2b/organizations';

const stateHelper = new StateHelper(ExamTemplate);
export const { initialState } = stateHelper;
const reducers = {};

reducers[EXAM_TEMPLATES_LIST_FETCHED] = function examTemplatesListFetch(state, payload) {
  return state.withMutations((newState) => {
    payload.list.forEach((examTemplate) => {
      newState.set(examTemplate.id, new ExamTemplate({
        id: examTemplate.id,
        attributes: {
          name: examTemplate.name,
          deleted: examTemplate.deleted,
          created_at: examTemplate.created_at,
          question_limit: examTemplate.question_limit, // represents the number of original questions on template
          question_ids: JSON.parse("[" + examTemplate.question_ids.slice(1, examTemplate.question_ids.length - 1) + "]") // represents actual questions on template
        },
        relationships: {
          creator: {
            data: {
              id: examTemplate.user_id
            }
          },
          question_bank: {
            data: {
              id: examTemplate.question_bank_id
            }
          }
        }
      }));
    });
  });
};
reducers[SESSION_RESET] = stateHelper.reset;
reducers[EXAM_TEMPLATES_FETCHED] = stateHelper.set;
reducers[EXAM_TEMPLATE_FETCHED] = stateHelper.set;
reducers[EXAM_TEMPLATE_CREATED] = stateHelper.set;
reducers[EXAM_TEMPLATE_UPDATED] = stateHelper.set;
reducers[ORGANIZATION_SELECTED] = stateHelper.reset;

export default stateHelper.createReducer(reducers);
