import { useEffect } from 'react';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";

export default function useScreenSize () {
  const { width } = useWindowDimensions();
  let isMobile = width <= breakpoints.sm;
  let isTablet = width > breakpoints.sm && width <= 820;
  useEffect(() => {
    isMobile = width <= breakpoints.sm;
    isTablet = width > breakpoints.sm && width <= 820;
  }, [width]);
  return {
    isMobile,
    isTablet,
    isWeb: (!isMobile && !isTablet)
  };
}
