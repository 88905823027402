import { Set } from 'immutable';

import Factory from 'factories/_Factory';


export default class ExamTemplate extends Factory {
  static jsonType = 'exam_template';

  static afterUpdate(record, json) {
    let newRecord = record;
    if (json.attributes.question_ids || newRecord.get('question_ids') === undefined) {
      newRecord = newRecord.set('question_ids', new Set(json.attributes.question_ids));
    }
    if (json.attributes.difficulty_levels || newRecord.get('difficulty_levels') === undefined) {
      newRecord = newRecord.set('difficulty_levels', new Set(json.attributes.difficulty_levels));
    }
    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      deleted: false,
      question_limit: 1,
      question_type: '',
    };
  }

  static get belongsTo() {
    return ['question_bank', 'creator'];
  }

  static get hasMany() {
    return ['subject'];
  }
}
