import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueDownArrow(props) {
  return (
    <SvgContainer title="Down Arrow Icon" {...props}>
      <circle cx="32.1" cy="32.1" r="28.4" fill="#ffffff" />
      <path d="M32.1 61C16.2 61 3.2 48 3.2 32.1s13-28.9 28.9-28.9C48 3.2 61 16.2 61 32.1S48 61 32.1 61zm0-56.8C16.7 4.2 4.2 16.7 4.2 32.1S16.7 60 32.1 60 60 47.5 60 32.1 47.5 4.2 32.1 4.2z" fill="#81ade1" />
      <g>
        <path d="M31.5 50.7c.3.3.6.4 1 .4s.7-.1 1-.4l13.7-14.2c.2-.3.4-.7.4-1.1 0-.8-.6-1.4-1.4-1.4H38V14H27v20h-8.2c-.4 0-.7.1-1 .4-.3.3-.4.6-.4 1s.1.7.4 1l13.7 14.3z" fill="#354afb" />
      </g>
    </SvgContainer>
  );
}
