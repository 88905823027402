import React from "react";
import useWindowDimensions from "helpers/windowDimensions";
import PropTypes from "prop-types";

export default function PlpPageHeader({ showMobileFilters }) {
  const { width } = useWindowDimensions();

  return (
    <div className={`plp-page-header main-wrapper ${showMobileFilters ? "d-none" : ""}`}>
      <div
        className={`${
          width >= 1360 ? "container" : "container-fluid"
        } inner-wrapper`}
      >
        <div className="breadcrumb mb-5 py-3">
          <a href="/" className="storefront-link">
            Home
          </a>{" "}
          <span className="link-color">/</span>{" "}
          <a href="/cme" className="storefront-link">
            CME
          </a>
        </div>
        <div className="plp-title mb-5 py-3">
          <h1 className="storefront-primary-title">
            CME Online Activities for Physicians, Physician Assistants, and
            Nurse Practitioners
          </h1>
        </div>
        <div className="plp-description py-3">
          <p className="storefront-body-text mb-0">
            <b>
              Complete your Continuing Medical Education (CME) requirements on
              your schedule with online board-style review questions.
              BoardVitals CME is a quick, efficient, and effective way to earn
              CME online!
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}

PlpPageHeader.propTypes = { showMobileFilters: PropTypes.bool };
