import { Map } from 'immutable';


// idObjectToNestedMaps
//
// Takes a JS Object with IDs as keys and JS Objects as values.
// Returns an Immutable.JS Map with Integer IDs as keys and Immutable.JS Maps as values.
// It is important to use this instead of Immutable.fromJS() because fromJS() will set the keys as strings.
export const idObjectToNestedMaps = idObject =>
  new Map().withMutations((newMap) => {
    Object.entries(idObject).forEach(([id, object]) => {
      newMap.set(parseInt(id), new Map(object));
    });
  });
