import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCellPhone(props) {
  return (
    <SvgContainer title="Cell Phone Icon" {...props}>
      <path d="M23 1.5H9A2.503 2.503 0 006.5 4v24A2.503 2.503 0 009 30.5h14a2.502 2.502 0 002.5-2.5V4A2.502 2.502 0 0023 1.5zm-15.5 6h17v17h-17zm17 20.5a1.502 1.502 0 01-1.5 1.5H9A1.502 1.502 0 017.5 28v-2.5h17zM7.5 6.5V4A1.502 1.502 0 019 2.5h14A1.502 1.502 0 0124.5 4v2.5z" />
      <path d="M19 4h-6a.5.5 0 000 1h6a.5.5 0 000-1z" />
      <circle cx={16} cy={27.5} r={1} />
    </SvgContainer>
  );
}
