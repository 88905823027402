import StateHelper from 'helpers/StateHelper';
import Subject from 'factories/Subject';
import {
  QUESTION_BANK_FETCHED,
  QUESTION_BANK_SELECTED,
  QUESTION_BANK_SUBJECTS_FETCHED
} from 'actions/questionBanks';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';


const stateHelper = new StateHelper(Subject);
export const { initialState } = stateHelper;
const reducers = {};


reducers[QUESTION_BANK_FETCHED] = stateHelper.set;
reducers[QUESTION_BANK_SELECTED] = stateHelper.resetAndSet;
reducers[QUESTION_BANK_SUBJECTS_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
