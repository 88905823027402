import React from 'react';
import PropTypes from 'prop-types';

import WYSIWYG from 'components/WYSIWYG';
import LegacyAnswers from './LegacyAnswers';
import LegacyExplanation from './LegacyExplanation';

class LegacySingleChoiceQuestion extends React.Component {
  renderShowAnswerButton() {
    const { onClickShowAnswer } = this.props;
    return (
      <button
        className="question-see-answer-btn btn btn-primary"
        type="button"
        onClick={() => onClickShowAnswer()}
      >
        See Answer
      </button>
    );
  }

  render() {
    const {
      question,
      onChangeAnswer,
      selectedAnswer,
      showResponseCorrectness,
      canShowAnswer,
      onClickAnswer,
      peerComparisonPercents,
      disableAnswerSelection
    } = this.props;

    return (
      <div className="LegacyQuestion SingleChoiceQuestion">
        <WYSIWYG
          className="question-name"
        >
          { question.get('safe_name') }
        </WYSIWYG>
        <LegacyAnswers
          answers={question.get('answers')}
          onSelectAnswer={answerId => onChangeAnswer(answerId)}
          onClickAnswer={(ev, answerId, isSelected) => onClickAnswer(ev, answerId, isSelected)}
          selectedAnswerIds={selectedAnswer || []}
          correctAnswerIds={question.get('correct_answer_ids').toArray()}
          showResponseCorrectness={showResponseCorrectness}
          disableAnswerSelection={disableAnswerSelection}
        />
        { canShowAnswer && (showResponseCorrectness ? (
          <LegacyExplanation question={question} peerComparisonPercents={peerComparisonPercents} />
        ) : this.renderShowAnswerButton()) }
      </div>
    );
  }
}

LegacySingleChoiceQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  showResponseCorrectness: PropTypes.bool,
  canShowAnswer: PropTypes.bool,
  selectedAnswer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  onChangeAnswer: PropTypes.func,
  onClickShowAnswer: PropTypes.func,
  onClickAnswer: PropTypes.func,
  peerComparisonPercents: PropTypes.object,
  disableAnswerSelection: PropTypes.bool
};

LegacySingleChoiceQuestion.defaultProps = {
  showResponseCorrectness: false,
  canShowAnswer: false,
  selectedAnswer: null,
  onChangeAnswer: () => {},
  onClickAnswer: () => {},
  onClickShowAnswer: () => {},
};

export default LegacySingleChoiceQuestion;
