import React from "react";
import SvgContainer from './SvgContainer';

const PsiCheckboxSelected = props => (
  <SvgContainer title="Checkbox Selected Icon" {...props}>
    <path d="M29.1 6.3v19.4c0 1.9-1.5 3.4-3.4 3.4H6.3c-1.9 0-3.4-1.5-3.4-3.4V6.3c0-1.9 1.5-3.4 3.4-3.4h19.4c1.9 0 3.4 1.5 3.4 3.4z" fill="#4c8af5" />
    <path d="M14.9 22.9c-.3 0-.6-.1-.8-.3L9 17.5c-.5-.5-.5-1.2 0-1.6s1.2-.5 1.6 0l4 4L21 9.7c.3-.5 1.1-.7 1.6-.4.5.3.7 1.1.4 1.6l-7.2 11.4c-.2.3-.5.5-.8.5 0 .1 0 .1-.1.1z" fill="#fff" />
  </SvgContainer>
);

export default PsiCheckboxSelected;
