import { List } from 'immutable';

import Factory from 'factories/_Factory';
import PatientTab from 'factories/PatientTab';


export default class PatientTabGroup extends Factory {
  static jsonType = 'patient_tab_group';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;
    if (attributes.patient_tabs || newRecord.get('patient_tabs') === undefined) {
      const patientTabs = (attributes.patient_tabs || []).map(patientTab => new PatientTab(patientTab));
      newRecord = newRecord.set('patient_tabs', new List(patientTabs));
    }

    return newRecord;
  }

  static get defaults() {
    return {
      tabbable_type: '',
      lead_in: ''
    };
  }
}
