import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading, useIsLoaded } from 'hooks/loading';
import { creditCardsFetch } from 'actions/creditCards';

/* useCurrentUser()
 *
 * Returns the current User.
 * Returns undefined if not found.
 */
export const useCurrentUser = () => {
  const session = useSelector(state => state.session);
  const users = useSelector(state => state.users);

  if (!session.get('is_logged_in')) return;

  return users.get(session.get('current_user_id'));
};

export const useTutorModeEnabled = () => useSelector(state => state.users.getIn([state.session.get('current_user_id'), 'tutor_mode_enabled'], false));

export const useLoadCreditCards = () => {
  const dispatch = useDispatch();
  const key = creditCardsFetch.getKey();
  const loaded = useIsLoaded(key);
  const loading = useIsLoading(key);

  useEffect(() => {
    if (loading || loaded) return;

    dispatch(creditCardsFetch());
  }, [loaded]);

  return loading || !loaded;
};
