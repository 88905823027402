import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueFlagInactive(props) {
  return (
    <SvgContainer title="Flag Inactive Icon" {...props}>
      <path d="M46.76 57a5.75 5.75 0 0 1-4.63-2.36 10.32 10.32 0 0 1-1.46-5l-1.43-13.28c-1.38-.21-2.67-.22-3.42.54a4.92 4.92 0 0 0-.74 1.2l-.11.21a14.9 14.9 0 0 1-7.17 7.16 11.7 11.7 0 0 1-8.89.1l-.82-.34c-1.11-.49-2.15-.95-3-.63a2.42 2.42 0 0 0-1.36 2 9.27 9.27 0 0 0 .27 3.14l.32 1.49-1.5-.31A7.32 7.32 0 0 1 7.49 45a12.19 12.19 0 0 1 1.42-7.66 10.61 10.61 0 0 1 4.31-4.87 13.33 13.33 0 0 1 2.6-1 9.59 9.59 0 0 0 2.61-1.07c1.71-1.14 2.49-3.41 3-5.48l.13-.49c.68-2.62 1.45-5.58 3.8-7.42a17.59 17.59 0 0 1 8.79-3.62h.2a3.1 3.1 0 0 0 1.68-.47c.47-.43.42-1.23.28-2.48a11.88 11.88 0 0 1-.12-1.58 5.67 5.67 0 0 1 10.68-2.71 10.31 10.31 0 0 1 1 3.89l4.37 39.08a10 10 0 0 1-.06 3.69 5.41 5.41 0 0 1-1 2A5.77 5.77 0 0 1 47.75 57a5.52 5.52 0 0 1-.99 0Zm-8.85-22.8a12.42 12.42 0 0 1 2.43.28l.73.13 1.59 14.83a8.62 8.62 0 0 0 1.1 4 3.61 3.61 0 0 0 5.84.07 3.43 3.43 0 0 0 .64-1.28 8.71 8.71 0 0 0 0-3l-4.37-38.96a8.53 8.53 0 0 0-.75-3.16 3.67 3.67 0 0 0-6.89 1.74c0 .4.05.85.1 1.33.16 1.35.35 3-.9 4.18a4.7 4.7 0 0 1-2.82 1h-.19a15.71 15.71 0 0 0-7.79 3.22c-1.82 1.42-2.47 3.92-3.1 6.34l-.13.5c-.64 2.44-1.6 5.13-3.86 6.63a11.12 11.12 0 0 1-3.14 1.32 12.74 12.74 0 0 0-2.23.84 8.79 8.79 0 0 0-3.47 4 10.3 10.3 0 0 0-1.25 6.41 5.81 5.81 0 0 0 2.28 3.67 7.83 7.83 0 0 1 0-2 4.35 4.35 0 0 1 2.66-3.54c1.6-.57 3.13.09 4.48.68l.76.32a9.72 9.72 0 0 0 7.37-.12 12.86 12.86 0 0 0 6.18-6.22l.1-.2a6.44 6.44 0 0 1 1.11-1.72 4.77 4.77 0 0 1 3.52-1.25Z" />
    </SvgContainer>
  );
}
