import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgAccessCode(props) {
  return (
    <SvgContainer title="Archive" viewBoxHeight={20} viewBoxWidth={20} height={20} width={20} {...props}>
      <g mask="url(#mask0_212_8899)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.73913 5.03023V17.4821C0.73913 18.267 1.41322 18.3169 1.54922 18.3192H14.8794C15.6356 18.3192 15.6843 17.6194 15.6866 17.479V1.60496C15.6866 0.820008 15.0125 0.769365 14.8765 0.767064H5.21087L0.73913 5.03023ZM1.54626 19.0865C0.924652 19.0865 0 18.6599 0 17.4821V4.69338L4.92335 -0.000244141H14.8794C15.501 -0.000244141 16.4257 0.427146 16.4257 1.60496V17.4821C16.4257 18.1266 16.014 19.0865 14.8794 19.0865H1.54626Z" fill="#767676" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5958 10.6855C11.4864 10.6855 11.377 10.6348 11.3038 10.5374L8.47295 6.75915C8.3473 6.59188 8.37687 6.35094 8.538 6.22127C8.69765 6.09083 8.93122 6.11998 9.05613 6.28879L11.8877 10.0662C12.0126 10.2335 11.9838 10.4752 11.8227 10.6049C11.7554 10.6594 11.6749 10.6855 11.5958 10.6855" fill="#767676" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.76509 14.4643C8.686 14.4643 8.60543 14.4382 8.53817 14.3837C8.37704 14.2541 8.34822 14.0124 8.47313 13.8451L11.3047 10.0669C11.4289 9.89806 11.6625 9.8689 11.8229 9.99934C11.984 10.129 12.0136 10.37 11.8879 10.5372L9.05704 14.3162C8.98387 14.4137 8.87448 14.4643 8.76509 14.4643" fill="#767676" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.596 10.6853H4.31634C4.11234 10.6853 3.94678 10.5134 3.94678 10.3016C3.94678 10.0898 4.11234 9.91797 4.31634 9.91797H11.596C11.8 9.91797 11.9656 10.0898 11.9656 10.3016C11.9656 10.5134 11.8 10.6853 11.596 10.6853" fill="#767676" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.68118 6.43529H1.13184V5.66798H5.68118C5.72331 5.66798 5.76618 5.66798 5.76618 5.57897V1.32886H6.50531V5.57897C6.50531 5.90968 6.28136 6.43529 5.68118 6.43529" fill="#767676" />
    </SvgContainer>
  );
}
