import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import CmeCoachComponent from 'components/forms/CmeCoach';
import LoadingIcon from 'components/LoadingIcon';

import {
  cmeCoachSearch,
  cmeCoachSendEmail
} from 'actions/cmeCoach';
import { hubspotSubmitCmeCoach } from 'actions/hubspot';
import {
  staticDataFetched
} from 'actions/staticData';

export class CmeCoachClass extends React.Component {
  componentDidMount() {
    if (!this.isLoaded()) this.load();
  }

  componentDidUpdate() {
    if (!this.isLoaded()) this.load();
  }

  onSubmitEmail(email) {
    const { actions } = this.props;
    actions.hubspotSubmitCmeCoach(email);
    return actions.cmeCoachSendEmail(email);
  }

  isLoaded() {
    const { loadedAt } = this.props;
    return loadedAt.has('static-data');
  }

  load() {
    const { actions, preloadedData } = this.props;
    if (preloadedData && preloadedData.data && preloadedData.included) {
      actions.staticDataFetched(preloadedData, 0);
    } else {
      console.warn('no preloaded data for CME Coach');
    }
  }

  render() {
    if (!this.isLoaded()) return <LoadingIcon />;

    const {
      actions,
      cmeCoach,
      staticData,
      productPages
    } = this.props;

    const filteredProductPages = productPages.filter((productPage, id) => cmeCoach.get('productPageIds').includes(id));

    return (
      <CmeCoachComponent
        cmeCoach={cmeCoach}
        specialties={staticData.get('specialties')}
        usStates={staticData.get('us_states')}
        productPages={filteredProductPages}
        onSearch={(selectedSpecialtyId, selectedStateId) => actions.cmeCoachSearch(selectedSpecialtyId, selectedStateId)}
        onSubmitEmail={email => this.onSubmitEmail(email)}
      />
    );
  }
}

CmeCoachClass.propTypes = {
  cmeCoach: PropTypes.object.isRequired,
  preloadedData: PropTypes.object
};

function mapStateToProps({
  cmeCoach,
  staticData,
  productPages,
  loading,
  loadedAt
}) {
  return {
    cmeCoach,
    staticData,
    productPages,
    loading,
    loadedAt
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      staticDataFetched,
      cmeCoachSearch,
      cmeCoachSendEmail,
      hubspotSubmitCmeCoach
    }, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CmeCoachClass);
