import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(func, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) func();
    else didMountRef.current = true;
  }, inputs);
}

export const debounceFunction = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, [...args]); },
      delay);
  };
};
