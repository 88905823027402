import {
  List
} from 'immutable';

import Factory from 'factories/_Factory';
import TemplateBlock from 'factories/TemplateBlock';


export default class PracticeExamTemplate extends Factory {
  static jsonType = 'practice_exam_template';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;
    if (attributes.break_pool_time_in_seconds === null) {
      newRecord = newRecord.set('break_pool_time_in_seconds', null);
    }
    if (attributes.block_pool_time_in_seconds === null) {
      newRecord = newRecord.set('block_pool_time_in_seconds', null);
    }
    if (attributes.template_blocks || newRecord.get('template_blocks') === undefined) {
      const templateBlocks = (json.attributes.template_blocks || []).map(templateBlock => new TemplateBlock(templateBlock));
      newRecord = newRecord.set('template_blocks', new List(templateBlocks));
    }
    if (attributes.question_ids || newRecord.get('question_ids') === undefined) {
      newRecord = newRecord.set('question_ids', new List(attributes.question_ids));
    }

    return newRecord;
  }

  static get belongsTo() {
    return ['question_bank'];
  }

  static get defaults() {
    return {
      name: '',
      question_bank_id: 0,
      break_pool_time_in_seconds: null,
      block_pool_time_in_seconds: null,
      board_name: '',
      board_exam_interface: '',
      status: ''
    };
  }
}
