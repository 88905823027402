import React from "react";
import PropTypes from "prop-types";


// StyleGuide = organsims/menus/nav

class Nav extends React.Component {
  render() {
    const {
      children,
      className,
      navHeader,
      role
    } = this.props;

    return (
      <div role={role} className={`Nav nav text-nowrap flex-nowrap ${className}`}>
        { navHeader ? <div className="nav-header py-2 px-3">{navHeader}</div> : null}
        { children }
      </div>
    );
  }
}

Nav.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  navHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  role: PropTypes.string,
};

Nav.defaultProps = {
  className: "",
  role: ""
};

export default Nav;
