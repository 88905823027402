import { Set } from 'immutable';


// When you have multiple reducers for a single slice of state this function allows you to
// reduce the same Actions in the different reducers.
export default function combineReducers(reducerGroups) {
  const actionKeys = reducerGroups
    .reduce((accumulator, reducersGroup) => accumulator.concat(Object.keys(reducersGroup)), []);

  const allActions = Set(actionKeys).toArray();

  const combinedReducers = {};
  allActions.forEach((action) => {
    combinedReducers[action] = (state, payload) => {
      let newState = state;
      reducerGroups.forEach((reducerGroup) => {
        if (Object.prototype.hasOwnProperty.call(reducerGroup, action)) {
          newState = reducerGroup[action](newState, payload);
        }
      });
      return newState;
    };
  });

  return combinedReducers;
}
