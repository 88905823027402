import * as React from "react";
import PropTypes from 'prop-types';
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  const { styleVariant } = props;
  const fillColor = styleVariant === "filled" ? "#FFF" : 'none';
  return (
    <SvgContainer title="Checkbox Unselected" width={21} height={21} viewBoxHeight={23} viewBoxWidth={23} {...props}>
      <rect x="35.5" y="296.5" width="20" height="20" rx="5" transform="translate(-35 -296)" fillRule="nonzero" stroke="#767676" fill={fillColor} />
    </SvgContainer>
  );
}

SvgCheck.propTypes = {
  styleVariant: PropTypes.oneOf([
    'filled',
    'none'
  ]),
};

SvgCheck.defaultProps = {
  styleVariant: 'none'
};
