import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgLock(props) {
  return (
    <SvgContainer title="Lock Icon" {...props}>
      <defs>
        <style type="text/css">{".lock-st0{fill:#f1e730}"}</style>
      </defs>
      <path className="lock-st0" d="M21.1 13.4h-.6V10c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5v3.4h-.6c-1 0-1.8.8-1.8 1.8v8.1c0 1 .8 1.8 1.8 1.8H21c1 0 1.8-.8 1.8-1.8v-8.1c0-1-.8-1.8-1.7-1.8zm-8-3.4c0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9v3.4H13V10zm3.8 9.1v2.2c0 .5-.4.9-.9.9s-.9-.4-.9-.9v-2.2c-.4-.3-.7-.8-.7-1.3 0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .6-.3 1.1-.7 1.3z" /><path className="lock-st0" d="M16 31.1C7.7 31.1.9 24.3.9 16S7.7.9 16 .9 31.1 7.7 31.1 16 24.3 31.1 16 31.1zm0-28.2C8.8 2.9 2.9 8.8 2.9 16S8.8 29.1 16 29.1 29.1 23.2 29.1 16 23.2 2.9 16 2.9z" />
    </SvgContainer>
  );
}
