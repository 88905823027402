import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgFilledFlag(props) {
  return (
    <SvgContainer title="Filled Flag Icon" {...props}>
      <path fill="#D0021B" stroke="#CCC" d="M25.601 17.65L20.961 13l4.64-4.65a.355.355 0 00.11-.16.5.5 0 000-.38.51.51 0 00-.27-.27.406.406 0 00-.19-.04H11.238a2.476 2.476 0 00.513-1.5 2.5 2.5 0 10-3 2.45V27.5h-2.5a.5.5 0 100 1h6a.5.5 0 000-1h-2.5v-9h15.5a.406.406 0 00.19-.04.51.51 0 00.27-.27.5.5 0 000-.38.355.355 0 00-.11-.16zM7.751 6a1.5 1.5 0 111.5 1.5 1.502 1.502 0 01-1.5-1.5z" />
    </SvgContainer>
  );
}
