import QuizQuestionFactory from 'factories/_QuizQuestionFactory';


export default class Response extends QuizQuestionFactory {
  static jsonType = 'response';

  static get defaults() {
    return {
      user_input: null,
      correct: null,
      ungraded_user_input: null,
      ungraded_correct: null,
      evaluated_at: null,
      ms_spent: 0,
      status: null,
      time: 0,
      result: 'NOT EVALUATED',
      answer: '{}',
      answered_at: null,
      is_aact: false,
      potential_score: 0,
      actual_score: 0,
      can_submit: false
    };
  }

  static get belongsTo() {
    return ['quiz', 'question'];
  }
}
