import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgUnfilledStar(props) {
  return (
    <SvgContainer title="Unfilled Star Icon" {...props}>
      <path d="M27.48 13.43a.97.97 0 00.011-.194 1.02 1.02 0 00-.062-.179.495.495 0 00-.056-.072.472.472 0 00-.072-.073c-.007-.006-.01-.014-.019-.02a.668.668 0 01-.131-.063l-7.107-2.247-3.611-6.245-.014-.016a.49.49 0 00-.066-.075.499.499 0 00-.074-.07c-.006-.003-.008-.01-.014-.013a.445.445 0 00-.058-.022.487.487 0 00-.106-.04.477.477 0 00-.088-.006.472.472 0 00-.1.004.485.485 0 00-.1.034.456.456 0 00-.064.022c-.006.003-.009.01-.015.013a1.088 1.088 0 00-.144.139l-.014.016-3.905 6.26-6.827 2.248a.459.459 0 00-.06.034.47.47 0 00-.072.031c-.007.006-.011.014-.018.02a.476.476 0 00-.07.074.498.498 0 00-.056.073.416.416 0 00-.058.272.482.482 0 00.012.1c.002.01 0 .018.003.027.004.012.016.017.021.029a.475.475 0 00.045.102l3.89 5.538-.978 8.224c-.001.01.003.019.003.03a.816.816 0 00.028.195c.003.01.002.02.006.03a.477.477 0 00.036.052.493.493 0 00.057.083.679.679 0 00.152.113.491.491 0 00.094.031.472.472 0 00.062.02.416.416 0 00.06.004.495.495 0 00.187-.038l.009-.002L16 24.543l7.804 3.33.009.002a.494.494 0 00.187.038.421.421 0 00.06-.004.494.494 0 00.202-.074c.01-.006.018-.017.028-.024a.49.49 0 00.147-.174c.005-.011.018-.016.023-.027.004-.01.002-.02.006-.03a.79.79 0 00.028-.197c0-.01.004-.02.002-.03l-.977-8.072 3.894-5.693a.479.479 0 00.044-.105c.004-.01.016-.016.02-.027.002-.008 0-.017.002-.026zM23.4 26.613L16 23.456l-7.402 3.158.921-7.745-3.723-5.3 6.533-2.151 3.655-5.86 3.388 5.86 6.835 2.16-3.726 5.449z" />
    </SvgContainer>
  );
}
