import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgMedicalHeart(props) {
  return (
    <SvgContainer title="Medical Heart Icon" {...props}>
      <path d="M22.51 6.5v1a3.06 3.06 0 012.652 2.194 6.388 6.388 0 01.245 3.23.5.5 0 00.418.57.514.514 0 00.078.006.5.5 0 00.493-.424 7.376 7.376 0 00-.3-3.74A4.038 4.038 0 0022.51 6.5zM25.27 15.69a.439.439 0 00-.17-.11.502.502 0 00-.38 0 .354.354 0 00-.16.11.453.453 0 00-.11.16.576.576 0 00-.04.19.57.57 0 00.04.19.445.445 0 00.11.16.468.468 0 00.35.15.487.487 0 00.36-.15.438.438 0 00.1-.16.47.47 0 000-.38.446.446 0 00-.1-.16z" />
      <path d="M28.898 8.264A7 7 0 0022.51 3.5h-.002c-2.465 0-5.066 1.8-6.508 4.387C14.558 5.3 11.957 3.5 9.492 3.5H9.49a7 7 0 00-6.388 4.764 11.855 11.855 0 00.863 9.358c.155.306.34.589.517.878H3.5a1 1 0 00-1 1v3a1 1 0 001 1h4v4a1 1 0 001 1h3a1 1 0 001-1v-1.238c.854.59 1.708 1.167 2.543 1.71l.684.446c.006.004.013.002.02.006a.46.46 0 00.507 0c.006-.004.014-.002.02-.006l.684-.445c4.083-2.662 8.71-5.68 11.078-10.35a11.855 11.855 0 00.861-9.36zM11.5 27.5h-3v-5h-5v-3h5v-5h3v5h5v3h-5zm15.643-10.33c-2.246 4.432-6.754 7.372-10.733 9.965l-.41.268-.41-.268a93.44 93.44 0 01-3.09-2.11V23.5h4a1 1 0 001-1v-3a1 1 0 00-1-1h-4v-4a1 1 0 00-1-1h-3a1 1 0 00-1 1v4H5.646a13.502 13.502 0 01-.789-1.33 10.85 10.85 0 01-.82-8.55A6.011 6.011 0 019.49 4.5h.002c2.363.001 4.904 1.973 6.045 4.689v.001l.002.003c.004.01.012.015.016.024a.409.409 0 00.212.217.446.446 0 00.04.027h.001a.5.5 0 00.382 0h.003a.457.457 0 00.052-.036.484.484 0 00.107-.073.491.491 0 00.093-.136c.004-.008.012-.014.016-.023l.001-.003v-.002C17.605 6.472 20.146 4.5 22.509 4.5h.002a6.011 6.011 0 015.454 4.122 10.85 10.85 0 01-.821 8.55z" />
    </SvgContainer>
  );
}
