import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgUpCaret1(props) {
  return (
    <SvgContainer title="Up Caret Icon" {...props}>
      <path d="M28.415 21.263A.904.904 0 0028.5 21c0-.008-.004-.015-.004-.022a.486.486 0 00-.021-.103.495.495 0 00-.025-.086 1.18 1.18 0 00-.115-.156c-.006-.005-.009-.012-.015-.017l-12-10a.473.473 0 00-.049-.027.448.448 0 00-.173-.072.46.46 0 00-.187-.001 1.126 1.126 0 00-.231.1l-12 10c-.006.005-.009.012-.015.017a.497.497 0 00-.065.082.49.49 0 00-.05.074.496.496 0 00-.025.087.487.487 0 00-.02.101c0 .008-.004.015-.004.023a.473.473 0 00.014.07.353.353 0 00.07.193.474.474 0 00.032.057c.004.006.011.007.016.012a.49.49 0 00.125.097c.015.008.026.02.041.027A.495.495 0 004 21.5h24a.495.495 0 00.202-.044c.014-.007.026-.02.04-.027a.49.49 0 00.126-.096c.005-.006.011-.007.016-.013a.476.476 0 00.03-.057zM16 11.65L26.619 20.5H5.38z" />
    </SvgContainer>
  );
}
