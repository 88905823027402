import * as React from "react";
import SvgContainer from './SvgContainer';

export default function OrangeStar(props) {
  return (
    <SvgContainer title="Orange Star Icon" {...props}>
      <path d="M12.8135 0.979492L16.9492 8.96886L25.5458 10.5888L19.5296 17.1718L20.689 26.1626L12.8135 22.2378L4.97326 26.1626L6.13268 17.1718L0.109375 10.5888L8.67774 8.96886L12.8135 0.979492Z" fill="#F9A11B" />
    </SvgContainer>
  );
}
