import StateHelper from 'helpers/StateHelper';
import CmeCertificate from 'factories/CmeCertificate';

import { SESSION_RESET } from 'actions/session';
import { CME_ACTIVITIES_FETCHED, CME_TRACKERS_AND_CERTIFICATES_FETCHED, CME_CERTIFICATE_GENERATED } from 'actions/cme';

const stateHelper = new StateHelper(CmeCertificate);

const reducers = {};


reducers[CME_ACTIVITIES_FETCHED] = stateHelper.set;
reducers[CME_TRACKERS_AND_CERTIFICATES_FETCHED] = stateHelper.set;
reducers[CME_CERTIFICATE_GENERATED] = stateHelper.set;

reducers[SESSION_RESET] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
