import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "helpers/windowDimensions";
import SVGIcon from "components/SVGIcon";
import italicize from 'helpers/italicize';
import PlpMobileFilters from "./PlpMobileFilters";

// TODO confirm specialty filters will be permanently removed
export default function PlpFilter({
  data,
  showMobileFilters,
  setShowMobileFilters,
  mobileFiltersRecentlyClosed,
  setMobileFiltersRecentlyClosed,
  creditTypesSelected,
  setCreditTypesSelected,
  numberOfCreditsSelected,
  setNumberOfCreditsSelected,
  showCreditTypes,
  setShowCreditTypes,
  showCreditAmount,
  setShowCreditAmount
}) {
  const {
    cme_details: cmeDetails,
    // specialties,
    pages,
    credit_details: creditDetails,
    possible_credit_types: possibleCreditTypes,
  } = data;

  const { width } = useWindowDimensions();

  const mobileFiltersRef = useRef();
  const plpContainerRef = useRef(null);

  const [plpData, setPlpData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // const [queryString, setQueryString] = useState("");
  // const [specialtiesSelected, setSpecialtiesSelected] = useState([]);
  // const [creditTypesSelected, setCreditTypesSelected] = useState([]);
  // const [numberOfCreditsSelected, setNumberOfCreditsSelected] = useState(null);
  // const [showSpecialties, setShowSpecialties] = useState(false);
  // const [showCreditTypes, setShowCreditTypes] = useState(true);
  // const [showCreditAmount, setShowCreditAmount] = useState(false);
  const [showMoreProducts, setShowMoreProducts] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const creditRanges = ["< 20 Credits", "20 - 40 Credits", "> 40 Credits"];

  const productBoxesData = () => {
    const defaultData = [];
    let tmpPages = pages;

    // Set credit details and a sum of credits for each product page
    tmpPages.map((page) => {
      page.creditDetails = creditDetails[page.id];
      page.totalCredits = page.creditDetails?.reduce(
        (total, obj) => total + obj.count,
        0
      );
      return page;
    });

    // if (specialtiesSelected.length > 0) {
    //   // Looks for exact specialty match
    //   const exactMatches = tmpPages.filter(page => specialtiesSelected?.some(specialty => specialty.toLowerCase() === page.name.toLowerCase()));

    //   // Looks for all other specialties with MOC points
    //   const pagesWithMocCredits = tmpPages.filter(page => page.creditDetails?.some(detailsObj => detailsObj.type.includes("MOC")));

    //   // Looks for the exact matches within the MOC pages array
    //   const crossMatchingPages = pagesWithMocCredits.filter(page => exactMatches?.some(exactMatch => exactMatch.name.toLowerCase() === page.name.toLowerCase()));

    //   if (crossMatchingPages.length > 0) {
    //     // Extract the MOC credit counts from crossMatchingPages
    //     const mocCountsInCrossMatching = crossMatchingPages.map(page => page.creditDetails?.find(detail => detail.type.includes("MOC"))?.count);

    //     // Find pages in pagesWithMocCredits with matching MOC count
    //     const pagesWithMatchingMocCount = pagesWithMocCredits.filter(page => page.creditDetails?.some(detail => detail.type.includes("MOC") && mocCountsInCrossMatching.includes(detail.count)));

    //     const combinedPages = [...pagesWithMatchingMocCount, ...exactMatches];

    //     // Remove duplicates based on the 'name' property
    //     tmpPages = Array.from(new Set(combinedPages.map(page => page.name.toLowerCase()))).map(name => combinedPages.find(page => page.name.toLowerCase() === name));
    //   } else {
    //     tmpPages = exactMatches;
    //   }
    // }

    if (creditTypesSelected.length > 0) {
      tmpPages = tmpPages.filter(page => page.creditDetails?.some(pageCreditType => creditTypesSelected.includes(pageCreditType.type)));
    }

    // Set this data here so the counter can make decision based on the length of this data subset
    setFilteredData(tmpPages);

    if (numberOfCreditsSelected) {
      tmpPages = tmpPages.filter((page) => {
        if (numberOfCreditsSelected === creditRanges[0] && page.totalCredits < 20) return page;
        if (numberOfCreditsSelected === creditRanges[1] && page.totalCredits >= 20 && page.totalCredits <= 40) return page;
        if (numberOfCreditsSelected === creditRanges[2] && page.totalCredits > 40) return page;

        return false;
      });
    }

    // Set default data for the filters section
    tmpPages.forEach((page) => {
      defaultData.push({
        page: page,
        cmeDetails: cmeDetails[page.id],
      });
    });

    setPlpData(defaultData);
  };

  const filterCount = (filterType, currentOption) => {
    let counter = 0;

    if (filterType === "creditType") {
      counter = pages.filter(page => page.creditDetails?.some(pageCreditType => currentOption.includes(pageCreditType.type)))?.length;
    } else if (filterType === "creditRange") {
      counter = filteredData.filter((page) => {
        if (currentOption === creditRanges[0] && page.totalCredits < 20) return page;
        if (currentOption === creditRanges[1] && page.totalCredits >= 20 && page.totalCredits <= 40) return page;
        if (currentOption === creditRanges[2] && page.totalCredits > 40) return page;

        return page;
      })?.length;
    }
    return counter;
  };

  const toggleFilter = (filterType) => {
    switch (filterType) {
      case "credit-type":
        // if (!showCreditTypes) setShowSpecialties(false);
        setShowCreditTypes(!showCreditTypes);
        break;
      case "credit-amount":
        // if (!showCreditAmount) setShowSpecialties(false);
        setShowCreditAmount(!showCreditAmount);
        break;
      default:
        // setShowSpecialties(!showSpecialties);
        // setShowCreditTypes(false);
        // setShowCreditAmount(false);
        break;
    }
  };

  const handleFilterChanges = (event, filterType) => {
    const inputName = event.target.name;
    let updatedFilterFromState;
    let filterStateSetter;

    switch (filterType) {
      case "credit-type":
        updatedFilterFromState = [...creditTypesSelected];
        filterStateSetter = setCreditTypesSelected;
        break;
      case "credit-amount":
        updatedFilterFromState = numberOfCreditsSelected;
        filterStateSetter = setNumberOfCreditsSelected;
        break;
      default:
        // updatedFilterFromState = [...specialtiesSelected];
        // filterStateSetter = setSpecialtiesSelected;
        break;
    }

    if (filterType !== "credit-amount") {
      if (event.target.checked) {
        if (!updatedFilterFromState.includes(inputName)) updatedFilterFromState.push(inputName);
      } else {
        const index = updatedFilterFromState.indexOf(inputName);
        if (index !== -1) updatedFilterFromState.splice(index, 1);
      }
    } else {
      updatedFilterFromState = event.target.value;
    }

    filterStateSetter(updatedFilterFromState);
  };

  const clearFilter = (filterType) => {
    // if (filterType === "specialties") setSpecialtiesSelected([]);
    if (filterType === "credit-type") setCreditTypesSelected([]);
    if (filterType === "credit-amount") setNumberOfCreditsSelected(null);
    if (filterType === "all") {
      // setQueryString("");
      // setSpecialtiesSelected([]);
      setCreditTypesSelected([]);
      setNumberOfCreditsSelected(null);
    }
  };

  const plpContainerColSize = () => {
    let colSize = "col-4";
    if (width <= 1024 && width >= 768) colSize = "col-md-6";
    if (width <= 767) colSize = "col-sm-12";

    return colSize;
  };

  const smoothScrollTo = (container, targetElement, duration) => {
    const start = container.scrollTop;
    const to = targetElement.offsetTop;
    const change = to - start;
    const startTime = performance.now();

    const easeInOut = (t, b, c, d) => {
      let time = t;
      time /= d / 2;

      if (time < 1) return (c / 2) * time * time + b;

      time -= 1;
      return (-c / 2) * (time * (time - 2) - 1) + b;
    };

    const animateScroll = (time) => {
      const elapsedTime = time - startTime;
      container.scrollTop = easeInOut(elapsedTime, start, change, duration);
      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    productBoxesData();
  }, [
    // queryString,
    // specialtiesSelected,
    creditTypesSelected,
    numberOfCreditsSelected,
  ]);

  // Prevents styling issues while resizing with the filters open
  useEffect(() => {
    if (width >= 834) {
      setShowMobileFilters(false);
    }

    if (width <= 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  // Maintain ref on mobile filters section after closing filter tab
  useEffect(() => {
    if (mobileFiltersRecentlyClosed) {
      mobileFiltersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [mobileFiltersRecentlyClosed]);

  useEffect(() => {
    if (showMoreProducts && plpContainerRef.current) {
      const elementToScrollTo = plpContainerRef.current.querySelectorAll(".product")[8];
      mobileFiltersRef.current.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        if (elementToScrollTo) {
          smoothScrollTo(plpContainerRef.current, elementToScrollTo, 500);
        }
      }, 600);
    }
  }, [showMoreProducts]);

  useEffect(() => {
    const element = document.getElementById("mobile-filters-container");

    if (element) {
      if (showMobileFilters) {
        element.classList.add("visible");
      } else {
        element.classList.remove("visible");
      }
    }
  }, [showMobileFilters]);

  return showMobileFilters ? (
    <PlpMobileFilters
      setShowMobileFilters={setShowMobileFilters}
      handleFilterChanges={handleFilterChanges}
      toggleFilter={toggleFilter}
      showCreditTypes={showCreditTypes}
      possibleCreditTypes={possibleCreditTypes}
      creditTypesSelected={creditTypesSelected}
      // showSpecialties={showSpecialties}
      // specialties={specialties}
      // specialtiesSelected={specialtiesSelected}
      showCreditAmount={showCreditAmount}
      creditRanges={creditRanges}
      numberOfCreditsSelected={numberOfCreditsSelected}
      clearFilter={clearFilter}
      setRecentlyClosed={setMobileFiltersRecentlyClosed}
      filterCount={filterCount}
    />
  ) : (
    <div className="main-wrapper">
      <div
        className={`${
          width >= 1360 ? "container" : "container-fluid"
        } d-flex inner-wrapper`}
      >
        <div className="plp-filter col">
          <div className="mb-4">
            <div className="main-actions mb-4">
              <span className="storefront-body-text bold mr-2">Filter</span>
              <span> | </span>
              <span
                className="storefront-link ml-2"
                role="button"
                tabIndex="0"
                onClick={() => clearFilter("all")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    clearFilter("all");
                  }
                }}
              >
                Clear
              </span>
            </div>
          </div>
          <hr />
          <div className="credit-type">
            <div className="credit-type-actions my-4">
              <span className="storefront-body-text mr-2">Credit Type</span>
              <span> | </span>
              <span
                className="storefront-link ml-2"
                role="button"
                tabIndex="0"
                onClick={() => clearFilter("credit-type")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    clearFilter("credit-type");
                  }
                }}
              >
                Clear
              </span>
              <span
                className="ml-auto svg-container"
                role="button"
                tabIndex="0"
                onClick={() => toggleFilter("credit-type")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    toggleFilter("credit-type");
                  }
                }}
              >
                <SVGIcon
                  name={showCreditTypes ? "Minus" : "Plus"}
                  width={16}
                  height={16}
                  aria-hidden="true"
                />
              </span>
            </div>
            <div
              className={`credit-type-container ${
                showCreditTypes ? "" : "d-none"
              }`}
            >
              {possibleCreditTypes.map((creditType, i) => (
                <div key={i} className="credit-type-option">
                  <input
                    type="checkbox"
                    name={creditType}
                    checked={creditTypesSelected.includes(creditType)}
                    onChange={e => handleFilterChanges(e, "credit-type")}
                  />
                  <label
                    htmlFor={creditType}
                    className="storefront-body-text ml-2"
                  >
                    {creditType.includes("AMA PRA") ? italicize(creditType) : creditType} ({filterCount("creditType", creditType)})
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr />
          {/* <div className="specialties mb-4">
            <div className="specialties-actions my-4">
              <span className="storefront-body-text mr-2">Specialty</span>
              <span> | </span>
              <span
                className="storefront-link ml-2"
                role="button"
                tabIndex="0"
                onClick={() => clearFilter("specialties")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    clearFilter("specialties");
                  }
                }}
              >
                Clear
              </span>
              <span
                className="ml-auto svg-container"
                role="button"
                tabIndex="0"
                onClick={() => toggleFilter("specialties")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    toggleFilter("specialties");
                  }
                }}
              >
                <SVGIcon
                  name={showSpecialties ? "Minus" : "Plus"}
                  width={16}
                  height={16}
                  aria-hidden="true"
                />
              </span>
            </div>
            <div
              className={`specialties-container ${
                showSpecialties ? "" : "d-none"
              }`}
            >
              {specialties.map((specialty, i) => (
                <div
                  key={i}
                  className="specialty-option d-flex align-items-baseline"
                >
                  <input
                    type="checkbox"
                    name={specialty}
                    checked={specialtiesSelected.includes(specialty)}
                    onChange={e => handleFilterChanges(e, "specialties")}
                  />
                  <label
                    htmlFor={specialty}
                    className="storefront-body-text ml-2"
                  >
                    {specialty.replace(/CME/g, "")}
                  </label>
                </div>
              ))}
            </div>
          </div> */}
          {/* <hr /> */}
          <div className="credit-amount">
            <div className="credit-amount-actions my-4">
              <div
                className={`storefront-body-text mr-2 ${
                  width <= 830 ? "number-of-credits" : ""
                }`}
              >
                Number of Credits
              </div>
              <span> | </span>
              <span
                className="storefront-link ml-2"
                role="button"
                tabIndex="0"
                onClick={() => clearFilter("credit-amount")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    clearFilter("credit-amount");
                  }
                }}
              >
                Clear
              </span>
              <span
                className="ml-auto svg-container"
                role="button"
                tabIndex="0"
                onClick={() => toggleFilter("credit-amount")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    toggleFilter("credit-amount");
                  }
                }}
              >
                <SVGIcon
                  name={showCreditAmount ? "Minus" : "Plus"}
                  width={16}
                  height={16}
                  aria-hidden="true"
                />
              </span>
            </div>
            <div
              className={`credit-amount-container ${
                showCreditAmount ? "" : "d-none"
              }`}
            >
              {creditRanges.map((creditRange, i) => (
                <div key={i} className="credit-amount-option">
                  <input
                    type="radio"
                    name={creditRange}
                    value={creditRange}
                    checked={numberOfCreditsSelected === creditRange}
                    onChange={e => handleFilterChanges(e, "credit-amount")}
                  />
                  <label
                    htmlFor={creditRange}
                    className="storefront-body-text ml-2"
                  >
                    {creditRange} ({filterCount("creditRange", creditRange)})
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr />
        </div>
        <div className="plp-filter-mobile" ref={mobileFiltersRef}>
          <button
            type="button"
            className="mobile-filter-button text-center"
            onClick={() => {
              setShowMobileFilters(true);
              setMobileFiltersRecentlyClosed(false);
            }}
          >
            <span className="mr-3">Filter</span>
            <SVGIcon
              name="DownCaret2"
              width={14}
              height={16}
              aria-hidden="true"
            />
          </button>
        </div>
        <div
          className={`plp-container col ${plpData.length > 8 && !showMoreProducts && isMobile && "large-container"}`}
          ref={plpContainerRef}
        >
          <div className="row">
            {plpData.map((obj, i) => (
              <div
                key={i}
                className={`product ${plpContainerColSize()} ${!showMoreProducts && i > 7 && isMobile ? "d-none" : "d-block"}`}
              >
                <a href={`/${obj.page.path}`} title={`${obj.page.summary}`}>
                  <div className="product-box">
                    <div className="product-name storefront-body-text">
                      {obj.page.name}
                    </div>
                    {obj.cmeDetails?.map((detail, iterator) => (
                      <div
                        key={iterator}
                        className="product-detail storefront-body-text"
                      >
                        {detail.includes("AMA PRA") ? italicize(detail) : detail}
                      </div>
                    ))}
                  </div>
                </a>
              </div>
            ))}
            <div
              className={`see-more ${showMoreProducts || !isMobile || plpData.length < 8 ? "d-none" : ""}`}
              role="button"
              tabIndex="0"
              onClick={() => setShowMoreProducts(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  setShowMoreProducts(true);
                }
              }}
            >
              See More
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PlpFilter.propTypes = {
  data: PropTypes.object.isRequired,
  showMobileFilters: PropTypes.bool,
  setShowMobileFilters: PropTypes.func,
  mobileFiltersRecentlyClosed: PropTypes.bool,
  setMobileFiltersRecentlyClosed: PropTypes.func,
  creditTypesSelected: PropTypes.array,
  setCreditTypesSelected: PropTypes.func,
  numberOfCreditsSelected: PropTypes.array,
  setNumberOfCreditsSelected: PropTypes.func,
  showCreditTypes: PropTypes.bool,
  setShowCreditTypes: PropTypes.func,
  showCreditAmount: PropTypes.bool,
  setShowCreditAmount: PropTypes.func
};
