import { List, Map } from 'immutable';

import Factory from 'factories/_Factory';


export default class QuestionBank extends Factory {
  static jsonType = 'question_bank';

  static afterUpdate(record, json) {
    let newRecord = record;

    const { attributes } = json;

    if (attributes.psi_board_exam_interface_options) {
      newRecord = newRecord.set('psi_board_exam_interface_options', new List(attributes.psi_board_exam_interface_options));
    }

    if (attributes.fred_board_exam_interface_options) {
      newRecord = newRecord.set('fred_board_exam_interface_options', new List(attributes.fred_board_exam_interface_options));
    }

    if (attributes.pearson_vue_board_exam_interface_options) {
      newRecord = newRecord.set('pearson_vue_board_exam_interface_options', new List(attributes.pearson_vue_board_exam_interface_options));
    }

    if (attributes.cme_product_credits) {
      newRecord = newRecord.set('cme_product_credits', new List(attributes.cme_product_credits.map(j => new Map(j))));
    }

    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      state: 'active',
      has_cme: false,
      has_clinical_pearls: false,
      has_trial_pearls: false,
      adaptive_available: false,
      subject_adaptive_available: false,
      occupation: '',
      pass_likelihood_score: 0,
      average_board_exam_response_time_in_seconds: 0,
      minimum_adaptive_questions: 0,
      maximum_adaptive_questions: 0,
      hide_feedback: false,
      count_specifications_rendered: '',
      brief_marketing_blurb: '',
      rounded_question_count: 0,
      vital_prep_question_count: 0,
      vital_prep_rounded_question_count: 0,
      board_info: '',
      show_patient_box: false,
      question_count: 0,
      psi_board_exam_interface_options: new List(),
      fred_board_exam_interface_options: new List(),
      pearson_vue_board_exam_interface_options: new List(),
      peer_score_standard_deviation: 0,
      peer_score_standard_deviation_practice: 0,
      cme_product_id: 0,
      cme_product_price: 0,
      pdp_path: '',
      cme_product_credits: new List(),
      peer_rank_threshold: 100,
      ngn_group_minimum_questions: 0,
      ngn_minimum_questions: 0,
      is_ngn: false
    };
  }

  static get hasMany() {
    return ['adaptive_eligible_subject', 'practice_exam_template'];
  }
}
