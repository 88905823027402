import { useState, useEffect, useRef } from "react";


// https://codesandbox.io/s/y3qzyr3lrz?file=/src/index.js:111-127
export function useMultiKeyPress() {
  const [keysPressed, setKeyPressed] = useState(new Set([]));

  function downHandler({ key }) {
    setKeyPressed(keysPressed.add(key));
  }

  function upHandler({ key }) {
    keysPressed.delete(key);
    setKeyPressed(keysPressed);
  }

  function areKeysPressed(keys = []) {
    const requiredKeys = new Set(keys);
    for (const elem of keysPressed) {
      requiredKeys.delete(elem);
    }
    return requiredKeys.size === 0;
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return areKeysPressed;
}

// https://usehooks.com/useKeyPress/
export function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

export function useOnKeyPress(targetKey, callback) {
  const keyPressedRef = useRef(false);

  useEffect(() => {
    const downHandler = ({ key, target }) => {
      // Skip key events from text inputs
      if (target.type === 'textarea' || target.type === 'input') return;

      if (key === targetKey) {
        keyPressedRef.current = true;
      }
    };

    const upHandler = ({ key, target }) => {
      // Skip key events from text inputs
      if (target.type === 'textarea' || target.type === 'input') return;

      if (key === targetKey) {
        if (keyPressedRef.current) callback();
        keyPressedRef.current = false;
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
}

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
}
