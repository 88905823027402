import QuizQuestionFactory from 'factories/_QuizQuestionFactory';


export default class Note extends QuizQuestionFactory {
  static jsonType = 'note';

  static get defaults() {
    return {
      body: ''
    };
  }

  static get belongsTo() {
    return ['quiz', 'question'];
  }
}
