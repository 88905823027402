import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFlagIndicator(props) {
  return (
    <SvgContainer title="Flag Indicator Icon" {...props}>
      <defs>
        <style type="text/css">
          {".flag-ind-st1{fill:#000000;}"}
        </style>
      </defs>
      <path d="M25.1 7.4S19.2 17.7 11 17.6L6 4.8s4.2-1.6 8.8 1.3c.1 0 5.7 3.1 10.3 1.3z" fill="#b92025" /><path className="flag-ind-st1" d="M11.1 18.2h-.5L5.2 4.5l.6-.2c.2-.1 4.6-1.7 9.4 1.3 0 0 5.4 2.9 9.7 1.3l1.6-.6-.9 1.5c-.2.3-6 10.4-14.5 10.4zm-4.3-13L11.5 17c5.8-.2 10.5-5.9 12.3-8.6-4.4.8-9-1.7-9.2-1.8-3.3-2.1-6.5-1.7-7.8-1.4z" /><path transform="rotate(-20.156 13.316 23.747)" className="flag-ind-st1" d="M12.7 17.2h1.2v13.2h-1.2z" />
    </SvgContainer>
  );
}
