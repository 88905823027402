import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgProfile(props) {
  return (
    <SvgContainer title="Products" {...props} viewBoxHeight={13} viewBoxWidth={13}>
      <path fill="#4A4A4A" strokeWidth="0" d="M11.847 8.817c-.272-.204-1.025-.48-2.505-.988a21.97 21.97 0 01-.826-.291c-.149-.063-.212-.117-.177-.362.031-.094.2-.353.322-.544.423-.654 1.062-1.642 1.062-2.72C9.723 1.754 8.277 0 6.5 0S3.277 1.755 3.277 3.911c0 1.08.639 2.067 1.062 2.721.123.19.29.45.323.544.034.245-.03.299-.178.362a21.97 21.97 0 01-.826.291c-1.48.507-2.233.784-2.505.988C.16 9.564.018 11.961 0 12.434c-.011.3.214.554.504.566h.021c.28 0 .513-.23.524-.523.06-1.571.465-2.586.717-2.775.226-.155 1.573-.617 2.221-.838.481-.165.76-.26.894-.318.626-.266.924-.83.816-1.55-.043-.285-.23-.572-.487-.97-.373-.578-.884-1.367-.884-2.115 0-1.53.996-2.822 2.174-2.822s2.174 1.293 2.174 2.822c0 .748-.511 1.537-.884 2.114-.258.399-.444.687-.487.971-.108.72.19 1.284.816 1.55.134.057.413.153.894.318.648.221 1.995.683 2.218.836.39.292.677 1.669.72 2.777a.534.534 0 00.524.523h.021a.538.538 0 00.504-.566c-.018-.473-.159-2.87-1.153-3.617" />
    </SvgContainer>
  );
}
