import React from 'react';
import PropTypes from 'prop-types';

import indexToLetter from 'helpers/indexToLetter';

import WYSIWYG from 'components/WYSIWYG';
import Image from 'components/media/Image';
import PeerComparisonGraph from './LegacyPeerComparisonGraph';

class LegacyExplanation extends React.Component {
  render() {
    const { question, peerComparisonPercents } = this.props;
    const difficultyLevel = question.get('difficulty_level');
    const correctAnswers = question.get('answers').filter(answer => answer.get('is_correct')).toArray();
    // We need to split the explanation on newlines and wrap each part in a <p> tag
    return (
      <div className="question-explanation">
        { (question.get('type') === 'MultipleChoiceQuestion' && question.get('correct_answer_ids').size === 1) && (
          <div className="question-correct-answer">
            Correct Answer:&nbsp;
            { indexToLetter(correctAnswers[0].get('choice')) }.&nbsp;
            <WYSIWYG className="answer-name">{correctAnswers[0].get('safe_name')}</WYSIWYG>
          </div>
        )}
        <WYSIWYG className="question-explanation-content">{ question.get('safe_explanation') }</WYSIWYG>
        { question.get('explanation_images').size > 0 && (
          <ul className="explanation-images">
            { question.get('explanation_images').map((image, key) => (
              <li className="explanation-image" key={key}>
                <Image image={image} />
              </li>
            )) }
          </ul>
        )}
        { question.get('plain_reference') !== '' && (
          <div>
            <h5 className="question-reference-heading">References:</h5>
            <WYSIWYG className="question-reference">{ question.get('safe_reference') }</WYSIWYG>
          </div>
        )}
        { peerComparisonPercents && <PeerComparisonGraph peerComparisonPercents={peerComparisonPercents} /> }
        { difficultyLevel && (
          <div className="difficulty-level">
            <span className="header">Difficulty: </span><br />
            <span className={`${difficultyLevel} value`} />
          </div>
        ) }
      </div>
    );
  }
}

LegacyExplanation.propTypes = {
  question: PropTypes.object.isRequired,
  peerComparisonPercents: PropTypes.object
};

export default LegacyExplanation;
