import React, { useEffect } from "react";
import PropTypes from "prop-types";

import PageSection from "./PageSection";

const Accreditors = ({ dataAccreditors, questionBankName }) => {
  useEffect(() => {
    const container = document.getElementById("paragraph-wrapper");

    if (container.childElementCount === 0) {
      dataAccreditors.forEach((accreditor) => {
        const statement = accreditor.statement.replace(/<br\s*\/?>/gi, '');
        const formattedParagraph = document.createElement("p");
        formattedParagraph.innerHTML = statement;
        formattedParagraph.classList.add("mb-5");
        formattedParagraph.style.color = "#1b3950";
        container.appendChild(formattedParagraph);

        const italicized = formattedParagraph.querySelector("i");
        const anchors = formattedParagraph.querySelectorAll("a");
        if (italicized) italicized.style.fontStyle = "italic";
        if (anchors) {
          anchors.forEach(function(anchor) {
            anchor.style.color = "#0044db";
            anchor.style.textDecoration = "underline";
          });
        }
      });
    }
  }, [dataAccreditors]);

  return (
    <PageSection section="accreditors">
      <h2 className="text-left text-lg-center text-xl-center mb-6">{questionBankName} Accreditations</h2>
      <div id="paragraph-wrapper" className="storefront-body-text" />
    </PageSection>
  );
};

Accreditors.propTypes = {
  dataAccreditors: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.array
  ]).isRequired,
  questionBankName: PropTypes.string
};

export default Accreditors;
