import api from 'helpers/api';
import { utcToDateStr } from 'helpers/dateTime';


const startAndEndDateHelper = (startDate, endDate, hasOtherParams = false) => {
  const leadingChar = hasOtherParams ? '&' : '?';
  if (startDate === '' && endDate === '') return '';
  if (!!startDate && !!endDate) return `${leadingChar}start=${startDate}&end=${endDate}`;
  if (startDate) return `${leadingChar}start=${startDate}`;
  if (endDate) return `${leadingChar}end=${endDate}`;
  return '';
};

export const PERFORMANCE_QUIZZES_FETCHING = 'PERFORMANCE_QUIZZES_FETCHING';
export const PERFORMANCE_QUIZZES_FETCHED = 'PERFORMANCE_QUIZZES_FETCHED';
export const PERFORMANCE_QUIZZES_FETCH_ERROR = 'PERFORMANCE_QUIZZES_FETCH_ERROR';
const performanceQuizzesFetchKey = userId => `performance/quizzes/${userId}`;
export const performanceQuizzesFetch = (userIdArg, startDateArg, endDateArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performanceQuizzesFetchKey(userId);

  if (loading.has(key)) return loading.get(key);

  const startDate = startDateArg || '';
  const endDate = endDateArg || '';

  const promise = api.get(`users/${userId}/performance/quizzes${startAndEndDateHelper(startDate, endDate)}`)
    .then((response) => {
      dispatch({
        type: PERFORMANCE_QUIZZES_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: PERFORMANCE_QUIZZES_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: PERFORMANCE_QUIZZES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performanceQuizzesFetch.getKey = performanceQuizzesFetchKey;


export const PERFORMANCE_RESPONSES_FETCHING = 'PERFORMANCE_RESPONSES_FETCHING';
export const PERFORMANCE_RESPONSES_FETCHED = 'PERFORMANCE_RESPONSES_FETCHED';
export const PERFORMANCE_RESPONSES_FETCH_ERROR = 'PERFORMANCE_RESPONSES_FETCH_ERROR';
const performanceResponsesFetchKey = userId => `performance/responses/${userId}`;
export const performanceResponsesFetch = (userIdArg, startDateArg, endDateArg) => (dispatch, getState) => {
  const { loading, loadedAt, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performanceResponsesFetchKey(userId);

  if (loading.has(key)) return loading.get(key);

  const promise = new Promise(async (resolve, reject) => {
    try {
      // Get Response counts from the API
      const startDate = startDateArg || utcToDateStr(loadedAt.get(key));
      const endDate = endDateArg || (startDate.length ? new Date().toISOString().slice(0, 10) : '');
      const countResponse = await api.get(`users/${userId}/performance/responses_count${startAndEndDateHelper(startDate, endDate)}`);

      // Get all paginated Responses from the API
      const promises = [];
      for (let i = 0; i < countResponse.page_count; i += 1) {
        promises.push(api.get(`users/${userId}/performance/responses?page=${i + 1}${startAndEndDateHelper(startDate, endDate, true)}`, { timeout: 20000 }));
      }
      const apiResponses = await Promise.all(promises);

      dispatch({
        type: PERFORMANCE_RESPONSES_FETCHED,
        payload: { key, apiResponses }
      });

      resolve(apiResponses);
    } catch (error) {
      dispatch({
        type: PERFORMANCE_RESPONSES_FETCH_ERROR,
        payload: { key, error }
      });
      reject(error);
    }
  });

  dispatch({
    type: PERFORMANCE_RESPONSES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performanceResponsesFetch.getKey = performanceResponsesFetchKey;


export const PERFORMANCE_QUESTIONS_FETCHING = 'PERFORMANCE_QUESTIONS_FETCHING';
export const PERFORMANCE_QUESTIONS_FETCHED = 'PERFORMANCE_QUESTIONS_FETCHED';
export const PERFORMANCE_QUESTIONS_FETCH_ERROR = 'PERFORMANCE_QUESTIONS_FETCH_ERROR';
const performanceQuestionsFetchKey = (questionBankId, userId) => `performance/questions/${questionBankId}/${userId}`;
export const performanceQuestionsFetch = (questionBankIdArg, userIdArg, startDateArg, endDateArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id');
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performanceQuestionsFetchKey(questionBankId, userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/performance/questions`)
    .then((response) => {
      dispatch({
        type: PERFORMANCE_QUESTIONS_FETCHED,
        payload: { key, response }
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: PERFORMANCE_QUESTIONS_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: PERFORMANCE_QUESTIONS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performanceQuestionsFetch.getKey = performanceQuestionsFetchKey;


export const PERFORMANCE_PEERS_FETCHING = 'PERFORMANCE_PEERS_FETCHING';
export const PERFORMANCE_PEERS_FETCHED = 'PERFORMANCE_PEERS_FETCHED';
export const PERFORMANCE_PEERS_ERROR = 'PERFORMANCE_PEERS_ERROR';
const performancePeersFetchKey = (questionBankId, userId) => `performance/peers/${questionBankId}/${userId}`;
export const performancePeersFetch = (questionBankIdArg, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id');
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performancePeersFetchKey(questionBankId, userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/performance/peers`)
    .then((response) => {
      dispatch({
        type: PERFORMANCE_PEERS_FETCHED,
        payload: { key, response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: PERFORMANCE_PEERS_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: PERFORMANCE_PEERS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performancePeersFetch.getKey = performancePeersFetchKey;

export const PERFORMANCE_ALL_STUDENTS_FETCHING = 'PERFORMANCE_ALL_STUDENTS_FETCHING';
export const PERFORMANCE_ALL_STUDENTS_FETCHED = 'PERFORMANCE_ALL_STUDENTS_FETCHED';
export const PERFORMANCE_ALL_STUDENTS_ERROR = 'PERFORMANCE_ALL_STUDENTS_ERROR';
const performanceAllStudentsFetchKey = (questionBankId, userId) => `performance/all_students/${questionBankId}/${userId}`;
export const performanceAllStudentsFetch = (questionBankIdArg, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id');
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performanceAllStudentsFetchKey(questionBankId, userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/performance/all_students`)
    .then((response) => {
      dispatch({
        type: PERFORMANCE_ALL_STUDENTS_FETCHED,
        payload: { key, response }
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: PERFORMANCE_ALL_STUDENTS_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: PERFORMANCE_ALL_STUDENTS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performanceAllStudentsFetch.getKey = performanceAllStudentsFetchKey;

export const PERFORMANCE_ORG_PEERS_FETCHING = 'PERFORMANCE_ORG_PEERS_FETCHING';
export const PERFORMANCE_ORG_PEERS_FETCHED = 'PERFORMANCE_ORG_PEERS_FETCHED';
export const PERFORMANCE_ORG_PEERS_ERROR = 'PERFORMANCE_ORG_PEERS_ERROR';
const performanceSchoolPeersFetchKey = (questionBankId, userId) => `performance/org_peers/${questionBankId}/${userId}`;
export const performanceSchoolPeersFetch = (questionBankIdArg, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id');
  const organizationId = session.get('selected_organization_id');
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = performanceSchoolPeersFetchKey(questionBankId, userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/performance/org_peers?organization_id=${organizationId}`)
    .then((response) => {
      dispatch({
        type: PERFORMANCE_ORG_PEERS_FETCHED,
        payload: { key, response }
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: PERFORMANCE_ORG_PEERS_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: PERFORMANCE_ORG_PEERS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
performanceSchoolPeersFetch.getKey = performanceSchoolPeersFetchKey;
