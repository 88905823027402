import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeStatPractitionersTrained(props) {
  return (
    <SvgContainer title="Home Stat Practitioners Trained Icon" {...props}>
      <g fill="#FDA100">
        <path d="M45.563 15.375h-8.438c-1.013 0-1.688.683-1.688 1.708s.675 1.709 1.688 1.709h8.438c3.037 0 5.062 2.05 5.062 5.125v8.541h-6.75c-1.013 0-1.688.684-1.688 1.709s.675 1.708 1.688 1.708h8.438c1.012 0 1.687-.683 1.687-1.708v-10.25c0-4.955-3.544-8.542-8.438-8.542ZM10.125 32.458h-6.75v-8.541c0-3.075 2.025-5.125 5.063-5.125h8.437c1.012 0 1.688-.684 1.688-1.709s-.675-1.708-1.688-1.708H8.437C3.545 15.375 0 18.962 0 23.917v10.25c0 1.025.675 1.708 1.688 1.708h8.437c1.012 0 1.688-.683 1.688-1.708s-.676-1.709-1.688-1.709ZM27 17.083c3.712 0 6.75-3.075 6.75-6.833S30.712 3.417 27 3.417s-6.75 3.075-6.75 6.833 3.038 6.833 6.75 6.833Zm0-10.25c1.856 0 3.375 1.538 3.375 3.417 0 1.879-1.519 3.416-3.375 3.416s-3.375-1.537-3.375-3.416c0-1.88 1.519-3.417 3.375-3.417ZM11.813 13.667c3.712 0 6.75-3.075 6.75-6.834 0-3.758-3.038-6.833-6.75-6.833-3.713 0-6.75 3.075-6.75 6.833 0 3.759 3.037 6.834 6.75 6.834Zm0-10.25c1.856 0 3.374 1.537 3.374 3.416 0 1.88-1.518 3.417-3.374 3.417-1.857 0-3.376-1.537-3.376-3.417 0-1.879 1.52-3.416 3.376-3.416ZM42.188 13.667c3.712 0 6.75-3.075 6.75-6.834C48.938 3.075 45.9 0 42.188 0c-3.713 0-6.75 3.075-6.75 6.833 0 3.759 3.037 6.834 6.75 6.834Zm0-10.25c1.856 0 3.374 1.537 3.374 3.416 0 1.88-1.518 3.417-3.374 3.417-1.857 0-3.376-1.537-3.376-3.417 0-1.879 1.52-3.416 3.376-3.416Z" />
        <path d="M30.375 18.791h-6.75c-4.894 0-8.438 3.588-8.438 8.542v11.959c0 1.024.676 1.708 1.688 1.708h20.25c1.013 0 1.688-.684 1.688-1.709V27.334c0-4.954-3.544-8.541-8.438-8.541Zm5.063 18.792H18.561v-10.25c0-3.075 2.025-5.125 5.063-5.125h6.75c3.038 0 5.063 2.05 5.063 5.125v10.25Z" />
      </g>
    </SvgContainer>
  );
}
