import React from "react";
import PropTypes from "prop-types";

import PageSection from "./PageSection";

const Markdown = ({ content, titleText }) => {
  const markdownHtml = `<h2>${titleText}</h2>${content}`;

  return (
    <PageSection section="markdown">
      {/* eslint-disable-next-line react/no-danger */}
      <div className="content" dangerouslySetInnerHTML={{ __html: markdownHtml }} />
    </PageSection>
  );
};

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired
};

export default Markdown;
