/*  eslint-disable react/no-danger */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import SVGIcon from 'components/SVGIcon';
import ProductDetails from "./ProductDetails";

const PageHeader = ({
  headerImage,
  headerInfo,
  headerRef,
  breadcrumbData
}) => {
  const {
    name,
    product_type: productType,
    heading,
    multiple_blurbs: multipleBurbs,
    blurbs,
    blurb
  } = headerInfo;

  const {
    base_url: baseUrl,
    product_path: productPath
  } = breadcrumbData;

  const imgStyle = {
    backgroundImage: `url(${headerImage?.original_url})`,
    backgroundSize: 'cover'
  };

  const formattedProductType = (type, isSrc = false) => {
    switch (type) {
      case "initial_certification":
        return isSrc ? "/medical-board-reviews" : "Initial Certification";
      case "moc":
        return isSrc ? "/moc-recertification-exams-prep" : "Recertification";
      case "cme":
        return isSrc ? "/cme" : "CME";
      default:
        break;
    }
  };

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": `${formattedProductType(productType)}`,
        "item": `${baseUrl}/${formattedProductType(productType, true)}`
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": `${name}`,
        "item": `${baseUrl}/${productPath}`
      }
    ]
  };

  const blurbsJsx = (
    multipleBurbs ? (
      <div className="d-flex align-items-center">
        <ul>
          { blurbs.map((tmpBlurb, index) => (
            <span key={index} className="blurb d-flex align-items-start mb-4">
              <SVGIcon name="CircleCheck" width={16} height={16} className="circle-check" />
              <li>
                <div dangerouslySetInnerHTML={{ __html: tmpBlurb }} />
              </li>
            </span>
          ))}
        </ul>
      </div>

    ) : (
      <div className="single-blurb" dangerouslySetInnerHTML={{ __html: blurb }} />
    )
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(breadcrumbSchema);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <ProductDetails section="page-header px-lg-6 px-xl-6">
      <div className="details pr-6" ref={headerRef}>
        <div className="details-content  mr-6 d-flex justify-content-center flex-column">
          <div className="name mb-3"><a href={formattedProductType(productType, true)} className="storefront-link">{formattedProductType(productType)}</a> / <a href="" className="storefront-link">{name}</a></div>
          <div className="d-flex flex-column justify-content-center">
            <h1 className="mb-6 storefront-primary-title">{heading}</h1>
            { blurbsJsx }
          </div>

        </div>
      </div>

      <div
        style={imgStyle}
        className="banner-image"
      />

      <div className="page-header-tablet-xl">
        <div className="name mb-3"><a href={formattedProductType(productType, true)}><u>{formattedProductType(productType)}</u></a> / <a href=""><u>{name}</u></a></div>

        <h1 className="mb-6">{heading}</h1>
        <div className="info-container d-flex align-items-start">
          <div className="blurbs">
            { blurbsJsx }
          </div>
          <div
            style={imgStyle}
            className="banner-image-tablet-xl"
          />
        </div>
      </div>
    </ProductDetails>
  );
};

PageHeader.propTypes = {
  headerImage: PropTypes.object,
  headerInfo: PropTypes.object,
  headerRef: PropTypes.func,
};

export default PageHeader;
