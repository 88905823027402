import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";

import { cmeCoachSendEmail } from "actions/cmeCoach";
import { hubspotSubmitCmeCoach } from "actions/hubspot";

import LoadingIcon from 'components/LoadingIcon';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';

export default function CmeCoachSendEmail({ cmeCoach }) {
  const [inputEmail, setInputemail] = useState("");

  const dispatch = useDispatch();

  const onSubmitEmail = (email) => {
    hubspotSubmitCmeCoach(email);
    return dispatch(cmeCoachSendEmail(email));
  };

  const onSubmitEmailForm = (ev) => {
    ev.preventDefault();

    onSubmitEmail(inputEmail)
      .then(() => setInputemail(inputEmail))
      .catch(() => {});
  };

  return (
    <div className="send-email-wrapper">
      <div className="send-email-title">
        <h3>Get a copy of your CME requirements for easy reference</h3>
      </div>
      <div className="send-email-form">
        <form onSubmit={ev => onSubmitEmailForm(ev)}>
          <div className="cme-coach-email-form-wrapper d-md-flex mt-4">
            <div className="form-group form-input">
              <Input
                name="cme-coach-email"
                className="input"
                type="email"
                placeholder="Email"
                required
                value={inputEmail}
                onChange={value => setInputemail(value)}
                disabled={cmeCoach.get('isSendingEmail')}
              />
            </div>
            <div className="form-group form-button ml-4">
              <Button
                type="submit"
                disabled={cmeCoach.get("isSendingEmail")}
              >
                Send
              </Button>
            </div>
            { cmeCoach.get("isSendingEmail") && (
            <div className="form-group">
              <LoadingIcon hideLabel />
            </div>
            )}

            {(!cmeCoach.get("isSendingEmail") && cmeCoach.get("sendEmailStatus") !== null) && (
            <div className="form-group">
                {cmeCoach.get("sendEmailStatus") ? "Email sent!" : "There was an error sending your email. Please try again later or with a different email address."}
            </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

CmeCoachSendEmail.propTypes = {
  cmeCoach: PropTypes.object.isRequired
};
