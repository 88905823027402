import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "components/SVGIcon";

const Modal = ({
  onClose,
  showModal,
  sampleAnswer,
  sampleQuestion
}) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      {showModal && (
        <div className="sample-modal-overlay">
          <div className="sample-modal">
            <div className="sample-modal-header">
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>

            <div className="sample-modal-body py-0">
              <div className="row bordered padded py-3 my-3">
                <div className="col-md-6 element bordered-right">
                  {sampleAnswer ? (
                    <>
                      <div className="col-md-3 pr-0">
                        {sampleAnswer.correct ? (
                          <SVGIcon name="Check" className="correct" width={24} height={24} />
                        ) : (
                          <SVGIcon name="X" className="incorrect" width={24} height={24} />
                        )}
                      </div>
                      <div className="col-md-9 pl-0">
                        <p className="vertical-align-center mb-0">
                          {sampleAnswer.correct
                            ? "Correct! You got it right."
                            : "Incorrect. Review the answer and explanation below."}
                        </p>
                      </div>
                    </>
                  ) : (
                    <div>Review the answer and explanation below.</div>
                  )}
                </div>
                <div className="col-md-6 element">
                  <div className="col-md-3 pr-0">
                    <span className="green bold">
                      {sampleQuestion.average_correct_percentage}%
                    </span>
                  </div>
                  <div className="col-md-9 pl-0">
                    <p className="vertical-align-center mb-0">
                      of exam takers got this question right.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 bordered question pt-3 pb-4 mb-3">
                  <div className="col-md-1">
                    <span className="bold q-element">Q:</span>
                  </div>
                  <div className="col-md-11">
                    <p
                      /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{ __html: sampleQuestion?.name }}
                    />
                    {sampleQuestion.images && sampleQuestion.images.map(img => <img src={img.url} alt="Question" />)}
                  </div>
                </div>
                <div className="col-md-12 bordered explanation py-3">
                  <div className="col-md-1">
                    <SVGIcon name="CircleCheck" width={24} height={24} />
                  </div>
                  <div className="col-md-11">
                    <p
                      /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{
                        __html: sampleQuestion?.explanation.safe_name ? sampleQuestion.explanation.safe_name.split(/Incorrect Answers:.*/i)[0] : ''
                      }}
                    />
                    {sampleQuestion.explanation.images && sampleQuestion.explanation.images.map(img => <img src={img.url} alt="Explanation" />)}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12 bordered py-3">
                  <h4 className="text-center">
                    Want more questions like this?
                  </h4>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary storefront-button-text"
                      onClick={closeModal}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="sample-modal-footer">
              <div
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: sampleQuestion?.safe_reference,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  sampleAnswer: PropTypes.object.isRequired,
  sampleQuestion: PropTypes.object.isRequired
};
