import { Map, Set } from 'immutable';
import { pluralize } from '../helpers/stringHelper';


/* Example Options:
const options = {
  fetched: true, // also updates synced attribute unless options.synced is explicitly set
  synced: true
};
*/


export default class Factory {
  // Child classes must define jsonType
  static jsonType = '';

  // jsonTypePlural can be overriden by defining it in the child Factory class
  static getJsonTypePlural() {
    return this.jsonTypePlural || this.jsonType + 's';
  }

  constructor(json, options = {}) {
    if (!this.constructor.jsonType) throw new Error(`Factory '${this.constructor.name}' must define jsonType`);
    this.constructor.validateJSON(json);
    const factory = Map({ id: parseInt(json.id) || null });
    return this.constructor.update(factory, json, options);
  }

  static validateJSON(json) {
    if (!json.id || json.id < 0) throw new Error(`ID is required to create a new ${this.constructor.name}`);
  }

  static update(state, json, options = {}) {
    if (!json.attributes) json.attributes = {};
    if (!json.relationships) json.relationships = {};

    const result = {};

    // Extract Attributes
    const { defaults } = this;
    Object.keys(defaults).forEach((key) => {
      if (json.attributes
        && Object.prototype.hasOwnProperty.call(json.attributes, key)
        && json.attributes[key] !== null) {
        result[key] = json.attributes[key];
      } else if (state.get(key) === undefined) {
        result[key] = defaults[key];
      }
    });

    // Extract Relationship IDs
    this.belongsTo.forEach((recordType) => {
      if (json.relationships
        && json.relationships[recordType]
        && json.relationships[recordType].data) {
        result[`${recordType}_id`] = parseInt(json.relationships[recordType].data.id);
      } else if (state.get(`${recordType}_id`) === undefined) {
        result[`${recordType}_id`] = 0;
      }
    });

    this.hasMany.forEach((recordType) => {
      const recordTypePlural = pluralize(recordType);
      if (json.relationships
        && json.relationships[recordTypePlural]
        && json.relationships[recordTypePlural].data
        && Array.isArray(json.relationships[recordTypePlural].data)) {
        const recordIds = json.relationships[recordTypePlural].data
          .filter(record => parseInt(record.id))
          .map(record => parseInt(record.id));
        result[`${recordType}_ids`] = new Set(recordIds);
      } else if (state.get(`${recordType}_ids`) === undefined) {
        result[`${recordType}_ids`] = new Set([]);
      }
    });

    // Update State with extracted Attributes and Relationship IDs
    let newState = state.merge(result);

    // Perform afterUpdate() from child Factory classes
    newState = this.afterUpdate(newState, json);

    // Update the created_at attribute
    if (json.attributes && json.attributes.created_at) {
      newState = newState.set('created_at', new Date(json.attributes.created_at).getTime());
    } else if (newState.get('created_at') === undefined) {
      newState = newState.set('created_at', 0);
    }

    // Update the fetched_at attribute unless options.fetched = false
    if (options.fetched === undefined || options.fetched) {
      newState = newState
        .set('fetched_at', new Date().getTime())
        .set('synced', options.synced !== undefined ? !!options.synced : true);
      if (json.attributes && json.attributes.updated_at) {
        newState = newState.set('updated_at', new Date(json.attributes.updated_at).getTime());
      } else {
        newState = newState.set('updated_at', 0);
      }
    } else {
      if (newState.get('fetched_at') === undefined) {
        newState = newState.set('fetched_at', null);
      }
      newState = newState
        .set('synced', options.synced !== undefined ? !!options.synced : false)
        .set('updated_at', new Date().getTime());
    }

    return newState;
  }


  // Overrideable Functions:

  static get defaults() {
    return {};
  }

  static get belongsTo() {
    return [];
  }

  static get hasMany() {
    return [];
  }

  static afterUpdate(state, json) {
    return state;
  }

  static get helperFunctions() {
    return {};
  }
}
