import { Map } from "immutable";

import api, { currentUserUrl } from 'helpers/api';


export const OFFLINE_QUIZ_SYNCING = 'OFFLINE_QUIZ_SYNCING';
export const OFFLINE_QUIZ_SYNCED = 'OFFLINE_QUIZ_SYNCED';
export const OFFLINE_QUIZ_SYNC_ERROR = 'OFFLINE_QUIZ_SYNC_ERROR';
const getOfflineQuizSyncKey = quizId => `offline-sync/${quizId}`;
export const offlineQuizSync = (quiz, responses) => (dispatch, getState) => {
  const questionBankId = quiz.get('question_bank_id');
  const quizId = quiz.get('id');
  const key = getOfflineQuizSyncKey(quizId);
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);
  const promise = new Promise(async (resolve, reject) => {
    try {
      const quizSyncPromises = [];
      const responseSyncPromises = [];
      const unsyncedResponses = responses ? responses.toList().filter(response => !response.get('synced')) : new Map();
      const unsyncedNotes = quiz.get('notes').toList().filter(note => !note.get('synced'));
      const unsyncedHighlights = quiz.get('highlights').toList().filter(highlight => !highlight.get('synced'));
      const unsyncedStrikeThroughs = quiz.get('strike_throughs').toList().filter(strikeThrough => !strikeThrough.get('synced'));
      // First sync quiz responses in batches
      if (unsyncedResponses.size > 0) {
        const page = 25;
        const length = unsyncedResponses.size;
        for (let i = 0; i < length; i += page) {
          const pagedResponses = unsyncedResponses.slice(i, i + page);
          responseSyncPromises.push(api.patch(`${currentUserUrl(getState())}/question_banks/${questionBankId}/quizzes/${quizId}/offline-sync?v2`, {
            // Endpoint throws error if quiz param is not included in request body
            quiz: {
              seconds_remaining: quiz.get('seconds_remaining'),
              last_seen_question_number: quiz.get('last_seen_question_number'),
              seen_case_question_ids: quiz.get('seen_case_question_ids').toArray(),
              updated_at: quiz.get('updated_at')
            },
            responses: pagedResponses.map(response => ({
              question_id: response.get('question_id'),
              user_input: response.get('user_input'),
              ungraded_user_input: response.get('ungraded_user_input'),
              updated_at: response.get('updated_at'),
              answered_at: response.get('answered_at'),
              evaluated_at: response.get('evaluated_at'),
              correct: response.get('correct'),
              ungraded_correct: response.get('ungraded_correct')
            })).toArray(),
            ignore_evaluation: true
          }));
        }
      }
      if (responseSyncPromises.length > 0) {
        await Promise.all(responseSyncPromises);
      }
      // Then sync quiz related stuff without responses and use api response in reducer since it will include the final question counters
      quizSyncPromises.push(api.patch(`${currentUserUrl(getState())}/question_banks/${questionBankId}/quizzes/${quizId}/offline-sync?v2`, {
        quiz: {
          seconds_remaining: quiz.get('seconds_remaining'),
          last_seen_question_number: quiz.get('last_seen_question_number'),
          seen_case_question_ids: quiz.get('seen_case_question_ids').toArray(),
          updated_at: quiz.get('updated_at')
        },
        time_slices: quiz.get('time_slices').map(timeSlice => ({
          created_at: timeSlice.get('created_at'),
          question_id: timeSlice.get('question_id'),
          event: timeSlice.get('event'),
          time: timeSlice.get('time')
        })).toArray(),
        seen_questions: quiz.get('seen_questions').entrySeq().map(([questionId, lastSeenAt]) => ({
          question_id: questionId,
          last_seen_at: lastSeenAt
        })).toArray(),
        notes: unsyncedNotes.map(note => ({
          question_id: note.get('question_id'),
          body: note.get('body'),
          updated_at: note.get('updated_at')
        })).toArray(),
        highlights: unsyncedHighlights.map(highlight => ({
          question_id: highlight.get('question_id'),
          ranges: highlight.get('ranges'),
          updated_at: highlight.get('updated_at')
        })).toArray(),
        strike_throughs: unsyncedStrikeThroughs.map(strikeThrough => ({
          answer_id: strikeThrough.get('answer_id'),
          deleted: strikeThrough.get('deleted'),
          updated_at: strikeThrough.get('updated_at')
        })).toArray(),
        ignore_evaluation: true
      }));
      const apiResponses = await Promise.all(quizSyncPromises);
      dispatch({
        type: OFFLINE_QUIZ_SYNCED,
        payload: { key, ...apiResponses[0] }
      });
      resolve(apiResponses[0]);
    } catch (error) {
      dispatch({
        type: OFFLINE_QUIZ_SYNC_ERROR,
        payload: { error, key }
      });
      reject(error);
    }
  });

  dispatch({
    type: OFFLINE_QUIZ_SYNCING,
    payload: { key, promise }
  });

  return promise;
};
offlineQuizSync.getKey = getOfflineQuizSyncKey;

// This action is not used anywhere

export const OFFLINE_QUIZZES_SYNCING = 'OFFLINE_QUIZZES_SYNCING';
export const OFFLINE_QUIZZES_SYNCED = 'OFFLINE_QUIZZES_SYNCED';
const getOfflineQuizzesSyncKey = () => 'offline-sync/all';
export const offlineQuizzesSync = quizzes => (dispatch, getState) => {
  const key = getOfflineQuizzesSyncKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = Promise.all(
    quizzes.map(quiz =>
      new Promise((resolve) => {
        dispatch(offlineQuizSync(quiz))
          .then(() => resolve())
          .catch(() => resolve());
      }))
  ).then(() => dispatch({ type: OFFLINE_QUIZZES_SYNCED, payload: { key } }));

  dispatch({ type: OFFLINE_QUIZZES_SYNCING, payload: { key, promise } });
  return promise;
};
offlineQuizzesSync.getKey = getOfflineQuizzesSyncKey;


export const OFFLINE_QUIZ_LOADED = 'OFFLINE_QUIZ_LOADED';
export const offlineQuizLoad = (quizId, quiz, questions, responses) => ({
  type: OFFLINE_QUIZ_LOADED,
  payload: {
    quizId,
    quiz,
    questions,
    responses
  }
});
