import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgUnlinked(props) {
  return (
    <SvgContainer title="Unlinked Icon" {...props}>
      <path d="M17.53 17.298A2.494 2.494 0 0020 19.5h3a2.503 2.503 0 002.5-2.5v-2a2.503 2.503 0 00-2.5-2.5h-.672l-1 1H23a1.502 1.502 0 011.5 1.5v2a1.502 1.502 0 01-1.5 1.5h-3a1.502 1.502 0 01-1.5-1.5v-.672z" />
      <path d="M25.158 9.67l-.86.86A3.493 3.493 0 0127.5 14v4a3.504 3.504 0 01-3.5 3.5h-5a3.494 3.494 0 01-3.298-2.374l-.769.77A4.496 4.496 0 0019 22.5h5a4.505 4.505 0 004.5-4.5v-4a4.494 4.494 0 00-3.342-4.33zM26.354 5.646a.5.5 0 00-.707 0l-20 20a.5.5 0 10.706.707l20-20a.5.5 0 000-.707zM7.702 21.47A3.493 3.493 0 014.5 18v-4A3.504 3.504 0 018 10.5h5a3.494 3.494 0 013.298 2.374l.769-.77A4.496 4.496 0 0013 9.5H8A4.505 4.505 0 003.5 14v4a4.494 4.494 0 003.342 4.33z" />
      <path d="M14.47 14.702A2.494 2.494 0 0012 12.5H9A2.503 2.503 0 006.5 15v2A2.503 2.503 0 009 19.5h.672l1-1H9A1.502 1.502 0 017.5 17v-2A1.502 1.502 0 019 13.5h3a1.502 1.502 0 011.5 1.5v.672z" />
    </SvgContainer>
  );
}
