import Factory from 'factories/_Factory';


export default class Subject extends Factory {
  static jsonType = 'subject';

  static get defaults() {
    return {
      name: '',
      average_correct_percentage: 0,
      background: ''
    };
  }
}
