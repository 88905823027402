import Factory from 'factories/_Factory';


export default class AddonAccessTracker extends Factory {
  static jsonType = 'addon_access_tracker';

  static afterUpdate(record, json) {
    let newRecord = record;

    if (json.relationships.access_item || newRecord.get('access_item_type') === undefined) {
      newRecord = newRecord.set('access_item_type', json.relationships.access_item.data.type);
    }

    return newRecord;
  }

  static get defaults() {
    return {
      charge_id: null,
      original_tracker_id: null,
      cached_charge_amount: null,
      coupon: null,
      deactivated: false
    };
  }

  static get belongsTo() {
    return ['access_item', 'question_bank'];
  }
}
