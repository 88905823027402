import { List, Map, Set } from 'immutable';

import Factory from 'factories/_Factory';
import CmeLicenseRequirement from 'factories/CmeLicenseRequirement';


export default class CmeLicense extends Factory {
  static jsonType = 'cme_license';

  static afterUpdate(record, json) {
    let newRecord = record;
    if (json.attributes.requirements || newRecord.get('requirements_by_moc_year') === undefined) {
      const requirementsJson = json.attributes.requirements || [];
      const mocYears = new Set(requirementsJson.map(requirementJson => requirementJson.attributes.moc_year)).toArray();
      let requirementsByMocYear = Map();
      mocYears.forEach((mocYear) => {
        const requirements = requirementsJson.filter(requirementJson => requirementJson.attributes.moc_year === mocYear)
          .map(requirementJson => new CmeLicenseRequirement(requirementJson));
        requirementsByMocYear = requirementsByMocYear.set(mocYear, new List(requirements));
      });

      newRecord = newRecord.set('requirements_by_moc_year', requirementsByMocYear);
    }
    return newRecord;
  }

  static get defaults() {
    return {
      description: '',
      cme_hours: null,
      renewal_timeframe: null,
      source: ''
    };
  }
}
