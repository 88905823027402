import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPrevious(props) {
  return (
    <SvgContainer title="Previous Icon" {...props}>
      <path d="M53 32a3 3 0 0 1-3 3H27.21l11.45 10.14a3 3 0 1 1-4 4.49l-17.15-15.2a2.94 2.94 0 0 1-1-2.14v-.19a3 3 0 0 1 .93-2.27l17-16.19a3 3 0 1 1 4.1 4.36L27 29h23a3 3 0 0 1 3 3Z" />
    </SvgContainer>
  );
}
