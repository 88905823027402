import QuizQuestionFactory from 'factories/_QuizQuestionFactory';


export default class Highlight extends QuizQuestionFactory {
  static jsonType = 'highlight';

  static get defaults() {
    return {
      ranges: ''
    };
  }

  static get belongsTo() {
    return ['quiz', 'question'];
  }
}
