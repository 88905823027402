import api, { currentUserUrl } from 'helpers/api';

export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

export const USER_UPDATED = 'USER_UPDATED';
const getUserUpdateKey = userId => `user-update/${userId}`;
export const userUpdate = (userId, userData) => (dispatch, getState) => {
  const key = getUserUpdateKey(userId);
  const { loading } = getState();

  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`users/${userId}`, {
    user: userData
  })
    .then((response) => {
      dispatch({
        type: USER_UPDATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: USER_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: USER_LOADING,
    payload: { key, promise }
  });
  return promise;
};
userUpdate.getKey = getUserUpdateKey;

export const USER_SUBSCRIPTIONS_FETCHED = 'USER_SUBSCRIPTIONS_FETCHED';
const getUserSubscriptionKey = userId => `subscriptions/${userId}`;
export const userSubscriptionsFetch = userIdOverride => (dispatch, getState) => {
  const { session, loading } = getState();
  const userId = userIdOverride || session.get('current_user_id');
  const key = getUserSubscriptionKey(userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/subscriptions`)
    .then((response) => {
      dispatch({
        type: USER_SUBSCRIPTIONS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: USER_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: USER_LOADING,
    payload: { key, promise }
  });

  return promise;
};
userSubscriptionsFetch.getKey = getUserSubscriptionKey;

export const USER_SUBSCRIPTION_ACTIVATED = 'USER_SUBSCRIPTIONS_ACTIVATED';
const getUserSubscriptionActivateKey = userId => `subscriptions-activate/${userId}`;
export const userSubscriptionActivate = subscriptionId => (dispatch, getState) => {
  const { session, loading } = getState();
  const userId = session.get('current_user_id');
  const key = getUserSubscriptionActivateKey(userId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${currentUserUrl(getState())}/subscriptions/activate`, { subscriptionId })
    .then((response) => {
      dispatch({
        type: USER_SUBSCRIPTION_ACTIVATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: USER_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: USER_LOADING,
    payload: { key, promise }
  });
  return promise;
};
userSubscriptionActivate.getKey = getUserSubscriptionActivateKey;

export const GENERAL_FEEDBACK_SUBMITTING = 'GENERAL_FEEDBACK_SUBMITTING';
export const GENERAL_FEEDBACK_SUBMITTED = 'GENERAL_FEEDBACK_SUBMITTED';
export const GENERAL_FEEDBACK_ERROR = 'GENERAL_FEEDBACK_ERROR';
const getUserFeedbackKey = () => 'user-feedback-submitting';
export const userFeedback = (userId, questionBankId, content, feedbackType, mobileOS, mobileBuild, mobileVersion) => (dispatch, getState) => {
  const key = getUserFeedbackKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`users/${userId}/feedback`, {
    feedback: {
      question_bank_id: questionBankId,
      message: content,
      feedback_type: feedbackType,
      mobile_os: mobileOS,
      mobile_build: mobileBuild,
      mobile_version: mobileVersion
    }
  })
    .then((response) => {
      dispatch({
        type: GENERAL_FEEDBACK_SUBMITTED,
        payload: {}
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: GENERAL_FEEDBACK_ERROR,
        payload: { error }
      });
      throw error;
    });

  dispatch({
    type: GENERAL_FEEDBACK_SUBMITTING,
    payload: { key, promise }
  });
  return promise;
};
userFeedback.getKey = getUserFeedbackKey;

export const USER_CONFIRMATION_EMAIL_SENDING = 'USER_CONFIRMATION_EMAIL_SENDING';
export const USER_CONFIRMATION_EMAIL_SENT = 'USER_CONFIRMATION_EMAIL_SENT';
export const USER_CONFIRMATION_EMAIL_SENDING_EROR = 'USER_CONFIRMATION_EMAIL_SENDING_EROR';
const getUserConfirmationEmailKey = () => `user-confirmation-sending`;
export const resendConfirmationEmail = userId => (dispatch, getState) => {
  const key = getUserConfirmationEmailKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/resend_confirmation_email/`)
    .then((response) => {
      dispatch({
        type: USER_CONFIRMATION_EMAIL_SENT,
        payload: { key }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: USER_CONFIRMATION_EMAIL_SENDING_EROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: USER_CONFIRMATION_EMAIL_SENDING,
    payload: { key, promise }
  });
  return promise;
};
getUserConfirmationEmailKey.getKey = getUserConfirmationEmailKey;

export const USER_RESETTING_PASSWORD = 'USER_RESETTING_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
const getUserResetPasswordKey = () => 'user-reset-password';
export const userResetPassword = (userId, currentPassword, password, passwordConfirmation) => (dispatch, getState) => {
  const key = getUserResetPasswordKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`users/${userId}/account-password-reset`, {
    user: {
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirmation
    }
  })
    .then((response) => {
      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: { key }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: USER_RESET_PASSWORD_ERROR,
        payload: { key, error }
      });
      throw error;
    });
  dispatch({
    type: USER_RESETTING_PASSWORD,
    payload: { key, promise }
  });
  return promise;
};
userResetPassword.getKey = getUserResetPasswordKey;

export const USER_ACCESS_CODE_SUBMITTED = 'USER_ACCESS_CODE_SUBMITTED';
export const USER_ACCESS_CODE_SUBMISSION_ERROR = 'USER_ACCESS_CODE_SUBMISSION_ERROR';
const getUserAccessCodeSubmittingKey = userId => `redeem/${userId}`;
export const userSubmitAccessCode = (userId, accessCode) => (dispatch, getState) => {
  const key = getUserAccessCodeSubmittingKey(userId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`access_codes/${accessCode}/redeem/`, {
    user_id: userId
  }).then((response) => {
    dispatch({
      type: USER_SUBSCRIPTIONS_FETCHED,
      payload: { key, ...response }
    });
    return response;
  })
    .catch((error) => {
      dispatch({
        type: USER_ACCESS_CODE_SUBMISSION_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: USER_LOADING,
    payload: { key, promise }
  });
  return promise;
};
userSubmitAccessCode.getKey = getUserAccessCodeSubmittingKey;

export const USER_ACCOUNT_AUTH_SUCCESS = 'USER_ACCOUNT_AUTH_SUCCESS';
export const USER_ACCOUNT_AUTH_FAILURE = 'USER_ACCOUNT_AUTH_FAILURE';
const getUserAccountAuthKey = () => 'user-account-auth';
export const userAccountAuth = (userId, email, password) => (dispatch, getState) => {
  const key = getUserAccountAuthKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`users/${userId}/account-auth`, {
    email: email,
    password: password
  })
    .then((response) => {
      if (response.auth_valid) {
        dispatch({
          type: USER_ACCOUNT_AUTH_SUCCESS,
          payload: { key }
        });
        return response.auth_valid;
      }

      dispatch({
        type: USER_ACCOUNT_AUTH_FAILURE,
        payload: { key }
      });

      return false;
    })
    .catch((error) => {
      dispatch({
        type: USER_ACCOUNT_AUTH_FAILURE,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: USER_LOADING,
    payload: { key, promise }
  });
  return promise;
};
userAccountAuth.getKey = getUserAccountAuthKey;

export const USER_FORGOT_PASSWORD_SENDING = 'USER_FORGOT_PASSWORD_SENDING';
export const USER_FORGOT_PASSWORD_SENT = 'USER_FORGOT_PASSWORD_SENT';
export const USER_FORGOT_PASSWORD_FAILED = 'USER_FORGOT_PASSWORD_FAILED';
const userForgotPasswordKey = () => 'user/forgot-password';
export const userForgotPassword = (email, examName) => (dispatch, getState) => {
  const key = userForgotPasswordKey();
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post('users/forgot_password', {
    user: { email },
    exam_name: examName
  })
    .then((apiResponse) => {
      dispatch({
        type: USER_FORGOT_PASSWORD_SENT,
        payload: { key, ...apiResponse }
      });
      return apiResponse;
    })
    .catch((error) => {
      dispatch({
        type: USER_FORGOT_PASSWORD_FAILED,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: USER_FORGOT_PASSWORD_SENDING,
    payload: { key, promise }
  });

  return promise;
};
userForgotPassword.getKey = userForgotPasswordKey;
