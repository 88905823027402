import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgWarning(props) {
  return (
    <SvgContainer title="Warning Icon" {...props}>
      <path d="M27.224 25.21L17.342 5.448a1.5 1.5 0 00-2.684 0L4.776 25.211a1.5 1.5 0 001.342 2.17h19.764a1.5 1.5 0 001.342-2.17zm-.917.934a.492.492 0 01-.425.238H6.118a.5.5 0 01-.447-.724l9.882-19.764a.5.5 0 01.894 0l9.882 19.764a.49.49 0 01-.022.486z" />
      <path d="M17 22.031a1.471 1.471 0 00-.448-.292 1.411 1.411 0 00-.552-.108 1.416 1.416 0 00-1.004.4 1.356 1.356 0 00-.296.432 1.331 1.331 0 00-.108.537 1.36 1.36 0 00.108.54 1.33 1.33 0 00.74.724 1.474 1.474 0 00.56.104 1.454 1.454 0 00.552-.104 1.397 1.397 0 00.448-.288 1.314 1.314 0 00.3-.436 1.36 1.36 0 00.108-.54A1.323 1.323 0 0017 22.03zM15.184 20.111h1.68q.143-.751.232-1.476a12.098 12.098 0 00.088-1.46V12.6h-2.32v4.576a12.098 12.098 0 00.088 1.46q.088.724.232 1.475z" />
    </SvgContainer>
  );
}
