import React from "react";
import PropTypes from "prop-types";

import Button from "components/inputs/Button";
import Tooltip from "components/Tooltip";
import SVGIcon from 'components/SVGIcon';

import useWindowDimensions from "helpers/windowDimensions";
import SimplifiedPricingCard from "./components/SimplifiedPricingCard";
import ProductDetails from "./ProductDetails";

const YearlyPricing = ({
  pricingPlanFeatures,
  activeQuestionCount,
  pricing,
  featuresForTooltip,
  tooltipDescription
}) => {
  const { width } = useWindowDimensions();
  const pricingObj = pricing[0];
  const isDesktop = width >= 990;
  const {
    id,
    months,
    price,
  } = pricingObj;

  return (
    <>
      <ProductDetails section="one-year-pricing-card">
        {isDesktop ? (
          <div className="wrapper pricing d-flex">
            <div className="details">
              <div>
                <h2>{months} Months</h2>
                <p className="mt-5">Access {activeQuestionCount}+ Questions</p>
              </div>
            </div>
            <div className="features">
              <div>
                <ul>
                  {pricingPlanFeatures.map((feature, i) => (
                    <li key={i} className={i === pricingPlanFeatures.length - 1 ? "" : "mb-3"}>
                      <SVGIcon name="CircleCheck" width={16} height={16} className="circle-check" />
                      <span className="ml-3">{feature}</span>{featuresForTooltip(i) && <Tooltip hoverable className="ml-2">{tooltipDescription(feature)}</Tooltip>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <form id={`product-${id}-form`} method="POST" className="add-to-cart" action="/cart_items">
              <div>
                <input type="hidden" name="cart_item[product_id]" value={id} />
                <h3 className="mb-5">${price}</h3>
                <Button type="submit" form={`product-${id}-form`}>
                  Add to Cart
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <SimplifiedPricingCard
            id={id}
            key={id}
            pricingPlanFeatures={pricingPlanFeatures}
            months={months}
            price={price}
            featuresForTooltip={featuresForTooltip}
            tooltipDescription={tooltipDescription}
          />
        )}
      </ProductDetails>
      {isDesktop ? (
        <ProductDetails section="other-cards">
          <div className="institutional wrapper">
            <h3>Looking for Institutional Pricing?</h3>
            <p>
              Whether you are looking to fill a need at a medical or nursing school, residency program,
              health sciences library, or hospital, BoardVitals is ready to customize a package to best suit your needs.
            </p>
            <a href="https://www.boardvitals.com/institutional">Bring BoardVitals to Your Institution{">"}</a>
          </div>
        </ProductDetails>
      ) : (
        <></>
      )}
    </>
  );
};

YearlyPricing.propTypes = {
  pricingPlanFeatures: PropTypes.array.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  pricing: PropTypes.array.isRequired,
  featuresForTooltip: PropTypes.func.isRequired,
  tooltipDescription: PropTypes.func.isRequired
};

export default YearlyPricing;
