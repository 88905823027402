import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCart(props) {
  return (
    <SvgContainer {...props} title="From Board Exam Prep to Practice Questions">
      <g opacity="0.3">
        <g clipPath="url(#clip0_1159_6214)">
          <path d="M99.0539 44.5306C98.9949 44.0744 98.1718 43.7347 97.641 43.5636C97.7 43.3355 97.523 43.0504 97.2281 42.9364L96.346 42.5942C95.9922 42.4802 95.5819 42.5942 95.405 42.8793C95.228 43.1645 95.405 43.5066 95.7588 43.6182L96.6409 43.9603C96.8768 44.0173 97.1128 44.0174 97.2897 43.9033C97.8205 44.0744 98.2308 44.4165 98.2308 44.4165C98.5257 45.4405 95.7588 49.7099 94.5818 51.3587L94.11 51.1306C94.11 51.1306 90.52 57.676 85.9274 56.7091C85.9274 56.7091 80.6297 55.457 85.0453 47.7165L84.6325 47.5454C85.3966 45.8372 87.6352 41.2281 88.8122 40.7149C88.8122 40.7149 89.402 40.7719 89.8713 40.943C89.8713 41.1711 90.0482 41.3421 90.2841 41.3992L91.1662 41.7413C91.5201 41.8554 91.9304 41.7413 92.0483 41.4562C92.2253 41.1711 92.0483 40.8289 91.6945 40.7174L90.8124 40.3752C90.5175 40.2612 90.2226 40.3182 90.0482 40.4893C89.5764 40.3182 88.6943 40.0331 88.2814 40.2612C88.2814 40.2612 86.1044 42.2521 84.1017 47.2603L83.6888 47.0322C83.6888 47.0322 78.7449 53.7487 83.9248 57.1628C77.568 59.6099 48.6072 72.0735 62.5593 83.4561C73.2728 92.2206 75.9217 100.871 76.5679 105.48C75.3909 106.162 74.5678 107.414 74.5678 108.837C74.5678 110.999 76.3935 112.764 78.6296 112.764C80.8656 112.764 82.6913 110.999 82.6913 108.837C82.6913 106.675 80.8656 104.91 78.6296 104.91L78.5706 104.91C77.9808 100.016 75.3909 91.3652 63.9133 81.9165C63.9133 81.9165 50.0226 72.4132 85.7505 58.0132C87.1044 58.1843 91.8714 58.2413 95.6973 51.6388L95.2844 51.4677C98.6385 47.1413 99.0513 44.5256 99.0513 44.5256L99.0539 44.5306ZM80.7476 109.125C80.7476 110.149 79.8655 111.002 78.8065 111.002C77.7475 111.002 76.8653 110.149 76.8653 109.125C76.8653 108.101 77.7475 107.248 78.8065 107.248C79.9245 107.248 80.7476 108.101 80.7476 109.125Z" fill="#1B3950" />
          <path d="M78.451 36.961C78.1561 36.5048 76.9791 36.3908 76.3329 36.3908C76.2739 36.1056 75.9201 35.8775 75.5688 35.8775L74.4508 35.8205C73.9789 35.8205 73.6276 36.1056 73.5686 36.4478C73.5686 36.847 73.9225 37.1296 74.3918 37.1296L75.5098 37.1866C75.8047 37.1866 76.0406 37.0726 76.215 36.9015C76.9201 36.9015 77.5689 37.1296 77.5689 37.1296C78.51 38.2106 77.7458 44.0172 77.392 46.2362L76.5099 46.0081C76.5099 46.0081 76.7458 54.1453 70.5659 55.0577C70.5659 55.0577 64.445 56.1957 65.0912 45.837L64.2681 45.666C64.2091 43.447 64.0911 37.4718 65.1502 36.5023C65.1502 36.5023 65.7989 36.3313 66.4451 36.3883C66.5631 36.6164 66.858 36.7304 67.1503 36.7875L68.2683 36.8445C68.7401 36.8445 69.0914 36.5594 69.1504 36.1627C69.1504 35.7635 68.7966 35.4809 68.3273 35.4809L67.2093 35.4238C66.8554 35.4238 66.5041 35.5949 66.3861 35.88C65.7374 35.823 64.6194 35.88 64.2681 36.2792C64.2681 36.2792 62.9731 39.3536 63.5039 45.7255L63.15 45.7255C63.15 45.7255 61.3833 55.4568 69.3889 57.1081L68.9761 105.257C67.3862 105.77 66.2092 107.248 66.2092 108.956C66.2092 111.118 68.035 112.883 70.271 112.883C72.507 112.883 74.3328 111.118 74.3328 108.956C74.3328 107.305 73.2148 105.882 71.6839 105.314L71.566 56.7684C73.0378 56.4833 78.0407 55.2883 78.6895 46.2957L78.2766 46.2957C79.3947 40.0354 78.4536 36.961 78.4536 36.961L78.451 36.961ZM72.2711 109.125C72.2711 110.148 71.389 111.001 70.33 111.001C69.2709 111.001 68.3888 110.148 68.3888 109.125C68.3888 108.158 69.153 107.359 70.1556 107.248L70.6274 107.248C71.5095 107.362 72.2763 108.158 72.2763 109.125L72.2711 109.125Z" fill="#1B3950" />
          <path d="M56.6813 56.7089C57.9173 55.6849 59.9764 53.1237 56.9172 46.9775L56.4454 47.1485C54.6786 42.1403 52.6195 40.0924 52.6195 40.0924C52.2067 39.8072 51.2656 40.0924 50.7938 40.2634C50.6169 40.0924 50.263 39.9783 49.9707 40.0924L49.0886 40.3775C48.7347 40.4915 48.5578 40.8337 48.7347 41.1163C48.9116 41.399 49.2655 41.5155 49.6168 41.4014L50.4989 41.1163C50.7348 41.0593 50.8528 40.8882 50.9117 40.6601C51.4425 40.489 52.0298 40.432 52.0298 40.432C53.2068 40.9452 55.1505 45.4973 55.8556 47.3196L55.3838 47.4907C55.3838 47.4907 59.2687 53.9791 55.03 56.4832C55.03 56.4832 49.4963 58.3601 46.1422 50.7361L45.7884 50.8502C44.7883 49.1989 42.0804 44.7609 42.4343 43.737C42.4343 43.737 42.8471 43.4519 43.3754 43.2808C43.5523 43.3948 43.8472 43.4519 44.0806 43.3378L44.9627 43.0527C45.3165 42.9386 45.4935 42.5965 45.3165 42.3138C45.1396 42.0287 44.7857 41.9147 44.4344 42.0287L43.5523 42.3138C43.2574 42.4279 43.1395 42.656 43.1395 42.8841C42.6087 43.0552 41.7855 43.3973 41.7266 43.794C41.7266 43.794 42.0804 46.4692 45.2576 51.0783L44.8447 51.3064C44.8447 51.3064 48.2577 59.0469 55.1453 57.6808C92.463 72.8766 76.2774 82.0948 76.2774 82.0948C65.2126 91.3724 62.3868 100.305 61.7381 105.145C59.561 105.202 57.8532 106.91 57.8532 109.015C57.8532 111.12 59.679 112.943 61.915 112.943C64.151 112.943 65.9768 111.177 65.9768 109.015C65.9768 107.421 64.9767 106.055 63.5638 105.43C64.328 100.878 67.2717 91.8856 78.1622 82.9501C91.9348 71.7386 63.2715 59.2179 56.6788 56.7138L56.6813 56.7089ZM61.9791 111.118C60.9201 111.118 60.038 110.265 60.038 109.241C60.038 108.217 60.9201 107.364 61.9791 107.364C63.0381 107.364 63.9202 108.217 63.9202 109.241C63.9202 110.265 63.0381 111.118 61.9791 111.118Z" fill="#1B3950" />
          <path d="M17.8594 33.8148L22.9725 28.2734L24.7624 29.8181L21.5673 33.2817L23.5238 34.9701L26.2522 32.0148L28.0421 33.5594L25.3137 36.5147L28.7447 39.4751L26.8266 41.5528L17.8594 33.8148Z" fill="#1B3950" />
          <path d="M27.3672 24.4207L30.9443 21.9215C33.0086 20.4785 35.2651 19.9604 36.9627 22.2314C37.9909 23.605 37.9755 24.9587 37.3191 26.124L42.2784 28.3405L39.6756 30.1603L35.4036 28.1025L34.2651 28.8984L36.7421 32.2108L34.4061 33.8422L27.3672 24.4232L27.3672 24.4207ZM33.9984 26.3744C35.1061 25.6008 35.3574 24.7182 34.6882 23.8232C34.0189 22.9256 33.2034 23.0546 32.0957 23.8281L31.0341 24.5719L32.9368 27.1182L33.9984 26.3769L33.9984 26.3744Z" fill="#1B3950" />
          <path d="M42.0118 21.8273C40.5271 18.2868 41.758 15.4752 44.7863 14.2876C47.8147 13.1 50.7303 14.3074 52.2073 17.8306C53.6843 21.3537 52.4945 24.2669 49.4661 25.452C46.4377 26.6396 43.4863 25.3479 42.0093 21.8248L42.0118 21.8273ZM49.4892 18.8967C48.6097 16.7967 47.1891 15.9264 45.7172 16.5041C44.2632 17.0744 43.853 18.6612 44.7325 20.7611C45.6069 22.8463 47.0839 23.8058 48.5404 23.238C50.0123 22.6603 50.3636 20.9818 49.4892 18.8992L49.4892 18.8967Z" fill="#1B3950" />
          <path d="M56.1797 10.9082L59.2953 10.5463L61.8082 15.3438C62.1108 15.9909 62.4006 16.6603 62.7109 17.3595L62.7852 17.352C62.916 16.5983 63.0134 15.8867 63.1673 15.1851L64.3571 9.95866L67.4727 9.59668L68.9087 21.1355L66.3239 21.4355L65.8264 17.4289C65.6854 16.2934 65.6777 14.3 65.6854 13.1471L65.611 13.157L64.9956 16.2859L63.9109 20.7264L62.298 20.9148L60.1748 16.8463L58.8465 13.943L58.7722 13.9529C59.0594 15.071 59.544 17.0099 59.685 18.1454L60.1825 22.152L57.6157 22.4495L56.1797 10.9107L56.1797 10.9082Z" fill="#1B3950" />
          <path d="M81.139 10.6304L85.2521 11.575C87.8215 12.1651 89.7037 13.5337 89.0216 16.313C88.3549 19.0229 85.9265 19.7122 83.4674 19.1469L82.0852 18.8295L81.1621 22.5808L78.3594 21.9361L81.139 10.6304ZM83.8289 16.9725C85.2111 17.2899 86.0111 16.8138 86.2906 15.6857C86.5676 14.5576 85.9291 13.9874 84.5649 13.675L83.4161 13.4097L82.6084 16.6923L83.8289 16.9725Z" fill="#1B3950" />
          <path d="M95.5442 14.6792L99.4932 16.5809C101.773 17.6792 103.296 19.3701 102.004 21.8792C101.222 23.3966 99.9753 24.0139 98.606 23.9916L98.8778 29.2676L96.0032 27.8817L95.9058 23.2677L94.6493 22.6627L92.7646 26.3222L90.1875 25.08L95.5468 14.6768L95.5442 14.6792ZM96.8341 21.2643C98.0572 21.8544 98.9804 21.661 99.4932 20.6693C100.004 19.6776 99.506 19.0404 98.2854 18.4528L97.111 17.8875L95.6622 20.6991L96.8366 21.2643L96.8341 21.2643Z" fill="#1B3950" />
          <path d="M108.848 21.9609L114.741 26.6097L113.223 28.4072L109.561 25.5188L108.138 27.2072L111.271 29.6791L109.753 31.4766L106.62 29.0072L104.996 30.9287L108.802 33.9287L107.286 35.7262L101.25 30.9659L108.845 21.9609L108.848 21.9609Z" fill="#1B3950" />
          <path d="M119.262 31.0181L121.885 34.2238C123.524 36.2271 124.124 38.4288 121.808 40.199C119.549 41.9271 117.203 41.0123 115.634 39.0957L114.752 38.0172L111.623 40.4098L109.836 38.2255L119.262 31.0181ZM117.308 37.6081C118.19 38.6866 119.124 38.7908 120.065 38.0718C121.006 37.3528 120.87 36.5222 119.998 35.4586L119.265 34.5635L116.529 36.6561L117.308 37.6081Z" fill="#1B3950" />
          <path d="M21.4274 101.392L19.7042 98.7067L21.7454 97.4844L26.697 105.208L24.6558 106.43L22.9327 103.745L14.7578 108.646L13.25 106.293L21.4249 101.392L21.4274 101.392Z" fill="#1B3950" />
          <path d="M23.6206 110.833C26.4259 108.143 29.5851 108.156 31.8878 110.399C34.1905 112.643 34.1648 115.71 31.3724 118.388C28.5799 121.065 25.3412 121.13 23.0411 118.886C20.7409 116.642 20.8307 113.513 23.6232 110.833L23.6206 110.833ZM29.303 116.372C30.9672 114.775 31.2493 113.174 30.1313 112.083C29.0261 111.004 27.3542 111.252 25.69 112.849C24.0386 114.433 23.6924 116.124 24.7976 117.2C25.9156 118.291 27.6542 117.956 29.3056 116.372L29.303 116.372Z" fill="#1B3950" />
          <path d="M43.066 119.149L46.8893 120.894C49.2767 121.985 50.828 123.699 49.569 126.282C48.3407 128.801 45.8175 128.992 43.5302 127.948L42.2455 127.36L40.5428 130.849L37.9375 129.659L43.0686 119.149L43.066 119.149ZM44.3482 125.893C45.6328 126.48 46.5175 126.173 47.0304 125.124C47.5432 124.075 47.0406 123.391 45.7713 122.811L44.702 122.322L43.2122 125.375L44.3482 125.893Z" fill="#1B3950" />
          <path d="M54.328 123.82L58.6437 124.712C61.1362 125.228 63.044 126.5 62.4388 129.24C62.0721 130.898 61.0233 131.796 59.6899 132.103L61.3105 137.149L58.1693 136.499L56.8872 132.054L55.5153 131.769L54.6306 135.765L51.8125 135.183L54.328 123.817L54.328 123.82ZM57.2718 129.887C58.6078 130.164 59.454 129.753 59.6925 128.672C59.931 127.588 59.2873 127.09 57.9513 126.815L56.6692 126.55L55.9897 129.621L57.2718 129.887Z" fill="#1B3950" />
          <path d="M68.8087 125.868L72.2423 125.823L76.1502 137.397L73.1218 137.436L72.3833 134.769L68.819 134.813L68.1548 137.501L65.2188 137.538L68.8061 125.87L68.8087 125.868ZM69.3446 132.664L71.7986 132.631L71.5268 131.635C71.196 130.497 70.8653 129.126 70.5345 127.916L70.4601 127.916C70.1806 129.133 69.8857 130.512 69.5883 131.66L69.3446 132.664Z" fill="#1B3950" />
          <path d="M79.1448 131.201C78.2165 127.432 80.3038 124.658 83.2502 123.981C84.7759 123.629 86.1606 124.008 87.1684 124.638L86.0991 126.609C85.3964 126.22 84.722 126.101 83.8964 126.292C82.3527 126.646 81.4372 128.253 81.9757 130.44C82.5322 132.696 83.9476 133.747 85.5811 133.37C86.5324 133.152 87.1786 132.616 87.594 131.915L89.4429 133.177C88.6967 134.54 87.5376 135.378 86.0632 135.716C83.0809 136.402 80.0833 135.019 79.1422 131.198L79.1448 131.201Z" fill="#1B3950" />
          <path d="M92.7921 123.391L89.8509 124.767L88.8047 122.679L97.2642 118.722L98.3104 120.81L95.3692 122.186L99.5592 130.556L96.9847 131.761L92.7947 123.391L92.7921 123.391Z" fill="#1B3950" />
          <path d="M100.016 116.818L102.352 115.189L109.385 124.613L107.049 126.242L100.016 116.818Z" fill="#1B3950" />
          <path d="M109.709 117.136C106.819 114.453 107.019 111.029 109.114 108.917C110.201 107.821 111.576 107.414 112.778 107.417L112.983 109.636C112.176 109.678 111.54 109.931 110.952 110.523C109.852 111.631 109.983 113.459 111.663 115.016C113.394 116.622 115.173 116.764 116.337 115.591C117.014 114.909 117.258 114.121 117.214 113.312L119.476 113.407C119.615 114.944 119.107 116.255 118.058 117.312C115.937 119.449 112.642 119.858 109.709 117.138L109.709 117.136Z" fill="#1B3950" />
          <path d="M113.648 103.99L117.592 97.7002L119.646 98.9027L117.195 102.81L119.121 103.938L121.216 100.594L123.27 101.796L121.175 105.141L123.37 106.427L125.916 102.366L127.967 103.569L123.929 110.013L113.648 103.988L113.648 103.99Z" fill="#1B3950" />
          <path d="M14.2591 95.6443C13.9488 95.6443 13.6565 95.4608 13.5385 95.1633C7.61764 80.0022 8.23819 63.041 15.2386 48.6262C15.3079 48.4824 15.3797 48.3361 15.4515 48.1873L10.4614 50.8452C10.0896 51.0435 9.62033 50.9121 9.41519 50.5526C9.35108 50.4386 9.32031 50.3146 9.32031 50.1931C9.32031 49.9303 9.46391 49.6774 9.71777 49.5411L16.9208 45.703C17.0926 45.6113 17.2875 45.589 17.4644 45.6287C17.5208 45.6411 17.5747 45.6584 17.626 45.6832C17.7439 45.7378 17.849 45.8245 17.9285 45.9361C17.9567 45.9758 17.9798 46.0179 17.9978 46.0601C18.0388 46.1543 18.0593 46.2535 18.0593 46.3526C18.0593 46.3849 18.0593 46.4171 18.0516 46.4493C18.0414 46.5163 18.0234 46.5832 17.9952 46.6452C17.9798 46.6774 17.9644 46.7097 17.9439 46.7394C17.4413 47.5675 17.008 48.4625 16.6233 49.2535C9.80496 63.3113 9.20236 79.8509 14.9771 94.6377C15.1258 95.022 14.9258 95.4509 14.5283 95.5972C14.4386 95.6294 14.3463 95.6443 14.2565 95.6443L14.2591 95.6443Z" fill="#1B3950" />
          <path d="M18.8148 54.7625C18.4532 54.7625 18.1301 54.5146 18.0609 54.1575L16.5762 46.5856C16.4967 46.1815 16.7711 45.7923 17.1891 45.7154C17.607 45.6385 18.0096 45.9038 18.0891 46.308L19.5738 53.8798C19.6533 54.284 19.3789 54.6732 18.961 54.7501C18.9122 54.76 18.8635 54.7625 18.8174 54.7625L18.8148 54.7625Z" fill="#1B3950" />
          <path d="M125.087 95.4737C125.061 95.4737 125.033 95.4737 125.007 95.4687C124.948 95.4637 124.892 95.4513 124.835 95.4315C124.712 95.3894 124.597 95.3175 124.507 95.2183C124.474 95.1811 124.446 95.1439 124.423 95.1042C124.351 94.9877 124.317 94.8588 124.317 94.7299L124.317 94.7199C124.317 94.6555 124.328 94.5885 124.348 94.5241C124.358 94.4894 124.371 94.4547 124.387 94.4199C124.779 93.5423 125.097 92.6001 125.379 91.767C130.377 77.015 128.884 60.5274 121.284 46.5341C121.086 46.1696 121.23 45.7209 121.607 45.5299C121.984 45.339 122.448 45.4779 122.645 45.8423C130.438 60.1902 131.969 77.0968 126.841 92.2307C126.789 92.3819 126.738 92.5356 126.684 92.6918L131.3 89.4638C131.643 89.2233 132.126 89.2976 132.374 89.6299C132.472 89.7613 132.52 89.915 132.52 90.0662C132.52 90.2968 132.41 90.5224 132.202 90.6687L125.538 95.3299C125.402 95.4241 125.246 95.4687 125.089 95.4687L125.087 95.4737Z" fill="#1B3950" />
          <path d="M125.036 95.3895C124.711 95.3895 124.408 95.1886 124.303 94.8713L121.872 87.5325C121.744 87.1407 121.967 86.7217 122.37 86.5977C122.775 86.4738 123.208 86.687 123.336 87.0787L125.767 94.4176C125.895 94.8093 125.672 95.2283 125.27 95.3523C125.193 95.3771 125.113 95.387 125.034 95.387L125.036 95.3895Z" fill="#1B3950" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1159_6214">
          <rect width="123.215" height="127.957" fill="white" transform="translate(9.32031 9.59424)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
}
