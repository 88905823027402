import React from "react";
import PropTypes from "prop-types";

import ProductDetails from "./ProductDetails";
import SimplifiedPricingCard from "./components/SimplifiedPricingCard";
import CardsCarousel from "./components/CardsCarousel";

const CmePricing = ({ pricing }) => {
  const cards = [];
  return (
    <>
      <ProductDetails section={`cme-pricing-cards ${pricing.length > 2 ? "three-cards" : "two-cards"}`}>
        {pricing.map((pricingObj, i) => {
          const {
            id,
            months,
            discount,
            price,
            best_value: isBestValue
          } = pricingObj;
          let card = <div key={id} />;

          card = (
            <SimplifiedPricingCard
              id={id}
              key={id}
              months={months}
              discount={i === pricing.length - 1 ? 0 : discount}
              price={price}
              isCme
              isBestValue={isBestValue}
            />
          );

          cards.push(card);

          return card;
        })}
      </ProductDetails>
      <ProductDetails section="mobile">
        <CardsCarousel
          cards={cards}
          mobileOnly
          cardClassName="mobile-card"
          paginationDotsClass={pricing.length === 2 ? "cme-two-dots" : ""}
          caretWidth={21}
          caretHeight={38}
        />
      </ProductDetails>
    </>
  );
};

CmePricing.propTypes = { pricing: PropTypes.array.isRequired };

export default CmePricing;
