import { stateHelper } from 'reducers/quizzes/quizzes';
import Note from 'factories/Note';

import {
  NOTE_SYNCED,
  NOTE_SYNC_OFFLINE
} from 'actions/notes';
import {
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_QUESTIONS
} from 'actions/quizzes';


const reducers = {};


function setNote(state, json) {
  const quizId = parseInt(json.relationships.quiz.data.id);
  const questionId = parseInt(json.relationships.question.data.id);
  let note = state.getIn([quizId, 'notes', questionId]);
  note = note ? Note.update(note, json) : new Note(json);
  return state.setIn([quizId, 'notes', questionId], note);
}

function setNotes(state, payload) {
  return payload.included.filter(record => record.type === 'note').reduce(setNote, state);
}


reducers[NOTE_SYNCED] = function noteSynced(state, payload) {
  return setNote(state, payload.data);
};


reducers[NOTE_SYNC_OFFLINE] = function noteSyncOffline(state, payload) {
  const {
    quizId,
    questionId,
    body
  } = payload;

  const json = {
    type: 'note',
    attributes: {
      body: body
    },
    relationships: {
      quiz: {
        data: {
          id: quizId,
          type: 'quiz'
        }
      },
      question: {
        data: {
          id: questionId,
          type: 'question'
        }
      }
    }
  };

  const options = { fetched: false };

  let note = state.getIn([quizId, 'notes', questionId]);
  note = note ? Note.update(note, json, options) : new Note(json, options);

  return state
    .setIn([quizId, 'notes', questionId], note)
    .setIn([quizId, 'synced'], false);
};


// Non-Notes Action Reducers

reducers[QUIZ_FETCHED_TAKE] = setNotes;
reducers[QUIZ_FETCHED_QUESTIONS] = setNotes;
// reducers[OFFLINE_QUIZ_SYNCED] = setNotes;


export const reducer = stateHelper.createReducer(reducers);

export default reducers;
