import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgKey(props) {
  return (
    <SvgContainer title="Key Icon" {...props}>
      <path d="M28.756 14H17.153c-.537-5.532-3.187-9.5-6.397-9.5S4.896 8.468 4.36 14h-.103a2 2 0 000 4h.103c.538 5.532 3.187 9.5 6.397 9.5s5.86-3.968 6.397-9.5h5.59v2.5h-1.919a1.083 1.083 0 00-1.08 1.081v4.838a1.083 1.083 0 001.08 1.081h2.42a.5.5 0 00.5-.5v-2.5h2V27a.5.5 0 00.5.5h2.419a1.083 1.083 0 001.08-1.081V21.58a1.083 1.083 0 00-1.08-1.081h-1.92V18h2.013a.989.989 0 00.988-.987v-2.026a.989.989 0 00-.988-.987zm-.093 7.5a.08.08 0 01.08.081v4.838a.08.08 0 01-.08.081h-1.92v-3h-4v3h-1.918a.08.08 0 01-.081-.081V21.58a.08.08 0 01.08-.081h2.92V18h2v3.5zM16.23 17l-.034.463c-.385 5.152-2.723 9.037-5.44 9.037s-5.055-3.885-5.44-9.037L5.283 17H4.256a1 1 0 010-2h1.026l.035-.463C5.7 9.385 8.04 5.5 10.757 5.5s5.054 3.885 5.439 9.037l.034.463h12.514l.012 2z" />
      <path d="M13.218 15h1.016l-.05-.547c-.334-3.56-1.711-5.953-3.428-5.953s-3.093 2.393-3.427 5.953L7.28 15h.977a1 1 0 010 2h-.978l.051.547c.334 3.56 1.711 5.953 3.427 5.953s3.094-2.393 3.428-5.953l.05-.547h-1.016a.982.982 0 01-.962-1.038.963.963 0 01.962-.962zm-.092 2.998c-.372 2.83-1.463 4.502-2.37 4.502s-1.998-1.673-2.37-4.504a2 2 0 000-3.992C8.758 11.173 9.85 9.5 10.756 9.5s1.998 1.672 2.37 4.502a1.984 1.984 0 00-1.87 2.036 1.964 1.964 0 001.87 1.96zM25.244 7.5h1.5V9a.5.5 0 101 0V7.5h1.5a.5.5 0 100-1h-1.5V5a.5.5 0 00-1 0v1.5h-1.5a.5.5 0 000 1z" />
      <circle cx={23.744} cy={10.5} r={1} />
    </SvgContainer>
  );
}
