import Factory from 'factories/_Factory';

class StaffOrganization extends Factory {
    static jsonType = 'staff_organization';

    static get defaults() {
      return {
        name: '',
      };
    }
}

export default StaffOrganization;
