import React from "react";
import SvgContainer from './SvgContainer';

export default function FredCheckboxUnselected (props) {
  return (
    <SvgContainer title="Checkbox Unselected Icon" {...props}>
      <path d="M25.1 26.9H6.9c-.8 0-1.5-.7-1.5-1.5V7.3c0-.8.7-1.5 1.5-1.5H25c.8 0 1.5.7 1.5 1.5v18.1c.1.8-.6 1.5-1.4 1.5z" fill="#fff" />
      <path d="M24 27.7H8c-1.9 0-3.5-1.6-3.5-3.5V8.4c0-1.9 1.6-3.5 3.5-3.5h16c1.9 0 3.5 1.6 3.5 3.5v15.9c-.1 1.9-1.6 3.4-3.5 3.4zM8 6.6c-1 0-1.7.8-1.7 1.7v15.9c0 1 .8 1.7 1.7 1.7h16c1 0 1.7-.8 1.7-1.7V8.4c0-1-.8-1.7-1.7-1.7H8z" fill="#6b6c6c" />
    </SvgContainer>
  );
}
