export default class Draggable {
  constructor(el) {
    this.el = el;
    this.el.style.pointerEvents = 'none';
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.el.addEventListener('mousedown', this.handleMouseDown);
    this.handleWindowMouseMove = this.handleWindowMouseMove.bind(this);
    this.handleWindowMouseUp = this.handleWindowMouseUp.bind(this);
    this.resetCssText = "position: fixed; top: 0; left: 0; z-index: 1050; pointerEvents: auto;";
    this.fadeDelay = 700;
  }

  handleWindowMouseMove(evt) {
    this.Sx = evt.clientX - this.x + this.Ox;
    this.Sy = evt.clientY - this.y + this.Oy;
    this.el.style.top = this.Sy + "px";
    this.el.style.left = this.Sx + "px";
  }

  handleWindowMouseUp() {
    window.removeEventListener('mousemove', this.handleWindowMouseMove);
    window.removeEventListener('mouseup', this.handleWindowMouseUp);
  }

  handleMouseDown(evt) {
    this.x = evt.clientX;
    this.y = evt.clientY;
    this.Ox = this.el.offsetLeft;
    this.Oy = this.el.offsetTop;

    window.addEventListener('mousemove', this.handleWindowMouseMove);
    window.addEventListener('mouseup', this.handleWindowMouseUp);
  }

  reset() {
    setTimeout(() => {
      this.el.style.cssText = this.resetCssText;
    }, this.fadeDelay);
    this.el.removeEventListener('mousedown', this.handleMouseDown);
  }
}
