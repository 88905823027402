import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgNextInactive(props) {
  return (
    <SvgContainer title="Next Inactive Icon" {...props}>
      <path fill="#4b6575" d="M2 7.9v16.8l25.9-8.4z" /><path d="M1.1 6.7l29.5 9.6-29.5 9.6V6.7zM2.8 9v14.5l22.3-7.3L2.8 9z" fill="#98b1c1" />
    </SvgContainer>
  );
}
