import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { modalOpen } from 'actions/modal';

import Button from 'components/inputs/Button';
import ButtonDropdown from 'components/inputs/ButtonDropdown';
import Calculator from 'components/calculator/Calculator';

import LabValuesModal from 'containers/TakeQuizPage/modals/LabValuesModal';

const SampleQuestionHeader = ({
  questionBankName,
  onClickSetIsStrikingOut,
  isStrikingOut,
  onClickHighlightOn,
  onClickHighlightOff
}) => {
  const dispatch = useDispatch();

  const onClickCalculator = () => {
    dispatch(modalOpen(<Calculator />, { size: 'large', closeUnderlay: false, containsForm: true }));
  };

  const onClickLabValues = () => {
    dispatch(modalOpen(<LabValuesModal />, { size: 'large', closeXAbsolute: true }));
  };

  return (
    <header className="question-header">
      <div className="question-nav container d-flex align-items-center justify-content-between">
        <div className="row">
          <div className="col-auto">
            <h2 className="text-light m-0"><b>{`Sample ${questionBankName} Practice Question`}</b></h2>
          </div>
        </div>
      </div>
      <div className="question-toggles border-top border-light">
        <div className="container d-flex align-items-center h-100 pl-0">
          <ButtonDropdown
            styleVariant="outline-light"
            className="mr-3"
            icon="Highlighter"
            iconProps={{}}
            options={{
              Highlight: () => onClickHighlightOn(),
              "Remove Highlight": () => onClickHighlightOff()
            }}
            small
            caretToRight
          >
            Highlight
          </ButtonDropdown>

          <Button
            styleVariant="outline-light"
            className="mr-3 pl-2"
            active={isStrikingOut}
            onClick={() => onClickSetIsStrikingOut()}
            icon="Strikeout"
            iconProps={{}}
            small
          >
            Strikeout
          </Button>

          <Button
            styleVariant="outline-light"
            className="mr-3 pl-2"
            onClick={() => onClickCalculator()}
            icon="Calculator"
            iconProps={{}}
            small
          >
            Calculator
          </Button>

          <Button
            styleVariant="outline-light"
            onClick={() => onClickLabValues()}
            icon="LabValues"
            iconProps={{}}
            small
          >
            Lab Values
          </Button>
        </div>
      </div>
    </header>
  );
};

SampleQuestionHeader.propTypes = {
  questionBankName: PropTypes.string,
  onClickSetIsStrikingOut: PropTypes.func,
  isStrikingOut: PropTypes.bool,
  onClickHighlightOn: PropTypes.func,
  onClickHighlightOff: PropTypes.func
};

export default SampleQuestionHeader;
