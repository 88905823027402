import { List, Map } from 'immutable';
import StateHelper from 'helpers/StateHelper';
import Quiz from 'factories/Quiz';

import { PERFORMANCE_QUIZZES_FETCHED } from 'actions/performance';
import { SESSION_RESET } from 'actions/session';
import {
  QUIZ_FETCHED_PERFORMANCE,
  QUIZ_FETCHING_TAKE,
  QUIZ_FETCHED_TAKE,
  QUIZ_UNLOAD_TAKE,
  QUIZ_FETCHED_ALL_QUESTIONS,
  QUIZZES_FETCHED,
  QUIZ_CREATING,
  QUIZ_CREATED,
  QUIZ_CREATE_SEARCH,
  QUIZ_DELETE_SEARCH,
  QUIZ_SEEN_QUESTION_SYNCED,
  QUIZ_SEEN_QUESTION_OFFLINE,
  QUIZ_TICK,
  QUIZ_SUBMITTED,
  QUIZ_ARCHIVED,
  QUIZ_UNARCHIVED,
  QUIZ_ADD_SEARCH_QUESTIONS,
  QUIZZES_ARCHIVED,
  QUIZ_FETCHED_PRACTICE,
  QUIZZES_UNARCHIVED,
  QUIZ_END_QUIZ_BLOCK_SYNCED,
  QUIZ_SET_LAST_SEEN_BLOCK,
  QUIZ_QUESTION_FETCHED_NEXT,
  QUIZ_UNLOAD_PERFORMANCE
} from 'actions/quizzes';
import {
  RESPONSE_SYNCED,
  RESPONSE_EVALUATED,
  RESPONSE_EVALUATE_LOCAL,
  RESPONSE_SYNC_OFFLINE,
  RESPONSE_SET
} from 'actions/responses';
import {
  OFFLINE_QUIZ_LOADED,
  OFFLINE_QUIZ_SYNCED
} from 'actions/offline';
import {
  ASSIGNMENT_QUIZZES_FETCHED
} from 'actions/b2b/assignments';

export const stateHelper = new StateHelper(Quiz);
export const { initialState } = stateHelper;
const reducers = {};


function quizFetchingTake(state) {
  return state
    .map(record => record
      .set('notes', new Map())
      .set('highlights', new Map())
      .set('strike_throughs', new Map())
      .set('quiz_blocks', new Map())
      .set('take_fetched_at', null)
      .set('questions_fetched_at', null));
}

function quizFetchedTake(state, payload) {
  let newState = state;
  newState = stateHelper.set(newState, payload)
    .setIn([parseInt(payload.data.id), 'take_fetched_at'], payload.serverTimeNow);
  const question = payload.included.find(record => record.type === 'question');
  if (!question) return newState;
  const questionId = parseInt(question.id);
  if (!questionId) return newState;

  return newState;
}

reducers[QUIZ_FETCHED_PERFORMANCE] = (state, payload) => stateHelper.set(state, payload)
  .setIn([parseInt(payload.data.id), 'performance_fetched_at'], payload.serverTimeNow);

reducers[QUIZ_CREATE_SEARCH] = (state, payload) => state.set(payload.searchId, new Quiz({ id: payload.searchId, attributes: { type: 'search', name: 'Browse Mode' } }));
reducers[QUIZ_DELETE_SEARCH] = (state, payload) => state.delete(payload.searchId);
reducers[QUIZ_ADD_SEARCH_QUESTIONS] = (state, payload) => {
  const updatedQuiz = state.get(payload.searchId).set('question_ids', payload.questionIds);
  return state.set(payload.searchId, updatedQuiz);
};

reducers[QUIZ_FETCHING_TAKE] = quizFetchingTake;
reducers[QUIZ_FETCHED_TAKE] = quizFetchedTake;

reducers[QUIZ_UNLOAD_TAKE] = (state, payload) => {
  const { quizId } = payload;

  const newQuiz = state.get(quizId)
    .set('take_fetched_at', null)
    .set('questions_fetched_at', null)
    .set('time_slices', List())
    .set('seen_questions', Map())
    .set('notes', Map())
    .set('highlights', Map())
    .set('strike_throughs', Map())
    .set('quiz_blocks', Map());
  return state.set(quizId, newQuiz);
};

reducers[QUIZ_FETCHED_PRACTICE] = stateHelper.set;
reducers[QUIZ_END_QUIZ_BLOCK_SYNCED] = stateHelper.set;
reducers[QUIZ_FETCHED_ALL_QUESTIONS] = (state, payload) => state.setIn([payload.quizId, 'questions_fetched_at'], payload.serverTimeNow);
reducers[QUIZZES_FETCHED] = stateHelper.set;
reducers[QUIZ_CREATING] = quizFetchingTake;
reducers[QUIZ_CREATED] = quizFetchedTake;
reducers[QUIZ_SEEN_QUESTION_SYNCED] = stateHelper.set;

reducers[QUIZ_SEEN_QUESTION_OFFLINE] = function quizSeenQuestionOffline(state, payload) {
  const newState = state
    .setIn([payload.quizId, 'seen_questions', payload.questionId], payload.lastSeenAt)
    .setIn([payload.quizId, 'last_seen_question_number'], payload.questionNumber)
    .setIn([payload.quizId, 'synced'], false)
    .setIn([payload.quizId, 'updated_at'], payload.serverTimeNow);

  if (!payload.groupId) return newState;

  return newState
    .updateIn([payload.quizId, 'seen_case_question_ids'], questionIds => questionIds.push(payload.questionId));
};

reducers[QUIZ_TICK] = function quizTick(state, payload) {
  // If we dispatch a 0-second Quiz Tick then we need to change the state without changing any values.
  // This is used by No-Pause and Due-Date Assignments.
  if (payload.seconds === 0) {
    return state.set('cache-buster', true).delete('cache-buster');
  }

  let newSecondsRemaining = 0;
  const quiz = state.get(payload.quizId);
  let newState = state;
  let whichSeconds = 'seconds_remaining';
  if (quiz.get('quiz_blocks').size) {
    const currentBlock = quiz.get('quiz_blocks').find(b => b.get('position') === quiz.get('last_quiz_block_number'));
    if (currentBlock.get('question_ids').size) {
      if (quiz.get('block_pool_seconds_remaining') !== null) {
        if (quiz.get('block_pool_seconds_remaining') === 0) {
          newSecondsRemaining = 0;
        } else {
          newSecondsRemaining = quiz.get('block_pool_seconds_remaining') - payload.seconds;
        }
        whichSeconds = 'block_pool_seconds_remaining';
      }
    } else if (quiz.get('break_pool_seconds_remaining') !== null) {
      if (quiz.get('break_pool_seconds_remaining') === 0) {
        newSecondsRemaining = 0;
      } else {
        newSecondsRemaining = quiz.get('break_pool_seconds_remaining') - payload.seconds;
      }
      whichSeconds = 'break_pool_seconds_remaining';
    }
  } else {
    newSecondsRemaining = quiz.get('seconds_remaining') - payload.seconds;
    if (newSecondsRemaining <= 0) {
      newState = newState.setIn([payload.quizId, 'status'], "ready-for-submission");
    }
  }
  return newState
    .setIn([payload.quizId, whichSeconds], newSecondsRemaining)
    .setIn([payload.quizId, 'synced'], false)
    .setIn([payload.quizId, 'updated_at'], payload.serverTimeNow);
};

reducers[QUIZ_SUBMITTED] = (state, payload) => stateHelper.set(state, payload)
  .setIn([parseInt(payload.data.id), 'performance_fetched_at'], payload.serverTimeNow);
reducers[QUIZ_ARCHIVED] = stateHelper.set;
reducers[QUIZ_UNARCHIVED] = stateHelper.set;
reducers[QUIZZES_ARCHIVED] = stateHelper.update;
reducers[QUIZZES_UNARCHIVED] = stateHelper.update;


// Non-Quizzes Action Reducers

reducers[QUIZ_QUESTION_FETCHED_NEXT] = stateHelper.set;

reducers[SESSION_RESET] = stateHelper.reset;
reducers[QUIZ_UNLOAD_PERFORMANCE] = stateHelper.reset;

reducers[OFFLINE_QUIZ_SYNCED] = stateHelper.update;

reducers[OFFLINE_QUIZ_LOADED] = function offlineQuizLoaded(state, payload) {
  let newState = quizFetchingTake(state, { key: 'offline-load', ...payload });
  payload.questions.forEach((question) => {
    const key = `${payload.quiz.get('id')}/questions/${question.get('id')}`;
    newState = newState.setIn(['fetched_ats', key], payload.quiz.get('questions_fetched_at'));
  });

  // Left instance of new Date getTime here b/c not sure if server time was available
  return newState
    .set(payload.quiz.get('id'), payload.quiz)
    .setIn([payload.quiz.get('id'), 'take_fetched_at'], new Date().getTime());
};


reducers[QUIZ_SET_LAST_SEEN_BLOCK] = function quizSetLastSeenBlock(state, payload) {
  return state.setIn([payload.quizId, 'last_quiz_block_number'], payload.position);
};


reducers[RESPONSE_SYNC_OFFLINE] = function responseSyncOffline(state, payload) {
  const {
    createdAt,
    quizId,
    questionId,
    event,
    time
  } = payload;

  const newTimeSlice = Map({
    created_at: createdAt,
    question_id: questionId,
    event,
    time
  });

  let newQuiz = state.get(quizId);
  newQuiz = newQuiz.update('time_slices', timeSlices => timeSlices.push(newTimeSlice));
  newQuiz = newQuiz.set('synced', false);
  return state.set(payload.quizId, newQuiz);
};


reducers[PERFORMANCE_QUIZZES_FETCHED] = function performanceQuizzesFetched(state, payload) {
  return state.withMutations((newState) => {
    payload.quizzes.forEach((quiz) => {
      newState.set(quiz[0], new Map({
        id: quiz[0],
        question_bank_id: quiz[1],
        status: quiz[2],
        type: quiz[3],
        archived: quiz[4],
        total_questions: quiz[5],
        assignment_id: quiz[6] || 0,
        name: quiz[7],
        created_at: Math.floor(quiz[8] * 1000),
        seconds_per_question: quiz[9],
        seconds_remaining: quiz[10],
        updated_at: Math.floor(quiz[11] * 1000),
        started_at: Math.floor(quiz[12] * 1000),
        end_time: Math.floor(quiz[13] * 1000),
        offline_device_uuid: quiz[14],
        last_seen_question_number: quiz[15],
        percentile: quiz[16],
        difficulty_level: quiz[17],
        practice_exam_template_id: quiz[18],
        is_ngn: quiz[19],
        cutoff_time: quiz[20],
        question_ids: JSON.parse("[" + quiz[21].slice(1, quiz[21].length - 1) + "]"),
        user_id: quiz[22],
      }));
    });
  });
};

reducers[ASSIGNMENT_QUIZZES_FETCHED] = function assignmentQuizzesPerformance(state, payload) {
  return state.withMutations((newState) => {
    payload.list.forEach((assignmentQuizzes) => {
      // parsing question_ids to array
      const ids = [];
      let id = '';
      for (let i = 0; i < assignmentQuizzes.question_ids.length; i += 1) {
        const char = assignmentQuizzes.question_ids[i];
        if (char !== '{' && char !== '}' && char !== ',') {
          id += char;
        } else if (char === ',' || char === '}') {
          ids.push(parseInt(id));
          id = '';
        }
      }
      const quizObject = {
        id: assignmentQuizzes.id,
        attributes: {
          status: assignmentQuizzes.status,
          end_time: Math.floor(parseInt(assignmentQuizzes.end_time) * 1000),
          total_questions: assignmentQuizzes.total_questions,
          question_ids: ids,
          potential_score: assignmentQuizzes.potential_score,
          actual_score: assignmentQuizzes.actual_score,
          is_ngn: assignmentQuizzes.is_ngn,
          num_answered_correctly: assignmentQuizzes.cached_num_answered_correctly,
          num_answered: assignmentQuizzes.cached_num_answered,
          score: assignmentQuizzes.score,
          percentile: assignmentQuizzes.percentile,
          difficulty_level: assignmentQuizzes.difficulty_level,
        },
        relationships: {
          user: {
            data: {
              id: assignmentQuizzes.user_id
            }
          },
          assignment: {
            data: {
              id: assignmentQuizzes.assignment_id
            }
          },
          question_bank: {
            data: {
              id: assignmentQuizzes.question_bank_id
            }
          }
        }
      };
      if (newState.has(assignmentQuizzes.id)) {
        newState.update(assignmentQuizzes.id, quiz => quiz.merge(quizObject));
      } else {
        newState.set(assignmentQuizzes.id, new Quiz(quizObject));
      }
    });
  });
};

export const reducer = stateHelper.createReducer(reducers);

export default reducers;
