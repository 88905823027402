import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgX(props) {
  return (
    <SvgContainer title="X Icon" {...props}>
      <path d="M16.66 16l7.496-8.673a.5.5 0 10-.756-.654L16 15.236 8.6 6.672a.5.5 0 10-.756.654L15.34 16l-7.495 8.673a.5.5 0 10.756.654l7.4-8.563 7.4 8.563a.5.5 0 00.756-.654z" />
    </SvgContainer>
  );
}
