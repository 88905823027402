import { Map } from 'immutable';

import StateHelper from 'helpers/StateHelper';
import User from 'factories/User';

import { PERFORMANCE_QUIZZES_FETCHED } from 'actions/performance';
import {
  USER_UPDATED
} from 'actions/users';
import {
  QUESTION_MARKED,
  QUESTION_UNMARKED
} from 'actions/questions';
import {
  PEARL_MARKED,
  PEARL_UNMARKED
} from 'actions/clinicalPearls';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  QUIZ_FETCHED_PERFORMANCE,
  QUIZ_FETCHED_TAKE,
  QUIZZES_FETCHED,
} from 'actions/quizzes';
import {
  ASSIGNMENT_USERS_FETCHED,
  ASSIGNMENTS_LIST_FETCHED,
  ASSIGNMENT_FETCHED
} from 'actions/b2b/assignments';
import {
  QUESTION_BANK_SELECTED
} from 'actions/questionBanks';
import {
  ORGANIZATION_MEMBERS_FETCHED,
  ORGANIZATION_SELECTED,
  RELOAD_CURRENT_USER,
  ORGANIZATION_UNLOAD_MEMBERS
} from 'actions/b2b/organizations';
import {
  MEMBER_GROUP_FETCHED,
  MEMBER_GROUPS_FETCHED,
  MEMBER_GROUP_USERS_FETCHED,
} from 'actions/b2b/memberGroups';
import {
  REPORT_USERS_FETCHED
} from 'actions/b2b/reports';
import {
  EXAM_TEMPLATES_LIST_FETCHED
} from 'actions/b2b/examTemplates';

const stateHelper = new StateHelper(User);
export const { initialState } = stateHelper;
const reducers = {};

reducers[USER_UPDATED] = stateHelper.set;

// Non-Users Action Reducers

reducers[SESSION_FETCHED] = stateHelper.set;
reducers[SESSION_RESET] = stateHelper.reset;

reducers[QUESTION_MARKED] = stateHelper.set;
reducers[QUESTION_UNMARKED] = stateHelper.set;

reducers[PEARL_MARKED] = stateHelper.set;
reducers[PEARL_UNMARKED] = stateHelper.set;

reducers[QUIZ_FETCHED_PERFORMANCE] = stateHelper.set;
reducers[QUIZ_FETCHED_TAKE] = stateHelper.set;
reducers[QUIZZES_FETCHED] = stateHelper.set;
reducers[ORGANIZATION_MEMBERS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUPS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_USERS_FETCHED] = stateHelper.set;
reducers[ASSIGNMENTS_LIST_FETCHED] = stateHelper.set;

reducers[ORGANIZATION_UNLOAD_MEMBERS] = stateHelper.reset;
reducers[EXAM_TEMPLATES_LIST_FETCHED] = stateHelper.set;
reducers[REPORT_USERS_FETCHED] = stateHelper.set;
reducers[RELOAD_CURRENT_USER] = function reloadCurrentUser(state, payload) {
  return state.set(payload.get('id'), payload);
};

reducers[ORGANIZATION_SELECTED] = (state, payload) => state.filter(user => payload.whitelistedUserIds.includes(user.get('id')));
reducers[ASSIGNMENT_USERS_FETCHED] = stateHelper.set;
reducers[ASSIGNMENT_FETCHED] = stateHelper.set;

reducers[PERFORMANCE_QUIZZES_FETCHED] = function performanceQuizzesFetched(state, payload) {
  if (!payload.assignment_creators) return state;

  return state.withMutations((newState) => {
    payload.assignment_creators.forEach((user) => {
      newState.set(user[0], state.get(user[0], new Map()).merge({
        id: user[0],
        first_name: user[1],
        last_name: user[2]
      }));
    });
  });
};

reducers[QUESTION_BANK_SELECTED] = function questionBankSelected(state, payload) {
  if (!payload.meta || !payload.meta.user) return state;

  // TODO: In API V2 this will be updated so we can just use stateHelper.set regularly
  return stateHelper.set(state, { data: payload.meta.user });
};

export default stateHelper.createReducer(reducers);
