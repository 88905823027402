import StateHelper from 'helpers/StateHelper';
import OrganizationalSubscription from 'factories/OrganizationalSubscription';

import {
  USER_SUBSCRIPTIONS_FETCHED
} from 'actions/users';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  ORGANIZATION_FETCHED
} from 'actions/b2b/organizations';


const stateHelper = new StateHelper(OrganizationalSubscription);
export const { initialState } = stateHelper;
const reducers = {};


reducers[USER_SUBSCRIPTIONS_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[ORGANIZATION_FETCHED] = stateHelper.set;
reducers[SESSION_RESET] = stateHelper.reset;


export default stateHelper.createReducer(reducers);
