import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLeftCaret3(props) {
  return (
    <SvgContainer title="Left Caret Icon" {...props}>
      <path d="M15.179 3.13918C15.8081 2.60023 15.8812 1.65331 15.3423 1.02416C14.8034 0.395005 13.8564 0.321877 13.2273 0.860821L15.179 3.13918ZM2.00386 12.4501L1.02802 11.311C0.695344 11.5959 0.503862 12.0121 0.503862 12.4501C0.503862 12.8882 0.695344 13.3043 1.02802 13.5893L2.00386 12.4501ZM13.2273 24.0395C13.8564 24.5784 14.8034 24.5053 15.3423 23.8761C15.8812 23.247 15.8081 22.3 15.179 21.7611L13.2273 24.0395ZM13.2273 0.860821L1.02802 11.311L2.97971 13.5893L15.179 3.13918L13.2273 0.860821ZM1.02802 13.5893L13.2273 24.0395L15.179 21.7611L2.97971 11.311L1.02802 13.5893Z" fill="#ECF2F6" />
    </SvgContainer>
  );
}
