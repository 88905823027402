import api, { currentUserUrl, selectedOrganizationUrl, } from 'helpers/api';
import { createApiRequestAction } from 'helpers/createAction';

export const ASSIGNMENTS_FETCHING = 'ASSIGNMENTS_FETCHING';
export const ASSIGNMENTS_FETCHED = 'ASSIGNMENTS_FETCHED';
export const ASSIGNMENTS_FETCH_ERROR = 'ASSIGNMENTS_FETCH_ERROR';

export const ASSIGNMENT_UPDATING = 'ASSIGNMENT_UPDATING';
export const ASSIGNMENT_UPDATED = 'ASSIGNMENT_UPDATED';
export const ASSIGNMENT_UPDATE_ERROR = 'ASSIGNMENT_UPDATE_ERROR';
const updateActionConstants = {
  loadingConstant: ASSIGNMENT_UPDATING,
  loadedConstant: ASSIGNMENT_UPDATED,
  errorConstant: ASSIGNMENT_UPDATE_ERROR,
};

export const ASSIGNMENT_CREATING = 'ASSIGNMENT_CREATING';
export const ASSIGNMENT_CREATED = 'ASSIGNMENT_CREATED';
export const ASSIGNMENT_CREATE_ERROR = 'ASSIGNMENT_CREATE_ERROR';
export const assignmentsCreate = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/assignments/create`,
  request: (getState, organizationId, params) =>
    api.post(`${currentUserUrl(getState())}/organizations/${organizationId}/assignments`, { assignment: params }),
  loadingConstant: ASSIGNMENT_CREATING,
  loadedConstant: ASSIGNMENT_CREATED,
  errorConstant: ASSIGNMENT_CREATE_ERROR,
});

const getAssignmentsFetchKey = organizationId => `organizations/${organizationId}/assignments`;
export const assignmentsFetch = organizationId => (dispatch, getState) => {
  const { loading } = getState();
  const key = getAssignmentsFetchKey(organizationId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/assignments`)
    .then((response) => {
      dispatch({
        type: ASSIGNMENTS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ASSIGNMENTS_FETCH_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: ASSIGNMENTS_FETCHING,
    payload: { key, promise }
  });
};

export const assignmentsUpdate = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/update`,
  request: (getState, assignmentId, params) =>
    api.patch(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}`, { assignment: params }),
  ...updateActionConstants,
});

export const ASSIGNMENT_MESSAGE_CREATING = 'ASSIGNMENT_MESSAGE_CREATING';
export const ASSIGNMENT_MESSAGE_CREATED = 'ASSIGNMENT_MESSAGE_CREATED';
export const ASSIGNMENT_MESSAGE_CREATE_ERROR = 'ASSIGNMENT_MESSAGE_CREATE_ERROR';
export const sendAssignmentMessage = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/message`,
  request: (getState, assignmentId, params) =>
    api.post(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/message`, { assignment: params }),
  loadingConstant: ASSIGNMENT_MESSAGE_CREATING,
  loadedConstant: ASSIGNMENT_MESSAGE_CREATED,
  errorConstant: ASSIGNMENT_MESSAGE_CREATE_ERROR,
});

export const assignmentAssociate = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/associate`,
  request: (getState, assignmentId) => api.patch(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/associate`),
  ...updateActionConstants,
});

export const assignmentArchive = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/archive`,
  request: (getState, assignmentId) => api.patch(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/archive`),
  ...updateActionConstants,
});

export const assignmentUnarchive = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/unarchive`,
  request: (getState, assignmentId) => api.patch(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/unarchive`),
  ...updateActionConstants,
});

export const ASSIGNMENTS_LIST_FETCHING = 'ASSIGNMENTS_LIST_FETCHING';
export const ASSIGNMENTS_LIST_FETCHED = 'ASSIGNMENTS_LIST_FETCHED';
export const ASSIGNMENTS_LIST_FETCH_ERROR = 'ASSIGNMENTS_LIST_FETCH_ERROR';
export const assignmentsListFetch = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/assignments/fetch_list`,
  request: (getState, organizationId) => api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/assignments/fetch_list`),
  loadingConstant: ASSIGNMENTS_LIST_FETCHING,
  loadedConstant: ASSIGNMENTS_LIST_FETCHED,
  errorConstant: ASSIGNMENTS_LIST_FETCH_ERROR,
});


export const ASSIGNMENT_FETCHING = 'ASSIGNMENT_FETCHING';
export const ASSIGNMENT_FETCHED = 'ASSIGNMENT_FETCHED';
export const ASSIGNMENT_FETCH_ERROR = 'ASSIGNMENT_FETCH_ERROR';
export const assignmentFetch = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}`,
  request: (getState, assignmentId) => api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}`),
  loadingConstant: ASSIGNMENT_FETCHING,
  loadedConstant: ASSIGNMENT_FETCHED,
  errorConstant: ASSIGNMENT_FETCH_ERROR,
});

assignmentsFetch.getKey = getAssignmentsFetchKey;

export const ASSIGNMENT_RESPONSES_FETCHING = 'ASSIGNMENT_RESPONSES_FETCHING';
export const ASSIGNMENT_RESPONSES_FETCHED = 'ASSIGNMENT_RESPONSES_FETCHED';
export const ASSIGNMENT_RESPONSES_FETCH_ERROR = 'ASSIGNMENT_RESPONSES_FETCH_ERROR';
export const assignmentResponsesFetch = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/fetch_responses`,
  request: (getState, assignmentId) => api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_responses`),
  loadingConstant: ASSIGNMENT_RESPONSES_FETCHING,
  loadedConstant: ASSIGNMENT_RESPONSES_FETCHED,
  errorConstant: ASSIGNMENT_RESPONSES_FETCH_ERROR,
});

export const ASSIGNMENT_QUIZZES_FETCHING = 'ASSIGNMENT_QUIZZES_FETCHING';
export const ASSIGNMENT_QUIZZES_FETCHED = 'ASSIGNMENT_QUIZZES_FETCHED';
export const ASSIGNMENT_QUIZZES_FETCH_ERROR = 'ASSIGNMENT_QUIZZES_FETCH_ERROR';
export const assignmentQuizzesFetch = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/fetch_quizzes`,
  request: (getState, assignmentId) => api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_quizzes`),
  loadingConstant: ASSIGNMENT_QUIZZES_FETCHING,
  loadedConstant: ASSIGNMENT_QUIZZES_FETCHED,
  errorConstant: ASSIGNMENT_QUIZZES_FETCH_ERROR,
});

export const ASSIGNMENT_USERS_FETCHING = 'ASSIGNMENT_USERS_FETCHING';
export const ASSIGNMENT_USERS_FETCHED = 'ASSIGNMENT_USERS_FETCHED';
export const ASSIGNMENT_USERS_FETCH_ERROR = 'ASSIGNMENT_USERS_FETCH_ERROR';
export const assignmentUsersFetch = createApiRequestAction({
  getKey: assignmentId => `organizations/assignments/${assignmentId}/fetch_students`,
  request: (getState, assignmentId) => api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_students`),
  loadingConstant: ASSIGNMENT_USERS_FETCHING,
  loadedConstant: ASSIGNMENT_USERS_FETCHED,
  errorConstant: ASSIGNMENT_USERS_FETCH_ERROR,
});

export const assignmentIndividualPerformanceFetch = assignmentId => (dispatch, getState) => {
  const request = api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_individual_performance`)
    .then(response => response)
    .catch((error) => {
      throw error;
    });
  return request;
};

export const assignmentQuestionViewFetch = assignmentId => (dispatch, getState) => {
  const request = api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_question_view`)
    .then(response => response)
    .catch((error) => {
      throw error;
    });
  return request;
};

export const assignmentClassAverageFetch = assignmentId => (dispatch, getState) => {
  const request = api.get(`${selectedOrganizationUrl(getState())}/assignments/${assignmentId}/fetch_class_average`)
    .then(response => response)
    .catch((error) => {
      throw error;
    });
  return request;
};

export const UNLOAD_ASSIGNMENT = 'UNLOAD_ASSIGNMENT';
