import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgLabValue(props) {
  return (
    <SvgContainer title="Lab Value Icon" {...props}>
      <defs>
        <style type="text/css">
          {".lab-st1{fill:#b3c7d2}"}
        </style>
      </defs>
      <path d="M17.7 11H9.3C8.7 11 8 10.4 8 9.6V5.4C8 4.7 8.6 4 9.3 4h8.4c.6 0 1.3.6 1.3 1.4v4.3c-.1.7-.7 1.3-1.3 1.3z" fill="#f9ee41" /><path className="lab-st1" d="M19.1 8H8V4.7c0-.4.3-.7.7-.7h9.6c.4 0 .7.3.7.7V8h.1z" /><path d="M18.1 11H8.9c-1 0-1.9-.9-1.9-1.9V4.9C7 3.9 7.9 3 8.9 3h9.2c1 0 1.9.9 1.9 1.9v4.3c-.1 1-1 1.8-1.9 1.8zM8.9 4c-.5 0-.9.5-.9.9v4.2c0 .5.5.9.9.9H18c.4 0 .9-.4.9-.9V4.9c.1-.5-.4-.9-.8-.9H8.9z" /><g><path d="M18.9 10l2.8 8.8S24.5 30 14.5 30h-2.1c-10 0-7.2-11.2-7.2-11.2L8 10h10.9z" fill="#6cc9e5" /><path fill="#fdfbe3" d="M19.3 11l2.7 9H5.4l2.7-9z" /><path d="M18.9 10.5l2.8 8.8s2.8 11.2-7.2 11.2h-2.1c-10 0-7.2-11.2-7.2-11.2L8 10.5h10.9z" fill="none" stroke="#000" strokeMiterlimit="10" /></g><g><path d="M25.1 30h-4.3c-1 0-1.8-.7-1.8-1.5v-24c0-.8.8-1.5 1.8-1.5h4.3c1 0 1.8.7 1.8 1.5v24c.1.8-.7 1.5-1.8 1.5z" fill="#f8b5c3" /><path className="lab-st1" d="M26.1 13H20V4c0-.6.6-1 1.1-1h3.8c.7 0 1.1.5 1.1 1v9h.1z" /><path d="M24.8 31h-3.7c-1.2 0-2.1-.9-2.1-2.1V4.1c0-1.2.9-2.1 2.1-2.1h3.8c1.2 0 2.1.9 2.1 2.1V29c0 1.1-1 2-2.2 2zM20 4.1v24.8c0 .6.5 1.1 1.1 1.1h3.7c.6 0 1.2-.5 1.2-1V4.1c0-.6-.5-1.1-1.1-1.1h-3.8c-.6 0-1.1.5-1.1 1.1z" /></g>
    </SvgContainer>
  );
}
