import { DateTime } from 'luxon';

import api, { selectedQuestionBankUrl, currentUserUrl } from 'helpers/api';
import { createApiRequestAction } from 'helpers/createAction';
import { getServerTimeNow, setTimeDifference } from 'helpers/dateTime';

import { selectQuizWithRelationships } from 'selectors/quizzes';


export const QUIZ_LOADING = 'QUIZ_LOADING';
function quizLoading(key, promise, dispatch) {
  dispatch({
    type: QUIZ_LOADING,
    payload: { key, promise }
  });
}

export const QUIZ_ERROR = 'QUIZ_ERROR';
function quizError(error, dispatch, key) {
  dispatch({
    type: QUIZ_ERROR,
    payload: { error, key }
  });
  throw error;
}

const quizActionCreator = (actionType, keyMethod, restAction = 'post', finalPath = '', includeServerTime = true, quizApiTimeout = 10000) => (quizId, userIdArg) => (dispatch, getState) => {
  const { loading } = getState();
  const key = keyMethod(quizId);

  if (loading.has(key)) return loading.get(key);
  const promise = api[restAction](`${selectedQuestionBankUrl(getState(), userIdArg)}/quizzes${quizId ? `/${quizId}` : ''}${finalPath}`, {}, { timeout: quizApiTimeout })
    .then((response) => {
      dispatch({
        type: actionType,
        payload: {
          key,
          ...(!!quizId && { quizId }),
          ...response,
          ...(includeServerTime && { serverTimeNow: getServerTimeNow() })
        }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};

export const QUIZ_FETCHED_PERFORMANCE = 'QUIZ_FETCHED_PERFORMANCE';
const getQuizFetchPerformanceKey = quizId => `quiz-fetch-performance/${quizId}`;
export const quizFetchPerformance = quizActionCreator(QUIZ_FETCHED_PERFORMANCE, getQuizFetchPerformanceKey, 'get', '?v2');
quizFetchPerformance.getKey = getQuizFetchPerformanceKey;

export const QUIZ_FETCHED_PRACTICE = 'QUIZ_FETCHED_PRACTICE';
const getQuizFetchPracticeKey = () => `quiz-fetch-practice`;
export const quizFetchPractice = quizActionCreator(QUIZ_FETCHED_PRACTICE, getQuizFetchPracticeKey, 'get', '/fetch_practice_exam?v2');
quizFetchPractice.getKey = getQuizFetchPracticeKey;

export const QUIZ_FETCHED_NEW = 'QUIZ_FETCHED_NEW';
const getQuizFetchNewKey = () => 'quiz-fetch-new';
export const quizFetchNew = quizActionCreator(QUIZ_FETCHED_NEW, getQuizFetchNewKey, 'get', '/new?v2', '', false);
quizFetchNew.getKey = getQuizFetchNewKey;

export const QUIZ_SUBMITTED = 'QUIZ_SUBMITTED';
const getQuizSubmitKey = quizId => `submit/${quizId}`;
export const quizSubmit = quizActionCreator(QUIZ_SUBMITTED, getQuizSubmitKey, 'patch', '/submit?v2', true, 20000);
quizSubmit.getKey = getQuizSubmitKey;

export const QUIZ_ARCHIVED = 'QUIZ_ARCHIVED';
const getArchiveQuizKey = quizId => `archive/${quizId}`;
export const quizArchive = quizActionCreator(QUIZ_ARCHIVED, getArchiveQuizKey, 'patch', '/archive', false);
quizArchive.getKey = getArchiveQuizKey;

export const QUIZ_UNARCHIVED = 'QUIZ_UNARCHIVED';
const getUnarchiveQuizKey = quizId => `unarchive/${quizId}`;
export const quizUnarchive = quizActionCreator(QUIZ_UNARCHIVED, getUnarchiveQuizKey, 'patch', '/unarchive', false);
quizUnarchive.getKey = getUnarchiveQuizKey;

const getQuizzesSetOfflineDeviceUuidKey = quizId => `quizzes/set-offline-device-uuid/${quizId}`;
export const QUIZZES_UNSET_OFFLINE_DEVICE_UUID = 'QUIZZES_UNSET_OFFLINE_DEVICE_UUID';
export const quizzesUnsetOfflineDeviceUuid = quizActionCreator(QUIZZES_UNSET_OFFLINE_DEVICE_UUID, getQuizzesSetOfflineDeviceUuidKey, 'delete', '/offline_device_uuid');
quizzesUnsetOfflineDeviceUuid.getKey = getQuizzesSetOfflineDeviceUuidKey;

export const QUIZ_QUESTION_FETCHED_NEXT = 'QUIZ_QUESTION_FETCHED_NEXT';
const getQuestionNextKey = () => `questions/next`;
export const quizQuestionFetchNext = quizActionCreator(QUIZ_QUESTION_FETCHED_NEXT, getQuestionNextKey, 'get', '/questions/next?v2', false);
quizQuestionFetchNext.getKey = getQuestionNextKey;

export const QUIZ_RESPONSES_FETCHING = 'QUIZ_RESPONSES_FETCHING';
export const QUIZ_RESPONSES_FETCHED = 'QUIZ_RESPONSES_FETCHED';
export const QUIZ_RESPONSES_FETCH_ERROR = 'QUIZ_RESPONSES_FETCH_ERROR';
export const quizFetchResponses = createApiRequestAction({
  getKey: quizId => `quizzes/${quizId}/fetch_quiz_responses`,
  request: (getState, quizId) => api.get(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/fetch_quiz_responses`),
  loadingConstant: QUIZ_RESPONSES_FETCHING,
  loadedConstant: QUIZ_RESPONSES_FETCHED,
  errorConstant: QUIZ_RESPONSES_FETCH_ERROR,
});
export const QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES = 'QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES';
export const QUIZ_FETCHING_ALL_QUESTIONS = 'QUIZ_FETCHING_ALL_QUESTIONS';
export const QUIZ_FETCHED_QUESTIONS = 'QUIZ_FETCHED_QUESTIONS';
export const QUIZ_FETCHED_ALL_QUESTIONS = 'QUIZ_FETCHED_ALL_QUESTIONS';
export const QUIZ_FETCH_ALL_QUESTIONS_ERROR = 'QUIZ_FETCH_ALL_QUESTIONS_ERROR';
const quizFetchQuestionsKey = quizId => `quizzes/${quizId}/questions`;
export const quizFetchQuestions = (quizId, excludeResponses = false) => (dispatch, getState) => {
  const key = quizFetchQuestionsKey(quizId);

  const { loading, quizzes, performanceQuestions } = getState();
  if (loading.has(key)) return loading.get(key);

  const quiz = quizzes.get(quizId);
  if (!quiz) return Promise.reject(new Error('Quiz not found'));

  const pageCount = Math.ceil(quiz.get('question_ids').size / 25);
  const requests = [];

  for (let i = 1; i <= pageCount; i += 1) {
    const request = api.get(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions?page=${i}&student_id=${quiz.get('user_id')}&exclude_responses=${excludeResponses}`)
      .then((response) => {
        const difficultyLevels = response.included.filter(r => r.type === 'question').map(json => ({
          id: parseInt(json.id),
          difficulty: performanceQuestions.getIn([parseInt(json.id), 'difficulty'])
        }));
        dispatch({
          type: QUIZ_FETCHED_QUESTIONS,
          payload: { ...response, quizId, difficultyLevels }
        });
        return response;
      });
    requests.push(request);
  }

  const promise = Promise.all(requests)
    .then((responses) => {
      const flattenedResponse = {
        data: responses.filter(resultSet => !!resultSet).map(response => response.data).reduce((accumulator, item) => accumulator.concat(item), []),
        included: responses.filter(resultSet => !!resultSet).map(response => response.included).reduce((accumulator, item) => accumulator.concat(item), [])
      };
      dispatch({
        type: QUIZ_FETCHED_ALL_QUESTIONS,
        payload: { key, quizId, serverTimeNow: getServerTimeNow() }
      });
      return flattenedResponse;
    })
    .catch((error) => {
      dispatch({
        type: QUIZ_FETCH_ALL_QUESTIONS_ERROR,
        payload: { error, key, quizId }
      });
      throw error;
    });

  dispatch({
    type: QUIZ_FETCHING_ALL_QUESTIONS,
    payload: {
      key,
      promise,
      quizId,
      questionIds: quiz.get('question_ids')
    }
  });

  return promise;
};
quizFetchQuestions.getKey = quizFetchQuestionsKey;

export const QUIZ_FETCHING_TAKE = 'QUIZ_FETCHING_TAKE';
export const QUIZ_FETCHED_TAKE = 'QUIZ_FETCHED_TAKE';
export const SERVER_TIME_FETCHED = 'SERVER_TIME_FETCHED';
const quizFetchTakeKey = quizId => `quizzes/${quizId}/take`;
export const quizFetchTake = (quizId, studentId = '', fetchAssociations = true) => (dispatch, getState) => {
  const key = quizFetchTakeKey(quizId);
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/take?v2=true${!!studentId && `&student_id=${studentId}`}`)
    .then((response) => {
      // TODO: Reduce the number of state changes here. Currently this triggers THREE dispatches:
      // QUIZ_FETCHED_TAKE, QUIZ_FETCHING_ALL_QUESTIONS, and SERVER_TIME_FETCHED.
      // ATM QUIZ_FETCHED_TAKE must be dispatched before calling quizFetchQuestions() because
      // quizFetchQuestions() requires data for the quiz which is reduced from QUIZ_FETCHED_TAKE.
      dispatch({
        type: QUIZ_FETCHED_TAKE,
        payload: { key, ...response, serverTimeNow: getServerTimeNow() }
      });
      if (fetchAssociations) dispatch(quizFetchQuestions(quizId));
      const timeDifference = DateTime.fromISO(response.meta.server_time).diffNow().milliseconds;
      setTimeDifference(timeDifference);
      dispatch({
        type: SERVER_TIME_FETCHED,
        payload: {
          server_time: response.meta.server_time,
          time_difference: timeDifference
        }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_FETCHING_TAKE,
    payload: { key, promise }
  });

  return promise;
};
quizFetchTake.getKey = quizFetchTakeKey;

export const QUIZ_UNLOAD_TAKE = 'QUIZ_UNLOAD_TAKE';
export const quizUnloadTake = quizId => (dispatch, getState) => {
  const { quizzes } = getState();
  if (!quizzes.has(quizId)) return;

  dispatch({
    type: QUIZ_UNLOAD_TAKE,
    payload: { quizId }
  });
};

const ltiQuizFetchTakeKey = () => `quizzes/lti/take`;
export const ltiQuizFetchTake = (userId, assignmentId, authID, cutoffTime, fetchAssociations = true) => (dispatch, getState) => {
  const key = ltiQuizFetchTakeKey();
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);
  const promise = api.get(`assignments/lti_quiz?user_id=${userId}&assignment_id=${assignmentId}&auth_id=${authID}&ct=${cutoffTime}&v2`, { baseURL: '/api/lti/v1/' })
    .then((response) => {
      dispatch({
        type: QUIZ_FETCHED_TAKE,
        payload: { key, ...response, serverTimeNow: getServerTimeNow() }
      });
      const quizId = parseInt(response.data.id);
      if (fetchAssociations) dispatch(quizFetchQuestions(quizId));
      const timeDifference = DateTime.fromISO(response.meta.server_time).diffNow().milliseconds;
      setTimeDifference(timeDifference);
      dispatch({
        type: SERVER_TIME_FETCHED,
        payload: {
          server_time: response.meta.server_time,
          time_difference: timeDifference
        }
      });
      return quizId;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_FETCHING_TAKE,
    payload: { key, promise }
  });

  return promise;
};
ltiQuizFetchTake.getKey = ltiQuizFetchTakeKey;

export const QUIZZES_FETCHED = 'QUIZZES_FETCHED';
const getQuizzesFetchKey = () => 'quizzes-fetch-all';
export const quizzesFetch = estimatedQuizCount => (dispatch, getState) => {
  const key = getQuizzesFetchKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  // By default we'll assume there's only going to be one page of quizzes
  const estimatedPageCount = Math.ceil((estimatedQuizCount || 1) / 50);

  // We may need to make extra requests to get all the quizzes, but we won't have an accurate
  // count of the total quizzes until we get our first response here.
  // To work around this issue we set up extraRequestsPromise as an externally resolved/rejected Promise.
  let resolveExtraRequests;
  let rejectExtraRequests;
  const extraRequestsPromise = new Promise((resolve, reject) => {
    resolveExtraRequests = resolve;
    rejectExtraRequests = reject;
  });

  // makeExtraRequestsIfNeeded() will only run fully after the first response.
  // It checks for missed pages and creates a new collection of Promises for them if found.
  // If missed pages are found then extraRequestsPromise gets resolved/rejected after the new Promises.
  // If no missed pages are found then extraRequestsPromise gets resolved immediately.
  let checkedForExtraRequests = false;
  const makeExtraRequestsIfNeeded = (response) => {
    if (checkedForExtraRequests) return response;
    checkedForExtraRequests = true;

    if (response.meta && response.meta.total_pages && response.meta.total_pages > estimatedPageCount) {
      const extraRequests = [];
      for (let i = estimatedPageCount + 1; i <= response.meta.total_pages; i += 1) {
        const request = api.get(`${selectedQuestionBankUrl(getState())}/quizzes?page=${i}&v2_timed&v2_tutor&v2=true`);
        extraRequests.push(request);
      }

      Promise.all(extraRequests).then(resolveExtraRequests).catch(rejectExtraRequests);
    } else {
      resolveExtraRequests([]);
    }
    return response;
  };

  // Create our collection of Promises for the pages we think we'll find
  const requests = [];
  for (let i = 1; i <= estimatedPageCount; i += 1) {
    const request = api.get(`${selectedQuestionBankUrl(getState())}/quizzes?page=${i}&v2_timed&v2_tutor&v2=true`)
      .then(makeExtraRequestsIfNeeded);
    requests.push(request);
  }

  // Wait for everything to finish (including any extra requests) before resolving
  const promise = Promise.all([extraRequestsPromise, Promise.all(requests)])
    .then((responseArrays) => {
      const responses = responseArrays[0].concat(responseArrays[1]);
      const flattenedResponse = {
        data: responses.filter(resultSet => !!resultSet).map(response => response.data).reduce((accumulator, item) => accumulator.concat(item), []),
        included: responses.filter(resultSet => !!resultSet).map(response => response.included).reduce((accumulator, item) => accumulator.concat(item), [])
      };
      dispatch({
        type: QUIZZES_FETCHED,
        payload: { key, ...flattenedResponse }
      });
      return flattenedResponse;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizzesFetch.getKey = getQuizzesFetchKey;

export const QUIZ_ADD_SEARCH_QUESTIONS = 'QUIZ_ADD_SEARCH_QUESTIONS';
export const quizAddQuestionsToSearch = (questionIds, searchId) => (dispatch) => {
  dispatch({
    type: QUIZ_ADD_SEARCH_QUESTIONS,
    payload: { questionIds, searchId }
  });
};

export const setSearchQuizQuestionsFetchedAt = searchId => dispatch => dispatch({
  type: QUIZ_FETCHED_ALL_QUESTIONS,
  payload: {
    key: "SearchQuestions",
    quizId: searchId,
    serverTimeNow: getServerTimeNow()
  }
});

export const QUIZ_CREATING = 'QUIZ_CREATING';
export const QUIZ_CREATED = 'QUIZ_CREATED';
const getQuizCreateKey = () => 'quizzes/create'; // All Create Quiz Actions share the same key

export const quizzesCreatePractice = (practiceExamTemplateId, fetchAssociations = true, subject_ids = []) => (dispatch, getState) => {
  const key = getQuizCreateKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const quizParams = {
    practice_exam_template_id: practiceExamTemplateId,
    subject_ids: subject_ids,
    v2: true
  };

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/quizzes/practice_create`, { quiz: quizParams })
    .then((response) => {
      dispatch({
        type: QUIZ_CREATED,
        payload: { key, serverTimeNow: getServerTimeNow(), ...response }
      });
      if (fetchAssociations) {
        const quizId = parseInt(response.data.id);
        dispatch(quizFetchQuestions(quizId));
      }
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_CREATING,
    payload: { key, promise }
  });
  return promise;
};

export const quizzesCreate = (quizParams, fetchAssociations = true) => (dispatch, getState) => {
  const key = getQuizCreateKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/quizzes/custom_create`, { quiz: quizParams, v2: true })
    .then((response) => {
      dispatch({
        type: QUIZ_CREATED,
        payload: { key, serverTimeNow: getServerTimeNow(), ...response }
      });
      if (fetchAssociations) {
        const quizId = parseInt(response.data.id);
        dispatch(quizFetchQuestions(quizId));
      }
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_CREATING,
    payload: { key, promise }
  });
  return promise;
};
quizzesCreate.getKey = getQuizCreateKey;

export const quizzesCreateCAT = (subjectId, quizName, fetchAssociations = true) => (dispatch, getState) => {
  const key = getQuizCreateKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const quizParams = { type: "Adaptive" };
  if (subjectId) {
    quizParams.subject_ids = [subjectId];
  }
  if (quizName && quizName !== "") {
    quizParams.name = quizName;
  }

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/quizzes`, { quiz: quizParams, v2: true })
    .then((response) => {
      dispatch({
        type: QUIZ_CREATED,
        payload: { key, serverTimeNow: getServerTimeNow(), ...response }
      });
      if (fetchAssociations) {
        const quizId = parseInt(response.data.id);
        dispatch(quizFetchQuestions(quizId));
      }
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_CREATING,
    payload: { key, promise }
  });

  return promise;
};
quizzesCreateCAT.getKey = getQuizCreateKey;

export const quizzesQuickCreate = ({ fetchAssociations = true, numberOfQuestions = 10, isAtRisk = false }) => (dispatch, getState) => {
  const key = getQuizCreateKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const quizParams = { question_limit: numberOfQuestions, at_risk: isAtRisk };

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/quizzes/quick`, { quiz: quizParams, v2: true })
    .then((response) => {
      dispatch({
        type: QUIZ_CREATED,
        payload: { key, serverTimeNow: getServerTimeNow(), ...response }
      });
      if (fetchAssociations) {
        const quizId = parseInt(response.data.id);
        dispatch(quizFetchQuestions(quizId));
      }
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  dispatch({
    type: QUIZ_CREATING,
    payload: { key, promise }
  });
  return promise;
};
quizzesQuickCreate.getKey = getQuizCreateKey;

export const QUIZ_CREATE_SEARCH = 'QUIZ_CREATE_SEARCH';
export const quizCreateSearch = searchId => (dispatch) => {
  dispatch({
    type: QUIZ_CREATE_SEARCH,
    payload: {
      searchId
    }
  });
};
export const QUIZ_DELETE_SEARCH = 'QUIZ_DELETE_SEARCH';
export const quizDeleteSearch = searchId => (dispatch) => {
  dispatch({
    type: QUIZ_DELETE_SEARCH,
    payload: {
      searchId
    }
  });
};

export const QUIZ_SEEN_QUESTION_SYNCED = 'QUIZ_SEEN_QUESTION_SYNCED';
const getQuizSeenKey = (quizId, questionId) => `quiz/${quizId}/seen/${questionId}`;
export const quizSeenQuestion = (quizId, questionId) => (dispatch, getState) => {
  const quiz = selectQuizWithRelationships(getState(), quizId);
  if (!quiz) return Promise.reject(new Error('Quiz not loaded'));
  if (quiz.get('currentStatus') !== 'incomplete') return Promise.reject(new Error('Quiz already complete'));

  const { loading } = getState();
  const key = getQuizSeenKey(quizId, questionId);
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/seen`)
    .then((response) => {
      dispatch({
        type: QUIZ_SEEN_QUESTION_SYNCED,
        payload: {
          key,
          quizId,
          questionId,
          ...response
        }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizSeenQuestion.getKey = getQuizSeenKey;


export const QUIZ_SEEN_QUESTION_OFFLINE = 'QUIZ_SEEN_QUESTION_OFFLINE';
export const quizSeenQuestionOffline = (quizId, questionId) => (dispatch, getState) => {
  const { quizzes, questions } = getState();
  const quiz = quizzes.get(quizId);
  const groupId = questions.getIn([questionId, 'group_id']);
  const questionNumber = quiz.get('question_ids').indexOf(questionId) + 1;
  const serverTimeNow = getServerTimeNow();
  dispatch({
    type: QUIZ_SEEN_QUESTION_OFFLINE,
    payload: {
      lastSeenAt: serverTimeNow,
      quizId,
      questionId,
      groupId,
      questionNumber,
      serverTimeNow
    }
  });
};

export const NAVIGATED_TO_QUIZ_BLOCK = 'NAVIGATED_TO_QUIZ_BLOCK';

export const QUIZ_END_QUIZ_BLOCK_SYNCED = 'QUIZ_END_QUIZ_BLOCK_SYNCED';
const getEndQuizBlockKey = quizId => `quizBlock/${quizId}`;
export const quizEndQuizBlock = (quizId, quizBlockNumber) => (dispatch, getState) => {
  const key = getEndQuizBlockKey(quizId);
  const { loading } = getState();

  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(
    `${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/next_quiz_block`,
    { quiz_block_number: quizBlockNumber }
  )
    .then((response) => {
      dispatch({
        type: QUIZ_END_QUIZ_BLOCK_SYNCED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};

quizEndQuizBlock.getKey = getEndQuizBlockKey;

export const QUIZ_TICK = 'QUIZ_TICK';
export const quizTick = (quizId, seconds = 1) => (dispatch) => {
  dispatch({
    type: QUIZ_TICK,
    payload: {
      quizId,
      seconds,
      serverTimeNow: getServerTimeNow()
    }
  });
};

export const QUIZ_TICK_SYNCED = 'QUIZ_TICK_SYNCED';
const getQuizTickSyncKey = quizId => `tick/${quizId}`;
export const quizTickSync = quizId => (dispatch, getState) => {
  const key = getQuizTickSyncKey(quizId);
  const { quizzes, loading } = getState();

  if (loading.has(key)) return loading.get(key);

  const quiz = quizzes.get(quizId);

  if (!quiz) return Promise.reject(new Error('Quiz tick error, called tick on quiz no loaded'));

  if (quiz.get('practice_exam_template_id') && !quiz.get('quiz_blocks').size) return Promise.reject(new Error('Quiz tick error, called tick on block quiz with no blocks loaded'));

  let currentBlock;
  let quizSecondsRemaining = 0;
  if (quiz.get('quiz_blocks') && quiz.get('quiz_blocks').size) {
    currentBlock = quiz.get('quiz_blocks').find(b => b.get('position') === quiz.get('last_quiz_block_number'));
    if (quiz.get('board_exam_interface') === 'fred') {
      if (currentBlock.get('seconds_remaining') <= 0 && (currentBlock.get('position') !== quiz.get('quiz_blocks').size)) {
        const blocksToGo = quiz.get('quiz_blocks').filter(qb => qb.get('position') > quiz.get('last_quiz_block_number'));
        const eligibleBlocks = blocksToGo.filter(qb => qb.get('seconds_remaining') > 0);
        currentBlock = eligibleBlocks.toList().sortBy(qb => qb.get('position')).first();
      }
    }
    if (currentBlock.get('question_ids').size) {
      if (quiz.get('block_pool_seconds_remaining') !== null) {
        quizSecondsRemaining = quiz.get('block_pool_seconds_remaining');
      } else {
        quizSecondsRemaining = currentBlock.get('seconds_remaining');
      }
    } else if (quiz.get('break_pool_seconds_remaining') !== null) {
      quizSecondsRemaining = quiz.get('break_pool_seconds_remaining');
    } else if (quiz.get('break_pool_seconds_remaining') === null) {
      quizSecondsRemaining = currentBlock.get('seconds_remaining');
    }
  } else {
    quizSecondsRemaining = quiz.get('seconds_remaining');
  }

  const promise = api.patch(
    `${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/tick`,
    { seconds_remaining: quizSecondsRemaining, quiz_block_id: currentBlock ? currentBlock.get('id') : {} }
  )
    .then((response) => {
      dispatch({
        type: QUIZ_TICK_SYNCED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizTickSync.getKey = getQuizTickSyncKey;


export const QUIZZES_ARCHIVED = 'QUIZZES_ARCHIVED';
const getArchiveQuizzesKey = questionBankId => `archive-all/${questionBankId}`;
export const quizzesArchive = questionBankId => (dispatch, getState) => {
  const { session, loading } = getState();
  const qbId = questionBankId || session.get('selected_question_bank_id');
  const key = getArchiveQuizzesKey(qbId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`${currentUserUrl(getState())}/question_banks/${qbId}/quizzes/archive`)
    .then((response) => {
      dispatch({
        type: QUIZZES_ARCHIVED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizzesArchive.getKey = getArchiveQuizzesKey;

export const QUIZZES_UNARCHIVED = 'QUIZZES_UNARCHIVED';
const getUnarchiveQuizzesKey = questionBankId => `unarchive-all/${questionBankId}`;
export const quizzesUnarchive = questionBankId => (dispatch, getState) => {
  const { session, loading } = getState();
  const qbId = questionBankId || session.get('selected_question_bank_id');
  const key = getUnarchiveQuizzesKey(qbId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`${currentUserUrl(getState())}/question_banks/${qbId}/quizzes/unarchive`)
    .then((response) => {
      dispatch({
        type: QUIZZES_UNARCHIVED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizzesUnarchive.getKey = getUnarchiveQuizzesKey;

export const QUIZZES_SET_OFFLINE_DEVICE_UUID = 'QUIZZES_SET_OFFLINE_DEVICE_UUID';
export const quizzesSetOfflineDeviceUuid = (quizId, deviceUuid) => (dispatch, getState) => {
  const { loading } = getState();
  const key = getQuizzesSetOfflineDeviceUuidKey(quizId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.patch(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/offline_device_uuid/${deviceUuid}`)
    .then((response) => {
      dispatch({
        type: QUIZZES_SET_OFFLINE_DEVICE_UUID,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => quizError(error, dispatch, key));

  quizLoading(key, promise, dispatch);
  return promise;
};
quizzesSetOfflineDeviceUuid.getKey = getQuizzesSetOfflineDeviceUuidKey;

export const QUIZ_SET_LAST_SEEN_BLOCK = 'QUIZ_SET_LAST_SEEN_BLOCK';
export const quizSetLastSeenBlock = (quizId, position) => dispatch => (
  dispatch({
    type: QUIZ_SET_LAST_SEEN_BLOCK,
    payload: { position, quizId }
  })
);
export const QUIZ_UNLOAD_PERFORMANCE = 'QUIZ_UNLOAD_PERFORMANCE';
