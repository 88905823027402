import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgClosedBookDark(props) {
  return (
    <SvgContainer title="Closed Book" {...props}>
      <path fill="none" d="M0 0h32v32H0z" />
      <path d="M12.389 20.747h8.918a.968.968 0 00.703-.298 1.02 1.02 0 00.29-.721V8.168a1.189 1.189 0 00-.332-.828 1.131 1.131 0 00-.805-.34H10.637a1.107 1.107 0 00-.805.34 1.164 1.164 0 00-.332.827v13.487c.002.781.305 1.53.843 2.081a2.841 2.841 0 002.027.865h8.919a.966.966 0 00.703-.297 1.017 1.017 0 00.29-.722 1.042 1.042 0 00-.294-.718.99.99 0 00-.699-.3h-8.9a.873.873 0 01-.626-.267.92.92 0 01-.259-.642.92.92 0 01.26-.642.873.873 0 01.625-.265z" fill="#101010" />
    </SvgContainer>
  );
}
