import React from "react";
import SVGIcon from "components/SVGIcon";
import { formatString } from "./homePageHelpers";

const CheckIcon = () => <SVGIcon name="HomeCMECheck" className="no-fill mr-4" height={22} width={22} viewBoxHeight={22} viewBoxWidth={22} />;

export default function HomeCMEProfessional({ cmeTextContent }) {
  const trialImageAltText = "Earn CME credits online";
  const { blurb_1: blurb1, blurb_2: blurb2, blurb_3: blurb3 } = cmeTextContent.cmeBlurbsData;

  return (
    <div className="HomeCMEProfessional home-section-wrapper">
      <div className="container">
        <div className="row d-flex flex-wrap px-3 px-md-0">
          <div className="col-12 col-lg-6 d-flex align-items-center order-2">
            <div className="mb-4 mb-lg-0 pb-3 pb-md-6 pb-lg-0">
              <h2 className="storefront section-title mt-4 mt-md-2 pt-3 pt-md-1">CONTINUING MEDICAL EDUCATION</h2>
              <h2 className="storefront header-style my-6 mt-md-6 mb-md-3 mb-lg-5 pt-md-2 pb-3 pb-lg-2">Earn Online CME Credits</h2>

              <p className="storefront pt-3">
                <CheckIcon />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: formatString(blurb1) || "CME online in 35+ specialties" }} />
              </p>
              <p className="storefront">
                <CheckIcon />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: formatString(blurb2) || "CME credit hours for Physicians, PAs, and Nurse Practitioners" }} />
              </p>
              <p className="storefront">
                <CheckIcon />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: formatString(blurb3) || "<i>AMA PRA Category 1 Credits<span>&#8482;</span></i>, MOC points, and Contact Hours" }} />
              </p>
              <div className="py-6 pt-md-5 mb-md-2 text-center text-md-start">
                <a href="/cme" className="more-btn-section p-4 font-weight-bold btn btn-primary text-center">
                  Explore Online CME Activities
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-end order-1 order-lg-2 mt-4 mt-lg-0">
            <img
              className="img-fluid d-lg-none pt-md-4"
              loading="lazy"
              src="/images/homepage/cme-board-review-sample-question-Tablet-Mobile.png"
              alt={trialImageAltText}
            />
            <img
              className="img-fluid d-none d-lg-block my-2"
              loading="lazy"
              src="/images/homepage/cme-board-review-sample-question-laptop-Desktop.png"
              alt={trialImageAltText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
