import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredCalculator(props) {
  return (
    <SvgContainer title="Calculator Icon" {...props}>
      <defs>
        <style type="text/css">
          {".calc-st2{fill:#fff}.calc-st3{fill:#434343}</style>"}
        </style>
      </defs>
      <path
        d="M30.7 30H1.3c-.2 0-.3-.1-.3-.3V6.8C1 5.8 1.8 5 2.8 5h26.3c1 0 1.8.8 1.8 1.8v22.9c.1.2 0 .3-.2.3z"
        fill="#8f8f8f"
      />
      <path
        d="M31 30H1V7.1C1 5.4 2.4 4 4.1 4H28c1.6 0 3 1.4 3 3.1V30zM2 29h28V7.1c0-1.2-.9-2.1-2.1-2.1H4.1C2.9 5 2 5.9 2 7.1V29z"
        fill="#1f2e37"
      />
      <path className="calc-st2" d="M4 8h24v10H4z" />
      <path className="calc-st3" d="M29 19H4V8h25v11zM5 18h23V9H5v9z" />
      <path className="calc-st2" d="M4 22h4v3H4z" />
      <path className="calc-st3" d="M9 26H4v-4h5v4zm-4-1h3v-2H5v2z" />
      <path className="calc-st2" d="M10 22h4v3h-4z" />
      <path className="calc-st3" d="M15 26h-5v-4h5v4zm-4-1h3v-2h-3v2z" />
      <g>
        <path className="calc-st2" d="M17 22h4v3h-4z" />
        <path className="calc-st3" d="M21 26h-5v-4h5v4zm-4-1h3v-2h-3v2z" />
      </g>
      <g>
        <path className="calc-st2" d="M23 22h4v3h-4z" />
        <path className="calc-st3" d="M27 26h-5v-4h5v4zm-4-1h3v-2h-3v2z" />
      </g>
      <path fill="none" d="M4 10.4h25V19H4z" />
      <text
        transform="translate(10.94 16.128)"
        fontSize="8"
        fontFamily="HelveticaNeue-Medium"
      >
        2.0
      </text>
    </SvgContainer>
  );
}
