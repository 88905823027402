import {
  List,
  Map
} from 'immutable';

import Factory from 'factories/_Factory';


export default class Quiz extends Factory {
  static jsonType = 'quiz';

  static jsonTypePlural = 'quizzes';

  static afterUpdate(record, json) {
    let newRecord = record;
    const { attributes } = json;

    if (attributes.question_ids || newRecord.get('question_ids') === undefined) {
      newRecord = newRecord.set('question_ids', new List(attributes.question_ids));
    }

    if (attributes.seen_case_question_ids || newRecord.get('seen_case_question_ids') === undefined) {
      newRecord = newRecord.set('seen_case_question_ids', new List(attributes.seen_case_question_ids));
    }

    if (json.attributes.end_time || newRecord.get('end_time') === undefined) {
      newRecord = newRecord.set('end_time', new Date(json.attributes.end_time).getTime());
    }

    if (json.attributes.started_at || newRecord.get('started_at') === undefined) {
      newRecord = newRecord.set('started_at', new Date(json.attributes.started_at).getTime());
    }

    if (newRecord.get('time_slices') === undefined) newRecord = newRecord.set('time_slices', List());
    if (newRecord.get('seen_questions') === undefined) newRecord = newRecord.set('seen_questions', Map());
    if (newRecord.get('notes') === undefined) newRecord = newRecord.set('notes', Map());
    if (newRecord.get('quiz_blocks') === undefined) newRecord = newRecord.set('quiz_blocks', Map());
    if (newRecord.get('highlights') === undefined) newRecord = newRecord.set('highlights', Map());
    if (newRecord.get('strike_throughs') === undefined) newRecord = newRecord.set('strike_throughs', Map());

    if (newRecord.get('take_fetched_at') === undefined) newRecord = newRecord.set('take_fetched_at', null);
    if (newRecord.get('questions_fetched_at') === undefined) newRecord = newRecord.set('questions_fetched_at', null);
    if (newRecord.get('performance_fetched_at') === undefined) newRecord = newRecord.set('performance_fetched_at', null);

    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      type: '',
      status: 'incomplete',
      total_questions: 1,
      last_seen_question_number: 1,
      seconds_remaining: 0,
      seconds_per_question: null,
      difficulty_levels: [],
      question_mode: '',
      difficulty_level: '',
      percentile: 0.0,
      average_difficulty: 0.0,
      archived: false,
      board_exam_interface: '',
      board_name: null,
      block_pool_seconds_remaining: null,
      break_pool_seconds_remaining: null,
      practice_exam_template_id: null,
      last_quiz_block_number: 1,
      offline_device_uuid: null,
      is_ngn: false,
      num_answered_correctly: 0,
      num_answered: 0,
      cutoff_time: null,
      potential_score: 0,
      actual_score: 0,
      score: 0,
      subject_performance: {},
      question_performance: {}
    };
  }

  static get belongsTo() {
    return ['question_bank', 'user', 'assignment', 'practice_exam_template'];
  }
}
