import React from "react";
import SvgContainer from './SvgContainer';

const PsiHelp = props => (
  <SvgContainer title="Help Icon" {...props}>
    <g opacity=".4"><path d="M24.2 12.7c0-5.1-4.2-9.3-9.3-9.3-5.2 0-9.3 4.2-9.3 9.3h5.5c0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 1.4-.8 2.7-1.9 3.3-1.8 1.1-3.3 2.7-4 4.7-.3.7-.4 1.4-.5 2.1v1.7h5.6v-1.7c.3-.9.9-1.7 1.8-2.1 2.7-1.6 4.5-4.6 4.5-8zM12.2 26.6h5.6v4.7h-5.6z" /></g><path className="PsiHelp_svg__st1" d="M25.2 11.7c0-5.1-4.2-9.3-9.3-9.3-5.2 0-9.3 4.2-9.3 9.3h5.5c0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 1.4-.8 2.7-1.9 3.3-1.8 1.1-3.3 2.7-4 4.7-.3.7-.4 1.4-.5 2.1v1.7h5.6v-1.7c.3-.9.9-1.7 1.8-2.1 2.7-1.6 4.5-4.6 4.5-8zM13.2 25.6h5.6v4.7h-5.6z" />
  </SvgContainer>
);

export default PsiHelp;
