import React from "react";
import { formatString } from "./homePageHelpers";

export default function HomeInstitutions({ institutionsTextContent }) {
  const imageAltText = "Board Prep & CME for Medical Institutions";
  const { institutionalHeaderText, institutionalDescriptionText } = institutionsTextContent;

  return (
    <div className="HomeInstitutions home-section-wrapper">
      <div className="container">
        <div className="row d-flex flex-wrap px-3 px-md-0">
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-end order-1 order-lg-2 my-6 py-lg-6">
            <div className="mr-lg-6  pr-lg-6">
              <img
                className="img-fluid d-lg-none pt-6 pb-4 pb-md-6 mt-3 mt-md-4 mb-2 mb-md-0"
                loading="lazy"
                src="/images/homepage/medical-student-hand-raised-institutions-iphone-ipad.jpeg"
                alt={imageAltText}
              />
              <img
                className="img-fluid d-none d-lg-block my-6 mr-6 py-5 pr-6"
                loading="lazy"
                src="/images/homepage/medical-student-hand-raised-institutions-desktop.jpg"
                alt={imageAltText}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center order-2">
            <div className="pb-5 pb-md-6 p-lg-0 mb-6 m-lg-0">
              <h2 className="storefront section-title">INSTITUTIONAL SOLUTIONS</h2>
              <h2
                className="storefront header-style my-6 pt-3 pt-lg-5 pb-lg-4"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(institutionalHeaderText) || "Support Your Students & Faculty" }}
              />
              <p
                className="storefront pb-4 pb-lg-5 mb-6 mb-md-3 mb-lg-2 mt-lg-2"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(institutionalDescriptionText) || "Trusted by 2,000+ World Class institutions, BoardVitals delivers expert content and a flexible platform designed to match educational needs. Whether you are looking to fill a need at a medical or nursing school, residency program, health sciences library or hospital, we’ll work with you to customize a solution." }}
              />
              <div className="pb-6 pb-md-0 pb-lg-4 mb-6"><img src="/images/homepage/institutions-graphic.svg" alt="Institutions Graphic" className="img-fluid mb-2 mb-md-0" /></div>
              <div className="text-center text-md-start">
                <a href="/institutional" className="more-btn-section p-4 font-weight-bold btn btn-primary text-center">
                  Bring BoardVitals to Your Institution
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
