import {
  Map,
  List
} from 'immutable';

import CmeOrganization from 'factories/CmeOrganization';
import createReducer from 'helpers/createReducer';
import {
  CME_COACH_SEARCHING,
  CME_COACH_SEARCHED,
  CME_COACH_SENDING_EMAIL,
  CME_COACH_SENT_EMAIL
} from 'actions/cmeCoach';


export const initialState = Map({
  isSearching: false,
  sendEmailStatus: null,
  isSendingEmail: false,
  organizations: List(),
  productPageIds: List(),
  searchedSpecialtyId: null,
  searchedStateId: null
});
const reducers = {};


reducers[CME_COACH_SEARCHING] = function cmeCoachSearching(state, payload) {
  const {
    selectedSpecialtyId,
    selectedStateId
  } = payload;

  return state
    .set('isSearching', true)
    .set('searchedStateId', selectedStateId)
    .set('searchedSpecialtyId', selectedSpecialtyId);
  // Uncomment these lines to remove results between searches
  // .set('organizations', List())
  // .set('productPageIds', List());
};
reducers[CME_COACH_SEARCHED] = function cmeCoachSearched(state, payload) {
  const organizations = payload.data.map(json => new CmeOrganization(json));
  const productPageIds = payload.included.filter(record => record.type === 'product_page').map(record => parseInt(record.id));
  return state
    .set('isSearching', false)
    .set('organizations', List(organizations))
    .set('productPageIds', List(productPageIds));
};


reducers[CME_COACH_SENDING_EMAIL] = function cmeCoachSendingEmail(state) {
  return state
    .set('isSendingEmail', true)
    .set('sendEmailStatus', null);
};
reducers[CME_COACH_SENT_EMAIL] = function cmeCoachSentEmail(state, payload) {
  return state
    .set('isSendingEmail', false)
    .set('sendEmailStatus', !!payload.sendEmailStatus);
};


export default createReducer(reducers, initialState);
