import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPreviousInactive(props) {
  return (
    <SvgContainer title="Previous Inactive Icon" {...props}>
      <path fill="#4b6575" d="M29.8 7.6v16.8L3.9 16z" /><path d="M30.7 25.6L1.1 16l29.5-9.6v19.2zM6.7 16L29 23.3V8.7L6.7 16z" fill="#98b1c1" />
    </SvgContainer>
  );
}
