import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgOpenBook(props) {
  return (
    <SvgContainer title="Open Book Icon" {...props}>
      <path d="M16 17.5a.5.5 0 00.5-.5v-6a.5.5 0 00-1 0v6a.5.5 0 00.5.5z" />
      <path d="M27.5 8h-2V6a1 1 0 00-1-1H19a3.49 3.49 0 00-3 1.726A3.49 3.49 0 0013 5H7.5a1 1 0 00-1 1v2h-2a1.016 1.016 0 00-1 1.03v15.882a1.016 1.016 0 001 1.03h8.086A1.503 1.503 0 0014 27h4a1.503 1.503 0 001.414-1.059H27.5a1.016 1.016 0 001-1.029V9.03A1.016 1.016 0 0027.5 8zm-11 13.5A2.502 2.502 0 0119 19h5.5v1H20a.5.5 0 000 1h4.5v1h-8zM7.5 6H13a2.503 2.503 0 012.5 2.5.5.5 0 001 0A2.502 2.502 0 0119 6h5.5v12H19a3.49 3.49 0 00-3 1.726A3.49 3.49 0 0013 18H7.5V6zm0 15H12a.5.5 0 000-1H7.5v-1H13a2.503 2.503 0 012.5 2.5v.5h-8zm20 3.941H19a.5.5 0 00-.5.5.533.533 0 01-.5.559h-4a.532.532 0 01-.5-.559.5.5 0 00-.5-.5l-8.5-.029V9h2v13a1 1 0 001 1h17a1 1 0 001-1V9.006l2 .023z" />
      <path d="M10 9.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM10 12.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM10 15.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM19 9.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM19 12.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM19 15.5h3a.5.5 0 000-1h-3a.5.5 0 000 1z" />
    </SvgContainer>
  );
}
