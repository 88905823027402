import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from 'components/SVGIcon';
import Button from 'components/inputs/Button';


// StyleGuide = elements/inputs/[text,number,password]-input, elements/inputs/text-area

// <InputPrepend> and <InputAppend> exist as wrapper components. Instead of being rendered themselves
// their children are extracted, manipulated, and rendered by <Input>
export const InputPrepend = () => null;
export const InputAppend = () => null;

export default function Input({
  children,
  type,
  name,
  placeholder,
  className,
  wrapperClassName,
  onChange,
  value,
  disabled,
  required,
  readOnly,
  min,
  max,
  minLength,
  maxLength,
  autoComplete,
  small,
  large,
  isInvalid,
  isValid,
  styleVariant,
  onBlur,
  onFocus,
  id,
  validate,
  invalidMsg,
  validMsg,
  rows,
  cols,
  onKeyPress,
  onKeyDown
}) {
  const inputClassName = classNames(`Input form-control type-${type} name-${name} ${className} ${styleVariant}`, {
    'form-control-sm': small,
    'form-control-lg': large,
    'is-invalid': isInvalid,
    'is-valid': isValid
  });

  const inputProps = {
    className: inputClassName,
    onChange: onChange ? ev => onChange(ev.currentTarget.value) : null,
    onKeyPress: onKeyPress ? ev => onKeyPress(ev) : null,
    onKeyDown: onKeyDown ? ev => onKeyDown(ev) : null,
    autoComplete: autoComplete ? 'on' : 'off',
    'aria-invalid': isInvalid,
    id,
    name,
    value,
    placeholder,
    disabled,
    required,
    readOnly,
    minLength,
    maxLength,
    onBlur,
    onFocus,
    ...(!placeholder && { 'aria-label': name })
  };

  const input = (
    <React.Fragment>
      { type === 'textarea' ? (
        <textarea
          rows={rows}
          cols={cols}
          {...inputProps}
        />
      ) : (
        <input
          type={type}
          min={min}
          max={max}
          {...inputProps}
        />
      )}

      { validate && isInvalid && <div className="invalid-feedback">{invalidMsg || "Input is Invalid!"}</div>}
      { validate && isValid && <div className="valid-feedback">{validMsg || "Input is Valid!"}</div>}
    </React.Fragment>
  );

  const ensureArray = possibleArray => (Array.isArray(possibleArray) ? possibleArray : [possibleArray]);

  const childArray = ensureArray(children);
  const prependChildren = childArray.filter(child => child && child.type === InputPrepend);
  const appendChildren = childArray.filter(child => child && child.type === InputAppend);

  if (prependChildren.length || appendChildren.length) {
    const inputGroupClassName = classNames('input-group flex-nowrap', wrapperClassName, {
      'input-group-sm': small,
      'input-group-lg': large
    });

    const renderSubChildren = (subChildren) => {
      const subChildArray = subChildren.map(child => ensureArray(child.props.children)).reduce((a, b) => a.concat(b)).filter(child => child);
      return subChildArray.map((subChild, i) => {
        if (subChild.type === SVGIcon) return <span key={i} className="input-group-text"><SVGIcon {...subChild.props} /></span>;
        if (subChild.type === Button) return <Button key={i} {...subChild.props} />;
        return <span key={i} className="input-group-text">{subChild}</span>;
      });
    };

    return (
      <div className={inputGroupClassName}>
        {prependChildren.length > 0 && <div className="input-group-prepend">{renderSubChildren(prependChildren)}</div>}
        {input}
        {appendChildren.length > 0 && <div className="input-group-append">{renderSubChildren(appendChildren)}</div>}
      </div>
    );
  }

  return input;
}


Input.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'textarea', 'email', 'tel', 'number', 'password', 'date']),
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  isInvalid: PropTypes.bool,
  validate: PropTypes.bool,
  isValid: PropTypes.bool,
  validMsg: PropTypes.string,
  invalidMsg: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  styleVariant: PropTypes.oneOf([
    '',
    'highlight'
  ]),
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  className: '',
  wrapperClassName: '',
  disabled: false,
  required: false,
  autoComplete: false,
  validate: false,
  styleVariant: ''
};
