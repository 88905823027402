import Factory from 'factories/_Factory';


export default class Assignment extends Factory {
  static jsonType = 'assignment';

  static afterUpdate(record, json) {
    let newRecord = record;
    if (json.attributes.start_time || newRecord.get('start_time') === undefined) {
      newRecord = newRecord.set('start_time', new Date(json.attributes.start_time).getTime());
    }
    if (json.attributes.end_time || newRecord.get('end_time') === undefined) {
      newRecord = newRecord.set('end_time', new Date(json.attributes.end_time).getTime());
    }
    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      timed: false,
      no_pause: false,
      seconds_per_question: 0,
      is_adaptive: false,
      initial_message: '',
      deleted: false,
      assigned_to_current_user: true,
      board_exam_interface: '',
      quiz_type: null,
      randomize_questions: false,
      randomize_answers: false,
      updated_at: 0,
      end_time: null,
      start_time: null,
      class_names: null,
      total_assignees: 0,
      total_quizzes: 0,
      completed_quizzes: 0,
      accommodations: {},
      started: false,
      hide_review: null,
      hide_results: null,
      is_lti: false,
      user_ids_incomplete: []
    };
  }

  static get belongsTo() {
    return ['organization', 'question_bank', 'creator', 'exam_template'];
  }

  static get hasMany() {
    return ['member_group', 'user', 'delegate'];
  }
}
