import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';

export default function HomeAnnouncements({ announcements }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [arrows, setArrows] = useState({ prev: false, next: announcements?.length > 1 });
  const iconProps = { width: 20.9, height: 40.9, className: "text-white" };
  const timerRef = useRef();

  function setArrowsVisible(index) {
    setArrows(() => {
      if (index === announcements?.length - 1) return { prev: true, next: false };
      if (index === 0) return { prev: false, next: true };
      return { prev: true, next: true };
    });
  }

  function changeAnnouncements(next) {
    setCurrentIndex((index) => {
      let newIndex = next ? index + 1 : index - 1;
      if (newIndex === announcements?.length) newIndex = 0;
      setArrowsVisible(newIndex);
      return newIndex;
    });
    if (!next) {
      // eslint-disable-next-line no-use-before-define
      startInterval(true);
    }
  }

  function startInterval() {
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      changeAnnouncements(true);
    }, 5000);

    return () => {
      if (timerRef.current) clearInterval(timerRef);
    };
  }

  useEffect(() => {
    if (announcements?.length > 1) startInterval();
  }, []);

  const arrowButton = (icon, next) => (
    <Button
      styleVariant="none"
      icon={icon}
      iconProps={iconProps}
      onClick={() => changeAnnouncements(next)}
    />
  );

  return announcements?.length ? (
    <div className="bg-navy">
      <div className="container-xl px-0 bg-navy">
        <div className="bg-navy text-white text-center d-flex px-5 py-3 AnnouncementContainer">
          { arrows.prev ? arrowButton('LeftCaret3', false) : <span /> }
          { announcements[currentIndex].link
            ? (
              <a
                target={announcements[currentIndex].new_tab ? "_blank" : ''}
                className="text-white"
                href={announcements[currentIndex].link}
              >
                { announcements[currentIndex].title }
              </a>
            )
            : <span>{ announcements[currentIndex].title }</span> }
          { arrows.next ? arrowButton('RightCaret3', true) : <span /> }
        </div>
      </div>
    </div>

  ) : null;
}

HomeAnnouncements.propTypes = {
  announcements: PropTypes.array
};
