import React from "react";
import SvgContainer from './SvgContainer';

const PsiMark = props => (
  <SvgContainer title="Mark Icon" {...props}>
    <rect fill="#ec1d25" x="2.1" y="6.5" className="mark_svg__st0" width="28.1" height="19" />
    <g>
      <line className="mark_svg__st0" x1="9" y1="21.7" x2="9" y2="11" />
      <rect fill="#ffffff" x="7.4" y="11" className="mark_svg__st1" width="3.1" height="10.7" />
    </g>
    <g>
      <line className="mark_svg__st0" x1="16.3" y1="21.7" x2="16.3" y2="11" />
      <rect fill="#ffffff" x="14.7" y="11" className="mark_svg__st1" width="3.1" height="10.7" />
    </g>
    <g>
      <line className="mark_svg__st0" x1="22.8" y1="21.7" x2="22.8" y2="11" />
      <rect fill="#ffffff" x="21.3" y="11" className="mark_svg__st1" width="3.1" height="10.7" />
    </g>
    <g>
      <line className="mark_svg__st0" x1="9" y1="11" x2="22.8" y2="11" />
      <rect fill="#ffffff" x="9" y="10.3" className="mark_svg__st1" width="13.8" height="1.6" />
    </g>
  </SvgContainer>
);

export default PsiMark;
