import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRightCaret2(props) {
  return (
    <SvgContainer title="Right Caret Icon" {...props}>
      <path d="M21.484 16.094a.467.467 0 000-.188.455.455 0 00-.074-.178.471.471 0 00-.026-.048l-10-12a.5.5 0 00-.768.64L20.349 16l-9.733 11.68a.5.5 0 00.768.64l10-12a.471.471 0 00.026-.047.455.455 0 00.074-.178z" />
    </SvgContainer>
  );
}
