import { Set, Map } from 'immutable';

import api, { currentUserUrl } from 'helpers/api';
import { utcToDateStr, hasBeenADaySince } from 'helpers/dateTime';
import { loadingPromiseDefined } from 'helpers/loading';

import { performanceQuizzesFetch, performanceResponsesFetch } from 'actions/performance';


export const CME_ACTIVITIES_FETCHING = 'CME_ACTIVITIES_FETCHING';
export const CME_ACTIVITIES_FETCHED = 'CME_ACTIVITIES_FETCHED';
export const CME_ACTIVITIES_FETCH_ERROR = 'CME_ACTIVITIES_FETCH_ERROR';
const cmeActivitiesFetchKey = (questionBankId = 'all') => `cme/activities/${questionBankId}`;
export const cmeActivitiesFetch = questionBankId => (dispatch, getState) => {
  const { loading, session, users } = getState();
  const userId = session.get('current_user_id');
  const key = cmeActivitiesFetchKey(questionBankId);
  const tutorModeEnabled = users.getIn([userId, 'tutor_mode_enabled']);

  if (loading.has(key)) return loading.get(key);

  const questionBankParam = questionBankId ? `question_banks/${questionBankId}/` : '';
  const promise = api.get(`users/${userId}/${questionBankParam}cme/activities${tutorModeEnabled ? '?v2=true' : ''}`)
    .then((response) => {
      dispatch({
        type: CME_ACTIVITIES_FETCHED,
        payload: {
          key,
          ...response,
          userId
        }
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: CME_ACTIVITIES_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: CME_ACTIVITIES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
cmeActivitiesFetch.getKey = cmeActivitiesFetchKey;


export const CME_TRACKERS_AND_CERTIFICATES_FETCHING = 'CME_TRACKERS_AND_CERTIFICATES_FETCHING';
export const CME_TRACKERS_AND_CERTIFICATES_FETCHED = 'CME_TRACKERS_AND_CERTIFICATES_FETCHED';
export const CME_TRACKERS_AND_CERTIFICATES_FETCH_ERROR = 'CME_TRACKERS_AND_CERTIFICATES_FETCH_ERROR';
const cmeTrackersAndCertificatesFetchKey = (questionBankId = 'all') => `cme/trackers-and-certificates/${questionBankId}`;
export const cmeTrackersAndCertificatesFetch = questionBankId => (dispatch, getState) => {
  const { loading, loadedAt, session } = getState();
  const userId = session.get('current_user_id');
  const key = cmeTrackersAndCertificatesFetchKey(questionBankId);

  if (loading.has(key)) return loading.get(key);

  const startDate = utcToDateStr(loadedAt.get(key));

  const questionBankParam = questionBankId ? `question_banks/${questionBankId}/` : '';
  const promise = api.get(`users/${userId}/${questionBankParam}cme/trackers-and-certificates${startDate && `?start=${startDate}`}`)
    .then((response) => {
      dispatch({
        type: CME_TRACKERS_AND_CERTIFICATES_FETCHED,
        payload: {
          key,
          ...response,
          userId
        }
      });

      return response;
    })
    .catch((error) => {
      dispatch({
        type: CME_TRACKERS_AND_CERTIFICATES_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: CME_TRACKERS_AND_CERTIFICATES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
cmeTrackersAndCertificatesFetch.getKey = cmeTrackersAndCertificatesFetchKey;

export const CME_CALCULATING = 'CME_CALCULATING';
export const CME_CALCULATED = 'CME_CALCULATED';
const cmeCalculateKey = (questionBankId = 'all') => `cme/calculate/${questionBankId}`;
export const cmeCalculate = questionBankId => (dispatch, getState) => {
  let { loading, loadedAt, session } = getState();
  const userId = session.get('current_user_id', 0);
  const key = cmeCalculateKey(questionBankId);
  if (loading.has(key)) return loading.get(key);


  // Get load keys for pre-requisite actions
  const quizzesKey = performanceQuizzesFetch.getKey(userId);
  const responsesKey = performanceResponsesFetch.getKey(userId);
  const activitiesKey = cmeActivitiesFetch.getKey(questionBankId);
  const userDataKey = cmeTrackersAndCertificatesFetch.getKey(questionBankId);


  // Check that pre-requisite actions are loaded and call them if they haven't been
  if (!loadedAt.has(quizzesKey) || !loadingPromiseDefined(quizzesKey)) dispatch(performanceQuizzesFetch());
  if (!loadedAt.has(responsesKey) || !loadingPromiseDefined(responsesKey)) dispatch(performanceResponsesFetch());
  if (!loadedAt.has(activitiesKey) || hasBeenADaySince(loadedAt.get(activitiesKey))) dispatch(cmeActivitiesFetch(questionBankId));
  if (!loadedAt.has(userDataKey) || !loadingPromiseDefined(userDataKey)) dispatch(cmeTrackersAndCertificatesFetch(questionBankId));

  // We need to make sure that CALCULATING is dispatched before CALCULATED, but we don't always have pre-requisite
  // Promises so we have to manually ensure that the main Promise's function waits for CALCULATING to be dispatched.
  // This is accomplished with a controlPromise which we manually resolve after dispatching the CALCULATING action.
  let controlPromiseResolve;
  const prerequisitePromises = [new Promise((resolve) => {
    controlPromiseResolve = resolve;
  })];

  // Check if pre-requisite actions are loading so that we can wait for them to finish
  ({ loading } = getState());
  if (loading.has(quizzesKey)) prerequisitePromises.push(loading.get(quizzesKey));
  if (loading.has(responsesKey)) prerequisitePromises.push(loading.get(responsesKey));
  if (loading.has(activitiesKey)) prerequisitePromises.push(loading.get(activitiesKey));
  if (loading.has(userDataKey)) prerequisitePromises.push(loading.get(userDataKey));


  const promise = new Promise(async (resolve) => {
    // Wait for any in-progress pre-requisite actions to finish loading
    await Promise.all(prerequisitePromises);


    // Synchronously get all Quizzes and Subjects from Redux Store (should already be loaded)
    const {
      performanceResponses,
      quizzes,
      cmeActivities,
      cmeQuestionBanks,
      cmeCertificates
    } = getState();


    // Set up Stat objects which we will extract data into
    const cmeActivityStats = {};
    cmeActivities.keySeq().forEach((cmeActivityId) => {
      cmeActivityStats[cmeActivityId] = {
        answeredCount: 0,
        correctCount: 0,
        incorrectCount: 0,
        creditsEarned: 0,
        creditsClaimed: 0,
        questionsForCredit: [],
        questionStats: {}
      };
    });


    // Extract data from CmeCertificates
    let redeemedQuestionIds = {};
    cmeCertificates.forEach((cmeCertificate) => {
      // Extract redeemed Question IDs to global array
      const cmeActivityId = cmeCertificate.get('cme_activity_id');
      if (!redeemedQuestionIds[cmeActivityId]) redeemedQuestionIds[cmeActivityId] = new Set();
      redeemedQuestionIds[cmeActivityId] = redeemedQuestionIds[cmeActivityId].concat(cmeCertificate.get('questions_logged_for_credit', []));

      // Extract claimed credit hours to CmeActivityStats
      const cmeActivityStat = cmeActivityStats[cmeCertificate.get('cme_activity_id')];
      if (cmeActivityStat) cmeActivityStat.creditsClaimed += cmeCertificate.get('hours_used');
    });

    // TODO: Use idObjectToMap() Immutable Helper here and remove ID => String typecasting below (ln 219)
    redeemedQuestionIds = new Map(redeemedQuestionIds);


    // Extract data from PerformanceResponses into CmeActivityStats
    performanceResponses.forEach((responsesByQuestionId, quizId) => {
      const quiz = quizzes.get(parseInt(quizId));
      // Must be from a completed Quiz, as we want to display cme for archived quizzes
      if (!quiz || quiz.get('status') !== 'complete') return;

      // Responses count towards any CmeActivity which shares a QuestionBank with this Quiz
      const cmeActivityIds = cmeQuestionBanks.filter(cqb => cqb.get('question_bank_id') === quiz.get('question_bank_id'))
        .map(cqb => cqb.get('cme_activity_id'))
        .toSet();

      cmeActivityIds.forEach((cmeActivityId) => {
        const cmeActivityStat = cmeActivityStats[cmeActivityId];
        if (!cmeActivityStat) return;

        responsesByQuestionId.forEach((response, questionIdStr) => {
          const questionId = parseInt(questionIdStr);

          // Question cannot already be redeemed in this CME Activity
          if (redeemedQuestionIds.get(`${cmeActivityId}`, new Set()).has(questionId)) return;

          // PerformanceResponse Format =
          //
          // [
          //   ms_spent,
          //   extra_ms_spent,
          //   answered_at,
          //   correct,
          //   answered
          // ]
          //

          // Response must be answered
          if (!response.get(4)) return;

          // Create QuestionStat in CmeActivityStat if it does not exist yet
          if (!cmeActivityStat.questionStats[questionId]) cmeActivityStat.questionStats[questionId] = {};
          const questionStat = cmeActivityStat.questionStats[questionId];

          // Extract stats from latest Response into QuestionStat
          if (!questionStat.latestGradedAt || questionStat.latestGradedAt < response.get(2)) {
            questionStat.latestGradedAt = response.get(2);
            questionStat.latestGradedCorrect = response.get(3);
          }
        });
      });
    });


    // Calculate final values for CmeActivityStats
    cmeActivities.forEach((cmeActivity) => {
      const cmeActivityStat = cmeActivityStats[cmeActivity.get('id')];
      const filteredCmeQuestionBanks = cmeQuestionBanks.filter(cqb => cqb.get('cme_activity_id') === cmeActivity.get('id'));
      cmeActivityStat.questionsPerHour = filteredCmeQuestionBanks.map(cqb => cqb.get('questions_per_hour')).min();

      // Summarize data from QuestionStats
      Object.keys(cmeActivityStat.questionStats).forEach((questionId) => {
        const questionStat = cmeActivityStat.questionStats[questionId];

        cmeActivityStat.answeredCount += 1;
        cmeActivityStat.questionsForCredit.push({ questionId, answeredAt: questionStat.latestGradedAt });

        if (questionStat.latestGradedCorrect === true) {
          cmeActivityStat.correctCount += 1;
        } else if (questionStat.latestGradedCorrect === false) {
          cmeActivityStat.incorrectCount += 1;
        }
      });

      cmeActivityStat.questionsForCredit.sort((a, b) => {
        if (a.answeredAt === b.answeredAt) return 0;
        return a.answeredAt > b.answeredAt ? 1 : -1;
      });

      // Extract earliest and latest available CME dates from questionsForCredit
      const { questionsForCredit } = cmeActivityStat;
      if (questionsForCredit && questionsForCredit.length > 0) {
        cmeActivityStat.earliestAvailableCme = new Date(questionsForCredit[0].answeredAt).toISOString().slice(0, 10);
        cmeActivityStat.latestAvailableCme = new Date(questionsForCredit[questionsForCredit.length - 1].answeredAt).toISOString().slice(0, 10);
      }

      // Determine Credits Earned and cap it at Credits Remaining
      cmeActivityStat.creditsEarned = cmeActivityStat.questionsPerHour > 0 ? Math.floor(cmeActivityStat.answeredCount / cmeActivityStat.questionsPerHour) : 0;
      const creditsRemaining = cmeActivity.get('cme_hours') - cmeActivityStat.creditsClaimed;
      if (cmeActivityStat.creditsEarned > creditsRemaining) cmeActivityStat.creditsEarned = creditsRemaining;

      cmeActivityStat.questionsTotalToNextCredit = cmeActivityStat.questionsPerHour > 0 ? cmeActivityStat.answeredCount % cmeActivityStat.questionsPerHour : 0;
      if (cmeActivityStat.creditsEarned + cmeActivityStat.creditsClaimed >= cmeActivity.get('cme_hours')) cmeActivityStat.questionsTotalToNextCredit = 0;
      cmeActivityStat.percentCorrect = cmeActivityStat.answeredCount > 0 ? Math.round(cmeActivityStat.correctCount / cmeActivityStat.answeredCount * 100) : 0;
    });


    // Done, dispatch results
    dispatch({
      type: CME_CALCULATED,
      payload: {
        key,
        cmeActivityStats
      }
    });

    resolve();
  });


  dispatch({
    type: CME_CALCULATING,
    payload: { key, promise }
  });

  // Make sure that CALCULATING dispatches before CALCULATED
  controlPromiseResolve();

  return promise;
};
cmeCalculate.getKey = cmeCalculateKey;


// Helper functions for validating and formatting request for CME Certificate Generation

const addressFormatter = (address1, address2, city, usState, zip, country) => [address1, address2, city, usState, zip, country].filter(v => v).join(', ');

const requestFormatter = ({
  abimNumber, start, selectedCredits, content, quality, usefullness, overallEvaluation, name, end, degree, freeOfBias, effectiveness, practiceChanges, learningObjectives, dateOfBirth, city,
  usState, address1, address2, country, zip, cmeCreditTrackerId, questionsLoggedForCredit, dayOfBirth, monthOfBirth, stateOfMedicalLicense
}) => {
  // "YYYY-MM-DD"
  const dob = dateOfBirth.split('-');
  const neededState = country === "United States" ? usState : '';
  return ({
    selected_hours_count: selectedCredits,
    cme_credit_tracker_id: cmeCreditTrackerId,
    questions_logged_for_credit: [...questionsLoggedForCredit],
    lower_bound: start,
    upper_bound: end,
    evaluation_answers: {
      name,
      degree,
      "ABIM Number": abimNumber,
      free_of_bias: { ...freeOfBias },
      practice_changes: { ...practiceChanges },
      effectiveness: [...effectiveness],
      learning_objectives: [...learningObjectives],
      "Birth Day": dayOfBirth || dob[2],
      "Birth Month": monthOfBirth || dob[1],
      ...(!dayOfBirth && {
        "Birth Year": dob[0],
        "Date of Birth": `${dob[1]}/${dob[2]}/${dob[0]}`
      }),
      ...(!stateOfMedicalLicense ? {
        location: {
          formatted_address: addressFormatter(address1, address2, city, neededState, zip, country),
          address_1: address1,
          address_2: address2,
          city,
          state: neededState,
          country,
          zip
        }
      } : { "State Of Medical License": stateOfMedicalLicense }),
      "Overall Evaluation": overallEvaluation,
      Content: content,
      Quality: quality,
      Usefulness: usefullness,
    }
  });
};


export const CME_CERTIFICATE_GENERATING = 'CME_CERTIFICATE_GENERATING';
export const CME_CERTIFICATE_GENERATED = 'CME_CERTIFICATE_GENERATED';
export const CME_CERTIFICATE_GENERATION_ERROR = 'CME_CERTIFICATE_GENERATION_ERROR';
const cmeCertificateGenerateKey = () => 'cme/certificate/generate';
export const cmeGenerateCertificate = formState => (dispatch, getState) => {
  const { loading, session } = getState();
  const key = cmeCertificateGenerateKey();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${currentUserUrl({ session })}/generate_certificate`, { certificate: requestFormatter(formState) })
    .then((response) => {
      dispatch({
        type: CME_CERTIFICATE_GENERATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CME_CERTIFICATE_GENERATION_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: CME_CERTIFICATE_GENERATING,
    payload: { key, promise }
  });

  return promise;
};
