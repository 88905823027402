import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgFilledRatingStar(props) {
  return (
    <SvgContainer title="Filled Star Icon" {...props}>
      <path d="M11.5 1.49135L13.6108 7.42296L13.7291 7.75532H14.0819H20.7789L15.4012 11.3227L15.0755 11.5388L15.2066 11.907L17.2853 17.7484L11.7764 14.094L11.5 13.9106L11.2236 14.094L5.71469 17.7484L7.79344 11.907L7.92448 11.5388L7.59878 11.3227L2.22108 7.75532H8.91809H9.27087L9.38915 7.42296L11.5 1.49135Z" fill="#006AD1" stroke="#006AD1" />
    </SvgContainer>
  );
}
