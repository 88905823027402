import React from "react";
import SvgContainer from "./SvgContainer";

export default function Print (props) {
  return (
    <SvgContainer height={99} width={99} viewBoxHeight={99} viewBoxWidth={99} name="print" {...props}>
      <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="49.133" cy="49.133" r="49.133" fill="#FFF2DB" />
        <path d="M35.7424 22.7979C35.1646 22.8509 34.6694 23.3933 34.6753 23.977V39.3065H31.5976C27.1638 39.3065 23.585 42.962 23.585 47.4489V62.3008C23.585 62.9199 24.1451 63.48 24.7642 63.48H34.7873V73.5031C34.7873 74.1222 35.3474 74.6823 35.9665 74.6823H63.0879C63.707 74.6823 64.2671 74.1222 64.2671 73.5031V63.48H74.2902C74.9093 63.48 75.4694 62.9199 75.4694 62.3008V47.4489C75.4694 42.962 71.8847 39.3065 67.4568 39.3065H64.3791V23.977C64.3791 23.358 63.8131 22.7979 63.1999 22.7979C54.0494 22.7979 44.8871 22.7979 35.7424 22.7979ZM37.0336 25.1562H62.0207V39.3065H37.0336V25.1562ZM31.5976 41.6649H67.4509C70.5876 41.6649 73.1051 44.2002 73.1051 47.4489V61.1216H64.2612V53.4568C64.2612 52.8378 63.7011 52.2777 63.082 52.2777H35.8545C35.2766 52.3307 34.7814 52.8731 34.7873 53.4568V61.1216H25.9433V47.4489C25.9433 44.2002 28.4609 41.6649 31.5976 41.6649ZM32.3169 45.7921C31.6978 45.8216 31.1613 46.4112 31.1907 47.0244C31.2202 47.6434 31.8098 48.18 32.423 48.1505H33.6022C34.2272 48.1564 34.7991 47.5963 34.7991 46.9713C34.7991 46.3463 34.2272 45.7862 33.6022 45.7921C33.1777 45.7921 32.7296 45.7921 32.311 45.7921H32.3169ZM38.8024 45.7921C38.1834 45.8216 37.6468 46.4112 37.6763 47.0244C37.7058 47.6434 38.2954 48.18 38.9086 48.1505H40.0878C40.7127 48.1564 41.2846 47.5963 41.2846 46.9713C41.2846 46.3463 40.7127 45.7862 40.0878 45.7921C39.6632 45.7921 39.2152 45.7921 38.7965 45.7921H38.8024ZM37.1457 54.636H61.9087V72.3239H37.1457V54.636ZM40.5712 59.3528C39.9521 59.3823 39.4156 59.9719 39.4451 60.5851C39.4746 61.2041 40.0642 61.7407 40.6773 61.7112H58.3652C58.9902 61.7171 59.5621 61.157 59.5621 60.532C59.5621 59.907 58.9902 59.3469 58.3652 59.3528H40.5712ZM40.5712 66.428C39.9521 66.4574 39.4156 67.047 39.4451 67.6602C39.4746 68.2793 40.0642 68.8158 40.6773 68.7863H58.3652C58.9902 68.7922 59.5621 68.2321 59.5621 67.6072C59.5621 66.9822 58.9902 66.4221 58.3652 66.428H40.5712Z" fill="#1B3950" />
      </svg>
    </SvgContainer>
  );
}
