import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SVGIcon from "components/SVGIcon";
import LoadingIcon from "components/LoadingIcon";
import italicize from "helpers/italicize";
import CmeCoachSendEmail from "./CmeCoachSendEmail";

export default function CmeCoachDetails({
  selectedSpecialtyName,
  selectedStateName,
  displayCoachContainer,
}) {
  const [seeMoreProducts, setSeeMoreProducts] = useState(false);
  const [seeMoreProductsMobile, setSeeMoreProductsMobile] = useState(false);

  const productPages = useSelector((state) => {
    const cmeCoachPdpIds = state.cmeCoach.get("productPageIds");
    return state.productPages
      .toList()
      .filter(pdp => cmeCoachPdpIds.includes(pdp.get("id")));
  });
  const cmeCoach = useSelector(state => state.cmeCoach);
  const organizations = cmeCoach.get("organizations");

  const renderRequirements = (mocYear, requirements, index) => (
    <div key={index} className={`license-req year-${mocYear}`}>
      <div className={`req-${mocYear}`}>
        {requirements.map((requirement, index2) => (
          <div key={index2} className="d-flex align-items-start mt-4">
            <SVGIcon
              name="CircleCheck"
              width={16}
              height={16}
              className="circle-check"
            />
            <span className="storefront-body-text">
              {italicize(requirement.get("description"))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const renderLicense = (license, organization, index) => {
    const noYearRequirements = license.get("requirements_by_moc_year").get("");
    const yearRequirements = license.get("requirements_by_moc_year").delete("");
    const hasRequirements = license.get("requirements_by_moc_year").size > 0;

    return (
      <div key={index} className="CmeCoachLicense">
        <h3 className="cme-coach-title">
          <a
            href={organization.get("website")}
            className="cme-coach-title"
            rel="noopener noreferrer"
            target="_blank"
          >
            {organization.get("name")}
          </a>
        </h3>

        <div className="license-glance mb-5">
          <h3 className="storefront-body-text bold">At a Glance</h3>

          {!hasRequirements && (
            <p className="storefront-body-text">
              No requirements for {organization.get("name")}
            </p>
          )}

          {noYearRequirements && renderRequirements("", noYearRequirements, -1)}

          {yearRequirements
            .entrySeq()
            .map((e, index2) => renderRequirements(e[0], e[1], index2))}
        </div>
        <div className="license-prop-description storefront-body-text mb-5">
          {italicize(license.get("description"))}
        </div>
        <a
          href={license.get("source")}
          className="storefront-link mb-5"
          rel="noopener noreferrer"
          target="_blank"
        >
          Source
        </a>
      </div>
    );
  };

  return (
    displayCoachContainer && (
      <>
        <div className="product-details-wrapper d-flex mt-5">
          <div className="col-4 recommended-container">
            <div className="">
              <h3 className="cme-coach-title">Recommended CME</h3>
            </div>
            <div
              className={`recommended ${
                productPages.size > 6 && !seeMoreProducts
                  ? "extra-product-pages"
                  : ""
              } ${seeMoreProducts ? "recommended-y-overflow" : ""}`}
            >
              {productPages.size > 0 ? (
                productPages.map((pdp, i) => (
                  <a href={pdp.get("path")} key={i}>
                    <div
                      className={`product-box ${
                        i > 5 && !seeMoreProducts ? "d-none" : ""
                      }`}
                    >
                      <div className="product-name storefront-body-text">
                        {pdp.get("name")}
                      </div>
                      {pdp.get("cme_details").map((detail, iterator) => (
                        <div
                          key={iterator}
                          className="product-detail storefront-body-text"
                        >
                          {detail.includes("AMA PRA")
                            ? italicize(detail)
                            : detail}
                        </div>
                      ))}
                    </div>
                  </a>
                ))
              ) : (
                <LoadingIcon />
              )}
              <div
                className={`see-more ${
                  productPages.size < 7 || seeMoreProducts ? "d-none" : ""
                }`}
              >
                <span
                  role="button"
                  tabIndex="0"
                  onClick={() => setSeeMoreProducts(true)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Space") {
                      setSeeMoreProducts(true);
                    }
                  }}
                >
                  See More
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 requirements-container">
            <div className="">
              <h3 className="cme-coach-title">{`${selectedSpecialtyName} & ${selectedStateName} Requirements`}</h3>
              <div
                className={`requirements ${
                  productPages.size > 6 && !seeMoreProducts
                    ? "extra-product-pages"
                    : ""
                }`}
              >
                <div className="CmeCoachRequirements">
                  <div className="list">
                    {organizations.size > 0 ? (
                      organizations.map((organization, index) =>
                        organization
                          .get("licenses")
                          .map((license, index2) => renderLicense(license, organization, index * index2)))
                    ) : (<LoadingIcon />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="disclaimer mt-5">
          <p className="storefront-disclaimer-text">
            Each state and medical specialty board has their own set of CME
            requirements to maintain your license and board certification.
          </p>
        </div>
        <div className="send-email">
          <CmeCoachSendEmail cmeCoach={cmeCoach} />
        </div>
        <div className="mobile-recommended-container">
          <div
            className={`mobile-recommended ${
              productPages.size > 4 && !seeMoreProductsMobile
                ? "mobile-extra-product-pages"
                : ""
            } ${seeMoreProductsMobile ? "mobile-recommended-y-overflow" : ""}`}
          >
            {productPages.size > 0 ? (
              productPages.map((pdp, i) => (
                <a href={pdp.get("path")} key={i}>
                  <div
                    className={`product-box ${
                      i > 3 && !seeMoreProductsMobile ? "d-none" : ""
                    }`}
                  >
                    <div className="product-name storefront-body-text">
                      {pdp.get("name")}
                    </div>
                    {pdp.get("cme_details").map((detail, iterator) => (
                      <div
                        key={iterator}
                        className="product-detail storefront-body-text"
                      >
                        {detail.includes("AMA PRA")
                          ? italicize(detail)
                          : detail}
                      </div>
                    ))}
                  </div>
                </a>
              ))
            ) : (
              <LoadingIcon />
            )}
            <div
              className={`see-more ${
                productPages.size < 5 || seeMoreProductsMobile ? "d-none" : ""
              }`}
            >
              <span
                role="button"
                tabIndex="0"
                onClick={() => setSeeMoreProductsMobile(true)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    setSeeMoreProductsMobile(true);
                  }
                }}
              >
                See More
              </span>
            </div>
          </div>
        </div>
      </>
    )
  );
}

CmeCoachDetails.propTypes = {
  selectedSpecialtyName: PropTypes.string,
  selectedStateName: PropTypes.string,
  displayCoachContainer: PropTypes.bool,
};
