import StateHelper from 'helpers/StateHelper';
import ClassReport from 'factories/ClassReport';

import { CLASS_REPORT_FETCHED } from 'actions/b2b/reports';

const stateHelper = new StateHelper(ClassReport);
export const { initialState } = stateHelper;
const reducers = {};

reducers[CLASS_REPORT_FETCHED] = function(state, payload) {
  return state.withMutations((newState) => {
    newState.set(`${payload.report_id}-${payload.class_id}`, new ClassReport({
      id: `${payload.report_id}-${payload.class_id}`,
      attributes: {
        class_id: payload.class_id,
        report_id: payload.report_id,
        status: payload.status,
        national_median_all: payload.national_median_all,
        national_median_test: payload.national_median_test,
        org_median_all: payload.org_median_all,
        org_median_test: payload.org_median_test,
        peer_results_all: payload.peer_results_all,
        peer_results_test: payload.peer_results_test,
        report_data: payload.report_data
      },

      relationships: {
        organization: {
          data: {
            id: payload.organization_id
          }
        }
      }
    }));
  });
};

export default stateHelper.createReducer(reducers);
