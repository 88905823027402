import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLeftArrow(props) {
  return (
    <SvgContainer title="Left Arrow" {...props}>
      <path d="M26 15.5H7.114l7.26-8.168a.5.5 0 10-.748-.664l-8 9a.45.45 0 00-.036.062.505.505 0 00-.045.065l-.01.028a.478.478 0 00-.018.085.501.501 0 00-.014.078L5.5 16l.003.014a.501.501 0 00.014.078.478.478 0 00.019.085l.01.028a.505.505 0 00.044.065.45.45 0 00.036.062l8 9a.5.5 0 00.748-.664L7.114 16.5H26a.5.5 0 000-1z" />
    </SvgContainer>
  );
}
