import StateHelper from 'helpers/StateHelper';
import Organization from 'factories/Organization';

import {
  USER_SUBSCRIPTIONS_FETCHED
} from 'actions/users';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  ORGANIZATION_FETCHED,
  ORGANIZATIONS_FETCHED,
  ORGANIZATION_MEMBERS_PAGINATED
} from 'actions/b2b/organizations';
import {
  MEMBER_GROUPS_PAGINATED,
  ORGANIZATION_UNLOAD_MEMBER_GROUPS
} from 'actions/b2b/memberGroups';


const stateHelper = new StateHelper(Organization);
export const { initialState } = stateHelper;
const reducers = {};


reducers[USER_SUBSCRIPTIONS_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[ORGANIZATION_FETCHED] = stateHelper.set;
reducers[ORGANIZATIONS_FETCHED] = stateHelper.update;
reducers[ORGANIZATION_MEMBERS_PAGINATED] = (state, payload) => state.setIn([payload.organizationId, 'has_more_members_pagination'], payload.hasMoreMembersPagination);
reducers[MEMBER_GROUPS_PAGINATED] = (state, payload) => state.setIn([payload.organizationId, 'has_more_member_groups_pagination'], payload.hasMoreMemberGroupsPagination);
reducers[SESSION_RESET] = stateHelper.reset;
reducers[ORGANIZATION_UNLOAD_MEMBER_GROUPS] = (state, payload) => state.setIn([payload.organizationId, 'has_more_member_groups_pagination'], true);

export default stateHelper.createReducer(reducers);
