import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// StyleGuide = molecules/media/image
export default function Image({
  image,
  refProp,
  className,
  wrapperClassName,
  onClick,
  onLoad,
  useMap,
  size,
  scrollable,
  lazyLoad
}) {
  const sizePrefix = size === 'default' ? '' : `${size}_`;
  const wrapperClass = classNames('Image', wrapperClassName, { scrollable: !!scrollable });
  return (
    <div className={wrapperClass} style={{ width: image.get(`${sizePrefix}width`) }}>
      <div style={{ paddingTop: image.get(`${sizePrefix}height`) / image.get(`${sizePrefix}width`) * 100 + '%' }} />
      { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */ }
      <img
        ref={refProp || null}
        className={className}
        src={image.get(`${sizePrefix}url`)}
        alt={image.get('caption')}
        width={image.get(`${sizePrefix}width`)}
        onClick={onClick ? ev => onClick(ev) : null}
        onLoad={onLoad ? ev => onLoad(ev) : null}
        useMap={useMap}
        loading={lazyLoad ? 'lazy' : 'eager'}
      />
    </div>
  );
}


Image.propTypes = {
  image: PropTypes.object.isRequired,
  refProp: PropTypes.object,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  useMap: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large', 'original']),
  scrollable: PropTypes.bool,
  lazyLoad: PropTypes.bool
};

Image.defaultProps = {
  className: '',
  wrapperClassName: '',
  size: 'default',
  scrollable: false
};
