import api, { currentUserUrl } from 'helpers/api';


export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
function notificationsError(error, dispatch, key) {
  dispatch({
    type: NOTIFICATIONS_ERROR,
    payload: { error, key }
  });
  throw error;
}

export const NOTIFICATIONS_FETCHED = 'NOTIFICATIONS_FETCHED';
const getNotificationFetchKey = () => 'notifications/all';
export const notificationsFetch = userIdArg => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const key = getNotificationFetchKey();

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/notifications`)
    .then((response) => {
      dispatch({
        type: NOTIFICATIONS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => notificationsError(error, dispatch, key));

  dispatch({
    type: NOTIFICATIONS_LOADING,
    payload: { key, promise }
  });
  return promise;
};
notificationsFetch.getKey = getNotificationFetchKey;

export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN';
export const notificationsSeen = notificationIds => (dispatch, getState) => {
  const key = 'seen';

  // No need to check if this is loading already

  return api.put(`${currentUserUrl(getState())}/notifications/seen`,
    {
      notifications: { ids: notificationIds }
    })
    .then((response) => {
      dispatch({
        type: NOTIFICATIONS_SEEN,
        payload: { key, ...response }
      });
      return response;
    });
};
