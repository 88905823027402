import { Map } from 'immutable';

export function quizPerformanceUseAsPerformanceQuestion(quiz, questionId) {
  if (quiz.get('status') === 'incomplete') return;

  let question = null;
  if (quiz.get('question_performance')) {
    const missingQuestion = quiz.get('question_performance')?.find(q => q.question_id === questionId);
    if (missingQuestion) {
      const missingSubjectIds = missingQuestion.subjects.map(sub => sub.id);
      question = Map({
        subjectIds: missingSubjectIds,
        potentialScore: missingQuestion.potential_score
      });
    }
  }
  return question;
}
