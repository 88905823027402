import React from 'react';
import PropTypes from 'prop-types';

import indexToLetter from 'helpers/indexToLetter';

import Image from 'components/media/Image';
import WYSIWYG from 'components/WYSIWYG';


const SampleQuestionBody = ({
  questionData,
  setSelectedAnswer,
  selectedAnswer,
  correctAnswerId,
  setDisabled,
  setReadyToEvaluate,
  readyToEvaluate,
  isStrikingOut,
  highlighterRef,
}) => {
  const { answers, plain_name: questionName } = questionData;

  const handleSelectedAnswer = (e) => {
    if (readyToEvaluate) setReadyToEvaluate(false);
    if (e.target.checked) setDisabled(false);

    setSelectedAnswer(e.target.value);
  };

  const onStrikeoutAnswer = (e) => {
    const answerClasses = document.getElementById(e.target.id).classList;
    return answerClasses.contains('strikeout') ? answerClasses.remove('strikeout') : answerClasses.add('strikeout');
  };

  const handleClickCapture = (e) => {
    if (isStrikingOut) {
      e.preventDefault();
      onStrikeoutAnswer(e);
    } else {
      handleSelectedAnswer(e);
    }
  };

  return (
    <div className="question-body">
      <div
        id="question-name"
        ref={highlighterRef}
        className="question-name mb-3"
      >
        { questionName }
      </div>
      <ul className="answers">
        {answers.map((answer, index) => (
          <li key={answer.id} data-answer-id={answer.id}>
            <label
              className={`d-flex answer ${isStrikingOut && "is-striking-out"}`}
              htmlFor={`answer-${answer.attributes.choice}`}
              onClickCapture={e => handleClickCapture(e)}
              ref={highlighterRef}
            >
              <div className="indicator">
                {readyToEvaluate && answer.id === selectedAnswer && (
                  <div className={answer.id === correctAnswerId ? "success" : "danger"}>
                    {answer.id === correctAnswerId ? "✔" : "X"}
                  </div>
                )}
              </div>
              <input
                id={`answer-${answer.attributes.choice}`}
                key={index}
                className="response-input mr-2"
                name="question"
                type="radio"
                value={answer.id}
                readOnly
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor={`answer-${answer.attributes.choice}`} className="mr-2" />
              <span className="answer-choice ml-1 d-flex mt-1 w-100" onClickCapture={() => {}}>
                <span className="answer-index mr-2">{ indexToLetter(index) }. </span>
                <WYSIWYG id={`question-answer-${answer.id}`} className="answer-name">{ answer.attributes.plain_name }</WYSIWYG>
                { answer.images?.length > 0 && (
                  <div className="w-25 ml-4 rounded-0 bg-gray-100 p-3">
                    {answer.images.map(image => (
                      <Image lazyLoad className="graphic-choice-answer-image float-right" image={image} key={image.id} />
                    ))}
                  </div>
                )}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

SampleQuestionBody.propTypes = {
  questionData: PropTypes.object.isRequired,
  setSelectedAnswer: PropTypes.func,
  selectedAnswer: PropTypes.string,
  correctAnswerId: PropTypes.string,
  setDisabled: PropTypes.func,
  setReadyToEvaluate: PropTypes.func,
  readyToEvaluate: PropTypes.bool,
  isStrikingOut: PropTypes.bool,
  highlighterRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default SampleQuestionBody;
