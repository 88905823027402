import React from "react";
import PropTypes from 'prop-types';
import SvgContainer from "./SvgContainer";

function CatMode (props) {
  const { small, medium } = props;

  if (small) {
    return (
      <SvgContainer name="cat mode" height={94} width={94} viewBoxWidth={94} viewBoxHeight={94}>
        <circle cx="46.9" cy="46.9" r="46.9" fill="#FFF2DB" />
        <g clipPath="url(#clip0_799_2313)">
          <path fillRule="evenodd" clipRule="evenodd" d="M47.2752 58.3624C46.6421 58.3624 46.22 58.8783 46.22 59.3942C46.22 60.0133 46.7476 60.426 47.2752 60.426C47.9084 60.426 48.436 59.9101 48.436 59.3942C48.436 58.7751 47.9084 58.3624 47.2752 58.3624ZM47.2752 58.3624C46.6421 58.3624 46.22 58.8783 46.22 59.3942C46.22 60.0133 46.7476 60.426 47.2752 60.426C47.9084 60.426 48.436 59.9101 48.436 59.3942C48.436 58.7751 47.9084 58.3624 47.2752 58.3624ZM47.2752 58.3624C46.6421 58.3624 46.22 58.8783 46.22 59.3942C46.22 60.0133 46.7476 60.426 47.2752 60.426C47.9084 60.426 48.436 59.9101 48.436 59.3942C48.436 58.7751 47.9084 58.3624 47.2752 58.3624Z" fill="#1B3950" stroke="#1B3950" strokeWidth="0.25" strokeMiterlimit="10" />
          <path fillRule="evenodd" clipRule="evenodd" d="M49.0691 24.0035H45.4813C44.8481 24.0035 44.426 24.5194 44.426 25.1385C44.426 25.7576 44.9537 26.2735 45.4813 26.2735H49.0691C49.7023 26.2735 50.2299 25.7576 50.2299 25.1385C50.2299 24.4163 49.7023 24.0035 49.0691 24.0035ZM49.0691 24.0035H45.4813C44.8481 24.0035 44.426 24.5194 44.426 25.1385C44.426 25.7576 44.9537 26.2735 45.4813 26.2735H49.0691C49.7023 26.2735 50.2299 25.7576 50.2299 25.1385C50.2299 24.4163 49.7023 24.0035 49.0691 24.0035ZM49.0691 24.0035H45.4813C44.8481 24.0035 44.426 24.5194 44.426 25.1385C44.426 25.7576 44.9537 26.2735 45.4813 26.2735H49.0691C49.7023 26.2735 50.2299 25.7576 50.2299 25.1385C50.2299 24.4163 49.7023 24.0035 49.0691 24.0035Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
          <path fillRule="evenodd" clipRule="evenodd" d="M74.7117 20.7017C74.0786 20.0826 73.1288 19.6699 72.1791 19.6699H22.4768C21.5271 19.6699 20.5774 20.0826 19.9442 20.7017C19.3111 21.3208 18.889 22.2494 18.889 23.178V59.9101C18.889 60.8387 19.3111 61.7674 19.9442 62.3864C20.5774 63.0055 21.5271 63.4182 22.4768 63.4182H40.9437V68.7836H27.331C26.5923 68.7836 25.9592 69.0931 25.4315 69.5059C24.9039 70.0218 24.6929 70.6408 24.6929 71.3631V75.2839H69.9631V70.8472C69.9631 70.3313 69.752 69.8154 69.3299 69.4027C68.9078 68.99 68.3802 68.7836 67.8526 68.7836H53.0791V63.4182H72.1791C73.1288 63.4182 74.0786 63.0055 74.7117 62.3864C75.3449 61.7674 75.767 60.8387 75.767 59.9101V23.178C75.767 22.2494 75.3449 21.3208 74.7117 20.7017ZM67.8526 70.4345C67.9581 70.4345 68.0636 70.4345 68.1692 70.5377C68.2747 70.6408 68.2747 70.744 68.2747 70.8472V73.6331H26.2757V71.3631C26.2757 71.1567 26.3813 70.8472 26.5923 70.6408C26.8034 70.4345 27.0144 70.3313 27.331 70.3313H67.8526V70.4345ZM51.4962 68.8868H42.6321V63.5214H51.4962V68.8868ZM74.0786 59.9101C74.0786 60.426 73.8675 60.9419 73.5509 61.2515C73.2344 61.561 72.7067 61.7674 72.1791 61.7674H22.4768C21.9492 61.7674 21.4216 61.561 21.105 61.2515C20.7884 60.9419 20.5774 60.426 20.5774 59.9101V56.402H74.1841V59.9101H74.0786ZM74.0786 54.8543H20.4719V23.178C20.4719 22.6621 20.6829 22.2494 20.9995 21.8367C21.4216 21.5272 21.9492 21.3208 22.4768 21.3208H72.1791C72.7067 21.3208 73.1288 21.5272 73.5509 21.8367C73.8675 22.1462 74.0786 22.6621 74.0786 23.178V54.8543Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
          <g clipPath="url(#clip1_799_2313)">
            <path d="M66.7296 31.716H37.7313C37.286 31.716 36.9249 31.353 36.9249 30.9055C36.9249 30.458 37.286 30.0951 37.7313 30.0951H66.7269C67.1722 30.0951 67.5333 30.458 67.5333 30.9055C67.5333 31.353 67.1722 31.716 66.7269 31.716H66.7296Z" fill="#1B3950" />
            <path d="M66.7296 34.6329H37.7313C37.286 34.6329 36.9249 34.27 36.9249 33.8225C36.9249 33.375 37.286 33.0121 37.7313 33.0121H66.7269C67.1722 33.0121 67.5333 33.375 67.5333 33.8225C67.5333 34.27 67.1722 34.6329 66.7269 34.6329H66.7296Z" fill="#1B3950" />
            <path d="M32.2792 43.1875H27.9511C27.5058 43.1875 27.1447 42.8246 27.1447 42.3771V38.0275C27.1447 37.58 27.5058 37.217 27.9511 37.217H32.2792C32.7244 37.217 33.0856 37.58 33.0856 38.0275V42.3771C33.0856 42.8246 32.7244 43.1875 32.2792 43.1875ZM28.7575 41.5666H31.4755V38.8352H28.7575V41.5666Z" fill="#1B3950" />
            <path d="M66.7296 39.5557H37.7313C37.286 39.5557 36.9249 39.1928 36.9249 38.7452C36.9249 38.2977 37.286 37.9348 37.7313 37.9348H66.7269C67.1722 37.9348 67.5333 38.2977 67.5333 38.7452C67.5333 39.1928 67.1722 39.5557 66.7269 39.5557H66.7296Z" fill="#1B3950" />
            <path d="M66.7296 42.4727H37.7313C37.286 42.4727 36.9249 42.1098 36.9249 41.6622C36.9249 41.2147 37.286 40.8518 37.7313 40.8518H66.7269C67.1722 40.8518 67.5333 41.2147 67.5333 41.6622C67.5333 42.1098 67.1722 42.4727 66.7269 42.4727H66.7296Z" fill="#1B3950" />
            <path d="M32.2792 51.0272H27.9511C27.5058 51.0272 27.1447 50.6643 27.1447 50.2168V45.8672C27.1447 45.4197 27.5058 45.0568 27.9511 45.0568H32.2792C32.7244 45.0568 33.0856 45.4197 33.0856 45.8672V50.2168C33.0856 50.6643 32.7244 51.0272 32.2792 51.0272ZM28.7575 49.4064H31.4755V46.6749H28.7575V49.4064Z" fill="#1B3950" />
            <path d="M66.7296 47.3953H37.7313C37.286 47.3953 36.9249 47.0324 36.9249 46.5848C36.9249 46.1373 37.286 45.7744 37.7313 45.7744H66.7269C67.1722 45.7744 67.5333 46.1373 67.5333 46.5848C67.5333 47.0324 67.1722 47.3953 66.7269 47.3953H66.7296Z" fill="#1B3950" />
            <path d="M66.7296 50.3123H37.7313C37.286 50.3123 36.9249 49.9493 36.9249 49.5018C36.9249 49.0543 37.286 48.6914 37.7313 48.6914H66.7269C67.1722 48.6914 67.5333 49.0543 67.5333 49.5018C67.5333 49.9493 67.1722 50.3123 66.7269 50.3123H66.7296Z" fill="#1B3950" />
            <path d="M29.3468 34.7121C29.1404 34.7121 28.934 34.633 28.7766 34.4747L27.2506 32.9385C26.9357 32.6219 26.9357 32.1089 27.2506 31.7924C27.5656 31.4759 28.076 31.4759 28.391 31.7924L29.3495 32.7556L31.8393 30.2534C32.1543 29.9369 32.6647 29.9369 32.9797 30.2534C33.2947 30.5699 33.2947 31.0829 32.9797 31.3995L29.9197 34.4747C29.7622 34.633 29.5558 34.7121 29.3495 34.7121H29.3468Z" fill="#1B3950" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_799_2313">
            <rect width="67.536" height="66.0352" fill="white" transform="translate(13.5072 12.7568)" />
          </clipPath>
          <clipPath id="clip1_799_2313">
            <rect width="40.5216" height="21.0112" fill="white" transform="translate(27.0144 30.016)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }

  if (medium) {
    return (
      <SvgContainer name="cat mode" width={96} height={96} viewBoxHeight={96} viewBoxWidth={96} {...props}>
        <circle cx="47.665" cy="47.665" r="47.665" fill="#FFF2DB" />
        <g clipPath="url(#clip0_799_2313)">
          <path fillRule="evenodd" clipRule="evenodd" d="M48.0463 59.3143C47.4029 59.3143 46.9739 59.8386 46.9739 60.363C46.9739 60.9921 47.5101 61.4116 48.0463 61.4116C48.6898 61.4116 49.226 60.8873 49.226 60.363C49.226 59.7338 48.6898 59.3143 48.0463 59.3143ZM48.0463 59.3143C47.4029 59.3143 46.9739 59.8386 46.9739 60.363C46.9739 60.9921 47.5101 61.4116 48.0463 61.4116C48.6898 61.4116 49.226 60.8873 49.226 60.363C49.226 59.7338 48.6898 59.3143 48.0463 59.3143ZM48.0463 59.3143C47.4029 59.3143 46.9739 59.8386 46.9739 60.363C46.9739 60.9921 47.5101 61.4116 48.0463 61.4116C48.6898 61.4116 49.226 60.8873 49.226 60.363C49.226 59.7338 48.6898 59.3143 48.0463 59.3143Z" fill="#1B3950" stroke="#1B3950" strokeWidth="0.25" strokeMiterlimit="10" />
          <path fillRule="evenodd" clipRule="evenodd" d="M49.8695 24.395H46.2232C45.5797 24.395 45.1507 24.9193 45.1507 25.5485C45.1507 26.1777 45.6869 26.702 46.2232 26.702H49.8695C50.513 26.702 51.0492 26.1777 51.0492 25.5485C51.0492 24.8145 50.513 24.395 49.8695 24.395ZM49.8695 24.395H46.2232C45.5797 24.395 45.1507 24.9193 45.1507 25.5485C45.1507 26.1777 45.6869 26.702 46.2232 26.702H49.8695C50.513 26.702 51.0492 26.1777 51.0492 25.5485C51.0492 24.8145 50.513 24.395 49.8695 24.395ZM49.8695 24.395H46.2232C45.5797 24.395 45.1507 24.9193 45.1507 25.5485C45.1507 26.1777 45.6869 26.702 46.2232 26.702H49.8695C50.513 26.702 51.0492 26.1777 51.0492 25.5485C51.0492 24.8145 50.513 24.395 49.8695 24.395Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
          <path fillRule="evenodd" clipRule="evenodd" d="M75.9304 21.0394C75.2869 20.4102 74.3217 19.9907 73.3564 19.9907H22.8435C21.8782 19.9907 20.913 20.4102 20.2695 21.0394C19.6261 21.6685 19.1971 22.6123 19.1971 23.5561V60.8873C19.1971 61.8311 19.6261 62.7748 20.2695 63.404C20.913 64.0332 21.8782 64.4526 22.8435 64.4526H41.6115V69.9055H27.7768C27.0261 69.9055 26.3826 70.2201 25.8464 70.6396C25.3101 71.1639 25.0956 71.793 25.0956 72.5271V76.5119H71.1043V72.0028C71.1043 71.4785 70.8898 70.9541 70.4608 70.5347C70.0318 70.1152 69.4956 69.9055 68.9593 69.9055H53.9449V64.4526H73.3564C74.3217 64.4526 75.2869 64.0332 75.9304 63.404C76.5738 62.7748 77.0028 61.8311 77.0028 60.8873V23.5561C77.0028 22.6123 76.5738 21.6685 75.9304 21.0394ZM68.9593 71.5833C69.0666 71.5833 69.1738 71.5833 69.2811 71.6882C69.3883 71.793 69.3883 71.8979 69.3883 72.0028V74.8341H26.7043V72.5271C26.7043 72.3174 26.8116 72.0028 27.0261 71.793C27.2406 71.5833 27.455 71.4785 27.7768 71.4785H68.9593V71.5833ZM52.3362 70.0104H43.3275V64.5575H52.3362V70.0104ZM75.2869 60.8873C75.2869 61.4116 75.0724 61.9359 74.7506 62.2505C74.4289 62.5651 73.8927 62.7748 73.3564 62.7748H22.8435C22.3072 62.7748 21.771 62.5651 21.4493 62.2505C21.1275 61.9359 20.913 61.4116 20.913 60.8873V57.322H75.3941V60.8873H75.2869ZM75.2869 55.749H20.8058V23.5561C20.8058 23.0318 21.0203 22.6123 21.342 22.1928C21.771 21.8783 22.3072 21.6685 22.8435 21.6685H73.3564C73.8927 21.6685 74.3217 21.8783 74.7506 22.1928C75.0724 22.5074 75.2869 23.0318 75.2869 23.5561V55.749Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
          <g clipPath="url(#clip1_799_2313)">
            <path d="M67.8181 32.2334H38.3468C37.8942 32.2334 37.5272 31.8645 37.5272 31.4097C37.5272 30.9549 37.8942 30.5861 38.3468 30.5861H67.8153C68.2679 30.5861 68.6349 30.9549 68.6349 31.4097C68.6349 31.8645 68.2679 32.2334 67.8153 32.2334H67.8181Z" fill="#1B3950" />
            <path d="M67.8181 35.198H38.3468C37.8942 35.198 37.5272 34.8291 37.5272 34.3743C37.5272 33.9195 37.8942 33.5507 38.3468 33.5507H67.8153C68.2679 33.5507 68.6349 33.9195 68.6349 34.3743C68.6349 34.8291 68.2679 35.198 67.8153 35.198H67.8181Z" fill="#1B3950" />
            <path d="M32.8057 43.8921H28.4071C27.9545 43.8921 27.5875 43.5232 27.5875 43.0684V38.6479C27.5875 38.1931 27.9545 37.8242 28.4071 37.8242H32.8057C33.2583 37.8242 33.6253 38.1931 33.6253 38.6479V43.0684C33.6253 43.5232 33.2583 43.8921 32.8057 43.8921ZM29.2267 42.2448H31.9889V39.4687H29.2267V42.2448Z" fill="#1B3950" />
            <path d="M67.8181 40.2009H38.3468C37.8942 40.2009 37.5272 39.8321 37.5272 39.3772C37.5272 38.9224 37.8942 38.5536 38.3468 38.5536H67.8153C68.2679 38.5536 68.6349 38.9224 68.6349 39.3772C68.6349 39.8321 68.2679 40.2009 67.8153 40.2009H67.8181Z" fill="#1B3950" />
            <path d="M67.8181 43.1655H38.3468C37.8942 43.1655 37.5272 42.7967 37.5272 42.3418C37.5272 41.887 37.8942 41.5182 38.3468 41.5182H67.8153C68.2679 41.5182 68.6349 41.887 68.6349 42.3418C68.6349 42.7967 68.2679 43.1655 67.8153 43.1655H67.8181Z" fill="#1B3950" />
            <path d="M32.8057 51.8596H28.4071C27.9545 51.8596 27.5875 51.4907 27.5875 51.0359V46.6154C27.5875 46.1606 27.9545 45.7917 28.4071 45.7917H32.8057C33.2583 45.7917 33.6253 46.1606 33.6253 46.6154V51.0359C33.6253 51.4907 33.2583 51.8596 32.8057 51.8596ZM29.2267 50.2123H31.9889V47.4363H29.2267V50.2123Z" fill="#1B3950" />
            <path d="M67.8181 48.1683H38.3468C37.8942 48.1683 37.5272 47.7995 37.5272 47.3446C37.5272 46.8898 37.8942 46.521 38.3468 46.521H67.8153C68.2679 46.521 68.6349 46.8898 68.6349 47.3446C68.6349 47.7995 68.2679 48.1683 67.8153 48.1683H67.8181Z" fill="#1B3950" />
            <path d="M67.8181 51.1329H38.3468C37.8942 51.1329 37.5272 50.7641 37.5272 50.3092C37.5272 49.8544 37.8942 49.4856 38.3468 49.4856H67.8153C68.2679 49.4856 68.6349 49.8544 68.6349 50.3092C68.6349 50.7641 68.2679 51.1329 67.8153 51.1329H67.8181Z" fill="#1B3950" />
            <path d="M29.8255 35.2784C29.6158 35.2784 29.406 35.1979 29.246 35.0371L27.6952 33.4758C27.3751 33.1541 27.3751 32.6327 27.6952 32.311C28.0153 31.9893 28.534 31.9893 28.8541 32.311L29.8282 33.2899L32.3587 30.7469C32.6788 30.4252 33.1976 30.4252 33.5177 30.7469C33.8378 31.0686 33.8378 31.59 33.5177 31.9116L30.4077 35.0371C30.2477 35.1979 30.038 35.2784 29.8282 35.2784H29.8255Z" fill="#1B3950" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_799_2313">
            <rect width="68.6376" height="67.1123" fill="white" transform="translate(13.7275 12.9648)" />
          </clipPath>
          <clipPath id="clip1_799_2313">
            <rect width="41.1826" height="21.3539" fill="white" transform="translate(27.4551 30.5056)" />
          </clipPath>
        </defs>
      </SvgContainer>
    );
  }

  return (
    <SvgContainer name="cat mode" height={125} width={125} viewBoxHeight={125} viewBoxWidth={125} {...props}>
      <circle cx="62.5" cy="62.5" r="62.5" fill="#FFF2DB" />
      <g clipPath="url(#clip0_799_2313)">
        <path fillRule="evenodd" clipRule="evenodd" d="M63 77.775C62.1562 77.775 61.5938 78.4625 61.5938 79.15C61.5938 79.975 62.2969 80.525 63 80.525C63.8438 80.525 64.5469 79.8375 64.5469 79.15C64.5469 78.325 63.8438 77.775 63 77.775ZM63 77.775C62.1562 77.775 61.5938 78.4625 61.5938 79.15C61.5938 79.975 62.2969 80.525 63 80.525C63.8438 80.525 64.5469 79.8375 64.5469 79.15C64.5469 78.325 63.8438 77.775 63 77.775ZM63 77.775C62.1562 77.775 61.5938 78.4625 61.5938 79.15C61.5938 79.975 62.2969 80.525 63 80.525C63.8438 80.525 64.5469 79.8375 64.5469 79.15C64.5469 78.325 63.8438 77.775 63 77.775Z" fill="#1B3950" stroke="#1B3950" strokeWidth="0.25" strokeMiterlimit="10" />
        <path fillRule="evenodd" clipRule="evenodd" d="M65.3906 31.9875H60.6094C59.7656 31.9875 59.2031 32.675 59.2031 33.5C59.2031 34.325 59.9062 35.0126 60.6094 35.0126H65.3906C66.2344 35.0126 66.9375 34.325 66.9375 33.5C66.9375 32.5375 66.2344 31.9875 65.3906 31.9875ZM65.3906 31.9875H60.6094C59.7656 31.9875 59.2031 32.675 59.2031 33.5C59.2031 34.325 59.9062 35.0126 60.6094 35.0126H65.3906C66.2344 35.0126 66.9375 34.325 66.9375 33.5C66.9375 32.5375 66.2344 31.9875 65.3906 31.9875ZM65.3906 31.9875H60.6094C59.7656 31.9875 59.2031 32.675 59.2031 33.5C59.2031 34.325 59.9062 35.0126 60.6094 35.0126H65.3906C66.2344 35.0126 66.9375 34.325 66.9375 33.5C66.9375 32.5375 66.2344 31.9875 65.3906 31.9875Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
        <path fillRule="evenodd" clipRule="evenodd" d="M99.5625 27.5875C98.7188 26.7625 97.4531 26.2125 96.1875 26.2125H29.9531C28.6875 26.2125 27.4219 26.7625 26.5781 27.5875C25.7344 28.4125 25.1719 29.65 25.1719 30.8875V79.8375C25.1719 81.075 25.7344 82.3125 26.5781 83.1375C27.4219 83.9625 28.6875 84.5125 29.9531 84.5125H54.5625V91.6625H36.4219C35.4375 91.6625 34.5938 92.075 33.8906 92.625C33.1875 93.3125 32.9063 94.1375 32.9063 95.1V100.325H93.2344V94.4125C93.2344 93.725 92.9531 93.0375 92.3906 92.4875C91.8281 91.9375 91.125 91.6625 90.4219 91.6625H70.7344V84.5125H96.1875C97.4531 84.5125 98.7188 83.9625 99.5625 83.1375C100.406 82.3125 100.969 81.075 100.969 79.8375V30.8875C100.969 29.65 100.406 28.4125 99.5625 27.5875ZM90.4219 93.8625C90.5625 93.8625 90.7031 93.8625 90.8438 94C90.9844 94.1375 90.9844 94.275 90.9844 94.4125V98.125H35.0156V95.1C35.0156 94.825 35.1562 94.4125 35.4375 94.1375C35.7188 93.8625 36 93.725 36.4219 93.725H90.4219V93.8625ZM68.625 91.8H56.8125V84.65H68.625V91.8ZM98.7188 79.8375C98.7188 80.525 98.4375 81.2125 98.0156 81.625C97.5938 82.0375 96.8906 82.3125 96.1875 82.3125H29.9531C29.25 82.3125 28.5469 82.0375 28.125 81.625C27.7031 81.2125 27.4219 80.525 27.4219 79.8375V75.1625H98.8594V79.8375H98.7188ZM98.7188 73.1H27.2813V30.8875C27.2813 30.2 27.5625 29.65 27.9844 29.1C28.5469 28.6875 29.25 28.4125 29.9531 28.4125H96.1875C96.8906 28.4125 97.4531 28.6875 98.0156 29.1C98.4375 29.5125 98.7188 30.2 98.7188 30.8875V73.1Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
        <g clipPath="url(#clip1_799_2313)">
          <path d="M88.9253 42.2655H50.2816C49.6881 42.2655 49.2069 41.7818 49.2069 41.1855C49.2069 40.5891 49.6881 40.1055 50.2816 40.1055H88.9217C89.5151 40.1055 89.9964 40.5891 89.9964 41.1855C89.9964 41.7818 89.5151 42.2655 88.9217 42.2655H88.9253Z" fill="#1B3950" />
          <path d="M88.9253 46.1527H50.2816C49.6881 46.1527 49.2069 45.669 49.2069 45.0727C49.2069 44.4763 49.6881 43.9927 50.2816 43.9927H88.9217C89.5151 43.9927 89.9964 44.4763 89.9964 45.0727C89.9964 45.669 89.5151 46.1527 88.9217 46.1527H88.9253Z" fill="#1B3950" />
          <path d="M43.016 57.5527H37.2484C36.6549 57.5527 36.1737 57.069 36.1737 56.4727V50.6763C36.1737 50.08 36.6549 49.5963 37.2484 49.5963H43.016C43.6094 49.5963 44.0906 50.08 44.0906 50.6763V56.4727C44.0906 57.069 43.6094 57.5527 43.016 57.5527ZM38.323 55.3927H41.9449V51.7527H38.323V55.3927Z" fill="#1B3950" />
          <path d="M88.9253 52.7127H50.2816C49.6881 52.7127 49.2069 52.2291 49.2069 51.6327C49.2069 51.0364 49.6881 50.5527 50.2816 50.5527H88.9217C89.5151 50.5527 89.9964 51.0364 89.9964 51.6327C89.9964 52.2291 89.5151 52.7127 88.9217 52.7127H88.9253Z" fill="#1B3950" />
          <path d="M88.9253 56.5999H50.2816C49.6881 56.5999 49.2069 56.1163 49.2069 55.5199C49.2069 54.9236 49.6881 54.4399 50.2816 54.4399H88.9217C89.5151 54.4399 89.9964 54.9236 89.9964 55.5199C89.9964 56.1163 89.5151 56.5999 88.9217 56.5999H88.9253Z" fill="#1B3950" />
          <path d="M43.016 67.9999H37.2484C36.6549 67.9999 36.1737 67.5163 36.1737 66.9199V61.1236C36.1737 60.5272 36.6549 60.0436 37.2484 60.0436H43.016C43.6094 60.0436 44.0906 60.5272 44.0906 61.1236V66.9199C44.0906 67.5163 43.6094 67.9999 43.016 67.9999ZM38.323 65.8399H41.9449V62.1999H38.323V65.8399Z" fill="#1B3950" />
          <path d="M88.9253 63.16H50.2816C49.6881 63.16 49.2069 62.6764 49.2069 62.08C49.2069 61.4836 49.6881 61 50.2816 61H88.9217C89.5151 61 89.9964 61.4836 89.9964 62.08C89.9964 62.6764 89.5151 63.16 88.9217 63.16H88.9253Z" fill="#1B3950" />
          <path d="M88.9253 67.0473H50.2816C49.6881 67.0473 49.2069 66.5637 49.2069 65.9673C49.2069 65.371 49.6881 64.8873 50.2816 64.8873H88.9217C89.5151 64.8873 89.9964 65.371 89.9964 65.9673C89.9964 66.5637 89.5151 67.0473 88.9217 67.0473H88.9253Z" fill="#1B3950" />
          <path d="M39.1081 46.2582C38.8332 46.2582 38.5582 46.1527 38.3483 45.9418L36.3148 43.8945C35.8951 43.4727 35.8951 42.7891 36.3148 42.3673C36.7345 41.9455 37.4148 41.9455 37.8345 42.3673L39.1118 43.6509L42.4298 40.3164C42.8495 39.8945 43.5298 39.8945 43.9495 40.3164C44.3692 40.7382 44.3692 41.4218 43.9495 41.8436L39.8716 45.9418C39.6618 46.1527 39.3868 46.2582 39.1118 46.2582H39.1081Z" fill="#1B3950" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_799_2313">
          <rect width="90" height="88" fill="white" transform="translate(18 17)" />
        </clipPath>
        <clipPath id="clip1_799_2313">
          <rect width="54" height="28" fill="white" transform="translate(36 40)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
}

CatMode.propTypes = { small: PropTypes.bool, medium: PropTypes.bool };

export default CatMode;
