const removePTagsFromString = (inputString = '') => {
  const regex = /<p[^>]*>|<\/p>/gi;
  return inputString.replace(regex, '');
};

const addClassToAnchors = (inputString) => {
  const regex = /<a\s+(.*?)>/gi;
  const replacement = '<a class="storefront" $1>';
  return inputString.replace(regex, replacement);
};

export const formatString = inputString => addClassToAnchors(removePTagsFromString(inputString));
