import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Modal from 'components/Modal';
import Button from 'components/inputs/Button';

import { useFetchStaticDataIfNeeded } from 'hooks/staticData';
import { useHighlight } from './helpers/highlight';

import SampleQuestionBody from "./components/SampleQuestionBody";
import PageSection from './PageSection';

const SampleQuestion = ({
  questionData,
  questionBankName
}) => {
  const AnswerStatus = loadable(() => import('components/TakeQuizPage/AnswerStatus'));
  const SampleQuestionHeader = loadable(() => import("./components/SampleQuestionHeader"));

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isCorrect, setIsCorrect] = useState(false);
  const [readyToEvaluate, setReadyToEvaluate] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [isStrikingOut, setIsStrikingOut] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [firstTry, setFirstTry] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const correctAnswerId = questionData.attributes.answers.filter(answer => answer.attributes.is_correct)[0].id;
  useFetchStaticDataIfNeeded();

  const highlighterRef = useRef(null);
  const { onClickHighlightOn, onClickHighlightOff } = useHighlight();

  const checkCorrectness = () => {
    if (selectedAnswer && correctAnswerId) {
      setReadyToEvaluate(true);
      setDisabled(true);
      return setIsCorrect(selectedAnswer === correctAnswerId);
    }
  };

  const handleCheckAnswer = () => {
    checkCorrectness();

    if (numberOfAttempts > 0) setFirstTry(false);
    const tmpNumberOfAttempts = numberOfAttempts + 1;
    setNumberOfAttempts(tmpNumberOfAttempts);
  };

  const onClickToggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const onClickSetIsStrikingOut = () => {
    setIsStrikingOut(!isStrikingOut);
  };

  useEffect(() => {
    const explanationContainer = document.getElementById("explanation-wrapper");

    const explanationText = document.createElement("div");
    explanationText.innerHTML = questionData.attributes.safe_explanation;

    const referenceText = document.createElement("div");
    const editedReferenceText = "<strong>Reference:</strong> " + questionData.attributes.safe_reference;
    referenceText.innerHTML = editedReferenceText;

    explanationContainer.appendChild(explanationText);
    explanationContainer.appendChild(referenceText);

    const linkElement = explanationContainer.querySelector("a");

    if (linkElement) {
      linkElement.setAttribute("target", "_blank");
    }

    return () => {};
  }, [questionData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 990) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <>
      <PageSection section="sample-question">
        <Modal />
        <div className="component">
          <div className="title mb-6"><h2>{`Test Your ${questionBankName} Board Knowledge`}</h2></div>
          <SampleQuestionHeader
            questionBankName={questionBankName}
            onClickSetIsStrikingOut={onClickSetIsStrikingOut}
            isStrikingOut={isStrikingOut}
            onClickHighlightOn={onClickHighlightOn}
            onClickHighlightOff={onClickHighlightOff}
          />
          <SampleQuestionBody
            questionData={questionData.attributes}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            correctAnswerId={correctAnswerId}
            setDisabled={setDisabled}
            setReadyToEvaluate={setReadyToEvaluate}
            readyToEvaluate={readyToEvaluate}
            isStrikingOut={isStrikingOut}
            highlighterRef={highlighterRef}
          />
          <div className="check-answer checked d-flex align-items-center">
            <div>
              <Button onClick={handleCheckAnswer} disabled={disabled}>
                Check Answer
              </Button>
            </div>
            <div>
              {readyToEvaluate && (
                <AnswerStatus
                  question={questionData.attributes}
                  correct={isCorrect}
                  first={firstTry}
                  isSampleQuestion
                />
              )}
            </div>
          </div>
          <div className="explanation-container">
            <div>
              <Button styleVariant="light" className="mt-4 mb-4" onClick={onClickToggleExplanation}>
                {showExplanation ? "Close Explanation" : "Show Explanation" }
              </Button>
              <div className={!showExplanation ? 'd-none' : ''}>
                <div className="row">
                  <div
                    id="explanation-wrapper"
                    className="col-12"
                    ref={highlighterRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>
    </>
  );
};

SampleQuestion.propTypes = {
  questionData: PropTypes.object.isRequired,
  questionBankName: PropTypes.string
};

export default SampleQuestion;
