import { Map } from 'immutable';


/* loading Slice of State
 *
 * This is a special reducer which checks every dispatched Action.
 * If the Action has `key` and `promise` attributes in its payload then
 * the Promise is stored in this Slice of State (SoS) using `key`.
 * The key is unset in this Slice of State when it is found in another
 * payload without a `promise` attribute.
 */

export default function reducer(state = new Map(), action) {
  if (Object.prototype.hasOwnProperty.call(action, 'payload')
    && typeof action.payload === 'object'
    && Object.prototype.hasOwnProperty.call(action.payload, 'key')) {
    // Actions that set loading should have a promise in the payload
    if (Object.prototype.hasOwnProperty.call(action.payload, 'promise')) {
      return state.set(action.payload.key, action.payload.promise);
    }
    // Actions that unset loading have the same key but no promise in the payload
    if (state.has(action.payload.key)) {
      return state.delete(action.payload.key);
    }
  }

  return state;
}
