import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueSave(props) {
  return (
    <SvgContainer title="Calculator Icon" {...props}>
      <path fill="fff" d="M50.2 5.1H7.3v54H57V11.9l-6.8-6.8zM18.7 8.4h26.1v15.4H18.7V8.4zM49.8 52H12.3V29.8h37.5V52z" />
      <path fill="fff" d="M35.4 10.2h7.1v11.6h-7.1z" />
    </SvgContainer>
  );
}
