import Factory from 'factories/_Factory';


export default class CmeLicenseRequirement extends Factory {
  static jsonType = 'cme_license_requirement';

  static get defaults() {
    return {
      description: '',
      hours_required: null,
      moc_year: null
    };
  }
}
