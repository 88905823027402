import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgPause(props) {
  return (
    <SvgContainer title="Pause Icon" {...props}>
      <path d="M13.5 4.5h-5a1.001 1.001 0 00-1 1v21a1 1 0 001 1h5a1 1 0 001-1v-21a1.001 1.001 0 00-1-1zm0 22.5v-.5h-5v-21h5v21h.001zM23.5 4.5h-5a1.001 1.001 0 00-1 1v21a1 1 0 001 1h5a1 1 0 001-1v-21a1.001 1.001 0 00-1-1zm0 22.5v-.5h-5v-21h5v21h.001z" />
    </SvgContainer>
  );
}
