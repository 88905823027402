import React from "react";
import SpecialtySearch from 'containers/SpecialtySearch';
import SVGIcon from 'components/SVGIcon';
import { formatString } from "./homePageHelpers";

export default function HomeFreeTrial({ freeTrialTextContent }) {
  const trialIconLabel = "10 day free trial badge";
  const trialImageAltText = "Prepare for your board exam online";

  const {
    freeTrialSectionTitle,
    freeTrialHeaderText,
    freeTrialDescriptionText
  } = freeTrialTextContent;

  return (
    <div className="HomeFreeTrial home-section-wrapper">
      <div className="container">
        <div className="row d-flex flex-wrap px-3 px-md-0">
          <div className="col-12 col-lg-5 d-flex align-items-center order-2 pb-md-6 pb-lg-0 mb-md-4 mb-lg-0">
            <div className="mb-6 mb-lg-0 pb-3 pb-md-6 pb-lg-0">
              <h2
                className="storefront section-title"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(freeTrialSectionTitle) || "BOARD EXAM PREP" }}
              />
              <h2
                className="storefront header-style my-6 mt-md-6 mb-md-2 mb-lg-5 pt-md-2 pb-lg-2"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(freeTrialHeaderText) || "Ace Your Board Exam" }}
              />
              <p
                className="storefront"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: formatString(freeTrialDescriptionText) || "Prepare for the most important exams of your career with realistic board review questions and board-style cases." }}
              />
              <h3 className="storefront my-3 mt-md-6 mb-md-4 my-lg-5 pt-md-3 pt-lg-0">Find your specialty/exam now.</h3>
              <div className="col col-md-9 col-lg-8 pl-0 ml-n2"><SpecialtySearch /></div>
            </div>
          </div>
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-end order-1 order-lg-2 position-relative pt-5 mt-2">
            <div className="free-tial-icon position-absolute">
              <SVGIcon className="d-md-none" name="FreeTrialSmallIcon" ariaLabel={trialIconLabel} height={86} width={87} viewBoxHeight={86} viewBoxWidth={87} />
              <SVGIcon className="d-none d-md-block" name="FreeTrialLargeIcon" ariaLabel={trialIconLabel} height={164} width={165} viewBoxHeight={164} viewBoxWidth={165} />
            </div>
            <img
              className="img-fluid d-lg-none mt-6 pt-3 pt-md-5"
              loading="lazy"
              src="/images/homepage/cardiology-board-review-question-laptop-Mobile-Tablet.png"
              alt={trialImageAltText}
            />
            <img
              className="img-fluid d-none d-lg-block"
              loading="lazy"
              src="/images/homepage/cardiology-board-review-question-laptop.png"
              alt={trialImageAltText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
