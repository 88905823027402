import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgSubmit(props) {
  return (
    <SvgContainer title="Submit Icon" {...props}>
      <path d="M29.489 16.095a1.744 1.744 0 000-.19.501.501 0 00-.03-.08.48.48 0 00-.045-.1l-.007-.015-5-7c-.008-.011-.02-.017-.03-.028a.477.477 0 00-.074-.069.52.52 0 00-.262-.104c-.014-.002-.026-.009-.041-.009a.48.48 0 00-.053.01.486.486 0 00-.096.02.48.48 0 00-.094.042.448.448 0 00-.048.021c-.011.008-.017.02-.028.03a.617.617 0 00-.121.152.49.49 0 00-.032.084.48.48 0 00-.02.102c-.001.014-.008.025-.008.039v2.5h-2V10a.507.507 0 00-.147-.354l-5.999-5.999A.507.507 0 0015 3.5H4A1.502 1.502 0 002.5 5v22A1.502 1.502 0 004 28.5h16a1.502 1.502 0 001.5-1.5v-6.5h2V23c0 .014.007.025.008.039a.52.52 0 00.174.338c.01.01.016.022.027.03.01.006.021.004.03.01A.487.487 0 0024 23.5a.492.492 0 00.21-.05.505.505 0 00.05-.033.486.486 0 00.121-.104c.008-.01.02-.013.026-.022l5-7c.004-.005.004-.011.007-.016a.475.475 0 00.045-.1.504.504 0 00.03-.08zM24.5 20a.5.5 0 00-.5-.5h-4.5v-7H24a.5.5 0 00.5-.5v-1.44L28.385 16 24.5 21.44zm-9-14.793L19.793 9.5H15.5zM20.5 27a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h10.5V10a.5.5 0 00.5.5h5.5v1h-1a1 1 0 00-1 1v7a1 1 0 001 1h1z" />
      <path d="M7 10.5h5a.5.5 0 000-1H7a.5.5 0 000 1zM16 13.5H7a.5.5 0 000 1h9a.5.5 0 000-1zM16 17.5H7a.5.5 0 000 1h9a.5.5 0 000-1zM16 21.5H7a.5.5 0 000 1h9a.5.5 0 000-1z" />
    </SvgContainer>
  );
}
