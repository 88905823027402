import { List } from 'immutable';

import createReducer from 'helpers/createReducer';
import { peerMapper } from 'helpers/b2bPeerCalc';

import { QUESTION_BANK_SELECTED } from 'actions/questionBanks';
import { PERFORMANCE_PEERS_FETCHED } from 'actions/performance';
import { SESSION_RESET } from 'actions/session';


export const initialState = new List();
const reducers = {};

reducers[PERFORMANCE_PEERS_FETCHED] = function peerPerformancesFetched(state, payload) {
  const { response } = payload;
  const peerPerformances = peerMapper(response);
  return peerPerformances;
};


reducers[QUESTION_BANK_SELECTED] = () => initialState;
reducers[SESSION_RESET] = () => initialState;

export default createReducer(reducers, initialState);
