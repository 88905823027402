import Factory from 'factories/_Factory';

// QuizQuestionFactory is used to create objects which belong to a Question in a Quiz and may be created in offline mode.
// These records may not have an ID of their own until synced and are stored in state by Quiz and Question ID.
// For these reasons we validate Quiz ID and Question ID from relationships instead of the ID from the record itself.

export default class QuizQuestionFactory extends Factory {
  static validateJSON(json) {
    const quizId = parseInt(json?.relationships?.quiz?.data?.id);
    const questionId = parseInt(json?.relationships?.question?.data?.id);
    if (!quizId || quizId < 0 || !questionId || questionId < 0) throw new Error(`Quiz ID and Question ID are required to create a new ${this.constructor.name}`);
  }
}
