import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLeftCaret2(props) {
  return (
    <SvgContainer title="Left Caret Icon" {...props}>
      <path d="M11.651 16l9.733-11.68a.5.5 0 10-.768-.64l-10 12a.471.471 0 00-.026.047.455.455 0 00-.074.179.467.467 0 000 .188.455.455 0 00.074.178.471.471 0 00.026.048l10 12a.5.5 0 10.768-.64z" />
    </SvgContainer>
  );
}
