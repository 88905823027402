import React from "react";
import PropTypes from "prop-types";

import * as Icons from 'components/icons';


// StyleGuide = elements/icons/*

const SVGIcon = (props) => {
  const { name } = props;
  const Icon = Icons[name];
  if (!Icon) {
    console.warn(`Invalid SVGIcon name "${name}"`);
    return null;
  }
  return <Icon {...props} />;
};

SVGIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf(Object.keys(Icons)).isRequired
};

SVGIcon.defaultProps = {
  className: ""
};

export default SVGIcon;
