import Factory from 'factories/_Factory';


export default class RiskAssessment extends Factory {
  static jsonType = 'risk_assessment';

  static get defaults() {
    return {
      disabled_qb: true,
      needs_more_data: false,
      subject_level_risks: [],
      risk_distributions: []
    };
  }

  static get belongsTo() {
    return ['user', 'question_bank'];
  }
}
