import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHourglass(props) {
  return (
    <SvgContainer title="Hourglass Icon" {...props}>
      <path d="M20.5 11a.5.5 0 00-.5-.5h-8a.5.5 0 000 1h8a.5.5 0 00.5-.5z" />
      <circle cx={16} cy={14.5} r={1} />
      <circle cx={16} cy={17.5} r={1} />
      <path d="M24.5 8v-.5h1.067a.933.933 0 00.933-.933V4.433a.933.933 0 00-.933-.933H6.433a.933.933 0 00-.933.933v2.134a.933.933 0 00.933.933H7.5V8a6.46 6.46 0 005 6.301V17.7A6.46 6.46 0 007.5 24v.5H6.433a.933.933 0 00-.933.933v2.134a.933.933 0 00.933.933h19.134a.933.933 0 00.933-.933v-2.134a.933.933 0 00-.933-.933H24.5V24a6.46 6.46 0 00-5-6.301V14.3A6.46 6.46 0 0024.5 8zM6.433 4.5L25.5 4.433V6.5l-19 .067zm19.067 23l-19 .067-.067-2.067 3.494-.012-.011.012h12.168l-.047-.055 3.463-.012zm-13.416-3l2.994-3.51a1.125 1.125 0 011.844 0l2.994 3.51zM23.5 24v.5h-2.27l-3.548-4.16a2.146 2.146 0 00-3.364 0L10.77 24.5H8.5V24a5.48 5.48 0 014.583-5.424l-.002-.011a.493.493 0 00.419-.484v-4.164a.493.493 0 00-.419-.484l.002-.01A5.48 5.48 0 018.5 8v-.5h15V8a5.48 5.48 0 01-4.583 5.424l.002.01a.493.493 0 00-.419.483v4.164a.493.493 0 00.419.484l-.002.012A5.48 5.48 0 0123.5 24z" />
    </SvgContainer>
  );
}
