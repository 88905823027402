import StateHelper from 'helpers/StateHelper';
import ProductPage from 'factories/ProductPage';

import {
  CME_COACH_SEARCHED
} from 'actions/cmeCoach';


const stateHelper = new StateHelper(ProductPage);
export const { initialState } = stateHelper;
const reducers = {};


reducers[CME_COACH_SEARCHED] = stateHelper.set;


export default stateHelper.createReducer(reducers);
