import React, { useState } from 'react';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';

import { hubspotSubmitInstitutionalLanding } from 'actions/hubspot';

const HubspotForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [note, setNote] = useState('');
  const [trialChecked, setTrialChecked] = useState(false);
  const [demoChecked, setDemoChecked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const fillValid = () => firstName !== '' && lastName !== '' && email !== '' && institutionName !== '';

  const submitForm = (ev) => {
    ev.preventDefault();
    const data = {
      trial: trialChecked,
      demo_request: demoChecked,
      firstname: firstName,
      lastname: lastName,
      email: email,
      institution: institutionName,
      notes: note
    };
    hubspotSubmitInstitutionalLanding(data);
    setFormSubmitted(true);
  };

  return (
    <div className={formSubmitted ? `HubspotForm submitted` : `HubspotForm`}>
      {formSubmitted ? (
        <div className="thank-you">
          <h1>Thank You!</h1>
          <p>One of our team members will be in touch shortly</p>
        </div>
      ) : (
        <>
          <h4>Ready to Learn More?</h4>
          <p>Get a free, full access trial, a demo walkthrough, or get your questions answered.</p>
          <div className="formWrapper d-flex justify-content-center">
            <form onSubmit={ev => submitForm(ev)}>
              <p><b>I would like:</b></p>
              <div className="d-flex request-type-buttons">
                <Button onClick={() => setTrialChecked(!trialChecked)} active={trialChecked} styleVariant="light" type="button">Trial</Button>
                <Button onClick={() => setDemoChecked(!demoChecked)} active={demoChecked} styleVariant="light" type="button">Demo Request</Button>
              </div>
              <span>First Name*</span>
              <div className="form-group d-flex">
                <Input
                  name="firstname"
                  value={firstName}
                  onChange={(e) => { setFirstName(e); }}
                  type="text"
                />
              </div>
              <span>Last Name*</span>
              <div className="form-group d-flex">
                <Input
                  name="lastname"
                  value={lastName}
                  onChange={(e) => { setLastName(e); }}
                  type="text"
                />
              </div>
              <span>Email*</span>
              <div className="form-group d-flex">
                <Input
                  name="email"
                  value={email}
                  onChange={(e) => { setEmail(e); }}
                />
              </div>
              <span>Institution Name*</span>
              <div className="form-group d-flex">
                <Input
                  name="institutionName"
                  value={institutionName}
                  onChange={(e) => { setInstitutionName(e); }}
                  type="text"
                />
              </div>
              <span>Notes (Optional)</span>
              <div className="form-group d-flex">
                <Input
                  name="note"
                  placeholder="Question banks, number of students, etc"
                  value={note}
                  onChange={(e) => { setNote(e); }}
                  type="textarea"
                  rows="3"
                />
              </div>
              <div className="form-group d-flex">
                <Button disabled={!fillValid()} styleVariant={fillValid() ? 'primary' : 'light'} type="submit">Submit</Button>
              </div>
            </form>
          </div>
        </>
      )
      }
    </div>
  );
};

HubspotForm.propTypes = {

};

export default HubspotForm;
