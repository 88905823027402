import StateHelper from 'helpers/StateHelper';
import ConsumerSubscription from 'factories/ConsumerSubscription';

import {
  SUBSCRIPTIONS_TRIAL_CREATED
} from 'actions/subscriptions';
import {
  USER_SUBSCRIPTIONS_FETCHED,
  USER_SUBSCRIPTION_ACTIVATED,
} from 'actions/users';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';
import {
  PRODUCT_IAP_SYNCED,
  PRODUCT_CREATE_TRIAL
} from 'actions/products';
import {
  ORGANIZATION_MEMBERS_FETCHED,
} from 'actions/b2b/organizations';


const stateHelper = new StateHelper(ConsumerSubscription);
export const { initialState } = stateHelper;
const reducers = {};


reducers[USER_SUBSCRIPTIONS_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;
reducers[USER_SUBSCRIPTION_ACTIVATED] = stateHelper.update;
reducers[SUBSCRIPTIONS_TRIAL_CREATED] = stateHelper.set;
reducers[ORGANIZATION_MEMBERS_FETCHED] = stateHelper.set;

reducers[PRODUCT_IAP_SYNCED] = stateHelper.set;
reducers[PRODUCT_CREATE_TRIAL] = stateHelper.set;


export default stateHelper.createReducer(reducers);
