import React from "react";
import { decodeEntities } from "helpers/stringHelper";
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import PropTypes from 'prop-types';
import PageSection from "./PageSection";

const BlogPreview = ({
  title, image, content, url
}) => {
  const { width: screenWidth } = useWindowDimensions();
  const isMobile = screenWidth <= breakpoints.sm;
  const characterLimit = isMobile ? 64 : 318;
  return (
    <div className="blog-card text-left border p-4 mb-5 d-flex rounded">
      <img src={image} alt="blog-img" loading="lazy" width="100" height="100" />
      <div className="ml-4 contentBlog">
        <a href={url} className="storefront blog-title font-weight-bold m-0 truncate truncate__title">{title}</a>
        <p className="storefront mb-2">{`${decodeEntities(content.replace(/<[^>]*>/g, '')).substring(0, characterLimit)}...`}</p>
        <a href={url} className="storefront blue more-link font-weight-bold">
          Read more &gt;
        </a>
      </div>
    </div>
  );
};

BlogPreview.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

const BlogFeed = ({ questionBankName, blogFeedSection }) => {
  const { body, feeds } = blogFeedSection;
  return (
    <PageSection section="blog-feed">

      <div className="mb-6">
        <h2 className="storefront-secondary-title text-lg-center mb-5">{`Learn more about the ${questionBankName} Board Exam on our blog`}</h2>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      {feeds.map(feed => <BlogPreview key={feed.entry_id} title={feed.title} image={feed.thumbnail} content={feed.content} url={feed.url} />)}

    </PageSection>
  );
};

BlogFeed.propTypes = {
  questionBankName: PropTypes.string.isRequired,
  blogFeedSection: PropTypes.shape({ body: PropTypes.string.isRequired, feeds: PropTypes.array.isRequired })
};

export default BlogFeed;
