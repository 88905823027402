import StateHelper from 'helpers/StateHelper';
import Notification from 'factories/Notification';

import {
  SESSION_RESET
} from 'actions/session';
import {
  NOTIFICATIONS_FETCHED,
  NOTIFICATIONS_SEEN
} from 'actions/notifications';


const stateHelper = new StateHelper(Notification);
export const { initialState } = stateHelper;
const reducers = {};


reducers[NOTIFICATIONS_FETCHED] = stateHelper.set;
reducers[NOTIFICATIONS_SEEN] = stateHelper.set;

reducers[SESSION_RESET] = stateHelper.reset;

export default stateHelper.createReducer(reducers);
