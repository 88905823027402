import api, {
  currentUserUrl,
  selectedOrganizationUrl,
} from 'helpers/api';

import { createApiRequestAction } from 'helpers/createAction';


export const EXAM_TEMPLATES_FETCHING = 'EXAM_TEMPLATES_FETCHING';
export const EXAM_TEMPLATES_FETCHED = 'EXAM_TEMPLATES_FETCHED';
export const EXAM_TEMPLATES_FETCH_ERROR = 'EXAM_TEMPLATES_FETCH_ERROR';
export const examTemplatesFetch = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/exam-templates`,
  request: (getState, organizationId) => api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/exam_templates`),
  loadingConstant: EXAM_TEMPLATES_FETCHING,
  loadedConstant: EXAM_TEMPLATES_FETCHED,
  errorConstant: EXAM_TEMPLATES_FETCH_ERROR,
});

export const EXAM_TEMPLATES_LIST_FETCHING = 'EXAM_TEMPLATES_LIST_FETCHING';
export const EXAM_TEMPLATES_LIST_FETCHED = 'EXAM_TEMPLATES_LIST_FETCHED';
export const EXAM_TEMPLATES_LIST_FETCH_ERROR = 'EXAM_TEMPLATES_LIST_FETCH_ERROR';
export const examTemplatesListFetch = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/exam-templates/fetch_list`,
  request: (getState, organizationId) => api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/exam_templates/fetch_list`),
  loadingConstant: EXAM_TEMPLATES_LIST_FETCHING,
  loadedConstant: EXAM_TEMPLATES_LIST_FETCHED,
  errorConstant: EXAM_TEMPLATES_LIST_FETCH_ERROR,
});


export const EXAM_TEMPLATE_FETCHING = 'EXAM_TEMPLATE_FETCHING';
export const EXAM_TEMPLATE_FETCHED = 'EXAM_TEMPLATE_FETCHED';
export const EXAM_TEMPLATE_FETCH_ERROR = 'EXAM_TEMPLATE_FETCH_ERROR';
export const examTemplateFetch = createApiRequestAction({
  getKey: templateId => `organizations/exam-templates/${templateId}`,
  request: (getState, templateId) => api.get(`${selectedOrganizationUrl(getState())}/exam_templates/${templateId}`),
  loadingConstant: EXAM_TEMPLATE_FETCHING,
  loadedConstant: EXAM_TEMPLATE_FETCHED,
  errorConstant: EXAM_TEMPLATE_FETCH_ERROR,
});


export const EXAM_TEMPLATE_CREATING = 'EXAM_TEMPLATE_CREATING';
export const EXAM_TEMPLATE_CREATED = 'EXAM_TEMPLATE_CREATED';
export const EXAM_TEMPLATE_CREATE_ERROR = 'EXAM_TEMPLATE_CREATE_ERROR';
export const examTemplateCreate = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/exam-templates/create`,
  request: (getState, organizationId, params) =>
    api.post(`${currentUserUrl(getState())}/organizations/${organizationId}/exam_templates`, { exam_template: params }),
  loadingConstant: EXAM_TEMPLATE_CREATING,
  loadedConstant: EXAM_TEMPLATE_CREATED,
  errorConstant: EXAM_TEMPLATE_CREATE_ERROR,
});


export const EXAM_TEMPLATE_UPDATING = 'EXAM_TEMPLATE_UPDATING';
export const EXAM_TEMPLATE_UPDATED = 'EXAM_TEMPLATE_UPDATED';
export const EXAM_TEMPLATE_UPDATE_ERROR = 'EXAM_TEMPLATE_UPDATE_ERROR';
const updateActionConstants = {
  loadingConstant: EXAM_TEMPLATE_UPDATING,
  loadedConstant: EXAM_TEMPLATE_UPDATED,
  errorConstant: EXAM_TEMPLATE_UPDATE_ERROR,
};

export const examTemplateUpdate = createApiRequestAction({
  getKey: templateId => `organizations/exam-templates/${templateId}/update`,
  request: (getState, templateId, params) =>
    api.patch(`${selectedOrganizationUrl(getState())}/exam_templates/${templateId}`, { exam_template: params }),
  ...updateActionConstants,
});

export const examTemplateArchive = createApiRequestAction({
  getKey: templateId => `organizations/exam-templates/${templateId}/archive`,
  request: (getState, templateId) => api.patch(`${selectedOrganizationUrl(getState())}/exam_templates/${templateId}/archive`),
  ...updateActionConstants,
});

export const examTemplateUnarchive = createApiRequestAction({
  getKey: templateId => `organizations/exam-templates/${templateId}/unarchive`,
  request: (getState, templateId) => api.patch(`${selectedOrganizationUrl(getState())}/exam_templates/${templateId}/unarchive`),
  ...updateActionConstants,
});

export const QUESTIONS_ON_TEMPLATE_FETCHED = 'QUESTIONS_ON_TEMPLATE_FETCHED';
export const QUESTIONS_ON_TEMPLATE_FETCHING = 'QUESTIONS_ON_TEMPLATE_FETCHING';
export const QUESTIONS_ON_TEMPLATE_FETCH_ERROR = 'QUESTIONS_ON_TEMPLATE_FETCH_ERROR';
const questionsOnTemplatesConstants = {
  loadingConstant: QUESTIONS_ON_TEMPLATE_FETCHING,
  loadedConstant: QUESTIONS_ON_TEMPLATE_FETCHED,
  errorConstant: QUESTIONS_ON_TEMPLATE_FETCH_ERROR,
};
export const questionsOnTemplates = createApiRequestAction({
  getKey: questionBankId => `question_banks/${questionBankId}/questions_used_on_templates`,
  request: (getState, questionBankId) => api.get(`${currentUserUrl(getState())}/question_banks/${questionBankId}/questions_used_on_templates`),
  ...questionsOnTemplatesConstants,
});
