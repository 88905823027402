import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgFilledStar(props) {
  return (
    <SvgContainer title="Filled Star Icon" {...props}>
      <path d="M27.46 13.149a.238.238 0 00-.03-.09c-.02-.03-.04-.05-.06-.08a.458.458 0 00-.07-.07c-.01 0-.01-.01-.02-.02-.02-.01-.05-.02-.07-.03l-.06-.03-7.11-2.25-3.61-6.24c0-.01-.01-.01-.01-.02-.02-.03-.05-.05-.07-.08a.435.435 0 00-.07-.06l-.02-.02c-.02-.01-.04-.01-.06-.02a.3.3 0 00-.1-.04h-.09a.168.168 0 00-.1 0 .318.318 0 00-.09.03c-.02.01-.05.01-.07.02l-.02.02a.44.44 0 01-.07.06c-.02.03-.05.05-.07.08l-.01.01-3.91 6.26-6.83 2.25c-.02.01-.04.03-.06.04s-.05.01-.07.03l-.02.02a.457.457 0 00-.07.07c-.02.03-.04.05-.05.07a.355.355 0 00-.04.09.293.293 0 00-.02.09.271.271 0 000 .09.41.41 0 00.01.11.03.03 0 00.01.02c0 .01.01.02.02.03a.417.417 0 00.04.1l3.89 5.54-.98 8.22c0 .01.01.02.01.03a.405.405 0 000 .11c.01.03.01.06.02.09a.037.037 0 01.01.03c.01.02.03.03.04.05a.218.218 0 00.05.08.506.506 0 00.08.07.435.435 0 00.07.05l.11.03a.077.077 0 00.05.02H8a.407.407 0 00.19-.04h.01l7.8-3.33 7.8 3.33h.01a.407.407 0 00.19.04h.06a.591.591 0 00.2-.07l.03-.03a.614.614 0 00.15-.17c0-.01.01-.02.02-.03a.037.037 0 01.01-.03c.01-.03.01-.06.02-.09a.405.405 0 000-.11c0-.01.01-.02.01-.03l-.98-8.07 3.89-5.69a.535.535 0 00.05-.11c0-.01.01-.01.02-.02v-.03a.375.375 0 00.01-.11.215.215 0 000-.08.24.24 0 00-.03-.09z" />
    </SvgContainer>
  );
}
