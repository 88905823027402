import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  return (
    <SvgContainer title="Radio Selected" width={21} height={21} viewBoxHeight={23} viewBoxWidth={23} {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle stroke="#0044DB" cx="10.5" cy="10.5" r="10.5" />
        <circle fill="#0044DB" cx="10.5" cy="10.5" r="7.5" />
      </g>
    </SvgContainer>
  );
}
