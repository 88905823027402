import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLabValues(props) {
  return (
    <SvgContainer title="Lab Values Icon" {...props}>
      <path d="M26.494 25.393a.758.758 0 00-.053-.192c-.004-.01-.004-.02-.01-.03L19.5 13.364V5H21a.5.5 0 000-1H11a.5.5 0 000 1h1.5v8.364L5.569 25.171c-.006.01-.005.02-.01.03a.758.758 0 00-.053.192c0 .01-.006.02-.006.031A2.578 2.578 0 008.076 28h15.848a2.578 2.578 0 002.576-2.576c0-.01-.006-.02-.006-.031zM23.924 27H8.076a1.573 1.573 0 01-1.564-1.46L13.286 14H16a.5.5 0 000-1h-2.5v-2H16a.5.5 0 000-1h-2.5V8H16a.5.5 0 000-1h-2.5V5h5v8.5c0 .011.006.02.006.032a.758.758 0 00.053.192c.004.01.004.02.01.029l6.92 11.787A1.573 1.573 0 0123.923 27z" />
      <path d="M21.474 23.262a.49.49 0 00-.032-.093c-.004-.01-.004-.02-.01-.03l-1.678-2.89a.459.459 0 00-.04-.046.49.49 0 00-.069-.077.67.67 0 00-.163-.094.494.494 0 00-.097-.02.47.47 0 00-.063-.012h-6.644a.472.472 0 00-.058.012.486.486 0 00-.104.02.466.466 0 00-.082.04.39.39 0 00-.147.13.46.46 0 00-.041.047l-1.679 2.891c-.005.01-.005.02-.01.03a.779.779 0 00-.051.191c0 .01-.006.02-.006.03A1.611 1.611 0 0012.11 25h7.78a1.611 1.611 0 001.61-1.61c0-.01-.005-.019-.006-.029a.49.49 0 00-.02-.099zM19.891 24h-7.782a.602.602 0 01-.589-.51L12.966 21h6.068l1.446 2.49a.602.602 0 01-.59.51z" />
    </SvgContainer>
  );
}
