import Factory from 'factories/_Factory';

export default class BoardExamDate extends Factory {
  static jsonType = 'board_exam_date';

  static get defaults() {
    return {
      user_id: null,
      exam_date: null,
      follow_up: true,
      postponed: false,
      first_quiz_completed: false,
      question_bank_id: null,
      study_frequency: null,
      updated_at: null
    };
  }

  static get belongsTo() {
    return ['question_bank', 'user'];
  }
}
