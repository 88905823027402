import React from "react";
import PropTypes from "prop-types";

import NavLink from "components/menus/NavLink";
import { svgProps } from 'components/icons/SvgContainer';


// StyleGuide = molecules/menus/nav-dropdown

class NavDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  toggleShow(ev) {
    if (ev) ev.preventDefault();
    const { show } = this.state;
    this.setState({ show: !show });
  }

  handleBlur(ev) {
    if (!ev.currentTarget.contains(ev.relatedTarget)) {
      ev.preventDefault();
      this.setState({ show: false });
    }
  }

  render() {
    const {
      children,
      toggle,
      right,
      active,
      icon,
      iconProps,
      toggleClassName,
      background,
      tabIndex
    } = this.props;
    const { show } = this.state;

    return (
      <div className={`NavDropdown nav-item dropdown ${background ?? ""}`}>
        <NavLink
          className={`${toggleClassName} ${active ? "active" : ""}`}
          onClick={ev => this.toggleShow(ev)}
          icon={icon}
          iconProps={iconProps}
          ariaControls={`id-${toggle}`}
          ariaExpanded={show}
          tabIndex={tabIndex}
        >
          { toggle }
        </NavLink>
        <div onBlur={ev => this.handleBlur(ev)} id={`id-${toggle}`} className={`dropdown-menu ${background ?? ""} ${show ? "show" : "hide"} ${right ? 'dropdown-menu-right' : ''}`}>
          { children }
        </div>
        { /* eslint-disable-next-line */ }
        {show && (<div className="dropdown-menu-underlay" onClick={ev => this.toggleShow(ev)} />)}
      </div>
    );
  }
}

NavDropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  toggle: PropTypes.string.isRequired,
  right: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.string,
  iconProps: PropTypes.shape({ ...svgProps }),
  toggleClassName: PropTypes.string,
  background: PropTypes.string,
  tabIndex: PropTypes.string
};

NavDropdown.defaultProps = {
  iconProps: { height: 12, width: 12 },
  toggleClassName: ''
};

export default NavDropdown;
