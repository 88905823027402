import { fromJS } from 'immutable';

import createReducer from 'helpers/createReducer';
import {
  SESSION_FETCHING,
  SESSION_FETCHED,
  SESSION_RESET,
  SESSION_RESET_OFFLINE,
  SESSION_SET_SEARCH_ID,
  SESSION_SET_CAN_SEE_CORRECT_ANSWERS,
} from 'actions/session';
import {
  ORGANIZATION_SELECTED,
} from 'actions/b2b/organizations';
import {
  QUESTION_BANK_SELECTED,
} from 'actions/questionBanks';
import {
  QUESTIONS_ON_TEMPLATE_FETCHED,
  EXAM_TEMPLATE_UPDATED
} from 'actions/b2b/examTemplates';
import {
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_PERFORMANCE,
  QUIZ_SUBMITTED
} from 'actions/quizzes';


import { CME_TRACKER_FETCHED } from 'actions/cmeTracker';
import { RESPONSE_SYNCED } from 'actions/responses';


export const initialState = fromJS({
  loading: 'initialized', // we assume that we are going to load the session first with sessionCheck()
  is_logged_in: false,
  logged_in_at: null,
  current_user_id: 0,
  selected_question_bank_id: 0,
  current_sign_in_at: null,
  cme_invalid: true,
  can_see_correct_answers: false,
  selected_organization_id: 0,
  questions_on_templates_bank_id: 0,
  questions_on_templates: [],
  isLti: false
});
const reducers = {};

const checkLti = (state, payload) => {
  const includes = payload.included;
  const assignment = includes.find(i => i.type === 'assignment');
  if (assignment) return state.set('isLti', assignment.attributes.is_lti);
  return state;
};

reducers[SESSION_FETCHING] = function sessionFetching(state) {
  return state.set('loading', true);
};

reducers[QUIZ_FETCHED_PERFORMANCE] = checkLti;
reducers[QUIZ_FETCHED_TAKE] = checkLti;
reducers[QUIZ_SUBMITTED] = checkLti;

reducers[SESSION_FETCHED] = function sessionFetched(state, payload) {
  const user = payload.data;
  return initialState
    .set('loading', false)
    .set('isIframe', payload.isIframe)
    .set('is_logged_in', true)
    .set('logged_in_at', Date.now())
    .set('current_user_id', parseInt(user.id))
    .set('selected_question_bank_id', parseInt(user.relationships.last_selected_question_bank.data.id))
    .set('selected_organization_id', payload.selectedOrganizationId || 0)
    .set('current_sign_in_at', new Date(user.attributes.current_sign_in_at).getTime());
};

reducers[SESSION_RESET] = function sessionReset() {
  return initialState.set('loading', false);
};

reducers[SESSION_RESET_OFFLINE] = function sessionResetOffline(state) {
  return state.set('loading', false);
};

reducers[SESSION_SET_SEARCH_ID] = (state, { searchId }) => state.set('search_id', searchId);

reducers[SESSION_SET_CAN_SEE_CORRECT_ANSWERS] = (state, payload) => state.set('can_see_correct_answers', payload.enabled);

reducers[ORGANIZATION_SELECTED] = (state, payload) => state.set('selected_organization_id', payload.organizationId);

// Non-Session Action Reducers

reducers[QUESTION_BANK_SELECTED] = function questionBankSelected(state, payload) {
  return state.merge({ selected_question_bank_id: parseInt(payload.data.id), search_id: null });
};

reducers[RESPONSE_SYNCED] = function responseSynced(state) {
  return state.set('cme_invalid', true);
};

reducers[CME_TRACKER_FETCHED] = function cmeFetched(state) {
  return state.set('cme_invalid', false);
};

reducers[QUESTIONS_ON_TEMPLATE_FETCHED] = function questionBankSelected(state, payload) {
  return state.merge({ questions_on_templates: payload.questions_on_templates, questions_on_templates_bank_id: payload.bank_id });
};

reducers[EXAM_TEMPLATE_UPDATED] = function questionBankSelected(state, payload) {
  if (state.get('questions_on_templates_bank_id') !== parseInt(payload.data.relationships.question_bank.data.id)) return state;
  return state.merge({ questions_on_templates_bank_id: 0 });
};

export default createReducer(reducers, initialState);
