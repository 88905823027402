import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgDownCaret1(props) {
  return (
    <SvgContainer title="Down Caret Icon" {...props}>
      <path d="M28.475 11.124a.486.486 0 00.02-.102c0-.007.005-.014.005-.022a2759.659 2759.659 0 01-.034-.168 128.78 128.78 0 00-.082-.152c-.005-.006-.012-.008-.017-.014a.493.493 0 00-.083-.066.283.283 0 00-.156-.074.49.49 0 00-.107-.022c-.007 0-.014-.004-.021-.004H4c-.008 0-.014.004-.022.004a.485.485 0 00-.105.021.497.497 0 00-.084.025.497.497 0 00-.075.05.492.492 0 00-.08.065c-.006.006-.013.009-.018.015a.47.47 0 00-.033.06.472.472 0 00-.049.09.481.481 0 00-.02.104A.47.47 0 003.5 11c0 .008.004.014.004.022a.593.593 0 00.161.344c.006.006.009.013.015.018l12 10a161.539 161.539 0 00.13.075.498.498 0 00.19.041.498.498 0 00.189-.041 161.539 161.539 0 00.13-.075l12-10c.007-.005.01-.012.015-.018a.4.4 0 00.14-.241zM16 20.35L5.381 11.5h21.237z" />
    </SvgContainer>
  );
}
