import { Map } from 'immutable';
import createReducer from 'helpers/createReducer';

import {
  defaultOptions,
  MODAL_OPEN,
  MODAL_CLOSE
} from 'actions/modal';


export const initialState = Map({
  shouldShow: false,
  content: null,
  ...defaultOptions
});
const reducers = {};


reducers[MODAL_OPEN] = function modalOpen(state, payload) {
  let newState = state;
  Object.keys(defaultOptions).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      newState = newState.set(key, payload[key]);
    } else {
      newState = newState.set(key, defaultOptions[key]);
    }
  });
  return newState
    .set('shouldShow', true)
    .set('content', payload.content);
};

reducers[MODAL_CLOSE] = function modalClose(state) {
  return state.set('shouldShow', false);
};


export default createReducer(reducers, initialState);
