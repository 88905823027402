import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgCloseX(props) {
  return (
    <SvgContainer title="CloseX Icon" {...props}>
      <path transform="rotate(-45 32.339 31.865)" d="M30.34 15.2h4v33.33h-4z" />
      <path transform="rotate(-45 32.339 31.865)" d="M15.68 29.86h33.33v4H15.68z" />
      <path d="M44 52H19a8 8 0 0 1-8-8V19a8 8 0 0 1 8-8h25a8 8 0 0 1 8 8v25a8 8 0 0 1-8 8ZM19 14a5 5 0 0 0-5 5v25a5 5 0 0 0 5 5h25a5 5 0 0 0 5-5V19a5 5 0 0 0-5-5Z" />
    </SvgContainer>
  );
}
