import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgNavigator(props) {
  return (
    <SvgContainer title="Navigator Icon" {...props}>
      <path d="M29 22.45v8.51L22.09 37h9.18L37 44.02v-9.14L43.55 29h-8.68L29 22.45zM29 20h-7v-2h-2v-5h4v-2h7v7h-2v2zM13 20v5h-2v6h7v-2h2v-7h-2.2l-.06-2H13zM11 35v7h3v4h4v-2h2v-7h-3v-2h-6zM20 48v5h5v2h6v-7h-2v-2h-7v2h-2zM46 48v5h-5v2h-6v-7h2v-2h7v2h2zM37 20h7v-2h2v-5h-4v-2h-7v7h2v2zM53 20v5h2v6h-7v-2h-2v-7h2.2l.06-2H53zM55 35v7h-3v4h-4v-2h-2v-7h3v-2h6z" />
    </SvgContainer>
  );
}
