import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueRightArrow(props) {
  return (
    <SvgContainer title="Right Arrow Icon" {...props}>
      <circle cx="32.5" cy="32.5" r="28.5" fill="#ffffff" />
      <path d="M32.1 61C16.2 61 3.2 48 3.2 32.1s13-28.9 28.9-28.9S61 16.2 61 32.1 48.1 61 32.1 61zm0-56.8C16.7 4.2 4.2 16.7 4.2 32.1S16.7 60 32.1 60 60 47.5 60 32.1 47.5 4.2 32.1 4.2z" fill="#81ade1" />
      <g>
        <path d="M36.4 17.8c-.3-.3-.6-.4-1-.4s-.7.1-1 .4c-.3.3-.4.6-.4 1V27H14v11h20v8.3c0 .8.6 1.4 1.4 1.4.4 0 .8-.2 1.1-.4l14.1-13.8c.3-.2.4-.6.4-.9 0-.4-.1-.7-.4-1L36.4 17.8z" fill="#354afb" />
      </g>
    </SvgContainer>
  );
}
