import { Map, fromJS } from 'immutable';

import Factory from 'factories/_Factory';


export default class CmeActivity extends Factory {
  static jsonType = 'cme_activity';

  static get defaults() {
    return {
      display_name: '',
      name: '',
      cme_type: '',
      cme_hours: 0,
      single_redemption: false,
      front_matter: '',
      minimum_percent_correct: 0,
      state: '',
      survey_attributes: new Map(),
      type: '',
      board_id_or_license_number: '',
      board_id_or_license_number_required: false,
      single_redemption_conversion_date: ''
    };
  }

  static get belongsTo() {
    return ['accreditor'];
  }

  static afterUpdate(record, json) {
    let newRecord = record;
    // TODO: Update this to not use fromJS
    if (json.attributes.survey_attributes) newRecord = newRecord.set('survey_attributes', fromJS(json.attributes.survey_attributes));

    if (json.attributes.single_redemption_conversion_date) newRecord = newRecord.set('single_redemption_conversion_date', new Date(json.attributes.single_redemption_conversion_date).getTime());

    const cmeType = json.cme_type || newRecord.get('cme_type');
    if (cmeType === "AMA PRA Category 1™") return newRecord.set('type', 'CME Credits');
    if (cmeType === "ANCC Contact Hours") return newRecord.set('type', 'CE Credits');
    return newRecord.set('type', "MOC Points");
  }
}
