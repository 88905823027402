import { Map } from 'immutable';

import StateHelper from 'helpers/StateHelper';
import CmeActivityTracker from 'factories/CmeActivityTracker';

import { SESSION_RESET } from 'actions/session';
import { CME_TRACKER_FETCHED } from 'actions/cmeTracker';
import { CME_ACTIVITIES_FETCHED, CME_CALCULATED } from 'actions/cme';

const stateHelper = new StateHelper(CmeActivityTracker);

const reducers = {};

// V1 CME
reducers[CME_TRACKER_FETCHED] = stateHelper.set;

// V2 CME (Tutor Mode Enabled)
reducers[CME_ACTIVITIES_FETCHED] = function cmeActivitiesFetched(state, payload) {
  let newState = state;

  payload.data.forEach((json) => {
    const activityId = parseInt(json.id);
    newState = newState.set(activityId, new Map({
      cme_activity_id: activityId,
      user_id: payload.userId,
      cme_credit_tracker_id: null,
      question_bank_id: null,
      credits_earned: 0,
      earliest_available_cme: '',
      eligible_to_redeem_hours: null,
      incorrect_total: 0,
      latest_available_cme: '',
      meets_minimum_percent_correct: null,
      percent_correct: '',
      questions_total_to_next_credit: 0
    }));
  });

  return newState;
};

reducers[CME_CALCULATED] = function cmeCalculated(state, payload) {
  let newState = state;

  Object.keys(payload.cmeActivityStats).forEach((cmeActivityIdString) => {
    const cmeActivityId = parseInt(cmeActivityIdString);
    const stats = payload.cmeActivityStats[cmeActivityId];

    newState = newState.mergeIn([cmeActivityId], {
      credits_earned: Math.round(stats.creditsEarned),
      incorrect_total: stats.incorrectCount,
      percent_correct: stats.percentCorrect,
      questions_total_to_next_credit: stats.questionsTotalToNextCredit,
      questions_for_credit: stats.questionsForCredit,
      questions_per_hour: stats.questionsPerHour,
      earliest_available_cme: stats.earliestAvailableCme,
      latest_available_cme: stats.latestAvailableCme
    });
  });

  return newState;
};

reducers[SESSION_RESET] = stateHelper.reset;

export default stateHelper.createReducer(reducers);
