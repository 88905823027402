import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

const Key = ({
  type, id, handleClick, active, trigUnit, label, ...props
}) => {
  const getTrigUnit = unit => (unit === "deg" ? "rad" : "deg");

  const text = id === "trigUnit" ? (getTrigUnit(trigUnit)) : label;

  const keyClassNames = classNames('key', type, id, { active: active });

  return (
    <button
      type="button"
      id={id}
      className={keyClassNames}
      onClick={event => handleClick(event, { type })}
      {...props}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

Key.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  trigUnit: PropTypes.oneOf(['deg', 'rad']),
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default Key;
