import Factory from 'factories/_Factory';

export default class Category extends Factory {
  static jsonType = 'category';

  static get defaults() {
    return {
      name: '',
    };
  }
}
