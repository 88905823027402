import { stateHelper } from 'reducers/quizzes/quizzes';
import QuizBlock from 'factories/QuizBlock';

import {
  QUIZ_CREATED,
  QUIZ_FETCHED_TAKE,
  QUIZ_FETCHED_QUESTIONS,
  QUIZZES_FETCHED,
  QUIZ_FETCHED_PRACTICE,
  QUIZ_FETCHED_PERFORMANCE,
  QUIZ_TICK
} from 'actions/quizzes';

const reducers = {};

function setQuizBlock(state, json) {
  const quizId = parseInt(json.relationships.quiz.data.id);
  const quizBlockId = json.id;
  let quizBlock = state.getIn([quizId, 'quiz_blocks', quizBlockId]);
  quizBlock = quizBlock ? QuizBlock.update(quizBlock, json) : new QuizBlock(json);
  return state.setIn([quizId, 'quiz_blocks', quizBlockId], quizBlock);
}

function setQuizBlocks(state, payload) {
  return payload.included.filter(record => record.type === 'quiz_block').reduce(setQuizBlock, state);
}

function setQuizBlockTime(state, payload) {
  const { quizId, seconds } = payload;
  const quiz = state.get(payload.quizId);
  let currentBlock = quiz.getIn(['quiz_blocks']).find(b => b.get('position') === quiz.get('last_quiz_block_number'));

  if (quiz.get('board_exam_interface') === 'fred') {
    if (currentBlock.get('seconds_remaining') <= 0) {
      const blocksToGo = quiz.get('quiz_blocks').filter(qb => qb.get('position') > quiz.get('last_quiz_block_number'));
      const eligibleBlocks = blocksToGo.filter(qb => qb.get('seconds_remaining') > 0);
      currentBlock = eligibleBlocks.toList().sortBy(qb => qb.get('position')).first();
    }
  }

  const newTime = currentBlock.get('seconds_remaining') - seconds >= 0 ? currentBlock.get('seconds_remaining') - seconds : 0;
  currentBlock = currentBlock.set('seconds_remaining', newTime);
  return state.setIn([quizId, 'quiz_blocks', `${currentBlock.get('id')}`], currentBlock);
}

function setQuizBlocksTick(state, payload) {
  const quiz = state.get(payload.quizId);
  if (quiz.get('quiz_blocks').size) {
    const currentBlock = quiz.getIn(['quiz_blocks']).find(b => b.get('position') === quiz.get('last_quiz_block_number'));
    if (currentBlock.get('question_ids').size && (quiz.get('block_pool_seconds_remaining') === null)) {
      return setQuizBlockTime(state, payload);
    }
    if (!currentBlock.get('question_ids').size && (quiz.get('break_pool_seconds_remaining') === null)) {
      return setQuizBlockTime(state, payload);
    }
  }
  return state;
}

reducers[QUIZ_TICK] = setQuizBlocksTick;
reducers[QUIZ_FETCHED_PERFORMANCE] = setQuizBlocks;
reducers[QUIZ_FETCHED_PRACTICE] = setQuizBlocks;
reducers[QUIZ_CREATED] = setQuizBlocks;
reducers[QUIZZES_FETCHED] = setQuizBlocks;
reducers[QUIZ_FETCHED_TAKE] = setQuizBlocks;
reducers[QUIZ_FETCHED_QUESTIONS] = setQuizBlocks;

export const reducer = stateHelper.createReducer(reducers);

export default reducers;
