import { useRef } from 'react';

import rangy from "rangy/lib/rangy-core.js";
import "rangy/lib/rangy-highlighter";
import "rangy/lib/rangy-classapplier";
import "rangy/lib/rangy-textrange";
import "rangy/lib/rangy-serializer";

export const useHighlight = () => {
  const highlights = useRef(new Map());

  const onClickHighlightOn = () => {
    const range = rangy.getSelection().getRangeAt(0);
    const cssClass = 'myHighlighter';
    const applier = rangy.createClassApplier(cssClass, {
      elementTagName: 'span',
      elementProperties: {
        className: cssClass,
        style: {
          backgroundColor: '#ffc107',
        }
      }
    });
    applier.applyToRange(range);
    highlights.current.set(range.toString(), applier);
  };

  const onClickHighlightOff = () => {
    const range = rangy.getSelection().getRangeAt(0);
    const highlightsMap = highlights.current;
    const key = range.toString();
    const applier = highlightsMap.get(key);
    if (applier) {
      const highlightElements = document.getElementsByClassName(applier.cssClass);
      // Loop through the highlight elements and remove them if they are within the selected range
      for (let i = highlightElements.length - 1; i >= 0; i -= 1) {
        const highlightElement = highlightElements[i];
        const highlightRange = rangy.createRange();
        highlightRange.selectNodeContents(highlightElement);
        if (range.intersectsRange(highlightRange)) {
          highlightElement.outerHTML = highlightElement.innerHTML;
        }
      }
      highlightsMap.delete(key); // Delete the highlight from the Map
    }
  };

  return { onClickHighlightOff, onClickHighlightOn };
};
