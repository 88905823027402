import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgVennDiagram(props) {
  return (
    <SvgContainer title="Venn Diagram Icon" {...props}>
      <path d="M29.25 11.75A8.496 8.496 0 0016 4.704a8.494 8.494 0 10-8.456 14.683 8.5 8.5 0 1016.912 0 8.503 8.503 0 004.794-7.637zm-25.5 0a7.484 7.484 0 0111.41-6.388 8.475 8.475 0 00-2.91 6.388 8.567 8.567 0 00.044.863 8.521 8.521 0 00-4.576 5.75A7.505 7.505 0 013.75 11.75zm13.833-4h-3.166A7.554 7.554 0 0116 5.951a7.554 7.554 0 011.583 1.799zm5.77 11.025a7.427 7.427 0 01-2.003.445l1.34-2.341a7.428 7.428 0 01.663 1.896zm-3.146.431l.032.018a7.49 7.49 0 01-1.107-.155l2.285-3.993a7.541 7.541 0 01.681.824zm-2.058-.43a7.46 7.46 0 01-1.31-.638 8.497 8.497 0 002.693-4.501 7.52 7.52 0 011.13.748zM9.31 16.876l1.34 2.343a7.429 7.429 0 01-2.003-.445 7.431 7.431 0 01.663-1.898zm.593-.978a7.547 7.547 0 01.681-.823l2.285 3.993a7.47 7.47 0 01-1.078.154l.01-.006zm3.95 2.876l-2.513-4.391a7.505 7.505 0 011.128-.747 8.497 8.497 0 002.692 4.5 7.466 7.466 0 01-1.308.638zM16 11.75a8.45 8.45 0 00-2.727.457c-.01-.152-.023-.303-.023-.457a7.497 7.497 0 01.075-1h5.35a7.497 7.497 0 01.075 1c0 .154-.014.305-.023.457A8.447 8.447 0 0016 11.75zm-2.471-2a7.444 7.444 0 01.351-1h4.24a7.444 7.444 0 01.351 1zm9.971 10.5a7.5 7.5 0 01-15 0c0-.154.014-.305.023-.457A8.443 8.443 0 0016 18.796a8.442 8.442 0 007.477.997c.01.152.023.303.023.457zm.782-1.887a8.523 8.523 0 00-4.576-5.75 8.565 8.565 0 00.044-.863 8.475 8.475 0 00-2.91-6.388 7.493 7.493 0 117.442 13.001z" />
    </SvgContainer>
  );
}
