import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


// StyleGuide = elements/global/fade

function Fade(props) {
  const {
    children,
    hide,
    className,
    id,
    role
  } = props;
  const shouldShow = !hide;
  const [shown, setShown] = useState(() => shouldShow);

  const ref = useRef();

  let finalProps = { role };
  if (id) {
    finalProps = {
      ...finalProps,
      id
    };
  }

  useEffect(() => {
    if (shouldShow === shown) return;

    // force redraw to make animation work
    ref.current.getClientRects();
    // Wait until it's safe to start the animation
    requestAnimationFrame(() => {
      // Start the animation
      setShown(null);
    });
  }, [shouldShow]);
  // end hooks

  const onFadeEnd = (ev) => {
    // Return immediately unless it's this component which has triggered the event
    if (ev.target !== ref.current) return;

    if (shouldShow && shown !== true) {
      // Finish fading in
      setShown(true);
    } else if (!shouldShow && shown !== false) {
      // Finish fading out
      setShown(false);
    }
  };

  const finalClassName = classNames(className, 'fade', {
    show: shouldShow && shown !== false,
    'd-none': !shouldShow && shown === false
  });

  return (
    <div {...finalProps} className={finalClassName} ref={ref} onTransitionEnd={onFadeEnd}>
      { children }
    </div>
  );
}

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  role: PropTypes.string
};

Fade.defaultProps = {
  hide: false,
  role: "generic"
};

export default Fade;
