import React, { useState, useEffect } from "react";
import loadable from '@loadable/component';
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { staticDataFetch } from 'actions/staticData';

export default function PlpPageClass ({ context }) {
  const PlpCmeCoach = loadable(() => import("./PlpPage/PlpCmeCoach"));
  const PlpFilter = loadable(() => import("./PlpPage/PlpFilter"));
  const PlpPageInstructions = loadable(() => import("./PlpPage/PlpPageInstructions"));
  const PlpPageHeader = loadable(() => import("./PlpPage/PlpPageHeader"));

  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [mobileFiltersRecentlyClosed, setMobileFiltersRecentlyClosed] = useState(false);
  const [creditTypesSelected, setCreditTypesSelected] = useState([]);
  const [numberOfCreditsSelected, setNumberOfCreditsSelected] = useState(null);
  const [showCreditTypes, setShowCreditTypes] = useState(true);
  const [showCreditAmount, setShowCreditAmount] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(staticDataFetch());
  }, []);

  return (
    <div className="ProductListingPage">
      <PlpPageHeader showMobileFilters={showMobileFilters} />
      <PlpPageInstructions showMobileFilters={showMobileFilters} />
      <PlpFilter
        data={context}
        showMobileFilters={showMobileFilters}
        setShowMobileFilters={setShowMobileFilters}
        mobileFiltersRecentlyClosed={mobileFiltersRecentlyClosed}
        setMobileFiltersRecentlyClosed={setMobileFiltersRecentlyClosed}
        creditTypesSelected={creditTypesSelected}
        setCreditTypesSelected={setCreditTypesSelected}
        numberOfCreditsSelected={numberOfCreditsSelected}
        setNumberOfCreditsSelected={setNumberOfCreditsSelected}
        showCreditTypes={showCreditTypes}
        setShowCreditTypes={setShowCreditTypes}
        showCreditAmount={showCreditAmount}
        setShowCreditAmount={setShowCreditAmount}
      />
      <PlpCmeCoach
        showMobileFilters={showMobileFilters}
      />
    </div>
  );
}

PlpPageClass.propTypes = { context: PropTypes.object.isRequired };
