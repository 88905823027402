import { Map } from "immutable";

import StateHelper from "helpers/StateHelper";


// QuizQuestionStateHelper Slices of State are unique in that their records are indexed primarily
// on the Quiz ID and Question ID. These Slices of State are therefore nested under two Maps
// instead of one, the first being Quiz IDs and the second being Question IDs.
// To get a specific record from one of these Slices of State, you would use `state.getIn([quizId, questionId])`
// These records may be created in offline mode and will not have IDs of their own until synced.

export default class QuizQuestionStateHelper extends StateHelper {
  setRecord(state, jsonRecord, options = {}) {
    const quizId = parseInt(jsonRecord.relationships.quiz.data.id);
    const questionId = parseInt(jsonRecord.relationships.question.data.id);

    let newRecord;
    const oldRecord = state.getIn([quizId, questionId]);
    if (oldRecord) {
      newRecord = this.Factory.update(oldRecord, jsonRecord, options);
      if (newRecord.get('id') === null) newRecord = newRecord.set('id', parseInt(jsonRecord.id));
    } else {
      newRecord = new this.Factory(jsonRecord, options);
    }

    const records = state.get(quizId) || new Map();
    return state.set(quizId, records.set(questionId, newRecord));
  }

  destroyRecord(state, quizId, questionId) {
    return state.deleteIn([quizId, questionId]);
  }
}
