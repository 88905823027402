import api from 'helpers/api';

export const CATEGORIES_FETCHING = 'CATEGORIES_FETCHING';
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';
export const CATEGORIES_FETCH_ERROR = 'CATEGORIES_FETCH_ERROR';

const categoriesFetchKey = () => 'categories';

export const categoriesFetch = () => (dispatch, getState) => {
  const { loading } = getState();
  const key = categoriesFetchKey();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`categories`)
    .then((response) => {
      dispatch({
        type: CATEGORIES_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CATEGORIES_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: CATEGORIES_FETCHING,
    payload: { key, promise }
  });

  return promise;
};

categoriesFetch.getKey = categoriesFetchKey;
