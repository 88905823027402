import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgAccessCode(props) {
  return (
    <SvgContainer title="Access Code" viewBoxHeight={12} viewBoxWidth={15} height={10} width={15} {...props}>
      <g fill="none" stroke="#4A4A4A">
        <rect width="14.3" height="9.3" x=".35" y=".35" strokeWidth=".7" rx="2" />
        <path d="M3 5.313l.565.565a.22.22 0 010-.313.22.22 0 01.313 0l-.525-.525-.04-.04.565-.565a.22.22 0 01-.313 0 .22.22 0 010-.313l-.525.525-.04.04-.565-.565a.22.22 0 010 .313.22.22 0 01-.313 0l.525.525.04.04-.565.565a.22.22 0 01.313 0 .22.22 0 010 .313l.525-.525.04-.04zm3 0l.565.565a.22.22 0 010-.313.22.22 0 01.313 0l-.525-.525-.04-.04.565-.565a.22.22 0 01-.313 0 .22.22 0 010-.313l-.525.525-.04.04-.565-.565a.22.22 0 010 .313.22.22 0 01-.313 0l.525.525.04.04-.565.565a.22.22 0 01.313 0 .22.22 0 010 .313l.525-.525.04-.04zm3 0l.565.565a.22.22 0 010-.313.22.22 0 01.313 0l-.525-.525-.04-.04.565-.565a.22.22 0 01-.313 0 .22.22 0 010-.313l-.525.525-.04.04-.565-.565a.22.22 0 010 .313.22.22 0 01-.313 0l.525.525.04.04-.565.565a.22.22 0 01.313 0 .22.22 0 010 .313l.525-.525.04-.04zm3 0l.565.565a.22.22 0 010-.313.22.22 0 01.313 0l-.525-.525-.04-.04.565-.565a.22.22 0 01-.313 0 .22.22 0 010-.313l-.525.525-.04.04-.565-.565a.22.22 0 010 .313.22.22 0 01-.313 0l.525.525.04.04-.565.565a.22.22 0 01.313 0 .22.22 0 010 .313l.525-.525.04-.04z" fill="#4A4A4A" />
      </g>
    </SvgContainer>
  );
}
