import React from 'react';
import PropTypes from "prop-types";

import PageSection from './PageSection';

function SubjectList({ subjects, questionBankName }) {
  return (
    <PageSection section="subject-list-section">
      <h2 className="storefront-secondary-title text-lg-center mb-5">{questionBankName} Board Review Topics Covered:</h2>
      <div className="subjects-container">
        {subjects.map(item => (
          <li key={item.name} className="pb-2"><span className="mr-3">&#8226;</span>{item.name}</li>
        ))}
      </div>
    </PageSection>
  );
}

SubjectList.propTypes = {
  questionBankName: PropTypes.string
};

export default SubjectList;
