import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgDownCaret5(props) {
  return (
    <SvgContainer title="Down Caret Icon" viewBoxHeight={12} viewBoxWidth={12} {...props}>
      <path d="M1 1L8 8L15 1" stroke="#1B3950" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgContainer>
  );
}
