import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgPassRates(props) {
  return (
    <SvgContainer title="Pass Rates Icon" {...props}>
      <defs>
        <style>{".Pass-Rates_svg__a{fill:#231f20}"}</style>
      </defs>
      <path
        className="Pass-Rates_svg__a"
        d="M22 15.5a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 12a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5z"
      />
      <path
        className="Pass-Rates_svg__a"
        d="M24.11 19.188l-3.512 4.39-.65-1.301a.5.5 0 00-.895.447l1 2 .004.005a.493.493 0 00.13.162c.013.01.027.013.04.022s.028.015.042.023a.465.465 0 00.45.013l.005-.001a.494.494 0 00.16-.13l.007-.005 4-5a.5.5 0 00-.781-.625zM14 21.5H4.5v-2.293l8.229-8.229.786 3.143c.002.008.01.012.012.02a.487.487 0 00.12.213c.01.01.024.014.035.023a.438.438 0 00.045.03.46.46 0 00.394.078c.011-.003.02-.01.03-.014a.703.703 0 00.174-.099c.009-.007.02-.01.028-.018L23.5 5.207V8a.5.5 0 001 0V4a.502.502 0 00-.5-.5h-4a.5.5 0 000 1h2.793l-8.522 8.522-.786-3.143c-.003-.012-.01-.02-.015-.031a.477.477 0 00-.043-.091.486.486 0 00-.054-.081c-.008-.01-.01-.02-.02-.03a.458.458 0 00-.051-.034.493.493 0 00-.082-.054.608.608 0 00-.183-.05.487.487 0 00-.1.004.473.473 0 00-.058.003c-.011.003-.02.01-.03.014a.487.487 0 00-.091.044.48.48 0 00-.083.055c-.009.007-.02.01-.028.019L4.5 17.793V4a.5.5 0 00-1 0v18a.5.5 0 00.5.5h10a.5.5 0 000-1z"
      />
    </SvgContainer>
  );
}
