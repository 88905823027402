import * as React from "react";
import { withSvgContainer } from './SvgContainer';

function SvgBarGraph() {
  return (
    <path d="M11.5 25h3a1 1 0 001-1V5a1 1 0 00-1-1h-3a1 1 0 00-1 1v19a1 1 0 001 1zm0-20h3v19h.001l-.001.5V24h-3zM5.5 25h3a1 1 0 001-1V11a1 1 0 00-1-1h-3a1 1 0 00-1 1v13a1 1 0 001 1zm0-14h3v13h.001l-.001.5V24h-3zM17.5 25h3a1 1 0 001-1V11a1 1 0 00-1-1h-3a1 1 0 00-1 1v13a1 1 0 001 1zm0-14h3v13h.001l-.001.5V24h-3zM26.5 4h-3a1 1 0 00-1 1v19a1 1 0 001 1h3a1 1 0 001-1V5a1 1 0 00-1-1zm0 20.5V24h-3V5h3v19h.001zM27 27H5a.5.5 0 000 1h22a.5.5 0 000-1z" />
  );
}

export default withSvgContainer(SvgBarGraph, 'Bar Graph Icon');
