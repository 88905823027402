import { useState, useEffect } from 'react';

export const useMediaQuery = (breakpoint = 'xs') => {
  const breakpoints = {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
  };
  const [matches, setMatches] = useState(false);
  const handleChange = e => setMatches(e.matches);
  useEffect(() => {
    const m = window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`);
    setMatches(m.matches);
    m.addEventListener('change', handleChange);

    return () => {
      m.removeEventListener('change', handleChange);
    };
  }, []);

  return matches;
};
