import api from 'helpers/api';


export const CME_COACH_SEARCHING = 'CME_COACH_SEARCHING';
export const CME_COACH_SEARCHED = 'CME_COACH_SEARCHED';
const cmeCoachSearchKey = () => 'cme-coach/searching';
export const cmeCoachSearch = (selectedSpecialtyId, selectedStateId) => (dispatch, getState) => {
  const key = cmeCoachSearchKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const requestParams = [];
  if (selectedSpecialtyId) requestParams.push(`specialty_id=${selectedSpecialtyId}`);
  if (selectedStateId) requestParams.push(`state=${selectedStateId}`);

  const promise = api.get(`cme-coach/search?${requestParams.join('&')}`)
    .then((response) => {
      dispatch({
        type: CME_COACH_SEARCHED,
        payload: { key, ...response }
      });
      return true;
    }).catch((error) => {
      dispatch({
        type: CME_COACH_SEARCHED,
        payload: { key }
      });
      throw error;
    });

  dispatch({
    type: CME_COACH_SEARCHING,
    payload: {
      key,
      selectedSpecialtyId,
      selectedStateId,
      promise,
    }
  });

  return promise;
};
cmeCoachSearch.getKey = cmeCoachSearchKey;


export const CME_COACH_SENDING_EMAIL = 'CME_COACH_SENDING_EMAIL';
export const CME_COACH_SENT_EMAIL = 'CME_COACH_SENT_EMAIL';
const getCmeSendEmailKey = () => `cme-coach/send-email`;
export const cmeCoachSendEmail = email => (dispatch, getState) => {
  const { cmeCoach, loading } = getState();

  const key = getCmeSendEmailKey();
  if (loading.has(key)) return loading.get(key);

  dispatch({ type: CME_COACH_SENDING_EMAIL });

  const promise = api.post(`cme-coach/send-email`, {
    email,
    specialty_id: cmeCoach.get('searchedSpecialtyId'),
    state: cmeCoach.get('searchedStateId')
  })
    .then((response) => {
      dispatch({
        type: CME_COACH_SENT_EMAIL,
        payload: { sendEmailStatus: true, key }
      });
      return true;
    })
    .catch((error) => {
      dispatch({
        type: CME_COACH_SENT_EMAIL,
        payload: { sendEmailStatus: false, key }
      });
      throw error;
    });

  dispatch({ type: CME_COACH_SENDING_EMAIL, payload: { key, promise } });
  return promise;
};
cmeCoachSendEmail.getKey = getCmeSendEmailKey;
