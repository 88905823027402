import { Duration, DateTime } from 'luxon';

export const utcToDateStr = utc => (utc ? new Date(utc).toISOString().slice(0, 10) : '');

export const getTimeFromSeconds = seconds => Duration.fromObject({ seconds: seconds }).shiftTo('hours', 'minutes', 'seconds').normalize().toObject();

// This could be useful if you want to extend Luxon formatting, just return a duration object
export const getDurationFromSeconds = seconds => Duration.fromObject({ seconds: seconds });

/**
 * Comparing two dates
 * @param {Integer} date1 - utc
 * @param {Integer} date2 - utc (optional, current date will be the default value)
 * @return {Boolean} "true" if date1 is greater than or equal to date2 else "false"
 */
export const compareDates = (date1, date2 = DateTime.now().ts) => (date1 >= date2);

export const msToTimeString = (ms) => {
  let hours = Math.floor(ms / 1000 / 60 / 60);
  let minutes = Math.floor(ms / 1000 / 60) % 60;
  let seconds = Math.floor(ms / 1000) % 60;
  if (seconds < 10) seconds = `0${seconds}`;
  if (hours > 0 && minutes < 10) minutes = `0${minutes}`;
  hours = hours > 0 ? `${hours}:` : '';
  return `${hours}${minutes}:${seconds}`;
};

export const weeksFromNowTo = (targetDate) => {
  const now = DateTime.now().ts;
  const targetTimestamp = new Date(targetDate).getTime();
  const timeDiff = targetTimestamp - now;
  const weeksDiff = timeDiff / (1000 * 60 * 60 * 24 * 7);
  return weeksDiff.toFixed(2);
};

export const transformToDateTime = (date) => {
  if (date === null) return null;
  return typeof (date) === 'number' ? DateTime.fromMillis(date) : DateTime.fromISO(date);
};


/* hasBeen helper functions take a UTC timestamp integer and return a boolean */
export const hasBeenNWeeksSince = (timestamp, weeks) => new Date().getTime() - timestamp > 1000 * 60 * 60 * 24 * 7 * weeks;

export const hasBeenAWeekSince = timestamp => hasBeenNWeeksSince(timestamp, 1);

export const hasBeenNDaysSince = (timestamp, days) => new Date().getTime() - timestamp > 1000 * 60 * 60 * 24 * days;

export const hasBeenADaySince = timestamp => hasBeenNDaysSince(timestamp, 1);

export const hasBeenNHoursSince = (timestamp, hours) => new Date().getTime() - timestamp > 1000 * 60 * 60 * hours;

export const hasBeenAnHourSince = timestamp => hasBeenNHoursSince(timestamp, 1);

export const hasBeenNMinutesSince = (timestamp, minutes) => new Date().getTime() - timestamp > 1000 * 60 * minutes;

export const hasBeenAMinuteSince = timestamp => hasBeenNMinutesSince(timestamp, 1);
/* ----- */


/* get server time */
let timeDifference = 0;

export const setTimeDifference = (newTimeDifference) => {
  timeDifference = newTimeDifference;
};

export const getServerDateTime = () => DateTime.now().plus({ milliseconds: timeDifference });

export const getServerTimeNow = () => getServerDateTime().toJSDate().getTime();
/* ----- */
