import QuizQuestionStateHelper from 'helpers/QuizQuestionStateHelper';
import Response from 'factories/Response';

import {
  QUIZ_CREATING,
  QUIZ_UNLOAD_TAKE,
  QUIZ_FETCHED_QUESTIONS,
  QUIZ_SUBMITTED,
  QUIZ_FETCHED_TAKE,
  QUIZ_RESPONSES_FETCHED,
  QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES
} from 'actions/quizzes';

import {
  RESPONSE_SET,
  RESPONSE_EVALUATE_LOCAL,
  RESPONSE_SYNCED,
  RESPONSE_EVALUATED,
  RESPONSE_TIME_UPDATED
} from 'actions/responses';

import {
  OFFLINE_QUIZ_LOADED,
} from 'actions/offline';
import {
  ASSIGNMENT_RESPONSES_FETCHED
} from 'actions/b2b/assignments';

export const stateHelper = new QuizQuestionStateHelper(Response);
export const { initialState } = stateHelper;
const reducers = {};

reducers[QUIZ_FETCHED_TAKE] = stateHelper.set;
reducers[QUIZ_RESPONSES_FETCHED] = stateHelper.set;
reducers[QUIZ_UNLOAD_QUESTIONS_AND_RESPONSES] = (state, payload) => {
  const { quizId } = payload;
  return state.delete(quizId);
};
reducers[QUIZ_FETCHED_QUESTIONS] = stateHelper.set;
reducers[QUIZ_SUBMITTED] = stateHelper.set;

reducers[QUIZ_UNLOAD_TAKE] = stateHelper.reset;
reducers[QUIZ_CREATING] = stateHelper.reset;
reducers[OFFLINE_QUIZ_LOADED] = function responseLoadOffline(state, payload) {
  const {
    quizId,
    responses
  } = payload;
  return state.setIn([quizId], responses);
};

reducers[RESPONSE_SYNCED] = stateHelper.set;
reducers[RESPONSE_EVALUATED] = stateHelper.set;
reducers[RESPONSE_TIME_UPDATED] = function responseTimeUpdated(state, payload) {
  const { quizId, questionId } = payload;
  return state.setIn([quizId, questionId, 'ms_spent'], payload.data.attributes.ms_spent);
};


reducers[RESPONSE_SET] = function responseSet(state, payload) {
  const {
    quizId,
    questionId,
    response
  } = payload;

  return state.setIn([quizId, questionId], response);
};

reducers[RESPONSE_EVALUATE_LOCAL] = function responseEvaluateLocal(state, payload) {
  const {
    quizId,
    questionId,
    correct
  } = payload;
  return state.setIn([quizId, questionId, 'correct'], correct);
};

reducers[ASSIGNMENT_RESPONSES_FETCHED] = function assignmentResponsesPerformance(state, payload) {
  return state.withMutations((newState) => {
    payload.list.forEach((assignmentResponses) => {
      newState.set(assignmentResponses.id, new Response({
        id: assignmentResponses.id,
        attributes: {
          status: assignmentResponses.status,
          time: assignmentResponses.time,
          potential_score: parseInt(assignmentResponses.potential_score),
          actual_score: parseInt(assignmentResponses.actual_score),
        },
        relationships: {
          user: {
            data: {
              id: assignmentResponses.user_id
            }
          },
          quiz: {
            data: {
              id: assignmentResponses.quiz_id
            }
          },
          question: {
            data: {
              id: assignmentResponses.question_id
            }
          }
        }
      }));
    });
  });
};

export default stateHelper.createReducer(reducers);
