import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgSearch(props) {
  return (
    <SvgContainer title="Search Icon" {...props}>
      <path d="M26.354 25.646l-5.156-5.155a9.034 9.034 0 10-.707.707l5.156 5.156a.5.5 0 00.707-.707zM6.5 14.5a8 8 0 118 8 8.01 8.01 0 01-8-8z" />
    </SvgContainer>
  );
}
