import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgImageQuestion(props) {
  return (
    <SvgContainer title="Image Question Icon" {...props}>
      <path d="M10.463 10.25a2 2 0 10-2-2 2.002 2.002 0 002 2zm0-3a1 1 0 11-1 1 1.001 1.001 0 011-1z" />
      <path d="M23.463 14.913V4.25h-17v3h-3v19H17.89a6.497 6.497 0 105.574-11.337zm-16-9.663h15v9.522c-.141-.01-.282-.022-.426-.022a6.458 6.458 0 00-3.116.798l-3.51-7.022a.459.459 0 00-.036-.045.484.484 0 00-.063-.081.49.49 0 00-.078-.06.484.484 0 00-.047-.038c-.011-.005-.023-.004-.035-.01a.496.496 0 00-.096-.025.54.54 0 00-.282.026c-.011.005-.023.004-.034.01a28.57 28.57 0 01-.126.097.486.486 0 00-.062.08.465.465 0 00-.036.046l-6 12a.5.5 0 00.224.67.495.495 0 00.223.054.5.5 0 00.448-.277l3.552-7.105 2.819 5.636a6.178 6.178 0 00-.16 2.746H7.463zm8.811 13.004l-2.752-5.504 1.441-2.882 3.118 6.236a6.534 6.534 0 00-1.807 2.15zM4.464 8.25h2v15h9.39a6.487 6.487 0 001.07 2H4.464zm17.573 18.5a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5z" />
      <path d="M22.426 23.508a.908.908 0 00-.28-.183.881.881 0 00-.345-.067.895.895 0 00-.35.067.874.874 0 00-.277.183.835.835 0 00-.253.604.837.837 0 00.53.79.93.93 0 00.35.066.916.916 0 00.346-.066.882.882 0 00.28-.18.831.831 0 00.187-.272.853.853 0 00.067-.338.832.832 0 00-.067-.335.819.819 0 00-.188-.27zM23.521 18.015a1.862 1.862 0 00-.642-.358 2.699 2.699 0 00-.848-.125 3.204 3.204 0 00-.622.058 2.919 2.919 0 00-.538.16 3.182 3.182 0 00-.467.237 3.423 3.423 0 00-.403.29l.41.63a.344.344 0 00.32.186.374.374 0 00.19-.056 7.63 7.63 0 01.223-.122 1.85 1.85 0 01.305-.123 1.536 1.536 0 01.442-.055.783.783 0 01.543.178.61.61 0 01.197.478.824.824 0 01-.117.447 1.585 1.585 0 01-.288.34 3.391 3.391 0 01-.365.285 2.93 2.93 0 00-.352.28 1.145 1.145 0 00-.248.33.693.693 0 00-.05.428l.11.804h1.06l.14-.7a.561.561 0 01.162-.28 2.862 2.862 0 01.3-.252q.172-.127.365-.277a2.043 2.043 0 00.353-.355 1.827 1.827 0 00.265-.483 1.86 1.86 0 00.105-.657 1.8 1.8 0 00-.143-.728 1.568 1.568 0 00-.407-.56z" />
    </SvgContainer>
  );
}
