import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgNotes(props) {
  return (
    <SvgContainer title="Notes Icon" {...props}>
      <path d="M7.436 15h8a.5.5 0 100-1h-8a.5.5 0 100 1zM11.437 19h-4a.5.5 0 100 1h4a.5.5 0 100-1zM11.437 24h-4a.5.5 0 100 1h4a.5.5 0 100-1z" />
      <path d="M28.624 12.952l-2.586-2.586a1.504 1.504 0 00-2.121 0l-1.48 1.48-.5.5V7.5a1.502 1.502 0 00-1.5-1.5h-1.5V3.5a.5.5 0 00-1 0V6h-3V3.5a.5.5 0 00-1 0V6h-3V3.5a.5.5 0 00-1 0V6h-3V3.5a.5.5 0 10-1 0V6h-1.5a1.502 1.502 0 00-1.5 1.5v20a1.502 1.502 0 001.5 1.5h16a1.502 1.502 0 001.5-1.5v-5.74l4.5-4.5 2.187-2.186a1.5 1.5 0 000-2.122zM4.436 7h16a.5.5 0 01.5.5V10h-17V7.5a.5.5 0 01.5-.5zm16.5 20.5a.5.5 0 01-.5.5h-16a.5.5 0 01-.5-.5V11h17v2.347L14.084 20.2a.507.507 0 00-.147.354V24.5c0 .01.004.018.005.027s-.005.017-.005.027a.5.5 0 00.5.5h4a.505.505 0 00.354-.147l2.146-2.146zm-6-5.74l2.293 2.294h-2.293zm3.5 2.087l-3.292-3.293 7.292-7.293 3.293 3.293zm9.481-9.48l-1.48 1.48-3.293-3.293 1.48-1.48a.5.5 0 01.707 0l2.586 2.586a.5.5 0 010 .707z" />
    </SvgContainer>
  );
}
