import api, { selectedQuestionBankUrl } from 'helpers/api';


export const NOTE_SYNCING = 'NOTE_SYNCING';
export const NOTE_SYNCED = 'NOTE_SYNCED';
export const NOTE_SYNC_ERROR = 'NOTE_SYNC_ERROR';
const getNoteSyncKey = (quizId, questionId) => `${quizId}/notes/${questionId}`;
export const noteSync = (quizId, questionId, body) => (dispatch, getState) => {
  const key = getNoteSyncKey(quizId, questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}/note`, {
    note: { body }
  })
    .then((response) => {
      dispatch({
        type: NOTE_SYNCED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: NOTE_SYNC_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: NOTE_SYNCING,
    payload: { key, promise }
  });

  return promise;
};
noteSync.getKey = getNoteSyncKey;


export const NOTE_SYNC_OFFLINE = 'NOTE_SYNC_OFFLINE';
export const noteSyncOffline = (quizId, questionId, body) => (dispatch) => {
  dispatch({
    type: NOTE_SYNC_OFFLINE,
    payload: {
      quizId,
      questionId,
      body
    }
  });
};
