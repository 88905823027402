import api, {
  selectedQuestionBankUrl,
  currentUserUrl,
} from 'helpers/api';


export const QUESTION_LOADING = 'QUESTION_LOADING';
function questionLoading(key, promise, dispatch) {
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });
}

export const QUESTION_ERROR = 'QUESTION_ERROR';
function questionError(error, dispatch, key) {
  dispatch({
    type: QUESTION_ERROR,
    payload: { error, key }
  });
  throw error;
}

const questionActionCreator = (actionType, keyMethod, restAction = 'post', finalPath = '') => questionId => (dispatch, getState) => {
  const { loading } = getState();
  const key = keyMethod(questionId);

  if (loading.has(key)) return loading.get(key);
  const path = questionId ? `questions/${questionId}/${finalPath}` : finalPath;
  const promise = api[restAction](`${selectedQuestionBankUrl(getState())}/${path}`)
    .then((response) => {
      dispatch({
        type: actionType,
        payload: {
          key,
          ...(!!questionId && { questionId }),
          ...response
        }
      });
      return response;
    })
    .catch(error => questionError(error, dispatch, key));

  questionLoading(key, promise, dispatch);
  return promise;
};

export const SEARCH_QUESTIONS_FETCHED = 'SEARCH_QUESTIONS_FETCHED';
const getSearchQuestionsKey = () => 'questions/search';
export const searchQuestionsFetch = questionActionCreator(SEARCH_QUESTIONS_FETCHED, getSearchQuestionsKey, 'get', 'browse');
searchQuestionsFetch.getKey = getSearchQuestionsKey;

export const AVAILABLE_QUESTIONS_FETCHED = 'AVAILABLE_QUESTIONS_FETCHED';
const getQuestionAvailableKey = () => `question/available`;
export const availableQuestionsFetch = questionActionCreator(AVAILABLE_QUESTIONS_FETCHED, getQuestionAvailableKey, 'get', 'quizzes/available_questions');
availableQuestionsFetch.getKey = getSearchQuestionsKey;

export const QUESTION_MARKED = "QUESTION_MARKED";
const getMarkQuestionKey = questionId => `questions/mark/${questionId}`;
export const markQuestion = questionActionCreator(QUESTION_MARKED, getMarkQuestionKey, 'put', 'mark');
markQuestion.getKey = getMarkQuestionKey;

export const QUESTION_UNMARKED = "QUESTION_UNMARKED";
const getUnmarkQuestionKey = questionId => `questions/unmark/${questionId}`;
export const unmarkQuestion = questionActionCreator(QUESTION_UNMARKED, getUnmarkQuestionKey, 'put', 'unmark');
unmarkQuestion.getKey = getUnmarkQuestionKey;

export const QUESTION_PRELOADED = 'QUESTION_PRELOADED';
export const questionPreloaded = response => ({
  type: QUESTION_PRELOADED,
  payload: { key: 'preload', ...response }
});

export const QUESTION_FETCHED = 'QUESTION_FETCHED';
const getQuestionFetchKey = questionId => `questions/fetch/${questionId}`;
export const questionFetch = (quizId, questionId) => (dispatch, getState) => {
  const key = getQuestionFetchKey(questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = quizId ? api.get(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/questions/${questionId}`)
    : api.get(`${selectedQuestionBankUrl(getState())}/questions/${questionId}`);

  promise.then((response) => {
    dispatch({
      type: QUESTION_FETCHED,
      payload: { key, ...response }
    });
    return response;
  })
    .catch((error) => {
      dispatch({
        type: QUESTION_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionFetch.getKey = getQuestionFetchKey;

export const QUESTION_RESULTS_FETCHED = 'QUESTION_RESULTS_FETCHED';
const getQuestionResultsFetchKey = () => 'questions/results';
export const questionResultsFetch = questionsIds => (dispatch, getState) => {
  const key = getQuestionResultsFetchKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const searchQuery = id => `question_ids[]=${id}`;
  const promise = api.get(`${selectedQuestionBankUrl(getState())}/search_results?${questionsIds.map(searchQuery).join('&')}`)
    .then((response) => {
      dispatch({
        type: QUESTION_RESULTS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionResultsFetch.getKey = getQuestionResultsFetchKey;

export const QUESTION_SHARED = 'QUESTION_SHARED';
const getQuestionShareKey = questionId => `questions/share/${questionId}`;
export const questionShare = (questionId, email) => (dispatch, getState) => {
  const key = getQuestionShareKey(questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/questions/${questionId}/share`, {
    email
  })
    .then((response) => {
      dispatch({
        type: QUESTION_SHARED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionShare.getKey = getQuestionShareKey;

export const QUESTION_FEEDBACK_SUBMITTED = 'QUESTION_FEEDBACK_SUBMITTED';
const getQuestionFeedbackKey = questionId => `questions/feedback/${questionId}`;
export const questionFeedback = (questionId, content, feedbackType, mobileOS, mobileBuild, mobileVersion) => (dispatch, getState) => {
  const key = getQuestionFeedbackKey(questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/questions/${questionId}/feedback`, {
    feedback: {
      content,
      feedback_type: feedbackType,
      mobile_os: mobileOS,
      mobile_build: mobileBuild,
      mobile_version: mobileVersion
    }
  })
    .then((response) => {
      dispatch({
        type: QUESTION_FEEDBACK_SUBMITTED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });

  return promise;
};
questionFeedback.getKey = getQuestionFeedbackKey;

export const QUESTION_RATED = "QUESTION_RATED";
const rateQuestionKey = questionId => `questions/rate/${questionId}`;
export const rateQuestion = (questionId, stars, note = "") => (dispatch, getState) => {
  const key = rateQuestionKey(questionId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`${selectedQuestionBankUrl(getState())}/questions/${questionId}/rate`, {
    rating: { stars: stars, note: note }
  })
    .then((response) => {
      dispatch({
        type: QUESTION_RATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: QUESTION_ERROR,
        payload: { error, key }
      });
      throw error;
    });
  dispatch({
    type: QUESTION_LOADING,
    payload: { key, promise }
  });

  return promise;
};
rateQuestion.getKey = rateQuestionKey;


export const QUESTION_EXCERPTS_FETCHED = 'QUESTION_EXCERPTS_FETCHED';
export const QUESTION_EXCERPTS_FETCHING = 'QUESTION_EXCERPTS_FETCHING';
export const QUESTION_EXCERPTS_FETCH_ERROR = 'QUESTION_EXCERPTS_FETCH_ERROR';
const getQuestionExcerptsKey = questionBankId => `questionBanks/${questionBankId}/questions/excerpts_paginate`;
export const questionExcerptsFetch = questionBankId => (dispatch, getState) => {
  const key = getQuestionExcerptsKey(questionBankId);

  const promise = new Promise(async (resolve, reject) => {
    try {
      // this call gives us the total pages wrt 1000 obj per page
      const countResponse = await api.get(`${currentUserUrl(getState())}/question_banks/${questionBankId}/questions/excerpts_paginate`);
      // Get all paginated Responses from the API and dispatching
      let questions = [];
      for (let i = 0; i < countResponse.total_pages; i += 1) {
        questions = [...await api.get(`${currentUserUrl(getState())}/question_banks/${questionBankId}/questions/excerpts?page_no=${i + 1}`), ...questions];
      }

      dispatch({
        type: QUESTION_EXCERPTS_FETCHED,
        payload: { key, questionArrays: questions }
      });
      resolve(questions);
    } catch (error) {
      dispatch({
        type: QUESTION_EXCERPTS_FETCH_ERROR,
        payload: { error, key }
      });
      reject(error);
    }
  });
  dispatch({
    type: QUESTION_EXCERPTS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
questionExcerptsFetch.getKey = getQuestionExcerptsKey;
