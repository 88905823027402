import React from "react";
import SVGIcon from 'components/SVGIcon';

export default function HomeStats({ statsTextContent }) {
  const { trustedInstitutionsStats, practitionersTrainedStats, accreditedSpecialtiesStats } = statsTextContent;

  return (
    <div className="HomeStats home-section-wrapper">
      <div className="container">
        <div className="stats-wrapper row d-flex flex-wrap justify-content-md-center py-4 pt-md-5 pb-md-6">
          <div className="col-12 col-md-4 d-flex flex-column align-items-center mt-4 mt-md-5 pt-1 pt-md-0 pt-lg-1">
            <div className="d-flex align-items-center justify-content-md-center">
              <SVGIcon name="HomeStatTrustedInstitutions" ariaLabel="2,000 Medical & Healthcare Institutions Trust BoardVitals Board Prep & CME" className="mr-2" height={41} width={45} viewBoxHeight={41} viewBoxWidth={45} />
              <h2 className="storefront header-style mb-0 ml-4">{trustedInstitutionsStats || "2K+"}</h2>
            </div>
            <p className="storefront text-center mb-4 mb-md-6 mb-lg-5">Institutions Trust BoardVitals</p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column align-items-center mt-4 mt-md-5 pt-1 pt-md-0 pt-lg-1">
            <div className="d-flex align-items-center justify-content-md-center">
              <SVGIcon name="HomeStatPractitionersTrained" ariaLabel="1,500,000 Medical Practitioners & Healthcare Professionals Trained" className="mr-2" height={41} width={54} viewBoxHeight={41} viewBoxWidth={54} />
              <h2 className="storefront header-style mb-0 ml-4">{practitionersTrainedStats || "1.5M+"}</h2>
            </div>
            <p className="storefront text-center mb-4 mb-md-6 mb-lg-5">Practitioners Trained</p>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column align-items-center mt-4 mt-md-5 pt-1 pt-md-0 pt-lg-1">
            <div className="d-flex align-items-center justify-content-md-center">
              <SVGIcon name="HomeStatCME" ariaLabel="Over 35 Accredited Continuing Medical Education (CME) Specialties Online" className="mr-2" height={41} width={37} viewBoxHeight={41} viewBoxWidth={37} />
              <h2 className="storefront header-style mb-0 ml-4">{accreditedSpecialtiesStats || "35"}</h2>
            </div>
            <p className="storefront text-center mb-6 mb-md-6 mb-lg-5">Accredited CME Specialties</p>
          </div>
        </div>
      </div>
    </div>
  );
}
