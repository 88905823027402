import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  return (
    <SvgContainer title="Radio Unselected" width={21} height={21} viewBoxHeight={23} viewBoxWidth={23} {...props}>
      <circle cx="45.5" cy="425.5" r="10.5" transform="translate(-34 -414)" stroke="#767676" fill="none" fillRule="evenodd" />
    </SvgContainer>
  );
}
