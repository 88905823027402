import React from 'react';
import PropTypes from 'prop-types';
import parseMarkdown from 'helpers/parseMarkdown';
import SVGIcon from 'components/SVGIcon';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import PageSection from './PageSection';

const SubItem = ({
  content, questionBankName, activeQuestionCount, subItemKey
}) => {
  const parsedContent = parseMarkdown(content, { questionBankName, activeQuestionCount });
  // eslint-disable-next-line react/no-danger
  return (<li key={subItemKey} className="all-plans-sub-item"><div dangerouslySetInnerHTML={{ __html: parsedContent }} /></li>);
};

SubItem.propTypes = {
  content: PropTypes.string.isRequired,
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  subItemKey: PropTypes.number.isRequired
};

const Item = ({
  item, activeQuestionCount, questionBankName, itemKey
}) => {
  const { sub_items: subItems } = item;
  const hasSubItems = subItems.length > 0;
  return (
    <li key={itemKey}>
      <ul className={`${!hasSubItems ? 'd-none ' : ''}sub-list mb-5`}>
        {subItems.map(subItem => <SubItem key={subItem.id} subItemKey={subItem.id} content={subItem.formatted_content} activeQuestionCount={activeQuestionCount} questionBankName={questionBankName} />)}
      </ul>
    </li>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  questionBankName: PropTypes.string.isRequired,
  itemKey: PropTypes.number.isRequired
};

const ImageContainer = ({
  image, showMobileIcons, small = false, medium = false, large = false
}) => {
  const {
    original_url: originalUrl, alt_text: altText
  } = image;
  const buttonDimensions = {};

  if (small) {
    buttonDimensions.width = 144;
    buttonDimensions.height = 43;
  } else if (medium) {
    buttonDimensions.width = 206;
    buttonDimensions.height = 62;
  } else if (large) {
    buttonDimensions.width = 162;
    buttonDimensions.height = 47;
  }

  return (
    <div className="column mb-md-6 mb-sm-6 mb-xs-6 mb-6 mb-lg-0 mb-xl-0 mt-4 align-self-xs-center">
      <img loading="lazy" className="row" alt={altText} src={originalUrl} height={small ? 388 : 568} width={small ? 291 : 426} />
      <div className={`row ${!showMobileIcons ? 'd-none' : 'd-flex'} align-items-center justify-content-center`}>
        <a className="mr-3" href="https://apps.apple.com/us/app/boardvitals-medical-exam-prep/id1484690932" target="_blank" rel="noopener noreferrer">
          <img loading="lazy" width={`${buttonDimensions.width}`} height={`${buttonDimensions.height}`} alt="Download on the App Store" src="/images/apple-ios-boardvitals-app.svg" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.boardvitals&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
          <img loading="lazy" width={`${buttonDimensions.width}`} height={`${buttonDimensions.height + (buttonDimensions.height / 2)}`} alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
        </a>
      </div>
    </div>
  );
};

ImageContainer.propTypes = {
  image: PropTypes.object.isRequired,
  showMobileIcons: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool
};

const ListContainer = ({
  header,
  sectionText,
  items,
  questionBankName,
  activeQuestionCount
}) => (
  <div className="px-xs-4 mr-xl-auto mr-lg-auto  mt-xs-3 mt-sm-3 mt-md-3 ml-xs-6">
    <div className="mb-6 header">
      <h3 className="storefront-underlined-title section-title mb-6">{parseMarkdown(header, { questionBankName, activeQuestionCount }).toUpperCase()}</h3>
      <h2 className="storefront-secondary-title mt-6">{parseMarkdown(sectionText, { questionBankName, activeQuestionCount })}</h2>
    </div>
    <div className="primary-list-header row">
      <ul className="primary-list mt-2">
        {items.map(item => (
          <div key={item.id}>
            <div className="row">
              <SVGIcon className="mt-3 mr-3" name="AllPlansCheckmark" width={19.75} height={19.75} viewBoxWidth={19.75} viewBoxHeight={19.75} />
              <b className="all-plans-item">{parseMarkdown(item.content, { questionBankName, activeQuestionCount })}</b>
            </div>
            <div>
              <Item
                key={item.id}
                itemKey={item.id}
                item={item}
                questionBankName={questionBankName}
                activeQuestionCount={activeQuestionCount}
              />
            </div>
          </div>
        ))}
      </ul>
    </div>
  </div>
);

ListContainer.propTypes = {
  header: PropTypes.string.isRequired,
  sectionText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired
};

const AllPlans = ({
  questionBankName, activeQuestionCount, allPlansSection
}) => {
  const {
    image, items, header, section_text: sectionText, show_mobile_icons: showMobileIcons
  } = allPlansSection;

  const { width } = useWindowDimensions();

  const isMobile = width <= breakpoints.sm;
  const isMd = width > breakpoints.sm && width <= 820;

  return (
    <PageSection section="all-plans-section">
      <div className="row d-flex justify-content-xl-start justify-content-lg-start align-items-center flex-xl-row-reverse flex-lg-row-reverse justify-content-center">
        <ImageContainer
          image={image}
          showMobileIcons={showMobileIcons}
          small={isMobile}
          medium={isMd}
          large={!isMobile && !isMd}
        />
        <ListContainer
          items={items}
          header={header}
          sectionText={sectionText}
          questionBankName={questionBankName}
          activeQuestionCount={activeQuestionCount}
        />
      </div>
    </PageSection>
  );
};
export default AllPlans;

AllPlans.propTypes = {
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired,
  allPlansSection: PropTypes.object.isRequired
};
