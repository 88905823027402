import React from 'react';
import PropTypes from 'prop-types';
import parseMarkdown from 'helpers/parseMarkdown';
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";
import PageSection from './PageSection';

const StaticImage = ({ staticImageSection, questionBankName, activeQuestionCount }) => {
  const { width: screenWidth } = useWindowDimensions();
  const isMobile = screenWidth <= breakpoints.sm;
  const isTablet = screenWidth > breakpoints.sm && screenWidth <= 1200;

  const {
    content, image, is_flipped: isFlipped, section_title: sectionTitle, title_text: titleText
  } = staticImageSection;

  const imageDimensions = {};
  if (isMobile) {
    imageDimensions.width = 348;
    imageDimensions.height = 262;
  } else if (isTablet) {
    imageDimensions.width = 521;
    imageDimensions.height = 393;
  } else {
    imageDimensions.width = 567;
    imageDimensions.height = 426;
  }

  return (

    <PageSection section="static-image">
      <div className={`row d-flex justify-content-between align-items-center ${isFlipped ? 'flex-xl-row-reverse flex-lg-row-reverse' : ''} p${isFlipped ? 'r' : 'l'}-lg-5`}>
        <div className="col-lg-5 col-xl-5 col-md-12 d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-xs-center">
          <img loading="lazy" alt={image.alt_text} src={image.original_url} width={imageDimensions.width} height={imageDimensions.height} />
        </div>
        <div className="col-lg-5 col-xl-5 col-md-12   d-flex justify-content-md-center justify-content-lg-center justify-content-xl-start">
          <div className="text-container">
            <h3 className="storefront-underlined-title section-title mb-6">{parseMarkdown(sectionTitle, { questionBankName, activeQuestionCount }).toUpperCase()}</h3>
            <h2 className="my-6 pt-6 storefront-secondary-title">{parseMarkdown(titleText, { questionBankName, activeQuestionCount })}</h2>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="mt-6 pt-6" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

StaticImage.propTypes = {
  staticImageSection: PropTypes.shape({
    content: PropTypes.string,
    image_alt_text: PropTypes.string,
    image: PropTypes.object,
    is_flipped: PropTypes.bool,
    section_title: PropTypes.string,
    title_text: PropTypes.string
  }),
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number.isRequired
};

export default StaticImage;
