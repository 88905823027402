import { Set, Map } from 'immutable';
// remotedev-serialize is the newer package, but it converts our integer keys into strings which breaks everything
// import Serialize from 'remotedev-serialize';
import transit from 'transit-immutable-js';

const whitelistedQuizAttributes = [
  'id',
  'question_bank_id',
  'status',
  'type',
  'archived',
  'total_questions',
  'assignment_id',
  'name',
  'created_at',
  'seconds_per_question',
  'seconds_remaining',
  'updated_at',
  'started_at',
  'end_time',
  'offline_device_uuid',
  'percentile',
  'difficulty_level'
];

// the transform interface that redux-persist is expecting
export default {
  in: (state, key) => {
    // anything start with `_` with not require immutable
    if (key.startsWith('_')) {
      return JSON.stringify(state);
    }

    let filteredState = state;

    if (key === 'users') {
      // Obfuscate User's personally identifying information
      filteredState.keySeq().forEach((userId) => {
        filteredState = filteredState.mergeIn([userId], {
          email: '',
          first_name: '',
          last_name: ''
        });
      });
    } else if (key === 'quizzes') {
      // Remove all Quiz attributes except those we've whitelisted
      filteredState = filteredState.withMutations((newState) => {
        filteredState.keySeq().forEach((quizId) => {
          const filteredQuiz = filteredState.get(quizId).filter((v, k) => whitelistedQuizAttributes.includes(k));
          newState.set(quizId, filteredQuiz);
        });
      });
    }

    return transit.toJSON(filteredState);
  },
  out: (raw, key) => {
    // anything start with `_` with not require immutable
    if (key.startsWith('_')) {
      return JSON.parse(raw);
    }

    // Parse JSON back into ImmutableJS objects
    let newState = transit.fromJSON(raw);

    // Reset loading values within each slice of state
    if (key === 'session') {
      newState = newState.set('loading', 'initialized').set('is_logged_in', false);
    } else if (typeof newState.get('loading') === 'boolean') {
      newState = newState.set('loading', false);
    } else if (Set.isSet(newState.get('loading'))) {
      newState = newState.set('loading', new Set());
    }

    if (key === 'feedback' && Map.isMap(newState.get('type'))) {
      newState = newState.set('type', null);
    }

    return newState;
  }
};
