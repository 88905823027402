import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import Key from "../key/Key";
import Decimal from "../utils/decimal-custom";

const Keypad = ({
  keys: rows,
  handleClick,
  currentOperation,
  trigUnit,
  memory,
  mode
}) => {
  const rowClassNames = classNames('keypad-row', { 'mt-2': mode === 'fred' });
  return (
    <section className="keypad">
      {rows.map((keys, index) => (
        <div className={rowClassNames} key={`row${index}`}>
          {keys.map(keyProps => (
            <Key
              key={keyProps.id}
              handleClick={handleClick}
              active={
              currentOperation === keyProps.id
              || (keyProps.id === 'memoryRecall' && memory !== null)
            }
              trigUnit={trigUnit}
              {...keyProps}
            />
          ))}
        </div>
      ))}
    </section>
  );
};

Keypad.propTypes = {
  keys: PropTypes.array,
  currentOperation: PropTypes.string,
  memory: PropTypes.instanceOf(Decimal),
  trigUnit: PropTypes.oneOf(['deg', 'rad']),
  handleClick: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['basic', 'scientific', 'fred'])
};

export default Keypad;
