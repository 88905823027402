import api, { selectedOrganizationUrl, } from 'helpers/api';
import { createApiRequestAction } from 'helpers/createAction';

export const REPORT_CREATING = 'REPORT_CREATING';
export const REPORT_CREATED = 'REPORT_CREATED';
export const REPORT_CREATE_ERROR = 'REPORT_CREATE_ERROR';
export const reportCreate = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/usage_reports`,
  request: (getState, start, end, questionBankId) =>
    api.post(`${selectedOrganizationUrl(getState())}/usage_reports`, { start_time: start, end_time: end, question_bank_id: questionBankId }),
  loadingConstant: REPORT_CREATING,
  loadedConstant: REPORT_CREATED,
  errorConstant: REPORT_CREATE_ERROR
});

export const MEMBER_GROUP_REPORT_CREATING = 'MEMBER_GROUP_REPORT_CREATING';
export const MEMBER_GROUP_REPORT_CREATED = 'MEMBER_GROUP_REPORT_CREATED';
export const MEMBER_GROUP_REPORT_CREATE_ERROR = 'MEMBER_GROUP_REPORT_CREATE_ERROR';
export const memberGroupReportCreate = createApiRequestAction({
  getKey: (organizationId, userId, classId, questionBankId,) => `organizations/${organizationId}/user/${userId}/class/${classId}/question_bank/${questionBankId}`,
  request: (getState, userId, classId, questionBankId, start, end) =>
    api.post(`${selectedOrganizationUrl(getState())}/usage_reports/user/${userId}/class/${classId}/question_bank/${questionBankId}`, { start_time: start, end_time: end, question_bank_id: questionBankId }),
  loadingConstant: MEMBER_GROUP_REPORT_CREATING,
  loadedConstant: MEMBER_GROUP_REPORT_CREATED,
  errorConstant: MEMBER_GROUP_REPORT_CREATE_ERROR
});

export const REPORTS_FETCHING = 'REPORTS_FETCHING';
export const REPORTS_FETCHED = 'REPORTS_FETCHED';
export const REPORTS_FETCHING_ERROR = 'REPORTS_FETCHING_ERROR';

export const reportsFetch = createApiRequestAction({
  getKey: organizationId => `organizations/${organizationId}/usage_reports`,
  request: (getState, organizationId) => api.get(`${selectedOrganizationUrl(getState())}/usage_reports`),
  loadingConstant: REPORTS_FETCHING,
  loadedConstant: REPORTS_FETCHED,
  errorConstant: REPORTS_FETCHING_ERROR,
});

export const REPORT_FETCHING = 'REPORT_FETCHING';
export const REPORT_FETCHED = 'REPORT_FETCHED';
export const REPORT_FETCHING_ERROR = 'REPORT_FETCHING_ERROR';

export const reportFetch = createApiRequestAction({
  getKey: reportId => `/usage_reports/${reportId}`,
  request: (getState, reportId) => api.get(`${selectedOrganizationUrl(getState())}/usage_reports/${reportId}`),
  loadingConstant: REPORT_FETCHING,
  loadedConstant: REPORT_FETCHED,
  errorConstant: REPORT_FETCHING_ERROR,
});

// class reports are tied to the main organization user reports member group reports are run on the classes themselves
export const CLASS_REPORT_FETCHING = 'CLASS_REPORT_FETCHING';
export const CLASS_REPORT_FETCHED = 'CLASS_REPORT_FETCHED';
export const CLASS_REPORT_ERROR = 'CLASS_REPORT_ERROR';
export const CLASS_REPORTS_UNLOAD = 'CLASS_REPORTS_UNLOAD';
export const classReport = createApiRequestAction({
  getKey: (organizationId, reportId, questionBankId, classId) => `organizations/${organizationId}/usage_reports/question_bank/${questionBankId}/${reportId}/class_report/${classId}`,
  request: (getState, reportId, questionBankId, classId) =>
    api.get(`${selectedOrganizationUrl(getState())}/usage_reports/${reportId}/question_bank/${questionBankId}/class/${classId}`),
  loadingConstant: CLASS_REPORT_FETCHING,
  loadedConstant: CLASS_REPORT_FETCHED,
  errorConstant: CLASS_REPORT_ERROR
});


export const REPORT_USERS_FETCHING = 'REPORT_USERS_FETCHING';
export const REPORT_USERS_FETCHED = 'REPORT_USERS_FETCHED';
export const REPORT_USERS_FETCH_ERROR = 'REPORT_USERS_FETCH_ERROR';
export const reportUsersFetch = createApiRequestAction({
  getKey: (reportId, questionBankId) => `organizations/report_users/${reportId}/question_bank/${questionBankId}`,
  request: (getState, reportId, questionBankId) => api.get(`${selectedOrganizationUrl(getState())}/report_users/${reportId}/question_bank/${questionBankId}`),
  loadingConstant: REPORT_USERS_FETCHING,
  loadedConstant: REPORT_USERS_FETCHED,
  errorConstant: REPORT_USERS_FETCH_ERROR,
});

export const MEMBER_GROUP_REPORTS_FETCHING = 'MEMBER_GROUP_REPORTS_FETCHING';
export const MEMBER_GROUP_REPORTS_FETCHED = 'MEMBER_GROUP_REPORTS_FETCHED';
export const MEMBER_GROUP_REPORTS_FETCH_ERROR = 'MEMBER_GROUP_REPORTS_FETCH_ERROR';
export const memberGroupReportsFetch = createApiRequestAction({
  getKey: (organizationId, userId, classId) => `organizations/${organizationId}/user/${userId}/class/${classId}`,
  request: (getState, organizationId, userId, classId) => api.get(`${selectedOrganizationUrl(getState())}/usage_reports/user/${userId}/class/${classId}`),
  loadingConstant: MEMBER_GROUP_REPORTS_FETCHING,
  loadedConstant: MEMBER_GROUP_REPORTS_FETCHED,
  errorConstant: MEMBER_GROUP_REPORTS_FETCH_ERROR,
});
