import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueInfo(props) {
  return (
    <SvgContainer title="X Icon" {...props}>
      <path fill="#fff" d="M55.86 32a24.25 24.25 0 1 0-24.24 24.25A24.25 24.25 0 0 0 55.86 32ZM31.62 51.94A19.94 19.94 0 1 1 51.55 32a19.94 19.94 0 0 1-19.93 19.94Z" />
      <path fill="#fff" d="M31.62 22.73a2.15 2.15 0 1 0-2.15-2.14 2.14 2.14 0 0 0 2.15 2.14ZM35.54 42.39c-.69-.24-1.54-.94-1.54-2.93V26.35a.34.34 0 0 0-.33-.35h-4.85a1.29 1.29 0 0 0-1.24 1.33 1.34 1.34 0 0 0 .88 1.28c.69.24 1.54.94 1.54 2.93v7.92c0 2-.85 2.69-1.54 2.93a1.34 1.34 0 0 0-.88 1.28A1.29 1.29 0 0 0 28.82 45h6.37a1.29 1.29 0 0 0 1.23-1.33 1.34 1.34 0 0 0-.88-1.28Z" />
    </SvgContainer>
  );
}
