import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from "components/SVGIcon";
import italicize from 'helpers/italicize';

// TODO confirm specialty filters will be permanently removed
export default function PlpMobileFilters ({
  setShowMobileFilters,
  handleFilterChanges,
  toggleFilter,
  showCreditTypes,
  possibleCreditTypes,
  creditTypesSelected,
  // showSpecialties,
  // specialties,
  // specialtiesSelected,
  showCreditAmount,
  creditRanges,
  numberOfCreditsSelected,
  clearFilter,
  setRecentlyClosed,
  filterCount
}) {
  const closeAndScrollToFilters = () => {
    setRecentlyClosed(true);
    setShowMobileFilters(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="mobile-filters-container" className="mobile-filters-container">
      <div className="mobile-inner-wrapper">
        <div className="global-filters d-flex justify-content-between align-items-center my-5">
          <span
            role="button"
            tabIndex="0"
            onClick={() => closeAndScrollToFilters()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                closeAndScrollToFilters();
              }
            }}
          >
            <SVGIcon
              name="X"
              width={24}
              height={24}
              aria-hidden="true"
            />
          </span>
          <span className="mobile-filters-title">
            Filter
          </span>
          <span
            className="storefront-link"
            role="button"
            tabIndex="0"
            onClick={() => clearFilter("all")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                clearFilter("all");
              }
            }}
          >
            Clear
          </span>
        </div>
        <hr />
        <div className="credit-type-actions d-flex justify-content-between align-items-center">
          <span className="filter-box d-flex justify-content-between align-items-center">
            <span>Credit Type</span>
            <span
              className="svg-container"
              role="button"
              tabIndex="0"
              onClick={() => toggleFilter("credit-type")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  toggleFilter("credit-type");
                }
              }}
            >
              <SVGIcon
                name={showCreditTypes ? "Minus" : "Plus"}
                width={24}
                height={24}
                aria-hidden="true"
              />
            </span>
          </span>
          <span
            className="storefront-link"
            role="button"
            tabIndex="0"
            onClick={() => clearFilter("credit-type")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                clearFilter("credit-type");
              }
            }}
          >
            Clear
          </span>
        </div>
        <div className={`credit-type-list ${showCreditTypes ? "" : "d-none"}`}>
          { possibleCreditTypes.map((creditType, i) => (
            <div key={i} className="credit-type-option">
              <input
                type="checkbox"
                name={creditType}
                checked={creditTypesSelected.includes(creditType)}
                onChange={e => handleFilterChanges(e, "credit-type")}
              />
              <label htmlFor={creditType} className="storefront-body-text ml-2">{creditType.includes("AMA PRA") ? italicize(creditType) : creditType} ({filterCount("creditType", creditType)})</label>
            </div>
          ))}
        </div>
        {/* <div className="specialties-actions d-flex justify-content-between align-items-center">
          <span className="filter-box d-flex justify-content-between align-items-center">
            <span>Specialties</span>
            <span
              className="svg-container"
              role="button"
              tabIndex="0"
              onClick={() => toggleFilter("specialties")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  toggleFilter("specialties");
                }
              }}
            >
              <SVGIcon
                name={showSpecialties ? "Minus" : "Plus"}
                width={24}
                height={24}
                aria-hidden="true"
              />
            </span>
          </span>
          <span
            className="storefront-link"
            role="button"
            tabIndex="0"
            onClick={() => clearFilter("specialties")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                clearFilter("specialties");
              }
            }}
          >
            Clear
          </span>
        </div> */}
        {/* <div className={`specialties-list ${showSpecialties ? "" : "d-none"}`}>
          { specialties.map((specialty, i) => (
            <div key={i} className="specialty-option d-flex align-items-baseline">
              <input
                type="checkbox"
                name={specialty}
                checked={specialtiesSelected.includes(specialty)}
                onChange={e => handleFilterChanges(e, "specialties")}
              />
              <label htmlFor={specialty} className="storefront-body-text ml-2">{specialty.replace(/CME/g, "")}</label>
            </div>
          ))}
        </div> */}
        <div className="credit-amount-actions d-flex justify-content-between align-items-center">
          <span className="filter-box d-flex justify-content-between align-items-center">
            <span>Number of Credits</span>
            <span
              className="svg-container"
              role="button"
              tabIndex="0"
              onClick={() => toggleFilter("credit-amount")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  toggleFilter("credit-amount");
                }
              }}
            >
              <SVGIcon
                name={showCreditAmount ? "Minus" : "Plus"}
                width={24}
                height={24}
                aria-hidden="true"
              />
            </span>
          </span>
          <span
            className="storefront-link"
            role="button"
            tabIndex="0"
            onClick={() => clearFilter("credit-amount")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Space") {
                clearFilter("credit-amount");
              }
            }}
          >
            Clear
          </span>
        </div>
        <div className={`credit-amount-list ${showCreditAmount ? "" : "d-none"}`}>
          { creditRanges.map((creditRange, i) => (
            <div key={i} className="credit-amount-option">
              <input
                type="radio"
                name={creditRange}
                value={creditRange}
                checked={numberOfCreditsSelected === creditRange}
                onChange={e => handleFilterChanges(e, "credit-amount")}
              />
              <label htmlFor={creditRange} className="storefront-body-text ml-2">{creditRange} ({filterCount("creditRange", creditRange)})</label>
            </div>
          ))}
        </div>
        <div>
          <button
            type="button"
            className="done-button text-center"
            onClick={() => closeAndScrollToFilters()}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

PlpMobileFilters.propTypes = {
  setShowMobileFilters: PropTypes.func,
  handleFilterChanges: PropTypes.func,
  toggleFilter: PropTypes.func,
  showCreditTypes: PropTypes.bool,
  possibleCreditTypes: PropTypes.array,
  creditTypesSelected: PropTypes.array,
  // showSpecialties: PropTypes.bool,
  // specialties: PropTypes.array,
  // specialtiesSelected: PropTypes.array,
  showCreditAmount: PropTypes.bool,
  creditRanges: PropTypes.array,
  numberOfCreditsSelected: PropTypes.array,
  clearFilter: PropTypes.func,
  setRecentlyClosed: PropTypes.func,
  filterCount: PropTypes.func
};
