import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function PearsonVueEndReviewIcon(props) {
  return (
    <SvgContainer title="X Icon" {...props}>
      <path fill="#efda61" d="M30.62 14.53 11.63 45a1.7 1.7 0 0 0 .69 2.31 1.56 1.56 0 0 0 .79.21h37.94a1.7 1.7 0 0 0 1.46-2.56l-19-30.41a1.73 1.73 0 0 0-2.36-.55 1.79 1.79 0 0 0-.53.53Z" /><path fill="#efda61" d="M13.11 48a2.17 2.17 0 0 1-1-.27 2.21 2.21 0 0 1-.89-3l19-30.48a2.14 2.14 0 0 1 .69-.68 2.22 2.22 0 0 1 3.07.68l19 30.42a2.31 2.31 0 0 1 .3 1.08 2.17 2.17 0 0 1-.63 1.57 2.21 2.21 0 0 1-1.55.67ZM31 14.8 12 45.24a1.2 1.2 0 0 0 .51 1.61 1.09 1.09 0 0 0 .56.15h37.98a1.21 1.21 0 0 0 .85-.37 1.24 1.24 0 0 0 .34-.86 1.33 1.33 0 0 0-.16-.58l-19-30.39a1.24 1.24 0 0 0-1.7-.38 1.25 1.25 0 0 0-.38.38Z" /><path fill="#000" d="m30.51 35-.45-6.74c-.09-1.36-.55-3.55.48-4.65.79-.85 2.66-1 3.22.18a8.27 8.27 0 0 1 .31 4.3l-.6 6.94A5.47 5.47 0 0 1 33 37a1.19 1.19 0 0 1-1.61.52 1.17 1.17 0 0 1-.51-.5 6.23 6.23 0 0 1-.37-2.02Zm1.56 9.26a2.15 2.15 0 1 1 .26 0Z" /><path fill="#000" d="M51 51H13.08a5.13 5.13 0 0 1-4.38-7.67l19-30.45a5.14 5.14 0 0 1 7.07-1.65 5.07 5.07 0 0 1 1.65 1.67l19 30.39A5.13 5.13 0 0 1 51 51ZM30.6 15l-19 30.45a1.7 1.7 0 0 0 .69 2.31 1.58 1.58 0 0 0 .79.21H51a1.72 1.72 0 0 0 1.46-2.56L33.51 15a1.72 1.72 0 0 0-2.38-.53 1.58 1.58 0 0 0-.53.53Z" />
    </SvgContainer>
  );
}
