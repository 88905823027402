import React from 'react';
import SvgContainer from './SvgContainer';

// Styled by:
// app/assets/stylesheets/components/_UpArrow.scss

const UpArrow = props => (
  <SvgContainer name="Up Arrow Icon" {...props}>
    <circle cx="17.5" cy="17.5" r="17.5" fill="#F9A11B" />
    <g clipPath="url(#clip0_1502_1776)">
      <path d="M11.3856 14.5729C10.9268 15.0306 10.9268 15.763 11.3856 16.1902C11.8445 16.6479 12.5787 16.6479 13.0069 16.1902L16.3412 12.8643V24.6737C16.3412 25.3146 16.8613 25.8333 17.5037 25.8333C18.1461 25.8333 18.6661 25.3146 18.6661 24.6737V12.8948L22.0004 16.221C22.4593 16.6787 23.1934 16.6787 23.6217 16.221C23.8358 16.0074 23.9582 15.7022 23.9582 15.3971C23.9582 15.0919 23.8358 14.8173 23.6217 14.5731L18.3293 9.29398C18.1152 9.08037 17.8093 8.95831 17.5034 8.95831C17.1975 8.95831 16.8916 9.08037 16.6775 9.29398L11.3856 14.5729Z" fill="#1B3950" />
    </g>
    <defs>
      <clipPath id="clip0_1502_1776">
        <rect width="12.9167" height="16.875" fill="white" transform="translate(11.0415 8.95831)" />
      </clipPath>
    </defs>
  </SvgContainer>
);

export default UpArrow;
