import api, { currentUserUrl } from 'helpers/api';

export const CREDIT_CARD_LOADING = 'CREDIT_CARD_LOADING';
export const CREDIT_CARD_ERROR = 'CREDIT_CARD_ERROR';

export const CREDIT_CARDS_FETCHED = 'CREDIT_CARDS_FETCHED';
const getCreditCardsKey = () => `stripe-customers-fetching`;
export const creditCardsFetch = () => (dispatch, getState) => {
  const { loading } = getState();
  const key = getCreditCardsKey();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/stripe_customers`)
    .then((response) => {
      dispatch({
        type: CREDIT_CARDS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CREDIT_CARD_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: CREDIT_CARD_LOADING,
    payload: { key, promise }
  });

  return promise;
};
creditCardsFetch.getKey = getCreditCardsKey;

export const CREDIT_CARD_UPDATED = 'CREDIT_CARD_UPDATED';
const getCreditCardUpdateKey = creditCardId => `stripe-customers-updating/${creditCardId}`;
export const creditCardUpdate = creditCard => (dispatch, getState) => {
  const creditCardId = creditCard.id;
  const key = getCreditCardUpdateKey(creditCardId);
  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.put(`${currentUserUrl(getState())}/stripe_customers/${creditCardId}`, {
    stripe_customer: creditCard
  })
    .then((response) => {
      dispatch({
        type: CREDIT_CARD_UPDATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CREDIT_CARD_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: CREDIT_CARD_LOADING,
    payload: { key, promise }
  });

  return promise;
};
creditCardUpdate.getKey = getCreditCardUpdateKey;

export const CREDIT_CARD_REMOVED = 'CREDIT_CARD_REMOVED';
const getCreditCardRemoveKey = creditCardId => `stripe-customers-removing/${creditCardId}`;
export const creditCardRemove = creditCardId => (dispatch, getState) => {
  const { loading } = getState();
  const key = getCreditCardRemoveKey(creditCardId);
  if (loading.has(key)) return loading.get(key);

  const promise = api.delete(`${currentUserUrl(getState())}/stripe_customers/${creditCardId}`)
    .then((response) => {
      dispatch(creditCardsFetch());
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CREDIT_CARD_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: CREDIT_CARD_LOADING,
    payload: { key, promise }
  });

  return promise;
};
creditCardRemove.getKey = getCreditCardRemoveKey;
