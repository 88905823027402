import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgShowInput(props) {
  return (
    <SvgContainer title="Show Input" viewBoxHeight={16} viewBoxWidth={23} {...props}>
      <g transform="translate(11.500000, 8.000000) scale(-1, 1) translate(-11.500000, -8.000000) ">
        <path d="M16.6,7.9c0,2.7-2.3,4.9-5.1,4.9s-5.1-2.2-5.1-4.9S8.7,3,11.5,3C14.3,3,16.6,5.2,16.6,7.9" />
        <g transform="translate(0.000000, 0.033304)">
          <path d="M0,8C0,8.1,0,8.2,0.1,8.3C0,8.1,0,8.1,0,8C0,8.1,0,8.1,0,8z" />
        </g>
        <path d="M22.8,8.1C22.8,8.1,22.8,8.1,22.8,8.1C22.8,8.1,22.8,8,22.8,8.1C22.8,8.1,22.8,8.1,22.8,8.1z" />
        <g transform="translate(10.829793, 0.033304)">
          <path d="M-10.6,7.8C-10.6,7.7-7.3,0,0.7,0c7.8,0,11,7.2,11.3,7.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0 c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0C11.7,8.8,8.4,16,0.7,16c-7.3,0-10.6-6.4-11.2-7.6c-0.1-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0 c0,0,0-0.1,0-0.1C-10.7,8-10.7,7.9-10.6,7.8z M0.7,15.2c6.9,0,10-6.2,10.5-7.2C10.8,7,7.6,0.8,0.7,0.8c-7,0.1-10,6.3-10.5,7.2 C-9.4,9.1-6.2,15.2,0.7,15.2z" />
          <path d="M12,7.8c0,0,0,0.1,0,0.1C12,7.9,12,7.9,12,7.8C12,7.8,12,7.8,12,7.8z" />
        </g>
      </g>
    </SvgContainer>
  );
}
