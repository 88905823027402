import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useWindowDimensions from "helpers/windowDimensions";
import CmeCoachFilter from "./CmeCoachFilter";

export default function PlpCmeCoach({ showMobileFilters }) {
  const { width } = useWindowDimensions();

  const [displaySelectSpecialty, setDisplaySelectSpecialty] = useState(false);
  const [displaySelectState, setDisplaySelectState] = useState(false);

  const staticData = useSelector(state => state.staticData);

  const closeDropdowns = () => {
    setDisplaySelectSpecialty(false);
    setDisplaySelectState(false);
  };

  const handleShowCoachOptions = (e) => {
    const selectIds = [
      "specialty-input",
      "state-input",
      "select-text",
      "select-svg",
      "select-button"
    ];

    const otherTagNames = [
      "polygon",
      "svg"
    ];

    if (!selectIds.includes(e.target.id) && !otherTagNames.includes(e.target.tagName)) {
      closeDropdowns();
    }
  };

  const displaySelectObject = {
    displaySelectSpecialty: displaySelectSpecialty,
    displaySelectState: displaySelectState,
    setDisplaySelectSpecialty: setDisplaySelectSpecialty,
    setDisplaySelectState: setDisplaySelectState,
    closeDropdowns: closeDropdowns
  };

  return (
    <div
      className={`main-wrapper cme-coach ${showMobileFilters ? "d-none" : ""}`}
      role="button"
      tabIndex="0"
      onClick={e => handleShowCoachOptions(e)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Space") {
          handleShowCoachOptions(e);
        }
      }}
    >
      <div className={`inner-wrapper ${width >= 1360 ? "container" : "container-fluid"}`}>
        <div className="text-center mb-3 coach-title"><h2 className="storefront-secondary-title mb-0">CME Coach™ by BoardVitals</h2></div>
        <div className="text-center mb-5 coach-description">
          <p className="storefront-body-text"><b>Score a free report outlining your specific Specialty and State Requirements</b></p>
        </div>
        <CmeCoachFilter
          specialties={staticData?.get("specialties")}
          usStates={staticData?.get("us_states")}
          displaySelectObject={displaySelectObject}
        />
      </div>
    </div>
  );
}

PlpCmeCoach.propTypes = { showMobileFilters: PropTypes.bool };
