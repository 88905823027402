import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueCalculator(props) {
  return (
    <SvgContainer title="Calculator Icon" {...props}>
      <path d="M50 17.24A6.24 6.24 0 0 0 43.76 11H23.2a6.26 6.26 0 0 0-6.2 6.3v30.4a6.26 6.26 0 0 0 6.2 6.3h20.55A6.25 6.25 0 0 0 50 47.75ZM23.24 15h20.51A2.13 2.13 0 0 1 46 17v5H21v-5a2.11 2.11 0 0 1 2.24-2Zm20.52 35H23.25A2.31 2.31 0 0 1 21 47.65V26h25v21.66A2.29 2.29 0 0 1 43.76 50Z" />
    </SvgContainer>
  );
}
