import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCheck(props) {
  return (
    <SvgContainer title="QuestionBankSelect Check Icon" viewBoxHeight={12} viewBoxWidth={12} {...props}>
      <path d="M9.55.118l.324.327a.426.426 0 010 .605C7.999 2.94 6.112 4.827 4.237 6.728c-.36.363-.53.363-.89 0L.127 3.483a.426.426 0 010-.605c.107-.109.204-.218.312-.327a.433.433 0 01.613 0c.913.92 1.827 1.84 2.74 2.772C5.511 3.593 7.23 1.861 8.948.118a.441.441 0 01.601 0z" fill="#101010" fillRule="evenodd" />
    </SvgContainer>
  );
}
