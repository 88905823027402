import cmeCoach from './cmeCoach';
import cmeActivities from './cmeActivities';
import accreditors from './accreditors';
import cmeQuestionBanks from './cmeQuestionBanks';
import cmeActivityTrackers from './cmeActivityTrackers';
import cmeCreditTrackers from './cmeCreditTrackers';
import cmeCertificates from './cmeCertificates';

export default {
  cmeCoach,
  cmeActivities,
  accreditors,
  cmeQuestionBanks,
  cmeActivityTrackers,
  cmeCreditTrackers,
  cmeCertificates
};
