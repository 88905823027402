import React, {
  useState, useEffect, useRef, useLayoutEffect
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


/**
 * pass value for "disabledTooltip" only if the element is disabled
 */
export default function Tooltip({
  className,
  children,
  hoverable,
  open,
  delayHide,
  top,
  contentClass,
  label,
  defaultPadding,
  hoistTooltipWidthRef,
  disabledTooltip,
  tabIndex,
  asButton,
  onClickBtn
}) {
  const [show, setShow] = useState(open);
  const tooltipClass = classNames('Tooltip', className, { hoverable, show, top });
  const toolTipContentClass = classNames(`Tooltip-content ${defaultPadding ? "p-4" : ""}`, contentClass);

  const ref = useRef(null);
  const tooltipId = useRef(`tooltip-${Math.random().toString(36).substr(2, 9)}`);
  useLayoutEffect(() => {
    if (hoistTooltipWidthRef) {
      hoistTooltipWidthRef(ref.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (delayHide && show) {
      setTimeout(() => setShow(false), delayHide);
    }
  }, []);

  const onClickProps = {
    onClick: ev => asButton && onClickBtn(ev),
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // <div tabIndex={tabIndex || 0} role="button" className={tooltipClass}>
    //   {label || '?'}
    //   <div ref={ref} role="tooltip" className={toolTipContentClass}>
    //     {disabledTooltip || children}
    //   </div>
    // </div>
    <div tabIndex={tabIndex || 0} role="button" className={tooltipClass} aria-describedby={tooltipId.current} {...onClickProps}>
      {label || '?'}
      <div id={tooltipId.current} ref={ref} role="tooltip" className={toolTipContentClass}>
        {disabledTooltip || children}
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hoverable: PropTypes.bool,
  open: PropTypes.bool,
  delayHide: PropTypes.number,
  top: PropTypes.bool,
  contentClass: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  defaultPadding: PropTypes.bool,
  hoistTooltipWidthRef: PropTypes.func,
  disabledTooltip: PropTypes.string,
  tabIndex: PropTypes.string,
  asButton: PropTypes.bool,
  onClickBtn: PropTypes.func
};

Tooltip.defaultProps = {
  className: '',
  hoverable: true,
  defaultPadding: true,
  disabledTooltip: "",
  asButton: false,
};
