import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgFredNotesIndicator(props) {
  return (
    <SvgContainer title="Notes Indicator Icon" {...props}>
      <path fill="#f68833" d="M5.137 18.51l16.76-11.805 6.22 8.83L11.355 27.34z" /><path d="M20.6 7.7l2.7-1.9c1.1-.7 2.5-.4 3.1.5l3.6 5.1c.7 1.1.4 2.5-.5 3.1l-2.7 1.9-6.2-8.7z" fill="#f15b60" /><path fill="#fff" d="M11.4 27.4l-10 .4 3.8-9.3z" /><path fill="#0d0e0e" d="M5.2 27.6l-2.3-3.4-1.5 3.6z" /><g><path d="M1 28l4-9.9h.1L23.3 5.5c.5-.4 1.2-.5 1.9-.4.7.1 1.2.5 1.6 1.1l3.7 5.2c.4.5.5 1.2.4 1.9-.1.7-.5 1.2-1.1 1.6L27 17 11.5 27.8 1 28zm4.5-9.4l-3.6 8.8 9.4-.4 18.1-12.7c.4-.3.7-.7.8-1.2.1-.5 0-1-.3-1.4l-3.7-5.2c-.2-.5-.6-.8-1.1-.9-.5-.1-1 0-1.4.3L20.8 8 5.5 18.6z" /></g><path d="M11.3 27.2L5 18.5l.6-.4 6.2 8.7zM20.376 8.052l.571-.405 6.015 8.484-.571.405z" />
    </SvgContainer>
  );
}
