import { Set } from 'immutable';

import api, { currentUserUrl } from 'helpers/api';
import { createApiRequestAction } from 'helpers/createAction';

export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';

export const organizationSelect = (organizationId, user) => (dispatch, getState) => {
  const { organizationalMemberships, session, assignments } = getState();
  const currentUserId = user.get('id');
  const isAdminUser = ['admin', 'distributor'].includes(user.get('internal_role'));

  // Skip no-op action if this organization is already selected
  if (organizationId === session.get('selected_organization_id')) return;

  const staffMemberships = organizationalMemberships
    .filter(membership => membership.get('user_id') === currentUserId
      && !membership.get('deactivated')
      && membership.get('verified')
      && ['admin', 'instructor', 'super admin'].includes(membership.get('role')));

  // Skip action unless the current user has an active & verified staff membership for the organization
  if (!staffMemberships.find(membership => membership.get('organization_id') === organizationId) && !isAdminUser) return;

  const whitelistedUserIds = new Set(
    assignments.filter(assignment => assignment.get('assigned_to_current_user'))
      .toList().map(assignment => assignment.get('creator_id'))
  ).toArray();
  whitelistedUserIds.push(currentUserId);

  api.put(`${currentUserUrl(getState())}/organizations/${organizationId}/select`);

  dispatch({
    type: ORGANIZATION_SELECTED,
    payload: { organizationId, whitelistedUserIds, currentUserId }
  });

  // Start other async things if needed
};


export const ORGANIZATION_FETCHING = 'ORGANIZATION_FETCHING';
export const ORGANIZATION_FETCHED = 'ORGANIZATION_FETCHED';
export const ORGANIZATION_FETCH_ERROR = 'ORGANIZATION_FETCH_ERROR';

const getOrganizationFetchKey = organizationId => `organizations/${organizationId}`;
export const organizationFetch = organizationId => (dispatch, getState) => {
  const { loading } = getState();
  const key = getOrganizationFetchKey(organizationId);

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${currentUserUrl(getState())}/organizations/${organizationId}`)
    .then((response) => {
      dispatch({
        type: ORGANIZATION_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: ORGANIZATION_FETCH_ERROR,
        payload: { error, key }
      });
      throw error;
    });

  dispatch({
    type: ORGANIZATION_FETCHING,
    payload: { key, promise }
  });
};
organizationFetch.getKey = getOrganizationFetchKey;


export const ORGANIZATION_MEMBERS_FETCHING = 'ORGANIZATION_MEMBERS_FETCHING';
export const ORGANIZATION_MEMBERS_FETCHED = 'ORGANIZATION_MEMBERS_FETCHED';
export const ORGANIZATION_MEMBERS_FETCH_ERROR = 'ORGANIZATION_MEMBERS_FETCH_ERROR';
export const ORGANIZATION_MEMBERS_PAGINATED = 'ORGANIZATION_MEMBERS_PAGINATED';

export const organizationMembersFetch = createApiRequestAction({
  getKey: ({ organizationId, query, pageNumber } = {}) => `organizations/${organizationId}/members?query=${query}&page=${pageNumber}`,
  request: (getState, {
    organizationId, loadSubscriptions, query, pageNumber, sortKey, ati, onlyStudents, fullAssociations
  } = {}) => {
    const options = {
      params: {
        load_subscriptions: loadSubscriptions,
        only_students: onlyStudents,
        full_associations: fullAssociations,
        query,
        page: pageNumber,
        sort_key: sortKey,
        ati
      }
    };
    return api.get(`${currentUserUrl(getState())}/organizations/${organizationId}/members`, options);
  },
  loadingConstant: ORGANIZATION_MEMBERS_FETCHING,
  loadedConstant: ORGANIZATION_MEMBERS_FETCHED,
  errorConstant: ORGANIZATION_MEMBERS_FETCH_ERROR,
  paginationEndConstant: ORGANIZATION_MEMBERS_PAGINATED,
});

export const ORGANIZATION_UNLOAD_MEMBERS = 'ORGANIZATION_UNLOAD_MEMBERS';
export const RELOAD_CURRENT_USER = 'RELOAD_CURRENT_USER';
export const RELOAD_CURRENT_USER_MEMBERSHIPS = 'RELOAD_CURRENT_USER_MEMBERSHIPS';

export const organizationUnloadMembers = () => (dispatch, getState) => {
  const {
    loadedAt,
    users,
    session,
    organizationalMemberships
  } = getState();
  const userId = session.get('current_user_id');
  const currentUser = users.get(userId);
  if (!currentUser) return;
  const isAdminUser = ['admin', 'distributor'].includes(currentUser.get('internal_role'));
  const organizationId = session.get('selected_organization_id');
  const allMemberships = organizationalMemberships.filter(om => om.get('user_id') === userId);
  const currentUserMemberships = allMemberships.filter(om => om.get('user_id') === userId || isAdminUser);
  const key = organizationMembersFetch.getKey({
    organizationId: organizationId,
    query: '',
    pageNumber: 1
  });
  const orgMembersLoaded = loadedAt.get(key);
  if (orgMembersLoaded) {
    dispatch({
      type: ORGANIZATION_UNLOAD_MEMBERS
    });
    dispatch({
      type: RELOAD_CURRENT_USER,
      payload: currentUser
    });
    dispatch({
      type: RELOAD_CURRENT_USER_MEMBERSHIPS,
      payload: currentUserMemberships
    });
  }
};


export const ORGANIZATIONS_FETCHED = 'ORGANIZATIONS_FETCHED';
export const ORGANIZATIONS_FETCH_ERROR = 'ORGANIZATIONS_FETCH_ERROR';
export const ORGANIZATIONS_FETCHING = 'ORGANIZATIONS_FETCHING';

const getOrganizationsFetchKey = () => 'organizations/';

export const organizationsFetch = createApiRequestAction({
  getKey: getOrganizationsFetchKey,
  request: getState => api.get(`${currentUserUrl(getState())}/organizations`),
  loadingConstant: ORGANIZATIONS_FETCHING,
  loadedConstant: ORGANIZATIONS_FETCHED,
  errorConstant: ORGANIZATIONS_FETCH_ERROR
});
