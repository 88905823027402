/* Function Keys
-----------------------------------------------*/
export const clear = { type: "clear", label: "AC", id: "clear" };
export const clearFred = { type: "clear", label: "C", id: "clear" };
export const equals = { type: "equals", label: "=", id: "equals" };
export const openParenthesis = {
  type: "function",
  label: "(",
  id: "openParenthesis"
};
export const closeParenthesis = {
  type: "function",
  label: ")",
  id: "closeParenthesis"
};
export const memoryClear = { type: "function", label: "mc", id: "memoryClear" };
export const memoryAdd = {
  type: "function",
  label: "m&plus;",
  id: "memoryAdd"
};
export const memorySubtract = {
  type: "function",
  label: "m&minus;",
  id: "memorySubtract"
};
export const memoryRecall = {
  type: "function",
  label: "mr",
  id: "memoryRecall"
};
export const alt = { type: "function", label: "2<sup>nd</sup>", id: "alt" };
export const trigUnit = { type: "function", label: "Rad", id: "trigUnit" };
export const rand = { type: "function", label: "Rand", id: "random" };

/* Number Keys
-----------------------------------------------*/
export const one = { type: "number", label: "1", id: "one" };
export const two = { type: "number", label: "2", id: "two" };
export const three = { type: "number", label: "3", id: "three" };
export const four = { type: "number", label: "4", id: "four" };
export const five = { type: "number", label: "5", id: "five" };
export const six = { type: "number", label: "6", id: "six" };
export const seven = { type: "number", label: "7", id: "seven" };
export const eight = { type: "number", label: "8", id: "eight" };
export const nine = { type: "number", label: "9", id: "nine" };
export const zero = { type: "number", label: "0", id: "zero" };
export const decimal = { type: "number", label: ".", id: "decimal" };

/* Binary Operation Keys
-----------------------------------------------*/
export const add = { type: "binaryOperation", label: "+", id: "add" };
export const subtract = {
  type: "binaryOperation",
  label: "-",
  id: "subtract"
};
export const multiply = {
  type: "binaryOperation",
  label: "x",
  id: "multiply"
};
export const divide = {
  type: "binaryOperation",
  label: "÷",
  id: "divide"
};
export const toPower = {
  type: "binaryOperation",
  label: "x<sup>y</sup>",
  id: "pow"
};
export const nthRoot = {
  type: "binaryOperation",
  label: "<sup>y</sup>&radic;<sub>x</sub>",
  id: "nthRoot"
};

/* Unary Operation Keys
-----------------------------------------------*/
export const negate = {
  type: "unaryOperation",
  label: "&plus;/&minus;",
  id: "neg"
};
export const negateFred = {
  type: "unaryOperation",
  label: "&plusmn;",
  id: "neg"
};
export const percent = { type: "unaryOperation", label: "&#37", id: "percent" };
export const square = {
  type: "unaryOperation",
  label: "x<sup>2</sup>",
  id: "square"
};
export const cube = {
  type: "unaryOperation",
  label: "x<sup>3</sup>",
  id: "cube"
};
export const eToXPower = {
  type: "unaryOperation",
  label: "e<sup>x</sup>",
  id: "eToXPower"
};
export const tenToXPower = {
  type: "unaryOperation",
  label: "10<sup>x</sup>",
  id: "tenToXPower"
};
export const inverse = { type: "unaryOperation", label: "1/x", id: "inverse" };
export const squareRoot = {
  type: "unaryOperation",
  label: "<sup>2</sup>&radic;<sub>x</sub>",
  id: "sqrt"
};
export const squareRootFred = {
  type: "unaryOperation",
  label: "&radic;<span class='square-root'>x</span>",
  id: "sqrt"
};
export const cubeRoot = {
  type: "unaryOperation",
  label: "<sup>3</sup>&radic;<sub>x</sub>",
  id: "cbrt"
};
export const naturalLogarithm = {
  type: "unaryOperation",
  label: "ln",
  id: "ln"
};
export const logarithm = { type: "unaryOperation", label: "log", id: "log" };
export const exponential = {
  type: "unaryOperation",
  label: "EE",
  id: "toExponential"
};
export const factorial = {
  type: "unaryOperation",
  label: "x!",
  id: "factorial"
};
export const sin = { type: "unaryOperation", label: "sin", id: "sin" };
export const cos = { type: "unaryOperation", label: "cos", id: "cos" };
export const tan = { type: "unaryOperation", label: "tan", id: "tan" };
export const sinh = { type: "unaryOperation", label: "sinh", id: "sinh" };
export const cosh = { type: "unaryOperation", label: "cosh", id: "cosh" };
export const tanh = { type: "unaryOperation", label: "tanh", id: "tanh" };

/* Constant Keys
-----------------------------------------------*/
export const e = { type: "constant", label: "e", id: "E" };
export const pi = { type: "constant", label: "&Pi;", id: "PI" };
