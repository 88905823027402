import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgFreeTrial(props) {
  return (
    <SvgContainer title="Free Trial" {...props}>
      <path d="M19.01 7.782V5.5h3a1 1 0 001-1v-2a1 1 0 00-1-1h-11a1 1 0 00-1 1v2a1 1 0 001 1h3v2.282a11.467 11.467 0 00-5.257 2.754L7.318 9.1l1.767-1.768a.5.5 0 10-.707-.707l-4.242 4.242a.5.5 0 10.707.707l1.768-1.767 1.435 1.435A11.488 11.488 0 1019.01 7.782zm-8-5.282h11V5v-.5h-11zm4 3h3v2.11a11.492 11.492 0 00-1.5-.11 11.49 11.49 0 00-1.5.11zm1.5 24A10.5 10.5 0 1127.01 19a10.511 10.511 0 01-10.5 10.5z" />
      <path d="M16.51 10.5a8.5 8.5 0 108.5 8.5 8.51 8.51 0 00-8.5-8.5zm0 16a7.5 7.5 0 117.5-7.5 7.508 7.508 0 01-7.5 7.5z" />
      <path d="M17.01 18.793V14a.5.5 0 00-1 0v5a.506.506 0 00.147.354l3.535 3.535a.5.5 0 10.707-.707z" />
    </SvgContainer>
  );
}
