import StateHelper from 'helpers/StateHelper';
import Assignment from 'factories/Assignment';

import { PERFORMANCE_QUIZZES_FETCHED } from 'actions/performance';
import {
  SESSION_RESET
} from 'actions/session';
import {
  QUIZ_FETCHED_PERFORMANCE,
  QUIZ_FETCHED_TAKE,
  QUIZZES_FETCHED
} from 'actions/quizzes';
import {
  ASSIGNMENTS_FETCHED as B2B_ASSIGNMENT_FETCHED,
  ASSIGNMENTS_LIST_FETCHED,
  ASSIGNMENT_FETCHED,
  ASSIGNMENT_UPDATED,
  ASSIGNMENT_CREATED,
  ASSIGNMENT_MESSAGE_CREATED
} from 'actions/b2b/assignments';
import {
  ORGANIZATION_SELECTED
} from 'actions/b2b/organizations';

const stateHelper = new StateHelper(Assignment);
export const { initialState } = stateHelper;
const reducers = {};

reducers[PERFORMANCE_QUIZZES_FETCHED] = function performanceQuizzesFetched(state, payload) {
  return state.withMutations((newState) => {
    payload.assignments.forEach((assignment) => {
      if (state.get(assignment[0])) return;
      newState.set(assignment[0], new Assignment({
        id: assignment[0],
        attributes: {
          name: assignment[4],
          no_pause: assignment[5],
          end_time: Math.floor(assignment[6] * 1000),
          initial_message: assignment[7],
          hide_review: assignment[8] || null,
          hide_results: assignment[9] || null,
          is_lti: assignment[10] || null,
          deleted: assignment[11] || false,
        },
        relationships: {
          creator: {
            data: {
              id: assignment[1]
            }
          },
          question_bank: {
            data: {
              id: assignment[2]
            }
          },
          organization: {
            data: {
              id: assignment[3]
            }
          }
        }
      }));
    });
  });
};

reducers[ASSIGNMENTS_LIST_FETCHED] = function assignmentListFetch(state, payload) {
  return state.withMutations((newState) => {
    payload.list.forEach((assignment) => {
      if (!newState.has(assignment.id)) {
        newState.set(assignment.id, new Assignment({
          id: assignment.id,
          attributes: {
            name: assignment.name,
            deleted: assignment.deleted,
            start_time: assignment.start_time,
            end_time: assignment.end_time,
            finished: assignment.finished,
            class_names: assignment.class_names,
            total_quizzes: assignment.total,
            completed_quizzes: assignment.completed,
            quiz_type: assignment.quiz_type,
            created_at: assignment.created_at,
            hide_results: assignment.hide_results || null,
            hide_review: assignment.hide_review || null,
            is_lti: assignment.is_lti
          },
          relationships: {
            creator: {
              data: {
                id: assignment.creator_id
              }
            },
            question_bank: {
              data: {
                id: assignment.question_bank_id
              }
            },
            organization: {
              data: {
                id: assignment.organization_id
              }
            },
            exam_template: {
              data: {
                id: assignment.exam_template_id
              }
            }
          }
        }));
      }
    });
  });
};

reducers[SESSION_RESET] = stateHelper.reset;
reducers[QUIZ_FETCHED_PERFORMANCE] = stateHelper.set;
reducers[QUIZ_FETCHED_TAKE] = stateHelper.set;
reducers[QUIZZES_FETCHED] = stateHelper.set;
reducers[ASSIGNMENT_FETCHED] = stateHelper.set;
reducers[ASSIGNMENT_CREATED] = (state, payload) => {
  const { start_time: startTime, total_assignees: totalAssignees } = payload.data.attributes;
  // Reference total assignees while the quizzes are generated in the backend. SendLater assignments will still show 0 quizzes until their scheduled start
  if (new Date(startTime) < new Date()) { payload.data.attributes.total_quizzes = totalAssignees; }
  return stateHelper.set(state, payload);
};
reducers[ASSIGNMENT_UPDATED] = (state, payload) => {
  const { start_time: startTime, total_assignees: totalAssignees } = payload.data.attributes;
  if (new Date(startTime) < new Date()) { payload.data.attributes.total_quizzes = totalAssignees; }
  return stateHelper.set(state, payload);
};
reducers[ASSIGNMENT_MESSAGE_CREATED] = stateHelper.set;
// When loading Assignments for the B2B Admin Tools we mark
reducers[B2B_ASSIGNMENT_FETCHED] = (state, payload) => {
  payload.data.forEach((record) => {
    record.attributes.assigned_to_current_user = state.getIn([parseInt(record.id), 'assigned_to_current_user'], false);
  });
  return stateHelper.set(state, payload);
};
// The User may have assignments assigned to them so when we select an Organization we don't do a full reset.
// Instead we filter out any assignments which aren't assigned to the current user (set in reducer above).
reducers[ORGANIZATION_SELECTED] = state => state.filter(assignment => assignment.get('assigned_to_current_user'));

export default stateHelper.createReducer(reducers);
