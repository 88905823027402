import React from 'react';
import PropTypes from 'prop-types';
import parseMarkdown from 'helpers/parseMarkdown';
import SVGIcon from 'components/SVGIcon';
import { snakeToPascal } from 'helpers/stringHelper';
import PageSection from './PageSection';
import useScreenSize from './hooks/useScreenSize';


const Feature = ({
  icon, questionBankName, message, activeQuestionCount, isMobile, isTablet
}) => {
  const copy = parseMarkdown(message, { questionBankName, activeQuestionCount });
  let iconName = snakeToPascal(icon);
  if (iconName === "Info" || iconName === "Key" || iconName === "Stethoscope") iconName = `PDP${iconName}`;
  return (
    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 w-100">
      <div className="feature row pb-lg-6 pb-md-6 pb-sm-2 d-flex align-items-center ">
        <div className="col-3 d-flex align-items-center justify-content-start">
          <SVGIcon name={iconName} small={isMobile} medium={isTablet} />
        </div>
        <p className="col pt-xs-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5 d-flex justify-content-start">{copy}</p>
      </div>
    </div>
  );
};

Feature.propTypes = {
  icon: PropTypes.oneOf(['book', 'books', 'bottle', 'clock', 'images', 'info', 'institution', 'key', 'practitioners', 'stethoscope']).isRequired,
  message: PropTypes.string.isRequired,
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool
};


const KeyFeatures = ({
  featureGroup, questionBankName, activeQuestionCount
}) => {
  const { isMobile, isTablet } = useScreenSize();
  return (
    <PageSection section="feature-group">
      <div className="d-flex w-100 flex-column align-items-center justify-content-center">
        <div className="pb-6 pl-5 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 w-100 d-flex justify-content-start justify-xl-content-center justify-content-lg-center">
          <h2 className="storefront-secondary-title">{parseMarkdown(featureGroup.name, { questionBankName, activeQuestionCount })}</h2>
        </div>
        <div className="row d-flex align-items-center">
          {featureGroup.features.map(feature =>
            <Feature key={feature.id} icon={feature.icon_name} message={feature.message} questionBankName={questionBankName} activeQuestionCount={activeQuestionCount} isMobile={isMobile} isTablet={isTablet} />)}
        </div>
      </div>
    </PageSection>
  );
};


KeyFeatures.propTypes = {
  questionBankName: PropTypes.string.isRequired,
  activeQuestionCount: PropTypes.number,
  featureGroup: PropTypes.object.isRequired
};

export default KeyFeatures;
