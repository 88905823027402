import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueQuestionCount(props) {
  return (
    <SvgContainer title="QuestionCount Icon" {...props}>
      <path d="M47 41H17a9 9 0 0 1 0-18h30a9 9 0 0 1 0 18ZM17 27a5 5 0 0 0 0 10h30a5 5 0 0 0 0-10Z" className="cls-1" />
      <path className="cls-1" d="M17 30h23v4H17zM23 17h27v4H23zM23 43h27v4H23z" />
    </SvgContainer>
  );
}
