import { List } from 'immutable';

import Factory from 'factories/_Factory';
import Image from 'factories/Image';


export default class Answer extends Factory {
  static jsonType = 'answer';

  static afterUpdate(record, json) {
    let newRecord = record;

    if (json.attributes.images || newRecord.get('images') === undefined) {
      const images = List((json.attributes.images || []).map(image => new Image(image)));
      newRecord = newRecord.set('images', images);
    }

    return newRecord;
  }

  static get defaults() {
    return {
      safe_name: '',
      plain_name: '',
      choice: 0,
      peer_response_count: 0,
      hotspot_coordinates: '',
      hotspot_shape: '',
      is_correct: false,
      answer_group_id: 0
    };
  }
}
