import Factory from 'factories/_Factory';


export default class Video extends Factory {
  static jsonType = 'video';

  static get defaults() {
    return {
      html: '',
      url: '',
      download_url: '',
      streaming_url: '',
      height: 0,
      width: 0,
      vimeo_id: ''
    };
  }
}
