import React from "react";
import SVGIcon from "components/SVGIcon";
import { formatString } from "./homePageHelpers";

export default function HomeReasons({ reasonsTextContent }) {
  const {
    title_1: title1,
    title_2: title2,
    title_3: title3,
    title_4: title4,
    title_5: title5,
    title_6: title6,
    description_1: description1,
    description_2: description2,
    description_3: description3,
    description_4: description4,
    description_5: description5,
    description_6: description6
  } = reasonsTextContent.tileSectionData;

  return (
    <div className="HomeReasons home-section-wrapper">
      <div className="container">
        <div className="row">
          <div className="col mt-5 py-6">
            <h2 className="storefront section-title my-6 pt-5">WHY BOARDVITALS?</h2>
          </div>
        </div>
        <div className="row">
          <div className="col mb-6 pb-4">
            <SVGIcon name="HomeReasonPassGuarantee" ariaLabel="Guaranteed to Pass your Board Exams" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title1) || "100% Pass Guarantee​" }}
            />
            {description1 ? (
              /* eslint-disable-next-line react/no-danger */
              <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description1) }} />
            ) : (
              <p className="storefront mb-6">We’re committed to supporting you and are confident we can help you achieve your goals. In fact, <a className="storefront" href="/board-pass-rates">we guarantee it</a>.</p>
            )}
          </div>
          <div className="col mb-6 pb-4">
            <SVGIcon name="HomeReasonPassRates" ariaLabel="Higher Board Exam Pass Rate than the National Average" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title2) || "Higher Pass Rates" }}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description2) || "Test-takers who used BoardVitals had a 9% higher board exam pass rate than the national average, plus 18% reduction in study time." }} />
          </div>
          <div className="col mb-6 pb-4">
            <SVGIcon name="HomeReasonChallengingQuestions" ariaLabel="Challenging Exam-style Board Practice Questions" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title3) || "Challenging Questions" }}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description3) || "Our content is developed by top specialists and made up of exam-style questions at or above the difficulty level of the actual exams." }} />
          </div>
        </div>
        <div className="row">
          <div className="col mb-5 pb-2">
            <SVGIcon name="HomeReasonUpdatedContent" ariaLabel="Fresh Updated Medical Content for Up To Date Training & Board Prep" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title4) || "Updated Content" }}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description4) || "Our team of top physicians and healthcare editors update content frequently to ensure the best questions are front and center." }} />
          </div>
          <div className="col mb-5 pb-2">
            <SVGIcon name="HomeReasonClearerUnderstanding" ariaLabel="Board Prep Questions & Answers Provide Comprehensive Explanations of Materials and Content" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title5) || "Comprehensive Explanations" }}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description5) || "Detailed explanations and rationales help you understand why you got a question right or wrong." }} />
          </div>
          <div className="col mb-5 pb-2">
            <SVGIcon name="HomeReasonPracticeExams" ariaLabel="Full Length Medical Board Simluations and Practice Exams" className="mb-6" height={127} width={127} viewBoxHeight={127} viewBoxWidth={127} />
            <h3
              className="storefront mt-3"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formatString(title6) || "Full-Length Practice Exams" }}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <p className="storefront mb-6" dangerouslySetInnerHTML={{ __html: formatString(description6) || "Practice using the exact blueprint, length, and board exam interface. Just like the real exam.*" }} />
          </div>
        </div>
        <div className="row">
          <div className="col mb-6 pb-2">
            <p className="storefront-disclaimers mb-6">*Select specialties only</p>
          </div>
        </div>
      </div>
    </div>
  );
}
