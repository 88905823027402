import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function SvgPearsonVueUpArrow(props) {
  return (
    <SvgContainer title="Up Arrow Icon" {...props}>
      <circle cx="32.1" cy="32.1" r="28.4" fill="#ffffff" />
      <path d="M32.1 61C16.2 61 3.2 48 3.2 32.1c0-15.9 13-28.9 28.9-28.9C48 3.2 61 16.2 61 32.1 61 48 48 61 32.1 61zm0-56.8C16.7 4.2 4.2 16.7 4.2 32.1 4.2 47.5 16.7 60 32.1 60 47.5 60 60 47.5 60 32.1 60 16.7 47.5 4.2 32.1 4.2z" fill="#81ade1" />
      <g>
        <path d="M17.8 28.6c-.3.3-.4.6-.4 1s.1.7.4 1c.3.3.6.4 1 .4H27v20h11V31h8.2c.8 0 1.4-.6 1.4-1.4 0-.4-.2-.8-.4-1.1L33.5 14.3c-.3-.3-.6-.4-1-.4s-.7.1-1 .4L17.8 28.6z" fill="#354afb" />
      </g>
    </SvgContainer>
  );
}
