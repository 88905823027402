import React from 'react';
import SVGIcon from "components/SVGIcon";
import PropTypes from 'prop-types';

export default function CmeCoachInputWithDropdown({
  options,
  displaySelectOptions,
  setDisplaySelectOptions,
  inputType,
  handleSelected,
  selectedId,
  selectedName
}) {
  return (
    <>
      <span className={`${inputType}-select position-relative`}>
        <span
          className="d-flex"
          role="button"
          tabIndex="0"
          onClick={() => setDisplaySelectOptions(!displaySelectOptions)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setDisplaySelectOptions(!displaySelectOptions);
            }
          }}
        >
          <div
            id={`${inputType}-input`}
            className={`${inputType}-input`}
          >
            <span id="select-text">{!selectedId ? `Select a ${inputType.charAt(0).toUpperCase() + inputType.slice(1)}` : `${selectedName}`}</span>
          </div>
          <button id="select-button" type="button" className="select-button">
            <SVGIcon id="select-svg" name="DownCaret3" width={14} height={14} />
          </button>
        </span>
        <div className={`options ${!displaySelectOptions ? "d-none" : ""}`}>
          <ul>
            { options.map(option => (
              <li
                key={option.value}
                className="mb-4"
                value={option.value}
                data-value={option.value}
                role="menuitem"
                tabIndex="0"
                onClick={e => handleSelected(e, inputType)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    handleSelected(e, inputType);
                  }
                }}
              >
                { option.name }
              </li>
            ))}
          </ul>
        </div>
      </span>
    </>
  );
}

CmeCoachInputWithDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  displaySelectOptions: PropTypes.bool,
  setDisplaySelectOptions: PropTypes.func,
  inputType: PropTypes.string,
  handleSelected: PropTypes.func,
  selectedId: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number
  ]),
  selectedName: PropTypes.string,
};
