import StateHelper from 'helpers/StateHelper';
import MemberGroup from 'factories/MemberGroup';

import {
  SESSION_RESET
} from 'actions/session';
import {
  MEMBER_GROUPS_FETCHED,
  MEMBER_GROUP_FETCHED,
  MEMBER_GROUP_CREATED,
  MEMBER_GROUP_UPDATED,
  ORGANIZATION_UNLOAD_MEMBER_GROUPS,
  MEMBER_GROUP_USERS_PAGINATED,
  ORGANIZATION_UNLOAD_MEMBER_GROUP_USERS
} from 'actions/b2b/memberGroups';
import {
  ORGANIZATION_SELECTED
} from 'actions/b2b/organizations';

import { ASSIGNMENT_FETCHED } from 'actions/b2b/assignments';

const stateHelper = new StateHelper(MemberGroup);
export const { initialState } = stateHelper;
const reducers = {};

reducers[SESSION_RESET] = stateHelper.reset;
reducers[MEMBER_GROUPS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_CREATED] = stateHelper.set;
reducers[MEMBER_GROUP_UPDATED] = stateHelper.set;
reducers[ORGANIZATION_SELECTED] = stateHelper.reset;
reducers[ORGANIZATION_UNLOAD_MEMBER_GROUPS] = stateHelper.reset;
reducers[ORGANIZATION_UNLOAD_MEMBER_GROUP_USERS] = stateHelper.reset;
reducers[MEMBER_GROUP_USERS_PAGINATED] = (state, payload) => state.setIn([payload.classId, 'has_more_members_pagination'], payload.hasMoreMembersPagination);


reducers[ASSIGNMENT_FETCHED] = stateHelper.set;

export default stateHelper.createReducer(reducers);
