import api from 'helpers/api';

export const RISK_ASSESSMENT_SYNCING = 'RISK_ASSESSMENT_SYNCING';
export const RISK_ASSESSMENT_SYNCED = 'RISK_ASSESSMENT_SYNCED';
export const RISK_ASSESSMENT_SYNC_ERROR = 'RISK_ASSESSMENT_SYNC_ERROR';
const riskFetchKey = () => 'risk_assessment';
export const riskFetch = (questionBankIdArg, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id', 0);
  const key = riskFetchKey();

  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/risk_assessments`)
    .then((response) => {
      dispatch({
        type: RISK_ASSESSMENT_SYNCED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: RISK_ASSESSMENT_SYNC_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: RISK_ASSESSMENT_SYNCING,
    payload: { key, promise }
  });

  return promise;
};
riskFetch.getKey = riskFetchKey;

export const RISK_UNLOAD = 'RISK_UNLOAD';
