import StateHelper from 'helpers/StateHelper';
import Product from 'factories/Product';

import {
  QUESTION_BANK_SELECTED
} from 'actions/questionBanks';
import {
  PRODUCTS_FETCHED
} from 'actions/products';
import {
  SESSION_FETCHED,
  SESSION_RESET
} from 'actions/session';

const stateHelper = new StateHelper(Product);
export const { initialState } = stateHelper;
const reducers = {};


reducers[PRODUCTS_FETCHED] = stateHelper.set;

reducers[QUESTION_BANK_SELECTED] = stateHelper.set;
reducers[SESSION_FETCHED] = stateHelper.resetAndSet;
reducers[SESSION_RESET] = stateHelper.reset;

export default stateHelper.createReducer(reducers);
