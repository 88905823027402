import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgProducts(props) {
  return (
    <SvgContainer title="Products" {...props} viewBoxHeight={13} viewBoxWidth={14}>
      <path fill="#4A4A4A" d="M.955 0A.967.967 0 000 .97v9.706c0 .18.142.324.318.324h13.364a.32.32 0 00.318-.324V.971A.967.967 0 0013.045 0H.955zm0 .647h12.09c.182 0 .319.139.319.324v1.294H.636V.97c0-.185.137-.324.319-.324zm.636.485a.32.32 0 00-.318.324.32.32 0 00.318.323h2.864a.32.32 0 00.318-.323.321.321 0 00-.318-.324H1.59zm-.955 1.78h12.728v7.44H.636v-7.44zM7 3.72c-.717 0-1.419.362-1.581 1.056-.046.147.056.379.229.42a.333.333 0 00.388-.273c.074-.32.452-.556.964-.556.554 0 .955.377.955.788 0 .719-.216.872-.532 1.168-.316.296-.741.74-.741 1.602-.003.171.15.329.318.329s.32-.158.318-.329c0-.682.212-.822.532-1.122.32-.3.74-.756.74-1.648 0-.818-.74-1.435-1.59-1.435zm.015 4.853c-.264 0-.477.217-.477.485s.213.485.477.485.477-.217.477-.485-.214-.485-.477-.485z" />
    </SvgContainer>
  );
}
