import algoliasearch from 'algoliasearch';
import { algolia } from 'config';

export const algoliaQuestionSearch = (keyword, questionBankId, subjectIds) => {
  const algoliaClient = algoliasearch(algolia.appId, algolia.apiKey);
  const searchIndex = algoliaClient.initIndex('Question');

  // Minimum search term length is 3 characters
  if (keyword.length < 3) return null;
  let hits = [];
  return searchIndex.browseObjects({
    query: keyword,
    numericFilters: `qbank_ids = ${questionBankId}`,
    facetFilters: [subjectIds.map(id => "subject_ids:" + id)],
    attributesToRetrieve: ['objectID'],
    batch: (batch) => { hits = hits.concat(batch); }
  }).then(() => new Set(hits.map(hit => parseInt(hit.objectID))));
};

export const algoliaProductsSearch = (keyword) => {
  const algoliaClient = algoliasearch(algolia.appId, algolia.apiKey);
  const searchIndex = algoliaClient.initIndex('ProductPage');

  // Minimum search term length is 3 characters
  if (keyword.length < 3) return null;
  return searchIndex.search(keyword, { hitsPerPage: 7 });
};

export const algoliaClinicalPearlSearch = (keyword, questionBankId, categoryIds) => {
  const algoliaClient = algoliasearch(algolia.appId, algolia.apiKey);
  const searchIndex = algoliaClient.initIndex('ClinicalPearl');

  // Minimum search term length is 3 characters
  if (keyword.length < 3) return null;
  let hits = [];
  return searchIndex.browseObjects({
    query: keyword,
    numericFilters: `qbank_ids = ${questionBankId}`,
    facetFilters: [categoryIds.map(id => "category_ids:" + id)],
    attributesToRetrieve: ['objectID'],
    batch: (batch) => { hits = hits.concat(batch); }
  }).then(() => new Set(hits.map(hit => parseInt(hit.objectID))));
};
