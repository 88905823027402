import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgUnfilledFlag(props) {
  return (
    <SvgContainer title="Unfilled Flag Icon" {...props}>
      <path d="M25.962 17.81a.498.498 0 00-.11-.164L21.208 13l4.646-4.646A.5.5 0 0025.5 7.5H11.488A2.476 2.476 0 0012 6a2.5 2.5 0 10-3 2.45V27.5H6.5a.5.5 0 000 1h6a.5.5 0 000-1H10v-9h15.5a.5.5 0 00.462-.69zM8 6a1.5 1.5 0 111.5 1.5A1.502 1.502 0 018 6zm2 2.5h14.293l-4.146 4.146a.5.5 0 000 .707l4.146 4.147H10z" />
    </SvgContainer>
  );
}
