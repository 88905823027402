import { Map, Set, fromJS } from 'immutable';

import createReducer from 'helpers/createReducer';

import Specialty from 'factories/Specialty';
import LabValue from 'factories/LabValue';
import UserType from 'factories/UserType';

import { SERVER_TIME_FETCHED } from 'actions/quizzes';
import { STATIC_DATA_FETCHED } from 'actions/staticData';


export const initialState = new Map({
  us_states: new Map(),
  countries: new Map(),
  feedback_types: new Set(),
  specialties: new Map(),
  lab_values: new Map(),
  product_page_indexes: new Map(),
  product_index_occupations: new Map(),
  sub_user_types: new Set(),
  all_question_banks: new Set(),
  bv_enums: new Map(),
  server_time: 0,
  hubspot_institutional_landing_form_id: '',
  hubspot_id: '',
  hubspot_should_sync: false,
  hubspot_newletter_form_id: '',
  hubspot_sign_up_form_id: '',
  hubspot_cme_coach_form_id: ''
});
const reducers = {};

reducers[SERVER_TIME_FETCHED] = (state, payload) => state.set('server_time', payload.server_time);

reducers[STATIC_DATA_FETCHED] = function globalDataFetched(state, payload) {
  let newState = state
    .set('server_time', payload.meta.server_time)
    .set('hubspot_institutional_landing_form_id', payload.data.attributes.hubspot_institutional_landing_form_id)
    .set('hubspot_id', payload.data.attributes.hubspot_id)
    .set('hubspot_should_sync', payload.data.attributes.hubspot_should_sync?.toLowerCase() === 'true')
    .set('hubspot_newletter_form_id', payload.data.attributes.hubspot_newletter_form_id)
    .set('hubspot_sign_up_form_id', payload.data.attributes.hubspot_sign_up_form_id)
    .set('hubspot_cme_coach_form_id', payload.data.attributes.hubspot_cme_coach_form_id);

  newState = (payload.data.attributes.us_states || []).reduce((accumulator, usState) =>
    accumulator.setIn(['us_states', usState.id], new Map(usState)), newState);

  newState = (payload.data.attributes.countries || []).reduce((accumulator, countries) =>
    accumulator.setIn(['countries', countries.id], new Map(countries)), newState);

  newState = (payload.data.attributes.feedback_types || []).reduce((accumulator, feedbackType) =>
    accumulator.update('feedback_types', feedbackTypes => feedbackTypes.add(feedbackType)), newState);
  newState = (payload.data.attributes.sub_user_types || []).reduce((accumulator, subUserType) =>
    accumulator.update('sub_user_types', subUserTypes => subUserTypes.add(fromJS(subUserType))), newState);
  newState = (payload.data.attributes.all_question_banks || []).reduce((accumulator, questionBank) =>
    accumulator.update('all_question_banks', allQuestionBanks => allQuestionBanks.add(fromJS(questionBank))), newState);
  newState = (payload.data.attributes.product_page_indexes || []).reduce((accumulator, productPage) =>
    accumulator.setIn(['product_page_indexes', productPage.id], new Map(productPage)), newState);
  newState = (payload.data.attributes.product_index_occupations || []).reduce((accumulator, productIndex) =>
    accumulator.setIn(['product_index_occupations', productIndex.id], new Map(productIndex)), newState);
  const labValuesJson = payload.included.filter(record => record.type === 'lab_value');
  newState = labValuesJson.reduce((accumulator, labValue) =>
    accumulator.setIn(['lab_values', parseInt(labValue.id)], new LabValue(labValue)), newState);

  if (payload.data.attributes.bv_enums) {
    const enums = payload.data.attributes.bv_enums;
    newState = Object.keys(enums).reduce((accumulator, enumKey) =>
      accumulator.setIn(['bv_enums', enumKey], new Map(enums[enumKey])), newState);
  }

  const specialtiesJson = payload.included.filter(record => record.type === 'specialty');
  newState = specialtiesJson.reduce((accumulator, specialty) =>
    accumulator.setIn(['specialties', parseInt(specialty.id)], new Specialty(specialty)), newState);

  const userTypesJson = payload.included.filter(record => record.type === 'user_type');
  newState = userTypesJson.reduce((accumulator, userType) =>
    accumulator.setIn(['user_types', parseInt(userType.id)], new UserType(userType)), newState);
  return newState;
};


export default createReducer(reducers, initialState);
