import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgHomeReasonUpdatedContent(props) {
  return (
    <SvgContainer title="Updated Content" {...props}>
      <circle cx="63.5" cy="63.5" r="63.5" fill="#FFF2DB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M64 80.775c-.844 0-1.406.687-1.406 1.375 0 .825.703 1.375 1.406 1.375.844 0 1.547-.688 1.547-1.375 0-.825-.703-1.375-1.547-1.375Zm0 0c-.844 0-1.406.687-1.406 1.375 0 .825.703 1.375 1.406 1.375.844 0 1.547-.688 1.547-1.375 0-.825-.703-1.375-1.547-1.375Zm0 0c-.844 0-1.406.687-1.406 1.375 0 .825.703 1.375 1.406 1.375.844 0 1.547-.688 1.547-1.375 0-.825-.703-1.375-1.547-1.375Z" fill="#1B3950" stroke="#1B3950" strokeWidth=".25" strokeMiterlimit="10" />
      <path fillRule="evenodd" clipRule="evenodd" d="M66.39 34.987h-4.78c-.844 0-1.407.688-1.407 1.513s.703 1.512 1.406 1.512h4.782c.843 0 1.546-.687 1.546-1.512 0-.963-.703-1.513-1.546-1.513Zm0 0h-4.78c-.844 0-1.407.688-1.407 1.513s.703 1.512 1.406 1.512h4.782c.843 0 1.546-.687 1.546-1.512 0-.963-.703-1.513-1.546-1.513Zm0 0h-4.78c-.844 0-1.407.688-1.407 1.513s.703 1.512 1.406 1.512h4.782c.843 0 1.546-.687 1.546-1.512 0-.963-.703-1.513-1.546-1.513Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
      <path fillRule="evenodd" clipRule="evenodd" d="M100.563 30.587a4.97 4.97 0 0 0-3.376-1.375H30.954c-1.265 0-2.531.55-3.375 1.375-.844.825-1.406 2.063-1.406 3.3v48.95c0 1.238.562 2.475 1.406 3.3a4.967 4.967 0 0 0 3.375 1.375h24.61v7.15H37.422c-.984 0-1.828.413-2.531.963-.703.687-.985 1.512-.985 2.475v5.225h60.328v-5.913c0-.687-.28-1.375-.843-1.925a2.808 2.808 0 0 0-1.97-.825H71.735v-7.15h25.454a4.97 4.97 0 0 0 3.375-1.375c.843-.825 1.406-2.062 1.406-3.3v-48.95c0-1.237-.563-2.475-1.406-3.3Zm-9.141 66.275c.14 0 .281 0 .422.138.14.137.14.275.14.412v3.713H36.016V98.1c0-.275.14-.688.422-.963.28-.275.562-.412.984-.412h54v.137ZM69.625 94.8H57.812v-7.15h11.813v7.15Zm30.094-11.963c0 .688-.281 1.375-.703 1.788-.422.412-1.125.687-1.829.687H30.954c-.703 0-1.406-.275-1.828-.687-.422-.413-.703-1.1-.703-1.788v-4.675h71.437v4.675h-.14Zm0-6.737H28.28V33.887c0-.687.282-1.237.703-1.787.563-.413 1.266-.688 1.97-.688h66.233c.704 0 1.266.275 1.829.688.421.412.703 1.1.703 1.787V76.1Z" fill="#1B3950" stroke="#1B3950" strokeMiterlimit="10" />
    </SvgContainer>
  );
}
