import React from "react";
import useWindowDimensions, { breakpoints } from "helpers/windowDimensions";

const SectionTitle = () => (<h2 className="storefront section-title ml-0 ml-md-4 ml-lg-6 mt-md-6 mt-lg-0 pb-md-3">ROBUST LEARNING TOOLS</h2>);

export default function HomeTools() {
  const { width } = useWindowDimensions();
  const isLg = width > breakpoints.md;
  return (
    <div className="HomeTools home-section-wrapper">
      <div className="container pt-lg-2">
        <div className="row d-flex d-lg-none px-3 px-md-0">
          <div className="col mb-6 mt-5 mt-md-6 pt-1 pb-md-3">
            <SectionTitle />
          </div>
        </div>
        <div className="row pt-lg-6 pb-lg-3 px-3 px-md-0">
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center mt-lg-6 pt-lg-6">
            <img className="img-fluid mt-3 mt-lg-2" loading="lazy" src="/images/homepage/quiz-modes-boardvitals.png" alt="Unlimited Board Practice Quizzes" />
          </div>
          <div className="position-relative col-12 col-lg-5 d-flex align-items-center justify-content-center mt-lg-6 pt-lg-6">
            <div>
              { isLg && <div className="d-none d-lg-block position-absolute section-title-lg-wrapper"><SectionTitle /></div>}
              <h3 className="storefront small m-5 m-md-6 mt-lg-6 mb-lg-0 mr-lg-0 py-1 py-md-3 pt-lg-5 pb-lg-0">Create unlimited custom quizzes to turn your weaknesses into strengths.</h3>
            </div>
          </div>
        </div>
        <div className="row py-lg-3 px-3 px-md-0">
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center">
            <img className="img-fluid" loading="lazy" src="/images/homepage/performance-analytics-boardvitals.png" alt="Analytics help you prep for the boards" />
          </div>
          <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center">
            <h3 className="storefront small m-5 m-md-6 ml-lg-6 m-lg-0 py-1 py-md-3 p-lg-0">In-depth analytics help you focus on key areas for improvement.</h3>
          </div>
        </div>
        <div className="row pt-lg-3 pb-md-3 pb-lg-6 px-3 px-md-0">
          <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center mb-lg-6 pb-lg-6">
            <img className="img-fluid mb-lg-2" loading="lazy" src="/images/homepage/medical-illustration-diagrams-boardvitals.png" alt="Videos, illustrations, and simulations support learning" />
          </div>
          <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center pb-6 pb-md-4 mb-lg-6 pb-lg-6">
            <h3 className="storefront small m-5 mb-6 m-md-6 ml-lg-6 m-lg-0 py-1 pt-md-3 pb-md-6 p-lg-0">Powerful visuals transform complicated concepts into clear illustrations.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
