import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgCustomizeExam(props) {
  return (
    <SvgContainer title="Customize Exam Icon" {...props}>
      <path d="M28.611 15.722h-.794a6.352 6.352 0 00-.235-.583l.557-.556a1.388 1.388 0 000-1.965l-1.257-1.257a1.39 1.39 0 00-1.964 0l-.557.557a6.599 6.599 0 00-.584-.235v-.794A1.39 1.39 0 0022.39 9.5H22V4a1.502 1.502 0 00-1.5-1.5h-17A1.502 1.502 0 002 4v24a1.502 1.502 0 001.5 1.5h17A1.502 1.502 0 0022 28v-1.5h.389a1.39 1.39 0 001.388-1.389v-.791a6.23 6.23 0 00.574-.249l.566.567a1.42 1.42 0 001.965 0l1.257-1.256a1.389 1.389 0 000-1.964l-.559-.56q.127-.276.235-.581h.796A1.39 1.39 0 0030 18.889V17.11a1.39 1.39 0 00-1.389-1.389zM21 28a.501.501 0 01-.5.5h-17A.5.5 0 013 28V4a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v5.5h-.389a1.39 1.39 0 00-1.388 1.389v.79c-.186.07-.375.153-.574.25l-.567-.568a1.39 1.39 0 00-1.964 0l-1.257 1.257a1.389 1.389 0 000 1.964l.56.56a6.638 6.638 0 00-.235.58h-.797A1.39 1.39 0 0013 17.112v1.777a1.39 1.39 0 001.389 1.388h.794q.107.308.235.584l-.557.557a1.389 1.389 0 000 1.964l1.257 1.257a1.42 1.42 0 001.964 0l.557-.556q.276.128.584.235v.793A1.39 1.39 0 0020.61 26.5H21zm8-9.111a.39.39 0 01-.389.388H27.08l-.106.356a5.743 5.743 0 01-.442 1.086l-.178.328 1.077 1.079a.378.378 0 01.115.273.383.383 0 01-.115.276l-1.257 1.257a.394.394 0 01-.55 0l-1.088-1.09-.328.178a5.488 5.488 0 01-1.072.461l-.359.107v1.523a.39.39 0 01-.388.389H20.61a.39.39 0 01-.388-.389v-1.53l-.358-.106a5.635 5.635 0 01-1.087-.44l-.328-.177-1.076 1.074a.394.394 0 01-.549 0l-1.257-1.257a.389.389 0 010-.55l1.076-1.075-.179-.328a5.661 5.661 0 01-.44-1.087l-.106-.357h-1.53a.39.39 0 01-.389-.39v-1.777a.39.39 0 01.389-.389h1.531l.107-.356a5.663 5.663 0 01.44-1.085l.18-.328-1.079-1.079a.383.383 0 01-.114-.274.388.388 0 01.114-.275l1.257-1.257a.389.389 0 01.55 0l1.089 1.089.328-.178a5.562 5.562 0 011.073-.46l.357-.107V10.89a.39.39 0 01.39-.389h1.777a.39.39 0 01.388.389v1.53l.358.106a5.616 5.616 0 011.087.44l.328.177 1.075-1.075a.39.39 0 01.55 0l1.257 1.258a.387.387 0 010 .55l-1.076 1.075.178.328a5.585 5.585 0 01.44 1.087l.108.357h1.529a.39.39 0 01.389.39z" />
      <path d="M21.5 15.5A2.5 2.5 0 1024 18a2.503 2.503 0 00-2.5-2.5zm0 4A1.5 1.5 0 1123 18a1.502 1.502 0 01-1.5 1.5z" />
      <circle cx={6.5} cy={13} r={1} />
      <circle cx={6.5} cy={19} r={1} />
      <circle cx={6.5} cy={25} r={1} />
      <path d="M8.854 5.646a.5.5 0 00-.707 0L6.5 7.293l-.646-.646a.5.5 0 00-.707.707l1 1a.5.5 0 00.707 0l2-2a.5.5 0 000-.708zM10.5 7.5h8a.5.5 0 000-1h-8a.5.5 0 000 1zM9.5 13.5h3a.5.5 0 000-1h-3a.5.5 0 000 1zM9.5 18.5a.5.5 0 000 1h1a.5.5 0 000-1zM13.5 24.5h-4a.5.5 0 000 1h4a.5.5 0 000-1z" />
    </SvgContainer>
  );
}
