import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';


// StyleGuide = molecules/global/wysiwyg


/* eslint-disable-next-line react/display-name */
const WYSIWYG = forwardRef((props, ref) => {
  const {
    children,
    className,
    id,
    tabIndex
  } = props;
  const innerHTML = { __html: children };

  const accessibilityProps = { ...(tabIndex && { tabIndex }) };

  return (
    /* eslint-disable-next-line react/no-danger */
    <div {...accessibilityProps} id={id} className={`wysiwyg ${className}`} dangerouslySetInnerHTML={innerHTML} ref={ref} />
  );
});

WYSIWYG.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number
};

WYSIWYG.defaultProps = {
  className: ''
};

export default WYSIWYG;
