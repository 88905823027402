import * as React from "react";
import SvgContainer from './SvgContainer';

export default function FredRadioSelected(props) {
  return (
    <SvgContainer title="Radio Selected Icon" {...props}>
      <circle cx="16.5" cy="16.5" r="14.5" fill="#5499f6" />
      <circle cx="16.5" cy="16.5" r="6.3" fill="#fff" />
    </SvgContainer>
  );
}
