import Factory from 'factories/_Factory';


export default class Image extends Factory {
  static jsonType = 'image';

  static get defaults() {
    return {
      url: '',
      width: 0,
      height: 0,
      large_url: '',
      large_width: 0,
      large_height: 0,
      original_url: '',
      original_width: 0,
      original_height: 0,
      caption: '',
      original_filename: '',
      display_original_by_default: false
    };
  }
}
