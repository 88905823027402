import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SVGIcon from "components/SVGIcon";
import useWindowDimensions from "helpers/windowDimensions";

export default function PlpPageInstructions({ showMobileFilters }) {
  const [isTablet, setIsTablet] = useState(false);
  const [isLargePhone, setIsLargePhone] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setIsTablet(width > 767 && width < 1024);
    setIsLargePhone(width > 576 && width <= 767);
  }, [width]);

  const svgSelector = (svgName) => {
    if (isTablet) return <SVGIcon name={svgName} xs />;

    return <SVGIcon name={svgName} medium />;
  };

  return (
    <div className={`plp-page-instructions main-wrapper ${showMobileFilters ? "d-none" : ""}`}>
      <div
        className={`${
          width >= 1360 ? "container" : "container-fluid"
        } inner-wrapper`}
      >
        <h2 className="storefront-secondary-title plp-instructions-title text-lg-center">
          Here's How It Works
        </h2>
        <div className="steps-container justify-content-between text-md mt-3 pt-5">
          <div
            className={`d-flex step ${isTablet ? "px-3" : "px-5"} py-5 mb-5`}
          >
            <div className={`svg ${isLargePhone ? "ml-5" : "mr-5"}`}>{svgSelector("Correct")}</div>
            <div className={`content ${isLargePhone ? "mr-5" : ""}`}>
              <p className="mb-3 font-weight-bold">Step 1</p>
              <p className="mb-0 storefront-body-text">
                Answer board-quality cases and questions from your computer or
                smartphone
              </p>
            </div>
          </div>
          <div
            className={`d-flex step ${isTablet ? "px-3" : "px-5"} py-5 mb-5`}
          >
            <div className={`svg ${isLargePhone ? "ml-5" : "mr-5"}`}>{svgSelector("Book")}</div>
            <div className={`content ${isLargePhone ? "mr-5" : ""}`}>
              <p className="mb-3 font-weight-bold">Step 2</p>
              <p className="mb-0 storefront-body-text">
                Review evidence-based rationales for incorrect responses
              </p>
            </div>
          </div>
          <div
            className={`d-flex step ${isTablet ? "px-3" : "px-5"} py-5 mb-5`}
          >
            <div className={`svg ${isLargePhone ? "ml-5" : "mr-5"}`}>{svgSelector("CmeMocEligible")}</div>
            <div className={`content ${isLargePhone ? "mr-5" : ""}`}>
              <p className="mb-3 font-weight-bold">Step 3</p>
              <p className="mb-0 storefront-body-text">
                Earn <i>AMA PRA Category 1 Credits™</i> and board-approved MOC credits
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PlpPageInstructions.propTypes = { showMobileFilters: PropTypes.bool };
