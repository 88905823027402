import StateHelper from 'helpers/StateHelper';
import Report from 'factories/Report';
import {
  REPORTS_FETCHED,
  REPORT_FETCHED,
  MEMBER_GROUP_REPORTS_FETCHED,
  CLASS_REPORTS_UNLOAD
} from 'actions/b2b/reports';

const stateHelper = new StateHelper(Report);
export const { initialState } = stateHelper;
const reducers = {};

reducers[REPORT_FETCHED] = stateHelper.set;
reducers[REPORTS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_REPORTS_FETCHED] = stateHelper.set;
reducers[CLASS_REPORTS_UNLOAD] = function classReportsUnload(state) {
  return state.filter(record => record.get('member_group_id') === null);
};

export default stateHelper.createReducer(reducers);
