// I'd like to change the Response Factory to an Immutable Record type so that we can implement
// these methods on the Response objects themselves.
// We haven't done this yet because benchmark tests have shown that the Record type is less
// performant than using a Map.


export function responseAnswerIds(response, ungraded = false) {
  let answerIds = response.get(ungraded ? 'ungraded_user_input' : 'user_input');
  answerIds = answerIds ? JSON.parse(answerIds) : answerIds;
  return answerIds ?? [];
}

export function responseEvaluation(response, question, ungraded = false) {
  if (!response) return false;

  const inputAttribute = ungraded ? 'ungraded_user_input' : 'user_input';

  switch (question.get('type')) {
    case 'SortableQuestion': {
      const correctAnswerIds = question.get('answers').sortBy(answer => answer.get('choice')).map(answer => answer.get('id')).join();
      const answerIds = responseAnswerIds(response, ungraded).join();
      return correctAnswerIds === answerIds;
    }
    case 'HotspotQuestion': {
      // The grading logic for hotspot is dependent on the onPress native event coordinates matching the svg polygon coordinates
      // hotspot_hit is only added if user presses to set the response, but not if we are reloading the question after leaving the quiz, since there is no onPress event.
      // In that case we need to rely on the previous cached answer which is the one rendered on the screen
      const inputParts = response.get(inputAttribute, '').split(',');
      if (inputParts.length >= 3) return inputParts[2] === 'true';
      return !!response.get('correct');
    }
    case 'OpenEndedQuestion':
      // We can't automatically evaluate Open Ended Questions so we automatically
      // set it to 'correct' if any answer at all has been provided.
      return response.get(inputAttribute).length > 0;
    case 'FillInBlankQuestion': {
      const correctAnswer = question.get('answers').toList().find(answer => answer.get('is_correct'));
      return correctAnswer.get('plain_name').toLowerCase().trim() === response.get(inputAttribute).toLowerCase().trim();
    }
    case 'MultipleChoiceQuestion':
    case 'GraphicChoiceQuestion':
    default: {
      const correctAnswerIds = question.get('correct_answer_ids').toArray();
      const answerIds = responseAnswerIds(response, ungraded);
      return answerIds.length === correctAnswerIds.length && answerIds.every(id => correctAnswerIds.includes(id));
    }
  }
}

export function responseSelectedAnswers(response, questionType, ungraded = false) {
  if (!response) return null;
  switch (questionType) {
    case 'HotspotQuestion':
    case 'OpenEndedQuestion':
    case 'FillInBlankQuestion':
      return response.get(ungraded ? 'ungraded_user_input' : 'user_input');
    case 'SortableQuestion':
    case 'MultipleChoiceQuestion':
    case 'GraphicChoiceQuestion':
    default:
      return responseAnswerIds(response, ungraded);
  }
}
