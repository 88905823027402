import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgLeftCaret4(props) {
  return (
    <SvgContainer title="Left Caret Icon" {...props}>
      <path d="M19 2L2 19L19 36" />
    </SvgContainer>
  );
}
