import * as React from "react";
import SvgContainer from "./SvgContainer";

export default function PearsonVueExit(props) {
  return (
    <SvgContainer title="X Icon" {...props}>
      <path d="M51 50H29V17h22Zm-18-4h14V21H33Z" />
      <path d="M36.72 33.49a2.53 2.53 0 0 1-.18.94 1.77 1.77 0 0 1-.15.31 2.11 2.11 0 0 1-.21.31 3.07 3.07 0 0 1-.25.26L23.58 46.26a2.5 2.5 0 0 1-1.66.62 2.47 2.47 0 0 1-1.87-.88 2.5 2.5 0 0 1 .22-3.53L27.63 36H12.85a2.56 2.56 0 0 1-2.54-1.88A2.5 2.5 0 0 1 12.73 31H28l-7.63-7.29a2.5 2.5 0 1 1 3.43-3.61l12.08 11.53.21.21a2.88 2.88 0 0 1 .26.35v.05a2.32 2.32 0 0 1 .18.39 2.11 2.11 0 0 1 .12.43 2.09 2.09 0 0 1 .07.43Z" />
    </SvgContainer>
  );
}
