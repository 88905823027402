import { List } from 'immutable';

import Factory from 'factories/_Factory';
import Image from 'factories/Image';


export default class Answer extends Factory {
  static jsonType = 'resource';

  static afterUpdate(record, json) {
    let newRecord = record;

    if (json.attributes.images || newRecord.get('images') === undefined) {
      const images = List((json.attributes.images || []).map(image => new Image(image)));
      newRecord = newRecord.set('images', images);
    }

    return newRecord;
  }

  static get defaults() {
    return {
      name: '',
      url: '',
      order: -1
    };
  }
}
