import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgRightCaret3(props) {
  return (
    <SvgContainer title="Left Caret Icon" {...props}>
      <path d="M0.947175 3.16615C0.303129 2.6451 0.203424 1.7006 0.724477 1.05655C1.24553 0.412504 2.19003 0.312799 2.83407 0.833852L0.947175 3.16615ZM14.8075 12.4501L15.7509 11.284C16.103 11.5688 16.3075 11.9973 16.3075 12.4501C16.3075 12.9029 16.103 13.3315 15.7509 13.6163L14.8075 12.4501ZM2.83407 24.0664C2.19003 24.5875 1.24553 24.4878 0.724477 23.8437C0.203424 23.1997 0.303129 22.2552 0.947175 21.7341L2.83407 24.0664ZM2.83407 0.833852L15.7509 11.284L13.864 13.6163L0.947175 3.16615L2.83407 0.833852ZM15.7509 13.6163L2.83407 24.0664L0.947175 21.7341L13.864 11.284L15.7509 13.6163Z" fill="#ECF2F6" />
    </SvgContainer>
  );
}
