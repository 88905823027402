/* getLoadingKey(...args)
 *
 * ...args = Accepts either a single string key OR an Action along with the same arguments passed to said Action.
 *
 * When passing an Action to ...args the Action function must have getKey() defined.
 * The Actions getKey() function should accept the same arguments as the Action.
 */

export function getLoadingKey(...args) {
  if (args.length < 1) {
    console.error('Received loadingKey() without arguments');
    return false;
  }
  switch (typeof args[0]) {
    case 'function':
      if (Object.prototype.hasOwnProperty.call(args[0], 'getKey') && typeof args[0].getKey === 'function') {
        const action = args[0];
        args.shift();
        return action.getKey(...args);
      }
      console.error('Received loadingKey() with function argument that doesn\'t have getKey() defined');
      return false;
    default:
      return args[0];
  }
}


// Loading Promises are set by the loadingMiddleware.
// Unlike the loading and loadedAt Slices of State, Loading Promises are not persisted locally.
// Finished Loading Promises are replaced with null to indicate that the key has been loaded for this session.
const loadingPromises = {};

export const loadingPromiseDefined = (...args) => Object.prototype.hasOwnProperty.call(loadingPromises, getLoadingKey(...args));
export const loadingPromiseLoading = (...args) => loadingPromiseDefined(...args) && loadingPromises[getLoadingKey(...args)] !== null;
export const loadingPromiseLoaded = (...args) => loadingPromiseDefined(...args) && loadingPromises[getLoadingKey(...args)] === null;

// These function exports (reset, set, unset) are for test mocks only. They should not be used in non-test environments/apps.
export const loadingPromisesSet = (promise, ...args) => { loadingPromises[getLoadingKey(...args)] = promise; };
export const loadingPromisesUnset = (...args) => { loadingPromises[getLoadingKey(...args)] = null; };
/* eslint-disable-next-line guard-for-in, no-restricted-syntax */
export const loadingPromisesReset = () => { for (const key in loadingPromises) delete loadingPromises[key]; };


export const loadingMiddleware = () => next => (action) => {
  // TODO: Should the key and payload be in the base action object instead of the payload?

  if (Object.prototype.hasOwnProperty.call(action, 'payload')
    && typeof action.payload === 'object'
    && Object.prototype.hasOwnProperty.call(action.payload, 'key')) {
    // Actions with keys will be processed
    if (Object.prototype.hasOwnProperty.call(action.payload, 'promise')) {
      // Actions that set loading have a promise in the payload.
      loadingPromises[action.payload.key] = action.payload.promise;
    } else if (Object.prototype.hasOwnProperty.call(loadingPromises, action.payload.key) || action?.type === 'STATIC_DATA_FETCHED') {
      // Actions that unset loading have the same key but no promise in the payload
      // STATIC_DATA_FETCHED may be pre-loaded so it may not have had a Promise set at all.
      loadingPromises[action.payload.key] = null;
    }
  }

  next(action);
};
