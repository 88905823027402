import api, { currentUserUrl } from 'helpers/api';

// Most of the Actions corresponding to these Action Constants are currently unique to the mobile app.
// Some of them have mobile-specific dependencies as well.
// These Action Constants have to be defined here because some Product Actions need to be reduced
// by our other reducers (specifically Question Banks and Subscriptions so far).

export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_ERROR = 'PRODUCTS_ERROR';

export const PRODUCTS_FETCHED = 'PRODUCTS_FETCHED';

export const PRODUCTS_SET_IAP_PERMISSION = 'PRODUCTS_SET_IAP_PERMISSION';

export const PRODUCT_CREATE_TRIAL = 'PRODUCT_CREATE_TRIAL';
export const PRODUCT_IAP_SYNCING = 'PRODUCT_IAP_SYNCING';
export const PRODUCT_IAP_SYNCED = 'PRODUCT_IAP_SYNCED';
export const PRODUCT_IAP_ERROR = 'PRODUCT_IAP_ERROR';
