import { Map } from 'immutable';

import createReducer from 'helpers/createReducer';

//
// StateHelper.js
//
// StateHelper is used to assist in the creation and usage of slices of state where
// the state is mean to represent a collection of ActiveRecord models.
//
// Updater functions are pure functions that perform common state mutations. They take
// a state object as their first parameter and return a new state object. These can be used
// to DRY up common functionality such as setting and updating records.
//

export default class StateHelper {
  constructor(Factory, expiration = 60 * 60 * 24) {
    if (!Factory) throw new Error('Factory is required to instantiate a StateHelper');
    this.Factory = Factory;
    // TODO: Implement automatic record expiration
    this.expiration = expiration;
  }


  // If your state requires additional root-level attributes then you must extend StateHelper
  // and override `get initialState()`. Your overridden function should first call
  // StateHelper.initialState and then add the new attributes to it. (always use super method)
  get initialState() {
    return new Map();
  }


  // State Updater Functions
  // These functions take a state object as the first parameter and return a new state object.
  setRecord(state, jsonRecord, options = {}) {
    let newRecord;
    const oldRecord = state.get(parseInt(jsonRecord.id));
    if (oldRecord) {
      newRecord = this.Factory.update(oldRecord, jsonRecord, options);
    } else {
      newRecord = new this.Factory(jsonRecord, options);
    }
    return state.set(parseInt(jsonRecord.id), newRecord);
  }

  setRecords(state, jsonRecords, options = {}) {
    return jsonRecords
      .filter(record => record.type === this.Factory.jsonType)
      .reduce((accumulator, jsonRecord) => this.setRecord(accumulator, jsonRecord, options), state);
  }

  destroyRecord(state, id) {
    return state.delete(id);
  }

  getRecordsFromPayload(payload) {
    const records = [];
    if (Array.isArray(payload.data)) {
      records.push(...payload.data.filter(record => record.type === this.Factory.jsonType));
    } else if (payload.data && payload.data.type === this.Factory.jsonType) {
      records.push(payload.data);
    }

    if (Array.isArray(payload.included)) {
      records.push(...payload.included.filter(record => record.type === this.Factory.jsonType));
    }

    return records;
  }

  reset = () => this.initialState;

  // Returns initialState with all records set from payload
  resetAndSet = (_state, payload, options = {}) =>
    this.set(this.initialState, payload, options);

  // Sets all records from payload
  set = (state, payload, options = {}) =>
    this.setRecords(state, this.getRecordsFromPayload(payload), options);

  // Only updates records which are already in the state
  update = (state, payload, options = {}) => {
    let newState = state;
    this.getRecordsFromPayload(payload).forEach((record) => {
      const id = parseInt(record.id);
      if (state.has(id)) newState = this.setRecord(newState, record, options);
    });
    return newState;
  };


  // Reducer generator
  // Export the results of calling this function from your reducers instead of exporting the results
  // of the regular createReducer helper directly. This automatically passes in the expected arguments
  // for this StateHelper.
  createReducer(reducers) {
    return createReducer(reducers, this.initialState);
  }
}
