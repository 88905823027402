import api, { selectedQuestionBankUrl } from 'helpers/api';


export const STRIKE_THROUGH_LOADING = 'STRIKE_THROUGH_LOADING';
function strikeThroughLoading(dispatch, key, promise) {
  dispatch({
    type: STRIKE_THROUGH_LOADING,
    payload: { key, promise }
  });
}

export const STRIKE_THROUGH_ERROR = 'STRIKE_THROUGH_ERROR';
function strikeThroughError(error, dispatch, key) {
  dispatch({
    type: STRIKE_THROUGH_ERROR,
    payload: { error, key }
  });
  throw error;
}

export const STRIKE_THROUGH_CREATED = 'STRIKE_THROUGH_CREATED';
const getStrikeThroughCreateKey = (quizId, answerId) => `strike-throughs-create/${quizId}/${answerId}`;
export const strikeThroughCreate = (quizId, answerId) => (dispatch, getState) => {
  const key = getStrikeThroughCreateKey(quizId, answerId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/strike_throughs/${answerId}`)
    .then((response) => {
      dispatch({
        type: STRIKE_THROUGH_CREATED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => strikeThroughError(error, dispatch, key));

  strikeThroughLoading(dispatch, key, promise);
  return promise;
};
strikeThroughCreate.getKey = getStrikeThroughCreateKey;

export const STRIKE_THROUGH_DESTROYED = 'STRIKE_THROUGH_DESTROYED';
const getStrikeThroughDestroyKey = (quizId, answerId) => `strike-throughs-destory/${quizId}/${answerId}`;
export const strikeThroughDestroy = (quizId, answerId) => (dispatch, getState) => {
  const key = getStrikeThroughDestroyKey(quizId, answerId);

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.delete(`${selectedQuestionBankUrl(getState())}/quizzes/${quizId}/strike_throughs/${answerId}`)
    .then((response) => {
      dispatch({
        type: STRIKE_THROUGH_DESTROYED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch(error => strikeThroughError(error, dispatch, key));

  strikeThroughLoading(dispatch, key, promise);
  return promise;
};
strikeThroughDestroy.getKey = getStrikeThroughDestroyKey;

export const STRIKE_THROUGH_CREATE_OFFLINE = 'STRIKE_THROUGH_CREATE_OFFLINE';
export const strikeThroughCreateOffline = (quizId, answerId) => (dispatch) => {
  dispatch({
    type: STRIKE_THROUGH_CREATE_OFFLINE,
    payload: {
      quizId,
      answerId
    }
  });
};

export const STRIKE_THROUGH_DESTROY_OFFLINE = 'STRIKE_THROUGH_DESTROY_OFFLINE';
export const strikeThroughDestroyOffline = (quizId, answerId) => (dispatch) => {
  dispatch({
    type: STRIKE_THROUGH_DESTROY_OFFLINE,
    payload: {
      quizId,
      answerId
    }
  });
};
