import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgClosedBook(props) {
  return (
    <SvgContainer title="Closed Book Icon" {...props}>
      <path d="M24 22.5H10a.5.5 0 000 1h14a.5.5 0 000-1z" />
      <path d="M27.5 20.5v-15a1 1 0 00-1-1H9A4.505 4.505 0 004.5 9v14A4.505 4.505 0 009 27.5h17.5a1 1 0 001-1v-1a1 1 0 00-1-1v-3a1 1 0 001-1zm-1 6H9A3.504 3.504 0 015.5 23V9A3.504 3.504 0 019 5.5h.5v15.05a2.5 2.5 0 00.5 4.95h16.5zm-1-2H10a1.5 1.5 0 010-3h15.5zm-.5-4H10.5v-15h16v15z" />
      <path d="M23.5 7.5h-10a1 1 0 00-1 1v6a1 1 0 001 1h10a1 1 0 001-1v-6a1 1 0 00-1-1zm0 7.5v-.5h-10v-6h10v6h.001z" />
    </SvgContainer>
  );
}
