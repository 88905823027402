import api from 'helpers/api';

export const CLINICAL_PEARLS_FETCHING = 'CLINICAL_PEARLS_FETCHING';
export const CLINICAL_PEARLS_FETCHED = 'CLINICAL_PEARLS_FETCHED';
export const CLINICAL_PEARLS_FETCH_ERROR = 'CLINICAL_PEARLS_FETCH_ERROR';
const clinicalPearlFetchKey = () => 'clinical_pearls';
export const pearlFetch = (questionBankIdArg, userIdArg) => (dispatch, getState) => {
  const { loading, session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const questionBankId = questionBankIdArg || session.get('selected_question_bank_id', 0);
  const key = clinicalPearlFetchKey();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`users/${userId}/question_banks/${questionBankId}/clinical_pearls`)
    .then((response) => {
      dispatch({
        type: CLINICAL_PEARLS_FETCHED,
        payload: { key, ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: CLINICAL_PEARLS_FETCH_ERROR,
        payload: { key, error }
      });
      throw error;
    });

  dispatch({
    type: CLINICAL_PEARLS_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
pearlFetch.getKey = clinicalPearlFetchKey;

export const PEARL_MARKED = 'PEARL_MARKED';
const PEARL_MARK_ERROR = 'PEARL_MARK_ERROR';
const getMarkPearlKey = (userIdArg, pearlIdArg) => `users/${userIdArg}/clinical_pearls/${pearlIdArg}/mark`;
export const markPearl = (userIdArg, pearlIdArg) => (dispatch, getState) => {
  const { session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const pearlId = pearlIdArg;

  return api.put(`users/${userId}/clinical_pearls/${pearlId}/mark`)
    .then((response) => {
      dispatch({
        type: PEARL_MARKED,
        payload: { ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: PEARL_MARK_ERROR,
        payload: { error }
      });
      throw error;
    });
};

markPearl.getKey = getMarkPearlKey;

export const PEARL_UNMARKED = 'PEARL_UNMARKED';
const PEARL_UNMARK_ERROR = 'PEARL_UNMARK_ERROR';
const getUnmarkPearlKey = (userIdArg, pearlIdArg) => `users/${userIdArg}/clinical_pearls/${pearlIdArg}/unmark`;
export const unmarkPearl = (userIdArg, pearlIdArg) => (dispatch, getState) => {
  const { session } = getState();
  const userId = userIdArg || session.get('current_user_id', 0);
  const pearlId = pearlIdArg;

  return api.put(`users/${userId}/clinical_pearls/${pearlId}/unmark`)
    .then((response) => {
      dispatch({
        type: PEARL_UNMARKED,
        payload: { ...response }
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: PEARL_UNMARK_ERROR,
        payload: { error }
      });
      throw error;
    });
};

unmarkPearl.getKey = getUnmarkPearlKey;
