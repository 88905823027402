import * as React from "react";
import SvgContainer from './SvgContainer';

export default function SvgPlay(props) {
  return (
    <SvgContainer title="Play Icon" {...props}>
      <path d="M23.877 15.155L9.282 5.151a1.024 1.024 0 00-1.604.845v20.009a1.024 1.024 0 001.604.845l14.595-10.005a1.025 1.025 0 000-1.69zm-.566.865L8.678 26.005l.039-20.03L23.31 15.98l.283-.413z" />
    </SvgContainer>
  );
}
