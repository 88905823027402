import { List, Map } from 'immutable';

export function b2bPeerCalc(averageScore, performancePeers, peerRankThreshold) {
  // Calculate Peer Rank
  // TODO: Update this to support multiple Practice Exams per QuestionBank
  let peerRank;
  let peerAverage;
  let peerStandardDeviation;

  // Peer Rank uses different scores depending on whether or not Tutor Mode is enabled.
  // The backend handles this automatically for performancePeers.
  const peerScore = averageScore;

  if (peerScore) {
    let totalPeers = 1;
    let lesserPeers = 0;
    let totalPeerScores = peerScore;

    performancePeers.forEach((performancePeer) => {
      if (performancePeer.get('practice_exam_template_id')) return;

      totalPeers += performancePeer.get('users_count');
      totalPeerScores += performancePeer.get('score') * performancePeer.get('users_count');
      if (performancePeer.get('score') <= peerScore) lesserPeers += performancePeer.get('users_count');
    });

    if (totalPeers > peerRankThreshold) {
      peerAverage = (totalPeerScores + peerScore) / totalPeers;
      peerRank = Math.round(lesserPeers / (totalPeers - 1) * 100);

      // Calculate Standard Deviation
      const totalDeviation = performancePeers.reduce((sum, performancePeer) => {
        const deviation = (performancePeer.get('score') - peerAverage) ** 2;
        return sum + (deviation * performancePeer.get('users_count'));
      }, 0) + ((peerScore - peerAverage) ** 2);

      peerStandardDeviation = Math.sqrt(totalDeviation / totalPeers);
    }
  }
  return [peerRank, peerStandardDeviation];
}

export function peerMapper(response) {
  const peerPerformances = response.map(a => new Map({
    practice_exam_template_id: a[0],
    score: a[1],
    users_count: a[2]
  }));
  return new List(peerPerformances);
}

export function reportPeerMapper(response) {
  const allPeerPerformances = response.all_peers.map(a => new Map({
    score: a[0],
    users_count: a[1],
    exclude_study: false
  }));
  const testPeerPerformances = response.test_peers.map(a => new Map({
    score: a[0],
    users_count: a[1],
    exclude_study: true
  }));
  return new List(allPeerPerformances.concat(testPeerPerformances));
}
