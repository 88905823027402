import StateHelper from 'helpers/StateHelper';
import CreditCard from 'factories/CreditCard';

import { CREDIT_CARDS_FETCHED, CREDIT_CARD_UPDATED } from 'actions/creditCards';

const stateHelper = new StateHelper(CreditCard);
export const { initialState } = stateHelper;
const reducers = {};

reducers[CREDIT_CARDS_FETCHED] = stateHelper.resetAndSet;
reducers[CREDIT_CARD_UPDATED] = stateHelper.set;

export default stateHelper.createReducer(reducers);
